import { DocumentsType } from 'v2-types/order';

type SearchDocumentsParams = {
  documents: DocumentsType[],
  queryTokens?: string[],
  partyTokens?: string[],
  propertyTokens?: string[]
}

const replaceRegex = /[^a-zA-Z0-9 ]/g;
const replaceValue = '\\$&';
export const searchDocuments = ({
  documents,
  queryTokens,
  partyTokens,
  propertyTokens
}: SearchDocumentsParams) => {
  const regexSearchQuery = new RegExp(`(${queryTokens?.join('|')})`);
  const regexSearchParty = new RegExp(`${partyTokens?.join('|')}`);
  const regexSearchProperty = new RegExp(`${propertyTokens?.join('|')}`);

  const testRegexs = (text) => {
    if (queryTokens && regexSearchQuery.test(text)) return true;
    if (partyTokens && regexSearchParty.test(text)) return true;
    if (propertyTokens && regexSearchProperty.test(text)) return true;
    return false;
  };

  let filteredDocuments = documents.filter((document) => {
    const files = document.archives.flatMap((archive) => archive.files);
    const textArray: string[] = [
      document.description,
      document.kind,
      document.data.reduce((text, currentInvolvedParty) => {
        text
          += (currentInvolvedParty.name ?? '')
          + (currentInvolvedParty.address ?? '')
          + (currentInvolvedParty.kind ?? '');
        return text;
      }, ''),
      files.reduce((text, currentFile) => {
        text += currentFile.filename;
        return text;
      }, ''),
    ];

    if (queryTokens && regexSearchQuery.test(
      textArray
        .join()
        .toLowerCase()
        .replace(replaceRegex, replaceValue)
    )) {
      return true;
    }

    if (partyTokens && document.data.some((association) => regexSearchParty.test(association.name?.toLowerCase() ?? ''))) return true;
    if (propertyTokens && document.data.some((association) => regexSearchProperty.test(association.address?.toLowerCase() ?? ''))) return true;

    return false;
  });

  filteredDocuments = filteredDocuments.map((document) => ({
    ...document,
    archives: document.archives.map((archive) => {
      if (archive && archive.files.length > 0) {
        if (archive.name) {
          const partyName = archive.name.toLowerCase().replace(replaceRegex, replaceValue);
          if (testRegexs(partyName)) return archive;
        }

        return {
          ...archive,
          files: archive.files.filter((file) => {
            const filename = file.filename.toLowerCase().replace(replaceRegex, replaceValue);
            return testRegexs(filename);
          })
        };
      }
      return archive;
    }),
    parts: document.parts?.filter((part) => {
      const filename = part.filename
        .toLowerCase()
        .replace(replaceRegex, replaceValue);
      return testRegexs(filename);
    }) ?? []
  }))
   ?? [];

  return filteredDocuments;
};
