import { GQLCreatePartyEmail, GQLDeletePartyEmail, GQLUpdatePartyEmail } from 'graphql/parties/mutations';
import { GQLGetUser } from 'graphql/users/queries';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { MutationResult, QueryResult } from 'react-query';
import {
  GQLCreateAddress,
  GQLCreateLicense,
  GQLCreatePhone,
  GQLDeleteAddress,
  GQLDeleteLicense,
  GQLDeletePhone,
  GQLUpdateAddress,
  GQLUpdateLicense,
  GQLUpdateParty,
  GQLUpdatePartyNotifications,
  GQLUpdatePhone,
} from 'services/querys/parties';
import { parseDate } from 'utils/helpers';
import { NewUserType } from 'v2-types/user';

const useMutations = (
  methods: UseFormReturn<NewUserType, any>,
  setFetch: React.Dispatch<React.SetStateAction<boolean>>,
  setErrors: React.Dispatch<React.SetStateAction<string[]>>,

  getCurrentUser: QueryResult<GQLGetUser, unknown>,

  responseUpdateParty: MutationResult<GQLUpdateParty, unknown>,
  responseUpdateAddress: MutationResult<GQLUpdateAddress, unknown>,
  responseUpdateEmail: MutationResult<GQLUpdatePartyEmail, unknown>,
  responseUpdatePhone: MutationResult<GQLUpdatePhone, unknown>,
  responseUpdateLicense: MutationResult<GQLUpdateLicense, unknown>,
  responseUpdatePartyNotifications: MutationResult<GQLUpdatePartyNotifications, unknown>,

  responseCreateAddress: MutationResult<GQLCreateAddress, unknown>,
  responseCreateEmail: MutationResult<GQLCreatePartyEmail, unknown>,
  responseCreatePhone: MutationResult<GQLCreatePhone, unknown>,
  responseCreateLicense: MutationResult<GQLCreateLicense, unknown>,

  responseDeleteAddress: MutationResult<GQLDeleteAddress, unknown>,
  responseDeleteEmail: MutationResult<GQLDeletePartyEmail, unknown>,
  responseDeletePhone: MutationResult<GQLDeletePhone, unknown>,
  responseDeleteLicense: MutationResult<GQLDeleteLicense, unknown>,
) => {
  // Fetch
  useEffect(() => {
    if (getCurrentUser.isSuccess && getCurrentUser.data) {
      const { party } = getCurrentUser.data.getUser;

      methods.setValue('_id', party._id);
      methods.setValue('user_id', party.user_id);
      methods.setValue('first_name', party.first_name || '');
      methods.setValue('middle_name', party.middle_name || '');
      methods.setValue('last_name', party.last_name || '');
      methods.setValue('kind', party.kind);
      methods.setValue('ssn', party.ssn || '');
      methods.setValue('birth', parseDate(party.birth as string));
      methods.setValue('gender', party.gender || '');
      methods.setValue('onboarded', party.onboarded);
      methods.setValue('addresses', party.addresses);
      methods.setValue('emails', party.emails);
      methods.setValue('phones', party.phones);
      methods.setValue('licenses', party.licenses);
      methods.setValue('parties', party.parties);
      methods.setValue('notifications', party.notifications);

      setFetch(false);

      getCurrentUser.clear();
    }
  }, [getCurrentUser, methods, setFetch]);

  // Update
  useEffect(() => {
    if (responseUpdateParty.isSuccess && responseUpdateParty.data) {
      responseUpdateParty.reset();
    }

    if (responseUpdateParty.isError) {
      responseUpdateParty.reset();
      let { errors = ['Error update party'] } = (responseUpdateParty.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseUpdateParty, setErrors]);

  useEffect(() => {
    if (responseUpdateAddress.isSuccess) {
      responseUpdateAddress.reset();
    }

    if (responseUpdateAddress.isError) {
      responseUpdateAddress.reset();
      let { errors = ['Error update address'] } = (responseUpdateAddress.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseUpdateAddress, setErrors]);

  useEffect(() => {
    if (responseUpdateEmail.isSuccess) {
      responseUpdateEmail.reset();
    }

    if (responseUpdateEmail.isError) {
      responseUpdateEmail.reset();
      let { errors = ['Error update email'] } = (responseUpdateEmail.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseUpdateEmail, setErrors]);

  useEffect(() => {
    if (responseUpdatePhone.isSuccess) {
      responseUpdatePhone.reset();
    }

    if (responseUpdatePhone.isError) {
      responseUpdatePhone.reset();
      let { errors = ['Error update phone'] } = (responseUpdatePhone.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseUpdatePhone, setErrors]);

  useEffect(() => {
    if (responseUpdateLicense.isSuccess) {
      responseUpdateLicense.reset();
    }

    if (responseUpdateLicense.isError) {
      responseUpdateLicense.reset();
      let { errors = ['Error update license'] } = (responseUpdateLicense.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseUpdateLicense, setErrors]);

  useEffect(() => {
    if (responseUpdatePartyNotifications.isSuccess) {
      responseUpdatePartyNotifications.reset();
    }

    if (responseUpdatePartyNotifications.isError) {
      responseUpdatePartyNotifications.reset();
      let { errors = ['Error update configuration'] } = (responseUpdatePartyNotifications.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseUpdatePartyNotifications, setErrors]);

  // Create
  useEffect(() => {
    if (responseCreateAddress.isSuccess && responseCreateAddress.data) {
      responseCreateAddress.reset();
      methods.setValue('addresses', responseCreateAddress.data.createAddress.addresses);
    }

    if (responseCreateAddress.isError) {
      responseCreateAddress.reset();
      let { errors = ['Error create address'] } = (responseCreateAddress.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseCreateAddress, methods, setErrors]);

  useEffect(() => {
    if (responseCreateEmail.isSuccess && responseCreateEmail.data) {
      responseCreateEmail.reset();
      methods.setValue('emails', responseCreateEmail.data.createEmail.emails);
    }

    if (responseCreateEmail.isError) {
      responseCreateEmail.reset();
      let { errors = ['Error create email'] } = (responseCreateEmail.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseCreateEmail, methods, setErrors]);

  useEffect(() => {
    if (responseCreatePhone.isSuccess && responseCreatePhone.data) {
      responseCreatePhone.reset();
      methods.setValue('phones', responseCreatePhone.data.createPhone.phones);
    }

    if (responseCreatePhone.isError) {
      responseCreatePhone.reset();
      let { errors = ['Error create phone'] } = (responseCreatePhone.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseCreatePhone, methods, setErrors]);

  useEffect(() => {
    if (responseCreateLicense.isSuccess && responseCreateLicense.data) {
      responseCreateLicense.reset();
      methods.setValue('licenses', responseCreateLicense.data.createLicense.licenses);
    }

    if (responseCreateLicense.isError) {
      responseCreateLicense.reset();
      let { errors = ['Error create license'] } = (responseCreateLicense.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseCreateLicense, methods, setErrors]);

  // Delete
  useEffect(() => {
    if (responseDeleteAddress.isSuccess && responseDeleteAddress.data) {
      responseDeleteAddress.reset();
      methods.setValue('addresses', responseDeleteAddress.data.deleteAddress.addresses);
    }

    if (responseDeleteAddress.isError) {
      responseDeleteAddress.reset();
      let { errors = ['Error delete address'] } = (responseDeleteAddress.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseDeleteAddress, methods, setErrors]);

  useEffect(() => {
    if (responseDeleteEmail.isSuccess && responseDeleteEmail.data) {
      responseDeleteEmail.reset();
      methods.setValue('emails', responseDeleteEmail.data.deleteEmail.emails);
    }

    if (responseDeleteEmail.isError) {
      responseDeleteEmail.reset();
      let { errors = ['Error delete email'] } = (responseDeleteEmail.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseDeleteEmail, methods, setErrors]);

  useEffect(() => {
    if (responseDeletePhone.isSuccess && responseDeletePhone.data) {
      responseDeletePhone.reset();
      methods.setValue('phones', responseDeletePhone.data.deletePhone.phones);
    }

    if (responseDeletePhone.isError) {
      responseDeletePhone.reset();
      let { errors = ['Error delete phone'] } = (responseDeletePhone.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseDeletePhone, methods, setErrors]);

  useEffect(() => {
    if (responseDeleteLicense.isSuccess && responseDeleteLicense.data) {
      responseDeleteLicense.reset();
      methods.setValue('licenses', responseDeleteLicense.data.deleteLicense.licenses);
    }

    if (responseDeleteLicense.isError) {
      responseDeleteLicense.reset();
      let { errors = ['Error delete license'] } = (responseDeleteLicense.error as any).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => ([...prev, errors]));
    }
  }, [responseDeleteLicense, methods, setErrors]);

  return null;
};

export default useMutations;
