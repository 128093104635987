import React, { FC } from 'react';
import { AccountingType } from 'v2-types/order';

import { useGeneralInformation } from './general-information-hooks';
import { GeneralInformationScene } from './general-information-scene';

type GeneralInformationProps = {
  orderOpener: string,
  accountingBalance: AccountingType[],
  accountingPremium: AccountingType[],
  orderType: string,
}
export const GeneralInformation: FC<GeneralInformationProps> = ({
  orderOpener,
  accountingBalance,
  accountingPremium,
  orderType
}) => {
  const {
    t,
    isAccountingTotalsDrawerOpen,
    setIsAccountingTotalsDrawerOpen,
    totalLoanAmount,
    totalPurchasePrice,
  } = useGeneralInformation({ accountingPremium });
  return (
    <GeneralInformationScene
      t={t}
      orderType={orderType}
      orderOpener={orderOpener}
      isAccountingTotalsDrawerOpen={isAccountingTotalsDrawerOpen}
      setIsAccountingTotalsDrawerOpen={setIsAccountingTotalsDrawerOpen}
      accountingBalance={accountingBalance}
      totalLoanAmount={totalLoanAmount}
      totalPurchasePrice={totalPurchasePrice}
    />
  );
};
