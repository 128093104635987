import { useTranslation } from 'react-i18next';
import { getAccountingEntryByLedgerCode } from 'utils/accounting';
import { LEDGER_CODE_ALL_DISBURSEMENTS, LEDGER_CODE_FUNDING, LEDGER_CODE_TOTAL_RECEIPTS } from 'utils/constants';
import { AccountingType } from 'v2-types/order';

type UseAccountingTotalsParams = {
  accountingBalance: AccountingType[]
}

export const useAccountingTotals = ({ accountingBalance }: UseAccountingTotalsParams) => {
  const { t } = useTranslation();

  const getEntryByLedgerCode = (ledgerCode: string) => getAccountingEntryByLedgerCode({ accounting: accountingBalance, ledgerCode });
  const totalReceipts = getEntryByLedgerCode(LEDGER_CODE_TOTAL_RECEIPTS)?.amount || 0;
  const allDisbursements = getEntryByLedgerCode(LEDGER_CODE_ALL_DISBURSEMENTS)?.amount || 0;
  const funding = getEntryByLedgerCode(LEDGER_CODE_FUNDING)?.amount || 0;

  return {
    t,
    totalReceipts,
    allDisbursements,
    funding
  };
};
