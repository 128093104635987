import {
  Grid, GridDirection, GridJustification, GridSpacing, Typography
} from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';

type TitleWithValueProps = {
  title: string,
  children?: React.ReactNode,
  titleVariant?: Variant,
  direction?: GridDirection,
  justifyContent?: GridJustification,
  spacing?: GridSpacing,
  titleUppercase?: boolean,
  isLoading?: boolean,
  loadingRowsNumber?: number,
  loadingRowsWidth?: number | string
}

export const TitleWithValue: FC<TitleWithValueProps> = ({
  title,
  children,
  titleVariant = 'caption',
  direction = 'column',
  justifyContent = 'flex-start',
  spacing = 0,
  titleUppercase = true,
  isLoading = false,
  loadingRowsNumber = 2,
  loadingRowsWidth = '100%'
}) => (
  (
    <Grid
      container
      direction={direction}
      spacing={spacing}
      justifyContent={justifyContent}
    >
      <Grid item>
        <Typography variant={titleVariant}>
          {titleUppercase ? title.toUpperCase() : title}
        </Typography>
      </Grid>
      {children && (
        <Grid item>
          {isLoading ? (
            <>
              {Array.from(Array(loadingRowsNumber).keys()).map((i) => (<Skeleton key={i} width={loadingRowsWidth} height={30} />))}
            </>
          ) : <>{children}</>}
        </Grid>
      )}
    </Grid>
  )
);
