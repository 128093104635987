import { formatMoney } from 'utils/helpers';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

export const sortCharges = (accountingInfo: AccountingType[]) => accountingInfo.sort((a, b) => {
  if (Number(a.number) > Number(b.number)) {
    return 1;
  }
  if (Number(a.number) < Number(b.number)) {
    return -1;
  }
  return 0;
});

export const chargesSorted = (accountingInfo:AccountingType[], allCharges?:boolean) => (allCharges
  ? sortCharges(accountingInfo)
  : sortCharges(accountingInfo.filter((acc) => acc.number !== null)));

export const formatOThersText = (parties: AccountingInvolvedParty[]) => parties.filter((party) => !party.order_kinds.includes('Buyer')
&& !party.order_kinds.includes('Seller')
&& party.kind.includes('Payer')).map((party) => `${party.name}:${formatMoney(party.amount)}`).join('-');

export type AccountingCharge = {
  id: string,
  description: string,
  number: number,
  chargeData: AccountingType,
  months: number | null,
  per_month: number | null,
  payee: string,
  seller_before_closing: number,
  seller_at_closing: number,
  buyer_before_closing: number,
  buyer_at_closing: number,
  by_others: number,
  others_description: string
}

export const formatAccountingData = (accountingData: AccountingType[]): AccountingCharge[] => accountingData.map((charge) => ({
  id: charge._id,
  description: charge.description,
  number: charge.number,
  chargeData: charge,
  months: charge.months || 0,
  per_month: charge.per_month || 0,
  tax: charge.involved_parties.find((party) => party.kind.includes('Tax')),
  pending_amount: charge.amount - charge.involved_parties.filter((party) => party.kind.includes('Payer')).reduce((prev, curr) => curr.amount + prev, 0),
  payee: charge.involved_parties.filter((party) => party.kind.includes('Payee')).map((p) => p.name).join(','),
  seller_before_closing: charge.involved_parties.reduce((prev, curr) => {
		  if (curr.order_kinds.includes('Seller') && curr.kind.includes('Payer')) {
      return prev + curr.before_closing_amount;
		  }
		  return prev;
  }, 0) || 0,
  seller_at_closing: charge.involved_parties.reduce((prev, curr) => {
		  if (curr.order_kinds.includes('Seller') && curr.kind.includes('Payer')) {
      return prev + curr.at_closing_amount;
		  }
		  return prev;
  }, 0) || 0,
  buyer_before_closing: charge.involved_parties.reduce((prev, curr) => {
		  if (curr.order_kinds.includes('Buyer') && curr.kind.includes('Payer')) {
      return prev + curr.before_closing_amount;
		  }
		  return prev;
  }, 0) || 0,
  buyer_at_closing: charge.involved_parties.reduce((prev, curr) => {
		  if (curr.order_kinds.includes('Buyer') && curr.kind.includes('Payer')) {
      return prev + curr.at_closing_amount;
		  }
		  return prev;
  }, 0) || 0,
  by_others: charge.involved_parties.reduce((prev, curr) => {
		  if (!curr.order_kinds.includes('Buyer') && !curr.order_kinds.includes('Seller') && curr.kind.includes('Payer')) {
      return prev + curr.amount;
		  }
		  return prev;
  }, 0) || 0,
  others_description: charge.involved_parties.length > 0 ? formatOThersText(charge.involved_parties) : 'Nothing to show'
	  }));

export const orderChargesByCodeAsc = (charges: AccountingCharge[]) => charges.sort((chargeA, chargeB) => {
  if (Number(chargeA.number) > Number(chargeB.number)) return 1;
  if (Number(chargeA.number) < Number(chargeB.number)) return -1;

  return 0;
});

export const getDisabledFieldsv2 = (param:{type: string;data?: any;}) => {
  if (param.type === 'edit') {
	  if (param.data?.id?.length === 10) {
      return {
		  months: true,
		  code: true,
		  kind: true,
		  months_advanced: true,
		  annuality: true,
		  entryDate: true,
		  number: true,
		  letter: true
      };
	  }
	  if (!param.data?.chargeData?.kind.includes('manual')) {
      return {
        months: true,
        code: true,
        kind: true,
        amount: true,
        months_advanced: true,
        annuality: true,
        entryDate: true,
        number: true,
        letter: true,
        description: true
      };
	  }
	  return {
      months: true,
      code: true,
      kind: true,
      amount: true,
      months_advanced: true,
      annuality: true,
      entryDate: true,
      number: true,
      letter: true
	  };
  }
  return {
    months: true,
    amount_override: true,
    months_advanced: true,
    annuality: true,
    entryDate: true,
    number: true,
  };
};

export const getHideFieldsv2 = (param:{type: string;data?: any;}) => {
  if (param.type === 'edit') {
	  if (param.data?.id?.length === 10) {
      return {
		  months: true,
		  code: true,
		  kind: true,
		  months_advanced: true,
		  annuality: true,
		  entryDate: true,
		  number: true,
		  amount_override: true
      };
	  }
	  return {
      months: true,
      code: true,
      kind: true,
      amount: true,
      months_advanced: true,
      annuality: true,
      entryDate: true,
      number: true
	  };
  }
  return {
	  months: true,
	  amount_override: true,
    months_advanced: true,
	  annuality: true,
	  entryDate: true,
	  number: true
  };
};
