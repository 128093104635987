import { makeStyles } from '@material-ui/core';
import React from 'react';
import { formatStringInputToNumber } from 'utils/helpers';

import InputCell from './input-cell';

const useStyles = makeStyles((theme: any) => ({
  span: {
    display: 'flex',
    '&>*': {
      width: theme.spacing(10),
      marginRight: theme.spacing(5),
    }
  },
}));

type YearsRangeFieldsProps = {
  year_raneg_to: number;
  year_range_from: number;
  onChangeField: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: any;
};

const YearsRangeFields = ({ year_raneg_to, year_range_from, onChangeField, errors }: YearsRangeFieldsProps) => {
  const classes = useStyles();

  return (
    <span className={classes.span}>
      <InputCell
        helperText={errors.payments?.year_range_from}
        value={year_range_from}
        onChangeInput={(e: any) => {
          const value = formatStringInputToNumber(e.target.value);
          onChangeField('payments.year_range_from', value !== null ? value : 1);
        }}
      />

      <InputCell
        helperText={errors.payments?.year_raneg_to}
        value={year_raneg_to}
        onChangeInput={(e: any) => {
          const value = formatStringInputToNumber(e.target.value);
          onChangeField('payments.year_raneg_to', value !== null ? value : 1);
        }}
      />
    </span>
  );
};

export default YearsRangeFields;
