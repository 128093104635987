import { Box } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { LoanFormType } from 'views/orders/loans/types';

import { ConditionForm } from '../condition-form';
import { ConditionsForm } from './types';
import { formatConditionsForm, getValues } from './utils';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>,
}

export const IncreasePrepaymentPenaltytConditions: FC<Props> = ({ loanForm }) => {
  const { control } = loanForm;
  const { has_prepayment_penalty_text } = useWatch({ control });

  const conditionForm = useForm<ConditionsForm>({ mode: 'onChange' });

  const { firstConditionExist, firstFieldValues } = useWatch({ control: conditionForm.control });

  useEffect(() => {
    conditionForm.reset(getValues(has_prepayment_penalty_text));
  }, [conditionForm, has_prepayment_penalty_text]);

  const saveForm = () => {
    loanForm.setValue('has_prepayment_penalty_text', formatConditionsForm(conditionForm.getValues()), { shouldDirty: true });
  };

  return (
    <Box>
      {firstConditionExist && (
      <ConditionForm
        conditionsFields={[
          {
            prefix: 'As high as $',
            value: firstFieldValues?.amount,
            onChange: (value) => {
              conditionForm.setValue('firstFieldValues.amount', value);
              saveForm();
            }
          },
          {
            prefix: ' if you pay off the loan within the first ',
            value: firstFieldValues?.years,
            sufix: 'years.',
            onChange: (value) => {
              conditionForm.setValue('firstFieldValues.years', value);
              saveForm();
            }
          }
        ]}
        onRemove={() => {
          conditionForm.setValue('firstConditionExist', false);
          conditionForm.setValue('firstFieldValues', {
            amount: undefined,
            years: undefined
          });
          saveForm();
        }}
      />
      )}
    </Box>
  );
};
