import { OrderType } from 'types/order';
import { generateRandomString } from 'utils/helpers';

import {
  ADD_CURRENT_ORDER_BUYER,
  ADD_CURRENT_ORDER_PROPERTY,
  ADD_CURRENT_ORDER_SELLER,
  EDIT_CURRENT_ORDER_BUYER,
  EDIT_CURRENT_ORDER_PROPERTY,
  EDIT_CURRENT_ORDER_SELLER,
  REMOVE_CURRENT_ORDER_BUYER,
  REMOVE_CURRENT_ORDER_PROPERTY,
  REMOVE_CURRENT_ORDER_SELLER,
  SET_CURRENT_ORDER,
  UPDATE_CURRENT_ORDER_CURRENT_VALUES,
} from './action-types';

export const setCurrentOrder = (order: OrderType) => ({
  type: SET_CURRENT_ORDER,
  payload: order,
});

export const addCurrentOrderProperty = () => ({
  type: ADD_CURRENT_ORDER_PROPERTY,
  payload: { id: generateRandomString(12) },
});

export const editCurrentOrderProperty = (payload: any) => ({
  type: EDIT_CURRENT_ORDER_PROPERTY,
  payload,
});

export const removeCurrentOrderProperty = (id: string) => ({
  type: REMOVE_CURRENT_ORDER_PROPERTY,
  payload: id,
});

export const addCurrentOrderBuyer = (role = 'buyer') => ({
  type: ADD_CURRENT_ORDER_BUYER,
  payload: {
    id: generateRandomString(12),
    role,
    information: { party_type: 'individual' },
  }
});

export const editCurrentOrderBuyer = (payload: any) => ({
  type: EDIT_CURRENT_ORDER_BUYER,
  payload,
});

export const removeCurrentOrderBuyer = (id: string) => ({
  type: REMOVE_CURRENT_ORDER_BUYER,
  payload: id,
});

export const addCurrentOrderSeller = (role = 'seller') => ({
  type: ADD_CURRENT_ORDER_SELLER,
  payload: {
    id: generateRandomString(12),
    role,
    information: { party_type: 'individual' },
  }
});

export const editCurrentOrderSeller = (payload: any) => ({
  type: EDIT_CURRENT_ORDER_SELLER,
  payload,
});

export const updateCurrentOrderCurrentValues = (payload: any) => ({
  type: UPDATE_CURRENT_ORDER_CURRENT_VALUES,
  payload,
});

export const removeCurrentOrderSeller = (id: string) => ({
  type: REMOVE_CURRENT_ORDER_SELLER,
  payload: id,
});

export default {};
