import React, { FC } from 'react';
import { NewUserType } from 'types/new-user';
import { NewOrderPartyType } from 'types/order';

import useTabBuyerAgent from './tab-agent-hooks';
import TabBuyerAgentScene from './tab-agent-scene';

type agentTabType = {
  party: NewUserType | undefined;
  partiesAdded: NewOrderPartyType[];
  roleParty: string;
  kind: string;
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>;
};
const BuyerSellerAgent: FC<agentTabType> = ({
  party,
  partiesAdded,
  roleParty,
  kind,
  setLoaderActive,
}) => {
  const {
    setSearchText,
    t,
    parties,
    setagentID,
    agentData,
    deleteagent,
    orderId,
    agentSelected,
    setagentSelected,
    setagentData,
  } = useTabBuyerAgent(party, roleParty, kind, setLoaderActive);

  const handleSearch = (text: string): void => setSearchText(text);

  const handleDeleteagent = () => {
    if (orderId && agentSelected) {
      setLoaderActive(true);
      setagentSelected(null);
      deleteagent({
        id: orderId,
        partyId: agentSelected._id,
        kind,
      });
    }
  };

  const handleSelectagent = (agent: NewOrderPartyType) => {
    setagentData(undefined);
    setagentSelected(agent);
  };

  return (
    <TabBuyerAgentScene
      t={t}
      handleSearch={handleSearch}
      parties={parties}
      setagentID={setagentID}
      agentData={agentData}
      handleDeleteagent={handleDeleteagent}
      agents={partiesAdded || []}
      agentSelected={agentSelected}
      handleSelectagent={handleSelectagent}
      kind={kind}
    />
  );
};

export default BuyerSellerAgent;
