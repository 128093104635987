import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { CreateLicenseModal } from 'components/create-license-modal';
import { LicensesHelperType } from 'components/users/types';
import { TFunction } from 'i18next';
import { iso31662 } from 'iso-3166';
import React, { FC, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { NewUserType } from 'types/new-user';

import SelectField from '../select-field';
import TextField from '../text-field';
import { useStyles } from './styles';

type Props = {
  t: TFunction,
  helpers: LicensesHelperType
}

export const LicenseFormList: FC<Props> = ({
  t,
  helpers
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const { control, formState: { errors } } = useFormContext<NewUserType>();
  const { fields: licenses } = useFieldArray({ name: 'licenses', control });
  const statesBycountry = [{
    code: 'ALL',
    name: 'National License',
    parent: ''
  }].concat(iso31662.filter((state) => state.parent === 'US')
    .map((state) => ({
      ...state,
      code: state.code.replace('US-', '')
    })));

  return (
    <>
      {!licenses.length && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} className={classes.emptyTitleContainer}>
            <Typography variant="body2" className={classes.title}>
              {t('users:no-licenses')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => setOpen(true)}
            >
              {t('users:create')}
            </Button>
          </Grid>
        </Grid>
      )}

      {!!licenses.length && (
        <>
          <Box>
            {licenses.map((license, index) => (
              <Grid
                key={license._id}
                container
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Controller
                    name={`licenses.${index}.number`}
                    control={control}
                    render={({ field: { ref, onBlur, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        label="ID"
                        handleBlur={() => {
                          const error = !!errors.licenses;

                          if (!error) {
                            helpers.licenses[index].number.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.licenses && !!errors.licenses[index] && !!errors?.licenses[index]?.number}
                        helperText={errors.licenses && errors.licenses[index] && errors?.licenses[index]?.number?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Controller
                    name={`licenses.${index}.state`}
                    control={control}
                    render={({ field: { ref, onChange, onBlur, ...field } }) => (
                      <SelectField
                        {...field}
                        inputRef={ref}
                        label={t('users:state')}
                        data={statesBycountry}
                        dataKey="code"
                        dataValue="code"
                        dataText="name"
                        handleChange={onChange}
                        handleBlur={() => {
                          const error = !!errors.licenses;

                          if (!error) {
                            helpers.licenses[index].state.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.licenses && !!errors.licenses[index] && !!errors?.licenses[index]?.state}
                        helperText={errors.licenses && errors.licenses[index] && errors?.licenses[index]?.state?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={1} className={classes.iconContainer}>
                  <IconButton
                    className={classes.icon}
                    onClick={() => helpers.remove(license._id || '')}
                  >
                    <FontAwesomeIcon icon="times" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Box>

          <Grid container className={classes.createButtonContainer}>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.createButton}
                onClick={() => setOpen(true)}
              >
                <FontAwesomeIcon
                  icon="plus-square"
                  className={classes.createButtonIcon}
                />

                {t('users:add-new')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <CreateLicenseModal
        t={t}
        open={open}
        states={statesBycountry}
        onClose={() => setOpen(false)}
        add={helpers.add}
      />
    </>
  );
};
