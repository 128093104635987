import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from 'components/text-field';
import useSearchParty from 'hooks/useSearchParty';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { NewTransferPayment } from 'types/common';
import { NewUserType } from 'types/new-user';
import * as Yup from 'yup';

import { PaymentProps, PaymentsTypes } from '../../types';
import ContainerPayment from './container-payment';

const useStyles = makeStyles(() => ({
  createButton: {
    height: 30,
    width: '100%',
    textTransform: 'capitalize',
  },
}));

type ModalCreateLicenseProps = {
  open: boolean;
  add: (data: PaymentsTypes, onClose?: () => void) => void;
  onClose: () => void;
  t: TFunction
};

const ModalCheck: FC<ModalCreateLicenseProps> = ({ onClose, open, add, t }) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const { parties } = useSearchParty(searchText);

  const schema = Yup.object({
    for_benefit_of: Yup.string().required(t('validations:required')),
    name: Yup.string().required(t('validations:required')),
    order_transfer: Yup.string().required(t('validations:required')),
    reference: Yup.string().required(t('validations:required')),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid, errors },
  } = useForm<NewTransferPayment & { contact?: NewUserType | null }>({
    resolver: yupResolver(schema),

    mode: 'onChange',
    defaultValues: {
      for_benefit_of: '',
      name: '',
      order_transfer: '',
      reference: '',
      contact: null,
    },
  });

  const classes = useStyles();

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle>
        <Typography>{t('charges:transfer')}</Typography>
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={handleSubmit((data) => {
            delete data.contact;
            return add({ transfer: data }, handleOnClose);
          })}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="contact"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    fullWidth
                    innerRef={ref}
                    options={parties}
                    filterOptions={(options) => options}
                    getOptionLabel={(party) => party.name}
                    onInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        setSearchText(value);
                      }
                    }}
                    onChange={(_, value) => {
                      if (value && typeof value === 'object') {
                        setValue('for_benefit_of', value._id);
                      } else {
                        onChange(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('users:for_benefit_of')}
                        inputRef={params.InputProps.ref}
                        InputProps={params.inputProps}
                        error={!!errors.for_benefit_of}
                        helperText={
                          errors.for_benefit_of?.message
                          || t('validations:required')
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:name')}
                    error={!!errors.name}
                    helperText={
                      errors.name?.message || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="order_transfer"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:order-transfer')}
                    error={!!errors.order_transfer}
                    helperText={
                      errors.order_transfer?.message
                      || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="reference"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:reference')}
                    error={!!errors.reference}
                    helperText={
                      errors.reference?.message || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item>
                  <Button
                    type="submit"
                    disableElevation
                    disabled={!isValid}
                    variant="contained"
                    color="secondary"
                    className={classes.createButton}
                  >
                    {t('users:create-payment')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Wire: FC<PaymentProps> = ({ helpers, t }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ContainerPayment
        t={t}
        kind="transfer"
        handleOpen={handleOpen}
        helpers={helpers}
      />

      <ModalCheck
        t={t}
        add={helpers.add}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default Wire;
