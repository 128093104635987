import { useGetCashToClose } from 'hooks/useGetCashToClose';
import { CashToCloseData, CashToCloseDataEntry } from 'hooks/useGetCashToClose/types';
import { useUpdateCashToClose } from 'hooks/useUpdateCashToClose';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { asyncForEach } from 'utils/helpers';

import { initialData } from './utils/initialData';

export const useCashToClose = () => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string}>();
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const queryCache = useQueryCache();
  const showAlert = useAlert();

  const cashToCloseForm = useForm<CashToCloseData>({
    defaultValues: initialData,
    mode: 'onChange'
  });

  const [orderTransactionType, setOrderTransactionType] = useState<string>('');

  const { isLoading: isCashToCloseLoading } = useGetCashToClose({
    orderId,
    queryConfig: {
      refetchOnWindowFocus: false,
      onSuccess: ({ cashToCloseData, configuration }) => {
        cashToCloseForm.reset(cashToCloseData);
        setOrderTransactionType(configuration.transaction_type);
      }
    }
  });

  const [updateCashToClose] = useUpdateCashToClose({});

  const { formState: { dirtyFields, isDirty } } = cashToCloseForm;
  const onSave = cashToCloseForm.handleSubmit(async (data) => {
    if (isDirty) {
      setIsUpdateLoading(true);
      await asyncForEach(Object.keys(dirtyFields), (propertyKey) => {
        const field: CashToCloseDataEntry = data[propertyKey] as CashToCloseDataEntry;
        return updateCashToClose({
          orderId,
          entryCode: field.entry_code,
          data: {
            estimate_amount: field.estimate_amount,
            changed: field.changed,
            changed_text: field.changed_text
          }
        });
      });

      queryCache.invalidateQueries(['party-order', orderId]);
      queryCache.invalidateQueries(['orderById', orderId]);
      setIsUpdateLoading(false);
      showAlert('All the changes were successfully saved', 'success');
    }
  });

  return {
    t,
    isCashToCloseLoading,
    isUpdateLoading,
    orderTransactionType,
    cashToCloseForm,
    onSave
  };
};
