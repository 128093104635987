import React, { FC } from 'react';
import { FileRequired } from 'views/document-request/types';

import { useDocumentRequestItemHooks } from './document-request-item-hooks';
import { DocumentRequestItemScene } from './document-request-item-scene';

type DocumentRequestItemProps = {
  fileRequired: FileRequired,
  onAddFile: ({ archiveId, file }: { archiveId: string, file: File }) => void,
  onRemoveFile: ({ archiveId, fileName }: { archiveId: string, fileName: string }) => void,
}
export const DocumentRequestItem: FC<DocumentRequestItemProps> = ({ fileRequired, onAddFile, onRemoveFile }) => {
  const {
    t,
    acceptedFiles,
    getInputProps,
    getRootProps,
    removeFile
  } = useDocumentRequestItemHooks({ fileRequired, onAddFile, onRemoveFile });
  return (
    <DocumentRequestItemScene
      t={t}
      fileRequired={fileRequired}
      acceptedFiles={acceptedFiles}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      removeFile={removeFile}
    />
  );
};
