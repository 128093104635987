import { createStyles, makeStyles, Theme } from '@material-ui/core';

type StyleProps = {
  isSelected: boolean,
  actionsDisabled: boolean
}

export const useStyles = makeStyles((theme: Theme) => createStyles<string, StyleProps>(({
  noteContainer: {
    padding: theme.spacing(2, 1),
    cursor: (props) => (props.actionsDisabled ? 'not-allowed' : 'pointer'),
    borderLeft: (props) => (props.isSelected ? '5px solid #D1B74F' : '5px solid transparent'),
    backgroundColor: (props) => (props.isSelected ? '#f5f5f5' : 'transparent'),
    borderRadius: '5px',
    color: '#000000'
  }
})));
