import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Button, makeStyles, Paper, Typography
} from '@material-ui/core';
import OnboardingBackground from 'assets/images/onboarding-background.png';
import { TFunction } from 'i18next';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import championship2Logo from '../../assets/images/login-img@2x.jpg';
import TextField from '../../components/text-field';

const useStyles = makeStyles((theme) => ({
  backgorund: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${OnboardingBackground})`,
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    overflow: 'hidden',
    position: 'relative',
    padding: 0,
    margin: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    maxWidth: '350px',
  },
  img: {
    width: '250px',
    marginBottom: theme.spacing(4.5),
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4.5),
  },
  btn: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    width: '100%',
    marginBottom: theme.spacing(4.5),
  },
  back: {
    color: theme.palette.info.dark,
    textTransform: 'capitalize',
    width: '100%',
  },
}));

type ResetPasswordProps = {
  t: TFunction;
  handleSendEmail: (email, reset) => void;
  backLogin: () => void;
};

const ResetPasswordScene = ({
  t,
  handleSendEmail,
  backLogin,
}: ResetPasswordProps) => {
  const classes = useStyles();
  const schema = Yup.object({ email: Yup.string().required(t('validations:required')), });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: { email: '' },
  });

  return (
    <>
      <Box className={classes.backgorund}>
        <Paper>
          <form
            className={classes.container}
            onSubmit={handleSubmit((data) => handleSendEmail(data.email, reset))}
          >
            <img className={classes.img} src={championship2Logo} alt="logo" />
            <Typography variant="h6" className={classes.title}>
              {t('auth:forgot-your-pass')}
            </Typography>
            <Typography
              variant="body2"
              className={classes.title}
              align="center"
            >
              {t('auth:instructions')}
            </Typography>

            <Controller
              name="email"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  style={{ marginBottom: '32px' }}
                  inputRef={ref}
                  label={t('auth:email')}
                  error={!!errors.email}
                  helperText={
                    errors.email?.message || t('validations:required')
                  }
                />
              )}
            />
            <Button
              className={classes.btn}
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!isValid}
            >
              {t('auth:send-email')}
            </Button>

            <Button
              className={classes.back}
              color="primary"
              onClick={backLogin}
            >
              {t('auth:go-login')}
            </Button>
          </form>
        </Paper>
      </Box>
    </>
  );
};

export default ResetPasswordScene;
