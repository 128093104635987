import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC, useMemo, useState } from 'react';
import { OrderListingType } from 'types/order';
import { IntlCurrencyFormat } from 'utils/helpers';

import HeaderOrders from './header-orders';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr',
    gridAutoRows: '1fr',
    gap: theme.spacing(2),
  },
  paginationContainer: { marginTop: theme.spacing(2) },
  paginationButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  pagintaionIcon: { fontSize: '0.98rem' },
  message: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
  button: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  icon: { marginLeft: theme.spacing(0.5) },
  rowsLetters: { fontWeight: 500 }
}));

type OrderProps = {
  t: TFunction;
  order: OrderListingType;
  openWorkspace: () => void;
};

const Order: FC<OrderProps> = ({ t, order, openWorkspace }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid item xs={2}>
        <Typography variant="body1" className={classes.rowsLetters}>
          {order.order_id}
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography variant="body1" className={classes.rowsLetters}>
          {order.address || t('common:no-defined-yet')}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography variant="body1" className={classes.rowsLetters}>
          {`${order.purchase_price ? IntlCurrencyFormat(order.purchase_price) : t('common:no-defined-yet')}`}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography variant="body1" className={classes.rowsLetters}>
          {`${order.loan_amount ? IntlCurrencyFormat(order.loan_amount) : t('common:no-defined-yet')}`}
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <IconButton
              className={classes.button}
              onClick={openWorkspace}
            >
              <FontAwesomeIcon icon="chevron-right" size="xs" className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

type OrdersProps = {
  t: TFunction;
  orders: OrderListingType[];
  openWorkspace: (orderId: string) => void;
};

const Orders: FC<OrdersProps> = ({ t, orders, openWorkspace }) => {
  const classes = useStyles();

  const offset = 10;
  const [page, setPage] = useState(0);

  const memorizedOrders = useMemo(() => (
    (orders || []).slice(page * offset, page * offset + offset)
  ),
  [orders, page]);

  const handleNavigation = (action: 'prev' | 'next') => {
    if (action === 'prev') {
      setPage((prev) => prev - 1);
      return;
    }

    setPage((prev) => prev + 1);
  };

  return (
    <>
      {!memorizedOrders.length && (
        <Typography variant="body2" className={classes.message}>
          {t('orders:not-orders-open')}
        </Typography>
      )}

      {!!memorizedOrders.length && (
        <>
          <Box className={classes.container}>
            <HeaderOrders t={t} />
            {memorizedOrders.map((order) => (
              <Order
                key={order.order_id}
                t={t}
                order={order}
                openWorkspace={() => openWorkspace(order._id)}
              />
            ))}
          </Box>

          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="flex-end"
            className={classes.paginationContainer}
          >
            <Grid item>
              <Typography>
                {page * offset + offset < orders.length ? (
                  `${page * offset + 1} - ${page * offset + offset} of ${orders.length}`
                ) : (
                  `${page * offset + 1} - ${orders.length} of ${orders.length}`
                )}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                disabled={page === 0}
                className={classes.paginationButton}
                onClick={() => handleNavigation('prev')}
              >
                <FontAwesomeIcon icon="chevron-left" className={classes.pagintaionIcon} />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                disabled={page * offset + offset >= orders.length}
                className={classes.paginationButton}
                onClick={() => handleNavigation('next')}
              >
                <FontAwesomeIcon icon="chevron-right" className={classes.pagintaionIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Orders;
