import { BaseButton, BaseButtonProps } from 'components/BaseButton';
import React, { FC } from 'react';

type ContainedButtonProps = BaseButtonProps

export const ContainedButton: FC<ContainedButtonProps> = (props) => (
  <BaseButton
    buttonVariant="contained"
    buttonColor="secondary"
    {...props}
  />
);
