import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { TFunction } from 'react-i18next';

import { PaymentsHelperType } from '../../types';

const useStyles = makeStyles((theme: any) => ({
  emptyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  createButtonContainer: { marginTop: theme.spacing(2) },
  createButton: {
    height: 30,
    width: '100%',
    textTransform: 'capitalize',
  },
  createButtonIcon: { marginRight: theme.spacing(1) },
  iconContainer: { textAlign: 'center' },
}));

type ContainerPaymentProps = {
  helpers: PaymentsHelperType;
  handleOpen: () => void;
  kind: string;
  t: TFunction
};

const ContainerPayment: FC<ContainerPaymentProps> = ({
  handleOpen,
  helpers,
  kind,
  t,
}) => {
  const classes = useStyles();
  return (
    <>
      {!helpers.payments.some((pay) => pay.kind === kind) ? (
        <>
          <Box className={classes.emptyContainer}>
            <Typography variant="body2">{t('users:no-payments')}</Typography>

            <Button
              className={classes.createButtonContainer}
              size="small"
              color="secondary"
              variant="outlined"
              onClick={handleOpen}
            >
              {t('users:create')}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography>Reference</Typography>
            </Grid>
          </Grid>
          {helpers.payments.map((payment) => (payment.kind === kind ? (
            <>
              <Divider style={{ height: '2px' }} />

              <Grid
                key={payment._id}
                container
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={11}>
                  <Typography>{payment.reference}</Typography>
                </Grid>

                <Grid item xs={1} className={classes.iconContainer}>
                  <IconButton
                    onClick={() => {
                      helpers.remove(payment._id);
                    }}
                  >
                    <FontAwesomeIcon icon="times" />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          ) : (
            ''
          )))}

          <Grid container className={classes.createButtonContainer}>
            <Grid item>
              <Button
                variant="outlined"
                className={classes.createButton}
                color="secondary"
                onClick={handleOpen}
              >
                <FontAwesomeIcon
                  icon="plus-square"
                  className={classes.createButtonIcon}
                />
                {t('users:add-new')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ContainerPayment;
