import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography
} from '@material-ui/core';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { FileChecked, RequestPartyDocuments } from '../types';

type RequestDocumentItemProps = {
  partyName: string,
  files: FileChecked[],
  requestIndex: number,
  control: Control<RequestPartyDocuments, object>
}

export const RequestDocumentItem: FC<RequestDocumentItemProps> = ({
  partyName,
  files,
  requestIndex,
  control
}) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<FontAwesomeIcon icon="angle-down" />}
    >
      <Typography variant="body1">{partyName}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container direction="row">
        {files.map((file, fileIndex) => (
          <Grid item xs={6} md={4} key={file.archiveId}>
            <Controller
              control={control}
              name={`requests.${requestIndex}.files.${fileIndex}.checked`}
              render={({ field }) => (
                <Checkbox
                  {...field}
                />
              )}
            />
            <Typography variant="caption">{file.filename.length > 50 ? `${file.filename.substring(0, 48)}...` : file.filename}</Typography>
          </Grid>
        ))}
      </Grid>
    </AccordionDetails>
  </Accordion>
);
