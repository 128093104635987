import React, { FC } from 'react';
import { NewOrderPartyType } from 'v2-types/order';

import { usePartiesInvolved } from './parties-involved-hooks';
import { PartiesInvolvedScene } from './parties-involved-scene';

type PartiesInvolvedProps = {
  sellers: NewOrderPartyType[],
  buyers: NewOrderPartyType[],
  lenders: NewOrderPartyType[],
  attorneys: NewOrderPartyType[],
  isOrderLoading: boolean
}

export const PartiesInvolved: FC<PartiesInvolvedProps> = ({
  sellers,
  buyers,
  lenders,
  attorneys,
  isOrderLoading
}) => {
  const {
    t,
    sellerList,
    buyerList
  } = usePartiesInvolved({
    sellers,
    buyers,
    attorneys
  });
  return (
    <PartiesInvolvedScene
      t={t}
      sellers={sellerList}
      buyers={buyerList}
      lenders={lenders}
      isOrderLoading={isOrderLoading}
    />
  );
};
