import { OrderProperty } from 'types/order';

export const extractPropertyData = (property: OrderProperty) => {
  const parcelIds: string[] = property.parcel_ids
    .map((parcelId) => {
      if (typeof parcelId !== 'string') return parcelId.value;
      return parcelId;
    }).filter((parcelId) => parcelId !== '');

  const {
    block,
    legal_description,
    jurisdiction,
    lot,
    range,
    section,
    subdivision,
    survey_date,
    survey_information,
    survey_number,
    township,
    type,
  } = property;

  const propertyData: Partial<OrderProperty> = {
    block,
    legal_description,
    jurisdiction,
    lot,
    parcel_ids: parcelIds,
    range,
    section,
    subdivision,
    survey_date,
    survey_information,
    survey_number,
    township,
    type,
  };
  return propertyData;
};
