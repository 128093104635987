import { makeStyles, TableCell } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles((theme:any) => ({
  borderCellStyle: {
    borderColor: theme.palette.input.border,
    borderWidth: 1,
    borderStyle: 'solid',
    opacity: 1,
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    backgroundColor: theme.palette.grey[100]
  },
}));

type headerCellProps = {
	children?:React.ReactNode,
	style?:React.CSSProperties | undefined,
	rowSpan?: number | undefined,
	colSpan?: number | undefined,
	onClick?: React.MouseEventHandler<any> | undefined
}

const CellHeader:FC<headerCellProps> = ({
  children, style, colSpan, rowSpan, onClick
}) => {
  const classes = useStyles();
  return (
    <TableCell
      className={classes.borderCellStyle}
      style={style}
      rowSpan={rowSpan}
      colSpan={colSpan}
      onClick={onClick}
    >
      {children}
    </TableCell>
  );
};

export default CellHeader;
