import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { WireTransfersType } from 'types/wire-transfers';

type FilterWireTransferListParams = {
  wireTransferList: WireTransfersType[],
  status?: string,
  amountMin?: number,
  amountMax?: number
}

export const filterWireTransferList = ({ wireTransferList, status, amountMin, amountMax }: FilterWireTransferListParams) => {
  const wireTranferFilteredByStatus = wireTransferList.filter((wireTransfer) => {
    if (status) {
      return wireTransfer.status.toLowerCase() === status.toLowerCase();
    }

    return true;
  });

  const wireTranferFilteredByAmountLimits = wireTranferFilteredByStatus.filter((wireTransfer) => {
    let isInLimit = true;
    const totalAmount = wireTransfer.instructions.reduce(
      (acc, element) => acc + element.amount,
      0
    );

    if (amountMin && amountMax) {
      return totalAmount >= amountMin && totalAmount <= amountMax;
    }

    if (amountMin) {
      isInLimit = totalAmount >= amountMin;
    }

    if (amountMax) {
      isInLimit = totalAmount <= amountMax;
    }

    return isInLimit;
  });

  return wireTranferFilteredByAmountLimits;
};

type GetComparationFnParams = {
  property: string,
  operator: string
}

export const getComparationFn = ({ property, operator }: GetComparationFnParams) => {
  let comparationFn: ((a: WireTransfersType, b: WireTransfersType) => number) | undefined;

  if (property === 'amount') {
    comparationFn = (a, b) => {
      const totalA = a.instructions.reduce(
        (acc, element) => acc + element.amount,
        0
      );
      const totalB = b.instructions.reduce(
        (acc, element) => acc + element.amount,
        0
      );

      if (totalA > totalB) {
        if (operator === 'descending') return -1;
        return 1;
      }
      if (totalA < totalB) {
        if (operator === 'descending') return 1;
        return -1;
      }
      return 0;
    };
  }
  return comparationFn;
};

export const convertMsToDate = ({ ms, formatValue = 'MMMM dd, yyyy', language }: { ms: number, language: string, formatValue?: string }) => {
  const locales = {
    en: enUS,
    es,
  };

  const locale = locales[language];
  return format(new Date(ms), formatValue, { locale });
};
