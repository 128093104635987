import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ContainedButton } from 'components/ContainedButton';
import SelectField from 'components/select-field';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { DocumentsType } from 'v2-types/order';

import { AssociationType } from '../../types';
import { getDocumentsByPartyId, getFilteredParty } from './services';
import { useStyles } from './styles';
import { DocumentPartyUpload } from './types';

type ManualDocumentUploadModalSceneProps = {
  open: boolean;
  t: TFunction;
  handleClose: () => void;
  control: Control<DocumentPartyUpload, object>;
  watch: UseFormWatch<DocumentPartyUpload>;
  isValid: boolean;
  onPartySelect: ({ partyId }) => void;
  onUploadDocuments: () => void;
  onRemoveFile: ({ file }: { file: File }) => void;
  openFileDialog: () => void;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  filesToUpload: File[];
  isLoading: boolean;
  documents: DocumentsType[];
  selectedParty: string;
  parties: AssociationType[];
  setValue: UseFormSetValue<DocumentPartyUpload>;
};

export const ManualDocumentUploadModalScene: FC<
  ManualDocumentUploadModalSceneProps
> = ({
  open,
  t,
  handleClose,
  parties,
  control,
  watch,
  isValid,
  onPartySelect,
  onUploadDocuments,
  onRemoveFile,
  openFileDialog,
  getRootProps,
  getInputProps,
  filesToUpload,
  isLoading,
  documents,
  selectedParty,
  setValue,
}) => {
  const classes = useStyles();
  const filterDocs = getDocumentsByPartyId({
    partyId: selectedParty,
    documents,
  });
  const filteredParties = getFilteredParty({ parties, documents });
  const archives = documents.flatMap((file) => file.archives.flatMap((archive) => archive.files));
  const duplicatedFileNames = archives.filter((file) => filesToUpload.find((uploaded) => uploaded.name.includes(file.filename)));

  return (
    <Dialog open={open} fullWidth keepMounted={false}>
      <DialogContent style={{ padding: 0 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              {t('documents:manual-upload')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            spacing={2}
            className={classes.container}
          >
            <Grid item>
              <Typography variant="body1" className={classes.subtitle}>
                {t('documents:select-party-document')}
              </Typography>
            </Grid>
            {!!duplicatedFileNames.length && (
              <Grid item>
                <Alert
                  severity="error"
                  classes={{ icon: classes.errorIconContainer }}
                >
                  {`${t('documents:you-already-have')}${duplicatedFileNames
                    .map((file) => file.filename)
                    .join(', ')}`}
                </Alert>
              </Grid>
            )}
            <Grid item>
              <Controller
                control={control}
                name="partyId"
                rules={{ required: true }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    label="Select the party"
                    value={field.value || ''}
                    InputProps={{
                      ...field,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        onPartySelect({ partyId: e.target.value });
                        field.onChange(e);
                        setValue('archiveId', '');
                      },
                    }}
                    data={filteredParties}
                    dataKey="_id"
                    dataValue="_id"
                    dataText="name"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="archiveId"
                rules={{ required: true }}
                render={({ field }) => (
                  <SelectField
                    label="Select the document"
                    {...field}
                    value={field.value || ''}
                    InputProps={{ ...field }}
                    data={filterDocs || []}
                    dataKey="_id"
                    dataValue="_id"
                    dataText="description"
                    disabled={!watch('partyId')}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            className={classes.container}
            xs
          >
            <Grid
              item
              {...getRootProps({ className: 'dropzone' })}
              className={classes.dropzoneContainer}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <input {...getInputProps()} />
                <Grid item>
                  <FontAwesomeIcon icon="upload" size="2x" />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {capitalize(t('documents:drag-or-drop'))}
                  </Typography>
                </Grid>
                <Grid item>
                  <ContainedButton
                    onClick={openFileDialog}
                    text={capitalize(t('documents:browse-files'))}
                  />
                </Grid>
                <Grid container justifyContent="center" style={{ gap: '5px' }}>
                  {filesToUpload.map((file) => (
                    <Grid
                      item
                      container
                      className={classes.fileContainer}
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          onClick={() => onRemoveFile({ file })}
                          style={{ minWidth: 'auto' }}
                        >
                          <Box className={classes.removeFileContainer}>
                            <FontAwesomeIcon
                              size="sm"
                              icon="plus"
                              color="white"
                              transform={{ rotate: 45 }}
                            />
                          </Box>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">{file.name}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <ContainedButton text="Close" onClick={handleClose} />
        <ContainedButton
          text="Upload documents"
          isLoading={isLoading}
          onClick={onUploadDocuments}
          disabled={!isValid || filesToUpload.length === 0}
        />
      </DialogActions>
    </Dialog>
  );
};
