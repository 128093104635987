import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import React from 'react';

const Cellstatus = ({ status }:{status:boolean}) => {
  if (status) {
    return (
      <Box>
        <FontAwesomeIcon icon="check" style={{ color: 'green' }} />
      </Box>
    );
  }
  return (
    <Box>
      <FontAwesomeIcon icon="times" style={{ color: 'red' }} />
    </Box>
  );
};

export default Cellstatus;
