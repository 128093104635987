import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FC } from 'react';
import { Loan } from 'v2-types/order';

import { useStyles } from '../../styles';

type Props = {
  isDataLoading: boolean,
  selectedLoan?: Loan
}

export const Property: FC<Props> = ({ isDataLoading, selectedLoan }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        Property
      </Typography>
      {isDataLoading && (
      <Skeleton animation="wave" variant="text" height={40} width="70%" />
      )}
      {selectedLoan && (
      <Typography>{selectedLoan.estate.address.address}</Typography>
      )}
    </Box>
  );
};
