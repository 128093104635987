import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@material-ui/core';
import React from 'react';

type ActionButtonProps = {
  shouldCollapse?: boolean;
  handleCollapse?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleAdd?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleEdit?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleDelete?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disableDelete?: boolean;
  handleChain?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleParties?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleTaxable?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleIndividualPayeePayer?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const Actionbuttons = ({
  shouldCollapse,
  handleCollapse,
  handleAdd,
  handleEdit,
  handleDelete,
  handleChain,
  handleParties,
  handleTaxable,
  handleIndividualPayeePayer,
  disableDelete
}: ActionButtonProps) => (
  <Box
    style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', gap: '2px' }}
  >
    {handleCollapse && (
      <IconButton style={{ padding: 0 }} onClick={handleCollapse}>
        {shouldCollapse ? (
          <FontAwesomeIcon icon="arrow-alt-circle-up" size="xs" />
        ) : (
          <FontAwesomeIcon icon="arrow-alt-circle-down" size="xs" />
        )}
      </IconButton>
    )}
    {handleAdd && (
      <IconButton style={{ padding: 0 }} onClick={handleAdd}>
        <FontAwesomeIcon icon="plus-circle" size="xs" />
      </IconButton>
    )}
    {handleEdit && (
      <IconButton style={{ padding: 0 }} onClick={handleEdit}>
        <FontAwesomeIcon icon="edit" size="xs" />
      </IconButton>
    )}
    {handleDelete && (
      <IconButton disabled={disableDelete} style={{ padding: 0 }} onClick={handleDelete}>
        <FontAwesomeIcon icon="trash" size="xs" />
      </IconButton>
    )}
    {handleChain && (
      <IconButton style={{ padding: 0 }} onClick={handleChain}>
        <FontAwesomeIcon icon="link" size="xs" />
      </IconButton>
    )}
    {handleParties && (
      <IconButton style={{ padding: 0 }} onClick={handleParties}>
        <FontAwesomeIcon icon="people-arrows" size="xs" />
      </IconButton>
    )}
    {handleTaxable && (
      <IconButton style={{ padding: 0 }} onClick={handleTaxable}>
        <FontAwesomeIcon icon="coins" size="xs" />
      </IconButton>
    )}
    {handleIndividualPayeePayer && (
      <IconButton style={{ padding: 0 }} onClick={handleIndividualPayeePayer}>
        <FontAwesomeIcon icon="user" size="xs" />
      </IconButton>
    )}
  </Box>
);

Actionbuttons.defaultProps = {
  shouldCollapse: undefined,
  handleCollapse: undefined,
  handleAdd: undefined,
  handleEdit: undefined,
  handleDelete: undefined,
};

export default Actionbuttons;
