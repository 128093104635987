import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { FC, useMemo } from 'react';

import { partiesfiltered } from '../../services';
import Party from './components/party-menu-entry';

const useStyles = makeStyles((theme: any) => ({
  sideContainer: {
    padding: theme.spacing(3),
    overflow: 'scroll',
    height: '68vh',
  },
  title: {
    fontWeight: 600,
    color: theme.palette.tab.offselected,
  },
  buttonContainer: { padding: theme.spacing(2) },
  buttonCreate: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
    margin: '0 auto 10px auto',
    display: 'block',
  },
  listTitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
  icon: { marginRight: theme.spacing(1.5) },
  iconUsers: {
    marginRight: theme.spacing(1.5),
    color: '#4F5B62DE',
  },
  psrtytitle: {
    color: '#4F5B62DE',
    fontWeight: 500,
  },
}));

type SideMenuProps = {
  parties: partiesfiltered[];
  handleViewChange: (partytype: string, id: string) => void;
  handleDeleteAssociation: (partyId: string, kind: string) => void;
};

const SideMenu: FC<SideMenuProps> = ({
  parties,
  handleViewChange,
  handleDeleteAssociation,
}) => {
  const classes = useStyles();

  const partyProps = useMemo(
    () => parties.map((associate) => ({
      ...associate,
      handleViewChange,
      handleDeleteAssociation,
    })),
    [parties, handleViewChange, handleDeleteAssociation]
  );

  return (
    <>
      <Grid container direction="column">
        <Paper>
          <Grid item xs={12} className={classes.sideContainer}>
            <Grid container direction="column">
              {partyProps
                .filter((sub) => sub.parties.length > 0)
                .map((props) => (
                  <Grid key={props.sectionId} item xs={12}>
                    <Party {...props} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default SideMenu;
