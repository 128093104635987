import React from 'react';

import { OrderFlowPropertiesScene } from './order-flow-properties-scene';
import { useOrderFlowProperties } from './use-order-flow-properties';

const OrderFlowProperties = () => {
  const {
    t,
    theme,
    properties,
    isSaveLoading,
    onRemoveProperty,
    isRemovePropertyLoading,
    selectedProperty,
    selectProperty,
    currentTab,
    onSelectTab,
    onSaveProperty,
    onAddProperty,
    errors,
    dataIsValid,
    control,
    getValues,
    isCreatePropertyError,
    isUpdatePropertyError,
    setValue,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isPropertiesDataLoading
  } = useOrderFlowProperties();

  return (
    <OrderFlowPropertiesScene
      t={t}
      theme={theme}
      properties={properties}
      isSaveLoading={isSaveLoading}
      isRemovePropertyLoading={isRemovePropertyLoading}
      selectedProperty={selectedProperty}
      selectProperty={selectProperty}
      currentTab={currentTab}
      onSelectTab={onSelectTab}
      onSaveProperty={onSaveProperty}
      errors={errors}
      dataIsValid={dataIsValid}
      control={control}
      getValues={getValues}
      onRemoveProperty={onRemoveProperty}
      onAddProperty={onAddProperty}
      isCreatePropertyError={isCreatePropertyError}
      isUpdatePropertyError={isUpdatePropertyError}
      setValue={setValue}
      isDeleteModalOpen={isDeleteModalOpen}
      setIsDeleteModalOpen={setIsDeleteModalOpen}
      isPropertiesDataLoading={isPropertiesDataLoading}
    />
  );
};

export default OrderFlowProperties;
