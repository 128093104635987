import { Box, Typography } from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/helpers';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';
import { ChargeEntry } from 'views/orders/charges-accounting/components/prototype-new-table-charges/types';
import InformationRow from 'views/orders/mortgage-payoffs/components/mortgage-detail/components/information-row';

import { Receipt } from './components/receipt';
import { useStyles } from './styles';

type CollapseRowProps = {
  entry: AccountingType,
  addDisbursement?: (chargeData: ChargeEntry) => void,
  showRemoveAssociationModal?: (chargeData: ChargeEntry) => void,
  showEditAssociationModal?: (chargeData: AccountingType, accountingPartyEntry: AccountingInvolvedParty) => void,
  showDeleteReceiptModal?: (chargeData: ChargeEntry) => void,
}

export const DisbursementCardRow:FC<CollapseRowProps> = ({
  entry,
  addDisbursement,
  showRemoveAssociationModal,
  showEditAssociationModal,
  showDeleteReceiptModal
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.drawLine}>
      {entry?.involved_parties.length === 0 && <Typography variant="body2" className={classes.titleSection}>{t('charges:no-items-to-show')}</Typography>}

      {entry?.involved_parties?.filter((filtered) => filtered.kind.includes('Payer')).map((involvedParty) => {
        const pendingamount = involvedParty.amount - (involvedParty.accounted_by?.reduce((prev, curr) => prev + curr.amount, 0) || 0);
        const accountedByList = involvedParty.accounted_by || [];

        const chargeEntryData: ChargeEntry = {
          accountingId: entry._id,
          kind: involvedParty.kind,
          partyId: involvedParty._id,
          description: entry.description
        };

        return (
          <Box key={involvedParty._id} className={classes.cardContainer}>
            <Box>
              <Typography variant="body1" className={classes.titleSection}>{t('charges:payment-details')}</Typography>
            </Box>
            <Box className={classes.actionContainer}>
              {addDisbursement && (
                <BaseButton
                  text={t('charges:add-disbursement')}
                  icon="plus"
                  size="small"
                  onClick={() => {
                    addDisbursement(chargeEntryData);
                  }}
                />
              )}
              {showRemoveAssociationModal && (
              <BaseButton
                text={t('charges:remove-association')}
                icon="trash"
                size="small"
                onClick={() => {
                  showRemoveAssociationModal(chargeEntryData);
                }}
              />
              )}
              {showEditAssociationModal && (
                <BaseButton
                  text={t('charges:edit-association')}
                  icon="edit"
                  size="small"
                  onClick={() => showEditAssociationModal(entry, involvedParty)}
                />
              )}
            </Box>
            <Box className={classes.informationContainer}>
              <InformationRow
                title={t('accounting:amount')}
                value={`${formatMoney(involvedParty.amount)}(${(involvedParty.percent * 100).toFixed(2)}%)`}
              />
              {pendingamount !== 0 && (
                <InformationRow
                  title={t('common:pending')}
                  warning
                  value={`${formatMoney(pendingamount)}`}
                />
              )}
              <InformationRow
                title={involvedParty.kind}
                value={` ${involvedParty.name}(${involvedParty.order_kinds.join(',')})`}
              />
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {accountedByList.length === 0 && (
                <Typography variant="caption">{t('charges:no-disbursements-yet')}</Typography>
              )}
              {accountedByList.map((accounted) => {
                const onDelete = showDeleteReceiptModal ? () => {
                  showDeleteReceiptModal({
                    ...chargeEntryData,
                    targetEntryId: accounted.record._id
                  });
                } : undefined;

                return (
                  <Receipt
                    key={accounted.record._id}
                    accounted={accounted}
                    onDelete={onDelete}
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
