import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  title: {
    marginRight: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  displayButton: {
    fontSize: '1rem',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.black,
  },
  displayButtonActive: { backgroundColor: theme.palette.button.default },
  spacing: { marginRight: theme.spacing(1) },
}));

type HeaderProps = {
  t: TFunction;
  displayMode: 'list' | 'grid';
  handleDisplayMode: (mode: 'list' | 'grid') => void;
};

const Header: FC<HeaderProps> = ({ t, displayMode, handleDisplayMode }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="h4" className={classes.title}>
              {t('users:contacts')}
            </Typography>
          </Grid>

          <Grid item className={classes.spacing}>
            <IconButton
              className={clsx(classes.displayButton, { [classes.displayButtonActive]: displayMode === 'grid', })}
              onClick={() => handleDisplayMode('grid')}
            >
              <FontAwesomeIcon icon="grip-horizontal" />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              className={clsx(classes.displayButton, { [classes.displayButtonActive]: displayMode === 'list', })}
              onClick={() => handleDisplayMode('list')}
            >
              <FontAwesomeIcon icon="list" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
