import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { iso31662 } from 'iso-3166';
import React, { FC, useState } from 'react';
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { NewAddressType, NewUserType } from 'types/new-user';
import * as Yup from 'yup';

import { capitalize } from '../../utils/helpers';
import NumberField from '../number-field';
import SelectField from '../select-field';
import TextField from '../text-field';
import { AddressHelperType } from './types';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
  emptyTitleContainer: { marginBottom: theme.spacing(2) },
  buttonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  buttonText: {
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  addressContainer: {
    overflowX: 'auto',
    overflowY: 'hidden',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: 4 },
    '&&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark },
  },
  addressButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    width: 150,
    height: 30,
    marginRight: theme.spacing(1),
    '&:hover': { backgroundColor: theme.palette.input.border },
  },
  addressSelectedButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.main },
  },
  addressInnerButton: { width: 140 },
  addressText: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
  addressSelectedText: { color: theme.palette.common.white },
  addressCloseIcon: {
    fontSize: theme.spacing(2),
    width: 30,
    height: 30,
  },
  addressAdd: {
    width: 30,
    height: 30,
    minWidth: 40,
  },
  addressFormContainer: { marginTop: theme.spacing(2) },
}));

type ModalCreateAddressProps = {
  t: TFunction;
  open: boolean;
  add: (data: NewAddressType, onClose?: () => void) => void;
  onClose: () => void;
};

const ModalCreateAddress: FC<ModalCreateAddressProps> = ({
  t,
  open,
  add,
  onClose,
}) => {
  const classes = useStyles();
  const statesBycountry = iso31662.filter((state) => state.parent === 'US')
    .map((state) => ({
      ...state,
      code: state.code.replace('US-', '')
    }));

  const schema = Yup.object({
    state: Yup.string().required(t('validations:required')),
    postal_code: Yup.string().required(t('validations:required')),
    street_address: Yup.string().required(t('validations:required')),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NewAddressType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      address: '',
      state: 'TX',
      postal_code: '',
      locality: '',
      references: '',
      settlement: '',
      kind: ''
    },
  });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>
        {t('users:create-address')}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit((data) => add(data, handleOnClose))}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="street_address"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:address')}
                    error={!!errors.street_address}
                    helperText={errors.street_address?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="references"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:referencias')}
                    error={!!errors.references}
                    helperText={errors.references?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="locality"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:locality')}
                    error={!!errors.locality}
                    helperText={errors.locality?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="state"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <SelectField
                    {...field}
                    inputRef={ref}
                    data={statesBycountry}
                    dataKey="code"
                    dataValue="code"
                    dataText="name"
                    label={t('users:state')}
                    handleChange={onChange}
                    error={!!errors.state}
                    helperText={errors.state?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="postal_code"
                control={control}
                render={({ field: { value, ...field } }) => (
                  <NumberField
                    {...field}
                    decimalScale={0}
                    allowNegative={false}
                    label={t('users:zip-code')}
                    value={value as number | null}
                    error={!!errors.postal_code}
                    helperText={errors.postal_code?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="settlement"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:settlement')}
                    error={!!errors.settlement}
                    helperText={errors.settlement?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="kind"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label="kind"
                    error={!!errors.kind}
                    helperText={errors.kind?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid item className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    disableElevation
                    disabled={!isValid}
                    variant="contained"
                    color="secondary"
                    className={classes.buttonText}
                  >
                    {t('users:create')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

type FormAddressProps = {
  t: TFunction;
  showTitle?: boolean;
  helpers: AddressHelperType;
};

const FormAddress: FC<FormAddressProps> = ({ t, showTitle, helpers }) => {
  const classes = useStyles();
  const statesBycountry = iso31662.filter((state) => state.parent === 'US')
    .map((state) => ({
      ...state,
      code: state.code.replace('US-', '')
    }));

  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);

  const { control, formState: { errors } } = useFormContext<NewUserType>();
  const { fields: addresses } = useFieldArray({ name: 'addresses', control });

  return (
    <>
      <Grid container direction="row" spacing={2}>
        {showTitle && (
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              {t('users:addresses')}
            </Typography>
          </Grid>
        )}

        {!addresses.length && (
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} className={classes.emptyTitleContainer}>
                <Typography variant="body2" className={classes.title}>
                  {t('users:no-addresses')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={() => setOpen(true)}
                >
                  {t('users:create')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {!!addresses.length && (
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Box display="flex" className={classes.addressContainer}>
                {addresses.map((address, index) => (
                  <Box
                    key={address._id}
                    className={clsx(classes.addressButton, {
                      [classes.addressSelectedButton]:
                        index === selected
                    })}
                  >
                    <Button
                      className={classes.addressInnerButton}
                      onClick={() => setSelected(index)}
                    >
                      <Typography
                        variant="caption"
                        className={clsx(classes.addressText, {
                          [classes.addressSelectedText]:
                            index === selected
                        })}
                      >
                        {`${t('users:address')} #${index + 1}`}
                      </Typography>
                    </Button>

                    <Box>
                      <IconButton
                        className={clsx(
                          classes.addressCloseIcon,
                          classes.addressText,
                          { [classes.addressSelectedText]: index === selected }
                        )}
                        onClick={() => {
                          setSelected(0);
                          helpers.remove(address._id || '');
                        }}
                      >
                        <FontAwesomeIcon icon="times-circle" />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box marginLeft={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.addressAdd}
                  onClick={() => setOpen(true)}
                >
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </Box>
            </Box>

            <Grid item xs={12} className={classes.addressFormContainer}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    key={addresses[selected]?._id}
                    name={`addresses.${selected}.street_address`}
                    control={control}
                    render={({ field: { ref, onBlur, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        label={t('users:address')}
                        handleBlur={() => {
                          const error = !!errors.addresses;

                          if (!error) {
                            helpers.addresses[selected].street_address.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.addresses && !!errors.addresses[selected] && !!errors?.addresses[selected]?.street_address}
                        helperText={errors.addresses && errors.addresses[selected] && errors?.addresses[selected]?.street_address?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    key={addresses[selected]?._id}
                    name={`addresses.${selected}.references`}
                    control={control}
                    render={({ field: { ref, onBlur, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        label={capitalize(t('users:references'))}
                        handleBlur={() => {
                          const error = !!errors.addresses;

                          if (!error) {
                            helpers.addresses[selected].references.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.addresses && !!errors.addresses[selected] && !!errors?.addresses[selected]?.references}
                        helperText={errors.addresses && errors.addresses[selected] && errors?.addresses[selected]?.references?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    key={addresses[selected]?._id}
                    name={`addresses.${selected}.state`}
                    control={control}
                    render={({ field: { ref, onChange, onBlur, ...field } }) => (
                      <SelectField
                        {...field}
                        inputRef={ref}
                        data={statesBycountry}
                        dataKey="code"
                        dataValue="code"
                        dataText="name"
                        label={t('users:state')}
                        handleChange={onChange}
                        handleBlur={() => {
                          const error = !!errors.addresses;

                          if (!error) {
                            helpers.addresses[selected].state.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.addresses && !!errors.addresses[selected] && !!errors?.addresses[selected]?.state}
                        helperText={errors.addresses && errors.addresses[selected] && errors?.addresses[selected]?.state?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    key={addresses[selected]?._id}
                    name={`addresses.${selected}.postal_code`}
                    control={control}
                    render={({ field: { value, onBlur, ...field } }) => (
                      <NumberField
                        {...field}
                        decimalScale={0}
                        allowNegative={false}
                        label={t('users:zip-code')}
                        value={value as number | null}
                        handleBlur={() => {
                          const error = !!errors.addresses;

                          if (!error) {
                            helpers.addresses[selected].postal_code.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.addresses && !!errors.addresses[selected] && !!errors?.addresses[selected]?.postal_code}
                        helperText={errors.addresses && errors.addresses[selected] && errors?.addresses[selected]?.postal_code?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    key={addresses[selected]?._id}
                    name={`addresses.${selected}.locality`}
                    control={control}
                    render={({ field: { ref, onBlur, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        label={t('users:locality')}
                        handleBlur={() => {
                          const error = !!errors.addresses;

                          if (!error) {
                            helpers.addresses[selected].locality.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.addresses && !!errors.addresses[selected] && !!errors?.addresses[selected]?.locality}
                        helperText={errors.addresses && errors.addresses[selected] && errors?.addresses[selected]?.locality?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    key={addresses[selected]?._id}
                    name={`addresses.${selected}.settlement`}
                    control={control}
                    render={({ field: { ref, onBlur, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        label={t('users:settlement')}
                        handleBlur={() => {
                          const error = !!errors.addresses;

                          if (!error) {
                            helpers.addresses[selected].settlement.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.addresses && !!errors.addresses[selected] && !!errors?.addresses[selected]?.settlement}
                        helperText={errors.addresses && errors.addresses[selected] && errors?.addresses[selected]?.settlement?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    key={addresses[selected]?._id}
                    name={`addresses.${selected}.kind`}
                    control={control}
                    render={({ field: { ref, onBlur, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        label={t('users:kind')}
                        handleBlur={() => {
                          const error = !!errors.addresses;

                          if (!error) {
                            helpers.addresses[selected].kind.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.addresses && !!errors.addresses[selected] && !!errors?.addresses[selected]?.kind}
                        helperText={errors.addresses && errors.addresses[selected] && errors?.addresses[selected]?.kind?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ModalCreateAddress
        t={t}
        open={open}
        add={helpers.add}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

FormAddress.defaultProps = { showTitle: true };

export default FormAddress;
