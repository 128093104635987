import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

export type GQLGetUseryNotesResponse = {
  getUserNotes: {
    notes: string
  }
}

export const getUserNotes = async () => {
  const query = gql`
    query GetUserNotes {
      getUserNotes {
        notes
      }
    }
  `;

  const response = await graphQLClient().request<GQLGetUseryNotesResponse>(query);
  return response;
};
