/* eslint-disable react-hooks/exhaustive-deps */
import useSearchParty from 'hooks/useSearchParty';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import graphql from 'services/querys';
import { UpdateOrderSpecific } from 'types/order';
import useAlert from 'utils/alert';

import PartiesContext from '../../../services/context';

const usePoaTab = (
  partyId: string,
  roleParty: string,
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const context = useContext(PartiesContext);
  const { filterParties } = context || {};
  const { t } = useTranslation();
  const [poaID, setPoatID] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { parties } = useSearchParty(searchText);
  const { id: orderId } = useParams<{ id: string }>();

  const showAlert = useAlert();
  const methods = useFormContext<UpdateOrderSpecific>();

  const [deletePoa, deletePoaResponse] = useMutation(
    graphql.deleteAssociation,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          setLoaderActive(false);
          showAlert(t('parties:poa_deleted'), 'success');
          deletePoaResponse.reset();
          setPoatID(null);
          if (data?.deleteOrderAssociation.parties && filterParties) {
            filterParties(data?.deleteOrderAssociation?.parties!);
          }
        }, 1500);
      },
      onError: () => {
        setLoaderActive(false);
        showAlert(t('parties:mutation-error'), 'error');
      },
    }
  );
  const [addPoa, addPoaResponse] = useMutation(graphql.createAssociation, {
    onSuccess: (data) => {
      setTimeout(() => {
        setLoaderActive(false);
        showAlert(t('parties:poa_add'), 'success');
        setPoatID(null);
        addPoaResponse.reset();
        if (data?.createOrderAssociation.parties && filterParties) {
          filterParties(data?.createOrderAssociation?.parties!);
        }
      }, 1500);
    },
    onError: () => {
      setLoaderActive(false);
      showAlert(t('parties:mutation-error'), 'error');
    },
  });

  useEffect(() => {
    if (orderId && partyId && poaID) {
      setLoaderActive(true);
      addPoa({
        id: orderId,
        partyId: poaID,
        linkedToId: partyId,
        kind: 'Power of Attorney',
        linkedToKind: roleParty,
      });
    }
  }, [addPoa, orderId, partyId, poaID, roleParty]);

  return {
    t,
    parties,
    setSearchText,
    setPoatID,
    orderId,
    deletePoa,
    methods,
  };
};

export default usePoaTab;
