import { ConditionsForm } from '../types';

const getNumberValue = (text): number | undefined => {
  const matches = text.match(/\d+/g);
  if (matches === null) return undefined;

  return Number(matches[0]);
};

export const getValues = (text: string | null | undefined): ConditionsForm => {
  const values: ConditionsForm = {
    firstConditionExist: false,
    secondConditionExist: false,
    thirdConditonExist: false,
    firstFieldValues: {
      year: undefined,
      years: undefined
    },
    secondFieldValues: {
      percent: undefined,
      year: undefined
    },
    thirdFieldValues: { year: undefined }
  };
  if (!text) return values;

  const conditions = text.split('. ');
  const firstCondition = conditions.find((condition) => condition.includes('Adjust every'));
  if (firstCondition) {
    const [firstPart, secondPart] = firstCondition.split(' years');
    const years = getNumberValue(firstPart);
    const year = getNumberValue(secondPart);

    values.firstConditionExist = true;
    values.firstFieldValues = {
      year,
      years
    };
  }

  const secondCondition = conditions.find((condition) => condition.includes('Can go as high as'));
  if (secondCondition) {
    const [firstPart, secondPart] = secondCondition.split('%');
    const percent = getNumberValue(firstPart);
    const year = getNumberValue(secondPart);

    values.secondConditionExist = true;
    values.secondFieldValues = {
      percent,
      year
    };
  }

  const thirdCondition = conditions.find((condition) => condition.includes('Includes only interest and no principal until year'));
  if (thirdCondition) {
    const year = getNumberValue(thirdCondition);

    values.thirdConditonExist = true;
    values.thirdFieldValues = { year };
  }

  return values;
};

export const formatConditionsForm = (data: ConditionsForm) => {
  const {
    firstConditionExist,
    secondConditionExist,
    thirdConditonExist,
    firstFieldValues,
    secondFieldValues,
    thirdFieldValues
  } = data;

  let conditions: string = '';
  if (firstConditionExist) {
    const { years, year } = firstFieldValues;
    conditions += `Adjust every ${years} years starting in year ${year}. `;
  }

  if (secondConditionExist) {
    const { percent, year } = secondFieldValues;
    conditions += `Can go as high as ${percent}% in year ${year}. `;
  }

  if (thirdConditonExist) {
    const { year } = thirdFieldValues;
    conditions += `Includes only interest and no principal until year ${year}. `;
  }

  return conditions;
};
