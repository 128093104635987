import { Grid, makeStyles, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewBasicInfoType } from 'types/new-user';

import DatePicker from '../date-picker/date-picker';
import SelectField from '../select-field';
import TextField from '../text-field';
import { PersonalInformationHelperType } from './types';

const useStyles = makeStyles((theme: any) => ({
  title: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
}));

type FormPersonalInformationProps = {
  t: TFunction;
  showTitle?: boolean;
  shouldValidate?: boolean;
  showOptionalFields?: boolean;
  helpers?: PersonalInformationHelperType;
  searchText?: string[];
};

const FormPersonalInformation: FC<FormPersonalInformationProps> = ({
  t,
  showTitle,
  shouldValidate,
  showOptionalFields,
  helpers,
  searchText,
}) => {
  const classes = useStyles();
  const {
    control,
    getValues,
    reset,
    formState: { errors }
  } = useFormContext<NewBasicInfoType>();

  const kind = getValues('kind');
  const genders = useMemo(
    () => [
      {
        value: 'Male',
        text: t('common:male'),
      },
      {
        value: 'Female',
        text: t('common:female'),
      },
    ],
    [t]
  );
  useEffect(() => {
    const currentValues = getValues();
    if (searchText) {
      if (kind === 'Person') {
        const [firstName, middleName, ...rest] = searchText;

        reset({
          ...currentValues,
          last_name: rest.length !== 0 ? rest.join(" ") : middleName,
          middle_name: rest.length !== 0 ? middleName : "",
          first_name: firstName,
        });
      } else {
        const completeNames = searchText?.join(' ') || '';
        reset({
          ...currentValues,
          first_name: completeNames,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kind]);

  return (
    <Grid container direction="row" spacing={2}>
      {showTitle && (
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            {t('users:personal-information')}
          </Typography>
        </Grid>
      )}

      <Grid item xs={kind === 'Person' ? 4 : 12}>
        <Controller
          name="first_name"
          control={control}
          render={({ field: { ref, onBlur, ...field } }) => (
            <TextField
              {...field}
              inputRef={ref}
              disabled={helpers!.first_name.disabled}
              label={kind === 'Person' ? t('users:firstName') : t('users:name')}
              handleBlur={() => {
                const error = !!errors.first_name || !!errors.last_name;

                if (!error && helpers!.first_name.onBlur) {
                  helpers!.first_name.onBlur(onBlur);
                }
              }}
              error={!!errors.first_name}
              helperText={
                errors.first_name?.message
                || (shouldValidate ? t('validations:required') : '')
              }
            />
          )}
        />
      </Grid>

      {kind === 'Person' && (
        <>
          <Grid item xs={4}>
            <Controller
              name="middle_name"
              control={control}
              render={({ field: { ref, onBlur, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  disabled={helpers!.middle_name.disabled}
                  label={t('users:middleName')}
                  handleBlur={() => {
                    const error = !!errors.first_name || !!errors.last_name;

                    if (!error && helpers!.middle_name.onBlur) {
                      helpers!.middle_name.onBlur(onBlur);
                    }
                  }}
                  error={!!errors.middle_name}
                  helperText={errors.middle_name?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name="last_name"
              control={control}
              render={({ field: { ref, onBlur, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  disabled={helpers!.last_name.disabled}
                  label={t('users:lastName')}
                  handleBlur={() => {
                    const error = !!errors.first_name || !!errors.last_name;

                    if (!error && helpers!.last_name.onBlur) {
                      helpers!.last_name.onBlur(onBlur);
                    }
                  }}
                  error={!!errors.last_name}
                  helperText={
                    errors.last_name?.message
                    || (shouldValidate ? t('validations:required') : '')
                  }
                />
              )}
            />
          </Grid>
        </>
      )}

      {showOptionalFields && kind === 'Person' && (
        <>
          <Grid item xs={4}>
            <Controller
              name="ssn"
              control={control}
              render={({ field: { ref, onBlur, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  disabled={helpers!.middle_name.disabled}
                  label="SSN"
                  handleBlur={() => {
                    const error = !!errors.first_name || !!errors.last_name;

                    if (!error && helpers!.ssn && helpers!.ssn.onBlur) {
                      helpers!.ssn.onBlur(onBlur);
                    }
                  }}
                  error={!!errors.ssn}
                  helperText={errors.ssn?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name="birth"
              control={control}
              render={({ field: { ref, onBlur, value, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  disableToolbar={false}
                  label={t('users:date-of-birth')}
                  value={value as string | Date}
                  handleBlur={() => {
                    const error = !!errors.first_name || !!errors.last_name;

                    if (!error && helpers!.birth && helpers!.birth.onBlur) {
                      helpers!.birth.onBlur(onBlur);
                    }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name="gender"
              control={control}
              render={({ field: { ref, onChange, onBlur, ...field } }) => (
                <SelectField
                  {...field}
                  inputRef={ref}
                  data={genders}
                  dataKey="value"
                  dataValue="value"
                  dataText="text"
                  label={t('users:gender')}
                  handleChange={onChange}
                  handleBlur={() => {
                    const error = !!errors.first_name || !!errors.last_name;

                    if (!error && helpers!.gender && helpers!.gender.onBlur) {
                      helpers!.gender.onBlur(onBlur);
                    }
                  }}
                  error={!!errors.gender}
                  helperText={errors.gender?.message}
                />
              )}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

FormPersonalInformation.defaultProps = {
  showTitle: true,
  shouldValidate: false,
  showOptionalFields: true,
  helpers: {
    first_name: {},
    last_name: {},
    middle_name: {},
  },
};

export default FormPersonalInformation;
