import { TFunction } from 'i18next';
import * as Yup from 'yup';

const schema = (t: TFunction) => Yup.object({
  first_name: Yup.string().required(t('validations:required')),
  last_name: Yup.string().required(t('validations:required')),
  addresses: Yup.array().of(Yup.object({
    state: Yup.string().required(t('validations:required')),
    postal_code: Yup.string().required(t('validations:required')),
    street_address: Yup.string().required(t('validations:required')),
  })),
  emails: Yup.array().of(Yup.object({
    address: Yup.string()
      .email(t('validations:invalidEmail'))
      .required(t('validations:required')),
    kind: Yup.string().matches(/^(?!login$).*$/i, {
      message: t('validations:email-kind-invalid'),
      excludeEmptyString: true,
    }).required(t('validations:required')),
  })),
  phones: Yup.array().of(Yup.object({
    number: Yup.string().matches(/\d{10}/, "Phone number is not valid.").max(10, "Phone number is too long").required(t('validations:required')),
    kind: Yup.string().required(t('validations:required')),
  })),
  licenses: Yup.array().of(Yup.object({
    number: Yup.string().required(t('validations:required')),
    state: Yup.string().required(t('validations:required')),
  })),
  parties: Yup.array().of(Yup.object({ kind: Yup.string().required(t('validations:required')) })),
});

export default schema;
