import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

type TitleProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
}));

const Title = ({ children }: TitleProps) => {
  const classes = useStyles();

  return (
    <Typography variant="h6" className={classes.title}>
      {children}
    </Typography>
  );
};

export default Title;
