import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
    padding: theme.spacing(3),
    height: 'calc(92vh - 65px - 90px)'
  },
  mainContainer: {
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: 23,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    padding: theme.spacing(3),

  },
  narrativeContainer: { padding: theme.spacing(3), maxHeight: '100%', overflowY: 'scroll', },
  narrativeText: { whiteSpace: 'pre-wrap', lineHeight: 1.3 }
}));
