import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  paddingContent: { paddingTop: theme.spacing(3) },
  divider: {
    padding: `0px ${theme.spacing(5)}px`,
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey,
    backgroundColor: '#D1B74F'
  },
  titleagentlist: { marginTop: theme.spacing(3) },
  suubtitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
  tabsContainer: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  selectedagent: {
    backgroundColor: theme.palette.button.default,
    textTransform: 'none',
  },
  unseLectedagent: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'none',
  },
  buttonLetter: {
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  iconSize: { fontSize: '0.875rem' },
  buttonDelete: { textTransform: 'none' },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2)
  }
}));
