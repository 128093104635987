import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles((theme:any) => ({
  itemtitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    marginLeft: theme.spacing(1)
  },
  itemiconConainer: { marginBottom: theme.spacing(2) },
}));

type itemIconProps ={
  icon: IconProp,
  text: string
}

const ItemwithIcon: FC<itemIconProps> = ({ icon, text }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="row" className={classes.itemiconConainer}>
      <FontAwesomeIcon icon={icon} />
      <Typography variant="subtitle1" className={classes.itemtitle}>{text}</Typography>
    </Box>
  );
};

export default ItemwithIcon;
