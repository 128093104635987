import {
  Button,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import VoidMeetingsIcon from 'assets/images/no_meeting.png';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  img: {
    width: 124.09,
    height: 59.31,
    marginBottom: 24
  },
  titleVoid: {
    fontWeight: 500,
    textAlign: 'center',
    opacity: 1,
    fontFamily: 'Poppins',
    color: theme.palette.tab.offselected,
    marginBottom: theme.spacing(1)
  },
  voidDescription: {
    fontFamily: 'Roboto',
    marginBottom: theme.spacing(3),
    color: theme.palette.tab.offselected,
  },
  button: {
    textTransform: 'none',
    fontWeight: 500,
    marginBottom: theme.spacing(4),
  },
  formContainer: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelText: {
    textTransform: 'none',
    fontWeight: 500,
    color: theme.palette.common.black
  },
}));
type VoidMeetingsType = {
  t:TFunction,
  onClickSchedule: ()=>void
}

const VoidMeetings: FC<VoidMeetingsType> = ({
  t,
  onClickSchedule
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.formContainer}>
      <img alt="no meetings" src={VoidMeetingsIcon} className={classes.img} />
      <Typography variant="h6" className={classes.titleVoid}>
        {t('ron:you-have-no-meetings')}
      </Typography>
      <Typography variant="caption" className={classes.voidDescription}>
        {t('ron:here-you-will')}
      </Typography>
      <Button
        onClick={onClickSchedule}
        className={classes.button}
        disableElevation
        color="secondary"
        variant="contained"
      >
        <Typography variant="body2" className={classes.cancelText}>
          {t('ron:schedule-meeting')}
        </Typography>
      </Button>
    </Grid>
  );
};

export default VoidMeetings;
