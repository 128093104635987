import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { TFunction } from 'i18next';
import React from 'react';

import Header from './components/layout-header/layout-header';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    padding: 0,
  },
  content: {
    flexGrow: 1,
    width: 'calc(100% - 240px)',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(30),
    height: theme.spacing(8),
    justifyContent: 'space-between',
  },
}));

type AuthenticatedLayoutSceneProps = {
  t: TFunction;
  agencySelected: string;
  selectAgency: (agencyId: string) => void;
  location: any;
  userIsTitleAdmin: boolean;
  currentLanguage: string;
  languageAnchorEl: HTMLElement | null;
  userLetters: string;
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  handleRoute: (path: string) => void;
  handleLanguageClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleLanguage: (language?: string | undefined) => void;
  handleProfileMenuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleNavigateProfile: () => void;
  handleNavigateTermsOfService: () => void;
  handleLogout: () => void;
  handleMenuClose: (path?: string) => void;
  children: React.ReactNode;
};

const AuthenticatedLayoutScene = ({
  t,
  agencySelected,
  selectAgency,
  location,
  userIsTitleAdmin,
  currentLanguage,
  languageAnchorEl,
  userLetters,
  anchorEl,
  isMenuOpen,
  handleRoute,
  handleLanguageClick,
  handleLanguage,
  handleProfileMenuOpen,
  handleNavigateProfile,
  handleNavigateTermsOfService,
  handleLogout,
  handleMenuClose,
  children,
}: AuthenticatedLayoutSceneProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />

      {!location.pathname.includes('/file') && (
        <Header
          t={t}
          agencySelected={agencySelected}
          selectAgency={selectAgency}
          location={location}
          userIsTitleAdmin={userIsTitleAdmin}
          currentLanguage={currentLanguage}
          languageAnchorEl={languageAnchorEl}
          userLetters={userLetters}
          anchorEl={anchorEl}
          isMenuOpen={isMenuOpen}
          handleRoute={handleRoute}
          handleLanguageClick={handleLanguageClick}
          handleLanguage={handleLanguage}
          handleProfileMenuOpen={handleProfileMenuOpen}
          handleNavigateProfile={handleNavigateProfile}
          handleNavigateTermsOfService={handleNavigateTermsOfService}
          handleLogout={handleLogout}
          handleMenuClose={handleMenuClose}
        />
      )}

      <main className={classes.content}>
        {!location.pathname.includes('/file') && (
          <div className={classes.toolbar} />
        )}

        <div>{children}</div>
      </main>
    </div>
  );
};

export default AuthenticatedLayoutScene;
