import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const LoaderListNotes = () => (
  <>
    {Array.from(Array(5).keys()).map((key) => (
      <Grid item key={key}>
        <Skeleton style={{ transform: 'none', marginBottom: '10px' }} height={70} />
      </Grid>
    ))}
  </>
);
