import { AddAndUpdatePartyLedgerEntry as AddPartyEntry } from 'components/add-and-update-party-ledger-modal/add-and-update-party-ledger-entry';
import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import { EntryLedgerType, } from 'components/add-entry-modal/services/types';
import { DeleteConfirmationModal } from 'components/delete-confirmation';
import React, { FC, useCallback } from 'react';
import { DisabledFields } from 'types/disabledFields';
import { TAXES } from 'utils/constants';
import { AccountingFilter, AccountingType } from 'v2-types/order';

import ModalSelectPayeePayer from '../modal-select-payee-payer';
import { AddEntryLinkedModal } from './components/add-entry-linked-modal';
import { RemoveAssociationModal } from './components/remove-association-modal';
import useTableCharges from './new-table-charges-hooks';
import TableChargesScene from './new-table-charges-scene';
import { getDisabledFields } from './services';

type ChargesTableProps = {
  updateAccounting: (accunting: AccountingType[]) => void;
  accountingData: AccountingType[];
  handleClickItem?: (item: any) => void;
  itemSelected?: any | null;
  chargesIdentifier: AccountingFilter | string;
  customColumnsFunction?: any;
  customFooterfunction?: any;
  customHideFieldsAddingEntry?: DisabledFields<EntryLedgerType> | undefined;
  customHideFieldsEditEntry?: DisabledFields<EntryLedgerType> | undefined;
};

const ChargesTable: FC<ChargesTableProps> = ({
  updateAccounting,
  accountingData,
  handleClickItem,
  itemSelected,
  chargesIdentifier,
  customColumnsFunction,
  customFooterfunction,
  customHideFieldsAddingEntry,
  customHideFieldsEditEntry,
}) => {
  const {
    columns,
    mapedData,
    footertable,
    footerTableSet,
    resolver,
    itemsCollapsed,
    addDisbursement,
    state,
    dispatch,
    showRemoveAssociationModal,
    isRemoveAssociationLoading,
    removeAssociation,
    showEditAssociationModal,
    payerPayeePartyEntryModalProps,
    deleteReceipt,
    isDeleteLinkLoding,
    showDeleteReceiptModalConfirm
  } = useTableCharges(
    updateAccounting,
    accountingData,
    itemSelected,
    chargesIdentifier,
    customColumnsFunction,
    customFooterfunction
  );
  const openModal = state.type === 'add' || state.type === 'edit';

  const getHideFields = useCallback(() => {
    if (state.type === 'edit') {
      if (customHideFieldsEditEntry) {
        return customHideFieldsEditEntry;
      }
      return {
        months: true,
        code: true,
        kind: true,
        amount: true,
        months_advanced: true,
        annuality: true,
      };
    }
    if (customHideFieldsAddingEntry) {
      return customHideFieldsAddingEntry;
    }
    return {
      months: true,
      amount_override: true,
      months_advanced: true,
      annuality: true,
    };
  }, [customHideFieldsAddingEntry, customHideFieldsEditEntry, state.type]);

  const disablefields = getDisabledFields(state.data);
  return (
    <>
      <TableChargesScene
        columns={columns}
        mapedData={mapedData}
        footertable={footertable}
        footerTableSet={footerTableSet}
        handleClickItem={handleClickItem}
        itemsCollapsed={itemsCollapsed}
        addDisbursement={addDisbursement}
        showRemoveAssociationModal={showRemoveAssociationModal}
        showEditAssociationModal={showEditAssociationModal}
        showDeleteReceiptModalConfirm={showDeleteReceiptModalConfirm}
      />
      {openModal && (
        <AddLedgerEntry
          open={!!openModal}
          handleClose={() => {
            dispatch({ type: null });
          }}
          onSuccesAddEntry={updateAccounting}
          prevData={
            state.type === 'edit'
              ? state?.data?.chargeData
              : ({ letter: chargesIdentifier } as AccountingType)
          }
          kindAllowedEntry={['manual']}
          codeAllowedEntry={['charge']}
          resolver={resolver}
          hideFields={getHideFields()}
          disableFields={disablefields}
          isEditingEntry={state.type === 'edit'}
        />
      )}
      {state.type === 'disbursements' && (
        <AddEntryLinkedModal
          open
          accountingId={state.data.accountingId}
          chargeDescription={state.data.description}
          partyId={state.data.partyId}
          kind={state.data.kind}
          onCloseModal={() => {
            dispatch({ type: null });
          }}
          onAddEntrySuccess={(data) => updateAccounting(data)}
          accountingFilter={chargesIdentifier as AccountingFilter}
        />
      )}
      {state.type === 'parties' && (
        <ModalSelectPayeePayer
          accountingID={state.data?.chargeData._id}
          open={state.type === 'parties'}
          handleClose={() => {
            dispatch({ type: null });
          }}
          handleUpdateAccounting={(data) => updateAccounting(data)}
        />
      )}
      {state.type === 'tax' && (
        <AddPartyEntry
          handleClose={() => {
            dispatch({ type: null });
          }}
          handleSuccess={(data) => updateAccounting(data)}
          entryLedger={state.data.chargeData}
          kindAllowedAssociationList="tax"
          partyLedgerEntry={undefined}
          partyKindFilter={[TAXES]}
        />
      )}
      {state.type === 'individual-payee-payer' && (
        <AddPartyEntry
          {...payerPayeePartyEntryModalProps}
        />
      )}
      {state.type === 'remove-association' && (
        <RemoveAssociationModal
          parties={state.data.row.chargeData.involved_parties}
          kindList={[state.data.kind]}
          onClose={() => { dispatch({ type: null }); }}
          accountingId={state.data.row.chargeData._id}
          title="Remove Payees"
          removeSuccessMessage="The Payee was succesfully removed"
          accountingFilter={chargesIdentifier as AccountingFilter}
          onDeleteAssociationSuccess={(data) => updateAccounting(data)}
        />
      )}
      {state.type === 'remove-association-payer-confirmation' && (
        <DeleteConfirmationModal
          title="Remove Payer"
          confirmationMessage="Are you sure you want to remove the payer from the disbursement?"
          isOpen
          isRemoveLoading={isRemoveAssociationLoading}
          onClose={() => { dispatch({ type: null }); }}
          onConfirm={removeAssociation}
          buttonConfirmText="Remove"
        />
      )}
      {state.type === 'delete-receipt-confirmation' && (
        <DeleteConfirmationModal
          title="Delete Receipt"
          confirmationMessage="Are you sure you want to delete the receipt?"
          isOpen
          onClose={() => { dispatch({ type: null }); }}
          isRemoveLoading={isDeleteLinkLoding}
          onConfirm={deleteReceipt}
        />
      )}
    </>
  );
};

export default ChargesTable;
