import { Box, Input, Theme } from '@material-ui/core';
import { ColumnsType } from 'components/table-skeleton/types';
import { CashToCloseData } from 'hooks/useGetCashToClose/types';
import { TFunction } from 'i18next';
import React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { Description } from '../components/description';
import { MoneyField } from '../components/MoneyField';
import { SelectYesNo } from '../components/SelectYesNo';
import { CashToCloseEntryRow } from '../types';

type Params = {
  t: TFunction,
  theme: Theme,
  props: any,
  control: Control<CashToCloseData, object>,
  setValue: UseFormSetValue<CashToCloseData>,
  isUpdateLoading: boolean
}

export const generateCashToCloseColumns = ({
  t,
  theme,
  props,
  control,
  setValue,
  isUpdateLoading
}: Params): ColumnsType => {
  const columns: ColumnsType = {
    title: {
      header: {
        title: '',
        width: '250px',
      },
      renderer: ({ row }: { row: CashToCloseEntryRow }) => (
        <Description text={row.title} />
      ),
    },
    loan_estimate: {
      header: {
        title: t('cashToClose:loan-estimate-table-title'),
        align: 'center',
        width: '150px',
        textColor: theme.palette.primary.light
      },
      renderer: ({ row }: { row: CashToCloseEntryRow }) => (
        <Controller
          control={control}
          name={`${row.fieldName}.estimate_amount`}
          render={({ field }) => (
            <MoneyField
              value={field.value}
              onChange={field.onChange}
              isDisabled={isUpdateLoading}
            />
          )}
        />
      ),
    },
    final: {
      header: {
        title: t('cashToClose:final'),
        align: 'center',
        width: '150px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: { row: CashToCloseEntryRow }) => (
        <Controller
          control={control}
          name={`${row.fieldName}.final`}
          render={({ field }) => (
            <MoneyField
              isDisabled
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      ),
    },
    did_this_charge: {
      header: {
        title: t('cashToClose:did-this-change'),
        align: 'center',
        width: '100%',
        minWidth: theme.spacing(60).toString(),
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: { row: CashToCloseEntryRow }) => (
        <Box display="flex" gridGap="10px" className={props.classNames?.didThisChangeColumnInputs}>
          <Controller
            control={control}
            name={`${row.fieldName}.changed`}
            render={({ field }) => (
              <>
                <SelectYesNo
                  value={field.value}
                  onChange={(isEnabled) => {
                    if (!isEnabled) {
                      setValue(`${row.fieldName}.changed_text`, '', { shouldDirty: true });
                    }
                    field.onChange(isEnabled);
                  }}
                  isDisabled={isUpdateLoading}
                />

                <Controller
                  control={control}
                  name={`${row.fieldName}.changed_text`}
                  render={({ field: textField }) => (
                    <Input
                      className={props.classNames.inputClassName}
                      disabled={!field.value || isUpdateLoading}
                      value={textField.value}
                      classes={{ disabled: props.classNames.disabledClass }}
                      onChange={textField.onChange}
                    />
                  )}
                />
              </>
            )}
          />
        </Box>
      ),
    },
  };

  return columns;
};
