import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FileRequired } from 'views/document-request/types';

type UseDocumentRequestItemHooksParams = {
  fileRequired: FileRequired,
  onAddFile: ({ archiveId, file }: { archiveId: string, file: File }) => void,
  onRemoveFile: ({ archiveId, fileName }: { archiveId: string, fileName: string }) => void,
}

export const useDocumentRequestItemHooks = ({
  fileRequired,
  onAddFile,
  onRemoveFile
}: UseDocumentRequestItemHooksParams) => {
  const { t } = useTranslation();
  const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
    maxSize: 31457280,
    // eslint-disable-next-line
    accept: 'image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet',
    noClick: false,
    noKeyboard: true,
    onDropAccepted: (files) => {
      files.forEach((file) => onAddFile({ archiveId: fileRequired.archive_id, file }));
    },
  });

  const removeFile = ({ file }: { file: File }) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    onRemoveFile({ archiveId: fileRequired.archive_id, fileName: file.name });
  };

  return {
    t,
    acceptedFiles,
    getInputProps,
    getRootProps,
    removeFile
  };
};
