import { useEffect, useState } from "react";

export const useLocalBlobImage = (filename) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [image, setImage] = useState<Blob>();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const importImage = async () => {
      try {
        const module = await import(`assets/images/${filename}.png`);
        const response = await fetch(module.default);
        const blob = await response.blob();
        setImage(blob);
      } catch (e: any) {
        setError(e.message);
      } finally {
        setIsLoading(false);
      }
    };
    importImage();
  }, [filename]);

  return {
    isLoading,
    image,
    error,
  };
};
