import { gql } from "graphql-request";
import graphQLClient from 'config/graphql-instance';
import { prorationsQuery } from "./queries";
import { Proration, ScheduleProrationEnum } from "v2-types/order";

type CreateProrationParams = {
  orderId: string,
  estateId: string,
  description: string,
  kind: 'installment' | 'per_diem',
  type: 'basic' | 'tax'
}

export type CreateProrationReturn = {
  createOrderProration: {
    prorations: Proration[]
  }
}

export const createProration = async (payload: CreateProrationParams) => {
  const query = gql`
    mutation CreateOrderProration($orderId: ID!, $estateId: ID!, $description: String!, $kind: OrderProrationKind!, $type: OrderProrationType!) {
      createOrderProration(_id: $orderId, estate_id: $estateId, description: $description, kind: $kind, type: $type) {
        ${prorationsQuery}
      }
    }
  `;
  const response = await graphQLClient().request<CreateProrationReturn, CreateProrationParams>(query, payload);
  return response;
};

export type UpdateProrationParams = {
  orderId: string,
  prorationId: string,
  data: {
    kind: 'installment' | 'per_diem',
    type: 'basic' | 'tax'
    days_year: number,
    debited_party: 'buyer_owes_seller' |'seller_owes_buyer',
    decimals: number,
    end_date: string,
    impound_disclosure: boolean,
    include_1099: boolean,
    next_due: string,
    paid_thru: string,
    proration_date: string,
    schedule: ScheduleProrationEnum,
    start_date: string,
  }
}

export type UpdateProrationReturn = {
  updateOrderProration: {
    prorations: Proration[]
  }
}

export const updateProration = async (payload: UpdateProrationParams) => {
  const query = gql`
    mutation UpdateOrderProration($orderId: ID!, $prorationId: ID!, $data: OrderProrationContent!) {
      updateOrderProration(_id: $orderId, proration_id: $prorationId, data: $data) {
        ${prorationsQuery}
      }
    }
  `;
  const response = await graphQLClient().request<UpdateProrationReturn, UpdateProrationParams>(query, payload);
  return response;
};

export type DeleteProrationParams = {
  orderId: string,
  prorationId: string
}

export type DeleteProrationReturn = {
  deleteOrderProration: {
    prorations: Proration[]
  }
}

export const deleteProration = async (payload: DeleteProrationParams) => {
  const query = gql`
    mutation DeleteOrderProration($orderId: ID!, $prorationId: ID!) {
      deleteOrderProration(_id: $orderId, proration_id: $prorationId) {
        ${prorationsQuery}
      }
    }
  `;
  const response = await graphQLClient().request<DeleteProrationReturn, DeleteProrationParams>(query, payload);
  return response;
};
