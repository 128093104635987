import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme:any) => ({
  drawLine: {
	  paddingLeft: theme.spacing(7),
	  position: 'relative',
	  '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: theme.spacing(2),
      bottom: '50%',
      width: theme.spacing(5),
      border: `1px solid ${theme.palette.border.line}`,
      borderTop: '0 none transparent',
      borderRight: '0 none transparent'
	  },
  },
  titleSection: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
    marginRight: theme.spacing(1)
  },
  cardContainer: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    padding: 12,
    border: `1px solid ${theme.palette.border.line}`,
    backgroundColor: theme.palette.background.default,
    gap: theme.spacing(1.5)
  },
  actionContainer: {
    display: 'flex',
    gap: '10px',
  },
  informationContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap'
  }
}));
