import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useYupValidationResolver } from 'utils/ledger/use-yup-resolver';
import { AccountingType } from 'v2-types/order';

import {
  initialstateModals,
  reducerModals,
  sortedAccounting,
  TableLedgerItemColumn,
  validationSchema
} from './services';
import { getOrder } from './services/querys';
import useMutationsData from './services/useMutationsdata';

const useAccounting = () => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const [state, dispatch] = useReducer(reducerModals, initialstateModals);
  const resolver = useYupValidationResolver(validationSchema);
  const getOrderAccounting = useQuery(['accounting-order', orderId],
    () => getOrder(orderId),
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setAccounting(sortedAccounting(data.getOrder.accounting || []));
        }
      }
    },);

  const {
    deleteAssociation,
    deleteEntry,
    deleteLink
	  } = useMutationsData(setAccounting);

  const handleDeleteLedgerItem = (row:TableLedgerItemColumn) => {
    deleteEntry({
      id: orderId,
      accountingId: row.id
    });
  };
  const handledeleteLink = (order_id:string, paymentRecordId:string, associationKind:string, associationId:string, associationLedgerRow:string) => {
    deleteLink({
      orderId: order_id,
      targetEntryId: associationLedgerRow,
      accountingId: paymentRecordId,
      targetAssociationId: associationId,
      targetAssociationKind: associationKind
    });
  };

  const handleDeleteLedgerParty = (item:any) => {
    const { row, ledgerInfo } = item;
    deleteAssociation({
      id: orderId,
      accountingId: ledgerInfo.id as string,
      kind: row.kind,
      partyId: row._id
    });
  };

  return {
    t,
    getOrderAccounting,
    accounting,
    setAccounting,
    handleDeleteLedgerItem,
    handleDeleteLedgerParty,
    resolver,
    handledeleteLink,
    state,
    dispatch
  };
};

export default useAccounting;
