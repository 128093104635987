import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Logostar from 'assets/images/star-circle.svg';
import clsx from 'clsx';
import { EmptyConversationContent } from 'components/inference/components/empty-conversation-content';
import { MessageContent } from 'components/inference/components/message-content';
import { SourceInfoModal } from 'components/inference/components/source-info-modal';
import { useAutoscroll } from 'hooks/useAutoscroll';
import { useInference } from 'hooks/useInference';
import { TFunction } from 'i18next';
import React, { useRef, useState } from 'react';

import Autocomplete from './components/autocomplete';
import { MenuProfile } from './components/menu-profile';
import NotificationsPopup from './components/notifications-popup';
import SnackBar from './components/snanckBar';
import useLayoutHeader from './layout-header-hooks';

const useStyles = makeStyles((theme: any) => ({
  appBarShift: {
    width: '100%',
    boxShadow: 'none',
    marginLeft: theme.spacing(30),
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbarContainer: {
    paddingLeft: 0,
    paddingRight: theme.spacing(3),
    height: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(30),
    height: theme.spacing(8),
    justifyContent: 'space-between',
  },
  toolbarLogo: {
    width: 64,
    height: 64,
  },
  toolbarSearch: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  navButton: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    borderColor: 'transparent',
    padding: '5px 10px',

    '& .MuiButton-label': { gap: '10px' },
  },
  navButtonActive: { borderColor: theme.accent.main },
  navIcon: { color: theme.palette.dualText.primary },
  navIconActive: { color: theme.accent.main },
  navText: {
    textTransform: 'capitalize',
    color: theme.palette.dualText.primary,
  },
  navTextActive: { color: theme.accent.main },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      display: 'flex',
      gap: '20px',
      marginLeft: 'auto',
    },
  },
  desktopHeaderRoot: {
    '& > *': {
      color: theme.palette.text.primary,
      padding: 0,
      '& .MuiButton-label': { gap: '10px' },
    },
  },
  headerColor: {
    color: theme.palette.dualText.primary,
    textTransform: 'capitalize',
  },
  inferenceContainer: {
    boxSizing: 'border-box',
    margin: theme.spacing(1),
    width: theme.spacing(30),
    padding: `0 ${theme.spacing(0.5)}px`,
    borderRadius: 5,
    border: '1px solid #59666c',
  },
  inferneceActions: { width: '60px' },
  inferenceButton: {
    height: '100%',
    width: '20px',
  },
  inferenceResponseContainer: {
    backgroundColor: '#ffffff',
    zIndex: 10000,
    color: '#000000',
    padding: theme.spacing(2),
    borderRadius: '5px',
    position: 'absolute',
    top: '64px',
    width: '700px',
  },
  avatarContainer: { position: 'relative' },
  stateBubble: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    borderRadius: '50%',
    right: '-3px',
    bottom: '-3px',
    border: `2px solid ${theme.palette.primary.main}`,
    background: 'white',
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`,
    background: theme.palette.secondary.main,
  },
  iconButton: { padding: 0, },
}));

type LayoutHeaderProps = {
  t: TFunction;
  agencySelected: string;
  selectAgency: (agencyId: string) => void;
  location: any;
  userIsTitleAdmin: boolean;
  currentLanguage: string;
  languageAnchorEl: HTMLElement | null;
  userLetters: string;
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  handleRoute: (path: string) => void;
  handleLanguageClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleLanguage: (language?: string | undefined) => void;
  handleProfileMenuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleNavigateProfile: () => void;
  handleNavigateTermsOfService: () => void;
  handleLogout: () => void;
  handleMenuClose: (path?: string) => void;
};

const LayoutHeader = ({
  t,
  agencySelected,
  selectAgency,
  location,
  userIsTitleAdmin,
  currentLanguage,
  languageAnchorEl,
  userLetters,
  anchorEl,
  isMenuOpen,
  handleRoute,
  handleLanguageClick,
  handleLanguage,
  handleProfileMenuOpen,
  handleNavigateProfile,
  handleNavigateTermsOfService,
  handleLogout,
  handleMenuClose,
}: LayoutHeaderProps) => {
  const classes = useStyles();

  const {
    options,
    loading,
    updateSearchText,
    profile,
    isConnectedSocket,
    notifications,
    newNotification,
    handleClickNotifications,
    anchorNotifications,
    openNotifications,
    handleCloseNotifications,
  } = useLayoutHeader();

  const [showConversation, setShowConversation] = useState<boolean>(false);
  const {
    isRecording,
    startRecordAudio,
    stopRecordAudio,
    messageList,
    sources,
    setSources,
    isInferenceAudioLoading,
    sendQuestion,
    toogleAudioEnable,
    playSpeech,
    stopSpeech,
    currentPlaying,
    isSpeechLoading,
  } = useInference({
    onConversationUpdated() {
      setShowConversation(true);
    },
  });
  const { htmlContainerRef } = useAutoscroll();
  const questionInputRef = useRef<HTMLInputElement>(null);
  const [audioEnabled, setAudioEnabled] = useState(false);

  return (
    <>
      <AppBar position="fixed" className={classes.appBarShift}>
        <Toolbar className={classes.toolbarContainer}>
          <div className={classes.toolbar}>
            <div>
              <img alt="logo" src={Logostar} className={classes.toolbarLogo} />
            </div>

            <Divider orientation="vertical" />

            <div className={classes.toolbarSearch}>
              <Autocomplete
                t={t}
                options={options}
                loading={loading}
                updateSearchText={updateSearchText}
              />
            </div>

            <Divider orientation="vertical" />
          </div>

          <div>
            <Grid container direction="column" spacing={0}>
              <Grid
                container
                className={classes.inferenceContainer}
                wrap="nowrap"
              >
                <Grid item>
                  <ButtonBase
                    className={classes.inferenceButton}
                    onClick={() => setShowConversation((show) => !show)}
                  >
                    <FontAwesomeIcon
                      icon={showConversation ? 'caret-up' : 'caret-down'}
                      color="#ffffff"
                      size="lg"
                    />
                  </ButtonBase>
                </Grid>
                <Grid item style={{ flex: '1' }}>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Assistant"
                    inputRef={questionInputRef}
                    inputProps={{
                      style: {
                        color: 'white',
                        padding: '5px 2px',
                        fontSize: '0.875rem',
                      },
                      onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          sendQuestion({ text: questionInputRef.current!.value, });
                          questionInputRef.current!.value = '';
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  wrap="nowrap"
                  className={classes.inferneceActions}
                >
                  {isInferenceAudioLoading && (
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                      style={{ width: '40px', height: '100%' }}
                    >
                      <Grid item>
                        <FontAwesomeIcon spin icon="spinner" color="#FB8C00" />
                      </Grid>
                    </Grid>
                  )}
                  {!isInferenceAudioLoading && (
                    <>
                      <Grid item>
                        <ButtonBase
                          className={classes.inferenceButton}
                          onClick={() => {
                            if (questionInputRef.current!.value.length > 0) {
                              sendQuestion({ text: questionInputRef.current!.value, });
                              questionInputRef.current!.value = '';
                            }
                          }}
                        >
                          <FontAwesomeIcon icon="paper-plane" />
                        </ButtonBase>
                      </Grid>
                      <Grid item>
                        <ButtonBase
                          className={classes.inferenceButton}
                          onClick={
                            isRecording ? stopRecordAudio : startRecordAudio
                          }
                        >
                          <FontAwesomeIcon
                            color={isRecording ? 'tomato' : '#ffffff'}
                            icon={isRecording ? 'circle' : 'microphone'}
                          />
                        </ButtonBase>
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <ButtonBase
                      className={classes.inferenceButton}
                      onClick={() => {
                        setAudioEnabled((enabled) => !enabled);
                        toogleAudioEnable();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={audioEnabled ? 'volume-up' : 'volume-mute'}
                      />
                    </ButtonBase>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Paper
              className={classes.inferenceResponseContainer}
              elevation={3}
              hidden={!showConversation}
            >
              <Grid
                item
                container
                style={{
                  width: '100%',
                  overflow: 'scroll',
                  overflowX: 'hidden',
                  scrollbarWidth: 'thin',
                  boxSizing: 'border-box',
                  height: '100%',
                  maxHeight: '60vh',
                  gap: '12px',
                }}
                direction="column"
                wrap="nowrap"
                ref={htmlContainerRef}
              >
                {messageList.length === 0 && !isInferenceAudioLoading && (
                  <EmptyConversationContent iconSize="5x" />
                )}
                {messageList.map((message) => (
                  <MessageContent
                    message={message}
                    onViewSourceClick={() => setSources(message.sources!)}
                    onPlaySpeech={playSpeech}
                    onStopSpeech={stopSpeech}
                    isAudioPlaying={currentPlaying === message.id}
                    isAudioLoading={isSpeechLoading}
                  />
                ))}
                {isInferenceAudioLoading && (
                  <Grid
                    container
                    justifyContent="center"
                    style={{ padding: '10px' }}
                  >
                    <Grid item>
                      <FontAwesomeIcon
                        spin
                        icon="spinner"
                        size="2x"
                        color="#FB8C00"
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>

            {sources.length > 0 && (
              <SourceInfoModal
                sources={sources}
                onClose={() => setSources([])}
                t={t}
              />
            )}
          </div>
          <Divider orientation="vertical" />

          <Box className={classes.navContainer}>
            <Button
              variant="outlined"
              className={clsx(classes.navButton, { [classes.navButtonActive]: location.pathname === '/orders', })}
              onClick={() => handleRoute('/orders')}
            >
              <FontAwesomeIcon
                size="1x"
                icon="archive"
                className={clsx(classes.navIcon, { [classes.navIconActive]: location.pathname === '/orders', })}
              />

              <Typography
                variant="body2"
                className={clsx(classes.navText, { [classes.navTextActive]: location.pathname === '/orders', })}
              >
                {t('common:orders')}
              </Typography>
            </Button>

            <Button
              variant="outlined"
              className={clsx(classes.navButton, {
                [classes.navButtonActive]:
                  location.pathname.includes('/orders/details'),
              })}
              onClick={() => handleRoute('/orders/details')}
            >
              <FontAwesomeIcon
                size="1x"
                icon="folder-open"
                className={clsx(classes.navIcon, {
                  [classes.navIconActive]:
                    location.pathname.includes('/orders/details'),
                })}
              />

              <Typography
                variant="body2"
                className={clsx(classes.navText, {
                  [classes.navTextActive]:
                    location.pathname.includes('/orders/details'),
                })}
              >
                {t('common:workspace')}
              </Typography>
            </Button>

            {userIsTitleAdmin && (
              <>
                <Button
                  variant="outlined"
                  className={clsx(classes.navButton, {
                    [classes.navButtonActive]:
                      location.pathname.includes('/contacts'),
                  })}
                  onClick={() => handleRoute('/contacts')}
                >
                  <FontAwesomeIcon
                    size="1x"
                    icon="address-book"
                    className={clsx(classes.navIcon, {
                      [classes.navIconActive]:
                        location.pathname.includes('/contacts'),
                    })}
                  />

                  <Typography
                    variant="body2"
                    className={clsx(classes.navText, {
                      [classes.navTextActive]:
                        location.pathname.includes('/contacts'),
                    })}
                  >
                    {t('common:contacts')}
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  className={clsx(classes.navButton, {
                    [classes.navButtonActive]: location.pathname.includes(
                      '/document-templates'
                    ),
                  })}
                  onClick={() => handleRoute('/document-templates')}
                >
                  <FontAwesomeIcon
                    size="1x"
                    icon="file"
                    className={clsx(classes.navIcon, {
                      [classes.navIconActive]: location.pathname.includes(
                        '/document-templates'
                      ),
                    })}
                  />

                  <Typography
                    variant="body2"
                    className={clsx(classes.navText, {
                      [classes.navTextActive]: location.pathname.includes(
                        '/document-templates'
                      ),
                    })}
                  >
                    {t('documents:document-templates')}
                  </Typography>
                </Button>
              </>
            )}

            <Button
              variant="outlined"
              className={clsx(classes.navButton, { [classes.navButtonActive]: location.pathname === '/notes', })}
              onClick={() => handleRoute('/notes')}
            >
              <FontAwesomeIcon
                size="1x"
                icon="book"
                className={clsx(classes.navIcon, { [classes.navIconActive]: location.pathname === '/notes', })}
              />

              <Typography
                variant="body2"
                className={clsx(classes.navText, { [classes.navTextActive]: location.pathname === '/notes', })}
              >
                {t('notes:notes')}
              </Typography>
            </Button>
          </Box>

          <div className={classes.sectionDesktop}>
            <IconButton
              className={classes.iconButton}
              color="inherit"
              onClick={handleClickNotifications}
            >
              <Badge
                badgeContent={
                  notifications.filter((notification) => !notification.viewed)
                    .length
                }
                color="secondary"
              >
                <FontAwesomeIcon size="sm" icon="bell" />
              </Badge>
            </IconButton>

            <div className={classes.desktopHeaderRoot}>
              <Button onClick={handleLanguageClick}>
                <FontAwesomeIcon
                  size="lg"
                  icon="language"
                  className={classes.headerColor}
                />
                <Typography variant="subtitle1" className={classes.headerColor}>
                  {currentLanguage}
                </Typography>
              </Button>
            </div>

            <Menu
              keepMounted
              anchorEl={languageAnchorEl}
              open={Boolean(languageAnchorEl)}
              onClose={() => handleLanguage(undefined)}
            >
              <MenuItem onClick={() => handleLanguage('en')}>English</MenuItem>
              <MenuItem onClick={() => handleLanguage('es')}>Español</MenuItem>
            </Menu>

            <IconButton
              className={classes.iconButton}
              color="inherit"
              onClick={handleProfileMenuOpen}
            >
              <Box className={classes.avatarContainer}>
                <Avatar className={classes.headerColor}>{userLetters}</Avatar>
                <Box
                  className={clsx({
                    [classes.stateBubble]: true,
                    [classes.active]: isConnectedSocket,
                  })}
                />
              </Box>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <MenuProfile
        isConnectedSocket={isConnectedSocket}
        anchorEl={anchorEl}
        open={isMenuOpen}
        handleClose={handleMenuClose}
        userLetters={userLetters}
        profile={profile}
        handleNavigateProfile={handleNavigateProfile}
        handleNavigateTermsOfService={handleNavigateTermsOfService}
        handleLogout={handleLogout}
        selectAgency={selectAgency}
        agencySelected={agencySelected}
        t={t}
      />

      <NotificationsPopup
        anchorNotifications={anchorNotifications}
        openNotifications={openNotifications}
        handleCloseNotifications={handleCloseNotifications}
        notifications={notifications}
        t={t}
      />

      {newNotification && <SnackBar t={t} notification={newNotification} />}
    </>
  );
};

export default LayoutHeader;
