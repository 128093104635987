import React from 'react';
import { Redirect } from 'react-router-dom';

import BackdropLoading from '../../components/common/BackdropLoading';
import useNotFound from './use-not-found';

const NotFound = () => {
  const { isAuthenticated, isLoading } = useNotFound();
  if (isLoading) return <BackdropLoading open loadingText="loading" />;

  return <Redirect to={isAuthenticated ? '/orders' : '/sign-in'} />;
};

export default NotFound;
