import IconWithTooltip from 'components/common/icon-with-tootip';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useAlert from 'utils/alert';

type ItemTagTextOptionProps = {
  tagId: string;
  isSignature?: boolean;
};

export const ItemTagTextOption: FC<ItemTagTextOptionProps> = ({
  tagId,
  isSignature,
}) => {
  const showAlert = useAlert();
  const { t } = useTranslation();

  const copyTagCode = useCallback(async () => {
    try {
      const tagCode = isSignature ? `[${tagId}]` : `{{${tagId}}}`;
      await navigator.clipboard.writeText(tagCode);
      showAlert(t('documents:tag-copied'), 'success');
    } catch (err: any) {
      showAlert(err.message, 'error');
    }
  }, []);

  return (
    <IconWithTooltip
      tooltipText={t('documents:copy-text')}
      icon="copy"
      onClick={copyTagCode}
    />
  );
};

ItemTagTextOption.defaultProps = { isSignature: false };
