import { createStyles, makeStyles } from '@material-ui/core';

type StylesProps<T> = {
  value: T | string | undefined
}

export function useStyles<T>(props: StylesProps<T>) {
  const classes = makeStyles((theme: any) => {
    const styles = createStyles({
      select: () => {
        const hasValueSelected = props.value !== undefined && (props.value as string) !== '';
        if (hasValueSelected) {
          return ({
            '& .MuiInputBase-input': {
              border: '1.2px solid #000000',
              borderRadius: '5px',
              padding: theme.spacing(1),
              paddingRight: theme.spacing(4),
              fontSize: '0.875rem',
              color: '#ffffff',
              backgroundColor: '#263238'
            },
            '& .MuiSelect-icon': {
              top: 0,
              borderLeft: '1.2px solid black',
              height: '100%',
              color: '#ffffff',
            },
          });
        }

        return ({
          '& .MuiInputBase-input': {
            border: '1.2px solid #000000',
            borderRadius: '5px',
            padding: theme.spacing(1),
            paddingRight: theme.spacing(4),
            fontSize: '0.875rem',
            color: '#000000'
          },
          '& .MuiSelect-icon': {
            top: 0,
            borderLeft: '1.2px solid black',
            height: '100%'
          },
        });
      }
    });

    return styles;
  });
  return classes();
}
