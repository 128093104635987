import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { createPartyEmail, deletePartyEmail, updatePartyEmail } from 'graphql/parties/mutations';
import { getUser } from 'graphql/users/queries';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import graphql from 'services/querys';
import { NewUserType } from 'v2-types/user';
import * as Yup from 'yup';

import useMutations from './services/useMutations';

const useProfile = () => {
  const { t } = useTranslation();
  const { isAuthenticated, user } = useAuth0();

  const schema = Yup.object({
    first_name: Yup.string().required(t('validations:required')),
    last_name: Yup.string().required(t('validations:required')),
    addresses: Yup.array().of(Yup.object({
      state: Yup.string().required(t('validations:required')),
      postal_code: Yup.string().required(t('validations:required')),
      street_address: Yup.string().required(t('validations:required')),
    })),
    emails: Yup.array().of(Yup.object({
      address: Yup.string()
        .email(t('validations:invalidEmail'))
        .required(t('validations:required')),
      kind: Yup.string().matches(/^(?!login$).*$/i, {
        message: t('validations:email-kind-invalid'),
        excludeEmptyString: true
      }).required(t('validations:required')),
    })),
    phones: Yup.array().of(Yup.object({
      number: Yup.string().required(t('validations:required')),
      kind: Yup.string().required(t('validations:required')),
    })),
    licenses: Yup.array().of(Yup.object({
      number: Yup.string().required(t('validations:required')),
      state: Yup.string().required(t('validations:required')),
    })),
  });

  const methods = useForm<NewUserType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [fetch, setFetch] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);

  const getCurrentUser = useQuery(
    ['getCurrentUser'],
    getUser,
    {
      enabled: isAuthenticated && fetch,
      refetchOnWindowFocus: false,
    },
  );

  const [updateParty, responseUpdateParty] = useMutation(graphql.updateParty);
  const [updateAddress, responseUpdateAddress] = useMutation(graphql.updateAddress);
  const [updateEmail, responseUpdateEmail] = useMutation(updatePartyEmail);
  const [updatePhone, responseUpdatePhone] = useMutation(graphql.updatePhone);
  const [updateLicense, responseUpdateLicense] = useMutation(graphql.updateLicense);
  const [updatePartyNotifications, responseUpdatePartyNotifications] = useMutation(
    graphql.updatePartyNotifications
  );

  const [createAddress, responseCreateAddress] = useMutation(graphql.createAddress);
  const [createEmail, responseCreateEmail] = useMutation(createPartyEmail);
  const [createPhone, responseCreatePhone] = useMutation(graphql.createPhone);
  const [createLicense, responseCreateLicense] = useMutation(graphql.createLicense);

  const [deleteAddress, responseDeleteAddress] = useMutation(graphql.deleteAddress);
  const [deleteEmail, responseDeleteEmail] = useMutation(deletePartyEmail);
  const [deletePhone, responseDeletePhone] = useMutation(graphql.deletePhone);
  const [deleteLicense, responseDeleteLicense] = useMutation(graphql.deleteLicense);

  useEffect(() => {
    if (isAuthenticated && user?.sub) {
      setFetch(true);
    }
  }, [isAuthenticated, user?.sub]);

  useMutations(
    methods,
    setFetch,
    setErrors,
    getCurrentUser,
    responseUpdateParty,
    responseUpdateAddress,
    responseUpdateEmail,
    responseUpdatePhone,
    responseUpdateLicense,
    responseUpdatePartyNotifications,
    responseCreateAddress,
    responseCreateEmail,
    responseCreatePhone,
    responseCreateLicense,
    responseDeleteAddress,
    responseDeleteEmail,
    responseDeletePhone,
    responseDeleteLicense,
  );

  return {
    t,
    methods,
    errors,
    loading: getCurrentUser.isLoading,
    currentTab,
    setCurrentTab,
    updateParty,
    updateAddress,
    updateEmail,
    updatePhone,
    updateLicense,
    updatePartyNotifications,
    createAddress,
    createEmail,
    createPhone,
    createLicense,
    deleteAddress,
    deleteEmail,
    deletePhone,
    deleteLicense,
  };
};

export default useProfile;
