import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { NewUserType } from 'types/new-user';

import EmptyContainer from './components/empty-container';
import GridResults from './components/grid';
import Header from './components/header';
import List from './components/list';
import { ModalCreateContact } from './components/modal-create-contact/modal-create-contact';
import SearchField from './components/search-field';
import { ColumnsType } from './services';
import { ContactKind } from './types';

const useStyles = makeStyles((theme: any) => ({
  drawerPadding: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(35),
  },
  noDrawerPadding: { padding: theme.spacing(3) },
  mainContainer: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchFieldContainer: { marginTop: theme.spacing(5) },
  resultsContainer: { marginTop: theme.spacing(5) },
  button: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    textTransform: 'capitalize',
    alignSelf: 'flex-end',
  },
  icon: {
    marginLeft: theme.spacing(1),
    fontSize: '0.7rem',
  },
  btnAddContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(5),
  },
}));

type ContactsSceneType = {
  t: TFunction;
  columns: ColumnsType;
  parties: NewUserType[];
  displayMode: 'list' | 'grid';
  searchText: string | undefined;
  detailsDesktop: boolean;
  createModalOpen: boolean;
  handleDisplayMode: (mode: 'list' | 'grid') => void;
  handleSearch: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleEditNav: (id: string) => void;
  handleUserCreateModal: (state: boolean, kind: ContactKind) => void;
  contactKind: ContactKind
};

const ContactsScene: FC<ContactsSceneType> = ({
  t,
  columns,
  parties,
  displayMode,
  searchText,
  detailsDesktop,
  createModalOpen,
  handleDisplayMode,
  handleSearch,
  handleEditNav,
  handleUserCreateModal,
  contactKind
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="column"
        className={clsx({
          [classes.drawerPadding]: detailsDesktop,
          [classes.noDrawerPadding]: !detailsDesktop,
        })}
      >
        <Grid item xs={12}>
          <Header
            t={t}
            displayMode={displayMode}
            handleDisplayMode={handleDisplayMode}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Grid container className={classes.mainContainer}>
              <Grid item xs={12} className={classes.searchFieldContainer}>
                <SearchField
                  t={t}
                  searchText={searchText}
                  handleSearch={handleSearch}
                />
              </Grid>

              {!!parties.length && (
                <Grid item className={classes.btnAddContainer}>
                  <Button
                    disableElevation
                    variant="outlined"
                    color="secondary"
                    size="small"
                    className={classes.button}
                    onClick={() => handleUserCreateModal(true, 'Person')}
                  >
                    {t('users:create-new')}
                    <FontAwesomeIcon
                      icon="plus"
                      size="1x"
                      className={classes.icon}
                    />
                  </Button>
                </Grid>
              )}

              {!parties.length && (
                <Grid item xs={12}>
                  <EmptyContainer
                    t={t}
                    handleUserCreateModal={handleUserCreateModal}
                  />
                </Grid>
              )}

              {!!parties.length && displayMode === 'list' && (
                <Grid item xs={12} className={classes.resultsContainer}>
                  <List
                    parties={parties}
                    columns={columns}
                    handleEditNav={handleEditNav}
                  />
                </Grid>
              )}

              {!!parties.length && displayMode === 'grid' && (
                <Grid item xs={12} className={classes.resultsContainer}>
                  <GridResults
                    t={t}
                    parties={parties}
                    handleEditNav={handleEditNav}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {createModalOpen && (
        <ModalCreateContact
          searchText={searchText}
          open={createModalOpen}
          onClose={() => handleUserCreateModal(false, 'Person')}
          contactKind={contactKind}
        />
      )}
    </>
  );
};

export default ContactsScene;
