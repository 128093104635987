import { gql } from 'graphql-request';
import gqlRequest from 'graphql/generic';
import { NewOrderPartyType } from 'v2-types/order';
import { NewUserType } from 'v2-types/user';

export type GetOrderPartiesType = {
  getOrder: {
    parties: NewOrderPartyType[]
  }
};

type GetOrderPartiesParams = {orderId: string };

const queryGetOrderParties = gql`
  query GetOrder($orderId: ID!) {
    getOrder(_id: $orderId) {
      parties {
        _id
        accounts {
          _id
          reference
          kind
        }
        kinds
        name
      }
    }
  }
`;

export const getOrderParties = (orderId: string) => gqlRequest<GetOrderPartiesType, GetOrderPartiesParams>(queryGetOrderParties, { orderId });

export type GQLGetParty = {
  getParty: NewUserType;
}

export type GQLGetPartyPayload = {
  id: string
}

export const getParty = (payload: GQLGetPartyPayload) => {
  const query = gql`
    query GetParty($id: ID!) {
      getParty(_id: $id) {
        _id
        user_id
        username
        kind
        name
        first_name
        last_name
        middle_name
        birth
        gender
        ssn
        addresses {
          _id
          state
          address
          postal_code
          street_address
          country
          locality
          references
          settlement 
          kind
        }
        emails {
          _id
          address
          kind
        }
        phones {
          _id
          number
          kind
        }
        licenses {
          _id
          state
          number
        }
        parties {
          _id
          name
          kind
        }
        members {
          _id
          kind
          name
        }
        orders {
          _id
          order_id
          address
          buyer
          closing_date
          loan_amount
          purchase_price
          transaction_type
          seller
          status
        }
        payments {
          _id
          kind
          reference
        }
      }
    }
  `;

  return gqlRequest<GQLGetParty, GQLGetPartyPayload>(query, payload);
};
