import React, { FC } from 'react';
import { AccountingType } from 'v2-types/order';

import useLinkentry from './modal-link-entry-hooks';
import ModalLinkScene from './modal-link-entry-scene';

type ModalLinkentryProps = {
	ledgerData:AccountingType[]
	currentLedgerEntry:AccountingType
	open:boolean,
	onClose:()=>void,
	handleUpdateAccounting:(accounting:AccountingType[])=>void
}

const ModalLinkentry: FC<ModalLinkentryProps> = ({
  ledgerData,
  currentLedgerEntry,
  open,
  onClose,
  handleUpdateAccounting,
}) => {
  const {
    methods,
    handeSubmit,
    createErrors,
    loadingCreation
  } = useLinkentry(handleUpdateAccounting, currentLedgerEntry, onClose);

  return (
    <ModalLinkScene
      open={open}
      onClose={onClose}
      ledgerEntries={ledgerData}
      methods={methods}
      handleSubmit={handeSubmit}
      errors={createErrors}
      loadingCreation={loadingCreation}
    />
  );
};

export default ModalLinkentry;
