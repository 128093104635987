import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import BackdropLoading from 'components/common/BackdropLoading';
import { LicenseFormList } from 'components/license-form-list';
import FormAddresses from 'components/users/addresses';
import FormEmails from 'components/users/emails';
import { FormParties } from 'components/users/form-parties';
import FormPersonalInformation from 'components/users/personal-information';
import FormPhones from 'components/users/phones';
import { ConfigurationHelperType, GeneralInformationValuesType } from 'components/users/types';
import { TFunction } from 'i18next';
import React from 'react';
import { FormProvider, UseFormGetValues, UseFormReturn } from 'react-hook-form';
import { NewUserType } from 'v2-types/user';

import Configuration from './components/configuration';
import Header from './components/header';

const useStyles = makeStyles((theme: any) => ({
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rootContainer: { padding: theme.spacing(2) },
  mainContainer: { padding: theme.spacing(2) },
  tabsContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tab: { textTransform: 'capitalize' },
  formContainer: { padding: theme.spacing(2) },
  title: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
  innerFormContainer: { marginBottom: theme.spacing(2) },
  formDivider: {
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[200],
  },
  sidebarContainer: { backgroundColor: theme.palette.primary.main },
}));

type ProfileSceneProps = {
  t: TFunction;
  loading: boolean;
  currentTab: number;
  methods: UseFormReturn<NewUserType, object>;
  errors: string[];
  helpers: (getValues: UseFormGetValues<NewUserType>) => GeneralInformationValuesType & { configuration: ConfigurationHelperType };
  handleOnChange: (_: React.ChangeEvent<{}>, newValue: number) => void;
};

const ProfileScene = ({
  t,
  loading,
  currentTab,
  methods,
  errors,
  helpers,
  handleOnChange,
}: ProfileSceneProps) => {
  const classes = useStyles();

  return (loading ? (
    <BackdropLoading open loadingText="loading" />
  ) : (
    <Grid container direction="column" className={classes.rootContainer}>
      <Grid item xs={12}>
        <Header t={t} />
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid container direction="row">
            {!!errors.length && (
              <Grid item xs={12}>
                <Alert severity="error" classes={{ icon: classes.errorIconContainer }}>
                  {errors.map((error) => (
                    <Typography variant="subtitle1">{error}</Typography>
                  ))}
                </Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container direction="row" className={classes.mainContainer}>
                <Grid item xs={12} className={classes.tabsContainer}>
                  <Tabs value={currentTab} onChange={handleOnChange}>
                    <Tab label={t('users:general-information')} className={classes.tab} />
                    <Tab label={t('users:addresses')} className={classes.tab} />
                    <Tab label={t('users:licenses')} className={classes.tab} />
                    <Tab label={t('users:parties')} className={classes.tab} />
                    <Tab label={t('users:configuration')} className={classes.tab} />
                  </Tabs>
                </Grid>

                <Grid item xs={12}>
                  <Divider orientation="horizontal" />
                </Grid>

                <Grid item xs={12} className={classes.formContainer}>
                  <FormProvider {...methods}>
                    {currentTab === 0 && (
                      <Grid container direction="column" spacing={3} className={classes.innerFormContainer}>
                        <Grid item xs={12}>
                          <FormPersonalInformation t={t} helpers={helpers(methods.getValues)} />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="h6" className={classes.title}>
                            {t('users:contact-information')}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container direction="row" spacing={5}>
                            <Grid item xs={6}>
                              <FormEmails
                                t={t}
                                helpers={helpers}
                              />
                            </Grid>

                            <Grid item xs={6} className={classes.formDivider}>
                              <FormPhones t={t} helpers={helpers(methods.getValues).phones} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {currentTab === 1 && (
                      <FormAddresses t={t} showTitle={false} helpers={helpers(methods.getValues).addresses} />
                    )}

                    {currentTab === 2 && (
                      <LicenseFormList t={t} helpers={helpers(methods.getValues).licenses} />
                    )}

                    {currentTab === 3 && (
                      <FormParties visual t={t} helpers={helpers(methods.getValues).parties} />
                    )}

                    {currentTab === 4 && (
                      <Configuration t={t} helpers={helpers(methods.getValues).configuration} />
                    )}
                  </FormProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ));
};

export default ProfileScene;
