import React, { FC } from 'react';
import { DocumentsType } from 'v2-types/order';

import { AssociationType } from '../../types';
import { useManualDocumentUploadModal } from './manual-document-update-modal-hooks';
import { ManualDocumentUploadModalScene } from './manual-document-update-modal-scene';

type ManualDocumentUploadModalProps = {
  open: boolean;
  parties: AssociationType[];
  documents: DocumentsType[];
  handleClose: () => void;
};
export const ManualDocumentUploadModal: FC<ManualDocumentUploadModalProps> = ({
  open,
  parties,
  documents,
  handleClose,
}) => {
  const {
    t,
    control,
    watch,
    isValid,
    onUploadDocuments,
    openFileDialog,
    onRemoveFile,
    getRootProps,
    getInputProps,
    filesToUpload,
    isLoading,
    setSelectedParty,
    selectedParty,
    setValue
  } = useManualDocumentUploadModal({
    parties,
    documents,
    onClose: handleClose,
  });
  const onPartySelect = ({ partyId }) => {
    setSelectedParty(partyId);
  };

  return (
    <ManualDocumentUploadModalScene
      open={open}
      t={t}
      handleClose={handleClose}
      control={control}
      watch={watch}
      isValid={isValid}
      onPartySelect={onPartySelect}
      onUploadDocuments={onUploadDocuments}
      openFileDialog={openFileDialog}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      filesToUpload={filesToUpload}
      onRemoveFile={onRemoveFile}
      isLoading={isLoading}
      documents={documents}
      selectedParty={selectedParty}
      parties={parties}
      setValue={setValue}
    />
  );
};
