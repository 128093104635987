import { InferenceResponse } from 'graphql/questions-and-answers/queries';

import { ResponseSource } from '../types';

export function getSources({ data }: { data: InferenceResponse}): ResponseSource[] {
  return Object.entries(data.metadata).map(([, metadata]) => ({
    filePath: metadata.file_path,
    creationDate: metadata.creation_date,
    pageLabel: metadata.page_label,
    text: metadata.text,
    fileName: metadata.file_name,
    fileType: metadata.file_type,
    fileSize: metadata.file_size,
    lastModifiedDate: metadata.last_modified_date,
    lastAccessedDate: metadata.last_accessed_date
  }));
}
