import { makeStyles, TableCell, Typography } from '@material-ui/core';
import Switch from 'components/switch';
import { TFunction } from 'i18next';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  addDescField: {
    marginLeft: theme.spacing(3),
    '&>*': {
      height: theme.spacing(4),
      width: theme.spacing(30),
    }
  },
  cell: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
  }
}));

type InEscrowFieldsTypes = {
  in_escrow_includes_other: boolean;
  in_escrow_includes_homeowners_insurance: boolean;
  in_escrow_includes_property_taxes: boolean;
  other: boolean;
  onSwitchChange: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  homeownersInsurance: boolean;
  propertiesTaxes: boolean;
  t: TFunction;
};

const InEscrowFields = ({
  other,
  onSwitchChange,
  homeownersInsurance,
  propertiesTaxes,
  in_escrow_includes_other,
  in_escrow_includes_homeowners_insurance,
  in_escrow_includes_property_taxes,
  t,
}: InEscrowFieldsTypes) => {
  const classes = useStyles();
  return (
    <>
      {homeownersInsurance && (
      <span className={classes.root}>
        <TableCell className={classes.cell}>
          <Switch label="" checked={in_escrow_includes_homeowners_insurance} handleOnChange={(e) => onSwitchChange('estimated_taxes.in_escrow_includes_homeowners_insurance', e.target.checked)} />
          <Typography>{t('projectedPayments:homeownersInsurance')}</Typography>
        </TableCell>
      </span>
      )}
      {propertiesTaxes && (
      <span className={classes.root}>
        <TableCell className={classes.cell}>
          <Switch label="" checked={in_escrow_includes_property_taxes} handleOnChange={(e) => onSwitchChange('estimated_taxes.in_escrow_includes_property_taxes', e.target.checked)} />
          <Typography>{t('projectedPayments:propertiesTaxes')}</Typography>
        </TableCell>
      </span>
      )}
      {other && (
      <span className={classes.root}>
        <TableCell className={classes.cell}>
          <Switch label="" checked={in_escrow_includes_other} handleOnChange={(e) => onSwitchChange('estimated_taxes.in_escrow_includes_other', e.target.checked)} />
          <Typography>{t('projectedPayments:other')}</Typography>
        </TableCell>
      </span>
      )}
    </>
  );
};

export default InEscrowFields;
