import gqlRequest from 'graphql/generic';

type createMortgageParams ={
	'id': string,
	'estateId': string,
	'loan_number': string
}

  type createMortgageReturn = {
	updateOrderLedgerAssociation: {
		_id:string
	  }
  }

const createMortgageQuery = `
  mutation Mutation($id: ID!, $estateId: ID!, $loan_number: String!) {
	createOrderMortgage(_id: $id, estate_id: $estateId, loan_number: $loan_number) {
	  _id
	}
  }
  `;

export const createMortgage = (params:createMortgageParams) => gqlRequest<createMortgageReturn, createMortgageParams>(createMortgageQuery, params);

type createMortgageAssociationParams = {
	id: string,
	mortgageId: string,
	kind: string,
	partyId: string
}

type createMortgageAssociationReturn = {
	createOrderMortgageAssociation: {
		_id:string
	  }
  }

const addMortgageAssociationQuery = `
  mutation Mutation($id: ID!, $mortgageId: ID!, $kind: String!, $partyId: ID!) {
	createOrderMortgageAssociation(_id: $id, mortgage_id: $mortgageId, kind: $kind, party_id: $partyId) {
	  _id
	}
  }
  `;

export const AddMortgageAssociation = (params:createMortgageAssociationParams) => gqlRequest<createMortgageAssociationReturn, createMortgageAssociationParams>(addMortgageAssociationQuery, params);

type deleteMortgageAssociationParams = {
	id: string,
	mortgageId: string,
	kind: string,
	partyId: string
}

type deleteMortgageAssociationReturn = {
	DeleteOrderMortgageAssociation: {
		_id:string
	  }
}

const deleteMortgageAssociationQuery = `
mutation DeleteOrderMortgageAssociation($id: ID!, $mortgageId: ID!, $kind: String!, $partyId: ID!) {
	deleteOrderMortgageAssociation(_id: $id, mortgage_id: $mortgageId, kind: $kind, party_id: $partyId) {
	  _id
	}
  }
  `;

export const DeleteMortgageAssociation = (params:deleteMortgageAssociationParams) => gqlRequest<deleteMortgageAssociationReturn, deleteMortgageAssociationParams>(
  deleteMortgageAssociationQuery,
  params
);

type updateMortgageInformationParams = {
	id: string | null,
  	mortgageId: string | null,
  	data: {
		rescission_date: string | null,
		recorded_date: string|null,
		page: string | null,
		instrument_number: string | null,
		document_number: string | null,
		deed_date: string | null,
		certificate_title: string | null,
		book: string | null
	}
}

type updateMortgageInformationReturn = {
	updateOrderLedgerAssociation: {
		_id:string
	  }
}

const updateMortgageInformationAssociationQuery = `
	mutation UpdateOrderMortgage($id: ID!, $mortgageId: ID!, $data: OrderMortgageContent!) {
		updateOrderMortgage(_id: $id, mortgage_id: $mortgageId, data: $data) {
	  		_id
		}
  	}
  `;

export const updateMortgageInformation = (params:updateMortgageInformationParams) => gqlRequest<updateMortgageInformationReturn, updateMortgageInformationParams>(
  updateMortgageInformationAssociationQuery,
  params
);

type deleteMortgageParams = {
	id: string,
	mortgageId: string
}

type deleteMortgageMutationReturn = {
	deleteOrderMortgage: {
		_id:string
	  }
}

const deleteMortgageMutation = `
mutation DeleteOrderMortgage($id: ID!, $mortgageId: ID!) {
	deleteOrderMortgage(_id: $id, mortgage_id: $mortgageId) {
	  _id
	}
  }
`;

export const deleteMortgage = (params:deleteMortgageParams) => gqlRequest<deleteMortgageMutationReturn, deleteMortgageParams>(
  deleteMortgageMutation,
  params
);

export default { };
