import { capitalize } from '@material-ui/core';
import { useDeleteOrderLedgerLink } from 'hooks/useDeleteOrderLedgerLink';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAlert from 'utils/alert';
import useDeleteLedgerAssociation from 'utils/ledger/use-delete-ledger-association';
import useDeleteEntry from 'utils/ledger/use-delete-ledger-entry';

import { sortedAccounting } from '.';

const useMutationsAccounting = (setAccounting:any) => {
  const { t } = useTranslation();
  const showAlert = useAlert();

  const { deleteAssociation, deleteAssociationResponse } = useDeleteLedgerAssociation((data) => {
    if (data) {
		  showAlert(capitalize(t('dialogs:order-updated')), 'success');
		  deleteAssociationResponse.reset();
		  setAccounting(sortedAccounting(data?.deleteOrderLedgerAssociation?.accounting || []));
    }
	  },
	  () => {
    showAlert(capitalize(t('dialogs:there-is-an')), 'error');
  });

  const { deleteEntry, deleteEntryResponse } = useDeleteEntry((data) => {
    if (data) {
			  showAlert(capitalize(t('dialogs:order-updated')), 'success');
			  deleteEntryResponse.reset();
			  setAccounting(sortedAccounting(data?.deleteOrderLedgerEntry?.accounting || []));
    }
		  },
		  () => {
    showAlert(capitalize(t('dialogs:there-is-an')), 'error');
  });

  const [deleteLink, deleteLinkResponse] = useDeleteOrderLedgerLink({
    queryConfig: {
      onSuccess: (data) => {
        if (data) {
          showAlert(capitalize(t('dialogs:order-updated')), 'success');
          deleteLinkResponse.reset();
          setAccounting(sortedAccounting(data.deleteOrderLedgerLink.accounting || []));
        }
      },
      onError: () => {
        showAlert(capitalize(t('dialogs:there-is-an')), 'error');
      }
    }
  });

  useEffect(() => {
    if (deleteAssociationResponse.isLoading
			|| deleteLinkResponse.isLoading
    ) {
			  showAlert('Deleting...', 'info');
    }
  }, [deleteAssociationResponse.isLoading, deleteLinkResponse.isLoading, showAlert]);

  return {
    deleteAssociation,
    deleteEntry,
    deleteLink
  };
};

export default useMutationsAccounting;
