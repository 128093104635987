import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr',
    gridAutoRows: '1fr',
    gap: theme.spacing(2),
  },
  paginationContainer: { marginTop: theme.spacing(2) },
  paginationButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  pagintaionIcon: { fontSize: '0.98rem' },
  message: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
  button: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  icon: { marginLeft: theme.spacing(0.5) },
  headerStyles: { fontWeight: 500, color: theme.palette.tab.offselected },
  rowsLetters: { fontWeight: 500 }
}));

type HeaderOrderProps = {
  t: TFunction;
};
const HeaderOrders: FC<HeaderOrderProps> = ({ t }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid item xs={2}>
        <Typography variant="body1" className={classes.headerStyles}>
          {t('parties:order-id')}
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Typography variant="body1" className={classes.headerStyles}>
          {t('common:address')}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography variant="body1" className={classes.headerStyles}>
          {t('datesAndConfig:purchase-price')}
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography variant="body1" className={classes.headerStyles}>
          {t('orders:loan-amount')}
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <Typography variant="body1" className={classes.headerStyles}>
          {t('lender:actions')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HeaderOrders;
