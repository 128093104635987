import {
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { FC } from 'react';

import { CellIndicator, CellText } from '../../components/table-cells';
import { formattedCharge } from '../services';
import CellHeader from './header-cell';
import HudRow from './hud-row';

const useStyles = makeStyles((theme: any) => ({
  borderCellStyle: {
    borderColor: theme.palette.input.border,
    borderWidth: 1,
    borderStyle: 'solid',
    opacity: 1,
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
  },
  padding: { padding: theme.spacing(3) },
  table: { background: theme.palette.background.paper },
  containerTable: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
  },
  scrollDiv: {
    overflowX: 'auto',
    width: '100%',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.5) },
    '&&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark, },
  },
}));

type HudtableProps = {
  hudCharges: formattedCharge[];
  title: string;
  titleNumber: number;
  handleEdit: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleDelete: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleSelectItem: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleClick: (event: React.MouseEvent, number: number) => void;
};

const HudTablewithTitle: FC<HudtableProps> = ({
  hudCharges,
  title,
  titleNumber,
  handleDelete,
  handleEdit,
  handleSelectItem,
  handleClick,
}) => {
  const classes = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <CellHeader style={{ width: '6rem' }} />
          <CellHeader colSpan={1} style={{ width: '5rem' }}>
            <CellIndicator isActive={false} position={titleNumber} />
          </CellHeader>
          <CellHeader colSpan={4}>
            <CellText
              position="left"
              backgroundColor="transparent"
              colorText="#4F5B62"
              data={title}
            />
          </CellHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {hudCharges.map((row) => (
          <HudRow
            key={row.id}
            row={row}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleSelectItem={handleSelectItem}
            handleClick={handleClick}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default HudTablewithTitle;
