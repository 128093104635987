import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text, TextProps } from 'components/text';
import React, { CSSProperties, FC } from 'react';

type TextWithIconProps = Omit<TextProps, 'leftAdorment'> & {
  icon: IconProp,
  iconSize?: SizeProp,
  iconColor?: string,
  iconRightMargin?: CSSProperties['marginRight']
};

export const TextWithIcon: FC<TextWithIconProps> = ({
  icon,
  iconColor,
  iconSize = 'sm',
  iconRightMargin = '8px',
  ...rest
}) => (
  <Text
    {...rest}
    style={{
      display: 'flex',
      alignItems: 'center'
    }}
    leftAdorment={
      <FontAwesomeIcon icon={icon} size={iconSize} color={iconColor} style={{ marginRight: iconRightMargin }} />
    }
  />
);
