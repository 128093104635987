import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import TextField from 'components/text-field';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { NewWirePayment } from 'types/common';
import * as Yup from 'yup';

import { PaymentProps, PaymentsTypes } from '../../types';
import ContainerPayment from './container-payment';

const useStyles = makeStyles(() => ({
  createButton: {
    height: 30,
    width: '100%',
    textTransform: 'capitalize',
  },
}));

type ModalCreateLicenseProps = {
  open: boolean;
  add: (data: PaymentsTypes, onClose?: () => void) => void;
  onClose: () => void;
  t: TFunction
};

const ModalCheck: FC<ModalCreateLicenseProps> = ({ onClose, open, add, t }) => {
  const schema = Yup.object({
    account: Yup.string().required(t('validations:required')),
    bank: Yup.string().required(t('validations:required')),
    bank_address: Yup.string().required(t('validations:required')),
    country: Yup.string().required(t('validations:required')),
    name: Yup.string().required(t('validations:required')),
    routing: Yup.string().required(t('validations:required')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<NewWirePayment>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      account: '',
      bank: '',
      bank_address: '',
      country: '',
      name: '',
      routing: '',
    },
  });

  const classes = useStyles();

  const handleOnClose = () => {
    reset();
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle>
        <Typography>{t('charges:wire')}</Typography>
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={handleSubmit((data) => add({ wire: data }, handleOnClose))}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="account"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:account')}
                    error={!!errors.account}
                    helperText={
                      errors.account?.message || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="bank"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:bank')}
                    error={!!errors.bank}
                    helperText={
                      errors.bank?.message || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="bank_address"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:bank-address')}
                    error={!!errors.bank_address}
                    helperText={
                      errors.bank_address?.message || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="country"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:country')}
                    error={!!errors.country}
                    helperText={
                      errors.country?.message || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:name')}
                    error={!!errors.name}
                    helperText={
                      errors.name?.message || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="routing"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:routing')}
                    error={!!errors.routing}
                    helperText={
                      errors.routing?.message || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item>
                  <Button
                    type="submit"
                    disableElevation
                    disabled={!isValid}
                    variant="contained"
                    color="secondary"
                    className={classes.createButton}
                  >
                    {t('users:create-payment')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Wire: FC<PaymentProps> = ({ helpers, t }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ContainerPayment
        t={t}
        kind="wire"
        handleOpen={handleOpen}
        helpers={helpers}
      />

      <ModalCheck
        t={t}
        add={helpers.add}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default Wire;
