import { makeStyles, TableCell, TableRow, } from '@material-ui/core';
import { Text } from 'components/text';
import React, { FC } from 'react';
import { NewOrderPartyType } from 'v2-types/order';

import { PartyWithPartyInvolved } from '../../../types';

type PartyNameProps = {
  party: PartyWithPartyInvolved | NewOrderPartyType,
}

const useStyles = makeStyles(() => ({
  tableCell: {
    padding: '10px 0px',
    borderBottom: '1px solid #00000020',
  },
  tableRow: { '&:last-child .MuiTableCell-root': { borderBottom: '0px' } },
}));

export const PartyNameRow: FC<PartyNameProps> = ({ party }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        <Text
          value={party.name}
          fontWeight="bold"
          variant="caption"
        />
      </TableCell>
    </TableRow>
  );
};
