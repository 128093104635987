import {
  Button, createStyles, Grid, makeStyles, Typography, useMediaQuery
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import championship2Logo from '../../assets/images/championship-logo@2x.png';
import OnboardingLayout from '../../layouts/onboarding-layout/onboarding-layout';

const useStyles = makeStyles((theme: any) => createStyles({
  underlineError: { '&.MuiInput-underline.Mui-error:after': { borderColor: theme.accent.main } },
  underlineFocus: { '&.MuiInput-underline.Mui-focused:after': { borderColor: theme.accent.main } },
  underlineDefault: {
    '&.MuiInput-underline:before': { borderBottomColor: theme.palette.common.white },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': { 'border-bottom': `1px solid ${theme.palette.common.white};` }
  },
  buttonFilled: {
    textTransform: 'none',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': { backgroundColor: theme.palette.button.hover }
  },
  buttonText: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    '&:hover': { color: theme.palette.button.hover }
  },
  smallLogo: { width: theme.spacing(20) },
  normalIntut: {
    paddingLeft: theme.spacing(60),
    paddingRight: theme.spacing(60),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
    },

  },
  ipad: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20)
  },
  mediumInput: { paddingLeft: theme.spacing(20), paddingRight: theme.spacing(20) },
  smallInput: { paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5) },

}));

const SignInScene = ({ loginWithRedirect, t, theme }: any) => {
  const SM = useMediaQuery(`(max-width:${theme.spacing(75)}px)`);
  const isIpad = useMediaQuery(`@media only screen and (min-device-width :${theme.spacing(96)}px) and (max-device-width : ${theme.spacing(120)}px)`);
  const classes = useStyles();
  return (
    <OnboardingLayout>
      <Grid
        container
        item
        xs={12}
        style={{
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid
          item
          style={{
            justifyContent: 'center',
            display: 'flex',
            marginTop: SM ? theme.spacing(5) : theme.spacing(9),
          }}
        >
          <img
            src={championship2Logo}
            alt="logo"
            style={{ width: SM ? theme.spacing(25) : theme.spacing(50) }}
          />
        </Grid>
        <Grid
          item
          style={{
            marginTop: SM ? theme.spacing(7) : theme.spacing(10),
            marginBottom: SM ? theme.spacing(7) : theme.spacing(10),
          }}
        >
          <Typography
            variant={SM ? 'h5' : 'h4'}
            style={{
              color: theme.palette.common.white,
              textAlign: 'center',
              wordWrap: 'break-word',
            }}
          >
            {t('common:welcome-signin')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={clsx(classes.normalIntut, { [classes.ipad]: isIpad })}
        >
          <Button
            data-testid="signButton"
            variant="contained"
            type="button"
            className={classes.buttonFilled}
            onClick={() => loginWithRedirect()}
          >
            {t('auth:loginIn')}
          </Button>

        </Grid>
      </Grid>
    </OnboardingLayout>
  );
};

export default SignInScene;
