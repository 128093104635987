import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  input: {
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.common.black,
      cursor: 'not-allowed'
    }
  },
}));
