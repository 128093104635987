import { CONDITION_CAN_GO_AS_HIGH_AS_$, CONDITION_CAN_INCREASE_UNTIL_YEAR } from 'utils/constants';

import { ConditionsForm } from '../types';

export const getValues = (increaseLoanAmountText: string | null | undefined): ConditionsForm => {
  if (!increaseLoanAmountText) {
    return {
      firstConditionExist: false,
      secondConditionExist: false,
      firstFieldValue: undefined,
      secondFieldValue: undefined
    };
  }
  const conditions = increaseLoanAmountText.split('. ');
  let firstFieldValue: number | undefined;
  let secondFieldValue: number | undefined;

  const firstCondition = conditions.find((condition) => condition.includes(CONDITION_CAN_GO_AS_HIGH_AS_$));
  if (firstCondition) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const [_, value] = firstCondition.split('$');
    if (value) firstFieldValue = Number(value.replaceAll(',', ''));
  }

  const secondCondition = conditions.find((condition) => condition.includes(CONDITION_CAN_INCREASE_UNTIL_YEAR));
  if (secondCondition) {
    const matches = secondCondition.match(/\d+/g);
    if (matches && matches[0]) secondFieldValue = Number(matches[0]);
  }

  return {
    firstConditionExist: firstCondition !== undefined,
    secondConditionExist: secondCondition !== undefined,
    firstFieldValue,
    secondFieldValue
  };
};

export const formatConditionForm = (data: ConditionsForm) => {
  const {
    firstConditionExist,
    secondConditionExist,
    firstFieldValue,
    secondFieldValue
  } = data;

  let conditions: string = '';
  if (firstConditionExist && firstFieldValue) conditions += `${CONDITION_CAN_GO_AS_HIGH_AS_$}${firstFieldValue}. `;
  if (secondConditionExist && secondFieldValue) conditions += `${CONDITION_CAN_INCREASE_UNTIL_YEAR}${secondFieldValue}. `;

  return conditions;
};
