import { Box, } from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import React from 'react';

type OptionsProps = {
  onSave: () => void,
  isLoading: boolean
};

export const Options = ({
  onSave,
  isLoading
}: OptionsProps) => {
  return (
    <Box>
      <ContainedButton
        text="Save"
        icon="hdd"
        onClick={onSave}
        isLoading={isLoading}
      />
    </Box>
  );
};
