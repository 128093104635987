/* eslint-disable no-unused-vars */
import {
  Box,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useYupValidationResolver } from 'utils/ledger/use-yup-resolver';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import ModalLinkentry from '../accounting/components/modal-link-entry/modal-link-entry';
import DisbursementTable from './components/disbursement-table';
import Header from './components/header';
import { getHideFields, validationSchema } from './services';

const useStyles = makeStyles((theme:any) => ({ container: { padding: theme.spacing(3) }, }));

type disbursementsReceiptsProps = {
	data:AccountingType[],
	state:{
		mode: string | null;
		entryLedger: AccountingType | null;
		associationSelected: AccountingInvolvedParty | null;
	},
	handleModal:(param: string | null, entryLedger?: AccountingType | null, entryAssociacion?: AccountingInvolvedParty | null) => void,
	handleUpdateAccounting:(data: AccountingType[]) => void,
	handledeleteLink: (order_id: string, paymentRecordId: string, associationKind: string, associationId: string, associationLedgerRow: string) => void,
	handleDeleteEntry:(accountingId: string) => void
}

const DisbursementsReceiptsScene:FC<disbursementsReceiptsProps> = ({
  data,
  handleModal,
  state,
  handleUpdateAccounting,
  handledeleteLink,
  handleDeleteEntry
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const receipts = useMemo(() => data.filter((entry) => entry.kind === 'manual').filter((entry) => entry.code.includes('cash') || entry.code.includes('wire') || entry.code.includes('check')), [data]);
  const openModal = state.mode === 'edit-entry' || state.mode === 'add-entry';
  const resolver = useYupValidationResolver(validationSchema);
  return (
    <>
      <Box flex="1" className={classes.container}>
        <Paper>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Header
                title={t('accounting:disbursements')}
                handleAddEntry={() => {
                  handleModal('add-entry', null, null);
			  }}
              />
            </Grid>
            <Grid item xs={12}>
              <DisbursementTable
                data={receipts}
                handleModal={handleModal}
                handledeleteLink={handledeleteLink}
                handledeleteDisbursement={handleDeleteEntry}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {state.mode === 'link' && (
      <ModalLinkentry
        ledgerData={data}
        currentLedgerEntry={state.entryLedger !== null
          ?	state.entryLedger
          : ({} as AccountingType)}
        open={state.mode === 'link'}
        onClose={() => {
          handleModal(null, null, null);
        }}
        handleUpdateAccounting={handleUpdateAccounting}
      />
      )}
      {openModal && (
      <AddLedgerEntry
        open={!!openModal}
        handleClose={() => {
          handleModal(null, null, null);
        }}
        onSuccesAddEntry={handleUpdateAccounting}
        prevData={
  state.mode === 'edit-entry'
    ? state?.entryLedger
    : { kind: 'manual' } as AccountingType
}
        kindAllowedEntry={['manual']}
        codeAllowedEntry={['cash', 'wire', 'check']}
        resolver={resolver}
        hideFields={getHideFields[state?.mode || 'other']}
        disableFields={{}}
        isEditingEntry={state.mode === 'edit-entry'}
      />
      )}
    </>
  );
};

export default DisbursementsReceiptsScene;
