import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import graphql from 'services/querys';
import { NewUserType } from 'types/new-user';

const useSearchParty = (searchText: string | undefined) => {
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [parties, setParties] = useState<NewUserType[]>([]);

  const searchPartyResponse = useQuery(
    ['searchParty', searchText],
    graphql.searchParty,
    {
      enabled: searchEnabled && searchText && searchText.length >= 3,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timeoutId: NodeJS.Timeout | undefined;

    if (searchText && searchText.length >= 3) {
      timeoutId = setTimeout(() => setSearchEnabled(true), 300);
    }

    return () => {
      setSearchEnabled(false);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchText]);

  useEffect(() => {
    if (!searchText || (searchText && searchText.length < 3)) {
      setParties([]);
    }

    if (searchPartyResponse.isSuccess && searchPartyResponse.data) {
      setParties(searchPartyResponse.data.searchParty);
    }

    if (searchPartyResponse.isError) {
      setParties([]);
    }
  }, [searchPartyResponse, searchText]);

  return { parties, loading: searchPartyResponse.isLoading };
};

export default useSearchParty;
