export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const UPDATE_CURRENT_ORDER_CURRENT_VALUES = 'UPDATE_CURRENT_ORDER_CURRENT_VALUES';

export const ADD_CURRENT_ORDER_SELLER = 'ADD_CURRENT_ORDER_SELLER';
export const REMOVE_CURRENT_ORDER_SELLER = 'REMOVE_CURRENT_ORDER_SELLER';
export const EDIT_CURRENT_ORDER_SELLER = 'EDIT_CURRENT_ORDER_SELLER';

export const ADD_CURRENT_ORDER_PROPERTY = 'ADD_CURRENT_ORDER_PROPERTY';
export const REMOVE_CURRENT_ORDER_PROPERTY = 'REMOVE_CURRENT_ORDER_PROPERTY';
export const EDIT_CURRENT_ORDER_PROPERTY = 'EDIT_CURRENT_ORDER_PROPERTY';

export const ADD_CURRENT_ORDER_BUYER = 'ADD_CURRENT_ORDER_BUYER';
export const REMOVE_CURRENT_ORDER_BUYER = 'REMOVE_CURRENT_ORDER_BUYER';
export const EDIT_CURRENT_ORDER_BUYER = 'EDIT_CURRENT_ORDER_BUYER';
