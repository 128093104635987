import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ContainedButton } from 'components/ContainedButton';
import NumberField from 'components/number-field';
import SelectField from 'components/select-field';
import React, { FC } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

import SelectFieldLink from './components/link-entry-select';
import { createlinkForm } from './services';

const useStyles = makeStyles((theme) => ({
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

type ModalLinkSceneProps = {
	onClose:()=> void;
	ledgerEntries: AccountingType[];
	open:boolean,
	methods: UseFormReturn<createlinkForm, object>
	handleSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
	errors: string[];
	loadingCreation: boolean
}

const ModalLinkScene: FC<ModalLinkSceneProps> = ({
  open,
  onClose,
  ledgerEntries,
  methods,
  handleSubmit,
  errors,
  loadingCreation
}) => {
  const { setValue, control, formState } = methods;
  const selectedEntry = useWatch({
    control,
    name: 'targetEntryId'
  });
  const selectedEntryLedger = ledgerEntries.find((f) => f._id === selectedEntry);
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
		  if (reason !== 'backdropClick') {
		    onClose();
		  }
      }}
      fullWidth
    >
      <form onSubmit={handleSubmit}>

        <DialogContent style={{ padding: 16 }}>
          <Box>
            <Typography variant="h6" style={{ color: 'black' }}>
              Create Link to entry
            </Typography>
          </Box>
          <Box display="flex" flex="1">
            {!!errors.length && (
            <Alert
              severity="error"
              classes={{ icon: classes.errorIconContainer }}
            >
              {errors.map((error) => (
                <Typography variant="subtitle1">{error}</Typography>
              ))}
            </Alert>
            )}
          </Box>
          <Controller
            name="targetEntryId"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SelectField
                {...field}
                inputRef={ref}
                value={field.value || ''}
                data={ledgerEntries}
                dataKey="_id"
                dataValue="_id"
                dataText={['description', 'letter', 'number']}
                label="Select entry"
                handleChange={(e) => {
                  field.onChange(e);
                  setValue('targetAssociationId', null);
                  setValue('targetAssociationKind', null);
                }}
              />
            )}
          />
          <Controller
            name="targetAssociationId"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <SelectFieldLink
                {...field}
                value={field.value || ''}
                inputRef={ref}
                data={selectedEntryLedger?.involved_parties || []}
                label="Select association"
                handleChange={(e) => {
                  field.onChange(e);
                  const selectedParty = selectedEntryLedger?.involved_parties.find((g) => g._id === e.target.value);
                  if (selectedParty) {
				  setValue('targetAssociationKind', selectedParty?.kind);
                  }
                }}
                error={!!formState.errors.targetAssociationId}
              />
            )}
          />
          <Controller
            name="amount"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <NumberField
                {...field}
                value={field.value || 0}
                customRef={ref}
                thousandSeparator=","
                prefix="$"
                onChange={undefined}
                onValueChange={(e) => { field.onChange(e.floatValue); }}
                error={!!formState.errors.amount}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            disableElevation
            onClick={onClose}
          >
            <Typography variant="body2" className={classes.text}>
              {t('common:close')}
            </Typography>
          </Button>

          <ContainedButton
            text="Creale Link"
            type="submit"
            isLoading={loadingCreation}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalLinkScene;
