import { Table, TableBody, TableContainer } from '@material-ui/core';
import { Text } from 'components/text';
import { TFunction } from 'i18next';
import React from 'react';

type PartyTableProps<TParty> = {
  parties: TParty[],
  t: TFunction,
  renderRowFn: (data: TParty, index: number) => React.ReactNode
}

export const PartyTable = <TParty, >({
  parties,
  t,
  renderRowFn
}: PartyTableProps<TParty>) => (
  <TableContainer>
    <Table>
      <TableBody>
        {parties.length <= 0 && (
          <Text
            value={t('orders:not-defined-yet').toString()}
            fontWeight="bold"
            variant="caption"
          />
        )}
        {parties.map(renderRowFn)}
      </TableBody>
    </Table>
  </TableContainer>
  );
