import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC, useMemo } from 'react';

import { filters as tabFilters, FiltersType } from '../services';

const useStyles = makeStyles((theme: any) => ({
  tabs: {
    flex: 1,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.button.default,
    height: theme.spacing(2),
  },
  tabsIndicator: { backgroundColor: theme.accent.main },
  tabRoot: {
    padding: 0,
    textTransform: 'none',
    minWidth: theme.spacing(12),
    maxWidth: theme.spacing(22),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  tabWrapper: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  inputAdornmentButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(4),
    width: theme.spacing(4.4),
  },
  inputAdornmentIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    height: theme.spacing(4),
    width: theme.spacing(4.6),
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
  },
  clearButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));

type OrderTabsProps = {
  t: TFunction;
  filter: FiltersType;
  searchString: string;
  handleSearchOrder: (orderId: string) => void;
  handleChangeFilter: (value: FiltersType) => void;
};

const OrderTabs: FC<OrderTabsProps> = ({
  t,
  filter,
  searchString,
  handleSearchOrder,
  handleChangeFilter,
}) => {
  const classes = useStyles();

  const memorizedFilters = useMemo(() => tabFilters(t), [t]);

  return (
    <Grid container alignItems="center" spacing={5}>
      <Grid item xs={9}>
        <Tabs
          value={filter}
          textColor="primary"
          className={classes.tabs}
          onChange={(_, value) => handleChangeFilter(value)}
        >
          {memorizedFilters.map((memorizedFilter) => (
            <Tab
              key={memorizedFilter.id}
              value={memorizedFilter.value}
              label={memorizedFilter.label}
              classes={{ root: classes.tabRoot, wrapped: classes.tabWrapper }}
            />
          ))}
        </Tabs>
      </Grid>

      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Search Order"
          value={searchString}
          onChange={(event) => handleSearchOrder(event.target.value)}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <>
                {searchString.trim() !== '' && (
                  <InputAdornment position="end" className={classes.inputAdornmentButton}>
                    <IconButton
                      className={classes.clearButton}
                      onClick={() => handleSearchOrder('')}
                    >
                      <FontAwesomeIcon icon="times" size="xs" />
                    </IconButton>
                  </InputAdornment>
                )}

                <InputAdornment position="end" className={classes.inputAdornmentIcon}>
                  <FontAwesomeIcon icon="search" />
                </InputAdornment>
              </>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OrderTabs;
