import { Box, makeStyles, Typography } from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProfile } from 'redux/profile-reducer/selectors';
import {
  AGENCY_SELECTED_ID,
  CONTACT_TITLE_ADMINISTRATOR,
} from 'utils/constants';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
    marginRight: theme.spacing(4),
  },
}));

type titleActionProps = {
  type: string;
  name: string;
  partyId: string;
};

const TitleAction: FC<titleActionProps> = ({ type, name, partyId }) => {
  const userProfile = useSelector(getProfile);
  const AgencySelected = localStorage.getItem(AGENCY_SELECTED_ID) || '';
  const authorization = userProfile.accesses.find(
    (access) => access._id === AgencySelected
  )?.authorization;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="row">
      <Typography variant="h5" className={classes.title}>
        {`${type} / ${`${name}`}`}
      </Typography>
      {authorization === CONTACT_TITLE_ADMINISTRATOR && (
        <BaseButton
          text={t('parties:edit-contact-details')}
          icon="arrow-right"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `${window.location.origin}/contacts/${partyId}`,
              '_blank',
              'noopener,noreferrer'
            );
          }}
        />
      )}
    </Box>
  );
};

export default TitleAction;
