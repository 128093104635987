import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles((theme:any) => ({
  lenderSelectedTitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500
	  },
}));

type titleSectionProps = {
	title:string,
	children:React.ReactNode
}
const TitleSection:FC<titleSectionProps> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.lenderSelectedTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default TitleSection;
