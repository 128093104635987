import { Box, Button, Paper, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { AutoTemplate, NewTemplateType } from 'types/auto-templates';
import { listingItemtype } from 'types/ledger';

import { FormTemplate } from './form-template';

type TemplateSelectedProps = {
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  partyRolls: listingItemtype[];
  handleUpdateTemplate: (data: NewTemplateType) => void;
  template: AutoTemplate;
  t: TFunction;
};

export const TemplateSelected: FC<TemplateSelectedProps> = ({
  template,
  partyRolls,
  handleUpdateTemplate,
  setOpenEditModal,
  t,
}) => (
  <Paper style={{ padding: '16px' }}>
    <Box display="flex" justifyContent="space-between" marginBottom={4}>
      <Typography variant="h6">
        {`${t('documents:template')} - ${template.name}`}
      </Typography>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setOpenEditModal(true)}
      >
        <Typography variant="subtitle2">
          {t('documents:edit-document')}
        </Typography>
      </Button>
    </Box>
    <FormTemplate
      partyRolls={partyRolls}
      template={template}
      handleOnSubmit={handleUpdateTemplate}
    />
  </Paper>
);
