import { Box, Typography } from '@material-ui/core';
import Checkbox from 'components/checkbox';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoanFormType } from 'views/orders/loans/types';

import { useStyles } from './styles';

type Props = {
  control: Control<LoanFormType, object>
  isDataLoading: boolean,
}

export const PartialPaymentsForm: FC<Props> = ({
  control,
  isDataLoading
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box style={{ flex: 1 }}>
      <Typography variant="h6" className={classes.title}>
        {t('loanDisclosures:partial-payments')}
      </Typography>

      <Typography variant="body2" className={classes.subtitle}>
        {t('loanDisclosures:partial-payments-subtitle')}
      </Typography>

      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Controller
          control={control}
          name="partial_payment_accept"
          render={({ field }) => (
            <Checkbox
              size="small"
              label={t('loanDisclosures:partial-payments-option-1')}
              checked={field.value ? field.value : false}
              handleOnChange={(e, checked) => field.onChange(checked)}
              disabled={isDataLoading}
            />
          )}
        />

        <Controller
          control={control}
          name="partial_payment_hold"
          render={({ field }) => (
            <Checkbox
              size="small"
              label={t('loanDisclosures:partial-payments-option-2')}
              checked={field.value ? field.value : false}
              handleOnChange={(e, checked) => field.onChange(checked)}
              disabled={isDataLoading}
            />
          )}
        />

        <Controller
          control={control}
          name="partial_payment_denied"
          render={({ field }) => (
            <Checkbox
              size="small"
              label={t('loanDisclosures:partial-payments-option-3')}
              checked={field.value ? field.value : false}
              handleOnChange={(e, checked) => field.onChange(checked)}
              disabled={isDataLoading}
            />
          )}
        />
      </Box>
    </Box>
  );
};
