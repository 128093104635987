import {
  Box,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React, { FC } from 'react';

const useStyles = makeStyles<Theme, { cardPadding: number | string, titleMarginBottom: number }>((theme: any) => ({
  cardContainer: (props) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(props.cardPadding)
  }),
  title: (props) => ({
    fontWeight: 500,
    color: theme.palette.tab.offselected,
    marginBottom: theme.spacing(props.titleMarginBottom)
  }),
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
}));

type PaperCardProps = {
  title?: string,
  titleVariant?: Variant,
  children?: React.ReactNode;
  titleMarginBottom?: number;
  rightComponent?: React.ReactNode,
  flex?: number,
  cardPadding?: number | string,
  backgroundImage?:string
}

export const PaperCard: FC<PaperCardProps> = ({
  title = '',
  titleVariant = 'h6',
  children,
  titleMarginBottom = 2,
  rightComponent,
  flex,
  cardPadding = 3,
  backgroundImage
}) => {
  const classes = useStyles({ cardPadding, titleMarginBottom });

  return (
    <Box
      className={classes.cardContainer}
      flex={flex}
      style={{ backgroundImage }}
    >
      {title.length > 0
      && (
      <Box className={classes.titleContainer}>
        <Typography
          variant={titleVariant}
          className={classes.title}
        >
          {title}
        </Typography>
        {rightComponent}
      </Box>
      )}
      {children || null}
    </Box>
  );
};
