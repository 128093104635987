import { Column } from 'types/common';
import { AccountedBy, AccountingInvolvedParty, AccountingType } from 'v2-types/order';
import * as yup from 'yup';

export type ColumnsType = { [key: string]: Column };

export type TableLedgerItemColumn = {
  id: string;
  amount: number;
  amount_override: number;
  amount_calculated:number;
  code: string;
  kind: string;
  letter: string;
  number:number;
  description: string;
  entryDate: string;
  months:number | null;
  per_month: number | null;
  involved_parties: AccountingInvolvedParty[];
  accounted_for:AccountedBy[]
}

export type EntryLedgerType = {
  entryDate: string | null,
  description: string,
  code: string,
  amount: number,
  kind: string,
  letter:string | null,
  amount_override: number,
  number:number | null,
  amount_calculated:number
  months: number | null
}

export type EditEntryLedgerType = {
  amount_override: number,
  letter: string | null,
  entry_date:string| null
}

export type involvedPartySelectedItem = {
  row:AccountingInvolvedParty,
  ledgerInfo: any
 }

export const validationSchema = yup.object({
  code: yup.string().required('Required'),
  description: yup.string().required('Required'),
  kind: yup.string().required('Required'),
});

export const sortedAccounting = (accountingData:AccountingType[]) => accountingData.sort((a, b) => {
  if (a.letter === b.letter) {
    return 0;
  }

  if (a.letter === null) {
    return 1;
  }
  if (b.letter === null) {
    return -1;
  }
  const x = a.letter.toLowerCase();
  const y = b.letter.toLowerCase();
  if (x < y) { return -1; }
  if (x > y) { return 1; }
  return 0;
});

export const initialstateModals = {
  entryLedger: null,
  associationSelected: null,
  mode: null
};

export const reducerModals = (state, action) => {
  switch (action.type) {
    case 'add-entry':
      return {
		  mode: 'add-entry',
		  entryLedger: action.entryLedger,
		  associationSelected: action.associationSelected
      };
    case 'edit-entry':
      return {
		  mode: 'edit-entry',
		  entryLedger: action.entryLedger,
		  associationSelected: action.associationSelected
      };
    case 'link':
      return {
        mode: 'link',
        entryLedger: action.entryLedger,
        associationSelected: action.associationSelected
      };
    case 'add-association':
      return {
		  mode: 'add-association',
		  entryLedger: action.entryLedger,
		  associationSelected: action.associationSelected
      };
    case 'edit-association':
      return {
		  mode: 'edit-association',
		  entryLedger: action.entryLedger,
		  associationSelected: action.associationSelected
      };
    default:
      return {
		  entryLedger: null,
		  mode: null,
		  associationSelected: null,
      };
  }
};

export default { };
