import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { UserReduxStateType } from 'types/redux';
import { TITLE_COMPANY } from 'utils/constants';

const useStyles = makeStyles((theme: any) => ({
  popoverContainer: {
    position: 'relative',
    minWidth: '300px',
    width: '100%',
  },
  headerContainer: {
    backgroundColor: theme.palette.button.default,
    height: '50px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  infoUserContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 20px',
  },
  avatarContainer: { position: 'relative' },
  imageUser: {
    width: 80,
    height: 80,
    backgroundColor: 'black',
  },
  stateBubble: {
    width: '30px',
    height: '30px',
    position: 'absolute',
    borderRadius: '50%',
    right: '-2px',
    bottom: 0,
    border: `2px solid ${theme.palette.button.default}`,
    background: 'white',
  },
  active: {
    border: '2px solid white',
    background: theme.palette.secondary.main,
  },
  infoNameTitle: {
    fontSize: '23px',
    color: theme.palette.primary.light,
    marginTop: '17px',
  },
  infoMailTitle: {
    fontSize: '13px',
    color: theme.palette.primary.darker,
    fontWeight: 500,
  },
  buttonProfile: {
    marginTop: '8px',
    padding: '10px 18px',
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.button.default,
  },
  listContainer: { padding: 0 },
  listTitle: {
    padding: '10px 20px',
    fontSize: '14px',
    color: theme.palette.primary.darker,
    fontWeight: 500,
  },
  listItemContainer: {
    padding: '15px 20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  itemTitle: {
    '& span': {
      fontSize: '12px',
      color: theme.palette.primary.darker,
      fontWeight: 500,
    },
  },
  itemTitleLeft: { paddingLeft: '20px' },
  itemSelectedLeft: { position: 'relative', left: '16px' },
  itemSelectedTitle: {
    fontSize: '10px',
    color: theme.palette.primary.light,
  },
  buttonLogOut: {
    padding: '10px 20px',
    width: '100%',
    fontSize: '14px',
    color: theme.palette.primary.darker,
    fontWeight: 500,
    justifyContent: 'flex-start',
  },
  buttonLogOutIcon: {
    fontSize: '14px !important',
    color: theme.palette.primary.darker,
  },
  termsServiceTitle: {
    padding: '15px',
    width: '100%',
    fontSize: '11px',
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 500,
    textAlign: 'center',
  },
}));

type MenuProfileProps = {
  t: TFunction;
  profile: UserReduxStateType;
  userLetters: string;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  open: boolean;
  handleNavigateProfile: () => void;
  handleNavigateTermsOfService: () => void;
  handleLogout: () => void;
  selectAgency: (agencyId: string) => void;
  agencySelected: string;
  isConnectedSocket: boolean;
};

export const MenuProfile: FC<MenuProfileProps> = ({
  open,
  anchorEl,
  handleClose,
  userLetters,
  profile,
  handleNavigateProfile,
  handleNavigateTermsOfService,
  handleLogout,
  selectAgency,
  agencySelected,
  t,
  isConnectedSocket,
}) => {
  const classes = useStyles();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box className={classes.popoverContainer}>
        <Box className={classes.headerContainer} />
        <Box className={classes.infoUserContainer}>
          <Box className={classes.avatarContainer}>
            <Avatar className={classes.imageUser}>{userLetters}</Avatar>
            <Box
              className={clsx({
                [classes.stateBubble]: true,
                [classes.active]: isConnectedSocket,
              })}
            />
          </Box>
          <Typography className={classes.infoNameTitle}>
            {profile.name}
          </Typography>
          <Typography className={classes.infoMailTitle}>
            {profile.emails.find((email) => email.kind === 'Login')?.address}
          </Typography>
          <Button
            color="secondary"
            className={classes.buttonProfile}
            onClick={() => {
              handleClose();
              handleNavigateProfile();
            }}
          >
            {t('menu:profile')}
          </Button>
        </Box>
        <Box>
          <Divider className={classes.divider} />
          <Typography className={classes.listTitle}>
            {t('menu:agencies')}
          </Typography>
          <List className={classes.listContainer}>
            <ListItem
              button
              className={classes.listItemContainer}
              key="allAgencies"
              onClick={() => selectAgency('')}
              disabled={agencySelected === ''}
              selected={agencySelected === ''}
            >
              <ListItemText
                className={classes.itemTitle}
                primary={t('menu:all-agencies')}
              />
              {agencySelected === '' && (
                <ListItemSecondaryAction>
                  <Typography className={classes.itemSelectedTitle}>
                    {t('menu:selected')}
                  </Typography>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {profile.accesses.map((agency) => (
              <ListItem
                button
                className={classes.listItemContainer}
                key={agency._id}
                onClick={() => selectAgency(agency._id)}
                disabled={agencySelected === agency._id}
                selected={agencySelected === agency._id}
              >
                {agencySelected === agency._id && (
                  <ListItemSecondaryAction
                    className={clsx({ [classes.itemSelectedLeft]: agency.kind !== TITLE_COMPANY, })}
                  >
                    <Typography className={classes.itemSelectedTitle}>
                      {t('menu:selected')}
                    </Typography>
                  </ListItemSecondaryAction>
                )}
                <ListItemText
                  className={clsx(classes.itemTitle, { [classes.itemTitleLeft]: agency.kind !== TITLE_COMPANY, })}
                  primary={agency.name}
                />
              </ListItem>
            ))}
          </List>
          <Divider className={classes.divider} />
          <Button
            className={classes.buttonLogOut}
            startIcon={(
              <FontAwesomeIcon
                className={classes.buttonLogOutIcon}
                icon="sign-out-alt"
              />
            )}
            onClick={handleLogout}
          >
            {t('menu:log_out')}
          </Button>
          <Divider className={classes.divider} />
          <Typography
            className={classes.termsServiceTitle}
            onClick={() => {
              handleClose();
              handleNavigateTermsOfService();
            }}
          >
            {t('menu:terms_services')}
          </Typography>
        </Box>
      </Box>
    </Popover>
  );
};
