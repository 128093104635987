import {
  Checkbox as MUICheckbox,
  FormControlLabel,
} from '@material-ui/core';
import React from 'react';

type SwitchProps = {
  name?: string;
  label?: string | React.ReactNode;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  size?: 'small' | 'medium';
  checked: boolean;
  handleBlur?: any;
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean
};

const Checkbox = ({
  name,
  label,
  labelPlacement,
  size,
  checked,
  handleBlur,
  handleOnChange,
  disabled = false
}: SwitchProps) => (
  <FormControlLabel
    name={name}
    label={label}
    labelPlacement={labelPlacement}
    disabled={disabled}
    control={(
      <MUICheckbox
        size={size}
        checked={checked}
        onBlur={handleBlur}
        onChange={handleOnChange}
      />
    )}
  />
);

Checkbox.defaultProps = {
  name: undefined,
  label: undefined,
  labelPlacement: 'end',
  size: 'medium',
  handleBlur: undefined,
  handleOnChange: () => {},
};

export default Checkbox;
