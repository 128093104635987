/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs
 */
import {
  Grid,
  makeStyles,
  Paper,
  Tabs,
  Typography
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import { AddAndUpdatePartyLedgerEntry } from 'components/add-and-update-party-ledger-modal/add-and-update-party-ledger-entry';
import AddEntry from 'components/add-entry-modal/add-entry-modal';
import { CustomTab as Tab } from 'components/common/custom-tab';
import React, { FC, useMemo, useState } from 'react';
import { TFunction } from 'react-i18next';
import { capitalize } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import Header from './components/header';
import ModalLinkentry from './components/modal-link-entry/modal-link-entry';
import SankeyDiagram from './components/sankeyDiagram';
import Ledger from './components/table-ledger/table-ledger';
import { TableLedgerItemColumn } from './services';
import AccountingContext from './services/context';

const useStyles = makeStyles((theme: any) => ({
  noinformation: {
    display: 'flex',
    justifyItems: 'center',
  },
  noinformationFont: {
    color: theme.palette.common.black,
    fontWeight: 500,
    textAlign: 'center',
  },
  padding: { padding: theme.spacing(3) },
}));

type AccountingScenetype = {
  accountingData: AccountingType[];
  handleUpdateLedger: (accountingData: AccountingType[]) => void;
  handleDeleteLedgerItem: (item: TableLedgerItemColumn) => void;
  handleDeleteLedgerParty: (item: any) => void;
  t: TFunction;
  resolver:any,
  handledeleteLink:(order_id: string, paymentRecordId: string, associationKind: string, associationId: string, associationLedgerRow: string) => void,
  state:any,
  dispatch: React.Dispatch<any>
};

const AccountingScene: FC<AccountingScenetype> = ({
  accountingData,
  handleUpdateLedger,
  t,
  handleDeleteLedgerItem,
  handleDeleteLedgerParty,
  resolver,
  handledeleteLink,
  state,
  dispatch
}) => {
  const [currentTab, setcurrentTab] = useState('money-flow');

  const handleChange = (event: any, index: string) => {
    setcurrentTab(index);
  };

  const classes = useStyles();

  const kindList = useMemo(() => accountingData.reduce<string[] | undefined>((prev, acc) => {
    if (prev && !prev.includes(acc.kind)) {
      return [...prev!, acc.kind];
    }
    return prev;
  }, []) || [], [accountingData]);

  const handleAddParty = (row:any) => {
    dispatch({
      type: 'add-association',
      entryLedger: row,
      associationSelected: null
    });
  };

  const handleEditAmount = (row:any) => {
    dispatch({
      type: 'add-entry',
      entryLedger: row,
      associationSelected: null
    });
  };

  const handleEditLedgerAssociation = (row:any) => {
    dispatch({
      type: 'add-association',
      entryLedger: row.ledgerInfo,
      associationSelected: row.row
    });
  };

  const handleDeleteLedgetEntry = (row:any) => {
    handleDeleteLedgerItem(row);
  };

  const handleCreationLink = (row:any) => {
    dispatch({
      type: 'link',
      entryLedger: row,
      associationSelected: null
    });
  };

  return (
    <>
      <AccountingContext.Provider value={{
        handleAddParty,
        handleDeleteLedgetEntry,
        handleEditLedgerAssociation,
        handleEditAmount,
        handleDeleteLegerAssociation: handleDeleteLedgerParty,
        handledeleteLink,
        handleCreateLink: handleCreationLink
      }}
      >
        <Grid className={classes.padding}>
          <Paper>
            <Header
              title={t('accounting:accounting')}
              handleAddEntry={() => {
                dispatch({
                  type: 'add-entry',
                  associationSelected: null,
                  entryLedger: null
                });
              }}
            />

            <TabContext value={currentTab}>
              {accountingData.length === 0 && (
              <Grid
                item
                xs={12}
                className={clsx(classes.noinformation, classes.padding)}
              >
                <Typography className={classes.noinformationFont}>
                  {t('accounting:void-ledger')}
                </Typography>
              </Grid>
              )}
              <Grid item>
                <Tabs
                  variant="scrollable"
                  value={currentTab}
                  onChange={handleChange}
                >
                  <Tab key="money-flow" currentValue={currentTab} value="money-flow" text="Money Flow" />
                  {['ledger', ...kindList].map((kind) => (
                    <Tab
                      key={kind}
                      currentValue={currentTab}
                      value={kind}
                      text={capitalize(kind.replace(/_/g, ' '))}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <TabPanel style={{ padding: 24 }} value="money-flow">
                  <SankeyDiagram accounting={accountingData} />
                </TabPanel>
                <TabPanel style={{ padding: 24 }} value="ledger">
                  <Ledger
                    ledgerData={accountingData}
                  />
                </TabPanel>
                {kindList.map((kind) => (
                  <TabPanel className={classes.padding} value={kind} key={kind}>
                    <Ledger
                      ledgerData={accountingData.filter((acc) => acc.kind.includes(kind))}
                    />
                  </TabPanel>
                ))}
              </Grid>
            </TabContext>
          </Paper>
        </Grid>
      </AccountingContext.Provider>
      {state.mode === 'add-entry' && (
        <AddEntry
          open={state.mode === 'add-entry'}
          handleClose={() => {
            dispatch({
              entryLedger: null,
              associationSelected: null,
              mode: null
            });
          }}
          isEditingEntry={state.entryLedger !== null}
          onSuccesAddEntry={handleUpdateLedger}
          prevData={state.entryLedger !== null

            ? {
              ...state.entryLedger,
              _id: state.entryLedger.id,
              entry_date: state.entryLedger.entryDate,
            }
            : null}
          resolver={resolver}
          hideFields={state.entryLedger !== null ? {
            code: true,
            amount: true,
            kind: true,
          } : { amount_override: true }}
        />
      )}
      {state.mode === 'add-association' && (
        <AddAndUpdatePartyLedgerEntry
          handleClose={() => {
            dispatch({
              entryLedger: null,
              associationSelected: null,
              mode: null
            });
          }}
          handleSuccess={handleUpdateLedger}
          entryLedger={
            state.entryLedger !== null
              ? {
                ...state.entryLedger,
                _id: state.entryLedger.id,
                entry_date: state.entryLedger.entryDate,
              }
              : ({} as AccountingType)
          }
          partyLedgerEntry={state.associationSelected}
        />
      )}
      {state.mode === 'link' && (
      <ModalLinkentry
        ledgerData={accountingData}
        currentLedgerEntry={state.entryLedger !== null
          ? {
            ...state.entryLedger,
            _id: state.entryLedger.id,
            entry_date: state.entryLedger.entryDate,
          }
          : ({} as AccountingType)}
        open={state.mode === 'link'}
        onClose={() => {
          dispatch({
            entryLedger: null,
            associationSelected: null,
            mode: null
          });
        }}
        handleUpdateAccounting={handleUpdateLedger}
      />
      )}
    </>
  );
};

export default AccountingScene;
