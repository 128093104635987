import { listOrders } from 'graphql/orders';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getProfile } from 'redux/profile-reducer/selectors';
import { ListOrdersParams } from 'types/common';
import { OrderListingType } from 'types/order';

import {
  CurrentData,
  FiltersType,
  intialReducerState,
  reducerModals,
} from './services';

const useOrders = (agencySelected) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { accesses } = useSelector(getProfile);
  const [orders, setOrders] = useState<OrderListingType[]>([]);
  const [filter, setFilter] = useState<FiltersType>('all');
  const [searchString, setSearchString] = useState('');
  const [state, dispatch] = useReducer(reducerModals, intialReducerState);
  const [currentData, setCurrentData] = useState<CurrentData>({
    page: 0,
    pages: 0,
  });

  const [params, setParams] = useState<ListOrdersParams>({
    agencyId: agencySelected,
    searchText: '',
    pagination: {
      items: 10,
      page: 1,
    },
  });

  const getOrders = useQuery([params, 'orders'], listOrders, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const agencyName =
    accesses.find((access) => access._id === agencySelected)?.name || '';

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      agencyId: agencySelected,
      pagination: {
        ...prev.pagination,
        page: 1,
      },
    }));
  }, [agencySelected]);

  useEffect(() => {
    if (getOrders.isSuccess && getOrders.data) {
      setOrders(getOrders.data.listOrders.orders);
      setCurrentData({
        page: getOrders.data.listOrders.pagination.page,
        pages: getOrders.data.listOrders.pagination.pages,
      });
    }
  }, [getOrders]);

  useEffect(() => {
    if (searchString.trim() === '') {
      setParams((prev) => ({
        ...prev,
        orderId: '',
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }

    // eslint-disable-next-line no-undef
    let timeoutId: NodeJS.Timeout | undefined;

    if (searchString.trim() !== '') {
      timeoutId = setTimeout(
        () =>
          setParams((prev) => ({
            ...prev,
            searchText: searchString,
            pagination: {
              ...prev.pagination,
              page: 1,
            },
          })),
        300
      );
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [searchString]);

  return {
    t,
    history,
    filter,
    setFilter,
    orders,
    loading: getOrders.isFetching,
    currentData,
    userIsTitleEmployee: accesses.length > 0,
    searchString,
    setSearchString,
    setParams,
    agencyName,
    state,
    dispatch,
  };
};

export default useOrders;
