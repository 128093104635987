import { Grid } from '@material-ui/core';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { propertyTypeOptions } from 'views/orders/properties/components/tab-property/services';

import { formInfoOrder } from '../services';
import SectionTitle from './section-title';

type PDFInformationProps = {
	control:Control<formInfoOrder, object>;
	states:{
		code: string;
		parent: string;
		name: string;
	}[]
}

const PropertyPDFInformation:FC<PDFInformationProps> = ({ control, states }) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitle title="Property Information" />
      <Grid item>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Controller
              control={control}
              name="address"
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('common:address')}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="zip_code"
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('common:zip-code')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { ref, ...field } }) => (
                    <SelectField
                      inputRef={ref}
                      label={t('common:state')}
                      value={field.value}
                      handleBlur={field.onBlur}
                      handleChange={(e) => {
							  field.onChange(e);
                      }}
                      data={states}
                      dataKey="code"
                      dataValue="code"
                      dataText="name"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="city"
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('common:city')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="county"
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('common:county')}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="property_type"
                  render={({ field: { ref, ...field } }) => (
                    <SelectField
                      inputRef={ref}
                      label={t('orders:property-type')}
                      value={field.value}
                      handleBlur={field.onBlur}
                      handleChange={(e) => {
                        field.onChange(e);
                      }}
                      data={propertyTypeOptions(t)}
                      dataKey="value"
                      dataValue="value"
                      dataText="text"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="country"
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('common:country')}
                      disabled
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="references"
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('common:references')}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PropertyPDFInformation;
