import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, Collapse, IconButton, TableCell, TableRow, Typography
} from '@material-ui/core';
import { ResponseSource } from 'components/inference/types';
import React, { FC } from 'react';
import { transformBytes } from 'utils/helpers';

import { useStyles } from './styles';

type RowProps = {
  source: ResponseSource
}

export const Row: FC<RowProps> = ({ source }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const sourceHasText = source.text && source.text.length > 0;

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>
          {sourceHasText && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <FontAwesomeIcon icon="angle-up" /> : <FontAwesomeIcon icon="angle-down" />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {source.filePath}
        </TableCell>
        <TableCell className={classes.tableCell}>{source.fileName}</TableCell>
        <TableCell className={classes.tableCell}>{source.fileType}</TableCell>
        <TableCell className={classes.tableCell}>{transformBytes(source.fileSize)}</TableCell>
        <TableCell className={classes.tableCell}>{source.creationDate}</TableCell>
        <TableCell className={classes.tableCell}>{source.lastModifiedDate}</TableCell>
        <TableCell className={classes.tableCell}>{source.lastAccessedDate}</TableCell>
      </TableRow>
      {sourceHasText && (
        <TableRow>
          <TableCell />
          <TableCell colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div" color="primary">
                  Source
                </Typography>
                <Typography
                  style={{
                    whiteSpace: 'pre-wrap',
                    backgroundColor: '#f3f3f3',
                    borderRadius: '10px',
                    padding: '10px'
                  }}
                  variant="body2"
                  color="primary"
                >
                  {source.text}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
