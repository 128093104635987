import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CustomPartyOption from 'components/add-contact-modal/components/custom-party-option';
import TextField from 'components/text-field';
import ContactCreation from 'components/users/form-contact-creation/contact-creation';
import React, { FC, useState } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewUserType } from 'v2-types/user';

import { formInfoOrder } from '../services';

const useStyles = makeStyles((theme: any) => ({
  titleRole: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
  containerSelectedUser: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1)
  }
}));

type PartiesItemSearchPros = {
  partyText: string,
  role: string,
  control: Control<formInfoOrder, object>
  index: number,
  parties: NewUserType[]
  setValue: UseFormSetValue<formInfoOrder>

}

const PartiesItemSearch: FC<PartiesItemSearchPros> = ({
  partyText,
  role,
  control,
  index,
  parties,
  setValue
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [contactCreation, setContatCreation] = useState(false);
  const [newContactKind, setNewContactKind] = useState<string>("Person");
  const [contactSelected, setContactSelected] = useState<string | null>(null);
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={6}>
        <Typography variant="body2" className={classes.titleRole}>{`role: ${role.replace('_', ' ')}`}</Typography>
        <Typography variant="body2" style={{ fontWeight: 500 }}>{partyText}</Typography>
      </Grid>
      <Grid item xs={6}>
        {contactCreation ? (
          <Box display="flex" flex="1" flexDirection="column" className={classes.containerSelectedUser}>
            <ContactCreation
              selectedKind={newContactKind}
              kindList={['Person', 'Company']}
              onSuccess={({ _id, name }) => {
                setValue(`parties.${index}.partySelected`, _id);
                setContactSelected(name);
                setContatCreation(false);
              }}
              handleCancel={() => {
                setContatCreation(false);
              }}
            />
          </Box>
        ) : (
          <Controller
            control={control}
            name={`parties.${index}.partySelected`}
            rules={{ required: true }}
            render={({ field: { ...field } }) => (
              field.value === ''
                ? (
                  <Autocomplete
                    fullWidth
                    options={parties}
                    getOptionLabel={(party) => party.name || ''}
                    onChange={(_, value) => {
                      field.onChange(value?._id);
                      setContactSelected(value?.name || '');
                    }}
                    filterOptions={(options) => (options.length > 0
                      ? [...options, {
                        _id: 'add-new-contact',
                        username: '',
                        user_id: '',
                        name: '',
                        emails: [],
                        addresses: [],
                        phones: []
                      } as unknown as NewUserType
                      ]
                      : []
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={params.InputProps.ref}
                        InputProps={params.inputProps}
                        label={t('parties:contact')}
                      />
                    )}
                    renderOption={(props) => (
                      <CustomPartyOption
                        contact={props}
                        handleContactCreation={(kind) => {
                          setContatCreation(true);
                          setNewContactKind(kind);
                        }}
                        compact
                        t={t}
                      />
                    )}
                  />
                ) : (
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" justifyContent="space-between" className={classes.containerSelectedUser}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1" style={{ fontWeight: 500, color: 'black' }}>{`${contactSelected}`}</Typography>
                      </Box>
                      <Box>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            field.onChange('');
                          }}
                        >
                          <FontAwesomeIcon icon="trash-alt" size="xs" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                )
            )}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PartiesItemSearch;
