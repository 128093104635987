/* eslint-disable react/no-array-index-key */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { UpdateOrderSpecific } from 'types/order';
import { capitalize } from 'utils/helpers';

import SignatureLine from './components/signature-line';
import { options } from './services';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  vestingGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 5
  },
  primarycolorfonts: { fontWeight: 500, color: theme.palette.tab.offselected },
  descriptionText: { fontWeight: 500 },
  inputs: {
    '& .MuiInputBase-input': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
    },
    '&:focus': { backgroundColor: theme.palette.background.default },
  },
  filledInputs: {
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
      borderTopLeftRadius: theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.background.default,
    },
    '&:focus': { backgroundColor: theme.palette.background.default },
  },
  buttonFilled: {
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
    '&:hover': { backgroundColor: theme.palette.button.hover }
  },
  //
  containerBlock: { marginTop: theme.spacing(2) },
  containesDescBlock: { marginBottom: theme.spacing(2) },
  buttontoadd: { textTransform: 'none', marginRight: theme.spacing(5) },
  spouseblockcontainer: { marginTop: theme.spacing(5) },
  titleblock: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.tab.offselected
  },
  bottomform: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    fontWeight: 500,
    color: theme.palette.tab.offselected
  },
  iconSize: { fontSize: '0.875rem' },
  buttonRemove: { textTransform: 'none' }
}));

type TabSignatureType = {
  t: TFunction,
  haveSpouse: boolean,
  organization: boolean,
  include_vesting_value: boolean,
  single_signature_methods: UseFieldArrayReturn<UpdateOrderSpecific, 'data_buyer.vesting_block' | 'data_seller.vesting_block', 'id'>,
  formMethods: UseFormReturn<UpdateOrderSpecific, any>,
  prefix: 'data_buyer' | 'data_seller'
}

const TabSignatureScene: FC<TabSignatureType> = ({
  t,
  haveSpouse,
  organization,
  include_vesting_value,
  single_signature_methods,
  formMethods,
  prefix
}) => {
  const classes = useStyles();
  const { control, setValue, getValues } = formMethods;
  return (
    <Grid container className={classes.container}>
      <Grid item className={classes.containerBlock}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box className={classes.containesDescBlock}>
            <Typography variant="h6" className={classes.primarycolorfonts}>{capitalize(t('orders:signature-block'))}</Typography>
            <Typography variant="caption" className={classes.descriptionText}>{capitalize(t('orders:block-signature-desc'))}</Typography>
          </Box>
        </Box>
        {single_signature_methods.fields.map((signature, index) => (
          <Box key={signature.id}>
            <SignatureLine
              organization={organization}
              t={t}
              control={control}
              onDown={() => single_signature_methods.swap(index, index + 1)}
              onUp={() => single_signature_methods.swap(index, index - 1)}
              disabledUp={index === 0}
              disableddown={index === single_signature_methods.fields.length - 1}
              onDelete={() => single_signature_methods.remove(index)}
              disableDelete={index === 1 || index === 0}
              name={`${prefix}.vesting_block`}
              index={index}
              setValue={setValue}
              idItem={signature.id}
            />
          </Box>
        ))}
        <Box display="flex" flexDirection="row" className={classes.containerBlock}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FontAwesomeIcon icon="plus" size="xs" className={classes.iconSize} />}
            className={classes.buttontoadd}
            onClick={() => {
              single_signature_methods.append({
                kind: 'text',
                tabs: 0,
                text: '',
              });
            }}
          >
            {capitalize(t('orders:add-blank-line'))}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<FontAwesomeIcon icon="plus" size="xs" className={classes.iconSize} />}
            style={{ textTransform: 'none' }}
            onClick={() => {
              single_signature_methods.append({
                kind: 'line',
                tabs: 0,
                text: ''
              });
            }}
          >
            {capitalize(t('orders:add-signature-block'))}
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="h6" className={classes.bottomform}>{capitalize(t('orders:vesting'))}</Typography>
        <Box
          display="flex"
          flexDirection="row"
        >
          <Box
            display="flex"
            flexDirection="column"
            flex={2}
          >
            {haveSpouse && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Controller
                  name={`${prefix}.include_marital`}
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Checkbox
                      {...field}
                      ref={ref}
                      checked={getValues(`${prefix}.include_marital`)}
                    />
                  )}
                />
                <Typography variant="caption">{capitalize(t('orders:include-marital-status'))}</Typography>
              </Box>
            ) }
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Controller
                name={`${prefix}.include_vesting`}
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Checkbox
                    {...field}
                    ref={ref}
                    checked={getValues(`${prefix}.include_vesting`)}
                  />
                )}
              />
              <Typography variant="caption">{capitalize(t('orders:include-vesting-type'))}</Typography>
            </Box>
          </Box>
          <Box
            className={classes.vestingGroup}
          >
            <Controller
              name={`${prefix}.vesting_type`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <SelectField
                  {...field}
                  inputRef={ref}
                  value={getValues(`${prefix}.vesting_type`)}
                  data={options(t)}
                  dataKey="value"
                  dataValue="value"
                  dataText="text"
                  label={capitalize(t('orders:vesting-type'))}
                  disabled={!include_vesting_value}
                  handleChange={field.onChange}
                />
              )}
            />
            <Controller
              name={`${prefix}.vesting`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  value={getValues(`${prefix}.vesting`)}
                  disabled={!include_vesting_value}
                  label={capitalize(t('orders:vesting-all'))}
                />
              )}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TabSignatureScene;
