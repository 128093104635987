/* eslint-disable radix */
import formatISO from 'date-fns/formatISO';
import { editManualCharge } from 'graphql/ledger/mutations';
import { getLedgerListings } from 'graphql/ledger/queries';
import { useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { listingItemtype } from 'types/ledger';
import useAlert from 'utils/alert';
import { validateAllowedKindTypes } from 'utils/filterItemsbykind';
import { capitalize } from 'utils/helpers';
import { AccountingFilter, AccountingType } from 'v2-types/order';

import { addManualCharge } from './services/mutations';
import { EntryLedgerType } from './services/types';

const useAddRecipts = (
  handleClose: () => void,
  handleSuccess: (accounting: AccountingType[]) => void,
  prevData: AccountingType | null,
  kindAllowedEntry?: string | string[],
  codeAllowedEntry?: string | string[],
  resolver?: Resolver<EntryLedgerType, object> | undefined,
  isEditingEntry?: boolean,
  accountingFilter?: AccountingFilter
) => {
  const { t } = useTranslation();
  const [createErrors, setcreateErrors] = useState<string[]>([]);
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const [allowedCodes, setAllowedCodes] = useState<listingItemtype[]>([]);
  const [allowedKinds, setAllowedKinds] = useState<listingItemtype[]>([]);
  const methods = useForm<EntryLedgerType>({
    defaultValues: {
      entryDate: prevData?.entry_date || null,
      description: prevData?.description || '',
      code: prevData?.code || '',
      amount: prevData?.amount || 0,
      kind: prevData?.kind || '',
      letter: prevData?.letter || '',
      number: prevData?.number || null,
      amount_override: prevData?.amount_override || prevData?.amount,
      amount_calculated: prevData?.amount_calculated || 0,
      months: prevData?.months || 12,
      months_advanced: prevData?.months_advanced || null,
      annuality: prevData?.annuality || null,
    },
    resolver,
  });

  const [AddManualcharge, responseAddManualcharge] = useMutation(addManualCharge, {
    onSuccess: (data) => {
      if (data) {
        showAlert(capitalize(t('dialogs:order-updated')), 'success');
        handleSuccess(data.createOrderLedgerEntry.accounting || []);
        responseAddManualcharge.reset();
        methods.reset();
        handleClose();
      }
    },
    onError: (e: any) => {
      const { errors = ['Error Adding Association'] } = e?.response || {};
      const errorsMapped = errors.map((error: any) => error.message);
      setcreateErrors(errorsMapped);
    },
  });

  const [EditManualcharge, responseEditManualcharge] = useMutation(editManualCharge, {
    onSuccess: (data) => {
      if (data) {
        showAlert(capitalize(t('dialogs:order-updated')), 'success');
        responseEditManualcharge.reset();
        methods.reset();
        handleClose();
        handleSuccess(data.updateOrderLedgerEntry?.accounting || []);
      }
    },
    onError: () => {
      showAlert(capitalize(t('dialogs:there-is-an')), 'error');
    },
  });
  const getLedgerAllowedKinds = useQuery(
    ['allowed-ledger-kinds', 'allowed_ledger_kinds'],
    () => getLedgerListings('allowed_ledger_kinds'),
    {
      enabled: !prevData?.kind,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.getListing.entries) {
          setAllowedKinds(validateAllowedKindTypes(data?.getListing?.entries, kindAllowedEntry));
        }
      },
    }
  );

  const getLedgerAllowedCodes = useQuery(
    ['allowed-ledger-Codes', 'allowed_ledger_codes'],
    () => getLedgerListings('allowed_ledger_codes'),
    {
      enabled: !prevData?.code,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.getListing.entries) {
          setAllowedCodes(validateAllowedKindTypes(data?.getListing?.entries, codeAllowedEntry));
        }
      },
    }
  );

  const getLedgerAllowedLetters = useQuery(
    ['allowed_ledger_letters', 'allowed_ledger_letters'],
    () => getLedgerListings('allowed_ledger_letters'),
    { refetchOnWindowFocus: false, enabled: !!prevData?.letter }
  );

  const handleEntry = methods.handleSubmit((data) => {
    if (isEditingEntry) {
      EditManualcharge({
        id: orderId!,
        accountingId: prevData?._id!,
        data: {
          amount_override: parseFloat(data.amount_override as unknown as string) || 0,
          entry_date: data?.entryDate || null,
          letter: data.letter === '' ? null : data.letter,
          months_advanced: data.months_advanced
            ? parseInt(data.months_advanced as unknown as string)
            : null,
          number: data.number ? parseFloat(data.number as unknown as string) : null,
          months: data.months ? parseInt(data.months as unknown as string) : null,
          annuality: parseFloat(data.annuality as unknown as string) || null,
          description: data.description,
        },
        filters: accountingFilter ? { accounting: accountingFilter } : undefined,
      });
      return;
    }

    AddManualcharge({
      id: orderId!,
      description: data.description,
      code: data.code,
      kind: data.kind,
      number: data.number ? parseFloat(data.number as unknown as string) : null,
      amount: parseFloat(data.amount as unknown as string) || 0,
      months: data.months ? parseInt(data.months as unknown as string) : null,
      entryDate: data?.entryDate ? formatISO(new Date(data.entryDate)) : null,
      letter: data.letter === '' ? null : data.letter,
      months_advanced: data.months_advanced
        ? parseInt(data.months_advanced as unknown as string)
        : null,
      annuality: parseFloat(data.annuality as unknown as string) || null,
      filters: accountingFilter ? { accounting: accountingFilter } : undefined,
    });
  });

  return {
    allowedCodes,
    allowedKinds,
    allowedLetters: getLedgerAllowedLetters.data?.getListing.entries,
    loadingKinds: getLedgerAllowedKinds.isLoading,
    loadingCodes: getLedgerAllowedCodes.isLoading,
    loadingLetters: getLedgerAllowedLetters.isLoading,
    loadingEditEntry: responseEditManualcharge.isLoading,
    loadingAddNewEntry: responseAddManualcharge.isLoading,
    handleEntry,
    methods,
    createErrors,
    t,
  };
};

export default useAddRecipts;
