import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import HeaderPayoffs from './components/header-payoffs';
import MortgageDetail from './components/mortgage-detail/mortgage-details';
import VoidPayoffs from './components/placeholder-payoffs';
import MortgagesContext from './services/context';
import { OrderMortgageType } from './services/types.d';

const useStyles = makeStyles((theme:any) => ({ container: { padding: theme.spacing(3) } }));

type MortgageSceneProps = {
	mortgages: OrderMortgageType[];
	loadingMortgages: boolean;
}

const MortgagePayoffsScene:FC<MortgageSceneProps> = ({ mortgages, loadingMortgages }) => {
  const { path, url } = useRouteMatch();
  const classes = useStyles();
  return (
    <MortgagesContext.Provider value={{
      mortgages,
      loadingMortgages
    }}
    >
      <Grid container direction="column" className={classes.container}>
        <Paper>
          <HeaderPayoffs />
          <Grid item xs={12}>
            <Switch>
              <Route exact path={path}>
                <Grid>
                  <VoidPayoffs />
                </Grid>
              </Route>
              <Route exact path={`${path}/mortgage/:mortgageId`}>
                <MortgageDetail />
              </Route>

              <Route path="*">
                <Redirect to={url} />
              </Route>
            </Switch>
          </Grid>
        </Paper>
      </Grid>
    </MortgagesContext.Provider>
  );
};

export default MortgagePayoffsScene;
