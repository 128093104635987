import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(10),
    borderBottomStyle: 'solid',
    borderBottomColor: theme.accent.main,
    borderBottomWidth: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    '& :nth-child(2)': { marginLeft: 'auto' },
  },
  title: {
    fontSize: 23,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
  },
}));
