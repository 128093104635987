import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

import Header from '../components/header';
import PayorInfo from '../components/payor-info';
import { columnsDataSet } from '../components/table-charges-kmln/services';
import { chargesSorted } from '../services';
import HudPopoverButton from './components/hud-popover-button';
import HudTablewithTitle from './components/hud-table-with-title';
import TotalChargesRow from './components/total-charges-row';
import TotalStateBrokerTable from './components/total-state-broker-fees';
import {
  formatDataKM,
  generateSingleGenericAccounting,
  getDisabledFieldsL,
  getHideFieldsL,
  mockData,
} from './services';

const useStyles = makeStyles((theme: any) => ({
  borderCellStyle: {
    borderColor: theme.palette.input.border,
    borderWidth: 1,
    borderStyle: 'solid',
    opacity: 1,
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
  },
  padding: { padding: theme.spacing(3) },
  table: { background: theme.palette.background.paper },
  containerTable: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
  },
  scrollDiv: {
    overflowX: 'auto',
    width: '100%',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.5) },
    '&&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark, },
  },
}));

type ChargesLProps = {
  accountingData: AccountingType[];
  handleUpdateAccounting: (acc: AccountingType[]) => void;
  handleEditModal: (item: columnsDataSet) => void;
  handlePayor: (item: columnsDataSet) => void;
  handleDelete: (item: columnsDataSet) => void;
  openAddModal: boolean;
  handleaddModal: (modal: string | null) => void;
  state: { type: string; data?: any };
};

type selectedRowType = {
  anchorEl: Element;
  number: number;
  kind: string;
};

const ChargesLScene: FC<ChargesLProps> = ({
  accountingData,
  handleUpdateAccounting,
  handleEditModal,
  handleDelete,
  handlePayor,
  openAddModal,
  handleaddModal,
  state,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [mapedData, setMapedData] = useState(
    mockData(chargesSorted(accountingData, true))
  );

  useEffect(() => {
    if (accountingData.length > 0) {
      setMapedData(mockData(chargesSorted(accountingData, true)));
    }
  }, [accountingData]);

  const currentChargeData = accountingData.find(
    (acc) => acc._id === state.data?.chargeData?._id
  );

  const [selectedRow, setSelectedRow] = useState<selectedRowType | null>(null);

  const handleClosePopover = () => {
    setSelectedRow(null);
  };

  const openPopover = Boolean(selectedRow);

  const handleClick = (event: React.MouseEvent, number, kind) => {
    setSelectedRow({
      anchorEl: event.currentTarget,
      number,
      kind,
    });
  };

  const handleAddRow = () => {
    if (selectedRow) {
      const lastIndex = parseInt(mapedData[selectedRow.kind].at(-1).number, 10) + 1;

      const genericRowAccounting = formatDataKM([
        generateSingleGenericAccounting(lastIndex, 'L'),
      ]);

      const newRows = [
        mapedData[selectedRow.kind],
        genericRowAccounting,
      ].flat();

      setMapedData({ ...mapedData, [selectedRow.kind]: newRows });
    }
  };

  return (
    <Grid container direction="column" className={classes.padding}>
      <Grid item xs={12}>
        <Paper>
          <Header
            title={t('charges:settlement-charges')}
            chargesIdentifier="L"
          />
          <Box
            className={`${classes.scrollDiv} ${classes.containerTable}`}
            flex="1"
          >
            <TotalStateBrokerTable
              totalBrokerFees={mapedData.totalBrokerFees}
              handleEdit={(item) => {
                handleEditModal({ ...item, indexRow: 1 });
              }}
              handleDelete={(item) => {
                handleDelete({ ...item, indexRow: 1 });
              }}
              handleSelectItem={(item) => {
                handlePayor({ ...item, indexRow: 1 });
              }}
              handleClick={(event, number) => {
                handleClick(event, number, 'totalBrokerFees');
              }}
            />
            <HudTablewithTitle
              hudCharges={mapedData.itemspayablewithLoan}
              title={t('charges:items-payable-loan')}
              titleNumber={800}
              handleEdit={(item) => {
                handleEditModal({ ...item, indexRow: 1 });
              }}
              handleDelete={(item) => {
                handleDelete({ ...item, indexRow: 1 });
              }}
              handleSelectItem={(item) => {
                handlePayor({ ...item, indexRow: 1 });
              }}
              handleClick={(event, number) => {
                handleClick(event, number, 'itemspayablewithLoan');
              }}
            />

            <HudTablewithTitle
              hudCharges={mapedData.itemsRequiredyByLender}
              title={t('charges:items-payable-required')}
              titleNumber={900}
              handleEdit={(item) => {
                handleEditModal({ ...item, indexRow: 1 });
              }}
              handleDelete={(item) => {
                handleDelete({ ...item, indexRow: 1 });
              }}
              handleSelectItem={(item) => {
                handlePayor({ ...item, indexRow: 1 });
              }}
              handleClick={(event, number) => {
                handleClick(event, number, 'itemsRequiredyByLender');
              }}
            />

            <HudTablewithTitle
              hudCharges={mapedData.reservesDepositedLender}
              title={t('charges:reserves-deposited')}
              titleNumber={1000}
              handleEdit={(item) => {
                handleEditModal({ ...item, indexRow: 1 });
              }}
              handleDelete={(item) => {
                handleDelete({ ...item, indexRow: 1 });
              }}
              handleSelectItem={(item) => {
                handlePayor({ ...item, indexRow: 1 });
              }}
              handleClick={(event, number) => {
                handleClick(event, number, 'reservesDepositedLender');
              }}
            />

            <HudTablewithTitle
              hudCharges={mapedData.titleCharges}
              title={t('charges:title-charges')}
              titleNumber={1100}
              handleEdit={(item) => {
                handleEditModal({ ...item, indexRow: 1 });
              }}
              handleDelete={(item) => {
                handleDelete({ ...item, indexRow: 1 });
              }}
              handleSelectItem={(item) => {
                handlePayor({ ...item, indexRow: 1 });
              }}
              handleClick={(event, number) => {
                handleClick(event, number, 'titleCharges');
              }}
            />

            <HudTablewithTitle
              hudCharges={mapedData.governmentRecordingCharges}
              title={t('charges:goverment-charges')}
              titleNumber={1200}
              handleEdit={(item) => {
                handleEditModal({ ...item, indexRow: 1 });
              }}
              handleDelete={(item) => {
                handleDelete({ ...item, indexRow: 1 });
              }}
              handleSelectItem={(item) => {
                handlePayor({ ...item, indexRow: 1 });
              }}
              handleClick={(event, number) => {
                handleClick(event, number, 'governmentRecordingCharges');
              }}
            />

            <HudTablewithTitle
              hudCharges={mapedData.additionalSettlementCharges}
              title={t('charges:aditional-charges')}
              titleNumber={1300}
              handleEdit={(item) => {
                handleEditModal({ ...item, indexRow: 1 });
              }}
              handleDelete={(item) => {
                handleDelete({ ...item, indexRow: 1 });
              }}
              handleSelectItem={(item) => {
                handlePayor({ ...item, indexRow: 1 });
              }}
              handleClick={(event, number) => {
                handleClick(event, number, 'additionalSettlementCharges');
              }}
            />
          </Box>
          <TotalChargesRow
            totalChargesRow={mapedData.totalSttlementCharges[0]}
            handleEdit={(item) => {
              handleEditModal({ ...item, indexRow: 1 });
            }}
            handleDelete={(item) => {
              handleDelete({ ...item, indexRow: 1 });
            }}
            handleClick={(event, number) => {
              handleClick(event, number, 'totalSttlementCharges');
            }}
          />
        </Paper>
      </Grid>
      {state.type === 'parties' && currentChargeData && (
        <Grid item xs={12}>
          <PayorInfo
            handleSuccess={handleUpdateAccounting}
            chargeData={currentChargeData}
          />
        </Grid>
      )}

      {openAddModal && (
        <AddLedgerEntry
          open={!!openAddModal}
          handleClose={() => {
            handleaddModal(null);
          }}
          onSuccesAddEntry={handleUpdateAccounting}
          prevData={state.type === 'edit' ? state.data?.chargeData! : null}
          kindAllowedEntry={['manual']}
          codeAllowedEntry={['charge']}
          disableFields={getDisabledFieldsL(state)}
          hideFields={getHideFieldsL(state)}
          isEditingEntry={
            state.type === 'edit' && state.data?.chargeData?._id.length > 10
          }
        />
      )}

      {selectedRow && (
        <HudPopoverButton
          open={openPopover}
          handleClose={handleClosePopover}
          anchorEl={selectedRow.anchorEl}
          handleAddRow={handleAddRow}
          t={t}
        />
      )}
    </Grid>
  );
};

export default ChargesLScene;
