import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ContainedButton } from 'components/ContainedButton';
import React from 'react';
import { HeaderCardType } from 'types/payments';

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(10),
    borderBottomStyle: 'solid',
    borderBottomColor: theme.accent.main,
    borderBottomWidth: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    '& :nth-child(2)': { marginLeft: 'auto' },
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

const HeaderCard = ({ t, handleToggleModals }: HeaderCardType) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box>
        <Typography variant="h5" className={classes.title}>
          {t('payments:wire-tranfers')}
        </Typography>
      </Box>
      <Box>
        <ContainedButton
          text={t('payments:create-wire-transfer')}
          onClick={() => handleToggleModals('createWire')}
        />
      </Box>
    </Box>
  );
};

export default HeaderCard;
