import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

type informationRowProps ={
	title:string;
	value:string;
	warning?:boolean
}

const useStyles = makeStyles((theme:any) => ({
  labelStyle: { fontWeight: 500, color: 'black' },
  valueStyle: { fontWeight: 500 },
  warnningStyle: { color: theme.palette.error.main }
}));

const InformationRow:FC<informationRowProps> = ({ title, value, warning }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="row">
      <Typography
        variant="body1"
        className={clsx(classes.labelStyle, { [classes.warnningStyle]: warning })}
      >
        {`${title}:`}
      </Typography>
      <Typography
        variant="body1"
        className={clsx(classes.valueStyle, { [classes.warnningStyle]: warning })}
      >
        {`\u00A0${value}`}
      </Typography>
    </Box>
  );
};

export default InformationRow;
