export type EntryLedgerType = {
	entryDate: string | null,
	description: string,
	code: string,
	amount: number,
}

export type disbledfields<T> = {
	[Property in keyof T]?: boolean;
}

/* eslint-disable no-shadow */
export enum EntryLedgerCodeEnum {
  CASH = 'cash',
  CHECK = 'check',
  WIRE = 'wire'
}
