import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { Control, useFieldArray, UseFormSetValue } from 'react-hook-form';

import { formInfoOrder } from '../services';
import PartiesItemSearch from './parties-item-search';
import SectionTitle from './section-title';
import SkeletonParties from './skeleton-parties';

type partiesFormProps = {
	loadingParties:boolean,
	control:Control<formInfoOrder, object>,
	setValue: UseFormSetValue<formInfoOrder>
}

const PartiesForm:FC<partiesFormProps> = ({ control, loadingParties, setValue }) => {
  const { fields } = useFieldArray({
    control,
    name: 'parties'
  });

  return (
    <>
      <SectionTitle title="Parties" />
      <Grid item>
        <Grid container direction="column" spacing={1}>
          {loadingParties ? <SkeletonParties /> : fields.map((item, index) => (
            <Grid item key={item.id}>
              <PartiesItemSearch
                control={control}
                partyText={(item as any).text}
                role={(item as any).role}
                index={index}
                parties={item.parties}
                setValue={setValue}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default PartiesForm;
