import { getLedgerListings } from 'graphql/ledger/queries';
import {
  createAutoTemplate,
  deleteAutoTemplate,
  updateAutoTemplate,
} from 'graphql/templates/mutations';
import {
  getDocumentTemplates,
  getTemplateTags,
} from 'graphql/templates/queries';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { AutoTemplate, NewTemplateType } from 'types/auto-templates';
import useAlert from 'utils/alert';

const useTemplates = () => {
  const showAlert = useAlert();
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [autoTemplates, setAutoTemplates] = useState<AutoTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<AutoTemplate>();
  const [codeTemplates, setCodeTemplates] = useState<string[]>([]);

  useQuery(
    ['autoTemplates'],
    getDocumentTemplates,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setAutoTemplates(data.getAutoTemplates);
      },
    }
  );

  const { data: templateTagsResponse } = useQuery(['templateTags'], getTemplateTags, { refetchOnWindowFocus: false, });

  const { data: partyRollsResponse } = useQuery(
    ['allowed_order_associations'],
    () => getLedgerListings('allowed_order_associations'),
    { refetchOnWindowFocus: false, }
  );

  const [createTemplate] = useMutation(createAutoTemplate, {
    onSuccess: (data) => {
      setAutoTemplates([...autoTemplates, data.createAutoTemplate]);
      showAlert('Template was created', 'success');
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
    },
  });

  const [deleteTemplate] = useMutation(deleteAutoTemplate, {
    onSuccess: (data) => {
      const deleteTemplateData = data.deleteAutoTemplate;
      if (deleteTemplateData._id === selectedTemplate?._id) {
        setSelectedTemplate(undefined);
      }
      setAutoTemplates(
        autoTemplates.filter(
          (template) => template._id !== deleteTemplateData._id
        )
      );
      showAlert('Template was deleted', 'success');
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
    },
  });

  const [updateTemplate] = useMutation(updateAutoTemplate, {
    onSuccess: (data) => {
      const templateData = data.updateAutoTemplate;
      setAutoTemplates(
        autoTemplates.map((template) => {
          if (template._id === templateData._id) {
            return { ...templateData };
          }
          return template;
        })
      );
      showAlert('Template was updated', 'success');
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
    },
  });

  const tagsField = useMemo(() => {
    if (templateTagsResponse && templateTagsResponse.getTemplateTags) {
      return [
        ...templateTagsResponse.getTemplateTags.filter(
          (tag) => tag.kind === 'text'
        ),
      ];
    }
    return [];
  }, [templateTagsResponse?.getTemplateTags]);

  const tagsImages = useMemo(() => {
    if (templateTagsResponse && templateTagsResponse.getTemplateTags) {
      return [
        ...templateTagsResponse.getTemplateTags.filter(
          (tag) => tag.kind === 'image'
        ),
      ];
    }
    return [];
  }, [templateTagsResponse?.getTemplateTags]);

  const tagsSignatures = useMemo(() => {
    if (templateTagsResponse && templateTagsResponse.getTemplateTags) {
      return [
        ...templateTagsResponse.getTemplateTags.filter(
          (tag) => tag.kind === 'signatures'
        ),
      ];
    }
    return [];
  }, [templateTagsResponse?.getTemplateTags]);

  const partyRollsMemorized = useMemo(() => {
    if (partyRollsResponse && partyRollsResponse.getListing) {
      return [...partyRollsResponse.getListing.entries];
    }
    return [];
  }, [partyRollsResponse?.getListing]);

  const handleDeleteTemplate = (id: string) => {
    deleteTemplate({ id });
  };

  const handleUpdateTemplate = (template: NewTemplateType) => {
    updateTemplate({
      id: template._id,
      ...template
    });
  };

  useEffect(() => {
    setCodeTemplates(autoTemplates.map((template) => template.code));
  }, [autoTemplates]);

  const handleCreateTemplate = (newTemplate: NewTemplateType) => {
    createTemplate(newTemplate);
  };

  return {
    autoTemplates,
    partyRollsMemorized,
    open,
    setOpen,
    handleCreateTemplate,
    handleDeleteTemplate,
    setSelectedTemplate,
    selectedTemplate,
    handleUpdateTemplate,
    setOpenEditModal,
    openEditModal,
    tagsField,
    tagsImages,
    tagsSignatures,
    codeTemplates,
  };
};

export default useTemplates;
