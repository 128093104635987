import { makeStyles } from '@material-ui/core';
import { useSankeyDiagram } from 'hooks/useSankeyDiagram';
import { SankeyLink, SankeyNode } from 'hooks/useSankeyDiagram/types';
import React, { memo, useMemo, useRef } from 'react';
import { AccountingType } from 'v2-types/order';

const useStyles = makeStyles((theme: any) => ({
  noinformation: {
	  display: 'flex',
	  justifyItems: 'center',
  },
  noinformationFont: {
	  color: theme.palette.common.black,
	  fontWeight: 500,
	  textAlign: 'center',
  },
  padding: { padding: theme.spacing(3) },
}));
const SankeyDiagram = ({ accounting }:{accounting:AccountingType[]}) => {
  const sankeyData = useMemo(() => {
    const nodes: SankeyNode[] = [
		  { name: 'Loan Amount', category: 'Premium' },
		  { name: 'Purchase Price', category: 'Premium' }
    ];
    const links: SankeyLink[] = [];
    accounting.forEach((entry) => {
		  if (entry.kind === 'property' || entry.involved_parties?.length) {
        if (nodes.filter((data) => data.name === entry.description).length === 0) {
			  nodes.push({ name: entry.description, category: entry.kind });
        }
		  }
		  if (entry.kind === 'property') {
        if (entry.code.startsWith('loan_amount')) { links.push({ source: entry.description, target: 'Loan Amount', value: entry.amount.toString() }); }
        if (entry.code.startsWith('purchase_price')) { links.push({ source: entry.description, target: 'Purchase Price', value: entry.amount.toString() }); }
		  }

		  entry.involved_parties.forEach((party) => {
        if (nodes.filter((data) => data.name === party.name).length === 0) { nodes.push({ name: party.name, category: entry.kind }); }
        if (nodes.filter((data) => data.name === party.kind).length === 0) { nodes.push({ name: party.kind, category: 'Association' }); }
        links.push({ source: party.name, target: entry.description, value: party.amount.toString() });
        links.push({ source: party.name, target: party.kind, value: party.amount.toString() });
        links.push({ source: entry.description, target: party.kind, value: party.amount.toString() });
		  });
    });

    return {
		  nodes,
		  links
    };
	  }, [accounting]);
	  const htmlSankeyDiagramRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  useSankeyDiagram({
    htmlDivElementRef: htmlSankeyDiagramRef,
    sankeyNodes: sankeyData.nodes,
    sankeyLinks: sankeyData.links
  });
  return (
    <div ref={htmlSankeyDiagramRef} style={{ width: '100%' }} className={classes.padding} />
  );
};

export default memo(SankeyDiagram);
