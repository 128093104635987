import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import { EntryLedgerType } from 'components/add-entry-modal/services/types';
import React, { FC } from 'react';
import { DisabledFields } from 'types/disabledFields';
import { AccountingType } from 'v2-types/order';

import useTableCharges from './table-charges-hooks';
import TableChargesScene from './table-charges-scene';

type ChargesTableProps = {
	updateAccounting: (accunting:AccountingType[]) =>void,
  accountingData:AccountingType[],
  handleClickItem?: (item:any)=>void,
  itemSelected?: any | null,
  hideFields?: DisabledFields<EntryLedgerType>;
}

const ChargesTable:FC<ChargesTableProps> = ({
  updateAccounting,
  accountingData,
  itemSelected,
  handleClickItem,
  hideFields
}) => {
  const {
    columns,
    mapedData,
    itemsCollapsed,
    modalData,
    setModalData
  } = useTableCharges(accountingData);

  const onClose = () => setModalData({ show: '', row: undefined });

  return (
    <>
      <TableChargesScene
        columns={columns}
        mapedData={mapedData}
        handleClickItem={handleClickItem}
        itemsCollapsed={itemsCollapsed}
      />
      {(modalData?.show === 'add-ledger' || modalData?.show === 'edit-ledger') && (
      <AddLedgerEntry
        open
        handleClose={onClose}
        onSuccesAddEntry={updateAccounting}
        kindAllowedEntry={['manual']}
        codeAllowedEntry={['charge']}
        hideFields={hideFields}
        prevData={modalData.show === 'edit-ledger' ? itemSelected?.chargeData : null}
        isEditingEntry={modalData.show === 'edit-ledger'}
      />
      )}
    </>
  );
};

export default ChargesTable;
