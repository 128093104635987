import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

export type InferenceResponse = {
  metadata: {
    [key:string]: {
      page_label: string,
      file_name: string,
      file_path: string,
      file_type: string,
      file_size: number,
      creation_date: string,
      last_modified_date: string,
      last_accessed_date: string,
      text: string
    }
  },
  question: string,
  response: string
}

export type GQLGetInference = {
  getInference: InferenceResponse
}

export const getInference = async (payload: { question: string }) => {
  const query = gql`
    query GetInference($question: String!) {
      getInference(question: $question) {
        metadata
        question
        response
      }
    }
  `;

  const response = await graphQLClient().request(query, payload);
  return response;
};
