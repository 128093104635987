import { Grid, makeStyles, Typography } from '@material-ui/core';
import EmptyParties from 'assets/images/empty_parties.png';
import { TFunction } from 'i18next';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  item: { maxWidth: 500 },
  icon: {
    width: 70,
    height: 80,
    marginTop: theme.spacing(5),
    color: theme.palette.tab.offselected,
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.tab.offselected,
    textAlign: 'center'
  },
  label: {
    color: theme.palette.tab.offselected,
    textAlign: 'center'
  }
}));

type ContainerProps = {
  title: string;
  label: string;
};

const Container: React.FC<ContainerProps> = ({ title, label }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Grid item className={classes.item}>
        <img src={EmptyParties} alt="void parties" />
      </Grid>

      <Grid item className={classes.item}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      </Grid>

      <Grid item className={classes.item}>
        <Typography variant="body1" className={classes.label}>
          {label}
        </Typography>
      </Grid>

    </Grid>
  );
};

type EmptyContainerProps = {
  t: TFunction
};

const EmptyContainer: React.FC<EmptyContainerProps> = ({ t }) => (
  <Grid container direction="row" style={{ height: '100%' }}>
    <Grid item xs={12}>
      <Container
        title={t('parties:not-selected')}
        label={t('parties:not-selected-label')}
      />
    </Grid>
  </Grid>
);

export default EmptyContainer;
