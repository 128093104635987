import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  dialgoTitleContainer: { paddingBottom: '0' },
  dialogTitle: {
    color: '#000000',
    textAlign: 'center'
  },
  closeIconContainer: { alignSelf: 'flex-end', },
  closeIcon: { color: '#000000', },
  actionsContainer: {
    display: 'flex',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    justifyContent: 'flex-end',
    gap: theme.spacing(2)
  },
  selectInput: { padding: '10px', marginTop: '10px', fontSize: '0.875rem', color: '#000000' }
}));
