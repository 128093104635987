import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentOrder } from 'redux/order-reducer/selectors';
import useSendOrderToServer from 'utils/send-order-to-server';

import { initialValues as fnInitialValues, } from './services/initial-values';

const useLoanTerms = () => {
  const { t } = useTranslation();
  const { updateOrder } = useSendOrderToServer(true, 'disclosures', 'disclosures');

  const order = useSelector(getCurrentOrder);
  const year_range_to = order.loan?.loan_term_years || 1;
  const principal_interest_amount = order.disclosures?.loan_terms?.loan_term_table?.monthly_principal_interest?.amount || 0;
  const { disclosures } = order;
  const { projected_payments } = disclosures || {};
  const initialValues = projected_payments
    || fnInitialValues(year_range_to, principal_interest_amount, 0, 0);

  return {
    t,
    initialValues,
    disclosures,
    orderId: order?.id || '',
    updateOrder,
  };
};

export default useLoanTerms;
