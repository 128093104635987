import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { NewPhoneType, NewUserType } from 'types/new-user';
import * as Yup from 'yup';

import NumberField from '../number-field';
import TextField from '../text-field';
import { PhoneHelperType } from './types';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
  emptyTitleContainer: { marginBottom: theme.spacing(2) },
  buttonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  buttonText: {
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  iconContainer: { textAlign: 'center' },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: theme.spacing(1),
  },
  createButtonContainer: { marginTop: theme.spacing(2) },
  createButton: {
    height: 30,
    width: '100%',
    textTransform: 'capitalize',
  },
  createButtonIcon: { marginRight: theme.spacing(1) },
}));

type ModalCreatePhoneProps = {
  t: TFunction;
  open: boolean;
  add: (data: NewPhoneType, onClose?: () => void) => void;
  onClose: () => void;
};

const simplePhoneRegex = /\d{10}/;
const ModalCreatePhone: FC<ModalCreatePhoneProps> = ({
  t,
  open,
  add,
  onClose,
}) => {
  const classes = useStyles();

  const schema = Yup.object({
    number: Yup.string().matches(simplePhoneRegex, "Phone number is not valid.").max(10, "Phone number is too long"),
    kind: Yup.string().required(t('validations:required')),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NewPhoneType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { kind: '', number: '' },
  });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>
        {t('users:create-phone')}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit((data) => add(data, handleOnClose))}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="number"
                control={control}
                render={({ field: { value, onBlur, ...field } }) => (
                  <NumberField
                    {...field}
                    allowNegative={false}
                    decimalScale={0}
                    label={t('users:phone-number')}
                    value={value as number | null}
                    handleBlur={onBlur}
                    error={!!errors.number}
                    helperText={errors.number?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="kind"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:kind')}
                    error={!!errors.kind}
                    helperText={errors.kind?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    disableElevation
                    disabled={!isValid}
                    variant="contained"
                    color="secondary"
                    className={classes.buttonText}
                  >
                    {t('users:create')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

type FormPhonesProps = {
  t: TFunction;
  helpers: PhoneHelperType;
};

const FormPhones: FC<FormPhonesProps> = ({ t, helpers }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const { control, formState: { errors } } = useFormContext<NewUserType>();
  const { fields: phones } = useFieldArray({ name: 'phones', control });

  return (
    <>
      {!phones.length && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} className={classes.emptyTitleContainer}>
            <Typography variant="body2" className={classes.title}>
              {t('users:no-phones')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => setOpen(true)}
            >
              {t('users:create')}
            </Button>
          </Grid>
        </Grid>
      )}

      {!!phones.length && (
        <>
          <Box>
            {phones.map((phone, index) => (
              <Grid
                key={phone._id}
                container
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Controller
                    name={`phones.${index}.number`}
                    control={control}
                    render={({ field: { value, onBlur, ...field } }) => (
                      <NumberField
                        {...field}
                        allowNegative={false}
                        decimalScale={0}
                        label={t('users:phone-number')}
                        value={value as number | null}
                        handleBlur={() => {
                          const error = !!errors.phones;

                          if (!error) {
                            helpers.phones[index].number.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.phones && !!errors.phones[index] && !!errors?.phones[index]?.number}
                        helperText={errors.phones && errors.phones[index] && errors?.phones[index]?.number?.message}

                      />
                    )}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Controller
                    name={`phones.${index}.kind`}
                    control={control}
                    render={({ field: { ref, onBlur, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        label={t('users:kind')}
                        handleBlur={() => {
                          const error = !!errors.phones;

                          if (!error) {
                            helpers.phones[index].kind.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.phones && !!errors.phones[index] && !!errors?.phones[index]?.kind}
                        helperText={errors.phones && errors.phones[index] && errors?.phones[index]?.kind?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={1} className={classes.iconContainer}>
                  <IconButton
                    className={classes.icon}
                    onClick={() => helpers.remove(phone._id || '')}
                  >
                    <FontAwesomeIcon icon="times" size="xs" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Box>

          <Grid container className={classes.createButtonContainer}>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.createButton}
                onClick={() => setOpen(true)}
              >
                <FontAwesomeIcon
                  icon="plus-square"
                  className={classes.createButtonIcon}
                />

                {t('users:add-new')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <ModalCreatePhone
        t={t}
        open={open}
        onClose={() => setOpen(false)}
        add={helpers.add}
      />
    </>
  );
};

export default FormPhones;
