import { useTheme } from '@material-ui/core';
import TableComponent from 'components/Table';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { columnsDataSet, generateKDueAdjustments, generatekDues } from './services';

type TableProtokduestype = {
	type: 'adjustments' | 'dues' | 'addendums',
	title: string,
	handleEditModal:(item:columnsDataSet)=> void,
	handlePayor: (item:columnsDataSet)=> void,
	handleDelete: (item:columnsDataSet)=> void,
	data: any
}

const TableProtoKdues: FC<TableProtokduestype> = ({
  type,
  title,
  handleEditModal,
  handleDelete,
  handlePayor,
  data
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = useMemo(() => {
    switch (type) {
      case 'dues':
        return generatekDues(
          t,
          theme,
          title,
          handleEditModal,
          handlePayor,
          handleDelete
        );
	  case 'adjustments':
        return generateKDueAdjustments(
          t,
          theme,
          title,
          handleEditModal,
          handlePayor,
          handleDelete
		  );
		  case 'addendums':
        return generateKDueAdjustments(
          t,
          theme,
          title,
          handleEditModal,
          handlePayor,
          handleDelete,
		  true
			  );
      default: return generatekDues(
        t,
        theme,
        title,
        handleEditModal,
        handlePayor,
        handleDelete
		  );
    }
  }, [handleDelete, handleEditModal, handlePayor, t, theme, title, type]);
  return (
    <TableComponent
      disabledBorderLeft
      columns={columns}
      dataSet={data}
    />
  );
};

export default TableProtoKdues;
