/* eslint-disable import/prefer-default-export */
import {
  ChargesPaymentType,
  NewCheckPaymentType,
  NewWirePaymentType,
} from 'types/common';

export const check: NewCheckPaymentType = {
  id: '',
  instructions: {
    account_name: '',
    reference_number: '',
  },
  mailing_address: {
    address: '',
    zip_code: null,
    state: '',
    city: '',
    county: '',
    apartment: '',
  },
};

export const wire: NewWirePaymentType = {
  id: '',
  instructions: {
    account_name: '',
    account_number: '',
    bank_name: '',
    further_credit_to: '',
    routing_number: null,
  },
  mailing_address: {
    address: '',
    zip_code: null,
    state: '',
    city: '',
    county: '',
    apartment: '',
  },
};

export const chargesPayments: ChargesPaymentType = {
  payment_amount: 0,
  payment_type: '',
  label: '',
  check,
  wire,
  net_funded: {
    name: '',
    payment_amount: 0,
    reference_number: null,
    label: ''
  },
  aggregate: {
    name: '',
    payment_amount: 0,
    label: ''
  },
  transfer: {
    name: '',
    payment_amount: 0,
    reference_number: null,
    order_number: null,
    for_benefit_to: '',
    label: ''
  },
  hold_back: {
    name: '',
    payment_amount: 0,
    reference_number: null,
    label: ''
  }
};

export const initialDisclosuresValues = {
  projected_payments: null,
  loan_disclosures: null,
  ap_table: null,
  air_table: null,
  loan_terms: null,
  cash_to_close: null,
  loan_calculations: null,
};
