import { GQLSearchLocations, searchLocations } from 'graphql/geospatial/queries';
import { iso31661, iso31662 } from 'iso-3166';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Location } from 'types/geospatial';

type LocationData = {
  country: string,
  state: string,
  county: string,
  zcode: string,
  city:string
}

type UseLocationParams = {
  initialData: LocationData,
  onSelectCountry?: () => void,
  onSelectState?: () => void,
  onSelectCounty?: () => void,
  onAutofillByZcode?: ({ state, county, city }: {state: string, county: string, city:string}) => void
}

export const useLocation = ({
  initialData,
  onSelectCountry,
  onSelectState,
  onSelectCounty,
  onAutofillByZcode
}: UseLocationParams) => {
  const [selectedLocation, setSelectedLocation] = useState(initialData);
  const [counties, setCounties] = useState<Location[]>([]);
  const [zcodes, setZcodes] = useState<Location[]>([]);
  const [cities, setCities] = useState<Location[]>([]);
  const [countryCounties, setCountryCounties] = useState<Location[]>([]);
  const countries = iso31661;
  const statesBycountry = iso31662.filter((state) => state.parent === selectedLocation.country).map((state) => ({
    ...state,
    code: state.code.replace(`${selectedLocation.country}-`, '')
  }));

  const { isLoading: isCountyListLoading } = useQuery<GQLSearchLocations>(
    ['county-list', selectedLocation.state],
    () => searchLocations({
      kind: 'state',
      name: statesBycountry.find((state) => state.code === selectedLocation.state)?.name ?? '',
      return: ['county']
    }),
    {
      refetchOnWindowFocus: false,
      enabled: selectedLocation.country === 'US' && selectedLocation.state !== '',
      onSuccess: (data) => {
        if (!data.searchLocations.some((location) => location.name === selectedLocation.county)) {
          setSelectedLocation((prevLocation) => ({
            ...prevLocation,
            county: ''
          }));
        }
        setCounties(data.searchLocations);
        setCountryCounties(data.searchLocations);
      }
    }
  );

  const { isLoading: isZcodesListLoading } = useQuery<GQLSearchLocations>(
    ['zcode-list', selectedLocation.county],
    () => searchLocations({
      kind: 'county',
      name: selectedLocation.county,
      return: ['zcta']
    }),
    {
      refetchOnWindowFocus: false,
      enabled: selectedLocation.country === 'US' && selectedLocation.state !== '' && selectedLocation.county !== '',
      onSuccess: (data) => setZcodes(data.searchLocations)
    }
  );

  const { isLoading: isLoadinZcodeInfo } = useQuery<GQLSearchLocations>(
    ['zcodeInfo', selectedLocation.zcode],
    () => searchLocations({
      kind: 'zcta',
      name: selectedLocation.zcode,
      return: ['county', 'state', 'urban_area']
    }),
    {
      refetchOnWindowFocus: false,
      enabled: selectedLocation.zcode.length >= 5 && selectedLocation.country === 'US',
      onSuccess: (data) => {
        if (data.searchLocations.length > 0) {
          const states = data.searchLocations.filter((location) => location.kind === 'state');
          const stateCode = states.length > 0 ? statesBycountry.find((state) => state.name === states[0].name)?.code ?? '' : '';
          const countyList = data.searchLocations.filter((location) => location.kind === 'county');
		  const citiesList = data.searchLocations.map((location) => ({ ...location, name: location.name.split(',')[0].replace('--', ', ') })).filter((location) => location.kind === 'urban_area');
		  setCounties(countyList || []);
		  setCities(citiesList || []);
          setSelectedLocation((prevLocation) => {
            const state = stateCode.length > 0 ? stateCode : prevLocation.state;
            const county = countyList.length > 0 ? countyList[0].name : prevLocation.county;
            const city = citiesList.length > 0 ? citiesList[0].name : prevLocation.city;
            if (onAutofillByZcode) {
              onAutofillByZcode({
                state,
                county,
                city
              });
            }
            return ({
              ...prevLocation,
              state,
              county
            });
          });
        }
      }
    }
  );

  const selectCountry = (country: string) => {
    setSelectedLocation({
      country,
      state: '',
      county: '',
      zcode: '',
	  city: ''
    });
    setZcodes([]);
    setCounties([]);
    if (onSelectCountry) {
      onSelectCountry();
    }
  };

  const selectState = (state: string) => {
    setSelectedLocation((prevLocation) => ({
      ...prevLocation,
      state,
      county: '',
      zcode: ''
    }));
    setZcodes([]);
    setCounties([]);
    if (onSelectState) {
      onSelectState();
    }
  };

  const selectCounty = (county: string) => {
    setSelectedLocation((prevLocation) => ({
      ...prevLocation,
      county,
      zcode: ''
    }));
    setZcodes([]);
    if (onSelectCounty) {
      onSelectCounty();
    }
  };

  const selectZcode = (zcode: string) => {
    if (zcode === '') {
      setCounties(countryCounties);
	  setCities([]);
    }
    setSelectedLocation((prevLocation) => ({
      ...prevLocation,
      zcode
    }));
  };

  return {
    selectedCountry: selectedLocation.country,
    selectedState: selectedLocation.state,
    selectedCounty: selectedLocation.county,
    selectedZcode: selectedLocation.zcode,
    countries,
    statesBycountry,
    counties,
    zcodes,
    cities,
    selectCountry,
    selectState,
    selectCounty,
    selectZcode,
    isCountyListLoading,
    isZcodesListLoading,
    isLoadinZcodeInfo
  };
};
