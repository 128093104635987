import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

import Header from '../components/header';
import TableComponent from '../components/prototype-new-table-charges/new-table-charges';
import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import TableLoader from '../components/skeleton-table-charges';
import DocumentsDetails from './components/documents-details';
import TaxCriteria from './components/tax-criteria';

const useStyles = makeStyles((theme) => ({ padding: { padding: theme.spacing(3) }, }));

type ChargesProtitypeProps = {
  accountingData: AccountingType[];
  handleSetAccounting: (accountingData: AccountingType[]) => void;
  currentRowSelected: columnsDataSet | null;
  handleSelectRow: (row: columnsDataSet) => void;
  loadingAccounting: boolean;
};

const ChargesEScene: FC<ChargesProtitypeProps> = ({
  accountingData,
  handleSetAccounting,
  currentRowSelected,
  handleSelectRow,
  loadingAccounting,
}) => {
  const classes = useStyles();
  const Memoizedocuments = useMemo(
    () => accountingData.filter((acc) => acc.code === 'document'),
    [accountingData]
  );
  const filtereCharges = accountingData.filter((f) => f.code !== 'document' && f.number !== null && f.letter === 'E');
  const { t } = useTranslation();
  return (
    <Grid container direction="column" className={classes.padding}>
      <Grid item xs={12} style={{ marginBottom: 16 }}>
        <Paper>
          <Header
            title={t('charges:taxes-and-goverment-fees')}
            chargesIdentifier="E"
          />
          <DocumentsDetails
            accountingData={Memoizedocuments}
            handleUpdateAccounting={handleSetAccounting}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Paper>
          <TaxCriteria taxAmount={8.5} />
          {loadingAccounting ? (
            <TableLoader />
          ) : (
            <TableComponent
              accountingData={filtereCharges}
              handleClickItem={(item) => {
                handleSelectRow(item);
              }}
              itemSelected={currentRowSelected}
              updateAccounting={handleSetAccounting}
              chargesIdentifier="E"
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChargesEScene;
