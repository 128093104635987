/* eslint-disable max-len */
import { useEffect, useReducer, useState } from 'react';
import { HumanMessage } from "@langchain/core/messages";
import { ChatOpenAI } from "@langchain/openai";
import { TOKEN_CLAIMS } from 'utils/constants';
import {
  generatePDFRequest,
  intialReducerState,
  processResponse,
  stateData
} from './services';

const useModalChargesDocument = () => {
  const [dataPDF, setDataPDF] = useState<any>(null);
  const [arrayCharges, setArrayCharges] = useState<any>([]);
  const [state, dispatch] = useReducer(stateData, intialReducerState);
  useEffect(() => {
    const inferenceImage = async (base64Images:string[]) => {
      dispatch({ type: 'loading-data' });
      const access_token = await localStorage.getItem(TOKEN_CLAIMS);
      const model = new ChatOpenAI({
        configuration: {
          baseURL: 'https://llm.titleproject.space',
        },
        modelName: 'claude-haiku',
        openAIApiKey: access_token!,
        temperature: 0.1,
        maxTokens: 4096
      });
      try {
        const res = await model.invoke(
          [new HumanMessage({ content: [
            {
              type: "text",
              text: `
							give me all the charges with description specified under the letters A,B,C,D,E,F,G,H and J in JSON without spaces group by the letter with the next format: 
                        		* number: number specified un the charge, is it is not specified put 1
								* description: description of the charge, if it is not specified put null
								* letter: letter in which this charge is located
								* borrower_at_closing: amount specified to pay in the at closing column paid by borrower, if there is not specified put 0
								* borrower_before_closing: amount specified to pay in the before closing column paid by borrower, if there is not specified put 0
								* seller_at_closing: amount specified to pay in the at closing column paid by seller, if there is not specified put 0
								* seller_before_closing: amount specified to pay in the before closing column paid by seller, if there is not specified put 0
								* by_others: amount specified to pay in the by others column
								* payee: when the charge description has a 'to' word, who belongs this charge, put only the name, remove the word 'to'
							remove all entries with null description from the JSON
						`
            },
            ...generatePDFRequest(base64Images)
          ] })]);
		  const arrayChargesParsed = processResponse(res.content as string);
		  setArrayCharges(arrayChargesParsed);
		  dispatch({ type: 'fetched' });
      } catch (e) {
        dispatch({ type: 'error', data: 'there was some error fetching the data,please try again' });
      }
    };
    if (dataPDF?.images.length > 0 && state.mode === 'fetch') {
      inferenceImage(dataPDF?.images || []);
    }
  }, [dataPDF, dataPDF?.images, state.mode]);

  return {
    dataPDF,
    setDataPDF,
    arrayCharges,
    state,
    dispatch
  };
};

export default useModalChargesDocument;
