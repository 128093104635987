import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getSignatures } from './graphql/querys';

const useSignDocuments = () => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{id:string}>();
  const [openModal, setOpenModal] = useState(false);
  const signedDocuments = useQuery(
    ['getSignatures', orderId],
    () => getSignatures(orderId),
    {
      refetchOnWindowFocus: false,
      enabled: !!orderId
    }
  );
  return {
    t,
    signatures: signedDocuments.data?.getSignatures || [],
    openModal,
    setOpenModal
  };
};

export default useSignDocuments;
