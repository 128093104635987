import React from 'react';

import { useDocumentRequestHooks } from './document-request-hooks';
import { DocumentRequestScene } from './document-request-scene';

export const DocumentRequest = () => {
  const {
    t,
    documentRequestInfo,
    isValidToken,
    isLoading,
    isSendButtonDisable,
    showThanksTrustMessage,
    onCloseWindow,
    onAddFile,
    onRemoveFile,
    uploadIsLoading,
    onSendFiles
  } = useDocumentRequestHooks();

  return (
    <DocumentRequestScene
      t={t}
      documentRequestInfo={documentRequestInfo}
      isLoading={isLoading}
      isSendButtonDisable={isSendButtonDisable}
      isValidToken={isValidToken}
      showThanksTrustMessage={showThanksTrustMessage}
      closeWindow={onCloseWindow}
      onAddFile={onAddFile}
      onRemoveFile={onRemoveFile}
      uploadIsLoading={uploadIsLoading}
      onSendFiles={onSendFiles}
    />
  );
};
