/* eslint-disable react-hooks/exhaustive-deps */
import { getParty } from 'graphql/parties/queries';
import useSearchParty from 'hooks/useSearchParty';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import graphql from 'services/querys';
import { NewUserType } from 'types/new-user';
import { NewOrderPartyType } from 'types/order';
import useAlert from 'utils/alert';

import PartiesContext from '../../../services/context';

const useTabagenthooks = (
  party: NewUserType | undefined,
  roleParty: string,
  kind: string,
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const context = useContext(PartiesContext);
  const { filterParties } = context || {};
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [agentID, setagentID] = useState<any>(null);
  const [agentData, setagentData] = useState<NewUserType | undefined>(
    undefined
  );
  const { parties } = useSearchParty(searchText);
  const { t } = useTranslation();

  const { id: orderId } = useParams<{ id: string }>();
  const [agentSelected, setagentSelected] = useState<NewOrderPartyType | null>(
    null
  );
  const showAlert = useAlert();
  const [addagent, addagentResponse] = useMutation(graphql.createAssociation, {
    onSuccess: (data) => {
      setTimeout(() => {
        showAlert(t(`parties:${kind.toLowerCase()}-aded`), 'success');
        if (data?.createOrderAssociation.parties && filterParties) {
          filterParties(data?.createOrderAssociation?.parties!);
        }
        addagentResponse.reset();
        setagentID(null);
        setSearchText(undefined);
        setLoaderActive(false);
      }, 1500);
    },
    onError: () => {
      setLoaderActive(false);
      showAlert(t('parties:mutation-error'), 'error');
    },
  });
  const [deleteagent, deleteagentResponse] = useMutation(
    graphql.deleteAssociation,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          setagentData(undefined);
          setagentID(null);
          setLoaderActive(false);
          showAlert(t(`parties:${kind.toLowerCase()}-deleted`), 'success');

          if (data?.deleteOrderAssociation.parties && filterParties) {
            filterParties(data?.deleteOrderAssociation?.parties!);
          }
          deleteagentResponse.reset();
        }, 1500);
      },
      onError: () => {
        setLoaderActive(false);
        showAlert(t('parties:mutation-error'), 'error');
      },
    }
  );

  const getPartyResponse = useQuery(
    ['getParty', agentSelected?._id],
    () => getParty({ id: agentSelected?._id ?? '' }),
    {
      enabled: agentSelected && agentData === undefined,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (orderId && party?._id && agentID) {
      setLoaderActive(true);
      addagent({
        id: orderId,
        partyId: agentID,
        linkedToId: party?._id,
        kind,
        linkedToKind: roleParty,
      });
    }
  }, [addagent, agentID, party?._id, orderId, roleParty, kind]);

  useEffect(() => {
    if (getPartyResponse.isSuccess) {
      setagentData(getPartyResponse.data?.getParty);
    }
  }, [getPartyResponse]);

  useEffect(() => {
    if (deleteagentResponse.isLoading) {
      showAlert(t(`parties:${kind.toLowerCase()}-deleteding`), 'warning');
    }
  }, [deleteagentResponse, showAlert, kind]);

  useEffect(() => {
    setagentData(undefined);
    setagentID(null);
    setagentSelected(null);
  }, [party]);

  return {
    searchText,
    setSearchText,
    parties,
    t,
    setagentID,
    agentData,
    setagentData,
    addagent,
    deleteagent,
    agentID,
    orderId,
    agentSelected,
    setagentSelected,
  };
};

export default useTabagenthooks;
