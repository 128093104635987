import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import NumberField from 'components/number-field';
import { TFunction } from 'i18next';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { formatCurrencyValueToNumber } from 'utils/helpers';
import DatePicker from 'components/date-picker/date-picker';

import { ProrationsForm } from '../types';

type FormProrationProps = {
  t: TFunction,
  selectedTabIndex: number,
  isLoading: boolean
};

const useStyles = makeStyles((theme: any) => ({
  subTitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
  bottomContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  bottomInnerContainer: {
    padding: theme.spacing(3),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: theme.spacing(0.5),
    borderColor: theme.accent.main,
  },
  buttonOption: { textTransform: 'capitalize' },
}));

export const FormProration = ({
  t,
  selectedTabIndex,
  isLoading
}: FormProrationProps) => {
  const classes = useStyles();
  const { control } = useFormContext<ProrationsForm>();

  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        {t('taxesAndProrations:proration')}
      </Typography>

      <Box display="flex" style={{ gap: "20px" }} flexDirection="column">
        <Box display="flex" style={{ gap: "20px" }}>
          <Controller
            control={control}
            key={`forms.${selectedTabIndex}.start_date`}
            name={`forms.${selectedTabIndex}.start_date`}
            render={({ field }) => (
              <DatePicker
                name="start_date"
                label={t('taxesAndProrations:installment-start-date')}
                value={field.value}
                handleBlur={field.onBlur}
                onChange={field.onChange}
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={control}
            key={`forms.${selectedTabIndex}.end_date`}
            name={`forms.${selectedTabIndex}.end_date`}
            render={({ field }) => (
              <DatePicker
                name="stop_date"
                label={t('taxesAndProrations:installment-end-date')}
                value={field.value}
                handleBlur={field.onBlur}
                onChange={field.onChange}
                disabled={isLoading}
              />
            )}
          />

          <Controller
            control={control}
            key={`forms.${selectedTabIndex}.proration_date`}
            name={`forms.${selectedTabIndex}.proration_date`}
            render={({ field }) => (
              <DatePicker
                name="proration_date"
                label={t('taxesAndProrations:proration-date')}
                value={field.value}
                handleBlur={field.onBlur}
                onChange={field.onChange}
                disabled={isLoading}
              />
            )}
          />

        </Box>

        <Box display="flex" style={{ gap: "20px" }}>
          <Controller
            control={control}
            key={`forms.${selectedTabIndex}.perDiemAmount.amount`}
            name={`forms.${selectedTabIndex}.perDiemAmount.amount`}
            render={({ field }) => {
              return (
                <NumberField
                  color="secondary"
                  label="Per Diem"
                  name="perDiem"
                  handleBlur={field.onBlur}
                  value={field.value}
                  thousandSeparator
                  onValueChange={({ floatValue }) => field.onChange(floatValue)}
                  prefix="$"
                  disabled={isLoading}
                />
              );
            }}
          />

          <Controller
            control={control}
            key={`forms.${selectedTabIndex}.days`}
            name={`forms.${selectedTabIndex}.days`}
            render={({ field }) => (
              <NumberField
                disabled
                name="number_of_days_in_period"
                label={t('taxesAndProrations:number-of-days-in-period')}
                isAllowed={({ floatValue = 0 }) => floatValue > -1 && floatValue < 367}
                value={field.value}
                handleBlur={field.onBlur}
                onChange={(event) => {
                  const value = formatCurrencyValueToNumber(event.target.value, 0, null, null) as number;
                  field.onChange(value);
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FormProration;
