import gqlRequest from 'graphql/generic';
import { AccountingType } from 'v2-types/order';

import { PolicyType } from '../services/types';

type DeleteEndorsement = {
  deleteOrderPolicyEndorsement: {
    accounting: AccountingType[]
  }
};

type EndorsementsParams = {
  id: string;
  kind: 'owners' | 'lenders';
  code: string;
};

const mutationDeleteEndorsement = `
  mutation DeleteOrderPolicyEndorsement($id: ID!, $kind: OrderPolicyKinds!, $code: String!) {
    deleteOrderPolicyEndorsement(_id: $id, kind: $kind, code: $code) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        code
        kind
        letter
        number
        entry_date
        description
        involved_parties {
          _id
          amount
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          name
          order_kinds
          payment_id
          payment_kind
          payment_reference
          percent
        }  
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const deleteEndorsement = (payload: EndorsementsParams) => gqlRequest<DeleteEndorsement, EndorsementsParams>(
  mutationDeleteEndorsement,
  payload,
);

type CreateEndorsement = {
  createOrderPolicyEndorsement: {
    accounting: AccountingType[]
  };
};

const mutationCreateEndorsement = `
  mutation CreateOrderPolicyEndorsement($id: ID!, $kind: OrderPolicyKinds!, $code: String!) {
    createOrderPolicyEndorsement(_id: $id, kind: $kind, code: $code) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        code
        kind
        letter
        number
        entry_date
        description
        involved_parties {
          _id
          amount
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          name
          order_kinds
          payment_id
          payment_kind
          payment_reference
          percent
        }  
      }
    }
  }
`;

export const createEndorsement = (payload: EndorsementsParams) => gqlRequest<CreateEndorsement, EndorsementsParams>(
  mutationCreateEndorsement,
  payload,
);

type deleteLdgerAssociationParams ={
  id: string,
  accountingId:string,
  kind: string,
  partyId: string
}

type deleteAssociationReturn = {
  deleteOrderLedgerAssociation: {
      accounting: AccountingType[] | null;
    }
}

const queryDeleteAssociation = `
mutation Mutation($id: ID!, $accountingId: ID!, $kind: String!, $partyId: ID!) {
  deleteOrderLedgerAssociation(_id: $id, accounting_id: $accountingId, kind: $kind, party_id: $partyId) {
    accounting {
      _id
      amount
      amount_calculated
      amount_override
      code
      description
      entry_date
      involved_parties {
        _id
        amount
        at_closing_amount
        at_closing_percent
        before_closing_amount
        before_closing_percent
        kind
        name
        order_kinds
        payment_id
        payment_kind
        payment_reference
        percent
      }
      kind
      letter
      number
    }
  }
}
`;

export const deleteLedgerAssociation = (params:deleteLdgerAssociationParams) => gqlRequest<deleteAssociationReturn, deleteLdgerAssociationParams>(queryDeleteAssociation, params);

type editentryReturn = {
  updateOrderLedgerEntry: {
      accounting: AccountingType[] | null;
    }
}

const mutationEditEntry = `
mutation UpdateOrderLedgerEntry($id: ID!, $accountingId: ID!, $data: OrderLedgerEntryUpdateContent!) {
  updateOrderLedgerEntry(_id: $id, accounting_id: $accountingId, data: $data) {
    accounting {
      _id
      amount
      amount_calculated
      amount_override
      code
      description
      entry_date
      kind
      letter
      number
      involved_parties {
        _id
        amount
        at_closing_amount
        at_closing_percent
        before_closing_amount
        before_closing_percent
        kind
        name
        order_kinds
        payment_id
        percent
      }
    }
  }
}
`;

type editManualChargeParams = {
 id: string,
  accountingId:string,
  data: {
    amount_override: number | null,
    entry_date?: null | string,
    letter?: null | string,
  }
}

export const editManualCharge = (params:editManualChargeParams) => gqlRequest<editentryReturn, editManualChargeParams>(mutationEditEntry, params);

type UpdateOrder = {
  updateOrderPolicy:{
    accounting: AccountingType[] | null;
  }
};

type UpdateOrderParams = {
  id: string;
  kind: 'owners' | 'lenders',
  data: PolicyType,
};

const mutationUpdateOrder = `
  mutation UpdateOrderPolicy($id: ID!, $kind: OrderPolicyKinds!, $data: OrderPolicyContent!) {
    updateOrderPolicy(_id: $id, kind: $kind, data: $data) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        code
        description
        entry_date
        kind
        letter
        number
        involved_parties {
          _id
          amount
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          name
          order_kinds
          payment_id
          percent
        }
      }
    }
  }
`;

export const updateOrderPolicy = (payload: UpdateOrderParams) => gqlRequest<UpdateOrder, UpdateOrderParams>(
  mutationUpdateOrder,
  payload,
);

export default {};
