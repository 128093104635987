import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { TFunction } from 'i18next';
import React from 'react';
import { ProjectedPaymentsType } from 'types/disclosures';

import EstimatedTaxesSectionTable from './components/estimated-taxes-section-table';
import Header from './components/header';
import PaymentSectionTable from './components/payment-section-table';
import FormContext from './services/formik-context';
// import validationSchema from './services/validation';

const useStyles = makeStyles((theme: any) => ({
  rootContainer: { padding: theme.spacing(3) },
  mainContainer: { padding: theme.spacing(3) },
  header: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
}));

type ProjectedPaymentsSceneProps = {
  t: TFunction;
  initialValues: ProjectedPaymentsType;
  handleSave: (values: ProjectedPaymentsType) => void;
};

const ProjectedPaymentsScene = ({
  t,
  handleSave,
  initialValues,
}: ProjectedPaymentsSceneProps) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={() => validationSchema(t)}
      validateOnBlur
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={(data) => handleSave(data)}
    >
      {({
        values,
        // isValid,
        errors,
        // handleBlur,
        submitForm,
        setFieldValue,
      }) => (
        <>
          <Box className={classes.rootContainer}>
            <Paper>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Header
                    t={t}
                    isValid
                    submitForm={submitForm}
                  />
                </Grid>

                <Grid item xs={12} className={classes.mainContainer}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={classes.header}>
                        {t('projectedPayments:payments')}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <PaymentSectionTable
                        t={t}
                        values={values}
                        paymentsTable={values?.payments}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <EstimatedTaxesSectionTable
                        t={t}
                        estimatedTaxesTable={values?.estimated_taxes}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>

          <FormContext />
        </>
      )}
    </Formik>
  );
};

export default ProjectedPaymentsScene;
