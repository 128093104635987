import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, IconButton } from '@material-ui/core';
import React from 'react';
import { TOKEN_CLAIMS } from 'utils/constants';

export const DocumentViewer = ({ open, handleClose, orderId, archive_id }) => {
  const access_token = localStorage.getItem(TOKEN_CLAIMS);
  const urlCollabora = process.env.REACT_APP_API_BASE_URL_COLLABORA || '';
  const linkCollabora = `${urlCollabora}${orderId}_${archive_id}&access_token=${access_token}`;

  return (
    <Dialog fullScreen open={open} onClose={() => handleClose('')}>
      <Box>
        <IconButton onClick={() => handleClose('')}>
          <FontAwesomeIcon icon="times" />
        </IconButton>
      </Box>
      <Box height="100%">
        <iframe
          title="prev"
          src={linkCollabora}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Dialog>
  );
};

export default DocumentViewer;
