import React, { FC } from 'react';
import { NewOrderPartyType } from 'types/order';

import useSpouseTab from './tab-spouse-hooks';
import SpouseTabScene from './tab-spouse-scene';

type SpouseTabProps = {
  partyId: string;
  roleParty: string;
  spouse: NewOrderPartyType | undefined;
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>;
  loaderActive: boolean;
};
const SpouseTab: FC<SpouseTabProps> = ({
  partyId,
  roleParty,
  spouse,
  setLoaderActive,
  loaderActive,
}) => {
  const {
    t,
    parties,
    setSearchText,
    setSpousetID,
    orderId,
    deleteSpouse,
    spouseData,
    isSpouseDataLoading,
  } = useSpouseTab(partyId, roleParty, spouse, setLoaderActive, loaderActive);

  const handleSearch = (text: string): void => setSearchText(text);

  const handleDeleteSpouse = () => {
    if (orderId && spouse) {
      setLoaderActive(true);
      deleteSpouse({
        id: orderId,
        partyId: spouse._id,
        kind: 'Spouse',
      });
    }
  };

  return (
    <SpouseTabScene
      t={t}
      handleSearch={handleSearch}
      parties={parties}
      setSpousetID={setSpousetID}
      handleDeleteSpouse={handleDeleteSpouse}
      spouseData={spouseData}
      isSpouseDataLoading={isSpouseDataLoading}
    />
  );
};

export default SpouseTab;
