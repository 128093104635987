import {
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import { PaperCard } from 'components/cards/paper-card';
import { ContainedButton } from 'components/ContainedButton';
import { TitleWithTextValue } from 'components/title-with-text-value';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { AccountingType } from 'v2-types/order';

import { AccountingTotals } from './components/accounting-totals/accounting-totals';

const useStyles = makeStyles((theme: any) => ({ divider: { backgroundColor: theme.palette.tab.offselected, opacity: 0.5 } }));

type GeneralInformationSceneProps = {
  t: TFunction,
  orderOpener: string,
  isAccountingTotalsDrawerOpen: boolean,
  setIsAccountingTotalsDrawerOpen: (boolean) => void,
  accountingBalance: AccountingType[],
  orderType: string,
  totalLoanAmount: number,
  totalPurchasePrice: number
}

export const GeneralInformationScene: FC<GeneralInformationSceneProps> = ({
  orderOpener,
  t,
  isAccountingTotalsDrawerOpen,
  setIsAccountingTotalsDrawerOpen,
  accountingBalance,
  orderType,
  totalLoanAmount,
  totalPurchasePrice
}) => {
  const classes = useStyles();

  return (
    <PaperCard title={t('orders:general-info')}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TitleWithTextValue
            title={t('common:transaction-type')}
            value={orderType}
          />
        </Grid>
        <Grid item xs={6}>
          <TitleWithTextValue
            title={t('orders:purchase-price')}
            value={totalPurchasePrice}
            valueIsMoney
          />
        </Grid>
        <Grid item xs={6}>
          <TitleWithTextValue
            title={t('orders:loan-amount')}
            value={totalLoanAmount}
            valueIsMoney
          />
        </Grid>
        <Grid item xs={6}>
          <ContainedButton
            text={t('orders:accounting-totals')}
            onClick={() => setIsAccountingTotalsDrawerOpen(true)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={6}>
          <TitleWithTextValue
            title={t('orders:order-opener')}
            value={orderOpener}
          />
        </Grid>

      </Grid>
      <AccountingTotals
        isOpen={isAccountingTotalsDrawerOpen}
        onClose={() => setIsAccountingTotalsDrawerOpen(false)}
        accountingBalance={accountingBalance}
      />
    </PaperCard>
  );
};
