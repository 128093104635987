import React from 'react';

import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import useGetCharges from '../services/useGetCharges';
import ChargesFPrototpeScene from './charges-f-scene';

const ChargesA = () => {
  const {

    accounting,
    currentRowSelected,
    setCurrentRowSelected,
    handleSetAccounting,
    isLoading: loadingAccounting
  } = useGetCharges({});

  const handleSelectItem = (currentrow:columnsDataSet) => {
    setCurrentRowSelected(currentrow);
  };

  return (
    <ChargesFPrototpeScene
      accountingData={accounting}
      handleSetAccounting={handleSetAccounting}
      currentRowSelected={currentRowSelected}
      handleSelectRow={handleSelectItem}
      loadingAccounting={loadingAccounting}
    />
  );
};

export default ChargesA;
