import { debounce } from 'debounce';
import { memo, useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  handleSave: (formValues: any) => void;
};

const AutoSave = memo<Props>((props) => {
  const form = useFormContext();
  const formData = useWatch({ control: form.control });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(debounce(() => form.handleSubmit(props.handleSave)(), 1000), []);

  useEffect(() => {
    if (form.formState.isDirty) {
      debouncedSave();
    }

    return () => {
      debouncedSave.clear();
    };
  }, [debouncedSave, form.formState.isDirty, formData]);

  return null;
});

export default AutoSave;
