import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase, Grid, TableCell, TableRow } from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import { Text } from 'components/text';
import { TextWithIcon } from 'components/text-with-icon';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';
import { WireTransfersType } from 'types/wire-transfers';
import { convertMsToDate } from 'views/orders/payments/utils';

import {
  rowBorderColor,
  statusSigners,
  wireTransferStatusIcons,
} from '../../utils';
import { InstructionsRow } from './components/InstructionsRow';

type RowProps = {
  t: TFunction;
  language: string;
  transfer: WireTransfersType;
  email: string;
  onClick: () => void;
};

export const Row: FC<RowProps> = ({
  t,
  language,
  transfer,
  email,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const total = transfer.instructions.reduce(
    (acc, element) => acc + element.amount,
    0
  );

  const signers = transfer.authorization.map((mail) => {
    const foundSignature = (transfer.signatures
        && transfer.signatures.find((signature) => signature.email === mail))
      || undefined;
    const status = foundSignature ? foundSignature.action : 'pending';

    return {
      mail,
      status,
    };
  });

  const amISigner = signers.find((signer) => signer.mail === email);
  const isRejected = signers.find((signer) => signer.status === 'block-order');
  const borderColor = transfer.status
    && rowBorderColor[transfer.status.toLowerCase().replace(' ', '')];

  const renderFilename = () => {
    if (transfer.filename) {
      return (
        <TextWithIcon
          value={transfer.filename}
          iconColor="#90a4ae"
          icon="file"
        />
      );
    }

    return transfer.filename;
  };

  const renderStatus = () => {
    if (amISigner && amISigner.status === 'pending' && !isRejected) {
      return (
        <ContainedButton text={t('payments:authorize')} onClick={onClick} />
      );
    }

    if (wireTransferStatusIcons[transfer.status.toLowerCase()]) {
      return (
        <TextWithIcon
          value={transfer.status}
          color="primary"
          icon={wireTransferStatusIcons[transfer.status.toLowerCase()].icon}
          iconColor={
            wireTransferStatusIcons[transfer.status.toLowerCase()].color
          }
          iconSize="1x"
        />
      );
    }

    return <Text value={transfer.status} color="primary" />;
  };

  return (
    <>
      <TableRow hover key={transfer.reference}>
        <TableCell style={{ borderLeft: `6px solid ${borderColor}` }}>
          <ButtonBase onClick={() => setOpen(!open)} style={{ gap: '5px' }}>
            {open ? (
              <FontAwesomeIcon icon="angle-up" />
            ) : (
              <FontAwesomeIcon icon="angle-down" />
            )}
            <Text value={total} isMoney color="primary" />
          </ButtonBase>
        </TableCell>
        <TableCell>{transfer.reference}</TableCell>
        <TableCell>{renderFilename()}</TableCell>
        <TableCell>
          {convertMsToDate({ ms: transfer.security?.data.date_time, language })}
        </TableCell>
        <TableCell>
          <Grid container direction="column" spacing={1}>
            {signers.map((signer) => (
              <Grid item>
                <TextWithIcon
                  value={signer.mail}
                  icon={statusSigners[signer.status].icon}
                  iconColor={statusSigners[signer.status].color}
                />
              </Grid>
            ))}
          </Grid>
        </TableCell>
        <TableCell>{renderStatus()}</TableCell>
      </TableRow>
      <InstructionsRow t={t} open={open} instructions={transfer.instructions} />
    </>
  );
};
