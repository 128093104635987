import React, { FC } from 'react';
import { AccountingType } from 'v2-types/order';

import { useBalancing } from './balancing-hooks';
import { BalancingScene } from './balancing-scene';

type BalancingProps = {
  accountingBalance: AccountingType[]
}

export const Balancing: FC<BalancingProps> = ({ accountingBalance }) => {
  const {
    t,
    postedReceipts,
    expectedReceipts,
    postedDisbursements,
    lenderOwes,
    borrowerOwes,
    earnestOwed,
    sellerDue,
    othersDue,
    balanced
  } = useBalancing({ accountingBalance });
  return (
    <BalancingScene
      t={t}
      postedReceipts={postedReceipts}
      expectedReceipts={expectedReceipts}
      postedDisbursements={postedDisbursements}
      lenderOwes={lenderOwes}
      borrowerOwes={borrowerOwes}
      earnestOwed={earnestOwed}
      sellerDue={sellerDue}
      othersDue={othersDue}
      balanced={balanced}
    />
  );
};
