import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { formType } from '../services';
import { Box, IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import TextField from 'components/text-field';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  errorInput: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.error.main,
    },
  }
}));

type cellEditableProps = {
	control: Control<formType, any>
	name:any,
	label?:string,
	rows?:number,
	required?:boolean,
	showDelete?:boolean,
	onDelete?:()=>void
}

const CellEditable:FC<cellEditableProps> = ({
  control,
  name,
  label,
  rows,
  required,
  showDelete,
  onDelete
}) => {
  const classes = useStyles();
  return (
    <Box
      style={{ width: '100%', }}
    >
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            fullWidth
            label={label}
            variant="standard"
            value={field.value}
            InputProps={{
              disableUnderline: true,
			  size: 'small',
			  margin: 'dense',
			  endAdornment: showDelete && (
  				<InputAdornment position="end">
    				<IconButton
      					style={{ padding: 0 }}
      					onClick={(e) => {
        					e.stopPropagation();
      					  if (onDelete) {
      					    onDelete();
      					  }
					  }}
    				>
      					<FontAwesomeIcon icon="trash-alt" style={{ fontSize: '14px' }} />
    				</IconButton>
  				</InputAdornment>),
			  style: {
                fontSize: '14px',
                color: 'black',
		      }
            }}
            multiline
            customClassName={clsx({ [classes.errorInput]: field.value === '' && required })}
            rows={rows || 0}
          />
        )}
      />
    </Box>
  );
};

export default CellEditable;
