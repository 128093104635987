import {
  Box,
  makeStyles,
  Popover,
  Popper,
} from '@material-ui/core';
import SearchContact from 'components/add-contact-modal/components/simple-search-contact';
import ContactCreation from 'components/users/form-contact-creation/contact-creation';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles((theme: any) => ({
  containerSelectedUser: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

type Props = {
  disabled?: boolean,
  onSelectParty: (contact: { _id: string, name: string } | null) => void
}

export const SearchPartyInput: React.FC<Props> = ({
  disabled,
  onSelectParty
}) => {
  const classes = useStyles();
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [showForm, setShowForm] = useState(false);
  const id = showForm ? 'simple-popover' : undefined;
  const [newContactKind, setNewContactKind] = useState<string>("Person");
  const [searchContactText, setSearchContactText] = useState<string | undefined>();

  return (
    <Box>
      <div ref={searchContainerRef}>
        <SearchContact
          disabled={disabled}
          aria-describedby={id}
          getValue={(user) => {
            onSelectParty(user);
          }}
          handleContactCreation={(kind, searchText) => {
            setNewContactKind(kind);
            setSearchContactText(searchText);
            setShowForm(true);
            setAnchorEl(searchContainerRef.current);
          }}
          popperComponent={(props) => (<Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />)}
        />
      </div>
      <Popover
        id={id}
        open={showForm}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box display="flex" flex="1" flexDirection="column" className={classes.containerSelectedUser}>
          <ContactCreation
            searchText={searchContactText}
            selectedKind={newContactKind}
            kindList={['Person', 'Company']}
            onSuccess={({ _id, name }) => {
              setShowForm(false);
              onSelectParty({ _id, name });
            }}
            handleCancel={() => { setShowForm(false); }}
          />
        </Box>
      </Popover>
    </Box>
  );
};
