import React, { FC } from 'react';
import ModalchargesScene from './modal-charges-document-scene';
import useModalChargesDocument from './modal-charges-document-hooks';

type ModalchargesdocumentProps ={
	open:boolean,
	onClose:()=>void,
}

const ModalChargesdocument:FC<ModalchargesdocumentProps> = ({
  open,
  onClose,
}) => {
  const {
    dataPDF,
    setDataPDF,
    arrayCharges,
    dispatch,
    state
  } = useModalChargesDocument();

  const handleUploadPDF = (param:any) => {
    setDataPDF(param);
  };

  const handleRefetch = () => {
    dispatch({ type: 'fetch' });
  };

  return (
    <ModalchargesScene
      onClose={onClose}
      open={open}
      handleUploadPDF={handleUploadPDF}
      pdfInformation={dataPDF}
      arrayCharges={arrayCharges}
      state={state}
      handleRefetch={handleRefetch}
    />
  );
};

export default ModalChargesdocument;