import { Box, Divider } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { LoanFormType } from 'views/orders/loans/types';

import { ConditionForm } from '../condition-form';
import { ConditionsForm } from './types';
import { formatConditionForm, getValues } from './utils';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>,
}

export const IncreaseLoanAmountConditions: FC<Props> = ({ loanForm }) => {
  const { control } = loanForm;
  const { increase_loan_amount_text } = useWatch({ control });

  const conditionForm = useForm<ConditionsForm>({ mode: 'onChange' });

  const {
    firstConditionExist,
    secondConditionExist,
    firstFieldValue,
    secondFieldValue
  } = useWatch({ control: conditionForm.control });

  useEffect(() => {
    conditionForm.reset(getValues(increase_loan_amount_text));
  }, [conditionForm, increase_loan_amount_text]);

  const saveForm = () => {
    loanForm.setValue('increase_loan_amount_text', formatConditionForm(conditionForm.getValues()), { shouldDirty: true });
  };

  return (
    <Box>
      {firstConditionExist && (
        <ConditionForm
          conditionsFields={[{
            prefix: 'Can go as high as $',
            value: firstFieldValue,
            onChange: (value) => {
              conditionForm.setValue('firstFieldValue', value);
              saveForm();
            }
          }]}
          onRemove={() => {
            conditionForm.setValue('firstConditionExist', false);
            conditionForm.setValue('firstFieldValue', undefined);
            saveForm();
          }}
        />
      )}
      {secondConditionExist && (
      <>
        <Divider />
        <ConditionForm
          conditionsFields={[{
            prefix: 'Can increase until year',
            value: secondFieldValue,
            onChange: (value) => {
              conditionForm.setValue('secondFieldValue', value);
              saveForm();
            },
            format: '####'
          }]}
          onRemove={() => {
            conditionForm.setValue('secondConditionExist', false);
            conditionForm.setValue('secondFieldValue', undefined);
            saveForm();
          }}
        />
      </>
      )}
    </Box>
  );
};
