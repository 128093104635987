const WORKSPACE = 'v2-workspace';

export const getWorkspace = (): { id: string; code: string }[] => {
  const localWorkspace = localStorage.getItem(WORKSPACE);

  return localWorkspace !== null ? JSON.parse(localWorkspace) : [];
};

export const v2_setWorkspace = (data: { id: string, code: string }): { id: string; code: string }[] => {
  const workspace = getWorkspace();
  const index = workspace.findIndex(({ id }) => data.id === id);

  if (index === -1) {
    workspace.push(data);
    localStorage.setItem(WORKSPACE, JSON.stringify(workspace));
  }

  return workspace;
};

export const closeOrder = (index: number): { id: string; code: string }[] => {
  const workspace = getWorkspace();
  workspace.splice(index, 1);
  localStorage.setItem(WORKSPACE, JSON.stringify(workspace));
  return workspace;
};
