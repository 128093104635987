import React from 'react';
import { AccountingType } from 'v2-types/order';

import usePolicyRefactor from './policy-info-refactor-hooks';
import PolicyRefactorScene from './policy-info-refactor-scene';
import { updateType } from './services/context';
import { FormPolicy } from './services/types';

const PolicyRefactor = () => {
  const {
    loadingData,
    accounting,
    methods,
    options,
    selectedPolicy,
    setSelectedPolicy,
    setAccounting,
    selectedEndorsement,
    setSelectedEndorsmement,
    info,
    setInfo,
    updateOrderInfo,
    id,
    typeTransaction,
  } = usePolicyRefactor();

  const handleSelectPolicy = (param: 'owners_policy' | 'lenders_policy') => {
    setSelectedPolicy(param);
  };

  const handleSetAccounting = (accountingData: AccountingType[]) => {
    setAccounting(accountingData);
  };
  const handleSave = (formValues: FormPolicy) => {
    updateOrderInfo({
      id,
      kind:
        formValues.selectedPolicy === 'owners_policy' ? 'owners' : 'lenders',
      data: formValues[formValues.selectedPolicy],
    });
  };

  const handleSelectEndorsement = (accountindInfo: AccountingType | null) => {
    setSelectedEndorsmement(accountindInfo);
  };

  const handleUpdateInfo = (infovalues: updateType) => {
    setInfo(infovalues);
  };

  return (
    <PolicyRefactorScene
      methods={methods}
      accounting={accounting}
      options={options}
      selectedPolicy={selectedPolicy}
      handleSelectPolicy={handleSelectPolicy}
      handleSetAccounting={handleSetAccounting}
      handleSave={handleSave}
      handleSelectEndorsement={handleSelectEndorsement}
      selectedEndorsement={selectedEndorsement}
      info={info}
      handleUpdateInfo={handleUpdateInfo}
      typeTransaction={typeTransaction}
      loadingData={loadingData}
    />
  );
};

export default PolicyRefactor;
