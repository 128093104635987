export const getIcon = (code: string) => {
  switch (code) {
    case 'cash':
      return 'money-bill';
    case 'check':
      return 'ticket-alt';
    case 'wire':
      return 'credit-card';
    default:
      return 'ticket-alt';
  }
};
