import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { Estates, } from 'types/order';
import { AccountingFilter, AccountingType } from 'v2-types/order';

export type GQLGetOrderProperties = {
  getOrder: {
    accounting: AccountingType[]
  } & Estates
};

export const getOrderProperties = async (
  payload: {
    id: string,
    filters?: {[key: string]: AccountingFilter}
  }
) => {
  const query = gql`
    query GetOrder($id: ID!, $filters: OrderFilters) {
      getOrder(_id: $id, filters: $filters) {
        estates {
          _id
          address {
            _id
            address
            country
            geo {
              coordinates
              type
            }
            locality
            postal_code
            references
            settlement
            state
            street_address
          }
          parcel_ids
          lot
          legal_description
          jurisdiction
          block
          range
          subdivision
          section
          survey_date
          survey_information
          survey_number
          township
          type
          accounting {
            _id
            amount
            amount_calculated
            amount_override
            code
            description
            entry_date
            involved_parties {
              _id
              amount
              at_closing_amount
              at_closing_percent
              before_closing_amount
              before_closing_percent
              kind
              member_of {
                _id
                kind
                name
              }
              name
              order_kinds
              payment_id
              payment_kind
              payment_reference
              percent
            }
            kind
            letter
            number
          }
          loans {
            _id
            loan_number
            accounting {
              _id
              amount
              code
              description
            }
          }
        }
        accounting {
          _id
          amount
          amount_calculated
          amount_override
          code
          description
          entry_date
          involved_parties {
            _id
            amount
            at_closing_amount
            at_closing_percent
            before_closing_amount
            before_closing_percent
            kind
            member_of {
              _id
              kind
              name
            }
            name
            order_kinds
            payment_id
            payment_kind
            payment_reference
            percent
          }
          kind
          letter
          number
        }
      }
    }
  `;
  const response = await graphQLClient()
    .request<GQLGetOrderProperties>(query, payload);

  if (response.getOrder.estates) {
    response.getOrder.estates = response.getOrder.estates?.map((estate) => ({
      ...estate,
      parcel_ids: estate.parcel_ids.map((parcelId) => ({ value: parcelId }))
    }));
  }

  return response;
};
