import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { formatMoney } from 'utils/helpers';
import { AccountedBy } from 'v2-types/order';

import { useStyles } from './styles';
import { getIcon } from './utils/getIcon';

type Props = {
  accounted: AccountedBy,
  onDelete?: () => void
}

export const Receipt: FC<Props> = ({ accounted, onDelete }) => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.boxbordered}>
      {onDelete && (
        <IconButton className={classes.deleteButton} onClick={onDelete}>
          <FontAwesomeIcon
            icon="trash"
            size="sm"
          />
        </IconButton>
      )}
      <Box display="flex" flexDirection="row" alignItems="center">
        <FontAwesomeIcon icon={getIcon(accounted.record.code) as IconProp} size="1x" />
        <Typography variant="caption" className={classes.recipFont}>
          {`${accounted.record.description}(${formatMoney(accounted.amount)}, date: ${accounted.record.entry_date?.split('T')[0]})`}
        </Typography>
      </Box>
    </Box>
  );
};
