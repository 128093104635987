import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setProfile } from 'redux/profile-reducer/actions';
import { getProfile } from 'redux/profile-reducer/selectors';
import querys from 'services/querys';
import { NewBasicInfoType } from 'types/new-user';
import { UserReduxStateType } from 'types/redux';
import useAlert from 'utils/alert';

const useOnboardingWelcome = () => {
  const theme = useTheme();
  const history = useHistory();
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(getProfile);

  const [currentUser, setCurrentUser] = useState<NewBasicInfoType | null>(null);

  const [updateParty, responseUpdateParty] = useMutation(querys.updateParty);

  useEffect(() => {
    setCurrentUser({
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      kind: user.kind,
      ssn: user.ssn,
      gender: user.gender,
      birth: user.birth,
      onboarded: true,
    });
  }, [user]);

  useEffect(() => {
    if (responseUpdateParty.isSuccess) {
      responseUpdateParty.reset();
      dispatch(setProfile({ onboarded: true } as UserReduxStateType));
      history.replace('/profile');
    }

    if (responseUpdateParty.isError) {
      responseUpdateParty.reset();
      showAlert(t('onboarding:error'), 'error');
    }
  }, [t, dispatch, history, responseUpdateParty, showAlert]);

  return {
    t,
    theme,
    updateParty,
    userId: user._id,
    currentUser,
  };
};

export default useOnboardingWelcome;
