import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ButtonBase,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Text } from 'components/text';
import { TitleWithTextValue } from 'components/title-with-text-value';
import { TitleWithValue } from 'components/title-with-value';
import { TFunction } from 'i18next';
import React, { memo, useState } from 'react';
import { OrderProperty } from 'types/order';

import { useStyles } from './styles';
import { useInformationBar } from './use-information-bar';
import { BaseButton } from 'components/BaseButton';
import ModalChargesdocument from '../modal-charges-document/modal-charges-document';

interface Props {
  t: TFunction;
  properties: OrderProperty[],
  isOrderLoading: boolean,
}

const InformationBar = ({
  t,
  properties,
  isOrderLoading,
}: Props) => {
  const classes = useStyles();
  const {
    selectedProperty,
    showPropertyList,
    setShowPropertyList,
    selectProperty
  } = useInformationBar({ properties });

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" className={classes.container}>
        <Grid item>
          <Grid item container spacing={2} direction="row">
            <Grid item>
              <TitleWithValue
                title={t('orders:property')}
                isLoading={isOrderLoading}
                loadingRowsNumber={1}
                loadingRowsWidth={300}
              >
                <div>
                  <Grid container direction="column">
                    <Grid item container>
                      {properties.length > 0 && (
                      <Grid item>
                        <ButtonBase
                          className={classes.caretButton}
                          onClick={() => setShowPropertyList((show) => !show)}
                        >
                          <FontAwesomeIcon
                            icon={showPropertyList ? 'caret-up' : 'caret-down'}
                            size="lg"
                            className={classes.icon}
                          />
                        </ButtonBase>
                      </Grid>
                      )}
                      <Grid item>
                        <ButtonBase
                          onClick={() => setShowPropertyList((show) => !show)}
                        >
                          <Text
                            value={
                        properties.length > 0
                          ? selectedProperty.address
                          : t('common:no-defined-yet').toString()
                        }
                            maxLength={50}
                            fontWeight="bold"
                          />
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Paper
                    className={classes.propertyListCollapsable}
                    elevation={3}
                    hidden={!showPropertyList}
                  >
                    <Grid
                      item
                      container
                      className={classes.propertyListContainer}
                      direction="column"
                      alignItems="flex-start"
                      wrap="nowrap"
                    >
                      {properties.map((property) => (
                        <ButtonBase
                          key={property._id}
                          className={classes.propertyButton}
                          onClick={() => {
                            selectProperty({ property });
                            setShowPropertyList(false);
                          }}
                        >
                          <Typography variant="body2">
                            {property.address.address}
                          </Typography>
                        </ButtonBase>
                      ))}
                    </Grid>
                  </Paper>

                </div>
              </TitleWithValue>
            </Grid>

            <Grid item>
              <TitleWithTextValue
                title={t('orders:loan-amount')}
                value={selectedProperty?.loanAmount ?? t('common:no-defined-yet').toString()}
                valueIsMoney
                isLoading={isOrderLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <BaseButton
            text="Import charges from document"
            onClick={(e) => {
              e.preventDefault();
              setOpenModal(true);
            }}
          />
        </Grid>
      </Grid>
      {openModal &&
      <ModalChargesdocument
        open={openModal}
        onClose={() => { setOpenModal(false); }}
      />}
    </>
  );
};

export default memo(InformationBar);