import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Popover, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

type HudPopoverButtonProps = {
  open: boolean;
  anchorEl: Element;
  handleClose: () => void;
  handleAddRow: () => void;
  t: TFunction;
};

const HudPopoverButton: FC<HudPopoverButtonProps> = ({
  open,
  anchorEl,
  handleClose,
  handleAddRow,
  t,
}) => (
  <Popover
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Button
      onClick={() => {
        handleAddRow();
        handleClose();
      }}
    >
      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          gap: '10px',
        }}
      >
        <FontAwesomeIcon icon="plus" />
        <Typography
          style={{ textTransform: 'capitalize', }}
        >
          {t('charges:add-row')}
        </Typography>
      </Box>
    </Button>
  </Popover>
);

export default HudPopoverButton;
