import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import React, { FC } from 'react';
import { TFunction } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(4) },
  title: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.palette.primary.darker,
    textAlign: 'center',
  },
  email: { paddingLeft: theme.spacing(1), textAlign: 'center' },
  avatar: { backgroundColor: theme.palette.tab.offselected },
  button: { textTransform: 'initial' },
  actionButton: { color: theme.palette.primary.darker },
  errorRootContainer: { justifyContent: 'center' },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  url: { margin: 0 },
}));

type ModalResetPasswordProps = {
  t: TFunction;
  open: boolean;
  onClose: () => void;
  hanldeResetPartyPassword: (email: string) => void;
  email: string;
  urlResetPassword: string;
  errorMessage: string;
  isLoading: boolean;
  nameInitials: string;
  userName: string;
};

const ModalResetPassword: FC<ModalResetPasswordProps> = ({
  t,
  open,
  onClose,
  hanldeResetPartyPassword,
  email,
  urlResetPassword,
  errorMessage,
  isLoading,
  nameInitials,
  userName,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogContent className={classes.container}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              {t('contact:reset-password-confirmation')}
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Avatar className={classes.avatar}>{nameInitials}</Avatar>
            </Grid>
            <Grid item>
              <Typography className={classes.email}>
                {userName}
                {' '}
                (
                {email}
                )
              </Typography>
            </Grid>
          </Grid>

          {errorMessage && (
            <Grid item>
              <Alert
                severity="error"
                classes={{ icon: classes.errorIconContainer }}
              >
                <Typography variant="subtitle1">{errorMessage}</Typography>
              </Alert>
            </Grid>
          )}

          {!!urlResetPassword && (
            <Grid item>
              <Alert
                severity="success"
                classes={{ icon: classes.errorIconContainer, root: classes.errorRootContainer }}
              >
                <Typography className={classes.url} paragraph>
                  {urlResetPassword}
                </Typography>
              </Alert>
            </Grid>
          )}

          <Grid item container direction="row-reverse" spacing={3}>
            <Grid item>
              <Button
                className={clsx(classes.button, classes.actionButton)}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => (urlResetPassword.length === 0
                  ? email && hanldeResetPartyPassword(email)
                  : onClose())}
              >
                {urlResetPassword.length === 0
                  ? t('common:confirm')
                  : t('common:close')}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={onClose} className={classes.button}>
                {t('common:cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalResetPassword;
