import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { WireTransfersType } from 'types/wire-transfers';

import { Row } from './components/row';
import { columns } from './utils/index';

type TableWireTransfersProps = {
  t: TFunction,
  wireTransferPages: Array<WireTransfersType[]>,
  currentPage: number,
  totalPages: number,
  rowsPerPage: number,
  totalRows: number,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  changeRowsPerPage: ({ rows }: { rows: number }) => void,
  email: string,
  handleToggleModals: (location: string, data: WireTransfersType | undefined) => void
}

export const TableWireTransfers: FC<TableWireTransfersProps> = ({
  t,
  wireTransferPages,
  currentPage,
  setCurrentPage,
  changeRowsPerPage,
  totalPages,
  rowsPerPage,
  totalRows,
  email,
  handleToggleModals
}) => {
  const { i18n } = useTranslation();
  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns(t).map((column) => (
              <TableCell style={{ backgroundColor: 'white', color: '#000000' }} key={column.id}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {totalPages > currentPage - 1 && wireTransferPages[currentPage - 1].map((transfer) => (
            <Row
              key={transfer.reference}
              t={t}
              language={i18n.language}
              transfer={transfer}
              email={email}
              onClick={() => {
                handleToggleModals('authTransfer', transfer);
              }}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 8, 10, 25]}
              rowsPerPage={rowsPerPage}
              count={totalRows}
              page={currentPage - 1}
              onPageChange={(e, page) => { setCurrentPage(page + 1); }}
              onRowsPerPageChange={(e) => {
                const value = Number(e.target.value);
                if (!Number.isNaN(value)) {
                  changeRowsPerPage({ rows: value });
                }
              }}
              labelRowsPerPage={t('common:rows-per-page')}
              SelectProps={{
                inputProps: { 'aria-label': t('common:rows-per-page'), },
                native: true,
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
