import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import React, { FC } from 'react';

import { CellIndicator, CellText } from '../../components/table-cells';
import { formattedCharge } from '../services';
import CellHeader from './header-cell';

const useStyles = makeStyles((theme: any) => ({
  borderCellStyle: {
    borderColor: theme.palette.input.border,
    borderWidth: 1,
    borderStyle: 'solid',
    opacity: 1,
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
  },
  padding: { padding: theme.spacing(3) },
  table: { background: theme.palette.background.paper },
  containerTable: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
  },
  scrollDiv: {
    overflowX: 'auto',
    width: '100%',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.5) },
    '&&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark, },
  },
}));

type totalStateBrokerTable = {
  totalChargesRow: formattedCharge;
  handleEdit: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleDelete: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleClick: (event: React.MouseEvent, number: number) => void;
};

const TotalChargesRow: FC<totalStateBrokerTable> = ({
  totalChargesRow,
  handleEdit,
  handleDelete,
  handleClick,
}) => {
  const classes = useStyles();
  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow>
          <CellHeader style={{ width: '7rem' }}>
            <Actionbuttons
              handleEdit={(e) => {
                e.stopPropagation();
                handleEdit(totalChargesRow);
              }}
              handleDelete={(e) => {
                e.stopPropagation();
                handleDelete(totalChargesRow);
              }}
            />
          </CellHeader>
          <CellHeader
            style={{ width: '6rem' }}
            onClick={(e) => handleClick(e, totalChargesRow.number)}
          >
            <CellIndicator isActive={false} position={totalChargesRow.number} />
          </CellHeader>
          <CellHeader>
            <CellText
              position="left"
              backgroundColor="#FAFAFA"
              colorText="#4F5B62"
              data={totalChargesRow.description}
            />
          </CellHeader>
          <TableCell
            className={classes.borderCellStyle}
            align="right"
            style={{ width: '8rem' }}
          >
            0
          </TableCell>
          <TableCell
            className={classes.borderCellStyle}
            align="right"
            style={{ width: '8rem' }}
          >
            0
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TotalChargesRow;
