import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, Button, Drawer, Grid, makeStyles, Typography, useTheme
} from '@material-ui/core';
import { PaperCard } from 'components/cards/paper-card';
import { TitleWithTextValue } from 'components/title-with-text-value';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

type AccountingTotalsSceneProps = {
  isOpen: boolean,
  onClose: () => void,
  t: TFunction,
  totalReceipts: number,
  allDisbursements: number,
  funding: number
}

export const AccountingTotalsScene: FC<AccountingTotalsSceneProps> = ({
  isOpen,
  onClose,
  totalReceipts,
  allDisbursements,
  funding,
  t
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      {isOpen && (
        <Box sx={{ width: 400, height: '100%', bgcolor: '#ECEFF1' }} padding={2}>
          <Grid container direction="column">
            <Grid item>
              <Button onClick={onClose} style={{ minWidth: 'auto' }}>
                <FontAwesomeIcon
                  icon="plus"
                  size="lg"
                  transform={{ rotate: 45 }}
                  color={theme.palette.primary.main}
                />
              </Button>
            </Grid>
            <Grid item container id="content">
              <Grid container direction="column" spacing={2} className={classes.container}>
                <Grid item xs>
                  <PaperCard>
                    <Grid item container direction="column" spacing={2}>
                      <Grid item>
                        <Typography variant="h6" style={{ fontWeight: 500 }}>{t('accounting:accounting-totals')}</Typography>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={6}>
                          <TitleWithTextValue
                            title={t('accounting:total-receipts')}
                            valueIsMoney
                            value={totalReceipts}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TitleWithTextValue
                            title={t('accounting:all-disbursements')}
                            valueIsMoney
                            value={allDisbursements}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TitleWithTextValue
                            title={funding < 0 ? t('accounting:underfunded') : t('accounting:overfunded')}
                            valueIsMoney
                            value={funding}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </PaperCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Drawer>
  );
};
