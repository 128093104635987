import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import usePolicy from '../services/use-policy-context';

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

const Header: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { latestUpdate } = usePolicy() || {};
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={classes.headerContainer}
    >
      <Grid item>
        <Typography variant="h5" className={classes.headerTitle}>
          {t('policyInfoAndRates:policy-info-and-rates')}
        </Typography>
      </Grid>
      <Grid item>
        <Alert severity={latestUpdate?.type}>
          {latestUpdate?.message }
        </Alert>
      </Grid>
    </Grid>
  );
};

export default Header;
