/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-plusplus */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  capitalize,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import * as PDFJS from 'pdfjs-dist/webpack.mjs';
import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme:any) => ({
  container: { padding: theme.spacing(2) },
  title: {
    fontWeight: 500,
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.accent.main,
    paddingBottom: theme.spacing(2)
  },
  subtitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  dropzoneContainer: {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'lightgray',
    borderStyle: 'dashed',
    padding: theme.spacing(5),
    width: '90%'
  },
  fileContainer: {
    borderRadius: '15px',
    background: '#ececec',
    width: 'auto',
    paddingRight: theme.spacing(2)
  },
  removeFileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    borderRadius: '50%',
    background: '#646b6e',
  },
}));

const readFileData = (file) => new Promise((resolve, reject) => {
	  const reader = new FileReader();
	  reader.onload = (e) => {
    resolve(e?.target?.result);
	  };
	  reader.onerror = (err) => {
    reject(err);
	  };
	  reader.readAsDataURL(file);
});
const convertPdfToImages = async (file) => {
  const blobFiles = [];
  const images = [];
  const data = await readFileData(file);
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement('canvas');
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 3 });
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport }).promise;
    const dataBlob = await new Promise<Blob | null>(
      (resolve) => canvas.toBlob(
		  (blob) => resolve(blob),
		  'image/jpeg', 0.95
      )
	  );
	  const dataFiles = canvas.toDataURL('image/png');
	  (images as any).push(dataFiles);
	  (blobFiles as any).push(dataBlob);
  }
  canvas.remove();
  return {
    images,
    blobFiles
  };
};

type OrderCreateDocumentProps={
	onUploadDocument:(param:any)=>void
}

const OrderCreateDocument:FC<OrderCreateDocumentProps> = ({ onUploadDocument }) => {
  const classes = useStyles();
  const [filesToUpload] = useState<File[]>([]);
  const { t } = useTranslation();
  const {
    getInputProps,
    getRootProps,
    open: openFileDialog,
  } = useDropzone({
    maxSize: 31457280,
    // eslint-disable-next-line
    accept:'application/pdf',
    noClick: true,
    noKeyboard: true,
    onDropAccepted: async (files: File[]) => {
      if (files.length > 0) {
        const file = files[0];
        const dataPDF = await convertPdfToImages(file);
        // setImagesToShow(dataPDF.blobFiles);
        onUploadDocument(dataPDF);
      }
    },
  });

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography>Please Select document from your files to crerate an order</Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.container}
        xs
      >
        <Grid
          item
          {...getRootProps({ className: 'dropzone' })}
          className={classes.dropzoneContainer}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={2}
          >
            <input {...getInputProps()} />
            <Grid item>
              <FontAwesomeIcon icon="upload" size="2x" />
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {capitalize(t('documents:drag-or-drop'))}
              </Typography>
            </Grid>
            <Grid item>
              <ContainedButton
                onClick={openFileDialog}
                text={capitalize(t('documents:browse-files'))}
              />
            </Grid>
            <Grid container justifyContent="center" style={{ gap: '5px' }}>
              {filesToUpload.map((file) => (
                <Grid
                  item
                  container
                  className={classes.fileContainer}
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      style={{ minWidth: 'auto' }}
                    >
                      <Box className={classes.removeFileContainer}>
                        <FontAwesomeIcon
                          size="sm"
                          icon="plus"
                          color="white"
                          transform={{ rotate: 45 }}
                        />
                      </Box>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">{file.name}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};

export default OrderCreateDocument;
/*
 <Grid item style={{ padding: 24, overflow: 'scroll' }}>
        {imagesToshow.map((pdf, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <img key={index} src={pdf} alt={`page${index}`} width={100} />
        ))}
      </Grid>
*/

/*
{
    "name": "Purchase",
    "types": [
        {
            "name": "Closing Disclosure"
        },
        {
            "name": "HUD 1"
        },
        {
            "name": "Settlement Statement"
        }
    ],
    "workflows": [
        {
            "name": "Purchase Standard"
        }
    ],
    "representing": [
        {
            "name": "Lender"
        },
        {
            "name": "Buyer"
        },
        {
            "name": "Seller"
        },
        {
            "name": "Buyer and Lender"
        },
        {
            "name": "Buyer and Seller"
        },
        {
            "name": "N/A"
        }
    ],
    "id": "b64f31c7-a95c-49ee-8103-07a299ee75a5"
}

*/
