import { deleteUser, DeleteUserPayload, GQLDeleteUser } from 'graphql/contacts/mutations';
import { useMutation } from 'react-query';

type UseDeleteUserParams = {
  onSuccess: (data: GQLDeleteUser) => void,
  onError: (error: Error) => void
}

export const useDeleteUser = ({
  onSuccess,
  onError,
}: UseDeleteUserParams) => useMutation<GQLDeleteUser, Error, DeleteUserPayload>(deleteUser, {
  onSuccess: (data) => onSuccess(data),
  onError: (error) => onError(error)
});
