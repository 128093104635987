import { UserReduxStateType } from 'types/redux';

const initialState: UserReduxStateType = {
  _id: '',
  username: '',
  name: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  kind: '',
  onboarded: false,
  birth: '',
  gender: '',
  ssn: '',
  deleted: false,
  user_id: '',
  addresses: [],
  emails: [],
  licenses: [],
  parties: [],
  payments: [],
  members: [],
  orders: [],
  phones: [],
  accesses: [],
  isLoading: true,
  notifications: { all: false },
  settings: {
    language: 'en',
    theme: 'light',
  },
};

const profileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default profileReducer;
