import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import NumberField from 'components/number-field';
import { TFunction } from 'i18next';
import React, { FC, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { NewUserType } from 'types/new-user';
import { UpdateOrderSpecific } from 'types/order';

const useStyles = makeStyles((theme: any) => ({
  infoContainer: { marginTop: theme.spacing(6) },
  basinfoContainer: {
    marginLeft: theme.spacing(1.3),
    marginTop: theme.spacing(5)
  },
  tabContainer: { marginRight: theme.spacing(10) },
  contactDivider: {
    padding: `0px ${theme.spacing(5)}px`,
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[400],
  },
  infoContLabel: {
    color: theme.palette.tab.offselected,
    fontWeight: 500
  },
  overlabel: {
    color: theme.palette.text.light,
    textTransform: 'uppercase'
  },
  label: {
    color: theme.palette.primary.darker,
    fontWeight: 500
  },
  addressLabel: {
    color: theme.palette.primary.darker,
    fontWeight: 500,
    marginTop: theme.spacing(4)
  },
  contactContainer: { marginBottom: theme.spacing(5.5) },
  contactLabel: {
    color: theme.palette.primary.darker,
    fontWeight: 500,
  },
  containerCheck: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2.2)
  },
  containerRadio: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.2)
  },
  divider: {
    backgroundColor: theme.palette.grey[400],
    height: theme.spacing(0.125)
  }

}));

type InformationTabProps = {
  t: TFunction;
  party: NewUserType;
  mainParty: boolean;
  methods?: UseFormReturn<UpdateOrderSpecific, any> | undefined;
  prefix?: 'data_buyer' | 'data_seller' | ''
};

const InformationTab: FC<InformationTabProps> = ({
  t,
  party,
  mainParty,
  methods,
  prefix
}) => {
  const classes = useStyles();

  const isPerson = useMemo(() => party.kind === 'Person', [party.kind]);
  const hasAddress = useMemo(() => !!party.addresses.length, [party.addresses]);
  const hasEmails = useMemo(() => !!party.emails.length, [party.emails]);
  const hasPhones = useMemo(() => !!party.phones.length, [party.phones]);

  return (
    <Grid container direction="column">
      <Box className={classes.infoContainer}>
        <Typography variant="h5" className={classes.infoContLabel}>
          {t('parties:basic-Info')}
        </Typography>
        <Grid item xs={12} className={classes.basinfoContainer}>
          <Grid
            container
            direction="row"
            className={classes.tabContainer}
          >

            <Grid item xs={isPerson ? 2 : 12}>
              <Typography variant="caption" className={classes.overlabel}>
                {t('parties:full-name')}
              </Typography>
              <Typography
                className={classes.label}
                variant="body2"
              >
                {party?.name}
              </Typography>
            </Grid>
            {isPerson && (
            <>
              <Grid item xs={2}>
                <Typography variant="caption" className={classes.overlabel}>
                  {t('parties:gender')}
                </Typography>
                <Typography
                  className={classes.label}
                  variant="body2"
                >
                  {party?.gender || t('parties:no-yet')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" className={classes.overlabel}>
                  {t('parties:marital-status')}
                </Typography>
                <Typography
                  className={classes.label}
                  variant="body2"
                >
                  {t('parties:no-yet')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" className={classes.overlabel}>
                  {t('parties:ssn')}
                </Typography>
                <Typography
                  className={classes.label}
                  variant="body2"
                >
                  {party?.ssn || t('parties:no-yet')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="caption" className={classes.overlabel}>
                  {t('parties:date-birth')}
                </Typography>
                <Typography
                  className={classes.label}
                  variant="body2"
                >
                  {party?.birth || t('parties:no-yet')}
                </Typography>
              </Grid>
            </>
            )}

          </Grid>
        </Grid>
      </Box>

      {hasAddress && (
        <>
          <Box className={classes.infoContainer}>
            <Typography variant="h5" className={classes.infoContLabel}>
              {t('parties:addresses')}
            </Typography>
            <Box className={classes.basinfoContainer}>
              {party?.addresses.map((address) => (
                <Typography
                  className={classes.addressLabel}
                  variant="body2"
                  key={address._id}
                >
                  {address.address}
                </Typography>
              ))}
            </Box>
          </Box>
        </>
      )}

      {(hasEmails || hasPhones) && (
        <Box className={classes.infoContainer}>
          <Typography variant="h5" className={classes.infoContLabel}>
            {t('parties:contact-info')}
          </Typography>
          <Grid
            container
            direction="row"
            className={classes.basinfoContainer}
          >
            {hasEmails && (
              <Grid item xs={hasPhones ? 4 : 12}>
                  {party?.emails.map((email) => (
                    <Box
                      className={classes.contactContainer}
                      key={email._id}
                    >
                      <Typography variant="caption" className={classes.overlabel}>
                        {email.kind}
                      </Typography>
                      <Typography
                        className={classes.contactLabel}
                        variant="body2"
                      >
                        {email.address}
                      </Typography>
                    </Box>
                  ))}
              </Grid>
            )}

            {hasPhones && (
              <Grid
                item
                xs={hasEmails ? 8 : 12}
                className={clsx({ [classes.contactDivider]: hasEmails })}
              >
                  {party?.phones.map((phone) => (
                    <Box
                      className={classes.contactContainer}
                      key={phone._id}
                    >
                      <Typography variant="caption" className={classes.overlabel}>
                        {phone.kind}
                      </Typography>
                      <Typography
                        className={classes.contactLabel}
                        variant="body2"
                      >
                        {phone.number}
                      </Typography>
                    </Box>
                  ))}
              </Grid>
            )}

          </Grid>

        </Box>
      )}

      {(mainParty && methods && prefix) && (
        <Box className={classes.infoContainer}>
          <Typography variant="h5" className={classes.infoContLabel}>
            {t('parties:1099_reporting')}
          </Typography>
          <Grid container direction="row" className={classes.containerCheck}>
            <Grid item xs={3}>
              <Controller
                name={`${prefix}.exception_1099`}
                control={methods.control}
                render={({ field: { ref, value, ...field } }) => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        {...field}
                        inputRef={ref}
                        color="secondary"
                        checked={value || false}
                      />
                    )}
                    label={t('parties:exempt_1099')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`${prefix}.is_foreign`}
                control={methods.control}
                render={({ field: { ref, value, ...field } }) => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        {...field}
                        inputRef={ref}
                        color="secondary"
                        checked={value || false}
                      />
                    )}
                    label={t('parties:foreign_person')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`${prefix}.declines_ssn_ein`}
                control={methods.control}
                render={({ field: { ref, value, ...field } }) => (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        {...field}
                        inputRef={ref}
                        checked={value || false}
                        color="secondary"
                      />
                    )}
                    label={t('parties:declines_SSN')}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container direction="row" className={classes.containerRadio}>
            <Grid item xs={3}>
              <Controller
                name={`${prefix}.ownership`}
                control={methods.control}
                render={({ field: { ref, value, ...field } }) => (
                  <NumberField
                    {...field}
                    fullWidth={false}
                    isAllowed={({ floatValue = 0 }) => floatValue <= 100}
                    label={t('common:ownership')}
                    value={value}
                    onChange={(params) => {
                      methods.setValue(`${prefix}.ownership`, parseFloat(params.currentTarget.value));
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                      inputRef: ref
                    }}
                  />
                )}
              />

            </Grid>
            <Grid item xs={2} style={{ alignSelf: 'center' }}>
              <Typography variant="subtitle1" className={classes.infoContLabel}>
                {t('parties:on')}
              </Typography>
              <Controller
                name={`${prefix}.listed_on_title`}
                control={methods.control}
                render={({ field: { ref, value, ...field } }) => (
                  <FormControlLabel
                    control={(
                      <Switch
                        {...field}
                        checked={value || false}
                        inputRef={ref}
                        color="secondary"
                      />
                    )}
                    label={t('parties:title')}
                  />
                )}
              />

            </Grid>
          </Grid>
        </Box>
      )}

    </Grid>
  );
};

InformationTab.defaultProps = {
  methods:
  undefined,
  prefix: ''
};

export default InformationTab;
