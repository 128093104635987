import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewOrderPartyType } from 'types/order';
import * as Yup from 'yup';

type SearchContactProps = {
  handleCreateAssociation: (partyId: string, action: ()=> void) => void;
  involvedParties: NewOrderPartyType[];
};
const useStyles = makeStyles((theme:any) => ({
  buttonIcon: {
    color: theme.palette.common.black,
    marginRight: theme.spacing(1)
  },
  textbutton: {
    color: theme.palette.common.black,
    fontWeight: 500
  }
}));

const SearchContact: FC<SearchContactProps> = ({ handleCreateAssociation, involvedParties }) => {
  const { t } = useTranslation();
  const schema = Yup.object({ partyId: Yup.string().required(t('validations:required')), });

  type PartyAssocType = {
    kind: string,
    contact: null | NewOrderPartyType,
    partyId: string
  }

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<PartyAssocType>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      contact: null,
      partyId: ''
    },
  });

  const handleClose = () => {
    reset();
  };
  const classes = useStyles();
  return (

    <form onSubmit={handleSubmit(({ partyId }) => handleCreateAssociation(partyId, handleClose))}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Controller
            name="contact"
            control={control}
            render={({ field: { ref, onChange, onBlur, ...field } }) => (
              <Autocomplete
                {...field}
                fullWidth
                innerRef={ref}
                options={involvedParties}
                getOptionLabel={(party) => party.name}
                onChange={(_, value) => {
                  if (value && typeof value === 'object') {
                    onChange(null);
                    onChange(value);
                    setValue('partyId', value._id);
                    onBlur();
                  } else {
                    onChange(null);
                    setValue('partyId', '');
                    onBlur();
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={params.InputProps.ref}
                    InputProps={params.inputProps}
                    label={t('parties:contact')}
                    error={!!errors.partyId}
                    helperText={errors.partyId?.message || t('validations:required')}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid container>
            <Button
              type="submit"
              disableElevation
              disabled={!isValid}
              variant="contained"
              color="secondary"
              style={{ textTransform: 'none' }}
            >
              <FontAwesomeIcon icon="plus" className={classes.buttonIcon} />
              <Typography variant="body2" className={classes.textbutton}>
                {t('ron:add-attendee')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>

  );
};

export default SearchContact;
