import React from 'react';

import useUnsuscribe from './unsuscribe-hooks';
import UnsuscribeScene from './unsuscribe-scene';

const Unsuscribe = () => {
  const {
    t,
    tokenResponse,
    unsuscribe,
    showFinalScreen
  } = useUnsuscribe();

  const handleUnsuscribe = (email:string) => {
    unsuscribe({ email });
  };

  return (
    <UnsuscribeScene
      t={t}
      tokenResponse={tokenResponse}
      handleUnsuscribe={handleUnsuscribe}
      showFinalScreen={showFinalScreen}
    />
  );
};

export default Unsuscribe;
