import React from 'react';

import useChargesKMLN from '../services/useGetChargesKM';
import ChargesLScene from './charges-l-scene';

const ChargesL = () => {
  const {
    dataCharges,
    handleUpdateAccounting,
    handleDelete,
    handleEditModal,
    handlePayor,
    openAddModal,
    handleAddModal,
    state,
  } = useChargesKMLN(['L']);

  return (
    <ChargesLScene
      accountingData={dataCharges || []}
      handleUpdateAccounting={handleUpdateAccounting}
      handleDelete={handleDelete}
      handleEditModal={handleEditModal}
      handlePayor={handlePayor}
      openAddModal={openAddModal}
      handleaddModal={handleAddModal}
      state={state as any}
    />
  );
};

export default ChargesL;
