import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrderProperty } from 'types/order';
import { capitalize } from 'utils/helpers';

import { PropertyDatePicker } from '../PropertyDatePicker';
import { PropertyNumberField } from '../PropertyNumberField';
import { PropertyTextField } from '../PropertyTextField';

const useStyles = makeStyles((theme: any) => ({ container: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) } }));

type SurveySceneProps = {
  control: Control<OrderProperty, object>,
}
export const SurveyScene: FC<SurveySceneProps> = ({ control }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box>
      <Grid container direction="column" spacing={2} className={classes.container}>
        <Grid item container spacing={2}>
          <Grid item xs>
            <PropertyDatePicker
              control={control}
              name="survey_date"
              label={t('orders:acceptance-date')}
            />
          </Grid>
          <Grid item xs>
            <PropertyNumberField
              control={control}
              name="survey_number"
              decimalScale={0}
              label={<Typography variant="body2">{capitalize(t('ordersProperties:survey-number'))}</Typography>}
            />
          </Grid>
        </Grid>
        <Grid item xs>
          <PropertyTextField
            control={control}
            name="survey_information"
            label={capitalize(t('ordersProperties:survey-information'))}
            multiline
          />
        </Grid>
      </Grid>
    </Box>
  );
};
