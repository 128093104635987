import { Grid } from '@material-ui/core';
import { PaperCard } from 'components/cards/paper-card';
import { TitleWithValue } from 'components/title-with-value';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { NewOrderPartyType } from 'v2-types/order';

import { PartyTable } from './components/party-table';
import { PartyNameRow } from './components/party-table/components/partyNameRow';
import { Row } from './components/party-table/components/row';
import { PartyWithPartyInvolved } from './types';

type PartiesInvolvedSceneProps = {
  t: TFunction,
  sellers: PartyWithPartyInvolved[],
  buyers: PartyWithPartyInvolved[],
  lenders: NewOrderPartyType[],
  isOrderLoading: boolean
}

export const PartiesInvolvedScene: FC<PartiesInvolvedSceneProps> = ({
  t,
  sellers,
  buyers,
  lenders,
  isOrderLoading
}) => (
  <PaperCard title={t('orders:parties-involved')}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleWithValue
          title={t('common:sellers')}
          isLoading={isOrderLoading}
        >
          <PartyTable
            parties={sellers}
            t={t}
            renderRowFn={(party) => <Row key={party.name} party={party} t={t} />}
          />
        </TitleWithValue>
      </Grid>
      <Grid item xs={12}>
        <TitleWithValue
          title={t('common:buyers')}
          isLoading={isOrderLoading}
        >
          <PartyTable
            parties={buyers}
            t={t}
            renderRowFn={(party) => <Row key={party.name} party={party} t={t} />}
          />
        </TitleWithValue>
      </Grid>
      <Grid item xs={12}>
        <TitleWithValue
          title={t('common:lenders')}
          isLoading={isOrderLoading}
        >
          <PartyTable
            parties={lenders}
            t={t}
            renderRowFn={(party) => <PartyNameRow key={party.name} party={party} />}
          />
        </TitleWithValue>
      </Grid>
    </Grid>
  </PaperCard>
);
