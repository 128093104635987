import { Grid } from '@material-ui/core';
import React from 'react';

import ParagraphTag from '../common/paragraph-tag';

const Tab2 = () => (
  <Grid container direction="column" spacing={3}>
    <Grid item xs={12}>
      <ParagraphTag
        text="
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, ex ducimus illum commodi
          beatae omnis mollitia nemo dolores asperiores, quos nobis, illo debitis.
          Reprehenderit inventore nisi, molestiae ea recusandae eaque.
        "
      />

      <ParagraphTag
        text="
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, ex ducimus illum commodi
          beatae omnis mollitia nemo dolores asperiores, quos nobis, illo debitis.
          Reprehenderit inventore nisi, molestiae ea recusandae eaque.
        "
      />

      <ParagraphTag
        text="
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, ex ducimus illum commodi
          beatae omnis mollitia nemo dolores asperiores, quos nobis, illo debitis.
          Reprehenderit inventore nisi, molestiae ea recusandae eaque.
        "
      />

      <ParagraphTag
        text="
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, ex ducimus illum commodi
          beatae omnis mollitia nemo dolores asperiores, quos nobis, illo debitis.
          Reprehenderit inventore nisi, molestiae ea recusandae eaque.
        "
      />
    </Grid>
  </Grid>
);

export default Tab2;
