import { Box, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BaseButton } from 'components/BaseButton';
import DatePicker from 'components/date-picker/date-picker';
import TextField from 'components/text-field';
import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { OrderMortgageType, PayOffInformationform } from 'views/orders/mortgage-payoffs/services/types.d';

import { updateMortgageInformation } from '../../../services/mutations';

const useStyles = makeStyles((theme: any) => ({
  subtitle: {
	  color: theme.palette.text.primary_light,
	  paddingTop: theme.spacing(0.5),
	  paddingBottom: theme.spacing(0.5),
  },
  containerTextfields: { padding: theme.spacing(3) },
  paddingText: { padding: theme.spacing(1) }
}));

type PayeOffformProps={
	currentMortgage: OrderMortgageType | undefined
}

const PayOffInfo:FC <PayeOffformProps> = ({ currentMortgage }) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { control, formState, handleSubmit, reset, } = useForm<PayOffInformationform>({ mode: 'onChange' });

  useEffect(() => {
    reset({
      book: currentMortgage?.book || '',
      certificate_title: currentMortgage?.certificate_title || '',
      deed_date: currentMortgage?.deed_date || '',
      document_number: currentMortgage?.document_number || '',
      instrument_number: currentMortgage?.instrument_number || '',
      page: currentMortgage?.page || '',
      rescission_date: currentMortgage?.rescission_date || '',
      recorded_date: currentMortgage?.recorded_date || '',
    });
  }, [currentMortgage, reset]);

  const [updateOrderMortgageMutation, updateMortgageMutationResponse] = useMutation(updateMortgageInformation, {
    onSuccess: () => {
	  reset(undefined, { keepValues: true });
    }
  });

  const returnNullObjetcs = (propertyString: string) => (propertyString !== '' ? propertyString : null);

  const updateMortgageFunc = useCallback((data:PayOffInformationform) => {
    updateOrderMortgageMutation({
      id,
      mortgageId: currentMortgage?._id || '',
      data: {
        rescission_date: returnNullObjetcs(data.rescission_date || ''),
        recorded_date: returnNullObjetcs(data.recorded_date || ''),
        page: returnNullObjetcs(data.page || ''),
        instrument_number: returnNullObjetcs(data.instrument_number || ''),
        document_number: returnNullObjetcs(data.document_number || ''),
        deed_date: returnNullObjetcs(data.deed_date || ''),
        certificate_title: returnNullObjetcs(data.certificate_title || ''),
        book: returnNullObjetcs(data.book || '')
	  }

    });
  }, [currentMortgage?._id, id, updateOrderMortgageMutation]);

  return (
    <>
      <form onSubmit={handleSubmit((data) => { updateMortgageFunc(data); })}>
        <Box display="flex" flexDirection="column" flex="1" className={classes.containerTextfields}>
          <Box display="flex" flexDirection="row" flex="1" justifyContent="space-between">
            <Box>
              <BaseButton
                text="Save"
                buttonVariant="contained"
                buttonColor="secondary"
                type="submit"
                disabled={!formState.isDirty}
                disableElevation
                isLoading={updateMortgageMutationResponse.isLoading}
              />
            </Box>
            <Box>
              <Alert severity={!formState.isDirty ? 'info' : 'warning'}>{formState.isDirty ? 'Pres save to record your charges' : 'there are not changes to save'}</Alert>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box flex="1" className={classes.paddingText}>
              <Controller
                control={control}
                name="deed_date"
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    disableToolbar={false}
                    label="Deed Date"
                    handleBlur={field.onBlur}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box flex="1" className={classes.paddingText}>
              <Controller
                control={control}
                name="rescission_date"
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    disableToolbar={false}
                    label="Recission Date"
                    handleBlur={field.onBlur}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>
            <Box flex="1" className={classes.paddingText}>
              <Controller
                control={control}
                name="recorded_date"
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    disableToolbar={false}
                    label="Recorded Date"
                    handleBlur={field.onBlur}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex="1" className={classes.containerTextfields}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box flex="1" className={classes.paddingText}>
              <Controller
                control={control}
                name="book"
                shouldUnregister={false}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Book"
                  />
                )}
              />
            </Box>
            <Box flex="1" className={classes.paddingText}>
              <Controller
                control={control}
                name="certificate_title"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Certificate Title"
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box flex="1" className={classes.paddingText}>
              <Controller
                control={control}
                name="document_number"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Document Number"
                  />
                )}
              />
            </Box>
            <Box flex="1" className={classes.paddingText}>
              <Controller
                control={control}
                name="instrument_number"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Instrument Number"
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default PayOffInfo;
