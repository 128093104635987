import { Box, Grid, Paper } from '@material-ui/core';
import React, { FC, useState } from 'react';
import Tree from 'react-d3-tree';

import { GenerateChartStructure, partiesfiltered } from '../../services';
import { renderNodeWithCustomEvents } from './components/custom-node';
import { ModalParty } from './components/modal-party';

type TreePartiesType = {
  parties: partiesfiltered[];
};

type NodeProperties = {
  kind: string;
  partyId: string;
};

const TreeParties: FC<TreePartiesType> = ({ parties }) => {
  const orgChart = GenerateChartStructure(parties);
  const [currentNode, setCurrentNode] = useState<NodeProperties | null>(null);
  const [openModal, setopenModal] = useState(false);

  const closeModal = () => {
    setopenModal(false);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Box
            id="treeWrapper"
            style={{ width: '100%', height: 'calc(100vh - 320px)' }}
          >
            <Tree
              data={orgChart}
              onNodeClick={(e) => {
                const { data } = e;
                if (data.attributes) {
                  setCurrentNode({
                    kind: data.attributes.kind as string,
                    partyId: data.attributes.partyId as string,
                  });
                  setopenModal(true);
                }
              }}
              nodeSize={{
                x: 300,
                y: 40,
              }}
              renderCustomNodeElement={(rd3tProps) => renderNodeWithCustomEvents({ ...rd3tProps })}
            />
          </Box>
        </Paper>
      </Grid>

      <ModalParty
        openModal={openModal}
        closeModal={closeModal}
        currentNode={currentNode}
      />
    </Grid>
  );
};

export default TreeParties;
