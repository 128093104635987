/* eslint-disable max-len */
import gqlRequest from 'graphql/generic';
import { AccountingFilter, AccountingType } from 'v2-types/order';

type addChargeReturn = {
  createOrderLedgerEntry: {
    accounting: AccountingType[] | null;
  }
};

const mutation = `
mutation CreateOrderLedgerEntry($id: ID!, $amount: Float!, $code: OrderLedgerCodes!, $description: String!, $kind: OrderLedgerKinds!, $entryDate: String, $letter:OrderLedgerCharges, $number: Int, $months: Int, $months_advanced: Int, $annuality: Float  $filters: OrderFilters) {
  createOrderLedgerEntry(_id: $id, amount: $amount, code: $code, description: $description, kind: $kind, entry_date: $entryDate,letter: $letter,  number: $number, months: $months,months_advanced: $months_advanced, annuality: $annuality, filters: $filters) {
    accounting {
      _id
      annuality
      amount
      amount_calculated
      amount_override
      code
      kind
      letter
      number
      description
      entry_date
    months
    per_month
    months_advanced
    accounted_for {
          amount
          percent
          record {
			  _id
			  code
			  number
			  amount
			  letter
			  description
			  involved_parties{
			    _id
				name
				kind
			  }
			}
        }
      involved_parties {
        _id
        amount
    accounted_amount
    accounted_percent
    accounted_by {
      amount
      percent
      record {
      _id
      code
      description
	  entry_date
      }
    }
        at_closing_amount
        at_closing_percent
        before_closing_amount
        before_closing_percent
        kind
        name
        order_kinds
        payment_id
        percent
      }  
    }
  }
}
`;
type manualchargeParams = {
  id: string,
  entryDate: string | null,
  description: string,
  code: string,
  amount: number,
  letter:string | null,
  kind: string,
  number?: number | null,
  months?:number | null,
  months_advanced?:number | null,
  annuality?: number | null,
  filters?: { [key: string]: AccountingFilter },
 }

export const addManualCharge = (params:manualchargeParams) => gqlRequest<addChargeReturn, manualchargeParams>(mutation, params);

export default {};
