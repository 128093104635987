import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const useModalCreateContact = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [created, setCreated] = useState(false);
  const [createdId, setCreatedId] = useState<string | null>(null);

  return {
    t,
    history,
    created,
    createdId,
    setCreated,
    setCreatedId
  };
};
