import { format } from 'date-fns';
import {
  NewAddressType,
  NewBasicInfoType,
  NewEmailType,
  NewLicenseType,
  NewNotificationsType,
  NewPartyType,
  NewPhoneType,
  NewUserType,
} from 'types/new-user';

export const updateBasicInformation = (values: NewUserType): { id: string; data: NewBasicInfoType } => {
  let parsedBirth = '';

  if (typeof values.birth === 'object') {
    try {
      parsedBirth = format(values.birth as Date, 'yyyy-MM-dd');
    } catch (error) {
      parsedBirth = '';
    }
  }

  return {
    id: values._id,
    data: {
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
      kind: values.kind,
      ssn: values.ssn,
      birth: parsedBirth,
      gender: values.gender,
      onboarded: values.onboarded,
    },
  };
};

type UpdateAddressResponse = { id: string;kind: string; partyId: string; data: NewAddressType };

export const updateAddress = (partyId: string, values: NewAddressType): UpdateAddressResponse => {
  const body = {
    country: 'US',
    state: values.state,
    postal_code: values.postal_code,
    street_address: values.street_address,
    locality: values.locality,
    settlement: values.settlement,
    references: values.references,
  };

  return {
    partyId,
    id: values._id || '',
    kind: values.kind,
    data: body as NewAddressType,
  };
};

type CreateAddressResponse = { partyId: string; kind:string; data: NewAddressType };

export const createAddress = (partyId: string, data: NewAddressType): CreateAddressResponse => {
  const body = {
    country: 'US',
    state: data.state,
    postal_code: data.postal_code,
    street_address: data.street_address,
    locality: data.locality,
    settlement: data.settlement,
    references: data.references
  };

  return {
    partyId,
    kind: data.kind,
    data: body as NewAddressType,
  };
};

type UpdateEmailResponse = {
  id: string;
  partyId: string;
  data: NewEmailType;
  kind: string;
};

export const updateEmail = (partyId: string, values: NewEmailType): UpdateEmailResponse => {
  const body = { address: values.address };

  return {
    id: values._id || '',
    partyId,
    data: body as NewEmailType,
    kind: values.kind,
  };
};

type CreateEmailResponse = { partyId: string; kind: string; data: NewAddressType };

export const createEmail = (partyId: string, data: NewEmailType): CreateEmailResponse => {
  const body = { address: data.address };

  return {
    partyId,
    kind: data.kind,
    data: body as NewAddressType,
  };
};

type UpdatePhoneResponse = {
  id: string;
  partyId: string;
  data: NewPhoneType;
  kind: string;
};

export const updatePhone = (partyId: string, values: NewPhoneType): UpdatePhoneResponse => {
  const body = { number: values.number };

  return {
    id: values._id || '',
    partyId,
    data: body as NewPhoneType,
    kind: values.kind,
  };
};

type CreatePhoneResponse = { partyId: string; kind: string; data: NewPhoneType };

export const createPhone = (partyId: string, data: NewPhoneType): CreatePhoneResponse => {
  const body = { number: data.number };

  return {
    partyId,
    kind: data.kind,
    data: body as NewPhoneType,
  };
};

type UpdateLicenseResponse = {
  id: string;
  partyId: string;
  data: NewLicenseType;
};

export const updateLicense = (partyId: string, values: NewLicenseType): UpdateLicenseResponse => {
  const body = {
    state: values.state,
    number: values.number,
  };

  return {
    id: values._id || '',
    partyId,
    data: body as NewLicenseType,
  };
};

type CreateLicenseResponse = { partyId: string; data: NewLicenseType };

export const createLicense = (partyId: string, data: NewLicenseType): CreateLicenseResponse => {
  const body = {
    state: data.state,
    number: data.number,
  };

  return {
    partyId,
    data: body as NewLicenseType,
  };
};

type UpdateAssociationResponse = { id: string; partyId: string; kind: string };

export const updateAssociation = (id: string, data: NewPartyType): UpdateAssociationResponse => ({
  id,
  partyId: data._id,
  kind: data.kind,
});

type CreateAssocistionResponse = { id: string; partyId: string; kind: string };

export const createAssociation = (id: string, data: NewPartyType): CreateAssocistionResponse => ({
  id,
  partyId: data._id,
  kind: data.kind
});

type UpdatePartyNotifications = { id: string; data: NewNotificationsType };

export const updatePartyNotifications = (id: string, data: NewNotificationsType): UpdatePartyNotifications => ({
  id,
  data,
});

/**
 * Returns the party email array.
 *
 * @remarks
 * Legacy users support, prevent to duplicate emails in the array.
 *
 * @param emails - Party email array.
 * @param username - The username is the login email.
 * @param userId - Party user_Id.
 * @returns party email array
 *
 */
export const addLoginEmailIfNotExist = (emails: NewEmailType[], username: string, userId: string) => {
  const emailList = [...emails];

  if (username != null) {
    const loginEmailExists = emailList.some((email) => email.address.toLowerCase() === username.toLowerCase() && email.kind === 'Login');
    if (!loginEmailExists) {
      emailList.unshift({
        address: username,
        kind: 'Login',
        _id: userId
      });
    }
  }

  return emailList;
};
