import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { AccountingFilter, AccountingType } from 'v2-types/order';

export type GQLAddPayerReturn = {
  helpOrderLedgerPayeePayer: {
    _id: string;
  accounting: AccountingType[]
  };
};

type OrderLedgerPayeePayerContent = {
  party_id: string;
  percent: number;
};

type OrderLedgerPayeePayer = {
  expected: 'at' | 'before';
  payees: OrderLedgerPayeePayerContent[];
  payers: OrderLedgerPayeePayerContent[];
};

export type HelpOrderLedgerPayeePayerPayload = {
  id: string,
  accountingId: string,
  data: OrderLedgerPayeePayer,
  filters?: { [key: string]: AccountingFilter },
};

export const helpOrderLedgerPayeePayer = async (
  payload: HelpOrderLedgerPayeePayerPayload
) => {
  const mutation = gql`
    mutation Mutation(
      $id: ID!
      $accountingId: ID!
      $data: OrderLedgerPayeePayerContent!
      $filters: OrderFilters
    ) {
      helpOrderLedgerPayeePayer(
        _id: $id
        accounting_id: $accountingId
        data: $data,
        filters: $filters
      ) {
        accounting {
          _id
          annuality
          amount
          amount_calculated
          amount_override
          code
          description
          entry_date
          kind
          letter
          number
          months
          per_month
          months_advanced
          accounted_for {
            amount
            percent
          }
          involved_parties {
            _id
            amount
            at_closing_amount
            at_closing_percent
            before_closing_amount
            before_closing_percent
            kind
            name
            order_kinds
            payment_id
            payment_kind
            payment_reference
            percent
            accounted_amount
            accounted_percent
            accounted_by {
            amount
              percent
              record {
                _id
                description
                code
                entry_date
              }
            }
          }
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLAddPayerReturn>(
    mutation,
    payload
  );

  return response;
};
