import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntlCurrencyFormat, truncateString } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import ModalSelectPayeePayer from './modal-select-payee-payer';

const useStyles = makeStyles((theme: any) => ({
  title: { color: theme.palette.tab.offselected },
  badge: {
    color: theme.palette.primary.darker,
    backgroundColor: theme.accent.main,
    marginLeft: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  containerHeaderRight: { gap: theme.spacing(1) },
  buttonText: { textTransform: 'capitalize' },
}));

type HeaderProps = {
  description: string;
  amount: number;
  accountingID: string;
  handleSetAccounting:(accounting:AccountingType[])=>void
};

const HeaderPayor: FC<HeaderProps> = ({
  description,
  amount,
  accountingID,
  handleSetAccounting
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={6}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              Payment Info
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.badge}>
              {truncateString(description || '', 50)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid
          container
          direction="row-reverse"
          alignItems="center"
          className={classes.containerHeaderRight}
        >
          <Grid item>
            <Alert severity="info">
              <span>{`${t('charges:current-total')} `}</span>
              <span>{IntlCurrencyFormat(amount || 0)}</span>
            </Alert>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenModal(true)}
            >
              <Typography className={classes.buttonText} variant="body2">
                Select Payee/Payer
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ModalSelectPayeePayer
        accountingID={accountingID}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleUpdateAccounting={(data) => { handleSetAccounting(data); }}
      />
    </Grid>
  );
};

export default HeaderPayor;
