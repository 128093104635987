import { FilledInputProps, TextField } from '@material-ui/core';
import React, { FC } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { OrderProperty } from 'types/order';

import { useStyles } from './styles';

type PropertyTextFieldProps = {
  control: Control<OrderProperty, object>
  name: FieldPath<OrderProperty>,
  label: string | React.ReactNode,
  style?: React.CSSProperties,
  inputProps?: Partial<FilledInputProps>,
  multiline?: boolean,
  required?: boolean,
  isDisabled?: boolean
}

export const PropertyTextField: FC<PropertyTextFieldProps> = ({
  control,
  name,
  label,
  style,
  inputProps,
  multiline,
  required = false,
  isDisabled = false
}) => {
  const classes = useStyles();
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label}
          InputLabelProps={{ shrink: field.value !== null && field.value !== undefined && field.value!.toString().length > 0 }}
          variant="filled"
          className={classes.input}
          style={style}
          disabled={isDisabled}
          InputProps={inputProps}
          multiline={multiline}
          fullWidth
          error={!!error}
        />
      )}
    />
  );
};
