import { useEffect, useReducer } from 'react';
import { useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import useDeleteLedger from 'utils/ledger/use-delete-ledger-entry';

const initialstate = {
  entryLedger: null,
  mode: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'edit':
      return {
		  mode: 'edit',
		  entryLedger: action.entryLedger
		   };
    case 'payments':
      return {
		  mode: 'payments',
		  entryLedger: action.entryLedger
      };
    case 'delete':
      return {
        mode: 'delete',
        entryLedger: action.entryLedger
      };
    case 'new':
      return {
		  mode: 'new',
		  entryLedger: null
      };
    default:
      return {
		  entryLedger: null,
		  mode: null
      };
  }
};
const useMortgageItems = (mortgateId:string) => {
  const [state, dispatch] = useReducer(reducer, initialstate);
  const queryCache = useQueryCache();
  const { id } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const { deleteEntry } = useDeleteLedger(() => {
    showAlert('Success Deleted', 'success');
    queryCache.invalidateQueries(['order-mortgages', id],);
  },
  () => {
    showAlert('there was an error', 'error');
  });

  useEffect(() => {
    dispatch({ type: null, entryLedger: null });
  }, [mortgateId]);

  return {
    state,
    dispatch,
    deleteEntry,
    id,
    queryCache
  };
};

export default useMortgageItems;
