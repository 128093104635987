import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const LoaderNotes = () => {
  const { t } = useTranslation();
  return (
    <Box style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', gap: '5px'
    }}
    >
      <Typography>{t('common:loading')}</Typography>
      <CircularProgress
        thickness={2}
        style={{ color: '#D1B74F' }}
      />
    </Box>
  );
};
