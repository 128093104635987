import React from 'react';

import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import useGetCharges from '../services/useGetCharges';
import ChargesGPrototpeScene from './charges-g-scene';

const ChargesG = () => {
  const {
    accounting,
    currentRowSelected,
    setCurrentRowSelected,
    handleSetAccounting,
    isLoading: loadingAccounting
  } = useGetCharges({});

  const handleSelectItem = (currentrow:columnsDataSet) => {
    setCurrentRowSelected(currentrow);
  };

  return (
    <ChargesGPrototpeScene
      accountingData={accounting}
      handleSetAccounting={handleSetAccounting}
      currentRowSelected={currentRowSelected}
      handleSelectRow={handleSelectItem}
      loadingAccounting={loadingAccounting}
    />
  );
};

export default ChargesG;
