import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { NewUserType } from 'types/new-user';
import {
  CONTACT_TITLE_ADMINISTRATOR,
  CONTACT_TITLE_AGENCY,
  CONTACT_TITLE_AGENT,
  CONTACT_TITLE_COMPANY_NAME,
} from 'utils/constants';

import SelectField from '../select-field';
import TextField from '../text-field';
import { PartiesHelperType } from './types';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
  emptyTitleContainer: { marginBottom: theme.spacing(2) },
  buttonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  iconContainer: { textAlign: 'center' },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: theme.spacing(1),
  },
  createButtonContainer: { marginTop: theme.spacing(2) },
}));

const titleRoles = [
  CONTACT_TITLE_AGENT,
  CONTACT_TITLE_ADMINISTRATOR,
  CONTACT_TITLE_AGENCY,
];

type FormPartiesProps = {
  t: TFunction;
  visual?: boolean;
  helpers: PartiesHelperType;
};

export const FormParties: FC<FormPartiesProps> = ({ t, helpers, visual = false }) => {
  const classes = useStyles();

  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<NewUserType>();
  const { fields: parties } = useFieldArray({ name: 'parties', control });

  return (
    <>
      {!parties.length && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} className={classes.emptyTitleContainer}>
            <Typography variant="body2" className={classes.title}>
              {t('users:no-parties')}
            </Typography>
          </Grid>
        </Grid>
      )}

      {!!parties.length && (
        <>
          <Box>
            {parties.map((party, index) => (
              <Grid
                key={party._id}
                container
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Controller
                    name={`parties.${index}.name`}
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        disabled={helpers.parties[index].name.disabled}
                        label={t('users:name')}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={visual ? 6 : 5}>
                  {getValues('parties')[index].name
                  === CONTACT_TITLE_COMPANY_NAME ? (
                    <Controller
                      name={`parties.${index}.kind`}
                      control={control}
                      render={({ field: { ref, onChange, onBlur, ...field }, }) => (
                        <SelectField
                          {...field}
                          inputRef={ref}
                          data={titleRoles}
                          dataKey=""
                          dataText=""
                          dataValue=""
                          disabled={helpers.parties[index].kind.disabled}
                          label={t('users:kind')}
                          handleChange={onChange}
                          handleBlur={() => {
                            const error = !!errors.parties;

                            if (!error) {
                              helpers.parties[index].kind.onBlur!(onBlur);
                            }
                          }}
                          error={
                            !!errors.parties
                            && !!errors.parties[index]
                            && !!errors?.parties[index]?.kind
                          }
                          helperText={
                            errors.parties
                            && errors.parties[index]
                            && errors?.parties[index]?.kind?.message
                          }
                        />
                      )}
                    />
                    ) : (
                      <Controller
                        name={`parties.${index}.kind`}
                        control={control}
                        render={({ field: { ref, onBlur, ...field } }) => (
                          <TextField
                            {...field}
                            inputRef={ref}
                            disabled={helpers.parties[index].kind.disabled}
                            label={t('users:kind')}
                            handleBlur={() => {
                              const error = !!errors.parties;

                              if (!error) {
                              helpers.parties[index].kind.onBlur!(onBlur);
                              }
                            }}
                            error={
                            !!errors.parties
                            && !!errors.parties[index]
                            && !!errors?.parties[index]?.kind
                          }
                            helperText={
                            errors.parties
                            && errors.parties[index]
                            && errors?.parties[index]?.kind?.message
                          }
                          />
                        )}
                      />
                    )}
                </Grid>

                {!visual && (
                  <Grid item xs={1} className={classes.iconContainer}>
                    <IconButton
                      className={classes.icon}
                      onClick={() => helpers.remove(party._id || '')}
                    >
                      <FontAwesomeIcon icon="times" size="xs" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>
        </>
      )}
    </>
  );
};
