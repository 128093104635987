import TableComponent from 'components/Table';
import React, { FC } from 'react';
import { NewUserType } from 'types/new-user';

import { ColumnsType } from '../services';

type ListProps = {
  parties: NewUserType[];
  columns: ColumnsType;
  handleEditNav: (id: string) => void;
};

const List: FC<ListProps> = ({ parties, columns, handleEditNav }) => (
  <TableComponent
    pointer
    disabledBorderLeft
    columns={columns}
    dataSet={parties || []}
    onClickItem={(party: NewUserType) => {
      handleEditNav(party._id);
    }}
  />
);

export default List;
