import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { Location, LocationKind } from 'types/geospatial';

export type GQLSearchLocations = {
  searchLocations: Location[]
};

export const searchLocations = async (
  payload: {
    kind: LocationKind,
    name: string,
    return?: LocationKind[]
  }
) => {
  const query = gql`
    query searchLocations($kind: LocationKind!, $name: String!, $return: [LocationKind!]) {
    searchLocations(kind: $kind, name: $name, return: $return) {
      name
      kind
      geometry
    }
  }
  `;
  const response = await graphQLClient()
    .request<GQLSearchLocations>(query, payload);

  return response;
};
