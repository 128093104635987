import React, { FC } from 'react';

import useParties from './new-parties-hooks';
import PartiesScene from './new-parties-scene';

const Parties: FC = () => {
  const {
    t,
    url,
    path,
    history,
    orderId,
    filteredparties,
    deleteAssociation,
    createAssociation,
    unfilteredParties,
    filterParties,
    openAddModal,
    setOpenAddModal,
    listingEntries
  } = useParties();

  const handleViewChange = (partytype: string, id: string) => {
    history.push(`${url.endsWith('/') ? url : url.concat('/')}${partytype}/${id}`);
  };

  const handleDeleteAssociation = (partyId: string, kind: string) => {
    deleteAssociation({ id: orderId!, partyId, kind });
  };

  const handleCreateAssociation = (partyId: string, kind: string, action: () => void) => {
    createAssociation({
      id: orderId!,
      partyId,
      kind,
      linkedToId: '',
      linkedToKind: ''
    });

    action();
  };

  return (
    <PartiesScene
      t={t}
      url={url}
      path={path}
      filteredparties={filteredparties}
      handleViewChange={handleViewChange}
      handleCreateAssociation={handleCreateAssociation}
      handleDeleteAssociation={handleDeleteAssociation}
      unfilteredParties={unfilteredParties}
      filterParties={filterParties}
      openAddModal={openAddModal}
      setOpenAddModal={setOpenAddModal}
      listingEntries={listingEntries}
    />
  );
};

export default Parties;
