import SelectField from 'components/select-field';
import React, { FC } from 'react';

import { useStyles } from './styles';

type Props = {
  value: boolean | null,
  onChange: (value: boolean) => void,
  isDisabled: boolean
}

export const SelectYesNo: FC<Props> = ({ value, onChange, isDisabled }) => {
  const classes = useStyles();
  return (
    <SelectField
      disabled={isDisabled}
      value={value !== null ? value.toString() : ''}
      data={[
        {
          title: 'Yes',
          value: true
        },
        {
          title: 'No',
          value: false
        }
      ]}
      dataKey="value"
      dataValue="value"
      dataText="title"
      InputProps={{ className: classes.underline }}
      style={{ width: '77px', flex: 'none' }}
      className={classes.select}
      handleChange={(e) => {
        const isEnabled = e.target.value.toString() === 'true';
        onChange(isEnabled);
      }}
    />
  );
};
