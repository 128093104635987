import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { WorkspaceRoutes } from 'types/common';

type CloseSidemenuProps = {
  setSidemenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleViewChange: (view: WorkspaceRoutes, param?: string) => void;
};

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.2),
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  headerIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  iconButton: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.2),
    color: theme.palette.common.white,
    fontSize: theme.spacing(2.5),
    '&:hover': {
      backgroundColor: theme.accent.main,
      color: theme.palette.primary.main,
    },
  },
}));

const CloseSidemenu = ({
  setSidemenuOpen,
  handleViewChange,
}: CloseSidemenuProps) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.header}>
        <IconButton
          className={classes.headerIcon}
          onClick={(e) => {
            e.stopPropagation();
            setSidemenuOpen(true);
		  }}
        >
          <FontAwesomeIcon icon="bars" size="xs" />
        </IconButton>
      </Box>

      <Box className={classes.container}>
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            handleViewChange('overview');
          }}
        >
          <FontAwesomeIcon icon="briefcase" />
        </IconButton>

        <IconButton
          className={classes.iconButton}
          onClick={() => handleViewChange('charges', 'a')}
        >
          <FontAwesomeIcon icon="file-invoice-dollar" />
        </IconButton>
      </Box>
    </>
  );
};

export default CloseSidemenu;
