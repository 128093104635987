import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';

import { createMortgage } from '../../../../services/mutations';
import { getOrderProperties } from '../../../../services/queries';

type addMortgageForm={
	estateId:string,
	loan_number:number | null,
	id:string
}
export const useAddMortgage = (open:boolean, onClose:()=>void) => {
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const queryCache = useQueryCache();
  const methods = useForm<addMortgageForm>({
    mode: 'onChange',
    defaultValues: {
		  id: orderId,
		  loan_number: null,
		  estateId: ''
    }
	  });
	  const getOrder = useQuery(
    ['mortgage-order-properties', orderId],
    () => getOrderProperties({ id: orderId }),
    {
      enabled: !!orderId && open,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    },
  );

  const [createMortgageMutation, createMortgageReponse] = useMutation(createMortgage, {
    onSuccess: () => {
      queryCache.invalidateQueries(['order-mortgages', orderId],);
      showAlert('Mortgage Created', 'success');
      onClose();
    },
    onError: () => {
      showAlert('there is an error', 'error');
    }
  });

  return {
    properties: getOrder.data?.getOrder.estates || [],
    methods,
    createMortgageMutation,
    createMortgageReponse
  };
};
