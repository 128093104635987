import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { AddAndUpdatePartyLedgerEntry as AddPartyEntry } from 'components/add-and-update-party-ledger-modal/add-and-update-party-ledger-entry';
import { format } from 'date-fns';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import useDeleteLedgerAssociation from 'utils/ledger/use-delete-ledger-association';
import { AccountingType } from 'v2-types/order';

import usePolicy from '../services/use-policy-context';
import DetailedInfo from './detailt-party-association';

const useStyles = makeStyles((theme: any) => ({
  optionsContainer: { marginTop: theme.spacing(4) },
  subtitleContainer: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row'
  },
  paymentBreakdownContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
  },
  paymentBreakdownItemContainer: { marginTop: theme.spacing(2) },
  thirdPayorButtonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  thirdPayorButton: { color: theme.palette.primary.light },
  thirdPayorIcon: { marginRight: theme.spacing(1) },
  title: { color: theme.palette.tab.offselected },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '0.875rem',
    '&:hover': { color: theme.palette.common.black },
    alignSelf: 'center'
  },
}));

type FormsPremiumProps = {
  accountingInfo: AccountingType | undefined,
  direction?: 'row' | 'column' | undefined
}

const FormBreakdown: FC<FormsPremiumProps> = ({ accountingInfo, direction }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const showAlert = useAlert();
  const { id } = useParams<{id:string}>();
  const { setAccounting, accounting, setLatestUpdate } = usePolicy() || {};
  const [openModal, setOpenModal] = useState(false);
  const updatedEndorsement = accounting?.find((acc) => acc._id === accountingInfo?._id && acc.kind === accountingInfo.kind);
  const { deleteAssociation, deleteAssociationResponse } = useDeleteLedgerAssociation((data) => {
    deleteAssociationResponse.reset();
    showAlert('Association Deleted', 'success');
    if (setAccounting && setLatestUpdate) {
	  setAccounting(data.deleteOrderLedgerAssociation?.accounting || []);
	  const now = format(new Date(), 'hh:mm');
	  setLatestUpdate({ time: now, type: 'success', message: `updated at  ${now}` });
    }
  });

  const deleteAssociationMemoized = useCallback((kind:string, party_id:string) => {
    if (accountingInfo) {
      deleteAssociation({
        id,
        accountingId: accountingInfo?._id,
        kind,
        partyId: party_id
      });
    }
  }, [accountingInfo, deleteAssociation, id]);

  const handleUpdateLedger = (accountingRes: AccountingType[]) => {
    if (setAccounting && setLatestUpdate) {
      const now = format(new Date(), 'hh:mm');
      setLatestUpdate({ time: now, type: 'success', message: `updated at  ${now}` });
      setAccounting(accountingRes);
    }
  };

  useEffect(() => {
    if (deleteAssociationResponse.isLoading) {
      showAlert('Deleting', 'warning');
    }
  }, [deleteAssociationResponse.isLoading, showAlert]);

  return (
    <Grid container direction={direction}>
      <Grid item xs={direction === 'row' ? 6 : 12}>
        <Grid item xs={12} className={classes.subtitleContainer}>
          <Typography variant="h6" className={classes.title}>
            {t('policyInfoAndRates:payment-breakdown')}
          </Typography>
          <Box style={{ alignItems: 'center', display: 'flex' }}>
            <IconButton
              className={classes.icon}
              onClick={() => setOpenModal(true)}
            >
              <FontAwesomeIcon icon="edit" />
            </IconButton>
          </Box>

        </Grid>
        <Grid item xs={12} className={classes.paymentBreakdownContainer}>
          { updatedEndorsement?.involved_parties?.filter((acc) => acc.kind.includes('Payer')).length
            ? updatedEndorsement?.involved_parties?.filter((acc) => acc.kind.includes('Payer')).map((acc) => (
              <DetailedInfo
                key={`${acc._id}-${acc.kind}`}
                association={acc}
                handleDeleteAssociation={deleteAssociationMemoized}
                partyDescription={t('policyInfoAndRates:paid-by')}
              />
            ))
            : (
              <Typography variant="body2" style={{ fontWeight: 500, color: 'black' }}>
                {t('policyInfoAndRates:not-defined-yet')}
              </Typography>
            )}
        </Grid>
      </Grid>
      <Grid item xs={direction === 'row' ? 6 : 12}>
        <Grid item xs={12} className={classes.subtitleContainer}>
          <Typography variant="h6" className={classes.title}>
            {t('policyInfoAndRates:payee-info')}
          </Typography>
          <Box style={{ alignItems: 'center', display: 'flex' }}>
            <IconButton
              className={classes.icon}
              onClick={() => setOpenModal(true)}
            >
              <FontAwesomeIcon icon="edit" />
            </IconButton>
          </Box>

        </Grid>
        <Grid item xs={12} className={classes.paymentBreakdownContainer}>
          {updatedEndorsement?.involved_parties?.filter((acc) => acc.kind.includes('Payee')).length
            ? updatedEndorsement?.involved_parties?.filter((acc) => acc.kind.includes('Payee')).map((acc) => (
              <DetailedInfo
                key={`${acc._id}-${acc.kind}`}
                association={acc}
                handleDeleteAssociation={deleteAssociationMemoized}
                partyDescription={t('policyInfoAndRates:disclosed-title-payee')}
              />
            ))
            : (
              <Typography variant="body2" style={{ fontWeight: 500, color: 'black' }}>
                {t('policyInfoAndRates:not-defined-yet')}
              </Typography>
            )}
        </Grid>
      </Grid>
      {openModal && accountingInfo
      && (
      <AddPartyEntry
        handleClose={() => { setOpenModal(false); }}
        handleSuccess={handleUpdateLedger}
        entryLedger={accountingInfo}
        kindAllowedAssociationList={['payee', 'payer']}
      />
      )}
    </Grid>
  );
};

FormBreakdown.defaultProps = { direction: undefined };

export default FormBreakdown;
