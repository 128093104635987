import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-ui/core/styles';
import { useGetUser } from 'hooks/useGetUser';
import { SnackbarProvider } from 'notistack';
import { MutateThemeContext } from 'providers/mutate-theme-provider';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProfile } from 'redux/profile-reducer/actions';
import Routes from 'routes';
import themes from 'theme/theme';
import { ACCESS_TOKEN, ADDRESS_KIND_LOGIN, TOKEN_CLAIMS } from 'utils/constants';
import { removeLSPersist } from 'utils/helpers';
import useValidSession from 'utils/session';
import { v4 as uuidV4 } from 'uuid';

import { UserReduxStateType } from './types/redux';

function App() {
  const dispatch = useDispatch();
  const session = useValidSession();
  const { theme } = useContext(MutateThemeContext);

  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,

  } = useAuth0();

  const [token, setToken] = useState<string | null>(null);

  useGetUser({
    config: {
      enabled: isAuthenticated && token,
      refetchOnWindowFocus: false,
      onSuccess: (userResponse) => {
        if (userResponse) {
          const userProfile: UserReduxStateType = {
            ...userResponse.getUser.party,
            isLoading: false,
            accesses: [...userResponse.getUser.accesses],
            settings: {
              language: 'en',
              theme: 'light',
            },
          };

          const { username } = userResponse.getUser.party;
          if (username !== null && username !== undefined && username.length) {
            userProfile.emails = [
              ...userProfile.emails,
              {
                _id: uuidV4(),
                address: username,
                kind: ADDRESS_KIND_LOGIN
              }
            ];
          }

          dispatch(setProfile(userProfile));
        }
      },
      onError: () => {
        session({ response: { status: 401 } } as any);
      }
    }
  });

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(TOKEN_CLAIMS, idToken?.__raw || '');
        setToken(idToken?.__raw || '');
      }
    })();
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      (async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          localStorage.setItem(ACCESS_TOKEN, accessToken);
        }
      })();
    }, 180000);
    return () => clearInterval(intervalId);
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      removeLSPersist();
    });

    return () => window.removeEventListener('beforeunload', () => {});
  }, []);

  const selectedTheme = useMemo(() => (
    theme === 'dark' ? themes.dark() : themes.light()
  ), [theme]);

  return (
    <ThemeProvider theme={selectedTheme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Routes />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
