import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import BackdropLoading from 'components/common/BackdropLoading';
import { LicenseFormList } from 'components/license-form-list';
import { ModalCreateAssociation } from 'components/modal-create-association';
import FormAddresses from 'components/users/addresses';
import ConfirmDialog from 'components/users/confirm-dialog';
import FormEmails from 'components/users/emails';
import { FormParties } from 'components/users/form-parties';
import FormMembers from 'components/users/members';
import FormPayments from 'components/users/payments';
import FormPersonalInformation from 'components/users/personal-information';
import FormPhones from 'components/users/phones';
import { GeneralInformationValuesType } from 'components/users/types';
import { GQLGetParty } from 'graphql/parties/queries';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { FormProvider, UseFormGetValues, UseFormReturn } from 'react-hook-form';
import { QueryResult } from 'react-query';
import { CONTACT_TITLE_AGENCY, CONTACT_TITLE_COMPANY_KIND } from 'utils/constants';
import { NewUserType } from 'v2-types/user';

import Header from './components/header';
import ModalInviteUser from './components/modal-invite-user';
import ModalResetPassword from './components/modal-reset-password';
import Navigation from './components/navigation';
import Orders from './components/orders';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(2) },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tabsContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tab: { textTransform: 'capitalize' },
  formContainer: { padding: theme.spacing(2) },
  title: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
  innerFormContainer: { marginBottom: theme.spacing(2) },
  formDivider: {
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[200],
  },
}));

type ContactEditSceneProps = {
  t: TFunction;
  loading: boolean;
  currentTab: number;
  methods: UseFormReturn<NewUserType>;
  errors: string[];
  modalInvite: boolean;
  confirmModalOpen: { state: boolean; kind: 'delete' | 'unlink'; };
  helpers: (getValues: UseFormGetValues<NewUserType>) => GeneralInformationValuesType;
  handleUnlink: (id: string) => void;
  handleDelete: (id: string) => void;
  handleCreateLoginEmail: (email: string, onClose: () => void) => void;
  handleOpenInviteUser: (state: boolean) => void;
  handleNavigateBack: () => void;
  handleOnChange: (_: React.ChangeEvent<{}>, newValue: number) => void;
  handleOpenConfirmModal: (state: boolean, kind: 'delete' | 'unlink') => void;
  openWorkspace: (orderId: string) => void;
  modalResetPassword: boolean;
  handleOpenModalResetPassword: (state: boolean) => void;
  handleResetPartyPassword: (email: string) => void;
  urlResetPassword: string,
  urlResetPasswordError: string,
  isUrlResetPasswordRequestLoading: boolean,
  handleCloseModalResetPassword: () => void,
  userHasAccessToResetPassword: boolean,
  setErrors: React.Dispatch<React.SetStateAction<string[]>>,
  modalCreateMemberOpen: boolean,
  setModalCreateMemberOpen: React.Dispatch<React.SetStateAction<boolean>>,
  getPartyResponse: QueryResult<GQLGetParty, unknown>
};

const ContactEditScene: FC<ContactEditSceneProps> = ({
  t,
  loading,
  currentTab,
  methods,
  errors,
  modalInvite,
  confirmModalOpen,
  helpers,
  handleUnlink,
  handleDelete,
  handleCreateLoginEmail,
  handleOpenInviteUser,
  handleNavigateBack,
  handleOnChange,
  handleOpenConfirmModal,
  openWorkspace,
  modalResetPassword,
  handleOpenModalResetPassword,
  handleResetPartyPassword,
  urlResetPassword,
  urlResetPasswordError,
  isUrlResetPasswordRequestLoading,
  handleCloseModalResetPassword,
  userHasAccessToResetPassword,
  setErrors,
  modalCreateMemberOpen,
  setModalCreateMemberOpen,
  getPartyResponse
}) => {
  const classes = useStyles();

  const _id = methods.watch('_id') || '';
  const name = methods.watch('name') || '';
  const initials = `${name.split(' ')[0]?.charAt(0) || ''}${name.split(' ')[1]?.charAt(0) || ''}`;
  const loginEmail = methods.getValues('username') || '';
  const isTitleCompany = methods.getValues('kind') === CONTACT_TITLE_COMPANY_KIND;
  const isTitleAgency = (methods.getValues('parties') || []).some((party) => party.kind === CONTACT_TITLE_AGENCY);

  return loading ? (
    <BackdropLoading open loadingText="loading" />
  ) : (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid item xs={12}>
          <Navigation
            t={t}
            name={methods.watch('name')}
            handleNavigateBack={handleNavigateBack}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Grid container direction="row">
                  {isTitleCompany && (
                    <Grid item xs={12}>
                      <Alert severity="warning">Title company</Alert>
                    </Grid>
                  )}

                  {!!errors.length && (
                    <Grid item xs={12}>
                      <Alert
                        severity="error"
                        classes={{ icon: classes.errorIconContainer }}
                      >
                        {errors.map((error) => (
                          <Typography variant="subtitle1">{error}</Typography>
                        ))}
                      </Alert>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Header
                      t={t}
                      name={methods.watch('name')}
                      user_id={methods.watch('user_id')}
                      isTitleCompany={isTitleCompany}
                      handleOpenConfirmModal={handleOpenConfirmModal}
                      handleOpenInviteUser={() => handleOpenInviteUser(true)}
                      handleOpenModalResetPassword={handleOpenModalResetPassword}
                      userHasAccessToResetPassword={userHasAccessToResetPassword}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.tabsContainer}>
                    <Tabs
                      value={currentTab}
                      onChange={handleOnChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab
                        label={t('users:general-information')}
                        className={classes.tab}
                      />
                      <Tab
                        label={t('users:addresses')}
                        className={classes.tab}
                      />
                      <Tab
                        label={t('users:licenses')}
                        className={classes.tab}
                      />
                      <Tab label={t('users:orders')} className={classes.tab} />
                      <Tab label={t('users:parties')} className={classes.tab} />
                      <Tab label={t('users:members')} className={classes.tab} />
                      <Tab
                        label={t('users:payments')}
                        className={classes.tab}
                      />
                    </Tabs>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider orientation="horizontal" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.formContainer}>
                <FormProvider {...methods}>
                  {currentTab === 0 && (
                    <Grid
                      container
                      direction="column"
                      spacing={3}
                      className={classes.innerFormContainer}
                    >
                      <Grid item xs={12}>
                        <FormPersonalInformation
                          t={t}
                          helpers={helpers(methods.getValues)}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" className={classes.title}>
                          {t('users:contact-information')}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container direction="row" spacing={5}>
                          <Grid item xs={6}>
                            <FormEmails
                              t={t}
                              helpers={helpers}
                            />
                          </Grid>

                          <Grid item xs={6} className={classes.formDivider}>
                            <FormPhones
                              t={t}
                              helpers={helpers(methods.getValues).phones}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {currentTab === 1 && (
                    <FormAddresses
                      t={t}
                      showTitle={false}
                      helpers={helpers(methods.getValues).addresses}
                    />
                  )}

                  {currentTab === 2 && (
                    <LicenseFormList
                      t={t}
                      helpers={helpers(methods.getValues).licenses}
                    />
                  )}

                  {currentTab === 3 && (
                    <Orders
                      t={t}
                      orders={methods.getValues().orders}
                      openWorkspace={openWorkspace}
                    />
                  )}

                  {currentTab === 4 && (
                    <FormParties
                      t={t}
                      helpers={helpers(methods.getValues).parties}
                    />
                  )}

                  {currentTab === 5 && (
                    <FormMembers
                      t={t}
                      members={methods.getValues('members')}
                      setModalCreateMemberOpen={setModalCreateMemberOpen}
                    />
                  )}

                  {currentTab === 6 && (
                    <FormPayments
                      t={t}
                      helpers={helpers(methods.getValues).payments}
                    />
                  )}
                </FormProvider>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <ModalInviteUser
        t={t}
        open={modalInvite}
        emails={methods.getValues('emails')}
        onClose={() => handleOpenInviteUser(false)}
        handleCreateLoginEmail={handleCreateLoginEmail}
      />

      <ConfirmDialog
        open={confirmModalOpen.state}
        title={
          confirmModalOpen.kind === 'delete'
            ? t('users:dialog-delete-title')
            : t('users:dialog-unlink-title')
        }
        name={name}
        initials={initials}
        description={
          confirmModalOpen.kind === 'delete'
            ? t('users:dialog-delete-description')
            : t('users:dialog-unlink-description')
        }
        confirm={confirmModalOpen.kind === 'delete'}
        confirmButtonText={
          confirmModalOpen.kind === 'delete'
            ? t('users:dialog-delete-confirm-button')
            : t('users:dialog-unlink-confirm-button')
        }
        handleConfirm={() => (confirmModalOpen.kind === 'delete'
          ? handleDelete(_id)
          : handleUnlink(_id))}
        handleCancel={() => handleOpenConfirmModal(false, 'delete')}
      />

      {userHasAccessToResetPassword && (
        <ModalResetPassword
          t={t}
          open={modalResetPassword}
          onClose={handleCloseModalResetPassword}
          hanldeResetPartyPassword={handleResetPartyPassword}
          email={loginEmail}
          urlResetPassword={urlResetPassword}
          errorMessage={urlResetPasswordError}
          isLoading={isUrlResetPasswordRequestLoading}
          nameInitials={initials}
          userName={name}
        />
      )}

      <ModalCreateAssociation
        partyId={_id}
        isTitleCompany={isTitleCompany}
        isTitleAgency={isTitleAgency}
        open={modalCreateMemberOpen}
        onClose={() => setModalCreateMemberOpen(false)}
        onSuccess={() => {
          getPartyResponse.refetch();
        }}
        onError={() => {
          setErrors((prev) => [...prev, 'Error associating user']);
        }}
      />
    </>
  );
};

export default ContactEditScene;
