import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion as MUAccordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UpdateOrderSpecific } from 'types/order';

type AccordionProps = {
  title: string;
  control:Control<UpdateOrderSpecific, any>,
  prefix:'data_buyer' | 'data_seller'
};

const useStyles = makeStyles((theme: any) => ({
  container: { marginTop: theme.spacing(3) },
  accordion: { backgroundColor: theme.palette.background.default },
  juratContainer: { marginTop: theme.spacing(2) },
  color: { color: theme.palette.primary.darker },
  colorText: { color: theme.palette.text.shadow }
}));

const Accordion: FC <AccordionProps> = ({
  title,
  control,
  prefix
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const valueAcknowledge = useWatch({
    control,
    name: `${prefix}.acknowledgement`
  });
  const valueAcknowJuat = useWatch({
    control,
    name: `${prefix}.jurat`
  });
  return (
    <MUAccordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon size="xs" icon="chevron-down" className={classes.color} />}
      >
        <Typography variant="subtitle1" className={classes.color}>
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container direction="column">
          <Grid item>
            <Controller
              name={`${prefix}.acknowledgement`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  multiline
                  rows={5}
                  value={valueAcknowledge}
                  label={t('orders:notary_blocks_acknowledgement')}
                />
              )}
            />
          </Grid>

          <Grid item className={classes.juratContainer}>
            <Controller
              name={`${prefix}.jurat`}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  multiline
                  rows={5}
                  value={valueAcknowJuat}
                  label={t('orders:notary_blocks_jurat')}
                />
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </MUAccordion>
  );
};

export default Accordion;
