import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import graphql from 'services/querys';
import {
  NewBuyerSellerType,
  NewExchange1031Type,
  NewOrderPartyType,
  NewPOAType,
  signatureBlockType,
  UpdateOrderSpecific,
} from 'types/order';
import useAlert from 'utils/alert';
import { INFORMATION, REAL_STATE_AGENT, TRANSACTION_COORDINATOR } from 'utils/constants';

import PartiesContext from '../../services/context';

const useBuyerHooks = (partyId) => {
  const context = useContext(PartiesContext);
  const { unfilteredParties, parties } = context || {};
  const [currentTab, setcurrentTab] = useState(INFORMATION);
  const [loaderActive, setLoaderActive] = useState(false);
  const { id: orderId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [attorneys, setAttorneys] = useState<NewOrderPartyType[]>([]);
  const [agents, setAgents] = useState<NewOrderPartyType[]>([]);
  const [poa, setPoa] = useState<NewOrderPartyType>();
  const [spouse, setSpouse] = useState<NewOrderPartyType>();
  const [exchange, setExchange] = useState<NewOrderPartyType>();
  const [realStateAgents, setRealStateAgents] = useState<NewOrderPartyType[]>([]);
  const [transactionCoordinators, setTransactionCoordinators] = useState<NewOrderPartyType[]>([]);
  const methods = useForm<UpdateOrderSpecific>();
  const [udpdateBuyerData, updatBuyerResponse] = useMutation(
    graphql.udpdateOrderBuyer
  );
  const [updatePOAData, updatePOAResponse] = useMutation(
    graphql.udpdateOrderPoa
  );
  const [updateExchangeData, updateExchangeResponse] = useMutation(
    graphql.udpdateOrderExchange
  );
  const showAlert = useAlert();

  useEffect(() => {
    const extraInfo = parties
      ?.find((b) => b.kind === 'Buyer')
      ?.parties.find((b) => b._id === partyId);
    const { setValue } = methods;
    const signature_default_values: signatureBlockType[] = [
      {
        kind: 'text',
        text: '',
        tabs: 0,
      },
      {
        kind: 'line',
        text: '',
        tabs: 0,
      },
    ];

    if (extraInfo) {
      const {
        vesting_block,
        acknowledgement,
        jurat,
        include_marital,
        include_vesting,
        vesting,
        vesting_type,
        exception_1099,
        ownership,
        declines_ssn_ein,
        is_foreign,
        listed_on_title,
      } = extraInfo.order_data?.find((databuyer) => databuyer.kind === 'Buyer') as NewBuyerSellerType || {};
      setValue(
        'data_buyer.vesting_block',
        vesting_block || signature_default_values
      );
      setValue('data_buyer.include_marital', include_marital);
      setValue('data_buyer.include_vesting', include_vesting);
      setValue('data_buyer.vesting', vesting);
      setValue('data_buyer.vesting_type', vesting_type);
      setValue('data_buyer.acknowledgement', acknowledgement);
      setValue('data_buyer.jurat', jurat);
      setValue('data_buyer.exception_1099', exception_1099);
      setValue('data_buyer.ownership', ownership);
      setValue('data_buyer.declines_ssn_ein', declines_ssn_ein);
      setValue('data_buyer.is_foreign', is_foreign);
      setValue('data_buyer.listed_on_title', listed_on_title);
    }
  }, [parties, methods, partyId]);

  useEffect(() => {
    if (unfilteredParties) {
      const attorneysLocal: NewOrderPartyType[] = [];
      const agentsLocal: NewOrderPartyType[] = [];
      let partyPOA: NewOrderPartyType | undefined;
      let partyExchange: NewOrderPartyType | undefined;
      let partySpouse: NewOrderPartyType | undefined;
      const realStateAgentList: NewOrderPartyType[] = [];
      const transactionCoordinatorList: NewOrderPartyType[] = [];

      unfilteredParties.forEach((party) => {
        if (party.associations) {
          party.associations.forEach((association) => {
            if (
              association.party_id === partyId
              && association.party_kind === 'Buyer'
            ) {
              if (association.linked_as.includes('Attorney')) {
                attorneysLocal.push(party);
              }
              if (association.linked_as.includes('Agent')) {
                agentsLocal.push(party);
              }
              if (association.linked_as.includes('Power of Attorney')) {
                partyPOA = party;
              }
              if (association.linked_as.includes('Exchange 1031')) {
                partyExchange = party;
              }
              if (association.linked_as.includes('Spouse')) {
                partySpouse = party;
              }
              if (association.linked_as.includes(REAL_STATE_AGENT)) {
                realStateAgentList.push(party);
              }
              if (association.linked_as.includes(TRANSACTION_COORDINATOR)) {
                transactionCoordinatorList.push(party);
              }
            }
          });
        }
      });
      setAgents(agentsLocal);
      setAttorneys(attorneysLocal);
      setPoa(partyPOA);
      setExchange(partyExchange);
      setSpouse(partySpouse);
      setRealStateAgents(realStateAgentList);
      setTransactionCoordinators(transactionCoordinatorList);
    }
  }, [unfilteredParties, partyId]);

  useEffect(() => {
    const { setValue } = methods;
    if (poa) {
      const { end_date, start_date } = poa.order_data?.find(
        (dataPoa) => dataPoa.kind === 'Power of Attorney'
      ) as NewPOAType;
      setValue('data_power_of_attorney.end_date', end_date);
      setValue('data_power_of_attorney.start_date', start_date);
    }
  }, [methods, poa]);

  useEffect(() => {
    const { setValue } = methods;
    if (exchange) {
      const { exchange_1031 } = exchange.order_data?.find(
        (dataExchange) => dataExchange.kind === 'Exchange 1031'
      ) as NewExchange1031Type;
      setValue('data_exchange_1031.exchange_1031', exchange_1031);
    }
  }, [methods, exchange]);

  useEffect(() => {
    if (updatBuyerResponse.isSuccess) {
      showAlert('Buyer Updated', 'success');
      updatBuyerResponse.reset();
    }
  }, [showAlert, updatBuyerResponse]);

  useEffect(() => {
    if (updatePOAResponse.isSuccess) {
      showAlert('Buyer Updated', 'success');
      updatePOAResponse.reset();
    }
  }, [showAlert, updatePOAResponse]);

  useEffect(() => {
    if (updateExchangeResponse.isSuccess) {
      showAlert('Buyer Updated', 'success');
      updateExchangeResponse.reset();
    }
  }, [showAlert, updateExchangeResponse]);

  return {
    currentTab,
    setcurrentTab,
    t,
    attorneys,
    methods,
    udpdateBuyerData,
    orderId,
    agents,
    poa,
    updatePOAData,
    exchange,
    updateExchangeData,
    spouse,
    realStateAgents,
    transactionCoordinators,
    loaderActive,
    setLoaderActive,
  };
};

export default useBuyerHooks;
