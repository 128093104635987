import { Dialog, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";
import { ContainedButton } from "components/ContainedButton";
import SelectField from "components/select-field";
import TextField from "components/text-field";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { OrderProperty } from "types/order";
import { Proration } from "v2-types/order";
import { useCreateProrationModal } from "./hooks";
import { useStyles } from "./styles";

type Props = {
  updateProrationsCache: (prorations: Proration[]) => void,
  properties: OrderProperty[],
  onClose: () => void
}

export const CreateProrationModal: FC<Props> = ({
  updateProrationsCache,
  properties,
  onClose
}) => {
  const classes = useStyles();
  const {
    isLoading,
    prorationForm,
    onConfirm
  } = useCreateProrationModal({
    updateProrationsCache,
    onClose
  });
  const { control } = prorationForm;

  return (
    <Dialog open fullWidth>
      <DialogContent style={{ padding: 0 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              Create Proration
            </Typography>
          </Grid>
          <Grid item container className={classes.container}>
            <Controller
              control={control}
              name="estateId"
              render={({ field }) => (
                <SelectField
                  label="Property"
                  value={field.value}
                  handleBlur={field.onBlur}
                  handleChange={field.onChange}
                  data={properties.map((property) => ({
                    ...property,
                    description: property.address.address
                  })) as any[]}
                  dataKey="_id"
                  dataValue="_id"
                  dataText="description"
                />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  color="secondary"
                  label={<Typography variant="body2">Description</Typography>}
                  name="loan_number"
                  handleBlur={field.onBlur}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="kind"
              render={({ field }) => (
                <SelectField
                  label="Kind"
                  value={field.value}
                  handleBlur={field.onBlur}
                  handleChange={field.onChange}
                  data={[
                    {
                      _id: 'installment',
                      value: "Installment"
                    },
                    {
                      _id: 'per_diem',
                      value: "Per Diem"
                    }
                  ]}
                  dataKey="_id"
                  dataValue="_id"
                  dataText="value"
                />
              )}
            />

            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <SelectField
                  label="Type"
                  value={field.value}
                  handleBlur={field.onBlur}
                  handleChange={field.onChange}
                  data={[
                    {
                      _id: 'basic',
                      value: "Basic"
                    },
                    {
                      _id: 'tax',
                      value: "Tax"
                    }
                  ]}
                  dataKey="_id"
                  dataValue="_id"
                  dataText="value"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <ContainedButton
          text="Close"
          onClick={onClose}
          disabled={isLoading}
        />
        <ContainedButton
          text="Create"
          isLoading={isLoading}
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};