import { TabOrder } from 'hooks/useOrderTabs';
import React from 'react';
import {
  cleanWorkspace,
  updateWorkspace,
} from 'redux/workspace-reducer/actions';
import { WorkspaceRoutes } from 'types/common';
import { CURRENT_PATH, DEFAULT_ROUTE } from 'utils/constants';

import OrdersLayoutScene from './orders-layout-scene';
import useOrdersLayout from './use-orders-layout';

const OrdersLayout = () => {
  const {
    t,
    url,
    history,
    sidemenuOpen,
    setSidemenuOpen,
    properties,
    parties,
    openedOrders,
    statusFetchOrderData,
    orderHasAccountingData,
    isOrderLoading,
    orderType,
    idTitleCompany,
    removeOrderTab,
    selectedOrderIdTab,
    selectOrderTab
  } = useOrdersLayout();

  const handleOrderTabChange = (
    _: React.ChangeEvent<{}>,
    tabSelected: TabOrder
  ) => {
    selectOrderTab(tabSelected);
  };

  const handleCloseTab = (id: string) => {
    removeOrderTab(id);
    // const copyOrders = { ...orders };
    // delete copyOrders[orderId];

    // const isObjectEmpty = Object.keys(copyOrders).length === 0;

    // if (isObjectEmpty) {
    //   dispatch(cleanWorkspace());
    //   localStorage.setItem(
    //     CURRENT_PATH,
    //     JSON.stringify({ view: '', param: '' })
    //   );
    //   history.replace('/orders/details');
    // }

    // if (!isObjectEmpty) {
    //   const listOrdersKey = Object.keys(orders || {});
    //   const positionAttribute = listOrdersKey.findIndex(
    //     (key) => key === orderId
    //   );
    //   const positionValidToChange = positionAttribute - 1 < 0
    //     ? positionAttribute + 1
    //     : positionAttribute - 1;
    //   const newCurrentOrder = copyOrders[listOrdersKey[positionValidToChange]];

    //   setCurrentOrderDetail(newCurrentOrder);
    //   setCurrentOrderTab(newCurrentOrder.id!);
    //   dispatch(updateWorkspace({ ...copyOrders }));

    //   localStorage.setItem(
    //     CURRENT_PATH,
    //     JSON.stringify({ view: DEFAULT_ROUTE, param: '' })
    //   );
    //   history.replace(`/orders/details/${newCurrentOrder.id}/${DEFAULT_ROUTE}`);
    // }
  };

  const handleViewChange = (view: WorkspaceRoutes, param = '') => {
    localStorage.setItem(CURRENT_PATH, JSON.stringify({ view, param }));
    history.push(`${url}/${view}/${param}`);
  };

  return (
    <OrdersLayoutScene
      t={t}
      sidemenuOpen={sidemenuOpen}
      setSidemenuOpen={setSidemenuOpen}
      openedOrders={openedOrders}
      currentOrderTab={selectedOrderIdTab}
      statusFetchOrderData={statusFetchOrderData}
      handleOrderTabChange={handleOrderTabChange}
      handleCloseTab={handleCloseTab}
      handleViewChange={handleViewChange}
      properties={properties}
      parties={parties}
      orderHasAccountingData={orderHasAccountingData}
      isOrderLoading={isOrderLoading}
      orderType={orderType}
      idTitleCompany={idTitleCompany}
    />
  );
};

export default OrdersLayout;
