import * as tus from 'tus-js-client';

type UploadFileParams = {
  file: File,
  token: string,
  orderId: string,
  filename: string,
  partyId: string,
  archiveId: string
}

const tusUrl = process.env.REACT_APP_API_BASE_URL_FILES;

export const uploadFile = async ({
  file,
  token,
  filename,
  orderId,
  partyId,
  archiveId
}: UploadFileParams) => new Promise((resolve, reject) => {
  const upload = new tus.Upload(file, {
    endpoint: tusUrl,
    metadata: {
      order_id: orderId,
      party_id: partyId,
      archive_id: archiveId,
      filename
    },
    headers: { Authorization: `Bearer ${token}` },
    onError: (error) => {
      reject(error);
    },
    onSuccess: () => {
      resolve(file.name);
    }
  });
  upload.start();
});
