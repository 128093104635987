import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import SelectField from 'components/select-field';
import React, { FC, useEffect, useRef, useState } from 'react';

import { AssociationType, FileDataAssociationType } from '../types';
import SearchAssociation from './search-association';

type AddDataModalType = {
  open: boolean;
  handleClose: (id: string) => void;
  parties: AssociationType[];
  estates: AssociationType[];
  handleAddData: (payload) => void;
  fileId: string;
};

const kinds = ['deliver', 'party', 'property', 'signer'];

const AddDataModal: FC<AddDataModalType> = ({
  open,
  handleClose,
  parties,
  estates,
  handleAddData,
  fileId,
}) => {
  const [dataAssociation, setDataAssociation] = useState<FileDataAssociationType>({
    kind: '',
    associationId: null,
    contact: null,
  });

  const kindPrev = useRef('');

  useEffect(() => {
    if (
      dataAssociation.kind === 'property'
      || kindPrev.current === 'property'
    ) {
      setDataAssociation({
        ...dataAssociation,
        contact: null,
        associationId: null,
      });
    }
    kindPrev.current = dataAssociation.kind;
  }, [dataAssociation.kind]);

  const handleOnClose = () => {
    setDataAssociation({
      kind: '',
      associationId: null,
      contact: null,
    });
    handleClose('');
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
    >
      <DialogTitle>Add Association</DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <SelectField
              value={dataAssociation.kind}
              handleChange={(e) => setDataAssociation({
                ...dataAssociation,
                kind: e.target.value,
              })}
              label="Kind"
              data={kinds}
              dataKey=""
              dataValue=""
              dataText=""
            />
          </Grid>

          <Grid item xs={12}>
            <SearchAssociation
              associations={
                dataAssociation.kind === 'property' ? estates : parties
              }
              setDataAssociation={setDataAssociation}
              dataAssociation={dataAssociation}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                disabled={
                  dataAssociation.kind === ''
                  || dataAssociation.contact === null
                }
                onClick={() => {
                  const payload = {
                    kind: dataAssociation.kind,
                    dataId: dataAssociation.associationId,
                    fileId,
                  };
                  handleAddData(payload);
                  handleOnClose();
                }}
                variant="contained"
                color="secondary"
              >
                Add Association
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddDataModal;
