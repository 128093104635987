import { createTheme } from '@material-ui/core/styles';

const font = "'Poppins', sans-serif";
const light = function createMyLightTheme(options?: any) {
  return createTheme({
    ...options,
    typography: { fontFamily: font, },
    palette: {
      type: 'light',
      primary: {
        main: '#263238',
        dark: '#000A12',
        light: '#4F5B62',
        contrastText: '#FFFFFF',
        darker: '#000000DE',
        500: '#607D8B'
      },
      secondary: {
        main: '#FB8C00',
        dark: '#ff6d00',
        light: '#4F5B62',
        contrastText: '#00000087',
        background: '#FFDFB2',
      },
      error: {
        main: '#F44336',
        dark: '#BA000D',
        light: '#FF7961',
        contrastText: '#00000087',
      },
      warning: {
        main: '#FDD835',
        dark: '#C6A700',
        light: '#FFFF6B',
        contrastText: '#00000087',
      },
      success: {
        main: '#4CAF50',
        dark: '#087F23',
        light: '#80E27E',
        contrastText: '#00000087',
      },
      info: {
        main: '#2196F3',
        dark: '#0069C0',
        light: '#6EC6FF',
        contrastText: '#00000087',
      },
      icon: { accent: '#FFF3E0' },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      },
      text: {
        primary: '#00000087',
        secondary: '#00000045',
        disabled: '#00000030',
        hint: '#00000020',
        shadow: '#00000073',
        light: '#78909C',
        primary_light: '#90A4AE',
      },
      dualText: {
        primary: '#FFFFFF87',
        secondary: '#FFFFFF45',
        disabled: '#FFFFFF30',
        hint: '#FFFFFF20',
      },
      divider: '#D1B74F20',
      neutraldivider: '#D3D7D9',
      background: {
        paper: '#FFFFFF',
        default: '#ECEFF1',
        ligth: '#FFFBEA',
        sidebar: '#FFFFFFDE',
        component: '#B0BEC5',
      },
      table: {
        hover: '#d5dbdf',
        row_left: '#90A4AE',
        row_bottom: '#00000029'
      },
      dualBackground: {
        paper: '#263238',
        default: '#263238',
      },
      offBackground: {
        paper: '#263238',
        default: '#FFFFFF'
      },
      common: {
        black: '#000000',
        white: '#FFFFFF',
      },
      button: { default: '#CFD8DC', hover: '#FFBD45' },
      tab: { offselected: '#607D8B' },
      border: {
        dashed: '#707070',
        main: '#00000033',
        line: '#D1D8DC',
        card: '#D1D8DB',
      },
      input: { border: '#0000001A' }
    },
    accent: {
      main: '#D1B74F',
      dark: '#9D871E',
      light: '#FFE97F',
      accent: '#00000087',
    },
    overrides: { '.MuiTab-textColorPrimary.Mui-selected': { color: 'red' } },
  });
};
const dark = function createMyDarkTheme(options?: any) {
  return createTheme({
    ...options,
    typography: { fontFamily: font, },
    palette: {
      type: 'dark',
      primary: {
        main: '#263238',
        dark: '#000A12',
        light: '#4F5B62',
        contrastText: '#FFFFFF',
        darker: '#000000DE',
      },
      secondary: {
        main: '#ff6d00',
        dark: '#FB8C00',
        light: '#4F5B62',
        contrastText: '#00000087',
      },
      icon: { accent: '#FFF3E0' },
      error: {
        main: '#F44336',
        dark: '#BA000D',
        light: '#FF7961',
        contrastText: '#00000087',
      },
      warning: {
        main: '#FDD835',
        dark: '#C6A700',
        light: '#FFFF6B',
        contrastText: '#00000087',
      },
      success: {
        main: '#4CAF50',
        dark: '#087F23',
        light: '#80E27E',
        contrastText: '#00000087',
      },
      info: {
        main: '#2196F3',
        dark: '#0069C0',
        light: '#6EC6FF',
        contrastText: '#00000087',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      },
      text: {
        primary: '#FFFFFF87',
        secondary: '#FFFFFF45',
        disabled: '#FFFFFF30',
        hint: '#FFFFFF20',
        shadow: '#00000073'
      },
      dualText: {
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
        disabled: '#FFFFFF',
        hint: '#FFFFFF',
      },
      divider: '#D1B74F',
      neutraldivider: '#D3D7D9',
      background: {
        paper: '#263238',
        default: '#000A12',
        ligth: '#FFFBEA'
      },
      dualBackground: {
        paper: '#263238',
        default: '#263238',
      },
      offBackground: {
        paper: '#FFFFFF',
        default: '#263238'
      },
      common: {
        black: '#000000',
        white: '#FFFFFF',
      },
      button: { default: '#CFD8DC', hover: '#FFBD45' },
      tab: { offselected: '#607D8B' },
      border: { dashed: '#707070', main: '#00000033', line: '#D1D8DC' },
    },
    accent: {
      main: '#D1B74F',
      dark: '#9D871E',
      light: '#FFE97F',
      accent: '#00000087',
    },
    overrides: { MuiTab: { color: '#FFFFFF87', } },
  });
};

export default { light, dark };
