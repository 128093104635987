import { Grid } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { MilestoneStatus } from './components/milestone-status';
import { Sections } from './components/sections';
import { Milestone } from './services';

type ProgressOrderSceneProps = {
  t: TFunction,
  milestoneList: Milestone[],
  selectedMilestone: Milestone,
  setSelectedMilestone: (milestone: Milestone) => void,
};

export const ProgressOrderScene: FC<ProgressOrderSceneProps> = ({
  t,
  milestoneList,
  selectedMilestone,
  setSelectedMilestone,
}) => (
  <Grid container direction="column" spacing={2}>
    <Grid item>
      <MilestoneStatus
        t={t}
        milestones={milestoneList}
        selectedMilestone={selectedMilestone}
        handleSelect={setSelectedMilestone}
      />
    </Grid>

    <Grid item>
      <Sections
        t={t}
        selectedMilestone={selectedMilestone}
      />
    </Grid>
  </Grid>
);
