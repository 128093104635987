import {
  Button, Dialog, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SaveButton from 'components/common/save-button';
import DatePicker from 'components/date-picker/date-picker';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { AccountingFilter, AccountingType } from 'v2-types/order';

import { useAddEntryLinkedModal } from './hooks';
import { useStyles } from './styles';
import { ledgerCodeList } from './utils';

type Props = {
  open: boolean,
  accountingId: string,
  chargeDescription?: string,
  kind: string,
  partyId: string,
  onAddEntrySuccess: (accounting: AccountingType[]) => void,
  onCloseModal: () => void,
  accountingFilter: AccountingFilter
}

export const AddEntryLinkedModal: FC<Props> = ({
  open,
  accountingId,
  chargeDescription,
  kind,
  partyId,
  onAddEntrySuccess,
  onCloseModal,
  accountingFilter
}) => {
  const classes = useStyles();
  const {
    t,
    control,
    onAddEntry,
    errors,
    isAddEntryLoading,
    formState
  } = useAddEntryLinkedModal({
    accountingId,
    chargeDescription,
    kind,
    partyId,
    onAddEntrySuccess,
    onCloseModal,
    accountingFilter
  });

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCloseModal();
        }
      }}
      fullWidth
      className={classes.removecontentPadding}
    >
      <DialogContent className={classes.nocontendPadding}>
        <Grid
          container
          className={classes.itempaddinType}
          direction="column"
        >
          <Grid item xs={12}>
            <Typography variant="h6" style={{ color: 'black' }}>
              {t('accounting:add-payment')}
            </Typography>
          </Grid>
          {errors.length > 0 && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                classes={{ icon: classes.errorIconContainer }}
              >
                {errors.map((error) => (
                  <Typography variant="subtitle1">{error}</Typography>
                ))}
              </Alert>
            </Grid>
          )}

          <Controller
            name="amount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                inputRef={ref}
                label={t('accounting:amount')}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                inputRef={ref}
                label={t('accounting:description')}
                error={!!formState.errors.description}
              />
            )}
          />

          <Controller
            control={control}
            name="code"
            render={({ field: { ref, value, onChange, ...field } }) => (
              <SelectField
                {...field}
                inputRef={ref}
                value={value}
                data={ledgerCodeList}
                dataKey="value"
                dataValue="value"
                dataText="label"
                handleChange={onChange}
                label={t('accounting:code')}
              />
            )}
          />

          <Controller
            name="entryDate"
            control={control}
            render={({ field: { ref, value, ...field } }) => (
              <DatePicker
                {...field}
                inputRef={ref}
                disableToolbar={false}
                label={t('accounting:entry-date')}
                value={value as string | Date}
              />
            )}
          />

        </Grid>

      </DialogContent>
      <DialogActions disableSpacing style={{ padding: 16 }} className={classes.itempaddinfoother}>
        <Button
          color="primary"
          variant="outlined"
          disableElevation
          onClick={onCloseModal}
        >
          <Typography variant="body2" className={classes.text}>
            {t('common:close')}
          </Typography>
        </Button>
        <SaveButton
          onClick={onAddEntry}
          customText={t('common:add')}
          loading={isAddEntryLoading}
        />
      </DialogActions>
    </Dialog>
  );
};
