import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  buttonText: {
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
}));
