import { TOKEN_CLAIMS } from 'utils/constants';

export const getSpeechAudioByText = async ({ text, signal }: { text: string, signal: AbortSignal }) => {
  const access_token = await localStorage.getItem(TOKEN_CLAIMS);
  const response = await fetch(`${process.env.REACT_APP_API_URL!}/inference/speech`, {
    method: 'POST',
    body: JSON.stringify({ text }),
    headers: {
      Accept: 'audio/mpeg',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    signal
  });
  return response;
};
