import DatePicker from 'components/date-picker/date-picker';
import { format, isValid, parseISO } from 'date-fns';
import React, { FC } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { OrderProperty } from 'types/order';

type PropertyDatePickerProps = {
  control: Control<OrderProperty, object>,
  name: FieldPath<OrderProperty>,
  label: string,
}

export const PropertyDatePicker: FC<PropertyDatePickerProps> = ({
  control,
  name,
  label,
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field, fieldState: { error } }) => (
      <DatePicker
        label={label}
        {...field}
        onChange={(e) => { field.onChange(isValid(e) ? format(new Date(e?.toString() ?? ''), 'yyyy-MM-dd') : e); }}
        value={parseISO(field.value?.toString() ?? '').toDateString()}
        InputLabelProps={{ shrink: field.value !== null && field.value!.toString().length > 0 }}
        error={!!error}
      />
    )}
  />
);
