import Actionbuttons from 'components/common/actions-buttons';
import TableComponent from 'components/Table';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/helpers';
import { AccountingLoanEntry } from 'v2-types/order';

import { ModalData } from '../../types';

type Props = {
  data: AccountingLoanEntry[],
  onShowModal: (data: ModalData) => void
}

export const AccountingTable: FC<Props> = ({ data, onShowModal }) => {
  const { t } = useTranslation();

  return (
    <TableComponent
      disabledBorderLeft
      cellAlignment="left"
      columns={{
        actions: {
          header: { title: t('accounting:actions'), width: '100px' },
          renderer: ({ row }) => (
            <Actionbuttons
              handleEdit={() => onShowModal({ modal: 'editEntry', entry: row })}
            />
          )
        },
        name: {
          header: { title: t('accounting:description'), align: 'left' },
          renderer: ({ row }) => <>{row.description}</>,
        },
        amount: {
          header: { title: t('accounting:amount'), align: 'left' },
          renderer: ({ row }) => <>{formatMoney(row.amount)}</>,
        }
      }}
      dataSet={data || []}
    />
  );
};
