import React, { FC, ReactNode, useState } from 'react';
import { Socket } from 'socket.io-client/build/esm/socket';

import { SocketContext } from './socket-context';

type SocketContextProps = {
  children: ReactNode;
};

const SocketProvider: FC<SocketContextProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  return (
    <SocketContext.Provider
      value={{
        socket,
        updateSocket: setSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
