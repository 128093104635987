import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProfile } from 'redux/profile-reducer/selectors';
import { CONTACT_TITLE_ADMINISTRATOR } from 'utils/constants';

const useAccessRequired = () => {
  const user = useSelector(getProfile);
  const { t } = useTranslation();
  const isAdmin = user.accesses.find((access) => access.authorization === CONTACT_TITLE_ADMINISTRATOR);
  return { isAdmin, t };
};

export default useAccessRequired;
