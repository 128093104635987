export const loanTypes = [
  "Conventional Insured",
  "Conventional Uninsured",
  "RHS",
  "FHA",
  "VA"
];

export const loanPurpose = [
  "Purchase",
  "Refinance",
  "Home Equality Loan",
  "Construction"
];