import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NegativeAmortizationKind } from 'v2-types/order';
import { LoanFormType } from 'views/orders/loans/types';

import { RadioButtonForm } from '../radio-button-form';

type Props = {
  control: Control<LoanFormType, object>
  isDataLoading: boolean,
}

export const NegativeAmortizationForm: FC<Props> = ({
  control,
  isDataLoading
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="negative_amortization"
      render={({ field }) => (
        <RadioButtonForm
          title={t('loanDisclosures:negative-amortization')}
          description={t('loanDisclosures:negative-amortization-subtitle')}
          value={field.value}
          options={[
            {
              label: t('loanDisclosures:negative-amortization-option-1'),
              disabled: isDataLoading,
              value: NegativeAmortizationKind.feature
            },
            {
              label: t('loanDisclosures:negative-amortization-option-2'),
              disabled: isDataLoading,
              value: NegativeAmortizationKind.possible
            },
            {
              label: t('loanDisclosures:negative-amortization-option-3'),
              disabled: isDataLoading,
              value: NegativeAmortizationKind.nonexistent
            }
          ]}
          onChange={(value) => field.onChange(value)}
        />
      )}
    />
  );
};
