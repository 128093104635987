import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SaveButton from 'components/common/save-button';
import TextField from 'components/text-field';
import { format } from 'date-fns';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { AccountingType } from 'v2-types/order';

import { editManualCharge } from '../graphql/mutations';
import usePolicy from '../services/use-policy-context';

const useStyles = makeStyles((theme:any) => ({
  container: { padding: theme.spacing(3) },
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  icon: {
    fontSize: '16px',
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  itempaddinType: {
    '& > * ': { marginTop: theme.spacing(2) },
    padding: theme.spacing(3),
    margin: 0,
  },
  itempaddinfoother: { '& > * ': { marginLeft: theme.spacing(2) } },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nocontendPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  removecontentPadding: { '& .MuiDialogContent-root': { padding: 0 } }
}));

type EditendorsementType = {
  amount_override: number,
  amount_calculated:number
}

type EditEntryModal = {
  onClose:() =>void;
  open: boolean;
  accounting:AccountingType
}

const EditEntry: FC<EditEntryModal> = ({ open, onClose, accounting }) => {
  const prevData = {};
  const classes = useStyles();
  const [customError, setCustomErrors] = useState<string[]>([]);
  const { id } = useParams<{id:string}>();
  const { t } = useTranslation();
  const { setAccounting, setLatestUpdate } = usePolicy() || {};
  const { control, handleSubmit, getValues, setValue } = useForm<EditendorsementType>({
    defaultValues: {
      amount_override: accounting.amount_override > 0 ? accounting.amount_override : accounting.amount,
      amount_calculated: accounting.amount_calculated || 0
    }
  });
  const [editEntry, editEntryResponse] = useMutation(editManualCharge,
    {
      onSuccess(data) {
        editEntryResponse.reset();
        onClose();
        if (setAccounting && setLatestUpdate) {
          setAccounting(data.updateOrderLedgerEntry.accounting || []);
          const now = format(new Date(), 'hh:mm');
          setLatestUpdate({ time: now, type: 'success', message: `updated at  ${now}` });
        }
      },
      onError(e:any) {
        const { errors = ['Error Adding Association'] } = e?.response || {};
        const errorsMapped = errors.map((error:any) => error.message);
        setCustomErrors((prev) => [...prev, errorsMapped]);
      },
    });
  const handleEdit = handleSubmit((data) => {
    editEntry({
      id,
      accountingId: accounting._id,
      data: { amount_override: parseFloat(data.amount_override as unknown as string) }
    });
  });

  const handleResetValue = () => {
    const amount_calculated = getValues('amount_calculated');
    setValue('amount_override', amount_calculated);
  };
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      className={classes.removecontentPadding}
    >
      <form onSubmit={handleEdit}>
        <DialogContent className={classes.nocontendPadding}>
          <Grid
            container
            className={classes.itempaddinType}
            direction="column"
          >
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: 'black' }}>
                Edit Ledger Entry
              </Typography>
            </Grid>
            {!!customError.length && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                classes={{ icon: classes.errorIconContainer }}
              >
                {customError.map((error) => (
                  <Typography variant="subtitle1">{error}</Typography>
                ))}
              </Alert>
            </Grid>
            )}
            <Controller
              name="amount_override"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  InputProps={ref}
                  label={t('accounting:amount-override')}
                />
              )}
            />

          </Grid>

        </DialogContent>
        <DialogActions disableSpacing style={{ padding: 16 }} className={classes.itempaddinfoother}>
          <Button
            color="primary"
            variant="outlined"
            disableElevation
            onClick={onClose}
          >
            <Typography variant="body2" className={classes.text}>
              {t('common:close')}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={handleResetValue}
            disableElevation
          >
            <Typography variant="body2" className={classes.text}>
              {t('accounting:reset-value')}
            </Typography>
          </Button>
          <SaveButton
            type="submit"
            customText={`${prevData ? t('accounting:edit-entry') : t('accounting:add-entry')}`}
            loading={editEntryResponse.isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditEntry;
