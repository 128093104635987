import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ContainedButton } from 'components/ContainedButton';
import { OptionButton } from 'components/OptionButton';
import React, { FC } from 'react';
import { Loan } from 'v2-types/order';

import { ModalData } from '../../types';
import { useStyles } from './styles';

type Props = {
  loans: Loan[],
  isDataLoading: boolean,
  selectedLoan?: Loan,
  setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>,
  onSelectLoan: (loan: Loan | undefined) => void,
}

export const Header: FC<Props> = ({
  loans,
  isDataLoading,
  selectedLoan,
  setModalData,
  onSelectLoan
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.headerTitle}>
      <Typography className={classes.title}>Loans</Typography>

      <Box style={{ display: 'flex', gap: '10px' }}>
        {isDataLoading && [1, 2, 3].map((key) => (
          <Skeleton key={key} animation="wave" variant="text" height={42} width={86} />
        ))}
        {loans.map((loan, index) => (
          <OptionButton
            isSelected={selectedLoan?._id === loan._id}
            text={`Loan #${index + 1}`}
            onClick={() => onSelectLoan(loan)}
          />
        ))}
        <ContainedButton
          text="Add"
          icon="plus"
          disabled={isDataLoading}
          onClick={() => setModalData({ modal: 'createLoan' })}
        />
      </Box>
    </Box>
  );
};
