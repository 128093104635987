import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import React from 'react';
import { formatMoney } from 'utils/helpers';
import { AccountedBy } from 'v2-types/order';

const useStyles = makeStyles((theme:any) => ({
  colorBlack: { backgroundColor: theme.palette.common.black },
  divider: {
    backgroundColor: theme.palette.common.black,
    height: theme.spacing(0.12),
    margin: theme.spacing(0.5)
  },
  text: {
    fontWeight: 500,
    color: theme.palette.common.black
  }
}));

const ListAccounted = ({ list, total }:{list:AccountedBy[], total:number}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={2}>
      { list.length > 0
        ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.text}>
                Accounted By
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" flex="1" style={{ padding: 4 }}>
                {list?.map((accounted) => (
                  <Typography variant="body2" className={classes.text}>{`${formatMoney(accounted.amount)} (${accounted.record.description})`}</Typography>
                ))}
                <Divider orientation="horizontal" className={classes.divider} variant="fullWidth" />
                <Typography variant="body2" className={classes.text}>{`${formatMoney(total)} (Total Amount Accounted)`}</Typography>
              </Box>
            </Grid>
          </>
        )
	  : <>not defined yet</>}
    </Grid>
  );
};

export default ListAccounted;

/*

*/
