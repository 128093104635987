import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { TFunctionResult } from 'i18next';
import React, { FC } from 'react';

export type TextProps = {
  value: string | number | TFunctionResult,
  variant?: Variant,
  isMoney?: boolean,
  leftAdorment?: string | React.ReactNode,
  fontWeight?: 'bold' | 'normal',
  color?: | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error',
  style?: React.CSSProperties,
  maxLength?: number
}

export const Text: FC<TextProps> = ({
  value,
  variant = 'body2',
  isMoney = false,
  leftAdorment = null,
  fontWeight = 'normal',
  color = 'initial',
  style = {},
  maxLength
}) => {
  let text = value?.toString() ?? '';
  if (isMoney && !Number.isNaN(Number(text))) {
    text = `$${Number(value).toLocaleString('en-US')}`.replace('$-', '-$');
  }

  if (maxLength && text.length > maxLength) {
    text = `${text.toString().substring(0, maxLength - 3)}...`;
  }

  return (
    <Typography
      variant={variant}
      color={color}
      style={{ ...style, fontWeight }}
    >
      {leftAdorment}
      {text}
    </Typography>
  );
};
