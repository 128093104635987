import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(2) },
  title: {
    fontWeight: 500,
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.accent.main,
    paddingBottom: theme.spacing(2)
  },
  subtitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  dropzoneContainer: {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'lightgray',
    borderStyle: 'dashed',
    padding: theme.spacing(5),
    width: '90%'
  },
  fileContainer: {
    borderRadius: '15px',
    background: '#ececec',
    width: 'auto',
    paddingRight: theme.spacing(2)
  },
  removeFileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    borderRadius: '50%',
    background: '#646b6e',
  },
  actionButtons: { padding: theme.spacing(2) },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
