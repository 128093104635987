import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { memo } from 'react';

const useStyles = makeStyles((theme: any) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(10),
    borderBottomStyle: 'solid',
    borderBottomColor: theme.accent.main,
    borderBottomWidth: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    '& :nth-child(2)': { marginLeft: 'auto' },
  },
  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': { marginRight: theme.spacing(1) }
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 23,
    fontWeight: 500
  },
  containerText2: {
    background: theme.accent.main,
    fontSize: theme.spacing(1.7),
    color: theme.palette.primary.darker,
    borderRadius: 4,
    padding: theme.spacing(0.25, 0.5),
    textTransform: 'uppercase'
  },
}));

type HeaderChargesProps = {
  title: string;
  chargesIdentifier: string;
};

const HeaderCharges = ({
  title,
  chargesIdentifier,
}: HeaderChargesProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.header}>
      <Box className={classes.inlineText}>
        <Typography className={classes.title}>
          {title}
        </Typography>

        <Typography className={classes.containerText2}>
          {chargesIdentifier}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(HeaderCharges);
