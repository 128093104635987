import gqlRequest from 'graphql/generic';

import { SignatureType } from '../services/types';

type addNewSignReturn ={
	requestSignature:SignatureType[]
}
const mutation = `
mutation Mutation($id: ID!, $source: String!, $embedded: Boolean) {
	requestSignature(_id: $id, source: $source,embedded: $embedded) {
		_id
		order_id
		key_id
		archive_id
		source
		has_error
		is_complete
		is_declined
		description
		provider_id
		signers {
		  _id
		  email
		  error
		  name
		  order
		  status
		  sign_url
		}
	}
  }
`;

type addNewSignatureParams = {
	id: string,
	source: string,
	embedded:boolean
}

export const addNewSignature = (params:addNewSignatureParams) => gqlRequest<addNewSignReturn, addNewSignatureParams>(mutation, params);
