import { Skeleton } from '@material-ui/lab';
import { TFunction } from 'i18next';
import React from 'react';

export const getLoadingColumns = ({ t }: {
  t: TFunction
}) => ({
  title: {
    header: { title: '', width: '250px' },
    renderer: () => <Skeleton animation="wave" variant="text" height={40} />
  },
  loan_estimate: {
    header: { title: t('cashToClose:loan-estimate-table-title'), width: '144px' },
    renderer: () => <Skeleton animation="wave" variant="text" height={40} />
  },
  final: {
    header: { title: t('cashToClose:final'), width: '144px' },
    renderer: () => <Skeleton animation="wave" variant="text" height={40} />,
  },
  did_this_charge: {
    header: { title: t('cashToClose:did-this-change') },
    renderer: () => <Skeleton animation="wave" variant="text" height={40} />
  }
});
