import { Grid, } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import TextField from 'components/text-field';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PartyAccounting } from 'types/ledger';

type SearchContactProps = {
  getValue:(user: PartyAccounting | null)=>void
  parties: PartyAccounting[],
  filterParties?: string,
};

const SearchContact: FC<SearchContactProps> = ({ getValue, parties, filterParties }) => {
  const { t } = useTranslation();
  const defaults = createFilterOptions<PartyAccounting>();
  const [selectedParty, setSelectedParty] = useState<PartyAccounting | undefined>();

  useEffect(() => {
    setSelectedParty(parties.length ? parties[0] : undefined);
  }, [parties]);

  return (

    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>

        <Autocomplete
          fullWidth
          options={parties}
          defaultValue={selectedParty}
          filterOptions={filterParties ? (options, state) => {
		    const results = defaults(options, state);
		    return results.filter((r) => r.kinds.includes(filterParties));
		  } : undefined}
          getOptionLabel={(party) => `${party.name}(${party.kinds.toString()})`}
          onInputChange={(_, value, reason) => {
            if (reason === 'input') {
              getValue(null);
            }
          }}
          onChange={(_, value) => {
            if (value && typeof value === 'object') {
              getValue(value);
              setSelectedParty(value);
            } else {
              getValue(null);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={params.InputProps.ref}
              InputProps={{
                ...params.inputProps,
                value: selectedParty ? `${selectedParty.name}(${selectedParty.kinds.toString()})` : undefined,
              }}
              LabelProps={{ shrink: selectedParty !== undefined }}
              label={t('parties:contact')}
            />
          )}
        />
      </Grid>
    </Grid>

  );
};

export default SearchContact;
