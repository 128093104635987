import axios, { AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30 * 1000,
});

const interceptorRequest = (config: AxiosRequestConfig<any>) => {
  const access_token = localStorage.getItem('access_token');

  if (
    typeof access_token === 'string'
    && access_token.length
    && config.headers
  ) {
    config.headers.authorization = `Bearer ${access_token}`;
  }

  return config;
};

const interceptorResponseReject = async (error: any) => {
  if (error && error.config && error.response) {
    const originalRequest = error.config;
    if (error.response.status === StatusCodes.UNAUTHORIZED && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = localStorage.getItem('access_token');
      error.config.headers.authorization = `Bearer ${access_token}`;
      return axiosInstance.request(originalRequest);
    }
  }
  let errorData = new Error("Unexpected error");
  if (error.response) {
    errorData = error;
  }

  return Promise.reject(errorData);
};

axiosInstance.interceptors.request.use(interceptorRequest, (error) => Promise.reject(error));

axiosInstance.interceptors.response.use((response) => response, interceptorResponseReject);

export default axiosInstance;
