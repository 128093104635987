import { Box, Grid, RadioGroup, Typography } from "@material-ui/core";
import Radio from "components/radio";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles";
import { ProrationsForm } from "../types";

type Props = {
  selectedTabIndex: number
  isLoading: boolean
}

export const DebitedToForm: FC<Props> = ({
  selectedTabIndex,
  isLoading
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProrationsForm>();
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        {t('taxesAndProrations:proration-date-debited-to')}
      </Typography>
      <Box>
        <Controller
          control={control}
          key={`forms.${selectedTabIndex}.debited_party`}
          name={`forms.${selectedTabIndex}.debited_party`}
          render={({ field }) => (
            <RadioGroup
              value={field.value}
              onChange={(_, value) => {
                field.onChange(value);
              }}
            >
              <Grid container>
                <Grid item>
                  <Radio
                    label={t('taxesAndProrations:seller')}
                    value="seller_owes_buyer"
                    disabled={isLoading}
                  />
                </Grid>

                <Grid item>
                  <Radio
                    label={t('taxesAndProrations:buyer')}
                    value="buyer_owes_seller"
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
        {/* <Controller
        control={control}
        key={`forms.${selectedTabIndex}.debited_party`}
        name={`forms.${selectedTabIndex}.debited_party`}
        render={({ field }) => (
          <ButtonGroup
            fullWidth
            disableElevation
            color="primary"
            disabled={isLoading}
          >
            <Button
              variant={field.value === 'seller_owes_buyer' ? 'contained' : 'outlined'}
              onClick={() => field.onChange('seller_owes_buyer')}
            >
              {t('taxesAndProrations:seller')}
            </Button>

            <Button
              variant={field.value === 'buyer_owes_seller' ? 'contained' : 'outlined'}
              onClick={() => field.onChange('buyer_owes_seller')}
            >
              {t('taxesAndProrations:buyer')}
            </Button>
          </ButtonGroup>
      )}
      /> */}
      </Box>
    </Box>
  );
};