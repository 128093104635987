import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'utils/helpers';

import VoidOrdersIcon from '../../assets/images/no-orders-icn@3x.png';

const useStyles = makeStyles((theme: any) => ({
  container: {
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(10),
  },
  img: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  title: { color: theme.palette.tab.offselected },
  subtitle: {
    color: theme.palette.tab.offselected,
    fontFamily: 'Roboto',
  },
}));

const VoidOrders = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <img alt="void orders" src={VoidOrdersIcon} className={classes.img} />

      <Typography variant="h4" className={classes.title}>
        {capitalize(t('orders:no-orders-yet'))}
      </Typography>

      <Typography variant="body2" className={classes.subtitle}>
        {capitalize(t('orders:please-create-order'))}
      </Typography>
    </Box>
  );
};

export default VoidOrders;
