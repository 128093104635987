import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import SelectField from 'components/select-field';
import React, { FC, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { NewAggregatePayment } from 'types/common';
import { NewUserType } from 'types/new-user';
import * as Yup from 'yup';

import { PaymentProps, PaymentsTypes } from '../../types';
import ContainerPayment from './container-payment';

const useStyles = makeStyles(() => ({
  createButton: {
    height: 30,
    width: '100%',
    textTransform: 'capitalize',
  },
}));

type ModalCreateLicenseProps = {
  open: boolean;
  add: (data: PaymentsTypes, onClose?: () => void) => void;
  onClose: () => void;
  t: TFunction
};

const ModalCheck: FC<ModalCreateLicenseProps> = ({ onClose, open, add, t }) => {
  const aggregates = useMemo(
    () => [
      {
        title: 'Anderson Roberts PLLC',
        party_id: 'd7c011f9-121e-44b2-bfed-2d3c2dfbb0e7',
      },
      {
        title: 'First National Title Insurance Company',
        party_id: '7524e390-d5d1-42dd-bf4a-fbe2dd52bb1e',
      },
    ],
    []
  );

  const schema = Yup.object({ party_id: Yup.string().required(t('validations:required')), });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<NewAggregatePayment & { contact?: NewUserType | null }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { party_id: '' },
  });

  const classes = useStyles();

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleOnClose}>
      <DialogTitle>
        <Typography>{t('charges:aggregate')}</Typography>
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={handleSubmit((data) => {
            delete data.contact;
            return add({ aggregate: data }, handleOnClose);
          })}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="party_id"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <SelectField
                    label={t('users:partyId')}
                    {...field}
                    inputRef={ref}
                    handleChange={onChange}
                    data={aggregates}
                    dataKey="party_id"
                    dataValue="party_id"
                    dataText="title"
                    error={!!errors.party_id}
                    helperText={
                      errors.party_id?.message || t('validations:required')
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item>
                  <Button
                    type="submit"
                    disableElevation
                    disabled={!isValid}
                    variant="contained"
                    color="secondary"
                    className={classes.createButton}
                  >
                    {t('users:create-payment')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const Aggregate: FC<PaymentProps> = ({ helpers, t }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ContainerPayment
        t={t}
        kind="aggregate"
        handleOpen={handleOpen}
        helpers={helpers}
      />

      <ModalCheck
        t={t}
        add={helpers.add}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default Aggregate;
