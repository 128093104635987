import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  divider: { backgroundColor: theme.palette.text.hint },
  item: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
  avatarStyle: { marginRight: theme.spacing(1) },
  itemtitleStyle: { color: theme.palette.common.black, fontWeight: 500 },
  datetitleStyle: { textTransform: 'none' },
  completedStatus: { backgroundColor: theme.palette.secondary.main, },
  pendingStatus: { backgroundColor: theme.palette.button.hover },
  arricon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  sectionPadding: { marginLeft: theme.spacing(3) },
  avatarLetters: {
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  disabledIcon: { opacity: 0.5 },
  enabledIcon: { opacity: 1 }
}));

type SectionItemProps ={
  title: string,
  status?: boolean,
  disabled?: boolean,
  avatar: string,
  onClick: () => void
};

export const SectionItem: FC<SectionItemProps> = ({
  title,
  status = false,
  avatar,
  disabled = false,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        flexDirection="row"
        flex="1"
        justifyContent="space-between"
        display="flex"
        className={clsx(classes.item, {
          [classes.disabledIcon]: disabled,
          [classes.enabledIcon]: !disabled,
        })}
      >
        <Box display="flex" flexDirection="row">
          <Avatar
            className={clsx(classes.avatarStyle, {
              [classes.completedStatus]: status,
              [classes.pendingStatus]: !status
            })}
          >
            <Typography className={classes.avatarLetters}>{avatar}</Typography>
          </Avatar>

          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="body2" className={classes.itemtitleStyle}>{title}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <IconButton
            onClick={onClick}
            disabled={disabled}
          >
            <FontAwesomeIcon icon="chevron-right" className={classes.arricon} />
          </IconButton>
        </Box>
      </Box>

      <Divider className={classes.divider} />
    </>
  );
};
