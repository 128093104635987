import {
  Grid,
  Tabs,
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';
import {
  PAYMENT_AGGREGATE,
  PAYMENT_CHECK,
  PAYMENT_HOLD_BACK,
  PAYMENT_NET_FUNDED,
  PAYMENT_TRANSFER,
  PAYMENT_WIRE,
} from 'utils/constants';

import CustomTab from '../../common/custom-tab';
import { PaymentsHelperType } from '../types';
import Aggregate from './components/aggregate';
import Check from './components/check';
import Holdback from './components/holdback';
import NetFunded from './components/net-funded';
import Transfer from './components/transfer';
import Wire from './components/wire';

type FormPaymentsProps = {
  t : TFunction;
  helpers: PaymentsHelperType;
};

const FormPayments: FC<FormPaymentsProps> = ({ t, helpers }) => {
  const [currentTab, setcurrentTab] = useState(PAYMENT_CHECK);

  const handleChange = (event: any, index: string) => {
    setcurrentTab(index);
  };

  return (
    <>
      <Grid container>
        <TabContext value={currentTab}>
          <Grid item xs={1}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={currentTab}
              onChange={handleChange}
            >
              <CustomTab
                currentValue={currentTab}
                value={PAYMENT_CHECK}
                text={t('charges:check')}
              />
              <CustomTab
                currentValue={currentTab}
                value={PAYMENT_WIRE}
                text={t('charges:wire')}
              />
              <CustomTab
                currentValue={currentTab}
                value={PAYMENT_NET_FUNDED}
                text={t('charges:net-funded')}
              />
              <CustomTab
                currentValue={currentTab}
                value={PAYMENT_AGGREGATE}
                text={t('charges:aggregate')}
              />
              <CustomTab
                currentValue={currentTab}
                value={PAYMENT_TRANSFER}
                text={t('charges:transfer')}
              />
              <CustomTab
                currentValue={currentTab}
                value={PAYMENT_HOLD_BACK}
                text={t('charges:holdback')}
              />
            </Tabs>
          </Grid>
          <Grid item xs={11}>
            <TabPanel value={PAYMENT_CHECK} style={{ height: '100%' }}>
              <Check t={t} helpers={helpers} />
            </TabPanel>
            <TabPanel value={PAYMENT_WIRE} style={{ height: '100%' }}>
              <Wire t={t} helpers={helpers} />
            </TabPanel>
            <TabPanel value={PAYMENT_NET_FUNDED} style={{ height: '100%' }}>
              <NetFunded t={t} helpers={helpers} />
            </TabPanel>
            <TabPanel value={PAYMENT_AGGREGATE} style={{ height: '100%' }}>
              <Aggregate t={t} helpers={helpers} />
            </TabPanel>
            <TabPanel value={PAYMENT_TRANSFER} style={{ height: '100%' }}>
              <Transfer t={t} helpers={helpers} />
            </TabPanel>
            <TabPanel value={PAYMENT_HOLD_BACK} style={{ height: '100%' }}>
              <Holdback t={t} helpers={helpers} />
            </TabPanel>
          </Grid>
        </TabContext>
      </Grid>
    </>
  );
};

export default FormPayments;
