import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CURRENT_PATH } from 'utils/constants';

const useHandleRedirection = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const history = useHistory();
  const handleRedirection = useCallback((route: string, param?: string) => {
    localStorage.setItem(CURRENT_PATH, JSON.stringify({ view: route, param }));
    history.push(`/orders/details/${orderId}/${route}/${param || ''}`);
  }, [history, orderId]);

  return { handleRedirection };
};

export default useHandleRedirection;
