import { GetLoansReturn } from 'graphql/loans/queries';
import { useDeleteLoan } from 'hooks/useDeleteLoan';
import { useDeleteLoanInterest } from 'hooks/useDeleteLoanInterest';
import { useGetLoans } from 'hooks/useGetLoans';
import { useUpdateEntry } from 'hooks/useUpdateEntry';
import { useUpdateLoan } from 'hooks/useUpdateLoan';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { filterAccountingEntriesByLoanId } from 'utils/accounting';
import useAlert from 'utils/alert';
import { LEDGER_CODE_LOAN_AMOUNT } from 'utils/constants';
import { AccountingType, Loan } from 'v2-types/order';

import { LoanFormType, ModalData } from './types';
import { getLoanFormDataFromLoan } from './utils';
import { loanInitialValues } from './utils/loanInitialValues';

export const useLoansHooks = () => {
  const { id: orderId, loanId } = useParams<{ id: string, loanId?: string }>();
  const history = useHistory();
  const showAlert = useAlert();
  const [selectedLoan, setSelectedLoan] = useState<Loan | undefined>(undefined);
  const [modalData, setModalData] = useState<ModalData>();

  const loanForm = useForm<LoanFormType>({ defaultValues: loanInitialValues, mode: 'onChange' });
  const queryCache = useQueryCache();

  const onSelectLoan = (loan: Loan | undefined) => {
    if (loan) {
      const loanAmountEntry = loan.accounting.find((entry) => entry.code.startsWith(LEDGER_CODE_LOAN_AMOUNT));
      history.push(loan._id);

      loanForm.reset({
        ...loan,
        loanAmountEntry
      });
      setSelectedLoan(loan);
    }
  };

  const {
    data,
    isLoading: isLoansLoading
  } = useGetLoans({
    orderId,
    queryConfig: {
      onSuccess: (response) => {
        if (!loanForm.formState.isDirty) {
          let loan: Loan | undefined = response.getOrder.loans[0];
          if (loanId) {
            loan = response.getOrder.loans.find((loanItem) => loanItem._id === loanId);
          }
          onSelectLoan(loan);
        }
      },
      staleTime: 50 * (60 * 1000)
    }
  });

  const updateLoansCache = (loans: Loan[], loanIdToSelect?: string) => {
    queryCache.setQueryData<GetLoansReturn>(
      [{ orderId }, 'loans'],
      () => {
        if (loanIdToSelect !== undefined) {
          const loanToSelect = loans.find((loan) => loan._id === loanIdToSelect);
          onSelectLoan(loanToSelect);
        }

        const newData: GetLoansReturn = { getOrder: { loans } };
        return newData;
      },
      { cacheTime: 50 * (60 * 1000) }
    );
  };

  const [deleteLoaninterest, { isLoading: isDeleteInterestLoading }] = useDeleteLoanInterest({
    queryConfig: {
      onSuccess: (response) => {
        updateLoansCache(response.deleteOrderLoanInterest.loans, loanId);
        setModalData({ modal: '' });
        showAlert('Interest successfully deleted', 'success');
      },
      onError: () => showAlert('Unexpected error ocurred while trying to delete the interest', 'error')
    }
  });

  const [deleteLoan, { isLoading: isDeleteLoanLoading }] = useDeleteLoan({
    queryConfig: {
      onSuccess: (response) => {
        const loan: Loan | undefined = response.deleteOrderLoan.loans[0];
        updateLoansCache(response.deleteOrderLoan.loans, loan ? loan._id : undefined);
        setModalData({ modal: '' });
        showAlert('Loan successfully deleted', 'success');
      },
      onError: () => {
        showAlert('Unexpected error ocurred while trying to delete the loan', 'error');
      }
    }
  });

  const [updateLoan, { isLoading: isUpdateLoanLoading }] = useUpdateLoan({
    queryConfig: {
      onSuccess: (response) => {
        updateLoansCache(response.updateOrderLoan.loans, loanId);
        showAlert('Loan successfully updated', 'success');
      },
      onError: () => {
        showAlert('Unexpected error ocurred while trying to update the loan', 'error');
      }
    }
  });

  const [updateEntry, { isLoading: isEntryUpdateLoading }] = useUpdateEntry({});

  const updateAccountingCache = (accounting: AccountingType[]) => {
    queryCache.setQueryData<GetLoansReturn>(
      [{ orderId }, 'loans'],
      (dataCache) => {
        const loanIndex = dataCache?.getOrder.loans.findIndex((loan) => loan._id === selectedLoan?._id);
        const newData: GetLoansReturn = { getOrder: { loans: [] } };

        if (loanIndex !== undefined && loanIndex >= 0) {
          const loans = [...dataCache?.getOrder.loans || []];
          loans[loanIndex].accounting = filterAccountingEntriesByLoanId(accounting, selectedLoan?._id!);
          newData.getOrder.loans = loans;
        }
        return newData;
      },
      { cacheTime: 50 * (60 * 1000) }
    );
  };

  const loans = data?.getOrder.loans || [];

  const onDeleteLoanInterest = () => {
    if (modalData?.modal === 'deleteInterest' && selectedLoan) {
      deleteLoaninterest({
        orderId,
        loanId: selectedLoan._id,
        interestId: modalData?.interestId
      });
    }
  };

  const { formState: { dirtyFields, isDirty } } = loanForm;
  const onSave = loanForm.handleSubmit(async ({
    loanAmountEntry,
    ...rest
  }) => {
    if (isDirty && selectedLoan) {
      if (dirtyFields.loanAmountEntry?.amount) {
        await updateEntry({
          id: orderId,
          accountingId: loanAmountEntry?._id!,
          data: { amount_override: loanAmountEntry?.amount!, }
        });
      }
      const loanData = getLoanFormDataFromLoan(rest as Loan);
      await updateLoan({
        orderId,
        loanId: selectedLoan._id,
        data: {
          ...loanData
        }
      });
    }
  });

  const onDeleteLoan = () => {
    if (loanId) {
      deleteLoan({
        orderId,
        loanId
      });
    }
  };

  return {
    loans,
    loanId,
    onSelectLoan,
    selectedLoan,
    loanForm,
    setModalData,
    modalData,
    updateAccountingCache,
    onDeleteLoanInterest,
    isDeleteInterestLoading,
    updateLoansCache,
    onDeleteLoan,
    isDeleteLoanLoading,
    onSave,
    isUpdateLoanLoading: isUpdateLoanLoading || isEntryUpdateLoading,
    isLoansLoading
  };
};
