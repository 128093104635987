import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme:any) => ({
  placeholdertitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    textAlign: 'center'
  },
  container: { height: '50vh' },
}));

const VoidPayoffs = () => {
  const classes = useStyles();
  return (
    <Grid container alignContent="center" justifyContent="center" className={classes.container} direction="column">
      <Grid item>
        <Typography variant="h6" className={classes.placeholdertitle}>
          There is no payoff currently selected
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.placeholdertitle}>
          please select or create one
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VoidPayoffs;
