import { QueryConfig, useQuery } from 'react-query';
import querys from 'services/querys';
import { GQLGetOrder } from 'services/querys/orders';

const useGetOrder = (
  orderId: string,
  identifier = 'getOrder',
  queryConfig?: QueryConfig<GQLGetOrder, unknown>,
) => {
  const getOrder = useQuery(
    [identifier, orderId],
    querys.getOrder,
    queryConfig,
  );

  return getOrder;
};

export default useGetOrder;
