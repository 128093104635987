import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SaveButton from 'components/common/save-button';
import DatePicker from 'components/date-picker/date-picker';
import NumberField from 'components/number-field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React, { useState } from 'react';
import { Control, Controller, UseFormReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { DisabledFields } from 'types/disabledFields';
import { listingItemtype } from 'types/ledger';
import { AccountingType } from 'v2-types/order';

import { calculateAdditionalInterest } from '../../services';
import AdditionalInterest from './components/calculation-helper';
import { EntryLedgerType } from './services/types';

const useStyles = makeStyles((theme:any) => ({
  container: { padding: theme.spacing(3) },
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  icon: {
    fontSize: '16px',
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  itempaddinType: {
    '& > * ': { marginTop: theme.spacing(2) },
    padding: theme.spacing(3),
    margin: 0,
  },
  itempaddinfoother: { '& > * ': { marginLeft: theme.spacing(2) } },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nocontendPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  removecontentPadding: { '& .MuiDialogContent-root': { padding: 0 } }
}));

type AddReceiptProps = {
  open:boolean,
  onClose: ()=>void,
  handleSubmit:(e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>,
  control: Control<EntryLedgerType, object>,
  allowedCodes:listingItemtype[],
  allowedKinds:listingItemtype[],
  errors:string[],
  prevData: AccountingType | null,
  t:TFunction;
  loadingEditEntry: boolean,
  loadingAddNewEntry: boolean,
  handleResetValue:()=> void,
  methods:UseFormReturn<EntryLedgerType, object>
  additionalamount?:number,
  hideFields?: DisabledFields<EntryLedgerType>
}

const AddEntryScene = ({
  open,
  onClose,
  handleSubmit,
  control,
  allowedCodes,
  allowedKinds,
  errors,
  prevData,
  t,
  loadingEditEntry,
  loadingAddNewEntry,
  handleResetValue,
  methods,
  additionalamount,
  hideFields
}:AddReceiptProps) => {
  const classes = useStyles();
  const prevDataisManual = prevData?.kind?.includes('disbursement') || prevData?.kind?.includes('manual') || prevData?.kind?.includes('receipt');
  const [showCalculator, setshowCalculator] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      className={classes.removecontentPadding}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.nocontendPadding}>
          <Grid
            container
            className={classes.itempaddinType}
            direction="column"
          >
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: 'black' }}>
                {`${prevData ? 'Edit Ledger Entry' : 'Add New Ledger Entry'}`}
              </Typography>
            </Grid>
            {!!errors.length && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                classes={{ icon: classes.errorIconContainer }}
              >
                {errors.map((error) => (
                  <Typography variant="subtitle1">{error}</Typography>
                ))}
              </Alert>
            </Grid>
            )}
            {prevData
              ? (
                <>
                  <Box flex={1} display="flex" flexDirection="row">
                    <Controller
                      name="amount_override"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <NumberField
                          {...field}
                          customRef={ref}
                          label={t('accounting:amount-override')}
                          disabled={showCalculator}
                        />
                      )}
                    />
                    {prevData?.code.includes('additional') && (
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => { setshowCalculator(true); }}
                    >
                      <FontAwesomeIcon icon="calculator" size="sm" />
                    </IconButton>
				  )}
                  </Box>
                  {showCalculator && (
                  <AdditionalInterest
                    additionalAmount={additionalamount || 0}
                    handleCancel={() => {
                      setshowCalculator(false);
                    }}
                    handleSave={(data) => {
					  const {
                        interest_rate,
						 days_per_year,
						 from_date, to_date,
						 per_diem
                      } = data;
					  const { additionalInterest } = calculateAdditionalInterest(additionalamount || 0, interest_rate, days_per_year);
					  const additional_interes = additionalInterest(from_date as any as Date, to_date as any as Date, interest_rate === 0 ? per_diem : undefined);
					  methods.setValue('amount_override', additional_interes);
					  setshowCalculator(false);
                    }}
                  />
				  )}
                </>
              )

              : (
                <Controller
                  name="amount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('accounting:amount')}
                    />
                  )}
                />
              )}
            {prevData
              ? (
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('accounting:description')}
                      disabled={!!prevData}
                      error={!!methods.formState.errors.description}
                      value={prevData?.code.includes('mortgage') ? capitalize(prevData.code.split('_')[1] || '') : field.value}
                    />
                  )}
                />
              )
              : (
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('accounting:description')}
                      disabled={!!prevData}
                      error={!!methods.formState.errors.description}
                    />
                  )}
                />
              )}

            {!prevData
                  && (
                  <>
                    {!hideFields?.code && (
                      <Controller
                        name="code"
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SelectField
                            {...field}
                            inputRef={ref}
                            value={allowedCodes.length > 0 ? field.value : ''}
                            data={allowedCodes}
                            dataKey="code"
                            dataValue="code"
                            dataText="description"
                            label={t('accounting:code')}
                            handleChange={onChange}
                            error={!!methods.formState.errors.description}
                          />
                        )}
                      />
                    )}
                    {!hideFields?.kind && (
                      <Controller
                        name="kind"
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SelectField
                            {...field}
                            inputRef={ref}
                            value={allowedKinds.length > 0 ? field.value : ''}
                            data={allowedKinds}
                            dataKey="code"
                            dataValue="code"
                            dataText="description"
                            label={t('accounting:kind')}
                            handleChange={onChange}
                            error={!!methods.formState.errors.description}
                          />
                        )}
                      />
                    )}
                  </>
                  )}
            <Controller
              name="entryDate"
              control={control}
              render={({ field: { ref, value, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  disableToolbar={false}
                  label={t('accounting:entry-date')}
                  value={value as string | Date}
                />
              )}
            />
          </Grid>

        </DialogContent>
        <DialogActions disableSpacing style={{ padding: 16 }} className={classes.itempaddinfoother}>
          <Button
            color="primary"
            variant="outlined"
            disableElevation
            onClick={onClose}
          >
            <Typography variant="body2" className={classes.text}>
              {t('common:close')}
            </Typography>
          </Button>
          {prevData && !prevDataisManual && (
          <Button
            variant="contained"
            onClick={handleResetValue}
            disableElevation
          >
            <Typography variant="body2" className={classes.text}>
              {t('accounting:reset-value')}
            </Typography>
          </Button>
          )}
          <SaveButton
            type="submit"
            customText={`${prevData ? t('accounting:edit-entry') : t('accounting:add-entry')}`}
            loading={loadingAddNewEntry || loadingEditEntry}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEntryScene;
