import {
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';
import { PaperCard } from 'components/cards/paper-card';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import useHandleRedirection from 'views/orders/overview/services/useHandleRedirection';

import { Milestone } from '../services';
import { SectionItem } from './section-item';

const useStyles = makeStyles((theme: any) => ({
  continer: {
    height: theme.spacing(50),
    overflowY: 'scroll',
    scrollbarColor: `${theme.palette.tab.offselected} ${theme.palette.button.default}`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      background: theme.palette.button.default,
      width: theme.spacing(0.625),
      borderRadius: theme.spacing(0.625)
    },
    '&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.625) },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.tab.offselected,
      borderRadius: theme.spacing(0.625),
    },
  },
}));

type SectionProps = {
  t: TFunction,
  selectedMilestone: Milestone
};

export const Sections: FC<SectionProps> = ({
  t,
  selectedMilestone,
}: SectionProps) => {
  const classes = useStyles();
  const { handleRedirection } = useHandleRedirection();
  return (
    <PaperCard
      flex={1}
      title={selectedMilestone.title}
    >
      <Box className={classes.continer}>
        <Typography variant="caption">
          {selectedMilestone.title}
        </Typography>

        {selectedMilestone.sections.map((section) => (
          <SectionItem
            key={section.title}
            title={section.title}
            avatar={section.avatar}
            onClick={() => handleRedirection(section.route, section.param || '')}
          />
        ))}
      </Box>
    </PaperCard>
  );
};
