import { listingItemtype } from 'types/ledger';

export const isArrayOfStrings = (value: unknown): value is string[] => Array.isArray(value) && value.every((item) => typeof item === 'string');

export const validateAllowedKindTypes = (localEntrys: listingItemtype[], kindsList?: string | string[]): listingItemtype[] => {
  if (kindsList) {
	  if (isArrayOfStrings(kindsList)) {
      return localEntrys.filter((association) => kindsList.some((k) => association.code.toLowerCase().includes(k)));
	   }
	  return localEntrys.filter((association) => association.code.toLowerCase() === kindsList);
  }
  return localEntrys;
};
