import {
  Backdrop,
  Box,
  Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography
} from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import TableComponent from 'components/Table';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingFilter, AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import { useRemoveAssociationModal } from './hooks';
import { useStyles } from './styles';

type Props = {
  parties: AccountingInvolvedParty[],
  kindList?: string[],
  onClose: () => void,
  title: string,
  removeSuccessMessage: string,
  accountingId: string,
  accountingFilter: AccountingFilter,
  onDeleteAssociationSuccess: (data: AccountingType[]) => void
}

export const RemoveAssociationModal: FC<Props> = ({
  parties,
  kindList,
  onClose,
  title,
  accountingFilter,
  accountingId,
  removeSuccessMessage,
  onDeleteAssociationSuccess
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    filteredParties,
    removeAssociation,
    isRemoveAssociationLoading
  } = useRemoveAssociationModal({
    parties,
    kindList,
    accountingId,
    accountingFilter,
    removeSuccessMessage,
    onDeleteAssociationSuccess
  });

  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
    >
      <DialogContent>
        <Box className={classes.contentContainer}>
          <Typography variant="h6" style={{ color: 'black' }}>
            { title }
          </Typography>
          <TableComponent
            disabledBorderLeft
            cellAlignment="left"
            columns={{
              actions: {
                header: { title: t('accounting:actions'), width: '100px' },
                renderer: ({ row }) => (
                  <Actionbuttons
                    handleDelete={() => { removeAssociation(row); }}
                  />
                )
              },
              name: {
                header: { title: t('accounting:name'), align: 'left' },
                renderer: ({ row }) => <>{row.name}</>,
              },
            }}
            dataSet={filteredParties}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          disableElevation
          onClick={onClose}
        >
          <Typography variant="body2" className={classes.text}>
            {t('common:close')}
          </Typography>
        </Button>
      </DialogActions>
      <Backdrop className={classes.backdrop} open={isRemoveAssociationLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </Dialog>
  );
};
