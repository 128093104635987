import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import useGetChargesBC from '../services/useGetChargesBC';

const useChargesC = () => {
  const { t } = useTranslation();
  const [currentRowSelected, setCurrentRowSelected] = useState<columnsDataSet | null>(null);
  const {
    disclosure, handleSetDisclosure, handleSetAccounting, accounting, isLoading
  } = useGetChargesBC('C');

  return {
    t,
    loadingAccounting: isLoading,
    accounting,
    disclosure,
    currentRowSelected,
    setCurrentRowSelected,
    handleSetAccounting,
    handleSetDisclosure
  };
};
export default useChargesC;
