import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  table: {
    background: theme.palette.background.paper,
    '& .MuiTableCell-sizeSmall ': {
      padding: '6px 6px 6px 6px',
    }
  },
  titleColumn: {
    fontSize: '0.875rem',
    color: theme.palette.primary.darker,
    fontWeight: 500,
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
  },
  cursor: { cursor: 'pointer' },
  cellHeader: {
    display: 'flex',
    justifyContent: 'center'
  },
  iconSort: { color: theme.palette.secondary.main },
  pagination: { width: '100%' },
  paddingCell: {
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2)
  },
  borderTopCell: {
    borderTopColor: theme.palette.input.border,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    opacity: 1
  },
  borderLeftCell: {
    borderLeftColor: theme.palette.input.border,
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: 'solid',
    opacity: 1
  },
  borderBottomCell: {
    borderBottomColor: theme.palette.input.border,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    opacity: 1
  },
  borderCellStyle: {
    borderColor: theme.palette.input.border,
    borderWidth: 1,
    borderStyle: 'solid',
    opacity: 1
  }
}));

export default useStyles;
