import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  paperContainer: {
    overflow: 'hidden',
    maxHeight: '100%',
    height: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    height: '100%'
  },
  header: {
    marginBottom: '5px',
    borderBottom: '1px solid #D1B74F',
    padding: '10px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  noteList: {
    padding: '10px',
    overflowY: 'scroll',
    maxHeight: '100%',
    height: '100%',
    flexGrow: 3
  },
  noteContainer: { borderBottom: '1px solid lightgray' },
  savingChanges: {
    flexGrow: 1,
    padding: '10px',
    paddingRight: '15px'
  }
}));
