import React from 'react';

import OnboardingWelcomeScene from './onboarding-welcome-scene';
import useOnboardingWelcome from './use-onboarding-welcome';

const OnboardingWelcome = () => {
  const {
    t,
    theme,
    userId,
    currentUser,
    updateParty,
  } = useOnboardingWelcome();

  const handle = () => {
    if (currentUser) {
      updateParty({ id: userId, data: currentUser });
    }
  };

  return (
    <OnboardingWelcomeScene
      t={t}
      theme={theme}
      handle={handle}
    />
  );
};

export default OnboardingWelcome;
