import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import {
  NewAddressType,
  NewBasicInfoType,
  NewLicenseType,
  NewNotificationsType,
  NewPartyType,
  NewPhoneType,
  NewUserType,
} from 'types/new-user';
import { NewBuyerSellerType, NewOrderPartyType, NewPOAType } from 'types/order';

import {
  NewAggregatePayment,
  NewCheckPayment,
  NewHoldbackPayment,
  NewNetFundedPayment,
  NewTransferPayment,
  NewWirePayment,
} from '../../types/common';
import { NewPaymentsType } from '../../types/new-user';
import { NewExchange1031Type } from '../../types/order';
import { PartyType } from '../../views/orders/new-parties/services';

export type GQLSearchParty = {
  searchParty: NewUserType[];
};

const searchParty = async (_: string, queryParam: string) => {
  const query = gql`
    query SearchParty($query: String!) {
      searchParty(query: $query) {
        _id
        name
        kind
        user_id
        addresses {
          _id
          address
        }
        emails {
          _id
          address
        }
        phones {
          _id
          number
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLSearchParty>(query, { query: queryParam, });

  return {
    param: queryParam,
    ...response
  };
};

export type GQLCreateUser = {
  createUser: {
    _id: string;
    user_id: string;
  };
};

/**
 * @deprecated The method should not be used
 * This query is replaced and relocated at graphql/contacts/mutations
 */
const createUser = async (payload: { id: string }) => {
  const mutation = gql`
    mutation CreateUser($id: ID!) {
      createUser(_id: $id) {
        _id
        user_id
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreateUser>(
    mutation,
    payload
  );

  return response;
};

export type GQLUpdateParty = {
  updateParty: {
    _id: string;
    name: string;
  };
};

const updateParty = async (payload: { id: string; data: NewBasicInfoType }) => {
  const mutation = gql`
    mutation UpdateParty($id: ID!, $data: PartyContent!) {
      updateParty(_id: $id, data: $data) {
        _id
        name
      }
    }
  `;

  const response = await graphQLClient().request<GQLUpdateParty>(
    mutation,
    payload
  );

  return response;
};

export type GQLCreateParty = {
  createParty: {
    _id: string;
	name:string
  };
};

const createParty = async (payload: { data: NewBasicInfoType }) => {
  const mutation = gql`
    mutation CreateParty($data: PartyContent!) {
      createParty(data: $data) {
        _id
		name
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreateParty>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeleteParty = {
  deleteParty: {
    _id: string;
    user_id: string;
  };
};

const deleteParty = async (payload: { id: string }) => {
  const mutation = gql`
    mutation DeleteParty($id: ID!) {
      deleteParty(_id: $id) {
        _id
        user_id
      }
    }
  `;

  const response = await graphQLClient().request<GQLDeleteParty>(
    mutation,
    payload
  );

  return response;
};

export type GQLUpdateAddress = {
  updateAddress: {
    _id: string;
  };
};

const updateAddress = async (payload: {
  id: string;
  partyId: string;
  kind: string;
  data: NewAddressType;
}) => {
  const mutation = gql`
    mutation UpdateAddress(
      $id: ID!
      $partyId: ID!
      $kind: String!
      $data: AddressContent!
    ) {
      updateAddress(_id: $id, party_id: $partyId, kind: $kind, data: $data) {
        _id
      }
    }
  `;

  const response = await graphQLClient().request<GQLUpdateAddress>(
    mutation,
    payload
  );

  return response;
};

export type GQLCreateAddress = {
  createAddress: {
    _id: string;
    addresses: NewAddressType[];
  };
};

const createAddress = async (payload: {
  partyId: string;
  kind: string;
  data: NewAddressType;
}) => {
  const mutation = gql`
    mutation CreateAddress(
      $partyId: ID!
      $kind: String!
      $data: AddressContent!
    ) {
      createAddress(party_id: $partyId, kind: $kind, data: $data) {
        _id
        addresses {
          _id
          state
          address
          postal_code
          street_address
          locality
          country
          settlement
          references
          kind
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreateAddress>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeleteAddress = {
  deleteAddress: {
    _id: string;
    addresses: NewAddressType[];
  };
};

const deleteAddress = async (payload: { id: string; partyId: string }) => {
  const mutation = gql`
    mutation DeleteAddress($id: ID!, $partyId: ID!) {
      deleteAddress(_id: $id, party_id: $partyId) {
        _id
        addresses {
          _id
          state
          address
          postal_code
          street_address
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLDeleteAddress>(
    mutation,
    payload
  );

  return response;
};

export type GQLUpdatePhone = {
  updatePhone: {
    _id: string;
  };
};

const updatePhone = async (payload: {
  id: string;
  partyId: string;
  data: NewPhoneType;
  kind: string;
}) => {
  const mutation = gql`
    mutation UpdatePhone(
      $id: ID!
      $partyId: ID!
      $data: PhoneContent!
      $kind: String
    ) {
      updatePhone(_id: $id, party_id: $partyId, data: $data, kind: $kind) {
        _id
      }
    }
  `;

  const response = await graphQLClient().request<GQLUpdatePhone>(
    mutation,
    payload
  );

  return response;
};

export type GQLCreatePhone = {
  createPhone: {
    _id: string;
    phones: NewPhoneType[];
  };
};

const createPhone = async (payload: {
  partyId: string;
  kind: string;
  data: NewPhoneType;
}) => {
  const mutation = gql`
    mutation CreatePhone($partyId: ID!, $kind: String!, $data: PhoneContent!) {
      createPhone(party_id: $partyId, kind: $kind, data: $data) {
        _id
        phones {
          _id
          number
          kind
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreatePhone>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeletePhone = {
  deletePhone: {
    _id: string;
    phones: NewPhoneType[];
  };
};

const deletePhone = async (payload: { id: string; partyId: string }) => {
  const mutation = gql`
    mutation DeletePhone($id: ID!, $partyId: ID!) {
      deletePhone(_id: $id, party_id: $partyId) {
        _id
        phones {
          _id
          number
          kind
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLDeletePhone>(
    mutation,
    payload
  );

  return response;
};

export type GQLUpdateLicense = {
  updateLicense: {
    _id: string;
  };
};

const updateLicense = async (payload: {
  id: string;
  partyId: string;
  data: NewLicenseType;
}) => {
  const mutation = gql`
    mutation UpdateLicense($id: ID!, $partyId: ID!, $data: LicenseContent!) {
      updateLicense(_id: $id, party_id: $partyId, data: $data) {
        _id
      }
    }
  `;

  const response = await graphQLClient().request<GQLUpdateLicense>(
    mutation,
    payload
  );

  return response;
};

export type GQLCreateLicense = {
  createLicense: {
    _id: string;
    licenses: NewLicenseType[];
  };
};

const createLicense = async (payload: {
  partyId: string;
  data: NewLicenseType;
}) => {
  const mutation = gql`
    mutation CreateLicense($partyId: ID!, $data: LicenseContent!) {
      createLicense(party_id: $partyId, data: $data) {
        _id
        licenses {
          _id
          state
          number
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreateLicense>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeleteLicense = {
  deleteLicense: {
    _id: string;
    licenses: NewLicenseType[];
  };
};

const deleteLicense = async (payload: { id: string; partyId: string }) => {
  const mutation = gql`
    mutation DeleteLicense($id: ID!, $partyId: ID!) {
      deleteLicense(_id: $id, party_id: $partyId) {
        _id
        licenses {
          _id
          state
          number
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLDeleteLicense>(
    mutation,
    payload
  );

  return response;
};

export type GQLUpdatePartyAssociation = {
  updatePartyAssociation: {
    _id: string;
  };
};

const updatePartyAssociation = async (payload: {
  id: string;
  partyId: string;
  kind: string;
}) => {
  const mutation = gql`
    mutation UpdatePartyAssociation($id: ID!, $partyId: ID!, $kind: String!) {
      updatePartyAssociation(_id: $id, party_id: $partyId, kind: $kind) {
        _id
      }
    }
  `;

  const response = await graphQLClient().request<GQLUpdatePartyAssociation>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeletePartyAssociation = {
  deletePartyAssociation: {
    _id: string;
    parties: NewPartyType[];
  };
};

const deletePartyAssociation = async (payload: {
  id: string;
  partyId: string;
}) => {
  const mutation = gql`
    mutation DeletePartyAssociation($id: ID!, $partyId: ID!) {
      deletePartyAssociation(_id: $id, party_id: $partyId) {
        _id
        parties {
          _id
          kind
          name
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLDeletePartyAssociation>(
    mutation,
    payload
  );

  return response;
};

export type GQLUpdatePartyNotifications = {
  updatePartyNotifications: {
    _id: string;
  };
};

const updatePartyNotifications = async (payload: {
  id: string;
  data: NewNotificationsType;
}) => {
  const mutation = gql`
    mutation UpdatePartyNotifications($id: ID!, $data: NotificationsContent!) {
      updatePartyNotifications(_id: $id, data: $data) {
        _id
      }
    }
  `;

  const response = await graphQLClient().request<GQLUpdatePartyNotifications>(
    mutation,
    payload
  );

  return response;
};

export type GQLUpdateOrdeSpecifics = {
  UpdateOrderSpecifics: {
    _id: string;
    parties: PartyType;
  };
};

const udpdateOrderBuyer = async (payload: {
  id: string;
  partyId: string;
  dataBuyer?: NewBuyerSellerType;
}) => {
  const mutation = gql`
    mutation UpdateOrderSpecifics(
      $id: ID!
      $partyId: ID!
      $dataBuyer: OrderPartyBuyerContent
    ) {
      updateOrderSpecifics(
        _id: $id
        party_id: $partyId
        data_buyer: $dataBuyer
      ) {
        _id
      }
    }
  `;
  const response = await graphQLClient().request<GQLUpdateOrdeSpecifics>(
    mutation,
    payload
  );

  return response;
};

const udpdateOrderSeller = async (payload: {
  id: string;
  partyId: string;
  dataSeller?: NewBuyerSellerType;
}) => {
  const mutation = gql`
    mutation UpdateOrderSpecifics(
      $id: ID!
      $partyId: ID!
      $dataSeller: OrderPartySellerContent
    ) {
      updateOrderSpecifics(
        _id: $id
        party_id: $partyId
        data_seller: $dataSeller
      ) {
        _id
      }
    }
  `;
  const response = await graphQLClient().request<GQLUpdateOrdeSpecifics>(
    mutation,
    payload
  );

  return response;
};

const udpdateOrderPoa = async (payload: {
  id: string;
  partyId: string;
  dataPoa?: NewPOAType;
}) => {
  const mutation = gql`
    mutation UpdateOrderSpecifics(
      $id: ID!
      $partyId: ID!
      $dataPoa: OrderPartyPowerOfAttorneyContent
    ) {
      updateOrderSpecifics(
        _id: $id
        party_id: $partyId
        data_power_of_attorney: $dataPoa
      ) {
        _id
      }
    }
  `;
  const response = await graphQLClient().request<GQLUpdateOrdeSpecifics>(
    mutation,
    payload
  );

  return response;
};

const udpdateOrderExchange = async (payload: {
  id: string;
  partyId: string;
  dataExchange?: NewExchange1031Type;
}) => {
  const mutation = gql`
    mutation UpdateOrderSpecifics(
      $id: ID!
      $partyId: ID!
      $dataExchange: OrderPartyExchange1031Content
    ) {
      updateOrderSpecifics(
        _id: $id
        party_id: $partyId
        data_exchange_1031: $dataExchange
      ) {
        _id
      }
    }
  `;
  const response = await graphQLClient().request<GQLUpdateOrdeSpecifics>(
    mutation,
    payload
  );

  return response;
};

export type GQLCreatePartyPayment = {
  createPartyPayment: {
    _id: string;
    payments: NewPaymentsType[];
  };
};

const createPartyPayment = async (payload: {
  partyId: string;
  data:
    | NewCheckPayment
    | NewWirePayment
    | NewNetFundedPayment
    | NewTransferPayment
    | NewHoldbackPayment
    | NewAggregatePayment;
  kind: string;
}) => {
  let mutation;
  switch (payload.kind) {
    case 'check':
      mutation = gql`
        mutation CreatePartyPayment(
          $partyId: ID!
          $data: PartyPaymentCheckContent
        ) {
          createPartyPayment(_id: $partyId, data_check: $data) {
            _id
            payments {
              _id
              kind
              reference
            }
          }
        }
      `;
      break;

    case 'wire':
      mutation = gql`
        mutation CreatePartyPayment(
          $partyId: ID!
          $data: PartyPaymentWireContent
        ) {
          createPartyPayment(_id: $partyId, data_wire: $data) {
            _id
            payments {
              _id
              kind
              reference
            }
          }
        }
      `;
      break;

    case 'netFunded':
      mutation = gql`
        mutation CreatePartyPayment(
          $partyId: ID!
          $data: PartyPaymentNetFundedContent
        ) {
          createPartyPayment(_id: $partyId, data_net_funded: $data) {
            _id
            payments {
              _id
              kind
              reference
            }
          }
        }
      `;
      break;

    case 'transfer':
      mutation = gql`
        mutation CreatePartyPayment(
          $partyId: ID!
          $data: PartyPaymentTransferContent
        ) {
          createPartyPayment(_id: $partyId, data_transfer: $data) {
            _id
            payments {
              _id
              kind
              reference
            }
          }
        }
      `;
      break;

    case 'holdback':
      mutation = gql`
        mutation CreatePartyPayment(
          $partyId: ID!
          $data: PartyPaymentHoldbackContent
        ) {
          createPartyPayment(_id: $partyId, data_holdback: $data) {
            _id
            payments {
              _id
              kind
              reference
            }
          }
        }
      `;
      break;

    default:
      mutation = gql`
        mutation CreatePartyPayment(
          $partyId: ID!
          $data: PartyPaymentAggregateContent
        ) {
          createPartyPayment(_id: $partyId, data_aggregate: $data) {
            _id
            payments {
              _id
              reference
              kind
            }
          }
        }
      `;
      break;
  }

  const response = await graphQLClient().request<GQLCreatePartyPayment>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeletePartyPayment = {
  deletePartyPayment: {
    _id: string;
    payments: NewPaymentsType[];
  };
};

const deletePartyPayment = async (payload: {
  paymentId: string;
  id: string;
}) => {
  const mutation = gql`
    mutation DeletePartyPayment($id: ID!, $paymentId: ID!) {
      deletePartyPayment(_id: $id, payment_id: $paymentId) {
        _id
        payments {
          _id
          kind
          reference
        }
      }
    }
  `;
  const response = await graphQLClient().request<GQLDeletePartyPayment>(
    mutation,
    payload
  );

  return response;
};

export type GQLResetPartyPassword = {
  resetPassword: {
    address: string;
    url: string;
  };
};

const resetPartyPassword = async (payload: { email: string }) => {
  const mutation = gql`
    mutation ResetPassword($email: String!) {
      resetPassword(email: $email) {
        address
        url
      }
    }
  `;
  const response = await graphQLClient().request<GQLResetPartyPassword>(
    mutation,
    payload
  );
  return response;
};

/*
parties {
          _id
          name
          associations {
            linked_as
            party_id
            party_kind
            party_name
          }
          kinds
          member_of {
            _id
            kind
            name
          }
          order_data {
            ... on OrderPartyBuyer {
              acknowledgement
              declines_ssn_ein
              exception_1099
              gross_proceeds
              include_marital
              include_vesting
              is_foreign
              jurat
              kind
              kind_owner
              listed_on_title
              name_control
              ownership
              part_of_tax
              vesting
              vesting_block {
                kind
                text
                tabs
              }
              vesting_type
            }
            ... on OrderPartyExchange1031 {
              exchange_1031
              kind
            }
            ... on OrderPartySeller {
              acknowledgement
              declines_ssn_ein
              exception_1099
              gross_proceeds
              include_marital
              include_vesting
              is_foreign
              jurat
              kind
              kind_owner
              listed_on_title
              name_control
              ownership
              part_of_tax
              vesting
              vesting_block {
                kind
                text
                tabs
              }
              vesting_type
            }
            ... on OrderPartyPowerOfAttorney {
              end_date
              kind
              start_date
            }
          }
        }
*/

type ReturnOnlyParties = {
	getOrder:{
		_id: string;
  		order_id: string;
  		status: 'active' | 'closed' | 'draft' | 'in_progress' | 'on_hold' | 'open';
  		parties: NewOrderPartyType[];
	}
}

const getOnlyOrderParties = async (_: string, id: string) => {
  const query = gql`
	  query GetOrder($id: ID!) {
		getOrder(_id: $id) {
		  _id
		  status
		  order_id
		  parties {
			_id
			name
			associations {
			  linked_as
			  party_id
			  party_kind
			  party_name
			}
			kinds
			member_of {
			  _id
			  kind
			  name
			}
			order_data {
			  ... on OrderPartyBuyer {
				acknowledgement
				declines_ssn_ein
				exception_1099
				gross_proceeds
				include_marital
				include_vesting
				is_foreign
				jurat
				kind
				kind_owner
				listed_on_title
				name_control
				ownership
				part_of_tax
				vesting
				vesting_block {
				  kind
				  text
				  tabs
				}
				vesting_type
			  }
			  ... on OrderPartyExchange1031 {
				exchange_1031
				kind
			  }
			  ... on OrderPartySeller {
				acknowledgement
				declines_ssn_ein
				exception_1099
				gross_proceeds
				include_marital
				include_vesting
				is_foreign
				jurat
				kind
				kind_owner
				listed_on_title
				name_control
				ownership
				part_of_tax
				vesting
				vesting_block {
				  kind
				  text
				  tabs
				}
				vesting_type
			  }
			  ... on OrderPartyPowerOfAttorney {
				end_date
				kind
				start_date
			  }
			}
		  }
		}
	  }
	`;

  const response = await graphQLClient().request<ReturnOnlyParties>(query, { id });

  return response;
};

export default {
  searchParty,
  getOnlyOrderParties,
  createUser,
  updateParty,
  createParty,
  deleteParty,
  updateAddress,
  createAddress,
  deleteAddress,
  updatePhone,
  createPhone,
  deletePhone,
  updateLicense,
  createLicense,
  deleteLicense,
  updatePartyAssociation,
  deletePartyAssociation,
  updatePartyNotifications,
  udpdateOrderBuyer,
  udpdateOrderSeller,
  udpdateOrderPoa,
  udpdateOrderExchange,
  createPartyPayment,
  deletePartyPayment,
  resetPartyPassword,
};
