import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { FormPolicy } from '../services/types';

const useStyles = makeStyles((theme: any) => ({
  maincontainer: {
    height: 120,
    overflow: 'auto',
  },
  title: { color: theme.palette.tab.offselected, marginTop: theme.spacing(2) },
  text: { color: theme.palette.primary.main },
}));

type LendersProps = {
  isUserAdmin: boolean;
};

const Lenders: FC<LendersProps> = ({ isUserAdmin }) => {
  const classes = useStyles();
  const { getValues } = useFormContext<FormPolicy>();
  const parties = getValues('parties');
  const lenders = useMemo(() => parties.filter((party) => party.kinds.includes('Lender')), [parties]);
  if (lenders.length === 0) {
    return null;
  }
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.title}>
          Lenders
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container direction="column" className={clsx({ [classes.maincontainer]: lenders.length >= 5 })}>
          {lenders.map((lender) => (
            <Grid key={lender._id} item>
              {isUserAdmin ? (
                <Link to={`/contacts/${lender._id}`}>
                  <Typography className={classes.text}>
                    {lender.name}
                  </Typography>
                </Link>
              ) : (
                <Typography className={classes.text}>
                  {lender.name}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>

    </Grid>
  );
};

export default Lenders;
