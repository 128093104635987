import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, Button, Grid, makeStyles, Paper, Typography
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { FileRequired } from 'views/document-request/types';

const useStyles = makeStyles((theme: any) => ({
  documentItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  containerIcon: {
    width: 39,
    height: 39,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    marginLeft: theme.spacing(1)
  },
  iconTitle: { color: theme.palette.background.paper },
  dropzoneContainer: {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'lightgray',
    borderStyle: 'dashed',
    padding: theme.spacing(1),
  },
  fileContainer: {
    borderRadius: '15px',
    background: '#ececec',
    width: 'auto',
    paddingRight: theme.spacing(2)
  },
  removeFileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    borderRadius: '50%',
    background: '#646b6e',
  }
}));

type DocumentRequestItemSceneProps = {
  t: TFunction,
  fileRequired: FileRequired,
  acceptedFiles: File[],
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T,
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T,
  removeFile: ({ file }: { file: File }) => void
}

export const DocumentRequestItemScene: FC<DocumentRequestItemSceneProps> = ({
  t,
  fileRequired,
  acceptedFiles,
  getInputProps,
  getRootProps,
  removeFile
}) => {
  const classes = useStyles();
  return (
    <Grid item key={fileRequired.archive_id}>
      <Paper elevation={2}>
        <Grid container direction="row" className={classes.documentItem} wrap="nowrap" justifyContent="space-between">
          <Grid item container spacing={2} wrap="nowrap" alignItems="center" xs={5}>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              className={classes.containerIcon}
            >
              <FontAwesomeIcon
                size="lg"
                icon="file"
                className={classes.iconTitle}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                {fileRequired.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid className={classes.dropzoneContainer}>
              {acceptedFiles.length <= 0 && (
                <Grid {...getRootProps({ className: 'dropzone' })} style={{ cursor: 'pointer' }}>
                  <Typography style={{ textAlign: 'center' }}>{t('documents:drag-drop-select')}</Typography>
                  <input {...getInputProps()} />
                </Grid>
              )}
              <Grid container justifyContent="center" style={{ gap: '5px' }}>
                {acceptedFiles.map((file) => (
                  <Grid item container className={classes.fileContainer} alignItems="center">
                    <Grid item>
                      <Button onClick={() => removeFile({ file })} style={{ minWidth: 'auto' }}>
                        <Box className={classes.removeFileContainer}>
                          <FontAwesomeIcon
                            size="sm"
                            icon="plus"
                            color="white"
                            transform={{ rotate: 45 }}
                          />
                        </Box>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">{file.name}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
