import { Autocomplete } from '@material-ui/lab';
import TextField from 'components/text-field';
import React, { FC } from 'react';

import { AssociationType, FileDataAssociationType } from '../types';

type SearchAssociationType = {
  associations: AssociationType[];
  setDataAssociation: React.Dispatch<
    React.SetStateAction<FileDataAssociationType>
  >;
  dataAssociation: FileDataAssociationType;
};

const SearchAssociation: FC<SearchAssociationType> = ({
  associations,
  setDataAssociation,
  dataAssociation,
}) => {
  const setLabel = (association: AssociationType) => {
    const kinds = association.kinds?.toString().replaceAll(',', ' - ');
    return `${association.name} ${kinds ? `(${kinds})` : ''}`;
  };
  return (
    <Autocomplete
      value={dataAssociation.contact}
      options={associations}
      getOptionLabel={(association) => setLabel(association)}
      onChange={(_, value) => {
        if (value && typeof value === 'object') {
          setDataAssociation({
            ...dataAssociation,
            contact: value,
            associationId: value._id,
          });
        } else {
          setDataAssociation({
            ...dataAssociation,
            contact: null,
            associationId: null,
          });
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={params.InputProps.ref}
          InputProps={params.inputProps}
          label="Association"
        />
      )}
    />
  );
};

export default SearchAssociation;
