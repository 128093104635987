import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import NumberField from 'components/number-field';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

import { getAmount } from '../services';
import usePolicy from '../services/use-policy-context';
import EditEntry from './edit-entry';
import FormBreakdown from './premium-breakdown';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(3) },
  closeIcon: { width: 24, height: 24 },
  spacerText: { marginTop: theme.spacing(1) },
  spacer: { marginTop: theme.spacing(2) },
  text: { color: theme.palette.tab.offselected },
  caption: {
    marginBottom: theme.spacing(0.2),
    color: theme.palette.primary.darker,
    fontSize: 10,
    textTransform: 'uppercase',
  },
  button: { textTransform: 'capitalize' },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '0.875rem',
    '&:hover': { color: theme.palette.common.black },
    alignSelf: 'center'
  },
}));

type DrawerProps = {
  accountingInfo:AccountingType,
  handleClose:()=>void
};

const Drawer: FC<DrawerProps> = ({ accountingInfo, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { accounting } = usePolicy() || {};
  const updatedAccounting = accounting?.find((acc) => acc._id === accountingInfo?._id && acc.kind === accountingInfo.kind);
  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              className={classes.closeIcon}
              onClick={handleClose}
            >
              <FontAwesomeIcon icon="times" size="xs" />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.spacer}>
          <Box>
            <Typography variant="h6" className={classes.text}>
              {accountingInfo.code}
            </Typography>
          </Box>

          <Box className={classes.spacerText}>
            <Typography variant="body2" className={classes.text}>
              {accountingInfo.description}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.spacer}>
          <Box display="flex" flexDirection="row">
            <NumberField
              thousandSeparator
              allowNegative={false}
              decimalScale={2}
              disabled
              prefix="$"
              label={t('policyInfoAndRates:price')}
              value={getAmount(updatedAccounting)}
            />
            <IconButton
              className={classes.icon}
              onClick={() => {
                setOpen(true);
              }}
            >
              <FontAwesomeIcon icon="edit" />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.spacer}>
          <FormBreakdown
            accountingInfo={updatedAccounting}
          />
        </Grid>
      </Grid>
      {updatedAccounting && !updatedAccounting.code.includes('policy')
      && (
      <EditEntry
        open={open}
        onClose={() => setOpen(false)}
        accounting={updatedAccounting}
      />
      )}
    </>
  );
};

export default Drawer;
