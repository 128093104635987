import { InputLabelProps, makeStyles, TextField as MUITextField } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

type TextFieldProps = {
  variant?: 'filled' | 'outlined' | 'standard';
  multiline?: boolean;
  rows?: number;
  name?: string;
  disabled?: boolean;
  color?: 'primary' | 'secondary';
  label?: string | React.ReactNode;
  value?: string | number | null;
  error?: boolean;
  helperText?: string;
  handleBlur?: any;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  InputProps?: any;
  LabelProps?: Partial<InputLabelProps>;
  placeholder?: string;
  rowsMax?: number;
  fullWidth?: boolean;
  style?: {};
  inputRef?: any;
  customClassName?: string
};

const useStyles = makeStyles((theme: any) => ({
  input: {
    '&:focus': { backgroundColor: theme.palette.background.default, },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
    '& .Mui-disabled': { cursor: 'not-allowed' }
  },

}));

const TextField = ({
  variant,
  multiline,
  rows,
  name,
  disabled,
  color,
  label,
  value,
  error,
  helperText,
  handleBlur,
  onChange,
  InputProps,
  LabelProps,
  placeholder,
  rowsMax,
  fullWidth,
  style,
  inputRef,
  customClassName
}: TextFieldProps) => {
  const classes = useStyles();

  return (
    <MUITextField
      fullWidth={fullWidth}
      variant={variant}
      multiline={multiline}
      minRows={rows}
      name={name}
      color={color}
      disabled={disabled}
      className={clsx(classes.input, customClassName)}
      label={label}
      value={value}
      error={error}
      helperText={helperText}
      onBlur={handleBlur}
      onChange={onChange}
      InputProps={InputProps}
      InputLabelProps={LabelProps}
      placeholder={placeholder}
      style={style}
      inputRef={inputRef}
      maxRows={rowsMax}
    />
  );
};

TextField.defaultProps = {
  variant: 'filled',
  multiline: false,
  rows: 4,
  name: undefined,
  color: 'primary',
  label: '',
  disabled: false,
  error: false,
  helperText: undefined,
  handleBlur: undefined,
  onChange: () => {},
  InputProps: undefined,
  placeholder: undefined,
  rowsMax: 6,
  value: '',
  fullWidth: true,
  style: {},
  customClassName: undefined,
  inputRef: undefined,
};

export default TextField;
