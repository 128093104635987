import React from 'react';

import useMortgagePayoffs from './mortgage-payoffs-hooks';
import MortgagePayoffsScene from './mortgage-payoffs-scene';

const MortgagePayoffs = () => {
  const { mortgages, loadingMortgages } = useMortgagePayoffs();

  return (
    <MortgagePayoffsScene
      mortgages={mortgages || []}
      loadingMortgages={loadingMortgages}
    />
  );
};

export default MortgagePayoffs;
