import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from 'components/text-field';
import React, { useState } from 'react';

const SearchAuthorization = ({ members, handledSelectAuth }) => {
  const [valueAutocomplete, setvalueAutocomplete] = useState('');

  return (
    <Autocomplete
      inputValue={valueAutocomplete}
      options={members}
      getOptionLabel={(member: any) => member.name}
      onChange={(_, newValue) => {
        if (newValue) {
          handledSelectAuth(newValue.login_email);
          setTimeout(() => {
            setvalueAutocomplete('');
          }, 1);
        }
      }}
      onInputChange={(_, newInputValue) => {
        setvalueAutocomplete(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={params.InputProps.ref}
          InputProps={{
            ...params.inputProps,
            endAdornment: (
              <InputAdornment position="end">
                <FontAwesomeIcon icon="user" />
              </InputAdornment>)
          }}
          label="Authorizations"
        />
      )}
    />
  );
};

export default SearchAuthorization;
