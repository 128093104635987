import { useMemo } from 'react';
import { Column } from 'types/common';

type ConfigHeaderType = {
  header: any[],
  subHeader: any[],
  hasHeader: boolean,
}

const configHeader = (columns: Column[]): ConfigHeaderType => columns?.reduce((obj:any, item: Column) => {
  const { header } = item;
  if ('columns' in item) {
    const colspan = item?.columns?.length;
    const listSubHeader = item?.columns?.map((subColumns:any) => ({
      title: subColumns?.header?.title,
      align: subColumns?.header?.align,
      width: subColumns?.header?.width,
      disabled: subColumns?.header?.disabled,
      minWidth: subColumns?.header?.minWidth,
      textColor: subColumns?.header?.textColor,
    })) ?? [];
    return {
      ...obj,
      header: [...obj?.header, { ...header, colspan }],
      subHeader: [...obj?.subHeader, ...listSubHeader],
      hasHeader: !header?.disabled
    };
  }
  return {
    ...obj,
    header: [...obj?.header, { ...{}, colspan: 1 }],
    subHeader: [...obj?.subHeader, { ...header }],
  };
}, {
  header: [],
  subHeader: [],
  hasHeader: false
});

const useHeader = (columns: Column[]) => {
  const { header, subHeader, hasHeader } = useMemo(() => configHeader(columns), [columns]);

  return { header, subHeader, hasHeader };
};

export default useHeader;
