import { Grid } from '@material-ui/core';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formInfoOrder } from '../services';
import SectionTitle from './section-title';

type orderTypeFormProps = {
	control:Control<formInfoOrder, object>
}

const OrderTypeForm:FC<orderTypeFormProps> = ({ control }) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitle title="Order Information" />
      <Grid item>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="transaction_type"
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('common:transaction-type')}
                    />
					  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="order_type"
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('common:type')}
                    />
					  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Controller
                  control={control}
                  name="workflow"
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('common:workflow')}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="loan_number"
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label={t('orders:loan-number')}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};

export default OrderTypeForm;
