import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import graphql from 'services/querys';
import { NewBasicInfoType } from 'types/new-user';
import * as Yup from 'yup';

export const useCreateContact = (kind: string, onSuccess?: ({ _id, name }) => void) => {
  const { t } = useTranslation();

  const schema = Yup.object({
    kind: Yup.string(),
    first_name: Yup.string().required(t('validations:required')),
    last_name: Yup.string().when('kind', {
      is: (value: string) => value === 'Person',
      then: Yup.string().required(t('validations:required')),
    }),
  });

  const methods = useForm<NewBasicInfoType>({
    defaultValues: { kind },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [created, setCreated] = useState(false);

  const [createParty, responseCreateParty] = useMutation(graphql.createParty, {
    onSuccess: (data) => {
      responseCreateParty.reset();
      setCreated(true);
	 if (onSuccess) {
        onSuccess({ ...data.createParty });
	 }
    },
    onError: () => {
      responseCreateParty.reset();
    },
  });

  return {
    methods,
    createParty,
    created,
    responseCreateParty
  };
};
