import { Box, Switch, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useStyles } from "../../styles";
import { LoanFormType } from "../../types";

type Props = {
  isDataLoading: boolean,
  isUpdateLoading: boolean,
  loanForm: UseFormReturn<LoanFormType, object>
}

export const LatePenaltyAs: FC<Props> = ({
  isDataLoading,
  isUpdateLoading,
  loanForm
}) => {
  const classes = useStyles();
  const { control } = loanForm;
  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        Late Penalty As
      </Typography>

      <Box>
        <Controller
          control={control}
          name="penalty_as_percent"
          render={({ field }) => (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography>Amount</Typography>
              <Switch
                disabled={isDataLoading || isUpdateLoading}
                size="medium"
                checked={field.value}
                onBlur={field.onBlur}
                onChange={(event) => field.onChange(event)}
              />
              <Typography>Percent</Typography>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};