import { createUser, CreateUserPayload, GQLCreateUser } from 'graphql/contacts/mutations';
import { useMutation } from 'react-query';

type UseCreateUserParams = {
  onSuccess: (data: GQLCreateUser) => void,
  onError: (error: Error) => void
}

export const useCreateUser = ({
  onSuccess,
  onError,
}: UseCreateUserParams) => useMutation<GQLCreateUser, Error, CreateUserPayload>(createUser, {
  onSuccess: (data) => onSuccess(data),
  onError: (error) => onError(error)
});
