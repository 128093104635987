import { GraphQLClient } from 'graphql-request';
import { TOKEN_CLAIMS } from 'utils/constants';

const API_BASE_URL_GRAPHQL = process.env.REACT_APP_API_BASE_URL_GRAPHQL || '';

const client = () => new GraphQLClient(
  API_BASE_URL_GRAPHQL,
  {
    headers: { authorization: localStorage.getItem(TOKEN_CLAIMS)! },
    responseMiddleware: async (res) => {
      if (res instanceof Error) {
        const { message } = res;
        if (message.includes('jwt')) {
          document.cookie.split(';').forEach((c) => {
            document.cookie = c
              .replace(/^ +/, '')
              .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
          });
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  },
);

export default client;
