import moment from 'moment';
import React, { FC } from 'react';

import useRonIvitation from './ron-invite-screen-hooks';
import RonInviteScreenScene from './ron-invite-screen-scene';
import { Buffer } from 'buffer';

const RonInviteScreen: FC = () => {
  const {
    t,
    columns,
    tokensRon,
    scheduleModal,
    setScheduleModal,
    loadingOrder,
    setTokensRon,
    graphOrder
  } = useRonIvitation();

  const tokenParsed = tokensRon[0]?.token
    ? JSON.parse(Buffer.from(tokensRon[0]?.token.split('.')[1], 'base64').toString())
    : null;

  const handleOpenScheduleModal = (openModal:boolean) => setScheduleModal(openModal);

  return (
    <RonInviteScreenScene
      t={t}
      columns={columns}
      start={tokenParsed ? moment.unix(tokenParsed.nbf) : null}
      end={tokenParsed ? moment.unix(tokenParsed.exp) : null}
      date={tokenParsed ? moment.unix(tokenParsed.exp) : null}
      tokens={tokensRon}
      handleOpenScheduleModal={handleOpenScheduleModal}
      scheduleModal={scheduleModal}
      loadingOrder={loadingOrder}
      setTokensRon={setTokensRon}
      graphOrder={graphOrder}
    />
  );
};

export default RonInviteScreen;
