import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  icons: {
    padding: 0,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    fontSize: '0.875rem',
    color: theme.palette.common.black
  },
  iconsbuttons: { width: theme.spacing(1.5) },
}));

type Actionbuttons = {
  onDelete: ()=>void;
}
const ActionButtons:FC <Actionbuttons> = ({ onDelete }) => {
  const classes = useStyles();
  return (
    <Box>
      <IconButton
        className={classes.icons}
        onClick={onDelete}
      >
        <FontAwesomeIcon icon="trash-alt" className={classes.iconsbuttons} />
      </IconButton>
    </Box>
  );
};

export default ActionButtons;
