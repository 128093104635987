import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@material-ui/core';
import AddContactModal from 'components/add-contact-modal/add-contact-modal';
import { BaseButton } from 'components/BaseButton';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';

import { getValidKinds } from '../../../services';
import { AddMortgageAssociation, DeleteMortgageAssociation } from '../../../services/mutations';
import { OrderMortgageType } from '../../../services/types.d';
import InformationRow from './information-row';

type partiesMortgageType ={
	currentMortgage:OrderMortgageType | undefined
}

const PartiesMortgage:FC<partiesMortgageType> = ({ currentMortgage }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const queryCache = useQueryCache();
  const { id } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const [addMortgageAssociation, addMortgageAssociacionResponse] = useMutation(
    AddMortgageAssociation, {
      onSuccess: () => {
        queryCache.invalidateQueries(['order-mortgages', id],);
        showAlert('Success party added', 'success');
        setOpenAddModal(false);
      }
    }
  );

  const [deleteMortgageAssociation, deleteMortgageAssociacionResponse] = useMutation(
    DeleteMortgageAssociation, {
      onSuccess: () => {
        showAlert('Success party deleted', 'success');
        queryCache.invalidateQueries(['order-mortgages', id],);
      },
    }
  );

  const AddMortgageAssociatioFunc = useCallback((kind:string, partyId:string) => {
    addMortgageAssociation({
      id,
      mortgageId: currentMortgage?._id || '',
      kind,
      partyId,
    });
  }, [addMortgageAssociation, currentMortgage?._id, id]);

  const DeleteMortgageF = useCallback((kind:string, partyId:string) => {
    deleteMortgageAssociation({
      id,
      mortgageId: currentMortgage?._id || '',
      kind,
      partyId

    });
  }, [currentMortgage?._id, deleteMortgageAssociation, id]);

  useEffect(() => {
    if (deleteMortgageAssociacionResponse.isLoading) {
      showAlert('loading', 'info');
    }
  }, [deleteMortgageAssociacionResponse.isLoading, showAlert]);

  return (
    <>
      <BaseButton
        text="Add New Party"
        onClick={() => { setOpenAddModal(true); }}
      />
      {currentMortgage?.parties.map((party) => (
        <Box
          display="flex"
          flex="1"
          flexDirection="row"
          justifyContent="space-between"
          style={{ padding: 24, backgroundColor: '#ECEFF1', marginBottom: 24 }}
          key={`${party._id}-${party.kinds?.join(',')}`}
        >
          <Box>
            <InformationRow title="Name" value={party.name} />
            <InformationRow title="Kinds" value={party.kinds.join(',')} />
            {party.ssn && <InformationRow title="SSN" value={party.ssn} />}
          </Box>
          <Box>
            {getValidKinds(party.kinds.join(',')).map((k) => (
              <Box display="flex" flexDirection="row" key={`${party._id}${k}`}>
                <IconButton
                  style={{ padding: 0, marginRight: 4 }}
                  onClick={() => {
                    DeleteMortgageF(k, party._id);
                  }}
                >
                  <FontAwesomeIcon icon="trash-alt" size="xs" />
                </IconButton>
                <Typography variant="caption">{k}</Typography>
              </Box>
            ))}

          </Box>
        </Box>
	  ))}
      {openAddModal && (
      <AddContactModal
        open={openAddModal}
        onClose={() => { setOpenAddModal(false); }}
        allowedCodeAssociations="allowed_mortgage_associations"
        onClickAddParty={(partyId: string, kind: string) => {
          AddMortgageAssociatioFunc(kind, partyId);
        }}
        loading={addMortgageAssociacionResponse.isLoading}
      />
	  )}
    </>
  );
};

export default PartiesMortgage;
