import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import TextField from 'components/text-field';
import React, { useState } from 'react';

import { ArchiveUpdate } from '../../types';

type UpdateArchiveModalProps = {
  handleClose: (x: string) => void,
  handleUpdate: ({
    documentId,
    archiveId,
    fileId,
    newName
  }: {
    documentId,
    archiveId,
    fileId,
    newName
  }) => void,
  data: ArchiveUpdate,
}

export const UpdateArchiveModal = ({
  handleClose,
  handleUpdate,
  data,
}: UpdateArchiveModalProps) => {
  const [descriptionFile, setDescriptionFile] = useState(data.filename);

  return (
    <Dialog open onClose={() => handleClose('')}>
      <DialogTitle>Edit Archive</DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <TextField
              value={descriptionFile}
              label="Description"
              onChange={(e) => {
                setDescriptionFile(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                onClick={() => {
                  handleUpdate({
                    documentId: data.documentId,
                    archiveId: data.archiveId,
                    fileId: data.fileId,
                    newName: descriptionFile
                  });
                  handleClose('');
                }}
                variant="contained"
                color="secondary"
              >
                Update Archive
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
