import {
  Box,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { NewUserType } from 'types/new-user';

const useStyles = makeStyles((theme: any) => ({
  searchTitle: {
    fontWeight: 500,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  autompleteuser: { marginBottom: theme.spacing(4) }
}));

type PartySearchProps = {
  description: string,
  labelField: string,
  parties: NewUserType[],
  handleOnChange: Function,
  handleItemSelect: Function
}

const PartySearch: FC<PartySearchProps> = ({
  description,
  labelField,
  parties,
  handleOnChange,
  handleItemSelect
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid item>
        <Typography
          variant="subtitle1"
          className={classes.searchTitle}
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          fullWidth
          className={classes.autompleteuser}
          options={parties}
          filterOptions={(options) => options}
          getOptionLabel={(party) => party.name}
          onInputChange={(_, value, reason) => {
            if (reason === 'input') {
              handleOnChange(value);
            }
          }}
          onChange={(_, value) => {
            if (value && typeof value === 'object') {
              handleItemSelect(value._id);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={params.InputProps.ref}
              InputProps={params.inputProps}
              label={labelField}
            />
          )}
        />
      </Grid>
    </Box>
  );
};

export default PartySearch;
