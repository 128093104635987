import React, { FC } from 'react';
import { NewUserType } from 'types/new-user';

import useNotaryBlocks from './notary-blocks-hooks';
import NotaryBlocksScene from './notary-blocks-scene';

type notaryblocksTabType ={
  party:NewUserType | undefined,
  roleParty:string
}

const NotaryBlocks: FC<notaryblocksTabType> = ({ party, roleParty }) => {
  const { methods, t } = useNotaryBlocks();

  return (
    <NotaryBlocksScene
      methods={methods}
      party={party}
      t={t}
      roleParty={roleParty}
    />
  );
};

export default NotaryBlocks;
