import { Box, Typography } from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import TableComponent from 'components/Table';
import { TextButton } from 'components/TextButton';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/helpers';
import { AccountingLoanEntry } from 'v2-types/order';

import { useStyles } from '../../styles';
import { ModalData } from '../../types';

type Props = {
  setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>,
  isDataLoading: boolean,
  isUpdateLoading: boolean,
  loanAmountEntry?: AccountingLoanEntry
}

export const Additional: FC<Props> = ({
  setModalData,
  isDataLoading,
  isUpdateLoading,
  loanAmountEntry
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box>
      <Box style={{
        display: 'flex', gap: '20px', height: '50px', alignItems: 'flex-start', justifyContent: 'space-between'
      }}
      >
        <Typography variant="h6" className={classes.subTitle}>
          Additional
        </Typography>
        <TextButton
          icon="plus"
          text="Add additional"
          textColor="#FB8C00"
          disabled={isDataLoading || isUpdateLoading}
          onClick={() => setModalData({ modal: 'createAdditional' })}
        />
      </Box>

      {loanAmountEntry && loanAmountEntry.involved_parties && loanAmountEntry.involved_parties.length > 0 && (
        <Box>
          <TableComponent
            disabledBorderLeft
            cellAlignment="left"
            columns={{
              actions: {
                header: { title: t('accounting:actions'), width: '100px' },
                renderer: ({ row }) => (
                  <Actionbuttons
                    handleEdit={() => setModalData({ modal: 'editPartyEntry', entry: row })}
                  />
                )
              },
              name: {
                header: { title: t('accounting:description'), align: 'left' },
                renderer: ({ row }) => <>{row.kind}</>,
              },
              amount: {
                header: { title: t('accounting:amount'), align: 'left' },
                renderer: ({ row }) => <>{`${formatMoney(row?.amount || 0)} (${((row?.percent || 0) * 100)}%)`}</>,
              }
            }}
            dataSet={loanAmountEntry?.involved_parties || []}
          />
        </Box>
      )}
      {loanAmountEntry && loanAmountEntry.involved_parties && loanAmountEntry.involved_parties.length === 0 && (
        <Typography className={classes.placeholderText}>
          There are not additionals yet
        </Typography>
      )}
    </Box>
  );
};
