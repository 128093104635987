import { useTheme } from '@material-ui/core';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrderProperty } from 'types/order';
import { useLocation } from 'v2-hooks/useLocation';

type UseTabPropertyParams ={
  getValues: UseFormGetValues<OrderProperty>,
  setValue: UseFormSetValue<OrderProperty>
}
const useTabProperty = ({ getValues, setValue }: UseTabPropertyParams) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    selectCountry,
    selectState,
    selectCounty,
    selectZcode,
    countries,
    statesBycountry,
    counties,
    isCountyListLoading,
    zcodes,
    isZcodesListLoading,
    cities
  } = useLocation({
    initialData: {
      country: getValues('address.country'),
      state: getValues('address.state'),
      county: getValues('address.settlement') || '',
      zcode: getValues('address.postal_code')?.toString() ?? '',
	  city: getValues('address.locality' || '')
    },
    onSelectState: () => {
      setValue('address.settlement', '');
      setValue('address.postal_code', '');
    },
    onSelectCounty: () => {},
    onAutofillByZcode: ({ state, county, city }) => {
      const prevStaTe = getValues('address.state');
      const prevCounty = getValues('address.settlement');
	  const prevCity = getValues('address.locality');
      if (prevStaTe === '') {
        setValue('address.state', state);
      }
      if (prevCounty === '') {
        setValue('address.settlement', county);
      }
	  if (prevCity === '') {
        setValue('address.locality', city);
	  }
    },
  });
  return {
    t,
    theme,
    countries,
    states: statesBycountry,
    selectCountry,
    selectState,
    counties,
    selectCounty,
    isCountyListLoading,
    zcodes,
    isZcodesListLoading,
    selectZcode,
    cities
  };
};

export default useTabProperty;
