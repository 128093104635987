import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from 'components/text-field';
import { GQLCreatePartyAssociation } from 'graphql/parties/mutations';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { KindField } from './components/kindField';
import { useModalCreateAssociationHooks } from './hooks';
import { useStyles } from './styles';

type ModalCreateAssociationProps = {
  partyId: string,
  isTitleCompany: boolean,
  isTitleAgency: boolean,
  open: boolean,
  onSuccess: (data: GQLCreatePartyAssociation) => void,
  onClose: () => void,
  onError: () => void,
};

export const ModalCreateAssociation: FC<ModalCreateAssociationProps> = ({
  partyId,
  isTitleCompany,
  isTitleAgency,
  open,
  onSuccess,
  onClose,
  onError
}) => {
  const classes = useStyles();
  const {
    reset,
    control,
    createAssociation,
    parties,
    setSearchText,
    setValue,
    errors,
    t,
    isValid
  } = useModalCreateAssociationHooks({ partyId, onSuccess, onClose, onError });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.title}>
        {t('parties:create-assoc')}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={(e) => {
          e.preventDefault();
          createAssociation();
        }}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={8}>
              <Controller
                name="selected"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    {...field}
                    freeSolo
                    fullWidth
                    innerRef={ref}
                    options={parties}
                    filterOptions={(options) => options}
                    getOptionLabel={(party) => party.name}
                    onInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        setSearchText(value);
                      }
                    }}
                    onChange={(_, value) => {
                      if (value && typeof value === 'object') {
                        onChange(value);
                        setValue('_id', value._id);
                        setValue('name', value.name);
                        setValue('kind', '');
                      } else {
                        onChange(null);
                        setValue('_id', '');
                        setValue('name', '');
                        setValue('kind', '');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={params.InputProps.ref}
                        InputProps={params.inputProps}
                        label={t('users:name')}
                        error={!!errors._id}
                        helperText={
                          errors._id?.message || t('validations:required')
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <KindField
                isTitleCompany={isTitleCompany}
                isTitleAgency={isTitleAgency}
                control={control}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    disableElevation
                    disabled={!isValid}
                    variant="contained"
                    color="secondary"
                    className={classes.buttonText}
                  >
                    {t('users:create')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
