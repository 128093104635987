import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { OrderProperty } from 'types/order';
import { DocumentsType, NewOrderPartyType } from 'v2-types/order';
import { template_document } from 'views/orders/documents/new_documents/types';

export type GQLGetOrderDocuments = {
  getOrder: {
    files: DocumentsType[];
    parties: NewOrderPartyType[];
    estates: OrderProperty[];
  };
};

export const getOrderDocuments = async (payload: { id: string }) => {
  const query = gql`
    query Files($id: ID!) {
      getOrder(_id: $id) {
        files {
          _id
          archives {
            _id
            files {
              _id
              data {
                _id
                kind
                address
                kinds
                name
              }
              download
              filename
              mtime
              size
              source
            }
            kinds
            name
          }
          data {
            _id
            address
            kind
            kinds
            name
          }
          description
          kind
          template
          per
          parts {
            description
            filename
            mtime
            size
            source
          }
        }
        parties {
          name
          _id
          kinds
        }
        estates {
          _id
          address {
            _id
            address
          }
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLGetOrderDocuments>(
    query,
    payload
  );
  return response;
};

export type GQLGetTemplates = {
  getTemplates: template_document[];
};

export const getTemplatesResponse = async () => {
  const query = `
  query Query {
    getTemplates {
      _id
      description
    }
  }
  `;

  const response = await graphQLClient().request<GQLGetTemplates>(query);
  return response;
};
