import {
  Dialog, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { OrderProperty } from 'types/order';
import { Loan } from 'v2-types/order';

import { useCreateLoanModal } from './hooks';
import { useStyles } from './styles';

type Props = {
  onClose: () => void,
  onSuccess: (data: Loan[], loanIdToSelect: string) => void,
  properties: OrderProperty[]
}

export const CreateLoanModal: FC<Props> = ({ onClose, onSuccess, properties }) => {
  const classes = useStyles();
  const {
    createLoanForm,
    onConfirm,
    isCreateLoanLoading
  } = useCreateLoanModal({ onClose, onSuccess });

  const { control } = createLoanForm;

  return (
    <Dialog open fullWidth>
      <DialogContent style={{ padding: 0 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              Create Loan
            </Typography>
          </Grid>
          <Grid item container className={classes.container}>
            <Controller
              control={control}
              name="loanNumber"
              render={({ field }) => (
                <TextField
                  color="secondary"
                  label={<Typography variant="body2">Loan Number</Typography>}
                  name="loan_number"
                  handleBlur={field.onBlur}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="estateId"
              render={({ field }) => (
                <SelectField
                  label="Property"
                  value={field.value}
                  handleBlur={field.onBlur}
                  handleChange={field.onChange}
                  data={properties.map((property) => ({
                    ...property,
                    description: property.address.address
                  })) as any[]}
                  dataKey="_id"
                  dataValue="_id"
                  dataText="description"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <ContainedButton
          text="Close"
          onClick={onClose}
          disabled={isCreateLoanLoading}
        />
        <ContainedButton
          text="Create"
          isLoading={isCreateLoanLoading}
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};
