import { Grid } from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import TableComponent from 'components/Table';
import React from 'react';
import { AccountingInvolvedParty } from 'v2-types/order';

export const TableEntries = ({
  purchasePriceEntries,
  t,
  setPartyLedgerEntry,
  setOpenPartyLedgerModal,
  onRemoveOrderPartyEntry,
}) => (
  <Grid item xs>
    <TableComponent
      disabledBorderLeft
      cellAlignment="left"
      columns={{
        actions: {
          header: { title: t('accounting:actions') },
          renderer: ({ row }) => (
            <Actionbuttons
              handleEdit={() => {
                setPartyLedgerEntry(row as AccountingInvolvedParty);
                setOpenPartyLedgerModal(true);
              }}
              handleDelete={() => {
                const partyEntry = row as AccountingInvolvedParty;
                onRemoveOrderPartyEntry(partyEntry);
              }}
            />
          ),
        },
        name: {
          header: { title: t('accounting:holder') },
          renderer: ({ row }) => <>{row.name}</>,
        },
        kind: {
          header: { title: t('accounting:kind') },
          renderer: ({ row }) => <>{row.kind}</>,
        },
        amount: {
          header: { title: t('accounting:amount') },
          renderer: ({ row }) => (
            <>
              $
              {Number(row.amount).toLocaleString('en-US')}
            </>
          ),
        },
      }}
      dataSet={purchasePriceEntries}
    />
  </Grid>
);
