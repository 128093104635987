import {
  Box, Button, Grid, makeStyles, Typography
} from '@material-ui/core';
import { PaperCard } from 'components/cards/paper-card';
import SelectField from 'components/select-field';
import { Text } from 'components/text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_KIND_LIST } from 'utils/constants';
import { DocumentsType } from 'v2-types/order';

import useHandleRedirection from '../../services/useHandleRedirection';
import { UseDocumentList } from './document-list-hooks';

const useStyles = makeStyles((theme: any) => ({
  buttonFilled: {
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
    '&:hover': { backgroundColor: theme.palette.button.hover },
  },
  documentsbutton: { fontWeight: 500, color: theme.palette.common.black },
  documentItem: {
    overflowWrap: 'break-word',
    maxWidth: '380px',
    borderBottom: '1px solid #00000020',
    '&:last-child': { borderBottom: '0px', }
  }
}));

type Props = {
  documents: DocumentsType[],
}

export const DocumentList: FC<Props> = ({ documents }) => {
  const {
    documentsFiltered,
    kindSelected,
    setKindSelected
  } = UseDocumentList({ documents });
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleRedirection } = useHandleRedirection();
  return (
    <PaperCard
      title={t('orders:documents')}
      rightComponent={(
        <Box>
          <Button
            onClick={() => { handleRedirection('documents'); }}
            className={classes.buttonFilled}
          >
            <Typography variant="body2" className={classes.documentsbutton}>{t('common:go-to-documents')}</Typography>
          </Button>
        </Box>
      )}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <SelectField
            label={t('documents:document-kind')}
            value={kindSelected}
            data={['All', ...DOCUMENT_KIND_LIST].map((kind) => ({ name: kind, value: kind.toLowerCase() }))}
            dataKey="name"
            dataValue="value"
            dataText="name"
            handleChange={(e) => setKindSelected(e.target.value)}
          />
        </Grid>
        <Grid item container spacing={2} direction="column">
          {documentsFiltered.map((document) => (
            <Grid
              item
              className={classes.documentItem}
            >
              <Text
                value={document.description}
                fontWeight="bold"
                variant="caption"
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </PaperCard>
  );
};
