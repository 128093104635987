import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, ButtonBase, Collapse, IconButton, makeStyles,
  Table, TableBody, TableCell, TableContainer, TableRow,
} from '@material-ui/core';
import { Text } from 'components/text';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';

import { PartyWithPartyInvolved } from '../../../types';

type RowProps = {
  party: PartyWithPartyInvolved,
  t: TFunction
}

const useStyles = makeStyles(() => ({
  tableCell: {
    borderBottom: '1px solid #00000020',
    padding: '10px 0px'
  },
  tableCellCollapsable: {
    padding: 0,
    border: 'none'
  }
}));

export const Row: FC<RowProps> = ({ party, t }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            style={{ paddingRight: '5px' }}
          >
            {open ? <FontAwesomeIcon icon="angle-up" /> : <FontAwesomeIcon icon="angle-down" />}
          </IconButton>

          <ButtonBase
            onClick={() => setOpen(!open)}
          >
            <Text
              value={party.name}
              fontWeight="bold"
              variant="caption"
            />
          </ButtonBase>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.tableCellCollapsable}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '15px' }}>
              <Text
                value={t('common:attorneys').toUpperCase()}
                variant="caption"
              />
              <TableContainer>
                <Table>
                  <TableBody>
                    {party.attorneys.length <= 0 && (
                      <Text
                        value={t('orders:not-defined-yet').toString()}
                        fontWeight="bold"
                        variant="caption"
                      />
                    )}
                    {party.attorneys.map((attorney) => (
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          <Text
                            value={attorney}
                            fontWeight="bold"
                            variant="caption"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
