import { capitalize } from '@material-ui/core';
import {
  getOrderProperties,
  GQLGetOrderProperties,
} from 'graphql/properties/queries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { OrderProperty } from 'types/order';
import useAlert from 'utils/alert';
import { LEDGER_CODE_PURCHASE_PRICE } from 'utils/constants';
import useDeleteLedgerAssociation from 'utils/ledger/use-delete-ledger-association';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import { initialValues } from '../properties/utils/initialValues';

const useEarnestAndCommissions = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [selectedProperty, setSelectedProperty] = useState<OrderProperty>(initialValues);
  const [openPartyLedgerModal, setOpenPartyLedgerModal] = useState<boolean>(false);
  const showAlert = useAlert();

  const [purchasePriceEntries, setPurchasePriceEntries] = useState<
    AccountingInvolvedParty[]
  >([]);
  const [entryLedger, setEntryLedger] = useState<AccountingType>();
  const [entriesLedger, setEntiesLedger] = useState<AccountingType[]>([]);
  const [properties, setProperties] = useState<OrderProperty[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [partyLedgerEntry, setPartyLedgerEntry] = useState<AccountingInvolvedParty>();

  const onUpdatePurchasePriceEntries = (accounting: AccountingType[]) => {
    setEntiesLedger(accounting);

    const purchasePriceEntriesf = accounting.find((entry) => entry.code.includes(
      `${LEDGER_CODE_PURCHASE_PRICE}_${selectedProperty._id}`
    ));
    const purchasePriceEntriesList = purchasePriceEntriesf
      ? (purchasePriceEntriesf as AccountingType).involved_parties
      : [];
    setPurchasePriceEntries(purchasePriceEntriesList);

    return purchasePriceEntriesf;
  };
  useQuery<GQLGetOrderProperties>(
    ['order-properties', orderId],
    () => getOrderProperties({
      id: orderId,
      filters: { accounting: 'property' },
    }),
    {
      onSuccess: (response) => {
        setEntiesLedger(response.getOrder.accounting ?? []);
        setProperties(response.getOrder.estates ?? []);

        if (response.getOrder && response.getOrder.estates?.length) {
          setSelectedProperty(response.getOrder.estates[0]);
        }
      },
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (selectedProperty._id !== '') {
      const findedPriceEntries = onUpdatePurchasePriceEntries(entriesLedger);
      setEntryLedger(findedPriceEntries);
    } else {
      setPurchasePriceEntries([]);
      setEntryLedger(undefined);
    }
  }, [selectedProperty]);

  const selectProperty = (id: string) => {
    if (properties.length > 0) {
      const property = properties.find(
        (orderProperty) => orderProperty._id === id
      );
      setSelectedProperty(property ?? initialValues);
    }
  };
  const { deleteAssociation, deleteAssociationResponse } = useDeleteLedgerAssociation((response) => {
    if (response) {
	  showAlert(capitalize(t('dialogs:order-updated')), 'success');
	  deleteAssociationResponse.reset();
	  onUpdatePurchasePriceEntries(
        response.deleteOrderLedgerAssociation.accounting ?? []
	  );
    }
    setLoading(false);
  },
  () => {
    setLoading(false);
    showAlert(capitalize(t('dialogs:there-is-an')), 'error');
  },);

  const onRemoveOrderPartyEntry = (entry: AccountingInvolvedParty) => {
    setLoading(true);
    deleteAssociation({
      id: orderId,
      accountingId: entryLedger?._id!,
      kind: entry.kind,
      partyId: entry._id,
    });
  };

  return {
    t,
    properties,
    selectedProperty,
    selectProperty,
    purchasePriceEntries,
    entryLedger,
    partyLedgerEntry,
    setPartyLedgerEntry,
    openPartyLedgerModal,
    setOpenPartyLedgerModal,
    onUpdatePurchasePriceEntries,
    onRemoveOrderPartyEntry,
    isLoading,
  };
};

export default useEarnestAndCommissions;
