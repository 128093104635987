/* eslint-disable react/no-array-index-key */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  makeStyles
} from '@material-ui/core';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import FormBreakdown from '../../components/payment-breakdown';

const useStyles = makeStyles((theme: any) => ({
  row: {
	  display: 'flex',
	  flexDirection: 'row',
	  '& > *': { marginLeft: theme.spacing(2) }
  },
  container: {
	  display: 'flex',
	  flexDirection: 'row',
	  alignItems: 'flex-end',
	  marginTop: theme.spacing(3),
	  marginBottom: theme.spacing(3),
	  '& > *': { marginRight: theme.spacing(2) },
  },
  buttonGroupContainer: {
	  display: 'flex',
	  justifyContent: 'center',
	  flexDirection: 'column',
  },
  caption: {
    marginBottom: theme.spacing(0.2),
    color: theme.palette.primary.darker,
    fontSize: 10,
    textTransform: 'uppercase',
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '0.875rem',
    '&:hover': { color: theme.palette.common.black },
    alignSelf: 'center'
  },
}));

type RecordingType = {
	recording:AccountingType,
	handleUpdateAccounting:(accountingData:AccountingType[]) => void,
	handleDelete:()=>void
}

const Recording: FC<RecordingType> = ({ recording, handleUpdateAccounting, handleDelete }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.row}>
        <IconButton
          className={classes.icon}
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon="times" />
        </IconButton>
        <TextField
          label={t('taxesAndFees:recording-type')}
          variant="filled"
          value={recording?.description}
          disabled
        />

        <TextField
          value={formatMoney(recording.amount)}
          label={t('taxesAndFees:mortgage-amount')}
          variant="filled"
          disabled
        />
      </Box>

      <Box className={classes.row}>
        <FormBreakdown
          addSubtitleMarginTop={false}
          accountingInfo={recording}
          handleSuccess={handleUpdateAccounting}
          disablePayee
          showCompleteInfo={false}
        />
      </Box>
    </Box>
  );
};

export default Recording;
