import {
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import React, { FC } from 'react';
import { WireTransfersType } from 'types/wire-transfers';
import { formatMoney } from 'utils/helpers';

import { useStyles } from './styles';

const columns = [
  { id: 'amount', label: 'Amount' },
  { id: 'description', label: 'Description' },
  { id: 'name', label: 'Name' }
];

type TransferInfoProps = {
  transfer: WireTransfersType,
  currentUserEmail: string
}

export const TransferInfo: FC<TransferInfoProps> = ({ transfer, currentUserEmail }) => {
  const classes = useStyles();
  const total = formatMoney(
    transfer.instructions.reduce(
      (acc, element) => acc + element.amount,
      0
    )
  );
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell className={classes.tableHeaderCell} key={column.id}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2" className={classes.total}>
                {total}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">
                {transfer.reference}
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography variant="body2">
                {currentUserEmail}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
