import { useRef } from 'react';
import { useMutation } from 'react-query';
import { getSpeechAudioByText } from 'services/inference/getSpeechAudioByText';

type UseSpeechParams = {
  onSpeechAudioEnd: () => void
}

export const useSpeech = ({ onSpeechAudioEnd }: UseSpeechParams) => {
  const playsoundRef = useRef<AudioBufferSourceNode>();
  const abortControllerRef = useRef<AbortController>();

  const [
    getSpeech,
    { isLoading, reset }
  ] = useMutation<any, any, { text: string }>((args) => {
    abortControllerRef.current = new AbortController();
    return getSpeechAudioByText({ text: args.text, signal: abortControllerRef.current.signal });
  }, {
    onSuccess: async (data) => {
      const buffer = await data.arrayBuffer();
      const ctx = new AudioContext();
      const decodedAudio = await ctx.decodeAudioData(buffer);
      const playsound = ctx.createBufferSource();
      playsound.buffer = decodedAudio;
      playsound.connect(ctx.destination);
      playsound.start(ctx.currentTime);
      playsoundRef.current = playsound;
      playsound.onended = () => {
        onSpeechAudioEnd();
      };
    },
  });

  const stopSpeechAudio = () => {
    if (playsoundRef.current) {
      playsoundRef.current.stop();
    }
  };

  const abortGetSpeech = () => {
    if (abortControllerRef.current) {
      reset();
      abortControllerRef.current.abort();
    }
  };

  return {
    getSpeech,
    stopSpeechAudio,
    isSpeechLoading: isLoading,
    abortGetSpeech
  };
};
