import {
  Grid,
  Paper
} from '@material-ui/core';
import React, { FC, ReactElement, useEffect, useRef } from 'react';

import { OrderProperty } from '../../types/order';
import { OlMap, OlMapRefType } from '../OlMap';
import SelectField from '../select-field';
import { useStyles } from './styles';

type PropertiesMapProps = {
  properties: OrderProperty[],
  onSelectProperty: (id: string) => void,
  selectedPropertyId: string,
  mapInitZoom?: number,
  mapFocusZoom?: number,
  direction?: 'vertical' | 'horizontal',
  mapHeight?: string,
  mapWidth?: string,
  padding?: string,
  elevation?: number,
  header?: ReactElement,
  children?: ReactElement,
  maxSeleceCharacterLength?: number
}

export const PropertiesMap: FC<PropertiesMapProps> = (props) => {
  const {
    properties,
    onSelectProperty,
    selectedPropertyId,
    mapInitZoom,
    mapFocusZoom,
    direction = 'horizontal',
    mapHeight = '500px',
    mapWidth = '50%',
    padding = '3',
    elevation = 1,
    header,
    children,
    maxSeleceCharacterLength
  } = props;
  const classes = useStyles({
    direction,
    mapHeight,
    mapWidth,
    padding
  });
  const olMapRef = useRef<OlMapRefType>(null);

  useEffect(() => {
    if (olMapRef.current?.selectFeatureById) {
      olMapRef.current.selectFeatureById(selectedPropertyId);
    }
  }, [selectedPropertyId]);

  const propertiesData = properties.map((x) => {
    let { address } = x.address;
    if (maxSeleceCharacterLength && address.length > maxSeleceCharacterLength) {
      address = `${address.substring(0, maxSeleceCharacterLength)}...`;
    }

    return {
      _id: x._id,
      address
    };
  });

  return (
    <Grid
      container
      className={classes.root}
      direction={direction === 'horizontal' ? 'row' : 'column'}
      wrap="nowrap"
      spacing={direction === 'horizontal' ? 1 : 0}
    >
      <Grid item container className={classes.mapContainer}>
        <OlMap
          ref={olMapRef}
          features={
            properties.map((property) => ({
              id: property._id,
              type: 'Feature',
              geometry: { ...property.address.geo },
              properties: { label: property.address.address }
            }))
          }
          onFeatureSelect={onSelectProperty}
          initZoom={mapInitZoom}
          focusZoom={mapFocusZoom}
        />
      </Grid>
      <Grid item xs>
        <Paper className={classes.controls} elevation={elevation}>
          {header}
          <SelectField
            label="Properties"
            value={selectedPropertyId}
            data={propertiesData}
            dataKey="_id"
            dataValue="_id"
            dataText="address"
            handleChange={(e) => onSelectProperty(e.target.value)}
          />
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
};
