import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React, { FC, useMemo } from 'react';
import { OrderListingType } from 'types/order';

import { columns, CurrentData } from '../services';

const useStyles = makeStyles((theme: any) => ({
  table: {
    borderSpacing: '0 8px',
    borderCollapse: 'separate',
  },
  headerRow: { cursor: 'default' },
  headerCell: { borderBottom: 'none' },
  row: { cursor: 'pointer' },
  cell: {
    borderTop: `1px solid ${theme.palette.button.default}`,
    borderBottom: `1px solid ${theme.palette.button.default}`,
  },
  firstCell: {
    borderLeft: `3px solid ${theme.palette.success.dark}`,
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
  },
  lastCell: {
    borderRight: `1px solid ${theme.palette.button.default}`,
    borderTopRightRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  paginationButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  pagintaionIcon: { fontSize: '0.98rem' },
}));

type OrdersTableProps = {
  t: TFunction;
  currentData: CurrentData;
  orders: OrderListingType[];
  handleSelectRow: (id: string, code: string) => void;
  handleTablePageChange: (action: 'prev' | 'next') => (cuantity?: number) => void;
  agencyId: string
};

const OrdersTable: FC<OrdersTableProps> = ({
  t,
  currentData,
  orders,
  handleSelectRow,
  handleTablePageChange,
  agencyId
}) => {
  const classes = useStyles();

  const shouldRenderAgencyColumn = agencyId === '';
  const memorizedColumns = useMemo(() => columns(t, shouldRenderAgencyColumn), [t, shouldRenderAgencyColumn]);

  if (!orders.length) {
    return (
      <Grid container>
        <Grid item />
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.headerRow}>
                {memorizedColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    className={classes.headerCell}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {orders.map((order) => (
                <TableRow
                  key={order._id}
                  hover
                  className={classes.row}
                  onClick={() => handleSelectRow(order._id, order.order_id)}
                >
                  {memorizedColumns.map((column, index) => (
                    <TableCell
                      key={column.id}
                      className={
                        clsx(classes.cell, {
                          [classes.firstCell]: index === 0,
                          [classes.lastCell]: index === memorizedColumns.length - 1,
                        })
                      }
                    >
                      {column.render(order)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            {`Page: ${currentData.page} of ${currentData.pages}`}
          </Grid>

          <Grid item>
            <IconButton
              disabled={currentData.page <= 1}
              className={classes.paginationButton}
              onClick={() => handleTablePageChange('prev')()}
            >
              <FontAwesomeIcon icon="chevron-left" className={classes.pagintaionIcon} />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              disabled={currentData.page >= currentData.pages}
              className={classes.paginationButton}
              onClick={() => handleTablePageChange('next')()}
            >
              <FontAwesomeIcon icon="chevron-right" className={classes.pagintaionIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrdersTable;
