import { Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { OrderProperty } from 'types/order';
import { AccountingType, DocumentsType, NewOrderPartyType } from 'v2-types/order';

import { Balancing } from './components/balancing/balancing';
import { DocumentList } from './components/document-list/document-list';
import { GeneralInformation } from './components/general-information/general-information';
import MapCard from './components/map-overview/map-overview';
import { PartiesInvolved } from './components/parties-involved/parties-involved';
import ProgressOrder from './components/progress-order/progress-order';

const useStyles = makeStyles((theme: any) => ({ container: { padding: theme.spacing(3), gap: theme.spacing(2) } }));

type OrderOverviewType = {
  orderOpener: NewOrderPartyType | undefined,
  sellers: NewOrderPartyType[],
  buyers: NewOrderPartyType[],
  lenders: NewOrderPartyType[],
  attorneys: NewOrderPartyType[],
  properties: OrderProperty[],
  accountingBalance: AccountingType[],
  accountingPremium: AccountingType[],
  orderDocuments: DocumentsType[],
  isOrderLoading: boolean,
  orderType: string
};

const OverviewScene: FC<OrderOverviewType> = ({
  orderOpener,
  sellers,
  buyers,
  lenders,
  attorneys,
  properties,
  accountingBalance,
  accountingPremium,
  orderDocuments,
  isOrderLoading,
  orderType
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} xs>
      <Grid item container xs direction="column">
        <ProgressOrder />
      </Grid>
      <Grid item container xs direction="column" spacing={2}>
        <Grid item>
          <MapCard properties={properties} />
        </Grid>
        <Grid item>
          <PartiesInvolved
            sellers={sellers}
            buyers={buyers}
            lenders={lenders}
            attorneys={attorneys}
            isOrderLoading={isOrderLoading}
          />
        </Grid>
        <Grid item>
          <Balancing accountingBalance={accountingBalance} />
        </Grid>
      </Grid>
      <Grid item container xs direction="column" spacing={2}>
        <Grid item>
          <GeneralInformation
            orderOpener={orderOpener?.name || ''}
            accountingBalance={accountingBalance}
            accountingPremium={accountingPremium}
            orderType={orderType}
          />
        </Grid>
        {orderDocuments.length > 0
          && (
          <Grid item>
            <DocumentList
              documents={orderDocuments}
            />
          </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default OverviewScene;
