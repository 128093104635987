import { getListing, GQLGetListingResponse, ListingCode } from 'graphql/listings/queries';
import { QueryConfig, useQuery } from 'react-query';

type Params = {
  code: ListingCode,
  queryConfig?: QueryConfig<GQLGetListingResponse, Error>
}

export const useGetListings = ({ code, queryConfig }: Params) => useQuery([{ code }, "interest-kind-listing"], {
  ...queryConfig,
  staleTime: Infinity,
  queryFn: getListing
});
