import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  backButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: theme.spacing(1.75),
    borderRadius: theme.spacing(0.5),
  },
  backIcon: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  title: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.shadow,
  },
  button: { textTransform: 'capitalize' },
  titleAccent: { color: theme.palette.primary.main },
}));

type NavigationProps = {
  t: TFunction;
  name: string;
  handleNavigateBack: () => void;
};

const Navigation: FC<NavigationProps> = ({ t, name, handleNavigateBack }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.title}>
              <Button className={classes.button} onClick={handleNavigateBack}>
                {`${t('users:contacts')} >`}
              </Button>

              <span className={classes.titleAccent}>{name}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navigation;
