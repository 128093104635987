import { Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { NewUserType } from 'types/new-user';
import { UpdateOrderSpecific } from 'types/order';

import Accordion from './components/accordion';

const useStyles = makeStyles((theme: any) => ({ container: { paddingTop: theme.spacing(3) } }));

type notaryblocksScene = {
  methods: UseFormReturn<UpdateOrderSpecific, any>,
  party:NewUserType | undefined,
  t: TFunction,
  roleParty:string
}
const NotaryBlocksScene: FC<notaryblocksScene> = ({
  methods,
  party,
  t,
  roleParty
}) => {
  const classes = useStyles();

  return (
    <form>
      <Grid container direction="column" spacing={3} className={classes.container}>
        <Grid item xs={12}>
          <Accordion
            title={`${t('orders:notary-blocks')} - ${party?.name}`}
            control={methods?.control}
            prefix={roleParty === 'Buyer' ? 'data_buyer' : 'data_seller'}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default NotaryBlocksScene;
