import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  container: { flexDirection: 'column', flex: 1, paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) },
  inputs: {
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
    '&:focus': { backgroundColor: theme.palette.background.default },
  },
  stretchInput: { padding: 0, align: 'center', paddingLeft: theme.spacing(1.5) },
  Boxider: { backgroundColor: theme.palette.text.hint, height: 'auto' },
  subTitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    marginBottom: theme.spacing(2)
  },
  labelRadio: { marginRight: 0, marginLeft: 0, },
  endAdornmentStyle: {
    width: theme.spacing(9),
    height: theme.spacing(7),
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#0000001A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1.2),
  },
  inputsRow: { display: 'flex', flexDirection: 'row', marginBottom: theme.spacing(2) },
  loanAmount: { flex: 2 },
  containerRadio: { flex: 1, display: 'flex', flexDirection: 'row' },
  containerFlex: { flex: 1 },
  leftPaddingContent: { flex: 1, paddingLeft: theme.spacing(2) },
  rightPaddingContent: { flex: 1, paddingRight: theme.spacing(2) },
  bottomMargin: { marginBottom: theme.spacing(2) },
  fontsizeIcon: { fontSize: '0.875rem' },
  moyearAdornment: { padding: 0, borderBottomRightRadius: theme.spacing(0.5) }
}));
