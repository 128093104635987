import React from 'react';

import OnboardingTermsAndConditionsScene from './onboarding-terms-and-conditions-scene';
import useOnboardingTermsAndConditions from './use-onboarding-terms-and-conditions';

const OnboardingTermsAndConditions = () => {
  const {
    t,
    theme,
    history,
    tabIndex,
    setTabIndex,
    userAgreed,
    setUserAgreed,
  } = useOnboardingTermsAndConditions();

  const handleTabChange = (_: React.ChangeEvent<{}>, value: any) => setTabIndex(value);

  const handleUserAgreed = () => setUserAgreed((prevState) => (!prevState));

  const handleAgreement = () => history.push('/onboarding/welcome');

  const a11yProps = (index: number) => ({
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  });

  return (
    <OnboardingTermsAndConditionsScene
      t={t}
      theme={theme}
      a11yProps={a11yProps}
      tabIndex={tabIndex}
      handleTabChange={handleTabChange}
      userAgreed={userAgreed}
      handleUserAgreed={handleUserAgreed}
      handleAgreement={handleAgreement}
    />
  );
};

export default OnboardingTermsAndConditions;
