import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@material-ui/core';
import React, { FC } from 'react';

import { ConditionField, ConditionFieldProps } from '../condition-field';

type Props = {
  conditionsFields: ConditionFieldProps[],
  onRemove: () => void,
}

export const ConditionForm: FC<Props> = ({
  conditionsFields,
  onRemove
}) => (
  <Box style={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'center', alignItems: 'flex-end' }}>
    {conditionsFields.map((conditionFieldProps) => (
      <ConditionField {...conditionFieldProps} />
    ))}
    <IconButton
      style={{ padding: 0, borderRadius: 0 }}
      onClick={onRemove}
    >
      <FontAwesomeIcon icon="times" size="xs" />
    </IconButton>
  </Box>
);
