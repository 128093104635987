import { Chip } from '@material-ui/core';
import React from 'react';
import { CustomNodeElementProps } from 'react-d3-tree';

export const renderNodeWithCustomEvents = ({
  nodeDatum,
  onNodeClick,
}: CustomNodeElementProps) => {
  const x = nodeDatum.name === 'Order Parties' ? -120 : 10;
  return (
    <g>
      <foreignObject {...{ width: 300, height: 200, x, y: -15 }}>
        <Chip
          style={{ color: 'black' }}
          color={nodeDatum.attributes ? 'secondary' : 'default'}
          size="medium"
          label={nodeDatum.name}
          onClick={onNodeClick}
        />
      </foreignObject>
      <circle r={8} strokeWidth={0} fill="#263238" />
    </g>
  );
};
