import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NarrativeLoading } from './components/narrative-loading';
import { useNarrativeHooks } from './hooks';
import { useStyles } from './styles';

export const Narrative = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isLoading,
    narrative
  } = useNarrativeHooks();

  return (
    <Box className={classes.container}>
      <Paper className={classes.mainContainer}>
        <Typography className={classes.title}>{t('orders:narrative')}</Typography>
        <Box className={classes.narrativeContainer}>
          {isLoading && (<NarrativeLoading />)}
          {!isLoading && (
          <Typography
            variant="body1"
            color="primary"
            className={classes.narrativeText}
          >
            {narrative}
          </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
