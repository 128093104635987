import {
  getOrderDocuments,
  GQLGetOrderDocuments,
} from 'graphql/documents/queries';
import { getOrderAccounting } from 'graphql/ledger/queries';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getAccountingEntriesByKind } from 'utils/accounting';
import {
  PARTY_KIND_ATTORNEY,
  PARTY_KIND_BUYER,
  PARTY_KIND_LENDER,
  PARTY_KIND_ORDER_OPENER,
  PARTY_KIND_SELLER,
} from 'utils/constants';
import { NewOrderPartyType } from 'v2-types/order';

import { filterPartiesByKind, getPartyByKind } from './services';

type UseOrderOverviewParams = {
  parties: NewOrderPartyType[];
};

export const useOrderOverview = ({ parties }: UseOrderOverviewParams) => {
  const { id: orderId } = useParams<{ id: string }>();
  const orderOpener = getPartyByKind({
    parties,
    kind: PARTY_KIND_ORDER_OPENER,
  });
  const sellers = filterPartiesByKind({ parties, kind: PARTY_KIND_SELLER });
  const buyers = filterPartiesByKind({ parties, kind: PARTY_KIND_BUYER });
  const attorneys = filterPartiesByKind({ parties, kind: PARTY_KIND_ATTORNEY });
  const lenders = filterPartiesByKind({ parties, kind: PARTY_KIND_LENDER });

  const { data } = useQuery(
    ['accounting-overview', orderId],
    () => getOrderAccounting({ id: orderId }),
    { refetchOnWindowFocus: false }
  );
  const { data: dataDocuments } = useQuery<GQLGetOrderDocuments>(
    ['order-documents', orderId],
    () => getOrderDocuments({ id: orderId }),
    { refetchOnWindowFocus: false, }
  );
  return {
    orderOpener,
    sellers,
    buyers,
    lenders,
    attorneys,
    accountingBalance: getAccountingEntriesByKind({ accounting: data?.getOrder.accounting ?? [], kind: 'balance' }),
    accountingPremium: getAccountingEntriesByKind({ accounting: data?.getOrder.accounting ?? [], kind: 'premium' }),
    orderDocuments: dataDocuments?.getOrder.files
  };
};
