import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SaveButton from 'components/common/save-button';
import DatePicker from 'components/date-picker/date-picker';
import NumberField from 'components/number-field';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React from 'react';
import { Control, Controller, UseFormReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { DisabledFields } from 'types/disabledFields';
import { listingItemtype } from 'types/ledger';
import { AccountingType } from 'v2-types/order';

import { EntryLedgerType } from './services/types';

const useStyles = makeStyles((theme:any) => ({
  container: { padding: theme.spacing(3) },
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  icon: {
    fontSize: '16px',
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  itempaddinType: {
    '& > * ': { marginTop: theme.spacing(2) },
    padding: theme.spacing(3),
    margin: 0,
  },
  itempaddinfoother: { '& > * ': { marginLeft: theme.spacing(2) } },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nocontendPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  removecontentPadding: { '& .MuiDialogContent-root': { padding: 0 } }
}));

type AddReceiptProps = {
  open:boolean,
  onClose: ()=>void,
  handleSubmit:(e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>,
  control: Control<EntryLedgerType, object>,
  allowedCodes:listingItemtype[],
  allowedKinds:listingItemtype[],
  allowedLetters:listingItemtype[],
  errors:string[],
  prevData: AccountingType | null,
  t:TFunction;
  loadingEditEntry: boolean,
  loadingAddNewEntry: boolean,
  handleResetValue:()=> void,
  methods:UseFormReturn<EntryLedgerType, object>,
  disableFields?: DisabledFields<EntryLedgerType>
  hideFields?: DisabledFields<EntryLedgerType>
}

const AddEntryScene = ({
  open,
  onClose,
  handleSubmit,
  control,
  allowedCodes,
  allowedKinds,
  allowedLetters,
  errors,
  prevData,
  t,
  loadingEditEntry,
  loadingAddNewEntry,
  handleResetValue,
  methods,
  disableFields,
  hideFields
}:AddReceiptProps) => {
  const classes = useStyles();
  const prevDataisManual = prevData?.kind?.includes('disbursement')
  || prevData?.kind?.includes('manual')
  || prevData?.kind?.includes('receipt');
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      className={classes.removecontentPadding}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.nocontendPadding}>
          <Grid
            container
            className={classes.itempaddinType}
            direction="column"
          >
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: 'black' }}>
                {`${prevData?._id ? 'Edit Ledger Entry' : 'Add New Ledger Entry'}`}
              </Typography>
            </Grid>
            {!!errors.length && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                classes={{ icon: classes.errorIconContainer }}
              >
                {errors.map((error) => (
                  <Typography variant="subtitle1">{error}</Typography>
                ))}
              </Alert>
            </Grid>
            )}
            {!hideFields?.amount_override && (
            <Controller
              name="amount_override"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <NumberField
                  {...field}
                  customRef={ref}
                  label={t('accounting:amount-override')}
                  disabled={!!disableFields?.amount_override}
                />
              )}
            />
            )}

            {!hideFields?.amount && (
            <Controller
              name="amount"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('accounting:amount')}
                  disabled={!!disableFields?.amount}
                />
              )}
            />
            )}

            {!hideFields?.description && (
            <Controller
              name="description"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label={t('accounting:description')}
                  error={!!methods.formState.errors.description}
                  disabled={!!disableFields?.description}
                />
              )}
            />
            )}

            {!hideFields?.code && (
            <Controller
              name="code"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <SelectField
                  {...field}
                  inputRef={ref}
                  value={allowedCodes.length > 0 ? field.value : ''}
                  data={allowedCodes}
                  dataKey="code"
                  dataValue="code"
                  dataText="description"
                  label={t('accounting:code')}
                  handleChange={onChange}
                  error={!!methods.formState.errors.description}
                  disabled={!!disableFields?.code}
                />
              )}
            />
            )}
            {!hideFields?.kind && (
            <Controller
              name="kind"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <SelectField
                  {...field}
                  inputRef={ref}
                  value={allowedKinds.length > 0 ? field.value : ''}
                  data={allowedKinds}
                  dataKey="code"
                  dataValue="code"
                  dataText="description"
                  label={t('accounting:kind')}
                  handleChange={onChange}
                  error={!!methods.formState.errors.description}
                  disabled={!!disableFields?.kind}
                />
              )}
            />
            )}

            {!hideFields?.letter && (
            <Controller
              name="letter"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <SelectField
                  {...field}
                  inputRef={ref}
                  value={allowedLetters.length > 0 ? field.value! : ''}
                  data={allowedLetters}
                  dataKey="code"
                  dataValue="code"
                  dataText="description"
                  label={t('accounting:letter')}
                  handleChange={onChange}
                  disabled={!!disableFields?.letter}
                />
              )}
            />
            )}

            {!hideFields?.number && (
            <Controller
              name="number"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <NumberField
                  {...field}
                  customRef={ref}
                  label={t('accounting:number')}
                  decimalScale={0}
                  isAllowed={({ floatValue = 0 }) => floatValue <= 99999}
                  error={!!methods.formState.errors.number}
                  disabled={!!disableFields?.number}
                />
              )}
            />
            )}

            {!hideFields?.entryDate && (
            <Controller
              name="entryDate"
              control={control}
              render={({ field: { ref, value, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  disableToolbar={false}
                  label={t('accounting:entry-date')}
                  value={value as string | Date}
                  disabled={!!disableFields?.entryDate}
                />
              )}
            />
            )}

            {!hideFields?.annuality && (
            <Controller
              name="annuality"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <NumberField
                  {...field}
                  customRef={ref}
                  label={t('accounting:annuality')}
                  disabled={!!disableFields?.annuality}
                />
              )}
            />
            )}

            {!hideFields?.months && (
            <Controller
              name="months"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <NumberField
                  {...field}
                  customRef={ref}
                  label="Months per year"
                  decimalScale={0}
                  error={!!methods.formState.errors.months}
                  disabled={!!disableFields?.months}
                />
              )}
            />
            )}

            {!hideFields?.months_advanced && (
            <Controller
              name="months_advanced"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <NumberField
                  {...field}
                  customRef={ref}
                  label="Payed in Advance"
                  decimalScale={0}
                  error={!!methods.formState.errors.months_advanced}
                  disabled={!!disableFields?.months_advanced}
                />
              )}
            />
            )}
          </Grid>

        </DialogContent>
        <DialogActions disableSpacing style={{ padding: 16 }} className={classes.itempaddinfoother}>
          <Button
            color="primary"
            variant="outlined"
            disableElevation
            onClick={onClose}
          >
            <Typography variant="body2" className={classes.text}>
              {t('common:close')}
            </Typography>
          </Button>
          {prevData?._id && !prevDataisManual && (
          <Button
            variant="contained"
            onClick={handleResetValue}
            disableElevation
          >
            <Typography variant="body2" className={classes.text}>
              {t('accounting:reset-value')}
            </Typography>
          </Button>
          )}
          <SaveButton
            type="submit"
            customText={`${prevData?._id ? t('accounting:edit-entry') : t('accounting:add-entry')}`}
            loading={loadingAddNewEntry || loadingEditEntry}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEntryScene;
