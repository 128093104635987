import DateFnsUtils from '@date-io/date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  input: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
  },
  naked: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-input': {
      paddingTop: 10,
      paddingLeft: 0,
    },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: 'transparent',
    },
  },
  icon: { padding: 0 },
}));

type DatePickerProps = {
  id?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  disableToolbar?: boolean;
  format?: string;
  color?: 'primary' | 'secondary' | undefined;
  inputVariant?: 'filled' | 'standard' | 'outlined' | undefined;
  naked?: boolean;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  value: Date | string | null;
  error?: boolean | undefined;
  helperText?: React.ReactNode;
  placeholder?:string;
  handleBlur?: any;
  onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
  InputLabelProps?:any
  InputProps?: any;
  inputRef?: any;
};

const DatePicker: FC<DatePickerProps> = ({
  id,
  name,
  label,
  disabled,
  disableToolbar,
  format,
  color,
  inputVariant,
  naked,
  minDate,
  maxDate,
  value,
  error,
  helperText,
  placeholder,
  handleBlur,
  onChange,
  InputLabelProps,
  InputProps,
  inputRef,
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        disableToolbar={disableToolbar}
        id={id}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        label={label}
        className={clsx({ [classes.input]: !naked, [classes.naked]: naked })}
        color={color}
        format={format}
        inputVariant={inputVariant}
        minDate={minDate}
        maxDate={maxDate}
        value={value !== '' ? value : null}
        error={error}
        helperText={helperText}
        onBlur={handleBlur}
        onChange={onChange}
        KeyboardButtonProps={{ className: classes.icon }}
        InputLabelProps={InputLabelProps}
        InputProps={{ ...InputProps, disableUnderline: naked }}
        keyboardIcon={(
          <InputAdornment position="end">
            <FontAwesomeIcon icon="calendar-alt" size="xs" />
          </InputAdornment>
        )}
        inputRef={inputRef}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.defaultProps = {
  id: undefined,
  label: undefined,
  disabled: false,
  disableToolbar: true,
  format: 'MM/dd/yyyy',
  color: 'primary',
  inputVariant: 'filled',
  naked: false,
  minDate: undefined,
  maxDate: undefined,
  error: false,
  helperText: undefined,
  placeholder: '',
  handleBlur: undefined,
  InputLabelProps: undefined,
  InputProps: undefined,
  inputRef: undefined,
};

export default DatePicker;
