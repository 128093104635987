import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

type GetNarrativePayload = {
  orderId: string
}

type GQLGetNarrativeResponse = {
  getOrder: {
    narrative: string
  }
}

export const getNarrative = async (payload: GetNarrativePayload) => {
  const query = gql`
    query GetOrder($orderId: ID!) {
      getOrder(_id: $orderId) {
        narrative
      }
    }
  `;

  const response = await graphQLClient().request<GQLGetNarrativeResponse>(query, payload);
  return response;
};
