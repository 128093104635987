import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { InterestKindEnum, Loan } from 'v2-types/order';

import { loansQuery } from './queries';

type CreateLoanParams = {
  orderId: string,
  estateId: string,
  loanNumber: string
}

export type CreateLoanReturn = {
  createOrderLoan: {
    loans: Loan[]
  }
}

export const createLoan = async (payload: CreateLoanParams) => {
  const query = gql`
  mutation CreateOrderLoan($orderId: ID!, $estateId: ID!, $loanNumber: String!) {
    createOrderLoan(_id: $orderId, estate_id: $estateId, loan_number: $loanNumber) {
      ${loansQuery}
    }
  }
  `;
  const response = await graphQLClient().request<CreateLoanReturn, CreateLoanParams>(query, payload);
  return response;
};

type DeleteLoanInterestParams = {
  orderId: string,
  loanId: string,
  interestId: string
}

export type DeleteLoanInteresReturn = {
  deleteOrderLoanInterest: {
    loans: Loan[]
  }
}

export const deleteLoanInterest = async (payload: DeleteLoanInterestParams) => {
  const query = gql`
    mutation DeleteOrderLoanInterest($orderId: ID!, $loanId: ID!, $interestId: ID!) {
    deleteOrderLoanInterest(_id: $orderId, loan_id: $loanId, interest_id: $interestId) {
      ${loansQuery}
    }
  }
  `;
  const response = await graphQLClient().request<DeleteLoanInteresReturn, DeleteLoanInterestParams>(query, payload);
  return response;
};

type CreateLoanInterestParams = {
  orderId: string,
  loanId: string,
  kind: InterestKindEnum,
  rate?: number
  index_name?: string,
  margin?: number,
  fee?: number,
  period_end?: string,
  period_start?: string,
  change_date?: string,
  change_max?: number,
  change_min?: number,
  months?: number,
  rate_max?: number,
  rate_min?: number
  dataOnly?: {},
  dataAdjustable?: {
  },
  dataConversion?: {

  },
  dataFirst?: {
  },
  dataFixed?: {
  },
  dataSubsequent?: {
  },
  dataVariable?: {}
}

type CreateInterestPayload = {
  orderId: string,
  loanId: string,
  dataOnly?: {},
  dataAdjustable?: {},
  dataConversion?: {},
  dataFirst?: {},
  dataFixed?: {},
  dataSubsequent?: {}
  dataVariable?: {}
}

export type CreateLoanInteresReturn = {
  createOrderLoanInterest: {
    loans: Loan[]
  }
}

export const createLoanInterest = async ({
  kind,
  orderId,
  loanId,
  ...rest
}: CreateLoanInterestParams) => {
  let mutationParamType = '';
  let mutationVariableSet = '';
  const payload: CreateInterestPayload = {
    orderId,
    loanId
  };

  switch (kind) {
    case InterestKindEnum.adjustable: {
      mutationParamType = '$dataAdjustable: OrderInterestAdjustableContent';
      mutationVariableSet = 'data_adjustable: $dataAdjustable';
      payload.dataAdjustable = { ...rest };
      break;
    }
    case InterestKindEnum.conversion: {
      mutationParamType = '$dataConversion: OrderInterestConversionContent';
      mutationVariableSet = 'data_conversion: $dataConversion';
      payload.dataConversion = { ...rest };
      break;
    }
    case InterestKindEnum.first: {
      mutationParamType = '$dataFirst: OrderInterestFirstContent';
      mutationVariableSet = 'data_first: $dataFirst';
      payload.dataFirst = { ...rest };
      break;
    }
    case InterestKindEnum.fixed: {
      mutationParamType = '$dataFixed: OrderInterestFixedContent';
      mutationVariableSet = 'data_fixed: $dataFixed';
      payload.dataFixed = { ...rest };
      break;
    }
    case InterestKindEnum.subsequent: {
      mutationParamType = '$dataSubsequent: OrderInterestSubsequentContent';
      mutationVariableSet = 'data_subsequent: $dataSubsequent';
      payload.dataSubsequent = { ...rest };
      break;
    }
    case InterestKindEnum.variable: {
      mutationParamType = '$dataVariable: OrderInterestVariableContent';
      mutationVariableSet = 'data_variable: $dataVariable';
      payload.dataVariable = { ...rest };
      break;
    }
    default: {
      mutationParamType = '$dataOnly: OrderInterestOnlyContent';
      mutationVariableSet = 'data_only: $dataOnly';
      payload.dataOnly = { ...rest };
    }
  }

  const query = gql`
    mutation CreateOrderLoanInterest($orderId: ID!, $loanId: ID!, ${mutationParamType}) {
      createOrderLoanInterest(_id: $orderId, loan_id: $loanId, ${mutationVariableSet}) {
      ${loansQuery}
    }
  }
  `;
  const response = await graphQLClient().request<CreateLoanInteresReturn, CreateInterestPayload>(query, payload);
  return response;
};

type DeleteLoanParams = {
  orderId: string,
  loanId: string
}

export type DeleteLoanReturn = {
  deleteOrderLoan: {
    loans: Loan[]
  }
}

export const deleteLoan = async (payload: DeleteLoanParams) => {
  const query = gql`
    mutation DeleteOrderLoan($orderId: ID!, $loanId: ID!) {
    deleteOrderLoan(_id: $orderId, loan_id: $loanId) {
      ${loansQuery}
    }
  }
  `;
  const response = await graphQLClient().request<DeleteLoanReturn, DeleteLoanParams>(query, payload);
  return response;
};

type UpdateLoanParams = {
  orderId: string,
  loanId: string,
  data: Partial<Loan>
}

export type UpdateLoanReturn = {
  updateOrderLoan: {
    loans: Loan[]
  }
}

export const updateLoan = async (payload: UpdateLoanParams) => {
  const query = gql`
  mutation UpdateOrderLoan($orderId: ID!, $loanId: ID!, $data: OrderLoanContent!) {
    updateOrderLoan(_id: $orderId, loan_id: $loanId, data: $data) {
      ${loansQuery}
    }
  }
  `;
  const response = await graphQLClient().request<UpdateLoanReturn, UpdateLoanParams>(query, payload);
  return response;
};
