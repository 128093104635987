import { Button } from '@material-ui/core';
import React from 'react';
import { Column } from 'types/common';
import { NewUserType } from 'types/new-user';

import RowText from '../component/row-text';

export type ColumnsType = { [key: string]: Column };

export type scheduleMettingTokens = {
  id:string;
  parties:any[];
  date:string;
  init_time:string;
  end_time:string
}

export type tableRowComponent = {
  party_info?: NewUserType;
  url: string;
  token: string;
}

 type RendererParams = {
  row: tableRowComponent;
  column: Column;
  index: number;
  indexRow?:number
};

export const generateLenderCreditsColumns = (
  t,
  theme,
  onCopy
): ColumnsType => ({
  Email: {
    header: {
      title: 'Email',
      align: 'center',
      textColor: theme.palette.primary.light
    },
    renderer: ({ row }: RendererParams) => (
      <RowText text={row.party_info?.username || t('ron:not-email')} />
    )
  },
  Name: {
    header: {
      title: 'Name',
      align: 'center',
      textColor: theme.palette.primary.light
    },
    renderer: ({ row }: RendererParams) => (
      <RowText text={row?.party_info?.name || ''} />
    )
  },
  kind: {
    header: {
      title: 'Url',
      align: 'center',
      textColor: theme.palette.primary.light
    },
    renderer: ({ row }: RendererParams) => (
      <RowText text={row.url} isURL />
    )
  },
  copy: {
    header: {
      title: 'Actions',
      align: 'center',
      textColor: theme.palette.primary.light
    },
    renderer: ({ row }: RendererParams) => (
      <Button onClick={() => onCopy(row.url)}>
        <RowText text="Copy url" />
      </Button>
    )
  },
});
