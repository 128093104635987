import { Box } from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import React, { FC } from 'react';

import { ModalData } from '../../types';

type Props = {
  isDataLoading: boolean,
  isUpdateLoading: boolean,
  isFormDirty: boolean,
  setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>,
  onSave: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

export const LoanActions: FC<Props> = ({
  isDataLoading,
  isUpdateLoading,
  isFormDirty,
  setModalData,
  onSave
}) => (
  <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
    <ContainedButton
      text="Delete Loan"
      icon="trash"
      disabled={isDataLoading || isUpdateLoading}
      onClick={() => setModalData({ modal: 'deleteLoan' })}
    />
    <ContainedButton
      text="Save"
      icon="save"
      disabled={!isFormDirty || isDataLoading}
      isLoading={isUpdateLoading}
      onClick={onSave}
    />
  </Box>
);
