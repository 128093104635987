import { deleteOrderLedgerAssociation } from 'graphql/ledger/mutations';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { AccountingFilter, AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import { filterPartiesByKindList } from './utils';

type Params = {
  parties: AccountingInvolvedParty[],
  kindList?: string[],
  accountingId: string,
  accountingFilter: AccountingFilter,
  removeSuccessMessage: string,
  onDeleteAssociationSuccess: (data: AccountingType[]) => void
}

export const useRemoveAssociationModal = ({
  parties,
  kindList,
  accountingId,
  accountingFilter,
  removeSuccessMessage,
  onDeleteAssociationSuccess
}: Params) => {
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const [filteredParties, setFilteredParties] = useState(filterPartiesByKindList(parties, kindList));

  const [deleteAssociation, { isLoading: isRemoveAssociationLoading }] = useMutation(deleteOrderLedgerAssociation, {
    onSuccess: (data) => {
      showAlert(removeSuccessMessage, 'success');
      const accountingFromServer = data.deleteOrderLedgerAssociation.accounting;
      const accountingEntry = accountingFromServer.find((entry) => entry._id === accountingId);

      onDeleteAssociationSuccess(accountingFromServer);
      if (accountingEntry) {
        setFilteredParties(filterPartiesByKindList(accountingEntry.involved_parties, kindList));
      }
    }
  });

  const removeAssociation = (party: AccountingInvolvedParty) => {
    deleteAssociation({
      orderId,
      filters: { accounting: accountingFilter },
      kind: party.kind,
      accountingId,
      partyId: party._id
    });
  };

  return { filteredParties, removeAssociation, isRemoveAssociationLoading };
};
