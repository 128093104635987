import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { ContactKind } from '../types';

const useStyles = makeStyles((theme: any) => ({
  container: {
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  icon: {
    width: 70,
    height: 80,
    marginTop: theme.spacing(5),
    color: theme.palette.background.component,
  },
  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    color: theme.palette.text.light,
  },
  button: {
    textTransform: 'capitalize',
    marginBottom: theme.spacing(5),
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  divider: {
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[400],
  },
}));

type ContainerProps = {
  icon: 'building' | 'user' | 'file-signature';
  label: string;
  buttonText: string;
  onClick: () => void;
};

const Container: FC<ContainerProps> = ({
  icon,
  label,
  buttonText,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <FontAwesomeIcon icon={icon} size="7x" className={classes.icon} />
      </Grid>

      <Grid item>
        <Typography variant="h5" className={classes.title}>
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Button
          disableElevation
          size="small"
          color="secondary"
          variant="outlined"
          className={classes.button}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  );
};

type EmptyContainerProps = {
  t: TFunction;
  handleUserCreateModal: (state: boolean, kind: ContactKind) => void;
};

const EmptyContainer = ({ t, handleUserCreateModal }: EmptyContainerProps) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid item xs={4}>
        <Container
          icon="building"
          label={t('users:create-com-label')}
          buttonText={t('users:create-com')}
          onClick={() => handleUserCreateModal(true, 'Company')}
        />
      </Grid>

      <Grid item xs={4} className={classes.divider}>
        <Container
          icon="file-signature"
          label={t('users:create-org-label')}
          buttonText={t('users:create-org')}
          onClick={() => handleUserCreateModal(true, 'Organization')}
        />
      </Grid>

      <Grid item xs={4} className={classes.divider}>
        <Container
          icon="user"
          label={t('users:create-party-label')}
          buttonText={t('users:add-party')}
          onClick={() => handleUserCreateModal(true, 'Person')}
        />
      </Grid>
    </Grid>
  );
};

export default EmptyContainer;
