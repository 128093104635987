import './styles/index.css';

import { useEffect } from 'react';

type UseWordHighlight = {
  container: HTMLDivElement | null,
  regexSearchWords: string
}

export const useWordHighlight = ({ container, regexSearchWords }: UseWordHighlight) => {
  useEffect(() => {
    if (container !== null) {
      if (regexSearchWords.length > 0) {
        const elements = container.getElementsByClassName('search-value');
        Array.from(elements).forEach((element) => {
          const text = element.textContent;
          if (text && text.length > 0) {
            const safeText = text.replace(/[^a-zA-Z0-9 ]/g, '\\$&');
            if ((new RegExp(regexSearchWords)).test(safeText.toLowerCase())) {
              const words = safeText.split(/\s+/).map((word) => {
                if (new RegExp(regexSearchWords).test(word.toLowerCase())) {
                  return word.replace(new RegExp(regexSearchWords, 'gi'), '<span class="highlight-item">$&</span>');
                }
                return word;
              });

              element.innerHTML = words.join(' ').replace(/[\\+]/g, '');
            }
          }
        });
      } else {
        const elements = container.getElementsByClassName('highlight-item');
        Array.from(elements).forEach((element) => element.classList.remove('highlight-item'));
      }
    }
  }, [container, regexSearchWords]);

  return { searchWordClass: 'search-value' };
};
