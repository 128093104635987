import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import VoidTemplates from 'assets/images/edit-templates.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListTemplates } from './components/list-templates';
import { ModalEditTemplate } from './components/modal-edit-template';
import { ModalNewTemplate } from './components/modal-new-template';
import { TemplateSelected } from './components/template';
import useTemplates from './documents-templates-hooks';

const useStyles = makeStyles((theme: any) => ({
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  boldFont: {
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  listtitle: {},
  headerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  lettersPlaceholder: {
    color: theme.palette.primary[500],
    fontWeight: 400,
  },
}));

export const DocumentsTemplates = () => {
  const {
    autoTemplates,
    open,
    setOpen,
    partyRollsMemorized,
    handleCreateTemplate,
    handleDeleteTemplate,
    setSelectedTemplate,
    selectedTemplate,
    handleUpdateTemplate,
    setOpenEditModal,
    openEditModal,
    tagsField,
    tagsImages,
    tagsSignatures,
    codeTemplates,
  } = useTemplates();

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box p={2}>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={2} style={{ height: '91vh' }}>
          <ListTemplates
            templates={autoTemplates}
            setOpen={setOpen}
            handleDeleteTemplate={handleDeleteTemplate}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            t={t}
          />
        </Grid>
        {selectedTemplate ? (
          <Grid item xs={10} style={{ height: '100%' }}>
            <TemplateSelected
              partyRolls={partyRollsMemorized}
              template={selectedTemplate}
              handleUpdateTemplate={handleUpdateTemplate}
              setOpenEditModal={setOpenEditModal}
              t={t}
            />
          </Grid>
        ) : (
          <Box
            display="flex"
            flex="1"
            padding={8}
            style={{ height: 'calc(100vh - 70px)' }}
            alignItems="center"
            flexDirection="column"
          >
            <img src={VoidTemplates} alt="select-template" />
            <Typography variant="h5" className={classes.lettersPlaceholder}>
              {t('documents:select-template')}
            </Typography>
          </Box>
        )}
      </Grid>
      <ModalNewTemplate
        open={open}
        setOpen={setOpen}
        partyRolls={partyRollsMemorized}
        createTemplate={handleCreateTemplate}
        codeTemplates={codeTemplates}
        t={t}
      />
      {openEditModal && (
        <ModalEditTemplate
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          template={selectedTemplate}
          tagsField={tagsField}
          tagsImages={tagsImages}
          tagsSignatures={tagsSignatures}
        />
      )}
    </Box>
  );
};
