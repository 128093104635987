import React, { FC } from 'react';
import { OrderProperty } from 'types/order';
import {
  Box,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core';

import { FormProration } from './components/form-proration';
import { FormTaxInfo } from './components/form-tax-info';
import { Header } from './components/header';
import { Options } from './components/options';
import { Tabs } from './components/tabs';

import { useTaxesAndProrations } from './hooks';
import { useStyles } from './styles';
import SelectField from 'components/select-field';
import { FormProvider } from 'react-hook-form';
import { CreateProrationModal } from './components/create-proration-modal';
import { ProratedAmount } from './components/prorated-amount-form';
import { ProrateUsingForm } from './components/prorate-using-form';
import { useTranslation } from 'react-i18next';
import { DaysPerYearForm } from './components/days-per-year-form';
import { DebitedToForm } from './components/debited-to-form';
import { FlagsForm } from './components/flags-form';

type TaxesAndProrationsProps = {
  properties: OrderProperty[]
}

export const TaxesAndProrations: FC<TaxesAndProrationsProps> = ({ properties }) => {
  const {
    selectedProperty,
    selectProperty,
    prorationsForm,
    selectedTabIndex,
    setSelectedTabIndex,
    updateProrationsCache,
    modalData,
    setModalData,
    onSave,
    onDelete,
    isLoading
  } = useTaxesAndProrations({ properties });
  const classes = useStyles();

  const onClose = () => setModalData({ modal: '' });
  const { t } = useTranslation();

  return (
    <Box className={classes.rootContainer}>
      <Paper>
        <FormProvider {...prorationsForm}>
          <>
            <Header
              t={t}
              isValid
              disableAddProration={isLoading || selectedProperty === undefined}
              openCreateProrationModal={() => setModalData({ modal: "createProrationModal" })}
            />

            <Box className={classes.selectPropertyContainer}>
              <SelectField
                label="Properties"
                value={selectedProperty?._id ?? ''}
                data={properties.map((property) => ({
                  _id: property._id,
                  address: property.address.address,
                }))}
                dataKey="_id"
                dataValue="_id"
                dataText="address"
                handleChange={(e) => selectProperty(e.target.value)}
                disabled={isLoading}
              />
            </Box>

            <Box className={classes.content}>
              {(prorationsForm.getValues("forms").length > 0 && selectedProperty) ? (
                <>
                  <Box display="flex" justifyContent="space-between">
                    <Tabs
                      selectedTabIndex={selectedTabIndex}
                      onDelete={onDelete}
                      onSelectTab={(index) => setSelectedTabIndex(index)}
                      disabledDelete={isLoading}
                    />
                    <Options
                      onSave={onSave}
                      isLoading={isLoading}
                    />
                  </Box>
                  <Box className={classes.container}>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '40px', flex: 1 }}>
                      {selectedProperty && (
                        <FormTaxInfo
                          t={t}
                          selectedProperty={selectedProperty}
                          selectedTabIndex={selectedTabIndex}
                          isLoading={isLoading}
                        />
                      )}
                      <FormProration
                        t={t}
                        selectedTabIndex={selectedTabIndex}
                        isLoading={isLoading}
                      />
                      <ProratedAmount
                        selectedTabIndex={selectedTabIndex}
                        isLoading={isLoading}
                      />
                    </Box>

                    <Divider orientation="vertical" className={classes.dividerVertical} />

                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '40px', flex: 1 }}>
                      <ProrateUsingForm
                        selectedTabIndex={selectedTabIndex}
                        isLoading={isLoading}
                      />

                      <DaysPerYearForm
                        selectedTabIndex={selectedTabIndex}
                        isLoading={isLoading}
                      />

                      <DebitedToForm
                        selectedTabIndex={selectedTabIndex}
                        isLoading={isLoading}
                      />

                      <FlagsForm
                        selectedTabIndex={selectedTabIndex}
                        isLoading={isLoading}
                      />
                    </Box>
                  </Box>
                </>
              ) : <Typography>This property does not have taxes yet.</Typography>}
            </Box>
          </>
        </FormProvider>
      </Paper>
      {modalData.modal === "createProrationModal" && (
        <CreateProrationModal
          properties={properties}
          updateProrationsCache={updateProrationsCache}
          onClose={onClose}
        />
      )}
    </Box>
  );
};
