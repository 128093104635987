import './styles.css';

import {
  Map,
  Overlay,
  View
} from 'ol';
import { defaults, FullScreen } from 'ol/control';
import { Extent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import React, {
  forwardRef, memo,
  useEffect, useImperativeHandle, useRef, useState
} from 'react';

import { featureStyles } from './utils/featureStyle';

export type OlMapRefType = {
  selectFeatureById: ((id: string) => void) | null
}

export type FeaturePoint = {
  id: string,
  type: string,
  geometry: {
    coordinates: number[],
    type: string
  },
  properties?: {
    label: string
  }
}

type OlMapPropTypes = {
  features: FeaturePoint[],
  initZoom?: number,
  focusZoom?: number,
  onFeatureSelect?: (id: string) => void
}

const OlMapComponent = forwardRef<OlMapRefType, OlMapPropTypes>(
  (props, ref) => {
    const {
      features,
      initZoom = 5.5,
      focusZoom = 10,
      onFeatureSelect
    } = props;
    const htmlMapRef = useRef(null);
    const htmlTooltipRef = useRef<HTMLDivElement>(null);
    const [selectFeatureById, setSelectFeatureById] = useState<((id: string) => (void)) | null>(null);
    useGeographic();
    useImperativeHandle(ref, () => ({ selectFeatureById }), [selectFeatureById]);
    const mapRef = useRef<Map>();
    const vectorLayerRef = useRef<VectorLayer<VectorSource<Geometry>>>();
    const overlayRef = useRef<Overlay>();

    useEffect(() => {
      vectorLayerRef.current = new VectorLayer({
        style(feature) {
          return featureStyles[feature.getGeometry()!.getType()];
        }
      });

      mapRef.current = new Map({
        controls: defaults({
          attribution: false,
          rotate: false,
          zoomOptions: { className: 'ol-zoom-button-container' }
        }).extend([new FullScreen({ className: 'ol-full-screen-button' })]),
        layers: [
          new TileLayer({ source: new OSM() }),
          vectorLayerRef.current
        ],
        view: new View({
          projection: 'EPSG:4326',
          center: [-98.70607734127819, 32.4021682084251],
          zoom: initZoom
        }),
        target: htmlMapRef.current!
      });

      overlayRef.current = new Overlay({ element: htmlTooltipRef.current!, offset: [10, 20], positioning: 'top-center' });
      mapRef.current.addOverlay(overlayRef.current);

      setSelectFeatureById(
        () => (id: string) => {
          const featureList = vectorLayerRef.current!.getSource()?.getFeatures();
          const feature = featureList?.find((x) => x.getId() === id);
          let coordinates: Extent | undefined = [-98.70607734127819, 32.4021682084251];
          let zoom = initZoom;
          if (feature) {
            coordinates = feature.getGeometry()?.getExtent();
            zoom = focusZoom;

            const featureLabel: string = feature.get('label');
            if (featureLabel && featureLabel.length > 0) {
              htmlTooltipRef.current!.innerText = featureLabel;
              overlayRef.current!.setPosition(feature.getGeometry()?.getExtent());
            }
          }
          htmlTooltipRef.current!.style.display = feature ? 'block' : 'none';
          mapRef.current!.getView().setCenter(coordinates);
          mapRef.current!.getView().setZoom(zoom);
        }
      );
    }, []);

    useEffect(() => {
      if (vectorLayerRef.current && mapRef.current) {
        const vectorSource = new VectorSource({
          features: new GeoJSON().readFeatures({
            type: 'FeatureCollection',
            features
          })
        });
        vectorLayerRef.current.setSource(vectorSource);

        mapRef.current.on('click', (e) => {
          const feature = mapRef.current!.getFeaturesAtPixel(e.pixel)[0];
          if (!feature) return;
          mapRef.current!.getView().setCenter(feature.getGeometry()?.getExtent());
          mapRef.current!.getView().setZoom(focusZoom);
          if (onFeatureSelect && feature.getId()) {
            onFeatureSelect(feature!.getId()!.toString());
          }
          const featureLabel: string = feature.get('label');
          if (featureLabel && featureLabel.length > 0) {
            htmlTooltipRef.current!.style.display = 'block';
            htmlTooltipRef.current!.innerText = featureLabel;
            overlayRef.current!.setPosition(feature.getGeometry()?.getExtent());
          }
        });

        mapRef.current.on('pointermove', (e) => {
          const feature = mapRef.current!.getFeaturesAtPixel(e.pixel)[0];
          if (feature) {
            const featureLabel: string = feature.get('label');
            if (featureLabel && featureLabel.length > 0) {
              htmlTooltipRef.current!.innerText = featureLabel;
              overlayRef.current!.setPosition(feature.getGeometry()?.getExtent());
            }
          }
          htmlTooltipRef.current!.style.display = feature ? 'block' : 'none';
        });
      }
    }, [features, onFeatureSelect]);

    return (
      <div ref={htmlMapRef} id="map" className="map" style={{ height: '100%', width: '100%' }}>
        <div ref={htmlTooltipRef} className="tooltip" />
      </div>
    );
  }
);

export const OlMap = memo(OlMapComponent, (prevProps, nextProps) => {
  if (prevProps.features.length !== nextProps.features.length) {
    return false;
  }

  return true;
});
