import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Chip,
  Grid,
  makeStyles
} from '@material-ui/core';
import DatePicker from 'components/date-picker/date-picker';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { NewUserType } from 'types/new-user';
import { NewOrderPartyType, UpdateOrderSpecific } from 'types/order';

import PartySearch from '../party-search/party-search';

const useStyles = makeStyles((theme: any) => ({
  cardContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
  },
  containerItem: { padding: theme.spacing(3) },
  button: {
    textTransform: 'none',
    marginTop: theme.spacing(4),
    fontWeight: 500
  },
  byttonText: { marginLeft: theme.spacing(1) },
  paddingContent: { paddingTop: theme.spacing(3) },
  partySelection: {
    fontWeight: 500,
    color: theme.palette.common.black
  },
  partyItem: { padding: theme.spacing(1) },
  suubtitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500
  },
  tabsContainer: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  selectedagent: {
    backgroundColor: theme.palette.button.default,
    textTransform: 'none'
  },
  unseLectedagent: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'none'
  },
  iconSize: { fontSize: '0.875rem' },
  buttonLetter: {
    fontWeight: 500,
    color: theme.palette.common.black
  },
  titleagentlist: { marginTop: theme.spacing(3) },
  buttoncontainer: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end'
  },
  buttonDelete: { textTransform: 'none' },
}));

type PoaTabSceneProps = {
  t: TFunction,
  handleSearch: (text: string) => void,
  parties: NewUserType[],
  setPoatID: React.Dispatch<any>,
  poa: NewOrderPartyType | undefined;
  handleDeletePoa: () => void;
  methods: UseFormReturn<UpdateOrderSpecific, any>,
}

const PoaTabScene: FC<PoaTabSceneProps> = ({
  t,
  handleSearch,
  parties,
  setPoatID,
  poa,
  handleDeletePoa,
  methods,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container direction="column" className={classes.paddingContent}>

        {!poa ? (
          <PartySearch
            description={t('parties:poa-search')}
            labelField={t('parties:contact')}
            parties={parties}
            handleOnChange={handleSearch}
            handleItemSelect={setPoatID}
          />
        ) : (
          <>

            <Grid container direction="row" justifyContent="space-between" spacing={2}>
              <Grid item xs={6}>
                <Chip label={poa.name} />
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon="trash" size="xs" className={classes.iconSize} />}
                  className={classes.buttonDelete}
                  onClick={() => {
                    handleDeletePoa();
                  }}
                >
                  {t('parties:poa_remove')}
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="data_power_of_attorney.start_date"
                  control={methods.control}
                  render={({ field: { ref, value, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      value={value as string}
                      disableToolbar={false}
                      label={t('parties:poa_start')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="data_power_of_attorney.end_date"
                  control={methods.control}
                  render={({ field: { ref, value, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      value={value as string}
                      disableToolbar={false}
                      label={t('parties:poa_end')}
                    />
                  )}
                />
              </Grid>
            </Grid>

          </>

        )}
      </Grid>
    </>
  );
};

export default PoaTabScene;
