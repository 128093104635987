import React from 'react';
import { OrderProperty } from 'types/order';

import { useMapOverview } from './map-overview-hooks';
import { MapOverviewScene } from './map-overview-scene';

type MapOverviewProps = {
  properties: OrderProperty[]
}

const MapOverview: React.FC<MapOverviewProps> = ({ properties }) => {
  const {
    selectProperty,
    selectedProperty
  } = useMapOverview({ properties });

  return (
    <MapOverviewScene
      properties={properties}
      selectProperty={selectProperty}
      selectedProperty={selectedProperty}
    />
  );
};

export default MapOverview;
