import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const NarrativeLoading = () => (
  <>
    <Skeleton variant="text" height={30} width="90%" />
    <Skeleton variant="text" height={30} width="70%" />
    <Skeleton variant="text" height={30} width="80%" />
    <Skeleton variant="text" height={30} width="90%" />
    <Skeleton variant="text" height={30} width="70%" />
    <Skeleton variant="text" height={30} width="80%" />
  </>
);
