import { Box, Grid, RadioGroup, Typography } from "@material-ui/core";
import SelectField from "components/select-field";
import Radio from 'components/radio';
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProrationsForm } from "../types";
import { useStyles } from "../styles";
import { perDiemDecimals } from "../utils";

type Props = {
  selectedTabIndex: number
  isLoading: boolean
}

export const ProrateUsingForm: FC<Props> = ({
  selectedTabIndex,
  isLoading
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProrationsForm>();
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        {t('taxesAndProrations:prorate-using')}
      </Typography>
      <Box>
        <Controller
          control={control}
          key={`forms.${selectedTabIndex}.kind`}
          name={`forms.${selectedTabIndex}.kind`}
          render={({ field }) => (
            <>
              <RadioGroup
                value={field.value}
                onChange={(_, value) => field.onChange(value)}
              >
                <Grid container>
                  <Grid item>
                    <Radio
                      label={t('taxesAndProrations:installment-amount')}
                      value="installment"
                      disabled={isLoading}
                    />
                  </Grid>

                  <Grid item>
                    <Radio
                      label={t('taxesAndProrations:per-diem')}
                      value="per_diem"
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              {field.value === "per_diem" && (
                <Controller
                  control={control}
                  key={`forms.${selectedTabIndex}.decimals`}
                  name={`forms.${selectedTabIndex}.decimals`}
                  render={({ field: decimalsField }) => (
                    <SelectField
                      emptyValue={false}
                      name="per_diem_decimals"
                      label={t('taxesAndProrations:per-diem-decimals')}
                      value={decimalsField.value.toString()}
                      data={perDiemDecimals}
                      dataKey="value"
                      dataValue="value"
                      dataText="name"
                      disabled={isLoading}
                      handleChange={(event) => {
                        field.onChange(Number(event.target.value));
                      }}
                    />
                  )}
                />
              )}
            </>
          )}
        />
      </Box>
    </Box>
  );
};