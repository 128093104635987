import gqlRequest from 'graphql/generic';
import { PartyAccounting } from 'types/ledger';
import { AccountingType } from 'v2-types/order';

type GetOrder = {
  getOrder: {
    accounting: AccountingType[] | null;
    parties: PartyAccounting[]
  };
};

type GetOrderParams = {id: string, filters?:any };

const query = `
  query GetOrder($id: ID!,$filters: OrderFilters) {
    getOrder(_id: $id,filters: $filters) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        code
        kind
        letter
        number
        entry_date
        description
		months
		per_month
		accounted_for {
			amount
			percent
			record {
			  _id
			  code
			  number
			  amount
			  letter
			  description
			  involved_parties{
			    _id
				name
				kind
			  }
			}
		  }
        involved_parties {
          _id
          amount
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          name
          order_kinds
          payment_id
          payment_kind
          payment_reference
          percent
		  accounted_amount
		  accounted_percent
		  accounted_by {
			amount
			percent
			record {
				_id
			  description
			  code
			}
		  }
        }  
      }
      parties {
        _id
        kinds
        name
        accounts {
          _id
          kind
          reference
        }
      }
    }
  }
`;

export const getOrder = (id: string, filters?:any) => gqlRequest<GetOrder, GetOrderParams>(query, { id, filters });

export default {};
