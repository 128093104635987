import React from 'react';
import { Redirect } from 'react-router-dom';

import BackdropLoading from '../../components/common/BackdropLoading';
import useResetPassword from './reset-password-hooks';
import ResetPasswordScene from './reset-password-scene';

const ResetPassword = () => {
  const {
    t, resetPassword, history, isAuthenticated, isLoading
  } = useResetPassword();

  const handleSendEmail = (email, reset) => {
    resetPassword({ email });
    reset();
  };

  const backLogin = () => {
    history.push('/sign-in');
  };

  if (isLoading) {
    return <BackdropLoading open loadingText="loading" />;
  }
  if (isAuthenticated) {
    return <Redirect to="/orders" />;
  }

  return (
    <ResetPasswordScene
      t={t}
      handleSendEmail={handleSendEmail}
      backLogin={backLogin}
    />
  );
};

export default ResetPassword;
