import { Box, Grid, RadioGroup, Typography } from "@material-ui/core";
import Radio from "components/radio";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles";
import { ProrationsForm } from "../types";

type Props = {
  selectedTabIndex: number
  isLoading: boolean
}

export const DaysPerYearForm: FC<Props> = ({
  selectedTabIndex,
  isLoading
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProrationsForm>();
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        {t('taxesAndProrations:days-per-year')}
      </Typography>
      <Box>
        <Controller
          control={control}
          key={`forms.${selectedTabIndex}.days_year`}
          name={`forms.${selectedTabIndex}.days_year`}
          render={({ field }) => (
            <RadioGroup
              value={field.value}
              onChange={(_, value) => {
                field.onChange(Number(value));
              }}
            >
              <Grid container>
                <Grid item>
                  <Radio
                    label="360"
                    value={360}
                    disabled={isLoading}
                  />
                </Grid>

                <Grid item>
                  <Radio
                    label="365"
                    value={365}
                    disabled={isLoading}
                  />
                </Grid>

                <Grid item>
                  <Radio
                    label="366"
                    value={366}
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          )}
        />
      </Box>
    </Box>
  );
};