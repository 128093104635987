import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';
import { AutoTemplate } from 'types/auto-templates';

import { ConfirmDelete } from './modal-confirm-delete';

type ListTemplatesProps = {
  templates: AutoTemplate[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteTemplate: (id: string) => void;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<AutoTemplate | undefined>
  >;
  selectedTemplate: AutoTemplate | undefined;
  t: TFunction;
};

export const ListTemplates: FC<ListTemplatesProps> = ({
  templates,
  setOpen,
  handleDeleteTemplate,
  setSelectedTemplate,
  selectedTemplate,
  t,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTemplateID, setSelectedTemplateID] = useState('');

  return (
    <Paper
      style={{
        padding: '16px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{t('documents:list-templates')}</Typography>
        <Button
          variant="outlined"
          color="secondary"
          style={{ height: 28, width: 28 }}
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon icon="plus" />
        </Button>
      </Box>

      <Box style={{ height: '100%', overflow: 'scroll' }}>
        <List>
          {templates.map((template) => (
            <ListItem
              selected={selectedTemplate?._id === template._id}
              key={template._id}
              button
              onClick={() => setSelectedTemplate(template)}
            >
              <ListItemText primary={template.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => {
                    setSelectedTemplateID(template._id);
                    setOpenModal(true);
                  }}
                >
                  <FontAwesomeIcon icon="times-circle" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>

      <ConfirmDelete
        openModal={openModal}
        handleDeleteTemplate={() => handleDeleteTemplate(selectedTemplateID)}
        closeModal={() => setOpenModal(false)}
      />
    </Paper>
  );
};
