import { Collapse, TableCell } from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import { DisbursementCardRow } from 'components/DisbursementCardRow';
import React from 'react';
import { Column } from 'types/common';
import { PAYEE } from 'utils/constants';
import { formatMoney } from 'utils/helpers';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import { CellIndicator, CellText } from '../table-cells';
import CellOptions from '../table-cells/cell-options-custom';
import { CellWithAction } from '../table-cells/cell-with-action';
import CellWithTooltip from '../table-cells/cell-with-tooltip';
import { ChargeEntry } from './types';

export type columnsDataSet = {
  indexRow: number;
  id: string;
  description: string;
  payee: string;
  chargeData: AccountingType;
  seller_before_closing: number;
  seller_at_closing: number;
  buyer_before_closing: number;
  buyer_at_closing: number;
  by_others: number;
  number: number;
  others_description: string;
  months: number;
  per_month: number;
  tax?: AccountingInvolvedParty;
  pending_amount?: number;
  annuality?: number;
};

export type RendererParams = {
  row: columnsDataSet;
  column: Column;
  index: number;
  indexRow?: number;
  shouldCollapse?: boolean;
};

export type ColumnsType = { [key: string]: Column };

export const generateColumns = (
  t,
  theme,
  currentRowSelected,
  handleDeleteRow,
  handleEdit,
  handleCollapse,
  handlePayeePayer,
  handleTax,
  handleSinglePayeePayer,
  handleOpenRemoveAssociationModal
): ColumnsType => {
  const columns: ColumnsType = {
    actions: {
      header: {
        title: '',
        minWidth: '80px',
        width: '80px',
      },
      renderer: ({ row }: RendererParams) => (
        <Actionbuttons
          handleDelete={
            row?.chargeData?.kind.includes('manual')
              ? (e) => {
                e.stopPropagation();
                handleDeleteRow(row.id);
              }
              : undefined
          }
          handleEdit={(e) => {
            e.stopPropagation();
            handleEdit(row);
          }}
          handleCollapse={(e) => {
            e.stopPropagation();
            handleCollapse(row);
          }}
          handleParties={(e) => {
            e.stopPropagation();
            handlePayeePayer(row);
          }}
          handleTaxable={(e) => {
            e.stopPropagation();
            handleTax(row);
          }}
          handleIndividualPayeePayer={(e) => {
            e.stopPropagation();
            handleSinglePayeePayer(row);
          }}
        />
      ),
    },
    number: {
      header: { title: '' },
      renderer: ({ row, indexRow }: RendererParams) => (
        <CellIndicator
          isActive={indexRow === currentRowSelected?.indexRow}
          position={row?.number}
        />
      ),
    },
    description: {
      header: {
        title: t('charges:description'),
        align: 'left',
        width: '390px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellText
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          data={row.description}
        />
      ),
    },
    payee: {
      header: {
        title: 'Payee',
        align: 'left',
        width: '390px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellWithAction
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          text={row.payee}
          action={() => handleOpenRemoveAssociationModal(row, PAYEE)}
        />
      ),
    },
    amount: {
      header: {
        title: 'Amount Details',
        align: 'left',
        width: '390px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellText
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          data={`${formatMoney(row.chargeData.amount)} ${
            row.tax
              ? `(Tax: ${formatMoney(row.tax.amount)}, Pending:${formatMoney(
                row.pending_amount || 0
              )})`
              : ''
          }`}
        />
      ),
    },
    paid_borrower: {
      header: {
        title: t('charges:paid-by-borrower'),
        textColor: theme.palette.primary.light,
      },
      columns: [
        {
          header: {
            title: t('charges:at-closing'),
            width: '142px',
            textColor: theme.palette.primary.light,
            align: 'left'
          },
          renderer: ({ row }: RendererParams) => (
            <CellText
              position="left"
              backgroundColor="#FAFAFA"
              colorText="#4F5B62"
              data={formatMoney(row?.buyer_at_closing || 0)}
            />
          ),
        },
        {
          header: {
            title: t('charges:before-closing'),
            width: '142px',
            align: 'left',
            textColor: theme.palette.primary.light,
          },
          renderer: ({ row }: RendererParams) => (
            <CellText
              position="left"
              backgroundColor="#FAFAFA"
              colorText="#4F5B62"
              data={formatMoney(row?.buyer_before_closing || 0)}
            />
          ),
        },
      ],
    },
    paid_seller: {
      header: {
        title: t('charges:paid-by-seller'),
        textColor: theme.palette.primary.light,
      },
      columns: [
        {
          header: {
            title: t('charges:at-closing'),
            width: '142px',
            align: 'left',
            textColor: theme.palette.primary.light,
          },
          renderer: ({ row }: RendererParams) => (
            <CellText
              position="left"
              backgroundColor="#FAFAFA"
              colorText="#4F5B62"
              data={formatMoney(row?.seller_at_closing || 0)}
            />
          ),
        },
        {
          header: {
            title: t('charges:before-closing'),
            width: '142px',
            align: 'left',
            textColor: theme.palette.primary.light,
          },
          renderer: ({ row }: RendererParams) => (
            <CellText
              position="left"
              backgroundColor="#FAFAFA"
              colorText="#4F5B62"
              data={formatMoney(row?.seller_before_closing || 0)}
            />
          ),
        },
      ],
    },
    by_others: {
      header: {
        title: t('charges:by-others'),
        width: '142px',
        align: 'left',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellWithTooltip
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          data={formatMoney(row?.by_others || 0)}
          tooltipText={row?.others_description || 'Nothing to Show'}
          tooltipPlacement="top"
        />
      ),
    },
  };

  return columns;
};

export const generateFooterTable = (handleAddRow, t) => [
  {
    colspan: 4,
    renderer: () => <CellOptions handleAddrow={handleAddRow} />,
  },
  {
    colspan: 1,
    renderer: () => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={t('charges:totals')}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.borrower_total_at_closing)}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.borrower_total_before_closing)}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.seller_total_at_closing)}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.seller_total_before_closing)}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.total_by_others)}
      />
    ),
  },
];

type Params = {
  addDisbursement?: (chargeData: ChargeEntry) => void,
  showRemoveAssociationModal?: (chargeData: ChargeEntry) => void,
  showEditAssociationModal?: (chargeData: AccountingType, accountingPartyEntry: AccountingInvolvedParty) => void,
  showDeleteReceiptModalConfirm?: (chargeData: ChargeEntry) => void
};

export const generateCollapseRow = ({ addDisbursement, showRemoveAssociationModal, showEditAssociationModal, showDeleteReceiptModalConfirm }: Params): Column[] => [
  {
    renderer: ({ row, index, shouldCollapse }) => {
      const { chargeData } = row as {chargeData: AccountingType | undefined};
      return (
        <TableCell key={`collapse-column-${index}`} colSpan={8}>
          <Collapse in={shouldCollapse} timeout="auto" unmountOnExit>
            {chargeData && (
              <DisbursementCardRow
                entry={chargeData}
                addDisbursement={addDisbursement}
                showRemoveAssociationModal={showRemoveAssociationModal}
                showEditAssociationModal={showEditAssociationModal}
                showDeleteReceiptModal={showDeleteReceiptModalConfirm}
              />
            )}
          </Collapse>
        </TableCell>
      );
    },
  },
];

export const initialState = { type: null };

export const reducerModals = (state, action) => {
  switch (action.type) {
    case 'edit':
      return { type: 'edit', data: action.data };
    case 'add':
      return { type: 'add' };
    case 'disbursements':
      return { type: 'disbursements', data: action.data };
    case 'parties':
      return { type: 'parties', data: action.data };
    case 'tax':
      return { type: 'tax', data: action.data };
    case 'individual-payee-payer':
      return { type: 'individual-payee-payer', data: action.data };
    case 'remove-association':
      return { type: 'remove-association', data: action.data };
    case 'remove-association-payer-confirmation':
      return { type: 'remove-association-payer-confirmation', data: action.data };
    case 'delete-receipt-confirmation':
      return { type: 'delete-receipt-confirmation', data: action.data };
    default:
      return { type: null };
  }
};

export const getDisabledFields = (param:columnsDataSet) => {
  if (param?.chargeData !== undefined && !param?.chargeData?.kind?.includes('manual')) {
    return {
      letter: true,
      description: true,
    };
  }
  return { letter: true };
};
