import { debounce } from 'debounce';
import { getUser } from 'graphql/users/queries';
import {
  authorizeTransferRequest,
  createTransferRequest,
} from 'graphql/wire-transfers/mutations';
import {
  getAccountingData,
  getMembesrTitle,
  getWireTrannsfersResponse,
  GQLGetOrderAccounting,
  GQLGetOrderMember,
  GQLGetWireTransfers,
} from 'graphql/wire-transfers/queries';
import { useCallback, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { WireTransfersType } from 'types/wire-transfers';
import useAlert from 'utils/alert';
import { formatMoney } from 'utils/helpers';

import { AccountingDataFiltered, SearchParam, SortBy } from './types';
import { filterWireTransferList, getComparationFn } from './utils';

const useWireTransfers = ({ idTitleCompany }) => {
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const searchParamsRef = useRef<SearchParam>({ status: '' });
  const sortyByRef = useRef<SortBy>({ property: '' });
  const amountFilterRef = useRef<{ min?: number, max?: number }>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [wireTransferPages, setWireTransferPages] = useState<Array<WireTransfersType[]>>([]);
  const [userEmail, setUserEmail] = useState<string>('');
  const queryCache = useQueryCache();

  const [open, setOpen] = useState<{
    status: boolean,
    location: string,
    data: WireTransfersType | undefined
  }>({
    status: false,
    location: '',
    data: undefined,
  });

  useQuery(
    ['getCurrentUser'],
    getUser,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.getUser.party.username) {
          setUserEmail(data.getUser.party.username);
        }
      }
    },
  );

  const wireTransfersResponse = useQuery<GQLGetWireTransfers>(
    ['wireTransfers', orderId],
    () => getWireTrannsfersResponse({ orderId }),
    {
      onSuccess(data) {
        paginateWireTransfers({ wireTransfers: data.getTransfers ?? [], transfersPerPage: rowsPerPage });
      },
      refetchOnWindowFocus: false
    }
  );

  const accountingDataResponse = useQuery<GQLGetOrderAccounting>(
    ['accounting', orderId],
    () => getAccountingData({ id: orderId }),
    { refetchOnWindowFocus: false }
  );

  const membersResponse = useQuery<GQLGetOrderMember>(
    ['members', orderId],
    () => getMembesrTitle({ id: idTitleCompany }),
    { refetchOnWindowFocus: false }
  );

  const [createTransfer] = useMutation(createTransferRequest, {
    onSuccess: () => {
      setTimeout(() => {
        wireTransfersResponse.refetch();
        showAlert('Transfer was created successfully', 'success');
      }, 1000);
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
    },
  });

  const [authorizeTransfer] = useMutation(authorizeTransferRequest, {
    onSuccess: () => {
      setTimeout(() => {
        wireTransfersResponse.refetch();
        showAlert('Authorization responded successfully', 'success');
      }, 1000);
    },
    onError: () => {
      showAlert('An error occurred, try again', 'error');
    },
  });

  const { getOrder } = accountingDataResponse.data || {};

  const { getParty } = membersResponse.data || {};

  const accountingDataFilter: AccountingDataFiltered[] = getOrder?.accounting?.filter((accounting) => {
    if (
      accounting.involved_parties
          && accounting.involved_parties.length > 1
    ) {
      const payee = accounting.involved_parties.find(
        (party) => party.kind.includes('Payee') && party.payment_kind === 'wire'
      );
      const payer = accounting.involved_parties.find(
        (party) => party.kind.includes('Payer')
              && party.name.includes('Championship Title')
      );
      return payee && payer;
    }
    return false;
  })
    .flatMap((accounting) => {
      const payer = accounting.involved_parties.find(
        (party) => party.kind.includes('Payer')
            && party.name.includes('Championship Title')
      );
      return accounting.involved_parties
        .filter(
          (party) => party.payment_kind && party.payment_kind.includes('wire')
        )
        .map((party) => {
          const payerAmount = payer?.amount || 0;
          const amount = party?.amount > payerAmount ? payer?.amount : party.amount;

          return {
            description: `${accounting.description} - ${formatMoney(
                amount as number
            )}`,
            _id: accounting._id,
            amount,
            association_id: party?._id,
          };
        });
    }) || [];

  const handleCreateWire = (authorization: string[], accounting: AccountingDataFiltered[]) => {
    const instructions = accounting.map((account) => ({
      accounting_id: account._id,
      amount: Number(account.amount),
      association_id: account.association_id,
    }));
    createTransfer({
      orderId,
      instructions,
      authorization,
    });
  };

  const handleAuthTransfer = (action) => {
    authorizeTransfer({ orderId, reference: open.data?.reference ?? '', action });
  };

  const handleToggleModals = (location: string = '', data: WireTransfersType | undefined = undefined) => {
    setOpen({ status: !open.status, location, data });
  };

  const searchWireTransfers = useCallback(() => {
    setOpenBackdrop(true);
    const { status } = searchParamsRef.current;
    const { property, operator = '' } = sortyByRef.current;
    const { min: amountMin, max: amountMax } = amountFilterRef.current;

    const wireTransferListCache = queryCache.getQueryData<GQLGetWireTransfers>(['wireTransfers', orderId])?.getTransfers || [];
    const wireTransferListFiltered = filterWireTransferList({
      wireTransferList: wireTransferListCache,
      status,
      amountMin,
      amountMax
    });

    const comparationFn = getComparationFn({ property, operator });
    if (comparationFn) {
      wireTransferListFiltered.sort(comparationFn);
    }

    paginateWireTransfers({ wireTransfers: wireTransferListFiltered, transfersPerPage: rowsPerPage });
    setOpenBackdrop(false);
  }, [queryCache]);

  const debounceSearch = debounce(searchWireTransfers, 1000);

  const onFilterChange = ({ value, paramKey }: { value: string, paramKey: keyof SearchParam }) => {
    debounceSearch.clear();
    searchParamsRef.current[paramKey] = value;
    debounceSearch();
  };

  const onSort = ({ property, operator = 'descending' }: SortBy) => {
    sortyByRef.current = {
      property,
      operator
    };
    searchWireTransfers();
  };

  const onUpdateAmountLimits = ({ min, max }: { min?: number, max?: number }) => {
    amountFilterRef.current = {
      min,
      max
    };

    searchWireTransfers();
  };

  const clearFilters = () => {
    searchParamsRef.current.status = '';
    sortyByRef.current = { property: '' };
    amountFilterRef.current = {};

    searchWireTransfers();
  };

  const paginateWireTransfers = ({ wireTransfers, transfersPerPage }: { wireTransfers: WireTransfersType[], transfersPerPage: number }) => {
    const listLength = wireTransfers.length;
    const totalPagesResult = Math.ceil(listLength / transfersPerPage);
    const paginationList: Array<WireTransfersType[]> = [];

    Array.from(Array(totalPagesResult).keys()).forEach((index) => {
      const page = wireTransfers.slice(index * transfersPerPage).slice(0, transfersPerPage);
      paginationList.push(page);
    });

    setTotalPages(totalPagesResult);
    setTotalRows(listLength);
    setRowsPerPage(transfersPerPage);
    setCurrentPage(1);
    setWireTransferPages(paginationList);
  };

  const changeRowsPerPage = ({ rows }: { rows: number }) => {
    const wireTransferListCache = queryCache.getQueryData<GQLGetWireTransfers>(['wireTransfers', orderId])?.getTransfers || [];
    paginateWireTransfers({ wireTransfers: wireTransferListCache, transfersPerPage: rows });
  };

  return {
    wireTransferPages,
    currentPage,
    totalPages,
    rowsPerPage,
    totalRows,
    setCurrentPage,
    changeRowsPerPage,
    accountingDataFilter,
    email: userEmail,
    open,
    handleToggleModals,
    members: getParty?.members || [],
    handleCreateWire,
    handleAuthTransfer,
    openBackdrop,
    onFilterChange,
    onSort,
    onUpdateAmountLimits,
    clearFilters
  };
};

export default useWireTransfers;
