import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { NewEmailType } from 'v2-types/user';

export type GQLCreateUser = {
  createUser: {
    _id: string;
    username: string
    emails: NewEmailType[]
  }
};

export type CreateUserPayload = {
  id: string,
  username: string
}

export const createUser = async (payload: CreateUserPayload) => {
  const mutation = gql`
  mutation CreateUser($id: ID!, $username: String!) {
    createUser(_id: $id, username: $username) {
        _id
        username
        emails {
          _id
          address
          kind
        }
      }
    }
  `;

  const response = await graphQLClient()
    .request<GQLCreateUser>(mutation, payload);

  return response;
};

export type GQLDeleteUser = {
  deleteUser: {
    _id: string,
    user_id: string,
    username: string,
    emails: NewEmailType[]
  };
};

export type DeleteUserPayload = {
  id: string
}

export const deleteUser = async (payload: DeleteUserPayload) => {
  const mutation = gql`
    mutation DeleteUser($id: ID!) {
      deleteUser(_id: $id) {
        _id
        user_id
        emails {
          _id
          address
          kind
        }
        username
      }
    }
`;

  const response = await graphQLClient()
    .request<GQLDeleteUser>(mutation, payload);

  return response;
};
