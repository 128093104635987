import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import realStateAgentIcon from 'assets/icons/real-state-agent.svg';
import icon1031 from 'assets/icons/tp-1031.svg';
import spouseIcon from 'assets/icons/tp-party-spouce.svg';
import poaIcon from 'assets/icons/tp-poa.svg';
import React from 'react';
import { REAL_STATE_AGENT, TRANSACTION_COORDINATOR } from 'utils/constants';

const useStyles = makeStyles((theme:any) => ({
  iconSize: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5)
  },
  iconSizeTransaction: {
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}));

type iconp={
  type: 'Agent'
  | 'Attorney'
  | 'Exchange 1031'
  | 'Power of Attorney'
  | 'Spouse'
  | string
}
const GetICon: React.FC<iconp> = ({ type }) => {
  const classes = useStyles();
  return (
    <>
      {(() => {
        switch (type) {
          case 'Spouse':
            return (
              <img
                alt="spouse logo"
                src={spouseIcon}
                className={classes.iconSize}
              />
            );
          case 'Power of Attorney':
            return (
              <img
                alt="spouse logo"
                src={poaIcon}
                className={classes.iconSize}
              />
            );
          case 'Exchange 1031':
            return (
              <img
                alt="spouse logo"
                src={icon1031}
                className={classes.iconSize}
              />
            );
          case REAL_STATE_AGENT:
            return (
              <img
                alt="real state agent logo"
                src={realStateAgentIcon}
                className={classes.iconSize}
              />
            );
          case TRANSACTION_COORDINATOR:
            return (
              <FontAwesomeIcon icon="money-check-alt" className={classes.iconSizeTransaction} />
            );
          case 'Agent':
            return (
              <FontAwesomeIcon icon="user-tie" className={classes.iconSize} />
            );
          case 'Attorney':
            return (
              <FontAwesomeIcon icon="gavel" className={classes.iconSize} />
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default GetICon;
