import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  colorBlack: { color: theme.palette.common.black },
  boldFont: { fontWeight: 500 },
  colorTag: {
    color: theme.palette.primary[500],
    marginRight: theme.spacing(2),
  },
}));

type Tag = {
  _id: string;
  kind: string;
  description: string;
};

type ItemtagType = {
  tag: Tag;
  options: Function;
  isSignature?: boolean;
};

export const ItemTag: FC<ItemtagType> = ({ tag, options, isSignature }) => {
  const tagCode = isSignature ? `[${tag._id}]` : `{{${tag._id}}}`;

  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flex="1"
    >
      <Box display="flex" flexDirection="column">
        <Typography
          variant="caption"
          className={clsx(classes.boldFont, classes.colorBlack)}
        >
          {tagCode}
        </Typography>
        <Typography
          variant="caption"
          className={clsx(classes.boldFont, classes.colorTag)}
        >
          {tag.description}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        <Box>{options()}</Box>
      </Box>
    </Box>
  );
};

ItemTag.defaultProps = { isSignature: false };
