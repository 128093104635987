import React from 'react';

import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import useChargesPrototype from './charges-c-hooks';
import ChargesCPrototpeScene from './charges-c-scene';

const ChargesC = () => {
  const {
    accounting,
    currentRowSelected,
    setCurrentRowSelected,
    disclosure,
    handleSetDisclosure,
    handleSetAccounting,
    loadingAccounting
  } = useChargesPrototype();

  const handleSelectItem = (currentrow:columnsDataSet) => {
    setCurrentRowSelected(currentrow);
  };

  return (
    <ChargesCPrototpeScene
      accountingData={accounting}
      handleSetAccounting={handleSetAccounting}
      currentRowSelected={currentRowSelected}
      handleSelectRow={handleSelectItem}
      disclosureSection={disclosure}
      handleSetDisclosure={handleSetDisclosure}
      loadingAccounting={loadingAccounting}
    />
  );
};

export default ChargesC;
