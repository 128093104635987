import { Grid, makeStyles, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import React from 'react';
import { PaymentsType, ProjectedPaymentsType } from 'types/disclosures';

import { getPaymentsRows, getTablePayments } from '../services';
import ProjectedPaymentsTable from './projected-payments-table';

const useStyles = makeStyles((theme: any) => ({
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

type PatymentsSectionTableType = {
  t: TFunction;
  values: ProjectedPaymentsType;
  paymentsTable?: PaymentsType;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const PaymentSectionTable = ({
  t,
  values,
  paymentsTable,
  setFieldValue,
  errors,
}: PatymentsSectionTableType) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.subTitle}>
          {`${t('projectedPayments:years-range-title')} ${values?.payments?.year_range_from} - ${values?.payments?.year_raneg_to}`}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ProjectedPaymentsTable
          data={getTablePayments({ ...paymentsTable, paymentsTable })}
          rows={getPaymentsRows({ ...paymentsTable, setFieldValue, t, errors })}
        />
      </Grid>
    </Grid>
  );
};

PaymentSectionTable.defaultProps = { paymentsTable: [] };

export default PaymentSectionTable;
