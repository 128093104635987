import {
  makeStyles,
} from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  rootContainer: { padding: theme.spacing(3) },
  navigationContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(3),
  },
  tabsContainer: {
    borderBottomColor: theme.palette.input.border,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  mainContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  selectPropertyContainer: {
    padding: theme.spacing(2, 4, 0, 4),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  dividerVertical: {
    backgroundColor: theme.palette.text.hint,
    height: 'auto',
  },
  subTitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
}));
