import { ListingEntry } from 'graphql/listings/queries';
import { createOrder } from 'graphql/orders/mutations';
import { useGetListings } from 'hooks/useGetListings';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { queryCache, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import useAlert from 'utils/alert';
import {
  AGENCY_SELECTED_ID,
  CLOSING_DISCLOSURE,
  CURRENT_PATH,
  DEFAULT_ROUTE,
  PURCHASE,
  PURCHASE_STANDARD,
  TOKEN_CLAIMS,
  REFINANCE
} from 'utils/constants';
import { capitalize } from 'utils/helpers';

export type Fields = {
  transactionType: string;
  type: string;
  workflow: string;
};

const useModalCreateOrdersHook = (onCancel: () => void) => {
  const history = useHistory();
  const { t } = useTranslation();
  const showAlert = useAlert();

  const methods = useForm<Fields>({
    defaultValues: {
      transactionType: PURCHASE,
      type: CLOSING_DISCLOSURE,
      workflow: PURCHASE_STANDARD,
    },
  });

  const [workflows, setWorkflows] = useState<ListingEntry[]>([{
    code: "Purchase Standard",
    description: "Purchase Standard"
  }]);

  const { data: transactionsResponse } = useGetListings({
    code: "allowed_transactions"
  });
  const transactionTypes = transactionsResponse?.getListing.entries ?? [];

  const { data: typesResponse } = useGetListings({
    code: "allowed_types"
  });
  const types = typesResponse?.getListing.entries.concat([{
    code: "Settlement Statement",
    description: "Settlement Statement"
  }]) ?? [];

  const [mutationCreateOrder, responseCreateOrder] = useMutation(createOrder);

  useEffect(() => {
    if (responseCreateOrder.status === 'success' && responseCreateOrder.data) {
      onCancel();
      responseCreateOrder.reset();
      queryCache.invalidateQueries('orders');
      localStorage.setItem(CURRENT_PATH, JSON.stringify({ view: DEFAULT_ROUTE, param: '' }));
      history.push(`/orders/details/${responseCreateOrder.data.createOrder._id}/${DEFAULT_ROUTE}`);
      showAlert(capitalize(t('dialogs:order-created')), 'success');
    }

    if (responseCreateOrder.isError) {
      onCancel();
      responseCreateOrder.reset();
      showAlert(capitalize(t('dialogs:there-is-an')), 'error');
    }
  }, [responseCreateOrder, onCancel, history, t, showAlert]);

  const selectTransactionType = (code: string) => {
    const workflowList: ListingEntry[] = [];
    if (code === PURCHASE) {
      workflowList.push({
        code: "Purchase Standard",
        description: "Purchase Standard"
      });
    }

    if (code === REFINANCE) {
      workflowList.push({
        code: "Refinance",
        description: "Refinance"
      });
    }

    methods.setValue('type', '');
    methods.setValue('workflow', '');
    setWorkflows(workflowList);
  };

  const handleOnSubmit = async (values: Fields) => {
    const selectedAgencyId = localStorage.getItem(AGENCY_SELECTED_ID);

    if (!selectedAgencyId) {
      showAlert(t('orders:agency-not-selected'), 'error');
      return;
    }

    await mutationCreateOrder({
      ...values,
      agencyId: selectedAgencyId
    });
    methods.reset();
  };

  return {
    t,
    methods,
    transactionTypes,
    types,
    workflows,
    selectTransactionType,
    handleOnSubmit
  };
};

export default useModalCreateOrdersHook;
