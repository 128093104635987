import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, PropTypes, Typography } from '@material-ui/core';
import React, { CSSProperties, FC } from 'react';

import { useStyles } from './styles';

export type BaseButtonProps = {
  text?: string,
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  isLoading?: boolean,
  disableElevation?: boolean,
  backgroundColor?: CSSProperties['backgroundColor'],
  hoverColor?: CSSProperties['backgroundColor'],
  disabled?: boolean,
  icon?: IconProp,
  size?: 'small' | 'medium' | 'large',
  type?: 'submit' | 'button' | 'reset',
  iconColor?: string,
  buttonVariant?: 'text' | 'outlined' | 'contained',
  buttonColor?: PropTypes.Color,
  textColor?: CSSProperties['color'],
  borderColor?: CSSProperties['backgroundColor'],
  width?: CSSProperties['width']
}

export const BaseButton: FC<BaseButtonProps> = ({
  text,
  icon,
  backgroundColor,
  onClick = () => { },
  isLoading = false,
  disableElevation = true,
  disabled = false,
  size = 'medium',
  type = 'button',
  iconColor,
  hoverColor,
  buttonVariant = 'contained',
  buttonColor = 'secondary',
  textColor,
  borderColor,
  width
}) => {
  const classes = useStyles({
    buttonBackgroundColor: backgroundColor,
    buttonHoverColor: hoverColor,
    textColor,
    buttonBorderColor: borderColor,
    width
  });

  return (
    <Button
      className={classes.button}
      variant={buttonVariant}
      disableElevation={disableElevation}
      color={buttonColor}
      disabled={disabled}
      onClick={onClick}
      size={size}
      type={type}
    >
      <Typography variant="body2" className={classes.text}>
        {!isLoading && icon && <FontAwesomeIcon icon={icon} className={classes.icon} color={iconColor} />}

        {isLoading && <FontAwesomeIcon spin icon="spinner" />}
        {!isLoading && text}
      </Typography>
    </Button>
  );
};
