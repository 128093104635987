import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const SkeletonParties = () => (
  <Grid container direction="column" spacing={2}>
    <Grid item>
      <Skeleton width="100%" height={30} />
    </Grid>
    <Grid item>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Skeleton width="100%" height={30} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton width="100%" height={30} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Skeleton width="100%" height={30} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton width="100%" height={30} />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default SkeletonParties;
