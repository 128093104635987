import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    maxHeight: '92vh',
    height: '92vh',
    overflow: 'hidden',
    padding: '15px',
    gap: '15px',
    flexWrap: 'nowrap'
  },
  sideBar: {
    maxHeight: '100%',
    width: '25%'
  }
}));
