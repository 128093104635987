import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DEFAULT_ROUTE, LOCAL_STORAGE_TABS_KEY } from "utils/constants";

export type TabOrder = {
  id: string,
  orderId: string
}

async function getTabsFromLocalStorage() {
  let tabs: TabOrder[] = [];
  try {
    const stringTabs = await window.localStorage.getItem(LOCAL_STORAGE_TABS_KEY);
    if (stringTabs) {
      tabs = await JSON.parse(stringTabs);
    }
  } catch (error) {
    return [];
  }
  return tabs;
}

export const useOrderTabs = () => {
  const [openedOrderIdList, setOpenedOrderIdList] = useState<TabOrder[]>([]);
  const [selectedOrderIdTab, setSelectedOrderIdTab] = useState<TabOrder>();
  const history = useHistory();
  const location = useLocation();

  const removeOrderTab = (id: string) => {
    let newList: TabOrder[] = [];
    setOpenedOrderIdList((prevOrderIdList) => {
      newList = prevOrderIdList.filter((tabOrder) => tabOrder.id !== id);
      window.localStorage.setItem(LOCAL_STORAGE_TABS_KEY, JSON.stringify(newList));
      return newList;
    });

    if (newList.length > 0) {
      setSelectedOrderIdTab(newList[0]);
      history.replace(`/orders/details/${newList[0].id}/${DEFAULT_ROUTE}`);
    }
  };

  const addOrderTab = (tabOrder: TabOrder) => {
    setOpenedOrderIdList((prevOrderIdList) => {
      if (prevOrderIdList.some((tab) => tab.id === tabOrder.id)) return prevOrderIdList;

      const newList = [...prevOrderIdList, tabOrder];
      window.localStorage.setItem(LOCAL_STORAGE_TABS_KEY, JSON.stringify(newList));
      return newList;
    });
    setSelectedOrderIdTab(tabOrder);
  };

  const selectOrderTab = (tabOrder: TabOrder) => {
    setSelectedOrderIdTab(tabOrder);
    if (selectedOrderIdTab) {
      const currentPath = history.location;
      history.replace(currentPath.pathname.replace(selectedOrderIdTab?.id, tabOrder.id));
    } else {
      history.replace(`/orders/details/${tabOrder.id}/${DEFAULT_ROUTE}`);
    }
  };

  useEffect(() => {
    const getSavedTabs = async () => {
      const tabs = await getTabsFromLocalStorage();
      setOpenedOrderIdList(tabs ?? []);
      if (tabs && tabs.length > 0 && location.pathname.endsWith("/orders/details")) {
        selectOrderTab(tabs[0]);
      }
    };

    getSavedTabs();
  }, []);

  return {
    openedOrderIdList,
    selectedOrderIdTab,
    setSelectedOrderIdTab,
    addOrderTab,
    removeOrderTab,
    selectOrderTab
  };
};