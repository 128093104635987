import { Grid } from '@material-ui/core';
import DatePicker from 'components/date-picker/date-picker';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formInfoOrder } from '../services';
import SectionTitle from './section-title';

type DatesPDFProps = {
	control:Control<formInfoOrder, object>
}

const DatesPDFForm:FC<DatesPDFProps> = ({ control }) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitle title="Dates Information" />
      <Grid item>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="closing_date"
                  render={({ field: { ref, value, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      disableToolbar={false}
                      label={t('orders:closing-date')}
                      value={value as string | Date}
                    />
				  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="acceptance_date"
                  render={({ field: { ref, value, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      disableToolbar={false}
                      label={t('orders:acceptance-date')}
                      value={value as string | Date}
                    />
				  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="funding_date"
                  render={({ field: { ref, value, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      disableToolbar={false}
                      label={t('orders:funding-date')}
                      value={value as string | Date}
                    />
				  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="disbursemente_date"
                  render={({ field: { ref, value, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      disableToolbar={false}
                      label={t('orders:disbursement-date')}
                      value={value as string | Date}
                    />
				  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DatesPDFForm;
