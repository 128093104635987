import { Box } from '@material-ui/core';
import React from 'react';
import { formatMoney } from 'utils/helpers';
import { Interest, InterestKindEnum } from 'v2-types/order';
import { formatPercent } from 'views/orders/loans/utils';

import { LabelWithValue } from '../components/label-with-value';

export function getInterestTextInfo(interest: Interest) {
  const { kind } = interest;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      style={{ gap: '20px' }}
    >
      {(kind === InterestKindEnum.fixed || kind === InterestKindEnum.only || kind === InterestKindEnum.variable) && (
        <LabelWithValue
          label="Rate"
          value={formatPercent(interest.rate)}
        />
      )}

      {kind === InterestKindEnum.adjustable && (
        <>
          <LabelWithValue
            label="Index Name"
            value={interest.index_name}
          />
          <LabelWithValue
            label="Margin"
            value={formatPercent(interest.margin)}
          />
        </>
      )}

      {kind === InterestKindEnum.conversion && (
        <>
          <LabelWithValue
            label="Fee"
            value={interest.fee ? formatMoney(interest.fee).toString() : ''}
          />
          <LabelWithValue
            label="Period Start"
            value={interest.period_start}
          />
          <LabelWithValue
            label="Period End"
            value={interest.period_end}
          />
        </>
      )}

      {kind === InterestKindEnum.first && (
        <>
          <LabelWithValue
            label="Change Date"
            value={interest.change_date}
          />
          <LabelWithValue
            label="Change Min"
            value={formatPercent(interest.change_min)}
          />
          <LabelWithValue
            label="Change Max"
            value={formatPercent(interest.change_max)}
          />
        </>
      )}

      {kind === InterestKindEnum.subsequent && (
        <>
          <LabelWithValue
            label="Months"
            value={interest.months.toString()}
          />
          <LabelWithValue
            label="Rate Min"
            value={formatPercent(interest.rate_min)}
          />
          <LabelWithValue
            label="Rate Max"
            value={formatPercent(interest.rate_max)}
          />
          <LabelWithValue
            label="Change Max"
            value={formatPercent(interest.change_max)}
          />
        </>
      )}
    </Box>
  );
}
