import { Box, makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme:any) => ({
  container: {
    height: 'calc(100vh)',
    padding: theme.spacing(4)
  },
  titleSkeleton: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  contenSkeleton: { marginBottom: theme.spacing(5) },
  littleSkeleton: { marginBottom: theme.spacing(2) }
}));

const SkeletonParties = () => {
  const classes = useStyles();
  return (

    <Paper>
      <Box display="flex" flexDirection="column" flex="1" className={classes.container}>
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.titleSkeleton}
          height={40}
        />
        <Skeleton
          variant="rect"
          animation="wave"
          height={48}
        />

        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.titleSkeleton}
          height={40}
        />

        <Box display="flex" flexDirection="row" justifyContent="space-around" className={classes.contenSkeleton}>
          {[1, 2, 3, 4, 5].map((sk) => (
            <Skeleton
              key={sk}
              variant="rect"
              animation="wave"
              height={40}
              width={100}
            />
          ))}
        </Box>
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.titleSkeleton}
          height={40}
        />

        <Box display="flex" flexDirection="column" className={classes.contenSkeleton}>
          <Skeleton
            variant="rect"
            animation="wave"
            height={30}
            className={classes.littleSkeleton}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            height={30}
            className={classes.littleSkeleton}
          />
        </Box>
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.titleSkeleton}
          height={40}
        />

      </Box>
    </Paper>

  );
};

export default SkeletonParties;
