import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import SaveButton from 'components/common/save-button';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import { useGetListings } from 'hooks/useGetListings';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewTemplateType } from 'types/auto-templates';
import { listingItemtype } from 'types/ledger';
import * as Yup from 'yup';

import {
  codeSelctor,
  emptyTemplate,
  perElemts,
  types,
  workflow,
} from '../utils/constants';

type FormTemplateProps = {
  partyRolls: listingItemtype[];
  handleOnSubmit: (data: NewTemplateType) => void;
  template: NewTemplateType;
  codeTemplates?: string[];
  closeModal?: () => void;
};

const useStyles = makeStyles((theme: any) => ({
  input: {
    '&.MuiInput-formControl': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
      marginTop: 0,
      '& .MuiSelect-root': { padding: '27px 12px 10px' },
    },
  },
}));

export const FormTemplate: FC<FormTemplateProps> = ({
  partyRolls,
  handleOnSubmit,
  template,
  codeTemplates,
  closeModal,
}) => {
  const { t } = useTranslation();
  const isEdit = !!template._id;

  const { data: transactionsResponse } = useGetListings({ code: "allowed_transactions", queryConfig: { refetchOnWindowFocus: false } });
  const transactionTypes = transactionsResponse?.getListing.entries ?? [];

  const schema = Yup.object({
    data: Yup.object().shape({
      property: Yup.mixed().test(
        'property-required',
        t('documents:property-required'),
        (value) => {
          if (typeof value === 'string') {
            return value.trim().length > 0;
          }
          return value.length > 0;
        }
      ),
    }),
    name: Yup.string().required(t('validations:required')),
    code: Yup.string()
      .required(t('documents:code-required'))
      .test(
        'name-exists',
        t('documents:code-repeated'),
        (value, { parent }) => {
          const fileName = `${value}${
            codeSelctor.find((codeSel) => codeSel.key === parent?.kindTem)
              ?.extension
          }`;
          return !codeTemplates?.includes(fileName);
        }
      )
      .test(
        'is-uppercase',
        t('validations:invalid-uppercase'),
        (value) => !/[A-Z]/.test(value || '')
      ),
    kindTem: isEdit
      ? Yup.string()
      : Yup.string().required(t('validations:required')),
  });

  const {
    reset,
    control,
    handleSubmit,
    clearErrors,
    setError,
    watch,
    formState: { errors, isValid },
  } = useForm<NewTemplateType>({
    mode: 'onChange',
    defaultValues: {
      kindTem: '',
      ...emptyTemplate,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const signersFiltered = template.data.signer.filter((signer) => partyRolls.find((party) => party.code === signer));

    const partiesFiltered = template.data.party.filter((partyFill) => partyRolls.find((party) => party.code === partyFill));

    const deliversFiltered = template.data.deliver.filter((deliver) => partyRolls.find((party) => party.code === deliver));

    const templateFiltered = {
      ...template,
      data: {
        ...template.data,
        signer: signersFiltered,
        deliver: deliversFiltered,
        party: partiesFiltered,
      },
    };

    reset({
      kindTem: '',
      ...(template._id ? templateFiltered : template),
    });
  }, [template, reset]);

  useEffect(() => {
    const kindTem = watch('kindTem');
    const code = watch('code');
    const fileName = `${code}${
      codeSelctor.find((codeSel) => codeSel.key === kindTem)?.extension
    }`;

    if (!codeTemplates?.includes(fileName)) {
      clearErrors('code');
    } else {
      setError('code', {
        type: 'name-exists',
        message: t('documents:code-repeated'),
      });
    }

    if (/[A-Z]/.test(code)) {
      setError('code', {
        type: 'is-uppercase',
        message: t('validations:invalid-uppercase'),
      });
    }
  }, [watch('kindTem')]);

  const classes = useStyles();

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const codeSelected = data.kindTem;
        delete data.kindTem;
        data.code = `${data.code}${
          codeSelctor.find((code) => code.key === codeSelected)?.extension
        }`;

        handleOnSubmit(data);

        if (!template._id) {
          if (closeModal) {
            closeModal();
          }
        }
      })}
    >
      <Grid container spacing={2}>
        {!isEdit && (
          <Grid item xs={6}>
            <Controller
              name="kindTem"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <SelectField
                  {...field}
                  handleChange={onChange}
                  value={field.value || ''}
                  data={codeSelctor}
                  dataKey="key"
                  dataValue="value"
                  dataText="text"
                  label={t('documents:kind-tem')}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <Controller
            name="code"
            control={control}
            rules={{ validate: schema.fields.code.validate as any }}
            render={({ field: { ref, onChange, ...field } }) => (
              <TextField
                {...field}
                value={field.value}
                inputRef={ref}
                label={t('documents:code')}
                disabled={isEdit}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(
                    /[^a-zA-Z0-9-_]/g,
                    ''
                  );
                  onChange(e);
                }}
                error={!!errors.code}
                helperText={errors.code?.message || t('validations:required')}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel>{t('documents:deliver')}</InputLabel>
            <Controller
              name="data.deliver"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <Select
                  {...field}
                  className={classes.input}
                  variant="filled"
                  multiple
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  value={field.value || []}
                  input={<Input />}
                  renderValue={(selected) => (selected as any).join(', ')}
                >
                  {partyRolls.map((roll) => (
                    <MenuItem key={roll.code} value={roll.code}>
                      <Checkbox
                        checked={
                          Array.isArray(field.value)
                          && field.value.some((data) => data === roll.code)
                        }
                      />
                      <ListItemText primary={roll.description} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel>{t('documents:party')}</InputLabel>
            <Controller
              name="data.party"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <Select
                  {...field}
                  className={classes.input}
                  variant="filled"
                  multiple
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  value={field.value || []}
                  input={<Input />}
                  renderValue={(selected) => (selected as any).join(', ')}
                >
                  {partyRolls.map((roll) => (
                    <MenuItem key={roll.code} value={roll.code}>
                      <Checkbox
                        checked={
                          Array.isArray(field.value)
                          && field.value.some((data) => data === roll.code)
                        }
                      />
                      <ListItemText primary={roll.description} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel>{t('documents:signer')}</InputLabel>
            <Controller
              name="data.signer"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <Select
                  {...field}
                  className={classes.input}
                  variant="filled"
                  multiple
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  value={field.value || []}
                  input={<Input />}
                  renderValue={(selected) => (selected as any).join(', ')}
                >
                  {partyRolls.map((roll) => (
                    <MenuItem key={roll.code} value={roll.code}>
                      <Checkbox
                        checked={
                          Array.isArray(field.value)
                          && field.value.some((data) => data === roll.code)
                        }
                      />
                      <ListItemText primary={roll.description} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                value={field.value}
                inputRef={ref}
                label={t('documents:name')}
                error={!!errors.name}
                helperText={errors.name?.message || t('validations:required')}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel>{t('documents:per')}</InputLabel>
            <Controller
              name="per"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <Select
                  {...field}
                  className={classes.input}
                  variant="filled"
                  multiple
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  value={field.value || []}
                  input={<Input />}
                  renderValue={(selected) => (selected as any).join(', ')}
                >
                  {perElemts.map((per) => (
                    <MenuItem
                      key={per.code}
                      value={per.code}
                      disabled={
                        Array.isArray(field.value)
                        && field.value.length >= 2
                        && !field.value.some((data) => data === per.code)
                      }
                    >
                      <Checkbox
                        checked={
                          Array.isArray(field.value)
                          && field.value.some((data) => data === per.code)
                        }
                      />
                      <ListItemText primary={per.description} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isEdit ? 6 : 4}>
          <Controller
            name="triggers.transaction_type"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <SelectField
                {...field}
                handleChange={onChange}
                value={field.value}
                data={transactionTypes}
                dataKey="code"
                dataValue="code"
                dataText="description"
                label={t('documents:transaction-type')}
              />
            )}
          />
        </Grid>
        <Grid item xs={isEdit ? 6 : 4}>
          <Controller
            name="triggers.type"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <SelectField
                {...field}
                handleChange={onChange}
                value={field.value}
                data={types}
                dataKey="key"
                dataValue="key"
                dataText="key"
                label={t('documents:type')}
              />
            )}
          />
        </Grid>
        <Grid item xs={isEdit ? 6 : 4}>
          <Controller
            name="triggers.workflow"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <SelectField
                {...field}
                handleChange={onChange}
                value={field.value}
                data={workflow}
                dataKey="key"
                dataValue="key"
                dataText="key"
                label={t('documents:workflow')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SaveButton
            disabled={!isValid}
            type="submit"
            customText={t('documents:send')}
          />
        </Grid>
      </Grid>
    </form>
  );
};
