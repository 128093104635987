import { TFunction } from 'i18next';
import React from 'react';
import { Column, RendererParams } from 'types/common';

export type ColumnsType = { [key: string]: Column };

export const generateColumns = (t: TFunction): ColumnsType => ({
  name: {
    header: {
      title: t('users:name'),
      align: 'left',
    },
    renderer: ({ row }: RendererParams) => (
      <>{row?.name}</>
    ),
  },
  kind: {
    header: {
      title: t('users:kind'),
      align: 'left',
      width: '300px',
      minWidth: '300px',
    },
    renderer: ({ row }: RendererParams) => (
      <>{row?.kind}</>
    ),
  },
  userSystem: {
    header: {
      title: t('users:user-system'),
      width: '140px',
      minWidth: '140px',
    },
    renderer: ({ row }: RendererParams) => (
      row.user_id && row.user_id !== null ? (
        <>{t('common:yes')}</>
      ) : (
        <>No</>
      )
    ),
  },
});
