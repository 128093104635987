import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

import Header from '../components/header';
import TableComponent from '../components/prototype-new-table-charges/new-table-charges';
import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import SettingsBC from '../components/settings-b-c';
import TableLoader from '../components/skeleton-table-charges';

const useStyles = makeStyles((theme) => ({ padding: { padding: theme.spacing(3) }, }));

type ChargesProtitypeProps = {
  accountingData: AccountingType[];
  handleSetAccounting: (accountingData: AccountingType[]) => void;
  currentRowSelected: columnsDataSet | null;
  handleSelectRow: (row: columnsDataSet) => void;
  disclosureSection: string | null;
  handleSetDisclosure: (disclosureLetter: string) => void;
  loadingAccounting: boolean;
};

const ChargesCScene: FC<ChargesProtitypeProps> = ({
  accountingData,
  handleSetAccounting,
  currentRowSelected,
  handleSelectRow,
  disclosureSection,
  handleSetDisclosure,
  loadingAccounting,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" className={classes.padding} spacing={2}>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Paper>
          <Header title={t('charges:did-shop-for')} chargesIdentifier="C" />
          {loadingAccounting ? (
            <TableLoader />
          ) : (
            <TableComponent
              accountingData={accountingData}
              handleClickItem={(item) => {
                handleSelectRow(item);
              }}
              itemSelected={currentRowSelected}
              updateAccounting={handleSetAccounting}
              chargesIdentifier="C"
            />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <SettingsBC
          currentSection={disclosureSection}
          chargesIdendifier="C"
          handleUpdateAccounting={handleSetAccounting}
          handleSetDisclosure={handleSetDisclosure}
        />
      </Grid>
    </Grid>
  );
};

export default ChargesCScene;
