import { differenceInDays } from 'date-fns';

export type PayOffInformationform = {
	rescission_date: string | null,
	recorded_date: string|null,
	page: string | null,
	instrument_number: string | null,
	document_number: string | null,
	deed_date: string | null,
	certificate_title: string | null,
	book: string | null
}

const validKinds = [
  {
    code: 'Current Trustee',
    description: 'Current Trustee'
  },
  {
    code: 'Lien holder',
    description: 'Lien holder'
  },
  {
    code: 'Mortgagee',
    description: 'Mortgagee'
  },
  {
    code: 'Mortgagor',
    description: 'Mortgagor'
  },
  {
    code: 'Original Trustee',
    description: 'Original Trustee'
  }
];

export const getValidKinds = (kinds:string) => kinds.split(',').filter((kind) => validKinds.some((validKind) => validKind.code === kind));

export const calculateAdditionalInterest = (principal: number, interestRate: number, daysPerYear: number) => {
  const calculatePerDiem = (a: number, b: number, c: number) => parseFloat(((a * (b / 100)) / c).toFixed(2));

  return {
	  perDiem: calculatePerDiem(principal, interestRate, daysPerYear),
	  additionalInterest: (fromDate: Date | null, toDate: Date | null, directPerDiem?: number) => {
      let diffDates = 0;
      let perDiem = 0;

      if (directPerDiem === undefined) {
		  perDiem = calculatePerDiem(principal, interestRate, daysPerYear);
      } else if (directPerDiem > 0) {
		  perDiem = directPerDiem;
      }

      if (fromDate && toDate) {
		  diffDates = differenceInDays(
          new Date(toDate.getFullYear(), toDate.getFullYear(), toDate.getDate()),
          new Date(fromDate.getFullYear(), fromDate.getFullYear(), fromDate.getDate()),
		  );
      }

      return !Number.isNaN(diffDates) ? parseFloat((perDiem * diffDates).toFixed(2)) : 0;
	  }
  };
};
