import Actionbuttons from 'components/common/actions-buttons';
import React from 'react';
import { Column } from 'types/common';
import { PAYEE } from 'utils/constants';
import { formatMoney } from 'utils/helpers';
import { AccountingInvolvedParty } from 'v2-types/order';
import { columnsDataSet } from 'views/orders/charges-accounting/components/prototype-new-table-charges/services';

import { CellIndicator, CellText } from '../components/table-cells';
import CellOptions from '../components/table-cells/cell-options-custom';
import { CellWithAction } from '../components/table-cells/cell-with-action';
import CellWithTooltip from '../components/table-cells/cell-with-tooltip';

type RendererParams = {
  row: columnsDataSet;
  column: Column;
  index: number;
  indexRow?: number;
  shouldCollapse?: boolean;
};

export type ColumnsType = { [key: string]: Column };

export const generateColumns = (
  t,
  theme,
  currentRowSelected,
  handleDeleteRow,
  handleEdit,
  handleCollapse,
  handlePayeePayer,
  handleTax,
  handleSinglePayeePayer,
  handleOpenRemoveAssociationModal
): ColumnsType => {
  const columns: ColumnsType = {
    actions: {
      header: {
        title: '',
        minWidth: '80px',
        width: '80px',
      },
      renderer: ({ row }: RendererParams) => (
        <Actionbuttons
          handleDelete={
            row.chargeData?.kind.includes('manual')
              ? (e) => {
                e.stopPropagation();
                handleDeleteRow(row.id);
              }
              : undefined
          }
          handleEdit={(e) => {
            e.stopPropagation();
            handleEdit(row);
          }}
          handleCollapse={(e) => {
            e.stopPropagation();
            handleCollapse(row);
          }}
          handleParties={(e) => {
            e.stopPropagation();
            handlePayeePayer(row);
          }}
          handleTaxable={(e) => {
            e.stopPropagation();
            handleTax(row);
          }}
          handleIndividualPayeePayer={(e) => {
            e.stopPropagation();
            handleSinglePayeePayer(row);
          }}
        />
      ),
    },
    number: {
      header: { title: '' },
      renderer: ({ row, indexRow }: RendererParams) => (
        <CellIndicator
          isActive={indexRow === currentRowSelected?.indexRow}
          position={row?.number}
        />
      ),
    },
    description: {
      header: {
        title: t('charges:description'),
        align: 'left',
        width: '390px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellText
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          data={row.description}
        />
      ),
    },
    payee: {
      header: {
        title: t('charges:payee'),
        align: 'left',
        width: '390px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellWithAction
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          text={row.payee}
          action={() => handleOpenRemoveAssociationModal(row, PAYEE)}
        />
      ),
    },
    amount: {
      header: {
        title: 'Amount Details',
        align: 'left',
        width: '390px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellText
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          data={`${formatMoney(row.chargeData.amount)} ${
            row.tax
              ? `(Tax: ${formatMoney(row.tax.amount)}, Pending:${formatMoney(
                row.pending_amount || 0
              )})`
              : ''
          }`}
        />
      ),
    },
    perMount: {
      header: {
        title: `$ ${t('charges:per-month')}`,
        align: 'left',
        width: '140px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellText
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          data={formatMoney(row?.chargeData.per_month || 0)}
        />
      ),
    },
    ofMount: {
      header: {
        title: `${t('charges:number-of-month')}`,
        align: 'left',
        width: '140px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellText
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          data={(row.chargeData.months_advanced || 0).toString()}
        />
      ),
    },
    paid_borrower: {
      header: {
        title: t('charges:paid-by-borrower'),
        textColor: theme.palette.primary.light,
      },
      columns: [
        {
          header: {
            title: t('charges:at-closing'),
            width: '142px',
            textColor: theme.palette.primary.light,
          },
          renderer: ({ row }: RendererParams) => (
            <CellText
              position="left"
              backgroundColor="#FAFAFA"
              colorText="#4F5B62"
              data={formatMoney(row?.buyer_at_closing || 0)}
            />
          ),
        },
        {
          header: {
            title: t('charges:before-closing'),
            width: '142px',
            textColor: theme.palette.primary.light,
          },
          renderer: ({ row }: RendererParams) => (
            <CellText
              position="left"
              backgroundColor="#FAFAFA"
              colorText="#4F5B62"
              data={formatMoney(row?.buyer_before_closing || 0)}
            />
          ),
        },
      ],
    },
    paid_seller: {
      header: {
        title: t('charges:paid-by-seller'),
        textColor: theme.palette.primary.light,
      },
      columns: [
        {
          header: {
            title: t('charges:at-closing'),
            width: '142px',
            textColor: theme.palette.primary.light,
          },
          renderer: ({ row }: RendererParams) => (
            <CellText
              position="left"
              backgroundColor="#FAFAFA"
              colorText="#4F5B62"
              data={formatMoney(row?.seller_at_closing || 0)}
            />
          ),
        },
        {
          header: {
            title: t('charges:before-closing'),
            width: '142px',
            textColor: theme.palette.primary.light,
          },
          renderer: ({ row }: RendererParams) => (
            <CellText
              position="left"
              backgroundColor="#FAFAFA"
              colorText="#4F5B62"
              data={formatMoney(row?.seller_before_closing || 0)}
            />
          ),
        },
      ],
    },
    by_others: {
      header: {
        title: t('charges:by-others'),
        width: '142px',
        textColor: theme.palette.primary.light,
      },
      renderer: ({ row }: RendererParams) => (
        <CellWithTooltip
          position="left"
          backgroundColor="#FAFAFA"
          colorText="#4F5B62"
          data={formatMoney(row?.by_others || 0)}
          tooltipText={row?.others_description || 'Nothing to Show'}
          tooltipPlacement="top"
        />
      ),
    },
  };

  return columns;
};

export const generateFooterTable = (handleAddRow, t) => [
  {
    colspan: 3,
    renderer: () => <CellOptions handleAddrow={handleAddRow} />,
  },
  {
    colspan: 4,
    renderer: () => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={t('charges:totals')}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.borrower_total_at_closing)}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.borrower_total_before_closing)}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.seller_total_at_closing)}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.seller_total_before_closing)}
      />
    ),
  },
  {
    colspan: 1,
    renderer: (row) => (
      <CellText
        position="left"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={formatMoney(row.total_by_others)}
      />
    ),
  },
];

export const formatOThersText = (parties: AccountingInvolvedParty[]) => parties
  .filter(
    (party) => !party.order_kinds.includes('Buyer')
        && !party.order_kinds.includes('Seller')
  )
  .map((party) => `${party.name}:${formatMoney(party.amount)}`)
  .join('-');
