import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import TextField from 'components/text-field';
import { TextButton } from 'components/TextButton';
import { OrderMember } from 'graphql/wire-transfers/queries';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';
import { formatMoney } from 'utils/helpers';

import { AccountingDataFiltered } from '../../types';
import SearchAuthorization from '../searchAuth';
import { useStyles } from './styles';

type ModalCreateWireProps = {
  t: TFunction,
  open: boolean,
  handleToggleModals: () => void,
  members: OrderMember[],
  email: string,
  accountingDataFilter: AccountingDataFiltered[],
  handleCreateWire: (authorization: string[], accounting: AccountingDataFiltered[]) => void
}

export const ModalCreateWire: FC<ModalCreateWireProps> = ({
  t,
  open,
  handleToggleModals,
  accountingDataFilter,
  members,
  handleCreateWire,
  email,
}) => {
  const classes = useStyles();
  const [selectedAccounting, setselectedAccounting] = useState<AccountingDataFiltered[]>([]);
  const [selectedAuthorization, setselectedAuthorization] = useState<string[]>(
    []
  );

  const handleChange = (e) => {
    setselectedAccounting(e.target.value);
  };

  const totalAmount = selectedAccounting.reduce(
    (prev, current) => prev + (current.amount ?? 0),
    0
  );

  const handledSelectAuth = (auth) => {
    if (!selectedAuthorization.includes(auth)) {
      setselectedAuthorization([...selectedAuthorization, auth]);
    }
  };

  const handledRemoveAuth = (auth) => {
    setselectedAuthorization(
      selectedAuthorization.filter((elementAuth) => elementAuth !== auth)
    );
  };

  const isValid = () => {
    const hasEmail = selectedAuthorization.includes(email);
    const numberEmails = hasEmail ? 4 : 3;
    return (
      selectedAuthorization.length >= numberEmails
      && selectedAccounting.length > 0
    );
  };

  return (
    <Dialog open={open} onClose={() => handleToggleModals()} fullWidth>
      <DialogTitle className={classes.dialgoTitleContainer}>
        <Grid container direction="column">
          <Grid item className={classes.closeIconContainer}>
            <Button onClick={() => handleToggleModals()} style={{ minWidth: 'auto' }}>
              <FontAwesomeIcon
                icon="plus"
                size="lg"
                transform={{ rotate: 45 }}
                className={classes.closeIcon}
              />
            </Button>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              className={classes.dialogTitle}
            >
              {t('payments:create-wire-tranfer')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="body1" align="center">
              {t('payments:create-wire-transfer-advice')}
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              variant="filled"
              fullWidth
              style={{ backgroundColor: '#ECEFF1' }}
            >
              <InputLabel>{t('payments:accounting-entrance')}</InputLabel>
              <Select
                variant="filled"
                multiple
                value={selectedAccounting}
                onChange={handleChange}
                input={(
                  <Input
                    className={classes.selectInput}
                  />
)}
                renderValue={(selected) => (selected as any)
                  .map((element) => element.description)
                  .join(', ')}
              >
                {accountingDataFilter.map((accountingData) => (
                  <MenuItem key={accountingData._id} value={accountingData as any}>
                    <Checkbox
                      checked={selectedAccounting.some(
                        (data) => data._id === accountingData._id
                      )}
                    />
                    <ListItemText primary={accountingData.description} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              label="Total Amount"
              value={formatMoney(totalAmount)}
              disabled
            />
          </Grid>
          <Grid item>
            <SearchAuthorization
              members={members}
              handledSelectAuth={handledSelectAuth}
            />
          </Grid>
          <Grid item container spacing={2}>
            {selectedAuthorization.map((auth) => (
              <Grid item key={auth}>
                <Chip
                  color="secondary"
                  style={{ marginRight: '4px' }}
                  label={auth}
                  onDelete={() => {
                    handledRemoveAuth(auth);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Box
          className={classes.actionsContainer}
        >
          <TextButton
            text={t('common:cancel')}
            onClick={() => handleToggleModals()}
          />
          <ContainedButton
            text={t('common:create')}
            disabled={!isValid()}
            onClick={() => {
              handleCreateWire(selectedAuthorization, selectedAccounting);
              handleToggleModals();
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
