import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  colorBlack: { color: theme.palette.common.black },
  colorWhite: { color: theme.palette.common.white },
  button: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
}));

const LightTooltip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

type IconWithTooltipType = {
  tooltipText: string;
  icon: IconProp;
  onClick: () => void;
};

const IconWithTooltip: React.FC<IconWithTooltipType> = ({
  tooltipText,
  icon,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <LightTooltip
      arrow
      title={(
        <Typography style={{ color: 'white' }} variant="caption">
          {tooltipText}
        </Typography>
      )}
      placement="top"
    >
      <IconButton size="small" className={classes.button} onClick={onClick}>
        <FontAwesomeIcon icon={icon} size="xs" className={classes.colorBlack} />
      </IconButton>
    </LightTooltip>
  );
};

export default IconWithTooltip;
