import { Grid, makeStyles, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.light,
  },
}));

type HeaderProps = {
  t: TFunction;
};

const Header = ({ t }: HeaderProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <Typography variant="h6" className={classes.title}>
          {t('users:profile')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
