import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  dialgoTitleContainer: { paddingBottom: '0' },
  dialogTitle: {
    color: '#000000',
    textAlign: 'center'
  },
  closeIconContainer: { alignSelf: 'flex-end', },
  closeIcon: { color: '#000000', },
  actionsContainer: { gap: theme.spacing(2) }
}));
