import { useState } from 'react';
import { DocumentsType } from 'v2-types/order';

type UseDocumentListParams = {
  documents: DocumentsType[]
}
export const UseDocumentList = ({ documents }: UseDocumentListParams) => {
  const [kindSelected, setKindSelected] = useState<string>('all');
  const documentsFiltered = kindSelected !== '' && kindSelected !== 'all' ? documents.filter((document) => document.kind === kindSelected) : documents;

  return {
    documentsFiltered,
    kindSelected,
    setKindSelected
  };
};
