import React, { FC } from 'react';

import { useProgressOrder } from './progress-order-hooks';
import { ProgressOrderScene } from './progress-order-scene';

const ProgressOrder = () => {
  const {
    milestoneList,
    selectedMilestone,
    setSelectedMilestone,
    t
  } = useProgressOrder();

  return (
    <ProgressOrderScene
      t={t}
      milestoneList={milestoneList}
      selectedMilestone={selectedMilestone}
      setSelectedMilestone={setSelectedMilestone}
    />
  );
};

export default ProgressOrder;
