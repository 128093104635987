import { CashToCloseEntryRow } from '../types';

export function getCashToCloseList({ orderTransactionType }: {
  orderTransactionType: string
}): CashToCloseEntryRow[] {
  if (orderTransactionType === 'Refinance') {
    return [
      {
        title: 'Loan Amount',
        fieldName: 'loan_amount'
      },
      {
        title: 'Total Closing Costs (J)',
        fieldName: 'total_closing_costs_j'
      },
      {
        title: 'Paid Before Closing',
        fieldName: 'paid_before_closing'
      },
      {
        title: 'Total Payoffs and Payments (K)',
        fieldName: 'total_payoffs_and_payments_k'
      },
      {
        title: 'Cash To Close',
        fieldName: 'cash_to_close'
      }
    ];
  }
  return [
    {
      title: 'Total Closing Costs (J)',
      fieldName: 'total_closing_costs_j'
    },
    {
      title: 'Paid Before Closing',
      fieldName: 'paid_before_closing'
    },
    {
      title: 'Closing Costs Financed',
      fieldName: 'closing_costs_financed'
    },
    {
      title: 'Down Payment From Borrower',
      fieldName: 'down_payment_from_borrower'
    },
    {
      title: 'Deposit',
      fieldName: 'deposit'
    },
    {
      title: 'Funds For Borrower',
      fieldName: 'funds_for_borrower'
    },
    {
      title: 'Seller Credits',
      fieldName: 'seller_credits'
    },
    {
      title: 'Adjustments and Other Credits',
      fieldName: 'adjustments_and_other_credits'
    },
    {
      title: 'Cash To Close',
      fieldName: 'cash_to_close'
    }
  ];
}
