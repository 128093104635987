import {
  Card,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { NewUserType } from 'types/new-user';

const useStyles = makeStyles((theme: any) => ({
  container: {
    borderWidth: 1,
    cursor: 'pointer',
    borderStyle: 'solid',
    padding: theme.spacing(2),
    borderColor: theme.palette.border.card,
  },
  title: { color: theme.palette.tab.offselected },
  chip: { fontSize: '0.6rem' },
  chipAlt: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.border.card,
  },
  mainContainer: { marginTop: theme.spacing(2) },
  subtitle: {
    fontSize: '0.625rem',
    textTransform: 'uppercase',
    color: theme.palette.text.shadow,
  },
  text: { color: theme.palette.primary.darker },
}));

type GridProps = {
  t: TFunction;
  parties: NewUserType[];
  handleEditNav: (id: string) => void;
};

const GridResults: FC<GridProps> = ({ t, parties, handleEditNav }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={3}>
      {parties.map((party) => (
        <Grid key={party._id} item xs={12} md={4}>
          <Card
            elevation={0}
            className={classes.container}
            onClick={() => {
              handleEditNav(party._id);
            }}
          >
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  {party.name}
                </Typography>
              </Grid>

              <Grid item>
                <Chip
                  size="small"
                  color="primary"
                  label={party.kind}
                  className={classes.chip}
                />
              </Grid>

              {party.user_id !== null && (
                <Grid item>
                  <Chip
                    size="small"
                    color="secondary"
                    label={t('users:user-system')}
                    className={clsx(classes.chip, classes.chipAlt)}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>

              <Grid item xs={12} className={classes.mainContainer}>
                {!party.addresses
                  && !party.emails
                  && !party.licenses
                  && !party.phones && (
                    <Typography variant="body2" className={classes.text}>
                      {t('users:no-matching-data')}
                    </Typography>
                )}

                {party.addresses && !!party.addresses.length && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        className={classes.subtitle}
                      >
                        {t('users:addresses')}
                      </Typography>
                    </Grid>

                    {(party.addresses || []).map((address) => (
                      <Grid key={address._id} item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {address.address}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                )}

                {party.emails && !!party.emails.length && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        className={classes.subtitle}
                      >
                        {t('users:emails')}
                      </Typography>
                    </Grid>

                    {(party.emails || []).map((email) => (
                      <Grid key={email._id} item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {email.address}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                )}

                {party.phones && !!party.phones.length && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        className={classes.subtitle}
                      >
                        {t('users:phones')}
                      </Typography>
                    </Grid>

                    {(party.phones || []).map((phone) => (
                      <Grid key={phone._id} item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {phone.number}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                )}

                {party.licenses && !!party.licenses.length && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption"
                        className={classes.subtitle}
                      >
                        {t('users:licenses')}
                      </Typography>
                    </Grid>

                    {(party.licenses || []).map((license) => (
                      <Grid key={license._id} item xs={12}>
                        <Typography variant="body2" className={classes.text}>
                          {license.number}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default GridResults;
