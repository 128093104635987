import { TFunction } from 'i18next';

export const columns = (t: TFunction) => (
  [
    { id: 'amount', label: t('payments:amount') },
    { id: 'reference', label: t('payments:reference') },
    { id: 'file', label: t('payments:file') },
    { id: 'last-modified-date', label: t('common:last-modified-date') },
    { id: 'authorizations', label: t('payments:authorizations') },
    { id: 'status', label: t('common:status') },
  ]
);

export const wireTransferStatusIcons = {
  started: {
    color: '#fdd835',
    icon: 'pause',
  },
  rejected: {
    color: '#f44336',
    icon: 'ban',
  },
  confirmed: {
    color: 'green',
    icon: 'check-circle',
  },
};

export const statusSigners = {
  pending: {
    color: '#fdd835',
    icon: 'pause',
  },
  'block-order': {
    color: '#90a4ae',
    icon: 'ban',
  },
  'authorize-order': {
    color: 'green',
    icon: 'check',
  },
};

export const rowBorderColor = {
  confirmed: '#4caf50',
  started: '#90a4ae',
  onauthorization: '#8c8c8c',
  proccessing: '#fdd835',
  rejected: '#f44336'
};
