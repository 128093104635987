import { NumberFormatValues } from 'react-number-format';

export function isNumberAllowed({ floatValue = 0 }: NumberFormatValues) {
  if (floatValue <= 100) return true;
  return false;
}

export function onNumberChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: (value: number) => void) {
  let value = Number(e.target.value.replace('%', ''));
  if (Number.isNaN(value)) {
    value = 0;
  }
  onChange(value / 100);
}

export function formatValue(value: number | undefined) {
  return value ? (value * 100) : value;
}
