import { ProjectedPaymentsType } from 'types/disclosures';

export const initialPrincipalInterestType = 'amount';
export const initialPeriodicity = 'per_month';
export const MORTGAGE_INSURANCE = 'Mortgage insurance';

export const initialValues = (
  year_range_to: number,
  principal_interest_amount: number,
  mortgage_insurance: number,
  estimated_escrow: number,
): ProjectedPaymentsType => ({
  payments: {
    year_range_from: 1,
    year_raneg_to: year_range_to,
    principal_interest_amount,
    interest_only: false,
    mortgage_insurance,
    estimated_escrow,
    principal_interest_min: 0,
    principal_interest_max: 0,
    principal_interest_type: initialPrincipalInterestType,
  },
  estimated_taxes: {
    amount: 0,
    includes_property_taxes: false,
    includes_homeowners_insurance: false,
    includes_other: false,
    description: '',
    in_escrow_includes_other: false,
    in_escrow_includes_property_taxes: false,
    in_escrow_includes_homeowners_insurance: false,
    periodicity: initialPeriodicity,
  },
  status: false,
});
