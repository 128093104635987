/* eslint-disable react/no-array-index-key */
import {
  Box,
  TableCell,
  TableFooter,
  TableRow,
} from '@material-ui/core';
import React, { Fragment } from 'react';

import useStyles from './style';

type FooTerType = {
  footerComponents?: any[],
  footerDataSet?: any[]
  disableFullborderCell?:boolean
};

const FooterTable = ({
  footerComponents,
  footerDataSet,
  disableFullborderCell
}: FooTerType) => {
  const { borderCellStyle } = useStyles();
  return (
    <TableFooter>
      {footerDataSet && footerDataSet.map((item, index) => (
        <Fragment key={`footer-data-${index}`}>
          {footerComponents && (
          <TableRow>
            {footerComponents?.map((footer, iterator) => (
              <TableCell
                colSpan={footer?.colspan || 0}
                style={{ textAlign: 'center' }}
                key={`footer-component-${iterator}`}
                className={`
                ${!disableFullborderCell && borderCellStyle}
                `}
              >
                <Box>
                  {footer.renderer(item)}
                </Box>
              </TableCell>
            ))}
          </TableRow>
          )}

        </Fragment>
      ))}
    </TableFooter>
  );
};

FooterTable.defaultProps = {
  disableFullborderCell: false,
  footerComponents: [],
  footerDataSet: []
};

export default FooterTable;
