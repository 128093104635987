import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  rootContainer: { padding: theme.spacing(3) },
  noLoansContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    width: '100%',
    height: '60vh'
  },
  subTitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
  noLoansText: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    textAlign: 'center'
  },
  placeholderText: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(5)
  },
}));
