import React, { FC, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import HeadTable from './components/header';
// import { AccountingType } from 'v2-types/order';
import { Column } from 'types/common';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import RowTable from './components/item';
import useStyles from './style';
import ExpandableRow from './components/expandableRow';

type ColumnSort = { [key: string]: 'asc' | 'desc' };

type virtualizedTableProps = {
	dataSet: any[];
	itemsCollapsed?: { [key: string]: boolean };
	columnCollapsing?: Column[];
	onClickItem?: (row?: any, column?: Column, index?: number) => void;
	columns: { [key: string]: Column };
  };

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (<TableContainer component={Paper} {...props} ref={ref} />)) as any,
  Table: (props) => {
    const classes = useStyles();
    return (<Table {...props} className={classes.table} style={{ borderCollapse: 'separate' }} />);
  },
  TableHead,
  TableRow: ExpandableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props as any} ref={ref} />),
};
const VirtualizedTable:FC<virtualizedTableProps> = ({
  columns,
  dataSet,
  columnCollapsing,
  onClickItem,
  itemsCollapsed,
}) => {
  const columnsv2 = Object.values(columns);
  const [currentcols, setCurrentcols] = useState(columnsv2);
  const { innerHeight: height } = window;
  return (
    <TableVirtuoso
      style={{ height }}
      data={dataSet}
      components={TableComponents as any}
      context={{
        currentcols,
        onClickItem,
        itemsCollapsed,
        columnCollapsing
      }}
      fixedHeaderContent={() => ((<HeadTable columns={currentcols || []} />))}
      overscan={10}
      itemContent={(index, data) => (
        <RowTable
          data={data}
          indexRow={index}
          columns={columnsv2}
          onClickItem={onClickItem}
        />
      )}
    />
  );
};

export default VirtualizedTable;
/*

*/
