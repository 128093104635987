import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SaveButton from 'components/common/save-button';
import SelectField from 'components/select-field';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { DocumentsType } from 'v2-types/order';

import { addNewSignature } from '../graphql/mutations';

const useStyles = makeStyles((theme) => ({
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  removecontentPadding: { '& .MuiDialogContent-root': { padding: 0 } },
  itempaddinType: {
    '& > * ': { marginTop: theme.spacing(2) },
    padding: theme.spacing(3),
    margin: 0,
  },
  font: {
    fontWeight: 500,
    color: theme.palette.common.black
  },
  closebutton: {
    marginRight: theme.spacing(2),
    textTransform: 'none'
  }
}));

type AddNewSignProps = {
	open:boolean,
	onClose:()=>void,
	documents:DocumentsType[]
}

const AddNewSignModal:FC<AddNewSignProps> = ({ open, onClose, documents }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams<{id:string}>();
  const queryCache = useQueryCache();
  const [currentDocument, setCurrentDocumentSelected] = useState<any>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [newsignature, newSignatureResponse] = useMutation(addNewSignature, {
    onSuccess: () => {
      newSignatureResponse.reset();
      queryCache.invalidateQueries(['getSignatures', id]);
	  onClose();
    },
    onError: (error:any) => {
      if (error.response.errors) {
        setErrors(error.response.errors.map((err) => err.message));
      }
    }
  });

  const flattedMap = documents
    .filter((d) => d.data.some((doc) => doc.kind.includes('signer')))
    .map((h) => h.archives.map((f) => f.files)).flat(2);

  const AddNewSign = useCallback((source:string, embedded:boolean) => {
    newsignature({
      id,
      source,
	  embedded
    });
  }, [id, newsignature]);

  const handleSubmit = (event) => {
    event.persist();
    event.preventDefault();
    const localSource = event.target.source.value as string;
    const embeded = event.target.embedded.checked;
    AddNewSign(localSource, embeded);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
		  if (reason !== 'backdropClick') {
		    onClose();
		  }
      }}
      fullWidth
      className={classes.removecontentPadding}
    >
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <Grid container direction="column" className={classes.itempaddinType}>
          {!!errors.length && (
          <Grid item xs={12}>
            <Alert
              severity="error"
              classes={{ icon: classes.errorIconContainer }}
            >
              {errors.map((error) => (
                <Typography variant="subtitle1">{error}</Typography>
              ))}
            </Alert>
          </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.font}>{t('documents:send-doc')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              value={currentDocument || ''}
              data={flattedMap || []}
              dataKey="source"
              dataValue="source"
              dataText="filename"
              name="source"
              label="Select Document"
              handleChange={(doc) => {
                setCurrentDocumentSelected(doc.target.value);
			 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Checkbox
                name="embedded"
              />
              <Typography variant="body2">{t('documents:document-ron')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="primary"
                className={classes.closebutton}
                onClick={() => { onClose(); }}
              >
                <Typography variant="body2" className={classes.font}>{(t('common:close'))}</Typography>
              </Button>
              <SaveButton
                type="submit"
                loading={newSignatureResponse.isLoading}
                disabled={currentDocument === null || currentDocument === ''}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default AddNewSignModal;
