import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import { TFunction } from 'i18next';
import React from 'react';

type Props = {
  t: TFunction,
  isValid: boolean,
  openCreateProrationModal: () => void,
  disableAddProration: boolean
};

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

export const Header = ({ t, isValid, openCreateProrationModal, disableAddProration }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      className={classes.headerContainer}
    >
      <Grid item>
        <Typography variant="h5" className={classes.headerTitle}>
          {t('taxesAndProrations:taxes-and-prorations')}
        </Typography>
      </Grid>

      <Grid item>
        <ContainedButton
          text="Add"
          icon="plus"
          onClick={openCreateProrationModal}
          disabled={disableAddProration}
        />
      </Grid>
    </Grid>
  );
};
