import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Tabs,
  Typography
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { CustomTab } from 'components/common/custom-tab';
import SelectField from 'components/select-field';
import { ListingEntry } from 'graphql/listings/queries';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import OrderCreateDocument from './components/order-create-document';
import { Fields } from './modal-create-orders-hooks';

const useStyles = makeStyles((theme) => ({
  container: { overflow: 'hidden' },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  buttonsContainer: { marginBottom: theme.spacing(1) },
  createButtonContainer: { marginLeft: theme.spacing(2) },
  buttonText: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
  },
}));

type ModalCreateOrdersSceneProps = {
  t: TFunction;
  open: boolean;
  methods: UseFormReturn<Fields, any>;
  transactionTypes: ListingEntry[];
  types: ListingEntry[];
  workflows: ListingEntry[];
  onClose: () => void;
  onCancel: () => void;
  selectTransactionType: (code: string) => void;
  handleOnSubmit: (data: Fields) => void;
  onUploadDocument: (param:any)=>void
};

const ModalCreateOrdersScene: FC<ModalCreateOrdersSceneProps> = ({
  t,
  open,
  methods,
  transactionTypes,
  types,
  workflows,
  onClose,
  onCancel,
  selectTransactionType,
  handleOnSubmit,
  onUploadDocument
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('standard');
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent className={classes.container}>
        <TabContext value={currentTab}>
          <Box>
            <Tabs
              variant="fullWidth"
              value={currentTab}
              orientation="horizontal"
              onChange={(_, value) => setCurrentTab(value)}
            >
              <CustomTab value="standard" currentValue={currentTab} text={t('orders:create-order')} />
              <CustomTab value="document" currentValue={currentTab} text="Use document" />
            </Tabs>
          </Box>
          <TabPanel value="standard">
            <Grid container direction="column" spacing={2}>

              <Grid item xs={12}>
                <Typography>
                  {t('orders:create-order-leme')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={6}>
                      <Controller
                        name="transactionType"
                        control={methods.control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SelectField
                            {...field}
                            inputRef={ref}
                            data={transactionTypes}
                            dataKey="code"
                            dataText="description"
                            dataValue="code"
                            label={t('common:transaction-types')}
                            handleChange={(event) => {
                              selectTransactionType(event.target.value);
                              onChange(event);
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name="type"
                        control={methods.control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SelectField
                            {...field}
                            inputRef={ref}
                            data={types}
                            dataKey="code"
                            dataText="description"
                            dataValue="code"
                            disabled={types.length === 0}
                            label={t('common:type')}
                            handleChange={onChange}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name="workflow"
                        control={methods.control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SelectField
                            {...field}
                            inputRef={ref}
                            data={workflows}
                            dataKey="code"
                            dataText="description"
                            dataValue="code"
                            disabled={workflows.length === 0}
                            label={t('common:workflow')}
                            handleChange={onChange}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} className={classes.buttonsContainer}>
                      <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                          <Button className={classes.buttonText} onClick={onCancel}>
                            {t('common:cancel')}
                          </Button>
                        </Grid>

                        <Grid item className={classes.createButtonContainer}>
                          <Button
                            disableElevation
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.buttonText}
                          >
                            {t('orders:create-order')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="document">
            <OrderCreateDocument onUploadDocument={onUploadDocument} />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  );
};

export default ModalCreateOrdersScene;
