import { capitalize } from '@material-ui/core';
import { useDeleteOrderLedgerLink } from 'hooks/useDeleteOrderLedgerLink';
import { useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { AccountingType } from 'v2-types/order';
import { getOrder } from 'views/orders/accounting/services/querys';
import useDeleteEntry from 'utils/ledger/use-delete-ledger-entry';
import { initialstateModals, reducerModals } from '../accounting/services';

const useDisbursementsReceipts = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(reducerModals, initialstateModals);
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const showAlert = useAlert();

  const getOrderAccounting = useQuery(['accounting-order', orderId],
    () => getOrder(orderId),
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
    });

  const updateAccountingCache = useCallback((data: AccountingType[]) => {
    queryCache.setQueryData(
      ['accounting-order', orderId],
		  { getOrder: { accounting: data } },
		  { cacheTime: 50 * (60 * 1000) }
    );
	  }, [orderId, queryCache]);

  const [deleteLink, deleteLinkResponse] = useDeleteOrderLedgerLink({
    queryConfig: {
		  onSuccess: (data) => {
        	if (data) {
			  showAlert(capitalize(t('dialogs:order-updated')), 'success');
			  deleteLinkResponse.reset();
			  updateAccountingCache(data.deleteOrderLedgerLink?.accounting || []);
        	}
		  },
		  onError: () => {
        	showAlert(capitalize(t('dialogs:there-is-an')), 'error');
		  }
    	}
	  });

  const { deleteEntry, deleteEntryResponse } = useDeleteEntry((data) => {
    if (data) {
      showAlert(capitalize(t('dialogs:order-updated')), 'success');
      deleteEntryResponse.reset();
      updateAccountingCache(data.deleteOrderLedgerEntry?.accounting || []);
    }
  },
  () => { showAlert(capitalize(t('dialogs:there-is-an')), 'error'); }
  );

  const deleteDisbursement = useCallback((accountingId:string) => {
    deleteEntry({
      id: orderId,
      accountingId
    });
  }, [deleteEntry, orderId]);

  const handledeleteLink = (order_id:string, paymentRecordId:string, associationKind:string, associationId:string, associationLedgerRow:string) => {
    deleteLink({
		  orderId: order_id,
		  targetEntryId: associationLedgerRow,
		  accountingId: paymentRecordId,
		  targetAssociationId: associationId,
		  targetAssociationKind: associationKind
    });
  };

  return {
    data: getOrderAccounting.data?.getOrder.accounting,
    state,
    dispatch,
    updateAccountingCache,
    handledeleteLink,
    deleteDisbursement
  };
};

export default useDisbursementsReceipts;
