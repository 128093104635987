import { FormikErrors } from 'formik';
import { TFunction } from 'i18next';
import React from 'react';
import { DisclosuresType, EstimatedTaxesType, ProjectedPaymentsType } from 'types/disclosures';
import { formatStringInputToNumber } from 'utils/helpers';

import AmountFields from '../components/amount-fields';
import IncludesFields from '../components/includes-fields';
import InEscrowFields from '../components/InEscrowFields';
import InputCell from '../components/input-cell';
import InterestOnlyFields from '../components/interest-only-fields';
import PrincipalInterestAmountFields from '../components/principal-interest-amount-fields';
import YearsRangeFields from '../components/years-range-fields';

export const generatePayload = (values: ProjectedPaymentsType, disclosures: DisclosuresType): { disclosures: DisclosuresType } => ({
  disclosures: {
    ...disclosures,
    projected_payments: {
      ...values,
      status: true,
    },
  },
});

export type PaymentsProps = {
  t: TFunction,
  setFieldValue: any;
  year_range_from: number;
  year_raneg_to: number;
  principal_interest_amount: number;
  interest_only: boolean;
  mortgage_insurance: number;
  estimated_escrow: number;
  principal_interest_min: number;
  principal_interest_max: number;
  principal_interest_type: 'amount' | 'range';
  errors: FormikErrors<any>;
};

type EstimatedTaxesRowsProps = {
  t: TFunction,
  estimatedTaxesTable: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  includes_property_taxes: boolean;
  includes_homeowners_insurance: boolean;
  includes_other: boolean;
  description: string;
  in_escrow_includes_other: boolean;
  in_escrow_includes_property_taxes: boolean;
  in_escrow_includes_homeowners_insurance: boolean;
  periodicity: string;
  errors: FormikErrors<any>;
};

export const getEstimatedTaxesRows = ({
  t,
  estimatedTaxesTable,
  setFieldValue,
  includes_other,
  description,
  includes_homeowners_insurance,
  includes_property_taxes,
  in_escrow_includes_other,
  in_escrow_includes_homeowners_insurance,
  in_escrow_includes_property_taxes,
  periodicity,
  errors,
}: EstimatedTaxesRowsProps) => ({
  amount: {
    title: t('projectedPayments:amount'),
    render: <AmountFields
      emptyValue={false}
      data={[
        {
          name: 'per_month',
          text: t('projectedPayments:per-month')
        },
        {
          name: 'per_week',
          text: t('projectedPayments:per-week')
        },
      ]}
      dataKey="name"
      dataValue="name"
      dataText="text"
      periodicity={periodicity || ''}
      estimatedTaxesTable={estimatedTaxesTable}
      onChangeInput={setFieldValue}
      errors={errors}
      t={t}
    />
  },
  includes: {
    title: t('projectedPayments:includes'),
    render: <IncludesFields
      onSwitchChange={setFieldValue}
      other={includes_other}
      description={description}
      homeownersInsurance={includes_homeowners_insurance}
      propertiesTaxes={includes_property_taxes}
      t={t}
      setFieldValue={setFieldValue}
    />
  },
  inEscrow: {
    title: t('projectedPayments:inEscrow'),
    render: <InEscrowFields
      other={includes_other}
      homeownersInsurance={includes_homeowners_insurance}
      propertiesTaxes={includes_property_taxes}
      in_escrow_includes_other={in_escrow_includes_other}
      in_escrow_includes_homeowners_insurance={in_escrow_includes_homeowners_insurance}
      in_escrow_includes_property_taxes={in_escrow_includes_property_taxes}
      onSwitchChange={setFieldValue}
      t={t}
    />
  }
});

export const getPaymentsRows = ({
  t,
  setFieldValue,
  year_range_from,
  year_raneg_to,
  principal_interest_amount,
  interest_only,
  mortgage_insurance,
  estimated_escrow,
  principal_interest_type,
  principal_interest_min,
  principal_interest_max,
  errors,
}: any) => ({
  year_ranges: {
    title: t('projectedPayments:years-range'),
    render: <YearsRangeFields
      year_raneg_to={year_raneg_to}
      year_range_from={year_range_from}
      onChangeField={setFieldValue}
      errors={errors}
    />
  },
  principal_interest_amount: {
    title: t('projectedPayments:principalInterest'),
    render: <PrincipalInterestAmountFields
      emptyValue={false}
      data={[{
        name: 'amount',
        text: t('projectedPayments:amount'),
      }, {
        name: 'range',
        text: t('projectedPayments:range'),
      }]}
      dataKey="name"
      dataValue="name"
      dataText="text"
      principal_interest_amount={principal_interest_amount}
      principal_interest_type={principal_interest_type}
      onChangeField={setFieldValue}
      principal_interest_min={principal_interest_min}
      principal_interest_max={principal_interest_max}
      errors={errors}
    />
  },
  interest_only: {
    title: t('projectedPayments:interestOnly?'),
    render: <InterestOnlyFields interest_only={interest_only} onChangeInput={setFieldValue} t={t} />
  },
  mortgage_insurance: {
    title: t('projectedPayments:mortgageInsurance'),
    render: (
      <InputCell
        helperText={errors?.payments?.mortgage_insurance}
        value={mortgage_insurance}
        onChangeInput={(e: any) => {
          const value = formatStringInputToNumber(e.target.value, true);
          setFieldValue('payments.mortgage_insurance', value);
        }}
      />
    ),
  },
  estimated_escrow: {
    title: t('projectedPayments:estimatedEscrow'),
    render: (
      <InputCell
        helperText={errors?.payments?.estimated_escrow}
        value={estimated_escrow}
        onChangeInput={(e: any) => {
          const value = formatStringInputToNumber(e.target.value, true);
          setFieldValue('payments.estimated_escrow', value);
        }}
      />
    )
  },
});

export const getTablePayments = ({ paymentsTable }) => {
  const {
    year_range_from,
    year_raneg_to,
    principal_interest_amount,
    interest_only,
    mortgage_insurance,
    estimated_escrow,
  } = paymentsTable;

  const paymentsInputs = paymentsTable ? {
    year_ranges: [year_range_from, year_raneg_to],
    principal_interest_amount,
    interest_only,
    mortgage_insurance,
    estimated_escrow,
  } : {};

  const paymentTableInputs = Object.keys(paymentsInputs);
  return paymentTableInputs;
};

export const getPaymentInputs = (estimatedTaxesTable: EstimatedTaxesType) => {
  const { includes_other, includes_homeowners_insurance, includes_property_taxes, amount } = estimatedTaxesTable;
  const shouldRenderInEscrowRow = () => !!Object.values({ includes_other, includes_homeowners_insurance, includes_property_taxes, })?.find((item) => !!item);

  // If any switch is on, then displays in escrow row in table
  const paymentsInputs = shouldRenderInEscrowRow() ? {
    amount,
    includes: '',
    inEscrow: '',
  } : {
    amount,
    includes: '',
  };

  return paymentsInputs;
};

export const getPaymentTableInputs = (estimatedTaxesTable: EstimatedTaxesType) => {
  const { includes_other, includes_homeowners_insurance, includes_property_taxes, amount } = estimatedTaxesTable;
  const shouldRenderInEscrowRow = () => !!Object.values({ includes_other, includes_homeowners_insurance, includes_property_taxes, })?.find((item) => !!item);
  const paymentsInputs = shouldRenderInEscrowRow() ? {
    amount,
    includes: '',
    inEscrow: '',
  } : {
    amount,
    includes: '',
  };
  const paymentTableInputs = Object.keys(paymentsInputs);
  return paymentTableInputs;
};
