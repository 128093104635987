import React, { FC } from 'react';

import useTabSignature from './tab-signature-hooks';
import TabSignatureScene from './tab-signature-scene';

type TypeSignature = {
  roleParty: string
}

const TabSignature:FC<TypeSignature> = ({ roleParty }) => {
  const {
    t,
    include_vesting_value,
    single_signature_methods,
    formMethods
  } = useTabSignature(roleParty);
  return (
    <TabSignatureScene
      t={t}
      haveSpouse
      organization={false}
      include_vesting_value={include_vesting_value}
      single_signature_methods={single_signature_methods}
      formMethods={formMethods}
      prefix={roleParty === 'Buyer' ? 'data_buyer' : 'data_seller'}
    />
  );
};

export default TabSignature;
