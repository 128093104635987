import gqlRequest from "graphql/generic";
import { AutoTemplate, Tag } from "types/auto-templates";

type GetAutoTemplates = {
  getAutoTemplates: AutoTemplate[];
};

const query = `
query GetAutoTemplates {
  getAutoTemplates {
    _id
    data {
      signer
      property
      party
      deliver
    }
    code
    name
    per
    triggers {
      transaction_type
      type
      workflow
    }
  }
}
`;

export const getDocumentTemplates = () =>
  gqlRequest<GetAutoTemplates, undefined>(query);

type GetTemplateTags = {
  getTemplateTags: Tag[];
};

const getTemplateTagsQuery = `
    query getTemplateTags {
      getTemplateTags {
        _id
        kind
        description
      }
    }
  `;

export const getTemplateTags = () =>
  gqlRequest<GetTemplateTags, null>(getTemplateTagsQuery);
