export const convertTextToTokenArray = ({ text }: { text: string }) => text.replace(/[^a-zA-Z0-9 ]/g, '\\$&').toLowerCase().split(/\s+/);

type GetTokens = {
  query: string,
  party: string,
  property: string
};

type TokenResult = {
  queryTokens?: string[],
  partyTokens?: string[],
  propertyTokens?: string[]
}

export const getTokens = ({
  query,
  party,
  property
}: GetTokens) => {
  const tokenArray: TokenResult = {};

  if (query.length > 0) {
    tokenArray.queryTokens = convertTextToTokenArray({ text: query }).filter((token) => token.length >= 1);
  }

  if (party.length > 0) {
    tokenArray.partyTokens = convertTextToTokenArray({ text: party }).filter((token) => token.length >= 3);
  }

  if (property.length > 0) {
    tokenArray.propertyTokens = convertTextToTokenArray({ text: property }).filter((token) => token.length >= 3);
  }

  return tokenArray;
};
