import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import services from '../../services/endpoints';
import useAlert from '../../utils/alert';

export default function useResetPassword() {
  const showAlert = useAlert();
  const history = useHistory();
  const { t } = useTranslation();
  const { isAuthenticated, isLoading } = useAuth0();
  const [resetPassword, responseResetPassword] = useMutation(
    services.resetPassword
  );

  useEffect(() => {
    if (responseResetPassword.isSuccess) {
      showAlert(t('auth:email-sent'), 'success');
      responseResetPassword.reset();
    }
    if (responseResetPassword.isError) {
      showAlert(t('auth:error'), 'error');
      responseResetPassword.reset();
    }
  }, [showAlert, responseResetPassword, t]);

  return {
    t,
    resetPassword,
    history,
    isAuthenticated,
    isLoading,
  };
}
