import { TitleWithTextValue } from 'components/title-with-text-value';
import React, { FC } from 'react';

type BalancingItemProps = {
  title: string,
  value: number,
  isPositiveIncome?: boolean,
  isNegativeIncome?: boolean
}

export const BalancingItem: FC<BalancingItemProps> = ({
  title,
  value,
  isPositiveIncome,
  isNegativeIncome,
}) => {
  let adorment: string | null = null;

  if (isPositiveIncome || isNegativeIncome) {
    adorment = isPositiveIncome ? '+' : '-';
  }
  return (
    <TitleWithTextValue
      title={title}
      value={value}
      valueIsMoney
      direction="row"
      justifyContent="space-between"
      valueLeftAdorment={adorment}
    />
  );
};
