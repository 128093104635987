import { PaperCard } from 'components/cards/paper-card';
import { PropertiesMap } from 'components/PropertiesMap';
import React from 'react';
import { OrderProperty } from 'types/order';

type MapOverviewSceneProps = {
  properties: OrderProperty[],
  selectProperty: (id: string) => void;
  selectedProperty: OrderProperty | undefined
};

export const MapOverviewScene = ({
  properties,
  selectProperty,
  selectedProperty,
}: MapOverviewSceneProps) => (
  <PaperCard cardPadding={0}>
    <PropertiesMap
      properties={properties}
      onSelectProperty={selectProperty}
      selectedPropertyId={selectedProperty?._id ?? ''}
      direction="vertical"
      mapHeight="260px"
      padding="0"
      mapInitZoom={4.5}
      elevation={0}
      maxSeleceCharacterLength={45}
    />
  </PaperCard>
);
