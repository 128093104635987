import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  container: { width: '100%', padding: theme.spacing(3) },
  mainContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(10),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 23,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
  },
  documentsContainer: {
    margin: '32px 0',
    padding: '0 32px',
  },
  rowContainer: { borderLeft: '4px solid #cfd8dc' },
  mergedDocumentsText: {
    '& span': {
      width: '90%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  filterActionsContainer: {
    borderBottom: '1px solid lightgray',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  searchBox: {
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
      padding: '0.7rem'
    },
    '& .MuiFilledInput-inputHiddenLabel': { padding: 0 }
  },
  searchIcon: { color: theme.palette.text.shadow, fontSize: '0.875rem' },
}));

export default useStyles;
