import { Box, makeStyles, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import TableComponent from 'components/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'types/common';

export type ColumnsType = { [key: string]: Column };

const CellLoading = () => (
  <Skeleton animation="wave" variant="text" />
);

const generateColumns = (
  t,
  theme,
): ColumnsType => {
  const columns: ColumnsType = {
	  actions: {
      header: {
		  title: '',
		  minWidth: '80px',
		  width: '80px'
      },
      renderer: () => (
        <CellLoading />
      )
    },
	  number: {
      header: { title: '', },
      renderer: () => (
        <CellLoading />
      )
	  },
	  description: {
      header: {
		  title: t('charges:description'),
		  align: 'left',
		  width: '390px',
		  textColor: theme.palette.primary.light
      },
      renderer: () => (
        <CellLoading />
      )
	  },
	  payee: {
      header: {
		  title: t('charges:payee'),
		  align: 'left',
		  width: '390px',
		  textColor: theme.palette.primary.light
      },
      renderer: () => (
        <CellLoading />
      )
	  },
	  paid_borrower: {
      header: {
		  title: t('charges:paid-by-borrower'),
		  textColor: theme.palette.primary.light
      },
      columns: [
		  {
          header: {
			  title: t('charges:at-closing'),
			  width: '142px',
			  textColor: theme.palette.primary.light
          },
          renderer: () => (
            <CellLoading />
          )
		  },
		  {
          header: {
			  title: t('charges:before-closing'),
			  width: '142px',
			  textColor: theme.palette.primary.light
          },
          renderer: () => (
            <CellLoading />
          )
		  }
      ]
	  },
	  paid_seller: {
      header: {
		  title: t('charges:paid-by-seller'),
		  textColor: theme.palette.primary.light
      },
      columns: [
		  {
          header: {
			  title: t('charges:at-closing'),
			  width: '142px',
			  textColor: theme.palette.primary.light
          },
          renderer: () => (
            <CellLoading />
          )
		  },
		  {
          header: {
			  title: t('charges:before-closing'),
			  width: '142px',
			  textColor: theme.palette.primary.light
          },
          renderer: () => (
            <CellLoading />
          )
		  }
      ]
	  },
	  by_others: {
      header: {
		  title: t('charges:by-others'),
		  width: '142px',
		  textColor: theme.palette.primary.light
      },
      renderer: () => (
        <CellLoading />
      )
	  }
  };

  return columns;
};

const useStyles = makeStyles((theme) => ({
  containerTable: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
	  },
	  scrollDiv: {
    overflowX: 'auto',
    width: '100%',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.5) },
    '&&::-webkit-scrollbar-thumb': {
		  background: theme.palette.secondary.main,
		  borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark },
	  },
}));

const TableLoader = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const columns = generateColumns(t, theme);
  const classes = useStyles();
  return (
    <Box className={`${classes.scrollDiv} ${classes.containerTable}`}>
      <TableComponent
        disabledBorderLeft
        disableFullborderCell
        columns={columns}
        dataSet={[...Array(8)]}
      />
    </Box>
  );
};

export default TableLoader;
