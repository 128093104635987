import gqlRequest from 'graphql/generic';
import { AccountingType } from 'v2-types/order';
import { PolicyType } from 'views/orders/policy-info-and-rates-refactor/services/types';

type UpdateOrder = {
	updateOrderPolicy:{
	  accounting: AccountingType[] | null;
	  lenders_policy: {
		disclosure_section: string
	}
	}
  };

  type UpdateOrderParams = {
	id: string;
	kind: 'owners' | 'lenders',
	data: PolicyType,
  };

const mutationUpdateOrder = `
	mutation UpdateOrderPolicy($id: ID!, $kind: OrderPolicyKinds!, $data: OrderPolicyContent!) {
	  updateOrderPolicy(_id: $id, kind: $kind, data: $data) {
		accounting {
		  _id
		  amount
		  amount_calculated
		  amount_override
		  code
		  description
		  entry_date
		  kind
		  letter
		  number
		  months
		  per_month
		  involved_parties {
			_id
			amount
			at_closing_amount
			at_closing_percent
			before_closing_amount
			before_closing_percent
			kind
			name
			order_kinds
			payment_id
			percent
		  }
		}
		lenders_policy {
			disclosure_section
		  }
	  }
	}
  `;

export const updateOrderPolicy = (payload: UpdateOrderParams) => gqlRequest<UpdateOrder, UpdateOrderParams>(
  mutationUpdateOrder,
  payload,
);
