import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  tableRow: {
    background: theme.palette.background.default,
    '& > *': {
      borderColor: theme.palette.input.border,
      borderWidth: 1,
      borderStyle: 'solid'
    }
  },
  tableCell: { verticalAlign: 'top' },
  containerItems: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: 4,
  },
  row2: {
    width: 200,
    '&>*': { minHeight: theme.spacing(4), }
  },
  cell1: {
    width: '35%',
    verticalAlign: 'middle'
  },
  cell2: { width: '65%', },
}));

const ProjectedPaymentsTable = ({ data, rows }) => {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {data.map((field) => (
            <TableRow key={field} className={classes.tableRow}>
              <TableCell component="th" scope="row" className={`${classes.tableCell} ${classes.row2} ${classes.cell1}`}>
                <Typography>{rows[field]?.title}</Typography>
              </TableCell>

              <TableCell component="th" scope="row" className={`${classes.tableCell} ${classes.row2} ${classes.cell2}`}>
                {rows[field]?.render}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectedPaymentsTable;
