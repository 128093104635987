import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  alpha,
  Button,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TabContext, TabPanel } from '@material-ui/lab';
import championshipLogo from 'assets/images/championship-logo.png';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React from 'react';

import TabComponentFour from './components/tab-component-four';
import TabComponentOne from './components/tab-component-one';
import TabComponentThree from './components/tab-component-three';
import TabComponentTwo from './components/tab-component-two';

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: { borderRight: `1px solid ${theme.palette.divider}` },
  nextButton: { backgroundColor: theme.palette.warning.main },
  nextButton2: { backgroundColor: theme.palette.button.default },
  title: { color: theme.accent.main },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  border: { borderRight: `1px solid ${theme.palette.divider}` },
  paddingSubtitle: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  hover: {
    '&$hover:hover': {
      background: alpha(theme.palette.common.black, 0.5),
      color: theme.palette.secondary.main,
    },
  },
  nextbuttonfontL: {
    fontWeight: 500,
    color: 'black',
  }
}));

type OnboardingTermsAndConditionsSceneProps = {
  t: TFunction;
  theme: any;
  tabIndex: string;
  handleTabChange: (event: React.ChangeEvent<{}>, value: any) => void;
  a11yProps: (index: number) => { id: string; 'aria-controls': string; };
  userAgreed: boolean;
  handleUserAgreed: () => void;
  handleAgreement: () => void;
};

const OnboardingTermsAndConditionsScene = ({
  t,
  theme,
  tabIndex,
  handleTabChange,
  a11yProps,
  userAgreed,
  handleUserAgreed,
  handleAgreement,
}: OnboardingTermsAndConditionsSceneProps) => {
  const classes = useStyles();
  const SM = useMediaQuery(`(max-width:${theme.spacing(75)}px)`);
  const MD = useMediaQuery(`(max-width:${theme.spacing(120)}px)`);

  return (
    <Grid container style={{ color: theme.palette.common.white }}>
      <TabContext value={tabIndex}>
        <Grid container item xs={12} md={3}>
          <Grid container item xs={12} md={4} />
          <Grid
            container
            item
            xs={12}
            md={8}
            style={{ flexDirection: 'column', display: 'flex' }}
          >
            <Grid
              item
              xs={12}
              md={8}
              style={{ display: 'flex', flex: 1, marginTop: theme.spacing(2) }}
              className={clsx({ [classes.centerContainer]: MD })}
            >
              <img
                src={championshipLogo}
                alt="logo"
                style={{ width: theme.spacing(20), alignSelf: 'center' }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                paddingTop: MD ? theme.spacing(3) : 0,
                paddingBottom: MD ? 0 : theme.spacing(3),
              }}
              className={clsx(classes.border, { [classes.centerContainer]: MD })}
            >
              <Typography variant="h4" className={classes.title}>
                {t('onboarding:terms-of-service')}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: 'flex', flex: 1 }}
              className={clsx(classes.border, {
                [classes.centerContainer]: MD,
                [classes.paddingSubtitle]: MD
              })}
            >
              <Typography variant="subtitle2">{t('onboarding:read-terms-of-service')}</Typography>
            </Grid>

            {!MD && (
              <Grid
                item
                xs={12}
                style={{
                  flex: 4,
                  display: 'flex',
                  alignSelf: 'flex-end',
                  paddingTop: theme.spacing(3),
                }}
                className={classes.border}
              >
                <Tabs
                  data-testid="tabs-terms-and-condition"
                  orientation={SM ? 'horizontal' : 'vertical'}
                  variant="fullWidth"
                  value={tabIndex}
                  onChange={handleTabChange}
                  className={classes.tabs}
                >
                  <Tab className={classes.hover} value="1" label={t('onboarding:terms-item-one')} {...a11yProps(0)} />
                  <Tab className={classes.hover} value="2" label={t('onboarding:terms-item-two')} {...a11yProps(1)} />
                  <Tab className={classes.hover} value="3" label={t('onboarding:terms-item-three')} {...a11yProps(2)} />
                  <Tab className={classes.hover} value="4" label={t('onboarding:terms-item-four')} {...a11yProps(3)} />
                </Tabs>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={9}
          style={{
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(3),
            marginTop: MD ? theme.spacing(3) : theme.spacing(10),
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          {MD ? (
            <>
              <TabComponentOne t={t} />
              <TabComponentTwo t={t} />
              <TabComponentThree t={t} />
              <TabComponentFour t={t} />
            </>
          ) : (
            <>
              <TabPanel value="1"><TabComponentOne t={t} /></TabPanel>
              <TabPanel value="2"><TabComponentTwo t={t} /></TabPanel>
              <TabPanel value="3"><TabComponentThree t={t} /></TabPanel>
              <TabPanel value="4"><TabComponentFour t={t} /></TabPanel>
            </>
          )}

          <Grid
            container
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              padding: SM ? 0 : theme.spacing(3),
              justifyContent: SM ? 'center' : 'space-between',
            }}
          >
            <Grid>
              <Switch
                checked={userAgreed}
                onChange={handleUserAgreed}
                name="agreement"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />

              <Typography variant="caption">{t('onboarding:agree-terms-of-service')}</Typography>
            </Grid>

            <Button
              disabled={!userAgreed}
              style={{
                textTransform: 'none',
                marginLeft: theme.spacing(2),
                margin: SM ? theme.spacing(3) : 0,
              }}
              color="secondary"
              onClick={handleAgreement}
              variant="contained"
              classes={{ disabled: classes.nextButton, }}
            >
              <Typography variant="body2" className={classes.nextbuttonfontL}>
                {t('common:next')}
              </Typography>

              <FontAwesomeIcon
                icon="paper-plane"
                size="1x"
                style={{ marginLeft: theme.spacing(1) }}
                className={classes.nextbuttonfontL}
              />
            </Button>
          </Grid>
        </Grid>
      </TabContext>
    </Grid>
  );
};

export default OnboardingTermsAndConditionsScene;
