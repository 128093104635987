import { AccountingInvolvedParty } from 'v2-types/order';

export const getInitialValues = (prevData?: AccountingInvolvedParty) => ({
  amount: prevData?.amount || 0,
  at_closing_amount: prevData?.at_closing_amount || 0,
  at_closing_percent: prevData?.at_closing_percent ? prevData?.at_closing_percent * 100 : 0,
  before_closing_amount: prevData?.before_closing_amount || 0,
  before_closing_percent: prevData?.before_closing_percent ? prevData?.before_closing_percent * 100 : 0,
  payment_id: prevData?.payment_id || null,
  percent: prevData?.percent ? prevData?.percent * 100 : 0,
  kind: prevData?.kind || '',
  party_id: ''
});
