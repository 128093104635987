import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Autocomplete as MUIAutocomplete,
  AutocompleteInputChangeReason,
} from '@material-ui/lab';
import { useTheme } from '@material-ui/styles';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { OrderListingType } from 'types/order';
import { CURRENT_PATH, DEFAULT_ROUTE } from 'utils/constants';
import { IntlCurrencyFormat } from 'utils/helpers';

const useStyles = makeStyles((theme: any) => ({
  toolbarTextfield: { color: theme.palette.grey[50], },
  toolbarTextfieldFocused: { color: theme.accent.main, },
  ListboxLabelContainer: { paddingLeft: theme.spacing(2) },
  ListboxLabel: { color: theme.palette.tab.offselected },
  renderContainer: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.grey[400],
  },
  renderLeft: {
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  renderRight: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[400],
  },
  renderValueContainer: { paddingBottom: theme.spacing(1) },
  renderValue: {
    color: theme.palette.primary.darker,
    fontWeight: 'bold',
  },
  renderLabel: {
    color: theme.palette.primary.light,
    lineHeight: '70%',
    textTransform: 'uppercase',
  },
}));

type AutocompleteProps = {
  t: TFunction;
  options: OrderListingType[];
  loading: boolean;
  updateSearchText: (text: string) => void;
};

const Autocomplete: FC<AutocompleteProps> = ({
  t,
  options,
  loading,
  updateSearchText,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme<any>();

  const onInputChange = (_: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      updateSearchText(value);
    }

    if (reason === 'clear') {
      updateSearchText('');
    }
  };

  const onChange = (_: React.ChangeEvent<{}>, option: OrderListingType | null) => {
    if (option !== null) {
      localStorage.setItem(CURRENT_PATH, JSON.stringify({ view: DEFAULT_ROUTE, param: '' }));
      history.push(`/orders/details/${option?._id}/${DEFAULT_ROUTE}`);
    }
  };

  return (
    <MUIAutocomplete
      options={options}
      filterOptions={(option) => option}
      loading={loading}
      getOptionLabel={(option) => option.order_id}
      onInputChange={onInputChange}
      onChange={onChange}
      noOptionsText={t('common:no-search-orders')}
      renderInput={(props) => (
        <TextField
          {...props}
          ref={props.InputProps.ref}
          placeholder={t('common:find-order')}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon="search" size="1x" />
              </InputAdornment>
            ),
            classes: {
              root: classes.toolbarTextfield,
              focused: classes.toolbarTextfieldFocused,
            }
          }}
        />
      )}
      renderOption={(option) => (
        <Grid container direction="row" className={classes.renderContainer}>
          <Grid item xs={4} className={classes.renderLeft}>
            <Typography variant="subtitle1" className={classes.renderValue}>
              {option.order_id}
            </Typography>
          </Grid>

          <Grid item xs={8} className={classes.renderRight}>
            <Box className={classes.renderValueContainer}>
              <Typography variant="subtitle2" className={classes.renderLabel}>
                {t('orders:property')}
              </Typography>

              <Typography variant="subtitle1" className={classes.renderValue}>
                {option.address || t('common:no-defined-yet')}
              </Typography>
            </Box>

            <Box className={classes.renderValueContainer}>
              <Typography variant="subtitle2" className={classes.renderLabel}>
                {t('orders:purchase-price')}
              </Typography>

              <Typography variant="subtitle1" className={classes.renderValue}>
                {option.purchase_price ? IntlCurrencyFormat(option.purchase_price) : t('common:no-defined-yet')}
              </Typography>
            </Box>

            <Box className={classes.renderValueContainer}>
              <Typography variant="subtitle2" className={classes.renderLabel}>
                {t('orders:loan-amount')}
              </Typography>

              <Typography variant="subtitle1" className={classes.renderValue}>
                {option.loan_amount ? IntlCurrencyFormat(option.loan_amount) : t('common:no-defined-yet')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      PopperComponent={(props) => (
        <Popper
          {...props}
          style={{ width: 600 }}
          placement="bottom-start"
        />
      )}
      PaperComponent={(props) => (
        <Paper
          {...props}
          style={{ backgroundColor: theme.palette.background.default }}
        />
      )}
      ListboxComponent={(props) => (
        <Box {...props} role="listbox">
          <Box className={classes.ListboxLabelContainer}>
            <Typography className={classes.ListboxLabel}>
              {t('orders:order')}
            </Typography>
          </Box>

          {props.children}
        </Box>
      )}
    />
  );
};

export default Autocomplete;
