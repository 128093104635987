/* eslint-disable no-unused-expressions */
import { capitalize } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { AccountingType } from 'v2-types/order';

import { createLedgerLink } from './graphql/mutations';
import { createlinkForm } from './services';

const useLinkentry = (handleUpdateLedger:(accounting:AccountingType[])=>void, currentLedgerEntry:AccountingType, handleClose:()=>void) => {
  const { t } = useTranslation();
  const showAlert = useAlert();
  const { id } = useParams<{id: string}>();
  const [createErrors, setCreateErrors] = useState<string[]>([]);
  const methods = useForm<createlinkForm>();
  const [createLedgerLinkMutation, createLedgerLinkResponse] = useMutation(createLedgerLink, {
    onSuccess: (data) => {
      if (data) {
        showAlert(capitalize(t('dialogs:order-updated')), 'success');
        createLedgerLinkResponse.reset();
        handleUpdateLedger(data?.createOrderLedgerLink?.accounting || []);
        handleClose();
	  }
    },
    onError: (e: any) => {
      const { errors = ['Error Adding Association'] } = e?.response || {};
      const errorsMapped = errors.map((error: any) => error.message);
      setCreateErrors(errorsMapped);
	  },
  });

  const handleCreationLink = useCallback((targetEntryId, targetAssociationId, targetAssociationKind, amount) => {
    createLedgerLinkMutation({
      id,
      accountingId: currentLedgerEntry._id,
      targetEntryId,
      targetAssociationId,
      targetAssociationKind,
	  amount: parseFloat(amount as string) || 0
    });
  }, [createLedgerLinkMutation, currentLedgerEntry._id, id]);

  const handeSubmit = methods.handleSubmit((data) => {
    handleCreationLink(data.targetEntryId, data.targetAssociationId, data.targetAssociationKind, data.amount);
  });
  return {
    t,
    handleCreationLink,
    methods,
    handeSubmit,
    createErrors,
    loadingCreation: createLedgerLinkResponse.isLoading
  };
};

export default useLinkentry;
