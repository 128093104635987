import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAccountingEntryByLedgerCode } from 'utils/accounting';
import { LEDGER_CODE_LOAN_AMOUNT, LEDGER_CODE_PURCHASE_PRICE } from 'utils/constants';
import { AccountingType } from 'v2-types/order';

import { transactionTypesTranslations } from '../../services';

type UseGeneralInformationParams = {
  accountingPremium: AccountingType[]
}

export const useGeneralInformation = ({
  accountingPremium
}: UseGeneralInformationParams) => {
  const { t } = useTranslation();
  const [isAccountingTotalsDrawerOpen, setIsAccountingTotalsDrawerOpen] = useState(false);

  const transactionTypes = transactionTypesTranslations(t);

  const getEntryByLedgerCode = (ledgerCode: string) => getAccountingEntryByLedgerCode({ accounting: accountingPremium, ledgerCode });
  const totalLoanAmount = getEntryByLedgerCode(LEDGER_CODE_LOAN_AMOUNT)?.amount || 0;
  const totalPurchasePrice = getEntryByLedgerCode(LEDGER_CODE_PURCHASE_PRICE)?.amount || 0;

  return {
    t,
    isAccountingTotalsDrawerOpen,
    setIsAccountingTotalsDrawerOpen,
    totalLoanAmount,
    totalPurchasePrice,
  };
};
