import { Box, Radio, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { LoanFormType } from '../../types';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>,
  isLoanLoading: boolean
}

export const AmountKindForm: FC<Props> = ({ loanForm, isLoanLoading }) => {
  const { control } = loanForm;
  return (
    <Box display="flex">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Controller
          control={control}
          name="amount_kind"
          render={({ field }) => (
            <Radio
              onChange={(e) => {
                e.stopPropagation();
                field.onChange('net');
              }}
              checked={field.value === 'net'}
              inputProps={{ 'aria-label': 'Radio A', }}
              disabled={isLoanLoading}
            />
          )}
        />
        <Typography variant="body2">Net</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Controller
          control={control}
          name="amount_kind"
          render={({ field }) => (
            <Radio
              onChange={(e) => {
                e.stopPropagation();
                field.onChange('gross');
              }}
              checked={field.value === 'gross'}
              inputProps={{ 'aria-label': 'Radio A', }}
              disabled={isLoanLoading}
            />
          )}
        />
        <Typography variant="body2">Gross</Typography>
      </Box>
    </Box>
  );
};
