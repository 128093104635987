import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import ModalAddParty from 'components/add-contact-modal/add-contact-modal';
import { ListingEntry } from 'graphql/listings/queries';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NewOrderPartyType } from 'types/order';

import EmptyContainer from './components/emptyContainer';
import SideMenu from './components/side-menu/side-menu';
import TreeParties from './components/tree-parties/tree-parties';
import { PartyInfo } from './party/PartyInfo';
import { partiesfiltered } from './services';
import PartiesContext from './services/context';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(3) },
  sidemenuContainer: { paddingRight: theme.spacing(2) },
  paperContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    paddingInline: theme.spacing(4),
  },
  headerContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  spacing: { marginRight: theme.spacing(1) },
  displayButton: {
    fontSize: '1rem',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.black,
  },
  displayButtonActive: { backgroundColor: theme.palette.button.default },
}));

type PartiesSceneProps = {
  t: TFunction;
  url: string;
  path: string;
  filteredparties: partiesfiltered[];
  handleViewChange: (partytype: string, id: string) => void;
  handleCreateAssociation: (
    partyId: string,
    kind: string,
    action: () => void
  ) => void;
  handleDeleteAssociation: (partyId: string, kind: string) => void;
  unfilteredParties: NewOrderPartyType[];
  filterParties: (parties: NewOrderPartyType[]) => void;
  openAddModal: boolean;
  setOpenAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  listingEntries: ListingEntry[];
};

const PartiesScene: FC<PartiesSceneProps> = ({
  t,
  url,
  path,
  filteredparties,
  handleViewChange,
  handleCreateAssociation,
  handleDeleteAssociation,
  unfilteredParties,
  filterParties,
  openAddModal,
  setOpenAddModal,
  listingEntries,
}) => {
  const classes = useStyles();
  const [displayMode, setDisplayMode] = useState<'list' | 'graph'>('list');

  const handleDisplayMode = (mode: 'list' | 'graph'): void => setDisplayMode(mode);

  return (
    <PartiesContext.Provider
      value={{
        parties: filteredparties,
        unfilteredParties,
        filterParties,
        listingEntries,
      }}
    >
      <Grid container direction="row" className={classes.container}>
        <Grid item xs={12}>
          <Paper className={classes.paperContainer}>
            <Box className={classes.headerContainer}>
              <Typography variant="h6" className={classes.headerTitle}>
                {t('parties:parties')}
              </Typography>

              <Box>
                <IconButton
                  className={clsx(classes.displayButton, { [classes.displayButtonActive]: displayMode === 'list', })}
                  onClick={() => handleDisplayMode('list')}
                >
                  <FontAwesomeIcon icon="list" />
                </IconButton>
              </Box>

              <Box>
                <IconButton
                  className={clsx(classes.displayButton, { [classes.displayButtonActive]: displayMode === 'graph', })}
                  onClick={() => handleDisplayMode('graph')}
                >
                  <FontAwesomeIcon icon="chart-area" />
                </IconButton>
              </Box>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                style={{ height: 28, width: 28 }}
                onClick={() => setOpenAddModal(true)}
              >
                <FontAwesomeIcon icon="plus" />
              </Button>
            </Box>
          </Paper>
        </Grid>
        {displayMode === 'list' ? (
          <>
            <Grid item xs={3} className={classes.sidemenuContainer}>
              <SideMenu
                parties={filteredparties}
                handleViewChange={handleViewChange}
                handleDeleteAssociation={handleDeleteAssociation}
              />
            </Grid>
            <Grid item xs={9}>
              <Switch>
                <Route exact path={path}>
                  <EmptyContainer t={t} />
                </Route>

                <Route exact path={`${path}/:kind/:partyId`}>
                  <PartyInfo />
                </Route>

                <Route path="*">
                  <Redirect to={url} />
                </Route>
              </Switch>
            </Grid>
          </>
        ) : (
          <>
            <TreeParties parties={filteredparties} />
          </>
        )}

        {openAddModal && (
          <ModalAddParty
            open={openAddModal}
            onClose={() => {
              setOpenAddModal(false);
            }}
            onClickAddParty={(partyId, kind, callback) => {
              handleCreateAssociation(partyId, kind, callback);
            }}
            allowedCodeAssociations="allowed_order_associations"
          />
        )}
      </Grid>
    </PartiesContext.Provider>
  );
};

export default PartiesScene;
//
