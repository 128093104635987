import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import VoidOrders from 'components/order-void-screen/orders-void-screen';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { OrderListingType } from 'types/order';

import Header from './components/header';
import ModalCreateOrders from './components/modal-create-orders/modal-create-orders';
import ModalOrderStepper from './components/order-stepper-creation/order-stepper';
import OrdersTabs from './components/order-tabs';
import OrdersTable from './components/orders-table';
import { CurrentData, FiltersType } from './services';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(3) },
  mainContainer: { marginTop: theme.spacing(5) },
  loadingContainer: { height: '60vh' },
  loading: { color: theme.accent.main },
  voidContainer: { height: '70vh' },
}));

type OrdersSceneProps = {
  t: TFunction;
  filter: FiltersType;
  searchString: string;
  orders: OrderListingType[];
  loading: boolean;
  currentData: CurrentData;
  userIsTitleEmployee: boolean;
  handleModalOpen: (state: boolean) => void;
  handleChangeFilter: (value: FiltersType) => void;
  handleSearchOrder: (orderId: string) => void;
  handleSelectRow: (id: string, code: string) => void;
  handleTablePageChange: (
    action: 'prev' | 'next'
  ) => (cuantity?: number) => void;
  agencyName: string;
  agencyId: string;
  state: { mode: string, data?: any },
  handleOpenOrderStepper:(type:string, data:any) => void
};

const OrdersScene: FC<OrdersSceneProps> = ({
  t,
  filter,
  searchString,
  orders,
  loading,
  currentData,
  userIsTitleEmployee,
  handleModalOpen,
  handleChangeFilter,
  handleSearchOrder,
  handleSelectRow,
  handleTablePageChange,
  agencyName,
  agencyId,
  state,
  handleOpenOrderStepper
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Header
            t={t}
            userIsTitleEmployee={userIsTitleEmployee}
            handleModalOpen={handleModalOpen}
            agencyName={agencyName}
          />
        </Grid>

        <Grid item xs={12} className={classes.mainContainer}>
          {!loading && !orders.length && searchString.trim() === '' && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.voidContainer}
            >
              <Grid item>
                <VoidOrders />
              </Grid>
            </Grid>
          )}

          <Grid container>
            {orders.length !== 0 && (
              <Grid item xs={12}>
                <OrdersTabs
                  t={t}
                  filter={filter}
                  searchString={searchString}
                  handleSearchOrder={handleSearchOrder}
                  handleChangeFilter={handleChangeFilter}
                />
              </Grid>
            )}

            {loading && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.loadingContainer}
              >
                <Grid item>
                  <CircularProgress className={classes.loading} />
                </Grid>
              </Grid>
            )}

            {!loading && (
              <Grid item xs={12}>
                <OrdersTable
                  agencyId={agencyId}
                  t={t}
                  currentData={currentData}
                  orders={orders}
                  handleSelectRow={handleSelectRow}
                  handleTablePageChange={handleTablePageChange}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <ModalCreateOrders
        open={state.mode === 'new-order'}
        onCancel={() => handleModalOpen(false)}
        onClose={() => handleModalOpen(false)}
        onUploadDocument={(param) => {
		  handleOpenOrderStepper('start-document-order', param);
        }}
      />
      {state.mode === 'start-document-order' && (
      <ModalOrderStepper
        open={state.mode === 'start-document-order'}
        onClose={() => handleModalOpen(false)}
        pdfInformation={state.data}
      />
      )}
    </>
  );
};

export default OrdersScene;
