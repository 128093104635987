import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { OrderProperty } from 'types/order';

import { useStyles } from './styles';

type PropertyAutocompleteProps<T> = {
  control: Control<OrderProperty, object>;
  name: FieldPath<OrderProperty>;
  label: string;
  data: T[];
  dataValue: keyof T;
  dataText: keyof T;
  required?: boolean;
  isDisabled?: boolean;
  onSelectItem?: Function;
  onTextChange?: Function;
  inputType?: 'text' | 'number';
  isLoading?: boolean;
};

export function PropertyAutocomplete<T>({
  control,
  name,
  label,
  data,
  dataValue,
  dataText,
  required,
  isDisabled = false,
  onSelectItem = () => {},
  onTextChange = () => {},
  inputType = 'text',
  isLoading = false,
}: PropertyAutocompleteProps<T>) {
  const classes = useStyles();
  const defaults = createFilterOptions<T>();
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field }) => (
        <Autocomplete
          disablePortal
          {...field}
          className={classes.autocomplete}
          value={{ ...{}, [dataValue]: field.value } as T}
          disabled={isDisabled}
          options={data}
          freeSolo
          innerRef={field.ref}
          onBlur={field.onBlur}
          onBlurCapture={field.onBlur}
          filterOptions={(options: T[], state) => {
            const results = defaults(options, {
              inputValue: field.value?.toString() ?? '',
              getOptionLabel: state.getOptionLabel,
            }) as T[];
            return results;
          }}
          getOptionLabel={(entry) => entry[dataText.toString()] ?? ''}
          getOptionSelected={(entry) => entry[dataText.toString()] ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={params.InputProps.ref}
              label={label}
              variant="filled"
              type={inputType}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(e, item) => {
            field.onChange(item ? item[dataValue.toString()] : '');
            onSelectItem(item ? item[dataValue.toString()] : '');
          }}
          onInputChange={(e, inputValue) => {
            field.onChange(inputValue);
            onTextChange(inputValue);
          }}
          closeIcon={<FontAwesomeIcon icon="times" size="xs" />}
        />
      )}
    />
  );
}
