import gqlRequest from 'graphql/generic';
import { AccountingType } from 'v2-types/order';

const queryCreateLink = `
mutation CreateOrderLedgerLink($id: ID!, $accountingId: ID!, $targetEntryId: ID!, $targetAssociationId: ID!, $targetAssociationKind: String!,$amount: Float) {
	createOrderLedgerLink(_id: $id, accounting_id: $accountingId, target_entry_id: $targetEntryId, 
		target_association_id: $targetAssociationId, target_association_kind: $targetAssociationKind,amount: $amount
		) {
		accounting {
			_id
			accounted_for {
			  amount
			  percent
			  record {
				_id
			  	code
			  	number
			  	amount
			  	letter
			  	description
			  	involved_parties{
					name
					kind
			  	}
			  }
			}
			amount
			amount_calculated
			amount_override
			code
			description
			entry_date
			involved_parties {
			  _id
			  accounted_amount
			  accounted_percent
			  accounted_by {
				amount
				percent
				record {
				  _id
				  code
				  description
				}
			  }
			  amount
			  at_closing_amount
			  at_closing_percent
			  before_closing_amount
			  before_closing_percent
			  kind
			  name
			  order_kinds
			  payment_id
			  payment_kind
			  payment_reference
			  percent
			}
			kind
			letter
			number
			months
			per_month
		  }
	}
  }
`;

type createLinkParams = {
	id: string,
	accountingId: string,
	targetEntryId: string,
	targetAssociationId: string,
	targetAssociationKind: string,
	amount: number
  }

  type createLinkReturn = {
	createOrderLedgerLink: {
		accounting: AccountingType[] | null;
	  }
  }

export const createLedgerLink = (params:createLinkParams) => gqlRequest<createLinkReturn, createLinkParams>(queryCreateLink, params);
