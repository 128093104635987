import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { OrderProperty } from 'types/order';

import { LegalDescription } from './tab-legal-description-scene';

type TabLegalDescriptionProps = {
  control: Control<OrderProperty, object>
}

export const TabLegalDescription: FC<TabLegalDescriptionProps> = ({ control }) => (
  <LegalDescription
    control={control}
  />
);
