import { OrderType } from '../../types/order';

export type WorkspaceReduxStateType = {
  openedOrders: { [id: string]: OrderType } | undefined;
  idOrderOpenSelected: string | undefined;
};

const initialState: WorkspaceReduxStateType = {
  openedOrders: undefined,
  idOrderOpenSelected: undefined,
};

const workspaceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_WORKSPACE':
      return {
        ...state,
        ...{
          openedOrders: {
            ...state.openedOrders,
            ...action.payload
          },
        },
      };

    case 'UPDATE_WORKSPACE':
      return {
        ...state,
        ...{ openedOrders: { ...action.payload } },
      };

    case 'CLEAR_WORKSPACE':
      return {
        ...state,
        ...{ openedOrders: undefined },
      };

    case 'SET_ID_ORDER_SELECTED':
      return {
        ...state,
        ...{ idOrderOpenSelected: action.payload },
      };

    default:
      return state;
  }
};

export default workspaceReducer;
