import {
  Box, Button,
  Divider, Grid, Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { OutlinedButton } from 'components/OutlinedButton';
import React, { FC } from 'react';
import { NewOrderPartyType } from 'types/order';

import InformationTab from '../information-tab';
import PartySearch from '../party-search/party-search';
import SkeletonParties from '../skeleton-loading';
import { useTabCreateAssocation } from './hooks';
import { useStyles } from './styles';

type Props = {
  associationKind: string,
  roleParty: string,
  partyId: string,
  partyAssociations: NewOrderPartyType[],
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>,
  loaderActive: boolean,
  searchDescription: string,
  removeButtonLabel: string,
  removeSuccessMessage: string,
  createSuccessMessage: string,
  listTitle: string
}

export const TabCreateAssociation: FC<Props> = ({
  associationKind,
  partyId,
  roleParty,
  partyAssociations,
  setLoaderActive,
  loaderActive,
  searchDescription,
  removeButtonLabel,
  removeSuccessMessage,
  createSuccessMessage,
  listTitle
}) => {
  const {
    t,
    parties,
    setSearchText,
    setSelectedPartyToLinkId,
    orderId,
    removePartyAssociation,
    setSelectedAssociationParty,
    selectedPartyAssociatonData,
    selectedAssociationParty,
    isLoading,
    isLoadingRemove
  } = useTabCreateAssocation({
    associationKind,
    partyId,
    roleParty,
    setLoaderActive,
    loaderActive,
    removeSuccessMessage,
    createSuccessMessage
  });
  const classes = useStyles();

  const handleSearch = (text: string): void => setSearchText(text);

  const handleRemove = () => {
    if (orderId && selectedPartyAssociatonData) {
      setLoaderActive(true);
      removePartyAssociation({
        id: orderId,
        partyId: selectedPartyAssociatonData._id,
        kind: associationKind,
      });
    }
  };

  return (
    <Grid container direction="column" className={classes.paddingContent}>
      <PartySearch
        description={searchDescription}
        labelField={t('parties:contact')}
        parties={parties}
        handleOnChange={handleSearch}
        handleItemSelect={setSelectedPartyToLinkId}
      />
      {partyAssociations.length > 0 && (
      <>
        <Divider orientation="horizontal" className={classes.divider} />

        <Grid item xs={12} className={classes.titleagentlist}>
          <Typography variant="subtitle1" className={classes.suubtitle}>
            {listTitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" className={classes.tabsContainer}>
            {partyAssociations.map((item) => (
              <Box key={item._id}>
                <Button
                  variant="contained"
                  disableElevation
                  className={clsx({
                    [classes.selectedagent]: selectedAssociationParty?.name === item.name,
                    [classes.unseLectedagent]: selectedAssociationParty?.name !== item.name,
                  })}
                  onClick={() => setSelectedAssociationParty(item)}
                >
                  <Typography
                    variant="body2"
                    className={classes.buttonLetter}
                  >
                    {item.name}
                  </Typography>
                </Button>
              </Box>
            ))}
          </Grid>
          <Divider orientation="horizontal" className={classes.divider} />
          {isLoading && (<SkeletonParties />)}
          {selectedPartyAssociatonData && (
            <>
              <Box className={classes.actionsContainer}>
                <OutlinedButton
                  text={removeButtonLabel}
                  icon="trash"
                  onClick={handleRemove}
                  isLoading={isLoadingRemove}
                />
              </Box>
              <InformationTab
                party={selectedPartyAssociatonData}
                t={t}
                mainParty={false}
              />
            </>
          )}
        </Grid>
      </>
      )}
    </Grid>
  );
};
