/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { BaseButton } from 'components/BaseButton';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { formatMoney } from 'utils/helpers';
import { AccountedBy } from 'v2-types/order';

export const useStyles = makeStyles((theme:any) => ({
  containerCell: { padding: theme.spacing(1), gap: '5px' },
  boxbordered: {
	  border: `1px solid ${theme.palette.border.line}`,
	  backgroundColor: theme.palette.background.default,
	  marginRight: theme.spacing(1),
	  padding: theme.spacing(0.5),
	  gap: '5px'
  },
  deleteButton: {
	  borderRight: `1px solid ${theme.palette.border.line}`,
	  borderRadius: '0px',
	  padding: theme.spacing(0.8),
	  fontSize: '1rem'
  },
  recipFont: { fontWeight: 500, color: 'black', marginLeft: theme.spacing(1) }
}));

type accontedForParams = {
	accountedItems:AccountedBy[],
	onDelete?: (order_id: string, paymentRecordId: string, associationKind: string, associationId: string, associationLedgerRow: string) => void,
	onAddReceipt?:()=>void,
	accountingId:string
}

const AccountedForC:FC<accontedForParams> = ({ accountedItems, onDelete, onAddReceipt, accountingId }) => {
  const classes = useStyles();
  const { id: orderId } = useParams<{ id: string }>();

  return (
    <Box className={classes.containerCell} display="flex" flexDirection="column">
      {accountedItems.map((accounted) => {
        const involvedParty = accounted.record.involved_parties?.[0];
        return (
          <Box display="flex" className={classes.boxbordered} key={`${involvedParty?.kind}-${involvedParty?._id}-${accounted.amount}`}>
            {onDelete && (
            <IconButton
              className={classes.deleteButton}
              onClick={(e) => {
			  e.stopPropagation();
			  onDelete(
                  orderId,
                  accountingId,
                  involvedParty?.kind || '',
                  involvedParty?._id || '',
                  accounted.record._id
			  );
              }}
            >
              <FontAwesomeIcon
                icon="trash"
                size="sm"
              />
            </IconButton>
            )}
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="caption" className={classes.recipFont}>
                {`${involvedParty?.name}(${involvedParty?.kind}) - ${accounted.record.description}
				${accounted.record.letter && accounted.record.number && `[${accounted.record.letter}-${accounted.record.number}]`}
				${formatMoney(accounted.amount)}(${accounted.percent * 100}% of ${formatMoney(accounted.record.amount)})`}
              </Typography>
            </Box>
          </Box>
        );
      })}
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <BaseButton
          icon="plus"
          text="Add Charge to cover"
          size="small"
          onClick={(e) => {
		    e.stopPropagation();
            if (onAddReceipt) {
		      onAddReceipt();
            }
		  }}
        />
      </Box>
    </Box>
  );
};

export default AccountedForC;
