import { CircularProgress, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from 'assets/images/star-circle.svg';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  backdrop: {
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    maxWidth: '100%',
    overflow: 'auto',
    position: 'fixed',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  image: {
    width: 64,
    height: 64,
  },
  loadingText: {
    color: theme.accent.main,
    fontWeight: 500,
  },
  circularProgressBar: {
    color: theme.accent.main,
    marginTop: theme.spacing(4),
  },
}));

const BackdropLoading = ({ loadingText, open = false }:any) => {
  const classes = useStyles();

  return (open && (
    <Container className={classes.backdrop}>
      <img
        alt="logo"
        src={Logo}
        className={classes.image}
      />

      <Typography className={classes.loadingText}>
        {loadingText}
      </Typography>

      <CircularProgress
        size="2rem"
        thickness={2}
        color="inherit"
        className={classes.circularProgressBar}
      />
    </Container>
  ));
};

export default BackdropLoading;
