import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  capitalize,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import TableComponent from 'components/Table';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { OrderProperty, PropertyLoanType } from 'types/order';
import { LEDGER_CODE_LOAN_AMOUNT } from 'utils/constants';
import { formatMoney } from 'utils/helpers';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import { AddAndUpdatePartyLedgerEntry } from '../../../../../components/add-and-update-party-ledger-modal/add-and-update-party-ledger-entry';
import { PropertyNumberField } from '../PropertyNumberField';
import { useTabAmountHooks } from './tab-amount-hooks';

const useStyles = makeStyles((theme: any) => ({
  container: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  primarycolorfonts: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
  placeholderText: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(5)
  },
}));

type TabAmountsProps = {
  propertyId: string;
  control: Control<OrderProperty, object>;
  selectedProperty: OrderProperty
};

export const TabAmounts: FC<TabAmountsProps> = ({
  propertyId,
  control,
  selectedProperty
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    orderId,
    accountingEntries,
    purchasePriceEntries,
    openPartyLedgerModal,
    setOpenPartyLedgerModal,
    entryLedger,
    partyLedgerEntry,
    setPartyLedgerEntry,
    onUpdatePurchasePriceEntries,
    onRemoveOrderPartyEntry,
    t,
  } = useTabAmountHooks({ control });

  const history = useHistory();

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item container spacing={2}>
        {accountingEntries.map((entry, index) => {
          const { code } = entry as AccountingType;
          const label = capitalize(
            code.replace(`_${propertyId}`, '').replace('_', ' ')
          );

          return (
            <Grid item xs key={entry.id}>
              <PropertyNumberField
                control={control}
                name={`accounting.${index}.amount`}
                label={label}
                decimalScale={2}
                thousandSeparator
                prefix="$"
                isMoney
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item container>
        <Typography variant="h6" className={classes.primarycolorfonts}>
          Loans
        </Typography>
        {selectedProperty.loans && selectedProperty.loans?.length > 0 && (
        <TableComponent
          disabledBorderLeft
          cellAlignment="left"
          columns={{
            actions: {
              header: { title: 'Actions', align: 'center', width: '80px', },
              renderer: ({ row }) => (
                <Box display="flex" justifyContent="center">
                  <IconButton style={{ padding: 0 }} onClick={() => history.push(`/orders/details/${orderId}/loans/${row._id}`)}>
                    <FontAwesomeIcon icon="external-link-alt" size="xs" />
                  </IconButton>
                </Box>
              )
            },
            numer: {
              header: { title: 'Loan number', align: 'left', width: 'auto' },
              renderer: ({ row }) => <Link to={`/orders/details/${orderId}/loans/${row._id}`}>{ row.loan_number }</Link>,
            },
            loanAmount: {
              header: { title: 'Amount', align: 'left' },
              // eslint-disable-next-line react/no-unused-prop-types
              renderer: ({ row }: { row: PropertyLoanType }) => {
                const loanAmountEntry = row.accounting.find((entry) => entry.code.startsWith(LEDGER_CODE_LOAN_AMOUNT));
                return <>{formatMoney(loanAmountEntry?.amount ?? 0)}</>;
              }
            }
          }}
          dataSet={selectedProperty.loans || []}
        />
        )}
        {(!selectedProperty.loans || selectedProperty.loans.length === 0) && (
          <Typography className={classes.placeholderText}>
            There are not loans added yet
          </Typography>
        )}
      </Grid>
      <Grid item xs container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between">
          <Typography variant="h6" className={classes.primarycolorfonts}>
            {t('earnestAndCommissions:earnest-and-commissions')}
          </Typography>
          <Button
            style={{
              textTransform: 'none',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            startIcon={(
              <FontAwesomeIcon
                icon="plus"
                style={{
                  fontSize: '0.875rem',
                  color: theme.palette.secondary.main,
                }}
              />
            )}
            onClick={() => setOpenPartyLedgerModal(true)}
          >
            <Typography
              variant="body2"
              style={{ fontWeight: 500, color: theme.palette.secondary.main }}
            >
              {t('accounting:add-entry')}
            </Typography>
          </Button>
        </Grid>
        {purchasePriceEntries.length > 0 && (
          <Grid item xs>
            <TableComponent
              disabledBorderLeft
              cellAlignment="left"
              columns={{
                actions: {
                  header: { title: t('accounting:actions') },
                  renderer: ({ row }) => (
                    <Actionbuttons
                      handleEdit={() => {
                        setPartyLedgerEntry(row as AccountingInvolvedParty);
                        setOpenPartyLedgerModal(true);
                      }}
                      handleDelete={() => {
                        const partyEntry = row as AccountingInvolvedParty;
                        onRemoveOrderPartyEntry(partyEntry);
                      }}
                    />
                  ),
                },
                name: {
                  header: { title: t('accounting:name') },
                  renderer: ({ row }) => <>{row.name}</>,
                },
                kind: {
                  header: { title: t('accounting:kind') },
                  renderer: ({ row }) => <>{row.kind}</>,
                },
                amount: {
                  header: { title: t('accounting:amount') },
                  renderer: ({ row }) => (
                    <>
                      $
                      {Number(row.amount).toLocaleString('en-US')}
                    </>
                  ),
                },
              }}
              dataSet={purchasePriceEntries}
            />
          </Grid>
        )}
      </Grid>
      {entryLedger && openPartyLedgerModal && (
        <AddAndUpdatePartyLedgerEntry
          kindAllowedAssociationList={['earnest', 'commission', 'option', 'owner']}
          entryLedger={entryLedger}
          partyLedgerEntry={partyLedgerEntry}
          handleClose={() => {
            setPartyLedgerEntry(undefined);
            setOpenPartyLedgerModal(false);
          }}
          handleSuccess={onUpdatePurchasePriceEntries}
        />
      )}
    </Grid>
  );
};
