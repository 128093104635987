import {
  AppBar,
  Box,
  CssBaseline,
  Grid,
  makeStyles,
  Paper,
  Toolbar,
  Typography
} from '@material-ui/core';
import championshipLogo from 'assets/images/championship-logo.png';
import BackdropLoading from 'components/common/BackdropLoading';
import { ContainedButton } from 'components/ContainedButton';
import NoActiveLink from 'components/non-active-link/not-active-link';
import { TFunction } from 'i18next';
import React from 'react';

import { DocumentRequestItem } from './components/document-request-item/document-request-item';
import ThanksForTrust from './components/thanks-trusting-us';
import { DocumentRequestInfo } from './types';

const useStyles = makeStyles((theme: any) => ({
  toolbarStyle: {
    paddingRight: theme.spacing(3),
    paddingLeft: 0,
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
  logo: { width: theme.spacing(20), alignSelf: 'center' },
  container: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(4)
  },
  card: { backgroundColor: theme.palette.common.white, },
  cardTitle: {
    fontWeight: 500,
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.accent.main,
    paddingBottom: theme.spacing(2)
  },
  cardContent: { padding: theme.spacing(2) },
}));
type DocumentRequestSceneProps = {
  t: TFunction,
  documentRequestInfo?: DocumentRequestInfo,
  isLoading: boolean,
  isSendButtonDisable: boolean,
  isValidToken: boolean,
  showThanksTrustMessage: boolean,
  closeWindow: () => void,
  onAddFile: ({ archiveId, file }: { archiveId: string, file: File }) => void,
  onRemoveFile: ({ archiveId, fileName }: { archiveId: string, fileName: string }) => void,
  uploadIsLoading: boolean,
  onSendFiles: () => void
}

export const DocumentRequestScene = ({
  t,
  documentRequestInfo,
  isValidToken,
  isLoading,
  isSendButtonDisable,
  showThanksTrustMessage,
  closeWindow,
  onAddFile,
  onRemoveFile,
  uploadIsLoading,
  onSendFiles
}: DocumentRequestSceneProps) => {
  const classes = useStyles();

  if (isLoading) {
    return <BackdropLoading open loadingText="loading" />;
  }
  if (!isValidToken) {
    return (<NoActiveLink t={t} />);
  }

  return (showThanksTrustMessage ? <ThanksForTrust t={t} closewindow={closeWindow} />
    : (
      <Grid>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar className={classes.toolbarStyle}>
            <img src={championshipLogo} alt="logo" className={classes.logo} />
          </Toolbar>
        </AppBar>
        <Grid container justifyContent="center" className={classes.container}>
          <Box display="flex" flexDirection="column" style={{ width: '70%' }}>
            <Paper className={classes.card}>
              <Typography
                variant="h5"
                className={classes.cardTitle}
              >
                {t('documents:upload-required-documents')}
              </Typography>
              <Grid
                container
                direction="column"
                spacing={3}
                className={classes.cardContent}
              >
                <Grid item>
                  <Typography variant="body1">{t('documents:provide-following-documents')}</Typography>
                </Grid>
                <Grid item container direction="column" spacing={2} wrap="nowrap">
                  {documentRequestInfo?.files.map((file) => (
                    <DocumentRequestItem
                      fileRequired={file}
                      onAddFile={onAddFile}
                      onRemoveFile={onRemoveFile}
                    />
                  ))}
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <ContainedButton
                    text={t('documents:send-files')}
                    icon="paper-plane"
                    isLoading={uploadIsLoading}
                    onClick={onSendFiles}
                    disabled={isSendButtonDisable}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    )
  );
};
