import { createContext, useContext } from 'react';

import { OrderMortgageType } from './types.d';

type MortgagesContextType = {
  mortgages: OrderMortgageType[];
  loadingMortgages: boolean;
  setLoadingMortgages?: (param:boolean) => void
}

const MortgagesContext = createContext<MortgagesContextType>({
  mortgages: [],
  loadingMortgages: false
});

export default MortgagesContext;

export const useMortgagesContext = () => useContext(MortgagesContext);
