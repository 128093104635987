import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

const gqlRequest = async <T, V>(queryBody: string, params?: V): Promise<T> => {
  const query = gql`${queryBody}`;

  const response = await graphQLClient().request<T, V | unknown>(query, params);

  return response;
};

export default gqlRequest;
