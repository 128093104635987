import {
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import ReadyTocloseIcon from 'assets/images/readyto-icon.svg';
import clsx from 'clsx';
import { PaperCard } from 'components/cards/paper-card';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { Milestone } from '../services';
import { MilestoneItem } from './milestone-item';

const useStyles = makeStyles((theme: any) => ({
  centerIcon: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignContent: 'center',
  },
  iconSize: {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    color: theme.palette.common.black,
  },
  readytoCloseButtonInctive: {
    backgroundColor: '#B0BEC5',
    '&:hover': { backgroundColor: '#B0BEC5' },
  },
  readytoCloseButtonActive: {
    backgroundColor: '#263238',
    '&:hover': { backgroundColor: '#263238' },
  },
  divider: {
    backgroundColor: theme.palette.tab.offselected,
    opacity: 0.5,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  }
}));

type MilestonesProps = {
  t: TFunction,
  milestones: Milestone[],
  handleSelect: (milestone: Milestone) => void;
  selectedMilestone: Milestone;
};

export const MilestoneStatus: FC<MilestonesProps> = ({
  t,
  milestones,
  handleSelect,
  selectedMilestone,
}) => {
  const classes = useStyles();
  return (
    <PaperCard title={t('orders:milestone-status')}>
      <Grid container>
        <Grid item container wrap="nowrap" alignItems="flex-end">
          {milestones.slice(0, 5).map((milestone) => (
            <Grid item xs={3}>
              <MilestoneItem
                key={milestone.title}
                title={milestone.title}
                icon={milestone.icon}
                optionKey={milestone.key}
                selectedMilestone={selectedMilestone.key}
                onClick={() => {
                  handleSelect(milestone);
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item container alignItems="flex-end" justifyContent="center">
          {milestones.slice(5, milestones.length + 1).map((milestone) => (
            <Grid item xs={6}>
              <MilestoneItem
                key={milestone.title}
                title={milestone.title}
                icon={milestone.icon}
                optionKey={milestone.key}
                selectedMilestone={selectedMilestone.key}
                onClick={() => {
                  handleSelect(milestone);
                }}
              />
            </Grid>
          ))}
          <Grid item xs={6}>
            <MilestoneItem
              title={t('orders:ready-to-close')}
              iconClassName={clsx(classes.centerIcon, { [classes.readytoCloseButtonInctive]: true })}
              iconContent={(
                <img
                  alt="logo"
                  src={ReadyTocloseIcon}
                  className={classes.iconSize}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </PaperCard>
  );
};
