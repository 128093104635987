import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme:any) => ({
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  itempaddinType: {
    '& > * ': { marginTop: theme.spacing(2) },
    padding: theme.spacing(3),
    margin: 0,
  },
  itempaddinfoother: { '& > * ': { marginLeft: theme.spacing(2) } },
  errorIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nocontendPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  removecontentPadding: { '& .MuiDialogContent-root': { padding: 0 } }
}));
