import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, },
}));
