import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

export type GQLCreateOrder = {
  createOrder: {
    _id: string;
  };
};

export type CreateOrderPayload = {
  transactionType: string;
  type: string;
  workflow: string;
  agencyId: string;
}

export const createOrder = async (payload: CreateOrderPayload) => {
  const mutation = gql`
    mutation CreateOrder(
      $transactionType: String!
      $type: String!
      $workflow: String!
      $agencyId: ID!
    ) {
      createOrder(
        transaction_type: $transactionType
        type: $type
        workflow: $workflow
        agency_id: $agencyId
      ) {
        _id
      }
    }
  `;

  const response = await graphQLClient()
    .request<GQLCreateOrder>(mutation, payload);

  return response;
};
