import { useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useOnboardingTermsAndConditions = () => {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState('1');
  const [userAgreed, setUserAgreed] = useState(false);

  return {
    t,
    theme,
    history,
    tabIndex,
    setTabIndex,
    userAgreed,
    setUserAgreed,
  };
};

export default useOnboardingTermsAndConditions;
