import { Box, RadioGroup, Typography } from '@material-ui/core';
import Radio from 'components/radio';
import React from 'react';

import { useStyles } from './styles';

type Props = {
  value: boolean | string | null,
  options: {
    label: string,
    disabled: boolean,
    value: string | number | boolean
  }[],
  onChange: (value: string) => void,
  title?: string,
  description?: string
}

export function RadioButtonForm({
  title,
  value,
  options,
  onChange,
  description
}: Props) {
  const classes = useStyles();
  return (
    <Box style={{ flex: 1 }}>
      {title && (
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      )}

      {description && (
        <Typography variant="body2" className={classes.subtitle}>
          {description}
        </Typography>
      )}

      <RadioGroup
        value={value}
        onChange={(e, valueSelected) => onChange(valueSelected)}
      >
        {options.map((option) => (
          <Radio
            size="small"
            label={option.label}
            disabled={option.disabled}
            value={option.value}
          />
        ))}
      </RadioGroup>
    </Box>
  );
}
