import {
  FormControlLabel,
  Switch as MUISwitch,
} from '@material-ui/core';
import React from 'react';

type SwitchProps = {
  disabled?: boolean;
  name?: string;
  label: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  size?: 'small' | 'medium';
  checked: boolean;
  handleBlur?: any;
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  inputRef?: any;
};

const Switch = ({
  disabled,
  name,
  label,
  labelPlacement,
  size,
  checked,
  handleBlur,
  handleOnChange,
  inputRef,
}: SwitchProps) => (
  <FormControlLabel
    disabled={disabled}
    name={name}
    label={label}
    labelPlacement={labelPlacement}
    inputRef={inputRef}
    control={(
      <MUISwitch
        size={size}
        checked={checked}
        onBlur={handleBlur}
        onChange={handleOnChange}
      />
    )}
  />
);

Switch.defaultProps = {
  disabled: false,
  name: undefined,
  labelPlacement: 'end',
  size: 'medium',
  handleBlur: undefined,
  handleOnChange: () => {},
  inputRef: undefined,
};

export default Switch;
