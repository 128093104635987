import { Grid } from '@material-ui/core';
import NumberField from 'components/number-field';
import Switch from 'components/switch';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatesAndConfigurationForm } from '../services';
import Title from './title';

type FormAmountsProps = {
  totalLoanAmount: number;
  totalPurchasePrice: number;
};

const FormAmounts = ({
  totalLoanAmount,
  totalPurchasePrice,
}: FormAmountsProps) => {
  const { control, watch } = useFormContext<DatesAndConfigurationForm>();
  const { t } = useTranslation();
  const [CashOnly, ConstructionLoan, Heloc] = watch(['cash_only', 'construction_loan', 'heloc']); // you can also target specific fields by their names
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Title>{t('datesAndConfig:amounts')}</Title>
      </Grid>

      <Grid item xs={6}>
        <NumberField
          thousandSeparator
          decimalScale={2}
          prefix="$"
          label={t('datesAndConfig:load-amount')}
          disabled
          value={totalLoanAmount}
        />
      </Grid>

      <Grid item xs={6}>
        <NumberField
          thousandSeparator
          decimalScale={2}
          prefix="$"
          label={t('datesAndConfig:purchase-price')}
          disabled
          value={totalPurchasePrice}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item>
            <Controller
              control={control}
              name="cash_only"
              render={({ field }) => (
                <Switch
                  inputRef={field.ref}
                  disabled={Heloc || ConstructionLoan}
                  label={t('datesAndConfig:cash-only')}
                  checked={field.value}
                  handleBlur={field.onBlur}
                  handleOnChange={(event) => field.onChange(event.target.checked)}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              control={control}
              name="heloc"
              render={({ field }) => (
                <Switch
                  inputRef={field.ref}
                  disabled={CashOnly || ConstructionLoan}
                  label={t('datesAndConfig:heloc')}
                  checked={field.value}
                  handleBlur={field.onBlur}
                  handleOnChange={(event) => field.onChange(event.target.checked)}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              control={control}
              name="construction_loan"
              render={({ field }) => (
                <Switch
                  inputRef={field.ref}
                  disabled={CashOnly || Heloc}
                  label={t('datesAndConfig:construction-loan')}
                  checked={field.value}
                  handleBlur={field.onBlur}
                  handleOnChange={(event) => field.onChange(event.target.checked)}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormAmounts;
