import { useQuery } from 'react-query';

import { getListings } from '../graphql';

const useGetListings = () => {
  const listings = useQuery(
    'listings',
    getListings,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
  return listings;
};

export default useGetListings;
