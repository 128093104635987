import { getParty } from 'graphql/parties/queries';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { PARTY_KIND_BUYER, PARTY_KIND_SELLER } from 'utils/constants';

import PartiesContext from '../services/context';
import { Buyer } from './buyer/buyer';
import { Seller } from './seller/seller';
import { SimpleParty } from './simple-party/index';

type PartyInfoProps = {
  kindProp?: string;
  partyIdProp?: string;
};

export const PartyInfo: React.FC<PartyInfoProps> = ({
  kindProp,
  partyIdProp,
}) => {
  const { kind = kindProp, partyId = partyIdProp } = useParams<{
    partyId: string;
    kind: string;
  }>();
  const context = useContext(PartiesContext);
  const { isLoading, data: getPartyResponse } = useQuery(
    ['getBuyer', partyId],
    () => getParty({ id: partyId || '' }),
    {
      enabled: !!partyId,
      refetchOnMount: false,
      onSuccess: (data) => {
        if (context && context.unfilteredParties.length) {
          const partyList = [...context.unfilteredParties];
          const partyIndex = partyList.findIndex(
            (party) => party._id === data.getParty._id
          );
          if (
            partyIndex !== -1
            && partyList[partyIndex].name !== data.getParty.name
          ) {
            partyList[partyIndex].name = data.getParty.name;
            context.filterParties(partyList);
          }
        }
      },
    }
  );
  const party = getPartyResponse?.getParty;

  if (kind === PARTY_KIND_BUYER) {
    return (
      <Buyer isModal={!!kindProp} party={party} isPartyLoading={isLoading} />
    );
  }

  if (kind === PARTY_KIND_SELLER) {
    return (
      <Seller isModal={!!kindProp} party={party} isPartyLoading={isLoading} />
    );
  }

  return (
    <SimpleParty
      isModal={!!kindProp}
      party={party}
      isPartyLoading={isLoading}
    />
  );
};
