import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 3px 6px gray',
  },
  icon: { color: theme.palette.text.primary },
  caretButton: {
    height: '100%',
    width: '20px'
  },
  propertyListCollapsable: {
    backgroundColor: '#ffffff',
    zIndex: 10000,
    color: '#000000',
    padding: theme.spacing(2),
    borderRadius: '5px',
    position: 'absolute',
    width: '700px',
  },
  propertyListContainer: {
    width: '100%',
    overflow: 'scroll',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    boxSizing: 'border-box',
    height: '100%',
    maxHeight: '60vh',
    gap: '10px',
  },
  propertyButton: {
    width: '100%',
    justifyContent: 'flex-start',
    padding: '2px',
    '&:hover': { backgroundColor: '#6b6b6b17', }
  }
}));
