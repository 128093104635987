import { TFunction } from 'i18next';

export const columns = (t: TFunction) => (
  [
    { id: 'amount', label: t('payments:amount') },
    { id: 'kind', label: t('payments:kind') },
    { id: 'description', label: t('payments:description') },
    { id: 'party_name', label: t('payments:party') },
  ]
);
