import { NewAddressType } from 'types/new-user';
import { OrderProperty } from 'types/order';

export const extractPropertyAddress = (property: OrderProperty) => {
  const {
    address: {
      street_address,
      references,
      settlement,
      postal_code,
      locality,
      state,
      country
    }
  } = property;

  const address: Partial<NewAddressType> = {
    street_address,
    references,
    settlement,
    postal_code,
    locality,
    state,
    country
  };
  return address;
};
