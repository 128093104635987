import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, Button, Popover, TableCell, TableRow, Typography
} from '@material-ui/core';
import SelectField from 'components/select-field';
import React, { FC, useState } from 'react';
import {
  Control, Controller, FieldPath, UseFormReturn, useWatch
} from 'react-hook-form';
import { LoanFormType } from 'views/orders/loans/types';

import { useStyles } from '../../styles';
import { IncreaseBalloonPaymentConditions } from './components/increase-balloon-payment-conditions';
import { IncreaseInterestRateConditions } from './components/increase-interest-rate-conditions';
import { IncreaseLoanAmountConditions } from './components/increase-loan-amount-conditions';
import { IncreaseMonthlyPrincipalConditions } from './components/increase-monthly-principal-conditions';
import { IncreasePrepaymentPenaltytConditions } from './components/increase-prepayment-penalty-conditions';

type Props = {
  control: Control<LoanFormType, object>,
  optionName: FieldPath<Pick<LoanFormType, 'increase_loan_amount' | 'increase_interest_rate' | 'increase_monthly_principal' | 'has_prepayment_penalty' | 'has_balloon_payment'>>,
  // eslint-disable-next-line max-len
  conditionName: FieldPath<Pick<LoanFormType, 'increase_loan_amount_text' | 'increase_interest_rate_text' | 'increase_monthly_principal_text' | 'has_prepayment_penalty_text' | 'has_balloon_payment_text'>>,
  description: string,
  loanForm: UseFormReturn<LoanFormType, object>,
  options: {
    title: string,
    value: boolean
  }[]
}

// eslint-disable-next-line max-len
const getOptionsByFieldName = (fieldName: FieldPath<Pick<LoanFormType, 'increase_loan_amount' | 'increase_interest_rate' | 'increase_monthly_principal' | 'has_prepayment_penalty' | 'has_balloon_payment'>>) => {
  switch (fieldName) {
    case 'increase_loan_amount':
      return [
        'Can go as high as $__.',
        'Can increase until year __.'
      ];

    case 'increase_interest_rate':
      return [
        'Adjust every __ years starting in year __.',
        'Can go as high as __ % in year __.'
      ];

    case 'increase_monthly_principal':
      return [
        'Adjust every __ years starting in year __.',
        'Can go as high as __ % in year __',
        'Includes only interest and no principal until year __ .'
      ];

    case 'has_prepayment_penalty':
      return [
        'As high as $__ if you pay off the loan within the first __ years.'
      ];

    case 'has_balloon_payment':
      return [
        'You will have to pay $__ at the end of year__.'
      ];

    default:
      return [];
  }
};

export const Row: FC<Props> = ({
  control,
  description,
  optionName,
  conditionName,
  loanForm,
  options
}) => {
  const classes = useStyles();

  const values = useWatch({ control });
  const [openOptionList, setOpenOptionList] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  return (
    <TableRow className={classes.tableRow}>
      <TableCell component="th" scope="row" className={` ${classes.row2}`}>
        <Typography className={classes.textStyle}>
          {description}
        </Typography>
      </TableCell>

      <TableCell component="th" scope="row" className={` ${classes.row2}`}>
        <Controller
          control={control}
          name={optionName}
          render={({ field }) => (
            <SelectField
              value={field.value !== null ? field.value.toString() : ''}
              data={options}
              dataKey="value"
              dataValue="value"
              dataText="title"
              InputProps={{ className: classes.underline }}
              handleChange={(e) => {
                const isEnabled = e.target.value.toString() === 'true';
                if (isEnabled) {
                  loanForm.setValue(conditionName, '');
                }
                field.onChange(isEnabled);
              }}
            />
          )}
        />
      </TableCell>

      <TableCell component="th" scope="row" colSpan={2}>
        {values[optionName] !== null && (
          <Box className={classes.row4}>
            {optionName === 'increase_loan_amount' && (
            <IncreaseLoanAmountConditions
              loanForm={loanForm}
            />
            )}
            {optionName === 'increase_interest_rate' && (
            <IncreaseInterestRateConditions
              loanForm={loanForm}
            />
            )}
            {optionName === 'increase_monthly_principal' && (
            <IncreaseMonthlyPrincipalConditions
              loanForm={loanForm}
            />
            )}
            {optionName === 'has_prepayment_penalty' && (
            <IncreasePrepaymentPenaltytConditions
              loanForm={loanForm}
            />
            )}
            {optionName === 'has_balloon_payment' && (
            <IncreaseBalloonPaymentConditions
              loanForm={loanForm}
            />
            )}
          </Box>
        )}
      </TableCell>

      <TableCell component="th" scope="row">
        <Box>
          <Button
            variant="outlined"
            color="primary"
            className={classes.navigationButton}
            onClick={(e) => {
              setOpenOptionList(true);
              setAnchorEl(e.currentTarget);
            }}
            disabled={values[optionName] === null || values[optionName] === false}
          >
            <FontAwesomeIcon icon="plus" className={classes.iconPlus} />
          </Button>

          {openOptionList && (
          <Popover
            open={openOptionList}
            anchorEl={anchorEl}
            onClose={() => setOpenOptionList(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
            >
              {getOptionsByFieldName(optionName).map((option) => (
                <Box
                  key={option}
                  onClick={() => {
                    const conditionText = loanForm.getValues(conditionName);
                    if (conditionText && conditionText.includes(option.split('__')[0])) return;

                    const newConditionText = conditionText ? `${conditionText} ${option}` : option;
                    loanForm.setValue(conditionName, newConditionText);
                    setOpenOptionList(false);
                  }}
                  style={{ padding: 10, cursor: 'pointer' }}
                >
                  <Typography>{option}</Typography>
                </Box>
              ))}
            </Box>
          </Popover>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};
