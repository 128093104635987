import {
  Box, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import { Text } from 'components/text';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { TransferInstructionsType } from 'types/wire-transfers';

import { useStyles } from './styles';
import { columns } from './utils';

type InstructionsRowProps = {
  t: TFunction,
  open: boolean,
  instructions: TransferInstructionsType[]
}

export const InstructionsRow: FC<InstructionsRowProps> = ({ t, open, instructions }) => {
  const classes = useStyles();
  const columnList = columns(t);
  return (
    <TableRow>
      <TableCell className={classes.tableCellCollapsable} colSpan={6}>
        <Collapse
          in={open}
          timeout="auto"
        >
          <Box className={classes.instructionsContainer}>
            <Text
              value={t('payments:instructions')}
              variant="h6"
              color="primary"
            />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columnList.map((column) => (
                      <TableCell key={column.id} className={classes.tableCellColumn}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instructions.map((instruction) => (
                    <TableRow key={instruction.account_id}>
                      <TableCell className={classes.tableCell}>
                        <Text
                          value={instruction.amount}
                          isMoney
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Text
                          value={instruction.account_kind}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Text
                          value={instruction.description}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Text
                          value={instruction.party_name}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
