import {
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

import Header from '../components/header';
import PayorInfo from '../components/payor-info';
import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import TableLoader from '../components/skeleton-table-charges';
import TableComponent from './components/table-charges/table-charges';

const useStyles = makeStyles((theme) => ({ padding: { padding: theme.spacing(3) }, }));

  type ChargesProtitypeProps = {
    accountingData:AccountingType[],
    handleSetAccounting: (accountingData: AccountingType[]) => void,
    currentRowSelected:columnsDataSet | null,
    handleSelectRow:(row:columnsDataSet)=>void,
    loadingAccounting:boolean
  }

const ChargesJScene: FC<ChargesProtitypeProps> = ({
  accountingData,
  handleSetAccounting,
  currentRowSelected,
  handleSelectRow,
  loadingAccounting
}) => {
  const classes = useStyles();
  const currentChargeData = accountingData.find((acc) => acc._id === currentRowSelected?.id);
  const { t } = useTranslation();

  return (
    <Grid container direction="column" className={classes.padding} spacing={2}>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Paper>
          <Header
            title={t('charges:lender-credits')}
            chargesIdentifier="J"
          />
          {loadingAccounting
            ? <TableLoader />
            : (
              <TableComponent
                accountingData={accountingData}
                handleClickItem={(item) => {
                  handleSelectRow(item);
                }}
                itemSelected={currentRowSelected}
                updateAccounting={handleSetAccounting}
                hideFields={{
                  amount: true,
                  entryDate: true,
                  code: true,
                  kind: true,
                  letter: true,
                  number: true,
                  months_advanced: true,
                  months: true,
                  annuality: true
				  }}
              />
            )}
        </Paper>
      </Grid>
      {currentRowSelected && currentRowSelected.description === 'Lender Credits'
      && (
      <>
        <Grid item xs={12}>
          <PayorInfo
            handleSuccess={handleSetAccounting}
            chargeData={currentChargeData}
          />
        </Grid>
      </>
      )}
    </Grid>
  );
};

export default ChargesJScene;
