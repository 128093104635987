import { BaseButton, BaseButtonProps } from 'components/BaseButton';
import React, { FC } from 'react';

type TextButtonProps = BaseButtonProps

export const TextButton: FC<TextButtonProps> = (props) => (
  <BaseButton
    buttonVariant="text"
    {...props}
  />
);
