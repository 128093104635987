import gqlRequest from 'graphql/generic';

import { DatesAndConfigurationForm } from '../services';

type UpdateOrder = {
  updateOrderConfiguration: {
    _id: string;
    configuration: {
      type: string;
    };
  };
};

type UpdateOrderParams = {
  id: string;
  data: Omit<DatesAndConfigurationForm, 'order_id'>;
};

const mutationUpdateOrder = `
  mutation UpdateOrderConfiguration($id: ID!, $data: OrderConfigurationContent!) {
    updateOrderConfiguration(_id: $id, data: $data) {
      order_id
	    _id
      configuration {
        type
      }
    }
  }
`;

export const gqlUpdateOrder = (params: UpdateOrderParams) => gqlRequest<UpdateOrder, UpdateOrderParams>(
  mutationUpdateOrder,
  params,
);

type UpdateOrderPolicy = {
  updateOrderPolicy: {
    owners_policy: { insurance_amount: number };
    lenders_policy: { insurance_amount: number };
  };
};

type UpdateOrderPolicyParams = {
  id: string;
  kind: 'owners' | 'lenders';
  data: {
    insurance_amount: number;
  };
};

const mutationUpdateOrderPolicy = `
  mutation UpdateOrderPolicy($id: ID!, $kind: OrderPolicyKinds!, $data: OrderPolicyContent!) {
    updateOrderPolicy(_id: $id, kind: $kind, data: $data) {
      owners_policy {
        insurance_amount
      }
      lenders_policy {
        insurance_amount
      }
    }
  }
`;

export const gqlUpdateOrderPolicy = (params: UpdateOrderPolicyParams) => gqlRequest<UpdateOrderPolicy, UpdateOrderPolicyParams>(
  mutationUpdateOrderPolicy,
  params,
);

export default {};
