import gqlRequest from 'graphql/generic';
import { AccountingFilter, AccountingType } from 'v2-types/order';

const queryAddPartyAssociation = `
  mutation CreateOrderLedgerAssociation($id: ID!, $accountingId: ID!, $data: OrderLedgerAssociationContent!, $kind: String!, $partyId: ID!, $filters: OrderFilters) {
    createOrderLedgerAssociation(_id: $id, accounting_id: $accountingId, data: $data, kind: $kind, party_id: $partyId, filters: $filters) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        code
        description
        entry_date
		months
	    per_month
		accounted_for {
			amount
			percent
			record {
			  _id
			  code
			  description
			}
		}
        involved_parties {
          _id
          amount
		  accounted_amount
		  accounted_percent
		  accounted_by {
			amount
			percent
			record {
			  _id
			  code
			  description
			  entry_date
			}
		  }
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          name
          order_kinds
          payment_id
          payment_kind
          payment_reference
          percent
        }
        kind
        letter
        number
      }
    }
  }
`;

type createLedgerAssociationParams = {
  id: string,
  accountingId:string,
  data: {
    amount?: number|null,
    at_closing_amount?:number| null,
    at_closing_percent?:number| null,
    before_closing_amount?:number | null,
    before_closing_percent?:number| null,
    payment_id?:string| null,
    percent?:number | null
  },
  kind:string,
  partyId: string,
  filters?: { [key: string]: AccountingFilter },
}

type associationentryReturn = {
  createOrderLedgerAssociation: {
      accounting: AccountingType[] | null;
    }
}

export const addpartyLedgerAssociation = (params: createLedgerAssociationParams) => gqlRequest<associationentryReturn, createLedgerAssociationParams>(queryAddPartyAssociation, params);

type updateledgerAssociationParams = {
  id: string,
  accountingId:string,
  data: {
    amount?:number | null,
    at_closing_amount?: number | null,
    at_closing_percent?:number| null,
    before_closing_amount?:number| null,
    before_closing_percent ? : number| null,
    payment_id?:string| null,
    percent?:number | null
  },
  kind: string,
  partyId: string,
  filters?: { [key: string]: AccountingFilter },
}

type updateAssociationReturn = {
  updateOrderLedgerAssociation: {
      accounting: AccountingType[] | null;
    }
}

const queryUpdateLedgerAssociation = `
mutation UpdateOrderLedgerAssociation($id: ID!, $accountingId: ID!, $data: OrderLedgerAssociationUpdateContent!, $kind: String!, $partyId: ID!, $filters: OrderFilters) {
  updateOrderLedgerAssociation(_id: $id, accounting_id: $accountingId, data: $data, kind: $kind, party_id: $partyId, filters: $filters) {
    accounting {
      _id
      amount
      amount_calculated
      amount_override
      code
      description
      entry_date
	  months
	  per_month
	  accounted_for {
		amount
		percent
		record {
		  _id
		  code
		  description
		}
	  }
      involved_parties {
        _id
        amount
        at_closing_amount
        at_closing_percent
        before_closing_amount
        before_closing_percent
        kind
        name
        order_kinds
        payment_id
        payment_kind
        payment_reference
        percent
		accounted_amount
		  accounted_percent
		  accounted_by {
			amount
			percent
			record {
			  _id
			  code
			  description
        entry_date
			}
		  }
      }
      kind
      letter
      number
    }
  }
}
`;

export const updateLedgerAssociation = (params:updateledgerAssociationParams) => gqlRequest<updateAssociationReturn, updateledgerAssociationParams>(queryUpdateLedgerAssociation, params);

export default { };
