import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * @deprecated this function will be replaced wen all the pending screens are migrated.
 */
export const unsuscribe = async (payload: any) => {
  const { data } = await axios.post(`${API_BASE_URL}v1/contacts/unsubscribe/`, payload);
  return data;
};

export default { unsuscribe };
