import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NewLicenseType } from 'types/new-user';
import * as Yup from 'yup';

import SelectField from '../select-field';
import TextField from '../text-field';
import { useStyles } from './styles';

type Props = {
  t: TFunction;
  open: boolean;
  states: {
    code: string;
    parent: string;
    name: string;
  }[],
  add: (data: NewLicenseType, onClose?: () => void) => void;
  onClose: () => void;
};

export const CreateLicenseModal: FC<Props> = ({
  t,
  open,
  states,
  add,
  onClose,
}) => {
  const classes = useStyles();

  const schema = Yup.object({
    number: Yup.string().required(t('validations:required')),
    state: Yup.string().required(t('validations:required')),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NewLicenseType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { state: '', number: '' },
  });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>
        {t('users:create-license')}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit((data) => add(data, handleOnClose))}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="number"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label="ID"
                    error={!!errors.number}
                    helperText={errors.number?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="state"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <SelectField
                    {...field}
                    inputRef={ref}
                    label={t('users:state')}
                    data={states}
                    dataKey="code"
                    dataValue="code"
                    dataText="name"
                    handleChange={onChange}
                    error={!!errors.state}
                    helperText={errors.state?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    disableElevation
                    disabled={!isValid}
                    variant="contained"
                    color="secondary"
                    className={classes.buttonText}
                  >
                    {t('users:create')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
