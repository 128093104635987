import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { AccountingType } from 'v2-types/order';

export type GQLChargesAIResponse = {
	helpOrderChargesByAI: {
	  _id: string;
	  accounting:AccountingType[]
	}
  };

type amountsChargesAI ={
	letter: string,
	number: string,
	description: string,
	buyer: {
		at_closing: number,
		before_closing: number
	},
	seller: {
		at_closing: number,
		before_closing: number
	},
	others: number | null,
	payee: string | null
}

export type CreateOrderAIParams = {
		id: string,
		amounts: amountsChargesAI[]
	  }

export const chargesHelperAI = async (payload: CreateOrderAIParams) => {
  const mutation = gql`
	mutation HelpOrderChargesByAI($id: ID!, $amounts: [ChargesAIContent!]) {
  		helpOrderChargesByAI(_id: $id, amounts: $amounts) {
    		_id
			accounting {
			_id
			amount
			amount_calculated
			amount_override
      		annuality
			code
			kind
			letter
			number
			entry_date
			description
			months
			per_month
			months_advanced
			involved_parties {
				_id
				amount
				at_closing_amount
				at_closing_percent
				before_closing_amount
				before_closing_percent
				kind
				name
				order_kinds
				payment_id
				payment_kind
				payment_reference
				percent
				accounted_amount
				accounted_percent
				accounted_by {
				  amount
				  percent
				  record {
					  _id
					description
					code
					entry_date
				  }
				}
			  }   
		    }
  		}
	}	
	`;

  const response = await graphQLClient()
	  .request<GQLChargesAIResponse>(mutation, payload);

  return response;
};
