import { Table, TablePagination } from '@material-ui/core';
import React from 'react';
import { Column, DocumentsType } from 'types/common';

import BodyTable from './body-table';
import FooterTable from './footer-table';
import HeadTable from './head-table';
import useStyles from './style';

type ColumnSort = { [key: string]: 'asc' | 'desc' };

type FooterComponentType = {
  renderer?: (params: { item: any }) => React.ReactElement;
};

type TableType = {
  pointer?: boolean;
  page?: any;
  size?: 'small' | 'medium';
  dataSet: DocumentsType[] | any[];
  loading?: boolean;
  rowsPerPage?: any;
  isPaginate?: boolean;
  columnSort?: ColumnSort;
  itemsCollapsed?: { [key: string]: boolean };
  includeColumns?: string[];
  excludeColumns?: string[];
  rowsPerPageOptions?: any[];
  columnCollapsing?: Column[];
  totalItems?: number;
  footerComponents?: FooterComponentType[];
  footerDataSet?: any[];
  disabledBorderLeft?: boolean;
  disabledBorderCell?: boolean;
  onSort?: (column: Column) => void;
  onClickItem?: (row?: any, column?: Column, index?: number) => void;
  columns: { [key: string]: Column };
  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (event: any) => void;
  disableFullborderCell?: boolean;
  styleTable?: React.CSSProperties;
  tableClassName?: string | undefined;
  borderLeftClassName?: string | undefined;
  cellAlignment?:
    | 'left'
    | 'right'
    | 'inherit'
    | 'center'
    | 'justify'
    | undefined;
};

const getValuesByKeys = (obj: { [key: string]: any }, keys: string[]) => keys.reduce((res: any[], key: string) => {
  const value = obj[key];
  return value ? [...res, value] : res;
}, []);

const filterColumns = (
  columns: { [key: string]: Column },
  includeColumns?: string[],
  excludeColumns?: string[]
) => {
  if (includeColumns?.length && excludeColumns?.length) {
    const showColumns = includeColumns.filter(
      (column) => !excludeColumns.includes(column)
    );
    return getValuesByKeys(columns, showColumns);
  }

  if (includeColumns?.length) {
    return getValuesByKeys(columns, includeColumns);
  }

  if (excludeColumns?.length) {
    return getValuesByKeys(
      columns,
      Object.keys(columns).filter((key) => !excludeColumns.includes(key))
    );
  }
  return Object.values(columns);
};

const TableComponent = ({
  pointer,
  columns,
  size,
  dataSet,
  includeColumns,
  excludeColumns,
  loading,
  columnCollapsing,
  rowsPerPageOptions,
  itemsCollapsed,
  rowsPerPage,
  columnSort,
  totalItems,
  onSort,
  onClickItem,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  isPaginate,
  disabledBorderCell,
  disabledBorderLeft,
  footerDataSet,
  footerComponents,
  disableFullborderCell,
  styleTable,
  tableClassName,
  borderLeftClassName,
  cellAlignment,
}: TableType) => {
  const { pagination, table } = useStyles();

  const columnsFiltered = filterColumns(
    columns,
    includeColumns,
    excludeColumns
  );

  return (
    <>
      <Table className={tableClassName || table} style={styleTable} size={size}>
        <HeadTable
          onSort={onSort}
          columns={columnsFiltered}
          columnSort={columnSort}
          disabledBorderCell={disabledBorderCell}
          disableFullborderCell={disableFullborderCell}
        />

        <BodyTable
          pointer={pointer}
          disabledBorderCell={disabledBorderCell}
          disabledBorderLeft={disabledBorderLeft}
          columns={columnsFiltered}
          columnCollapsing={columnCollapsing}
          onClickItem={onClickItem}
          itemsCollapsed={itemsCollapsed}
          dataSet={dataSet}
          loading={loading}
          disableFullborderCell={disableFullborderCell}
          borderLeftClassName={borderLeftClassName}
          cellAlignment={cellAlignment}
        />

        <FooterTable
          footerComponents={footerComponents}
          footerDataSet={footerDataSet}
          disableFullborderCell={disableFullborderCell}
        />
      </Table>

      {isPaginate && (
        <TablePagination
          className={pagination}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalItems || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => handleChangePage && handleChangePage(event, newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

TableComponent.defaultProps = {
  pointer: false,
  page: 0,
  size: 'medium',
  columnSort: {},
  loading: false,
  rowsPerPage: 0,
  onSort: undefined,
  includeColumns: [],
  excludeColumns: [],
  isPaginate: false,
  columnCollapsing: [],
  itemsCollapsed: {},
  totalItems: 0,
  footerComponents: [],
  disabledBorderLeft: false,
  disabledBorderCell: false,
  footerDataSet: [],
  onClickItem: undefined,
  handleChangePage: () => {},
  rowsPerPageOptions: [10, 25, 100],
  handleChangeRowsPerPage: () => {},
  disableFullborderCell: false,
  styleTable: undefined,
  tableClassName: undefined,
  borderLeftClassName: undefined,
  cellAlignment: 'center',
};

export default TableComponent;
