import { GQLGetOrder } from 'services/querys/orders';
import {
  NewAssociationType,
  NewBuyerSellerType,
  NewExchange1031Type,
  NewOrderPartyType,
  NewPOAType
} from 'types/order';

export type PartyType = {
  _id: string;
  name: string;
  order_data?:(NewBuyerSellerType | NewExchange1031Type | NewPOAType)[];
  associations:NewAssociationType[] | null;
};

export type PartiesType = {
  sellers: PartyType[];
  buyers: PartyType[];
  lenders: PartyType[];
  accounting:PartyType[];
  assistant:PartyType[];
  countersigner:PartyType[];
  escrow_officer:PartyType[];
  examiner:PartyType[];
  funder:PartiesType[];
  insurance_company:PartyType[];
  legal:PartyType[];
  lender:PartyType[];
  marketer:PartyType[];
  passthrough:PartyType[];
  policy_production:PartyType[];
  settlement_agency:PartyType[];
};

export const filterRole = (parties: NewOrderPartyType[]) => (
  (kind: string): PartyType[] => parties.filter((party) => party.kinds.includes(kind)
  && !party.kinds.includes('Spouse')));

export type partiesfiltered = {
    title: string;
    parties: PartyType[];
    sectionId: string;
    kind: string;
}

export const updatePartyCache = ({
  orderData,
  parties
}: {
  orderData: GQLGetOrder | undefined,
  parties: NewOrderPartyType[]
}) => {
  if (!orderData) return orderData;

  return {
    ...orderData,
    getOrder: {
      ...orderData.getOrder,
      parties
    }
  };
};

export const GenerateChartStructure = (parties:partiesfiltered[]) => ({
  name: 'Order Parties',
  children: parties.filter((d) => d.parties.length > 0).map((party) => ({
    name: party.kind,
    children: party.parties.map((f) => ({
      name: f.name,
      attributes: { kind: party.kind, partyId: f._id },
      children: f.associations?.map((h) => ({
        name: h.party_name,
        attributes: { kind: h.party_kind, partyId: h.party_id },
	  }))
    }))
  }))
});
