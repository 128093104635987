import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

type RowTextType = {
  text:string;
  isURL?:boolean
}

const useStyles = makeStyles(() => ({
  textStyle: { fontWeight: 500, textTransform: 'none' },
  textStyleURL: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '30rem',
    fontWeight: 500,
    textTransform: 'none'
  }
}));

const RowText: FC <RowTextType> = ({ text, isURL }) => {
  const classes = useStyles();
  return (
    <Typography
      noWrap
      variant="body2"
      className={clsx({
        [classes.textStyle]: !isURL,
        [classes.textStyleURL]: isURL
      })}
    >
      {text}
    </Typography>
  );
};

RowText.defaultProps = { isURL: false };
export default RowText;
