import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme:any) => ({
  boxbordered: {
    border: `1px solid ${theme.palette.border.line}`,
    backgroundColor: theme.palette.background.default,
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    gap: '5px'
  },
  deleteButton: {
    borderRight: `1px solid ${theme.palette.border.line}`,
    borderRadius: '0px',
    padding: theme.spacing(0.8),
    fontSize: '1rem'
  },
  recipFont: { fontWeight: 500, color: 'black', marginLeft: theme.spacing(1) }
}));
