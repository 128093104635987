import { Grid } from '@material-ui/core';
import Switch from 'components/switch';
import { ConfigurationHelperType } from 'components/users/types';
import { TFunction } from 'i18next';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewUserType } from 'types/new-user';

type ConfigurationProps = {
  t: TFunction;
  helpers: ConfigurationHelperType;
};

const Configuration = ({ t, helpers }: ConfigurationProps) => {
  const { control } = useFormContext<NewUserType>();

  return (
    <Grid container direction="column">
      <Grid item>
        <Controller
          name="notifications.all"
          control={control}
          render={({ field: { ref, value, onChange, ...field } }) => (
            <Switch
              {...field}
              inputRef={ref}
              checked={value}
              label={t('users:receive-all-notifications')}
              handleOnChange={(event) => helpers.notifications.all.onChange(
                () => onChange(event.target.checked),
              )}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Configuration;
