import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { NewAccessesType, NewUserType } from 'types/new-user';

export type GQLGetUser = {
  getUser: {
    party: NewUserType;
    accesses: NewAccessesType[];
  };
};

export const getUser = async () => {
  const query = gql`
    query GetUser {
      getUser {
        party {
          _id
          username
          user_id
          kind
          name
          first_name
          last_name
          middle_name
          onboarded
          birth
          gender
          ssn
          addresses {
            _id
            state
            address
            postal_code
            street_address
          }
          emails {
            _id
            address
            kind
          }
          phones {
            _id
            number
            kind
          }
          licenses {
            _id
            state
            number
          }
          parties {
            _id
            name
            kind
          }
          notifications {
            all
          }
        }
        accesses {
          _id
          name
          kind
          authorization
        }
      }
    }
  `;

  const response = await graphQLClient()
    .request<GQLGetUser>(query);

  return response;
};
