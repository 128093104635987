import React, { FC } from 'react';
import { NewOrderPartyType } from 'v2-types/order';

import { OrderProperty } from '../../../types/order';
import { useOrderOverview } from './order-overview-hooks';
import OverviewScene from './overview-scene';

type OverviewProps = {
  properties: OrderProperty[],
  parties: NewOrderPartyType[],
  isOrderLoading: boolean,
  orderType: string
};

export const Overview: FC<OverviewProps> = ({
  properties,
  parties,
  isOrderLoading,
  orderType,
}) => {
  const {
    orderOpener,
    sellers,
    buyers,
    lenders,
    attorneys,
    accountingBalance,
    accountingPremium,
    orderDocuments
  } = useOrderOverview({ parties });

  return (
    <OverviewScene
      orderOpener={orderOpener}
      sellers={sellers}
      buyers={buyers}
      lenders={lenders}
      attorneys={attorneys}
      properties={properties}
      accountingBalance={accountingBalance}
      accountingPremium={accountingPremium}
      orderDocuments={orderDocuments || []}
      isOrderLoading={isOrderLoading}
      orderType={orderType}
    />
  );
};
