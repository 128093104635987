import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  titleSection: {
    fontWeight: 500,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1)
  }
}));

type sectiontitleProps = {
	title:string
}

const SectionTitle:FC<sectiontitleProps> = ({ title }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Typography variant="body1" className={classes.titleSection}>{title}</Typography>
    </Grid>
  );
};

export default SectionTitle;
