import React from 'react';
import { Column } from 'types/common';
import { NewUserType } from 'types/new-user';

import RowText from '../../row-text';
import ActionButtons from '../components/action-buttons';

export type ColumnsType = { [key: string]: Column };

export type scheduleMettingTokens = {
  id?:string;
  parties: NewUserType[];
  date:string;
  init_time:string | null;
  end_time:string | null
}

type RendererParams = {
  row: NewUserType;
  column: Column;
  index: number;
  indexRow?:number
}

export const generateColumns = (
  t,
  theme,
  onDelete
): ColumnsType => ({
  email: {
    header: {
      title: 'Email',
      align: 'center',
      width: '300px',
      textColor: theme.palette.primary.light
    },
    renderer: ({ row }: RendererParams) => (
      <RowText text={row.username ?? t('ron:not-email')} />
    )
  },
  name: {
    header: {
      title: 'Name',
      align: 'center',
      width: '300px',
      textColor: theme.palette.primary.light
    },
    renderer: ({ row }: RendererParams) => (
      <RowText text={row.name || ''} />
    )
  },
  actions: {
    header: {
      title: 'Actions',
      align: 'center',
      width: '300px',
      textColor: theme.palette.primary.light
    },
    renderer: ({ indexRow }: RendererParams) => (
      <ActionButtons
        onDelete={() => onDelete(indexRow)}
      />
    )
  },
});
