import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import services from '../../services/endpoints';

export default function useSignInHooks() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [focusedIntput, setfocusedInput] = useState(null);
  const [login, loginResponse] = useMutation(services.login);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const history = useHistory();

  return {
    t,
    dispatch,
    theme,
    focusedIntput,
    setfocusedInput,
    login,
    loginResponse,
    isAuthenticated,
    isLoading,
    password,
    email,
    setPassword,
    setEmail,
    queryCache,
    history,
    loginWithRedirect,
  };
}
