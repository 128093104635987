import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { NewUserType } from 'types/new-user';

import PartySearchWithPartyInformation from '../party-search-with-party-information';

type SpouseTabSceneProps = {
  t: TFunction,
  handleSearch: (text: string) => void,
  parties: NewUserType[],
  setSpousetID: React.Dispatch<any>,
  handleDeleteSpouse: () => void;
  spouseData: NewUserType | null;
  isSpouseDataLoading: boolean;
}

const SpouseTabScene: FC<SpouseTabSceneProps> = ({
  t,
  handleSearch,
  parties,
  setSpousetID,
  handleDeleteSpouse,
  spouseData,
  isSpouseDataLoading
}) => (
  <PartySearchWithPartyInformation
    t={t}
    handleSearch={handleSearch}
    parties={parties}
    isPartySelectedLoading={isSpouseDataLoading}
    handleItemSelect={setSpousetID}
    handleDeleteParty={handleDeleteSpouse}
    description={t('parties:spouse-search')}
    labelField={t('parties:contact')}
    deleteLabel={t('parties:spouse_remove')}
    partySelectedData={spouseData}
  />
);

export default SpouseTabScene;
