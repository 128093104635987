import { NoteType } from '../types';

type SliceStringParams = {
  text: string,
  maxLength: number
}

export const sliceString = ({
  text,
  maxLength
}: SliceStringParams): string => {
  if (text.length > maxLength) return `${text.slice(0, maxLength - 3)}...`;

  return text;
};

export const getNotesArray = (notes: string): NoteType[] => {
  if (!notes.length) return [];
  return notes
    .split('\n')
    .map((note, index) => ({ id: index.toString(), text: note }) as NoteType)
    .filter((note) => note.text !== '');
};

export const joinNotesArray = (notes: NoteType[]): string => notes
  .map((note) => note.text.replaceAll('\n', ' '))
  .join('\n');
