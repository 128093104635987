import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { AccountingType } from 'v2-types/order';

import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import { chargesSorted } from '.';
import { getOrder } from './querys';

type Params = {
  shouldFilterCharges?: boolean
}
const useGetCharges = ({ shouldFilterCharges = true }: Params) => {
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const [currentRowSelected, setCurrentRowSelected] = useState<columnsDataSet | null>(null);

  const location = useLocation();
  const { pathname } = location;
  const currentLetter = pathname.split('/')[pathname.split('/').length - 1].toUpperCase();

  const { isLoading, isFetching } = useQuery(
    ['accounting-order', orderId],
    () => getOrder(orderId),
    {
      enabled: !!orderId && !!currentLetter,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setAccounting(
            chargesSorted(data.getOrder.accounting?.filter((acc) => acc.letter === currentLetter) ?? [], !shouldFilterCharges)
          );
        }
      },
    }
  );

  const handleSetAccounting = useCallback(
    (data: AccountingType[]) => {
      setAccounting(
        chargesSorted(shouldFilterCharges ? data.filter((acc) => acc.letter === currentLetter) : data, !shouldFilterCharges)
      );
    },
    [currentLetter, shouldFilterCharges]
  );

  useEffect(() => {
    if (isFetching && !isLoading) {
      showAlert('Updating...', 'info');
    }
  }, [isFetching, showAlert, isLoading]);

  return {
    accounting,
    handleSetAccounting,
    isLoading,
    isFetching,
    currentRowSelected,
    setCurrentRowSelected
  };
};

export default useGetCharges;
