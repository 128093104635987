import { useAuth0 } from '@auth0/auth0-react';
import { useSocket } from 'contexts/socketcontext/hooks';
import { initializeSocketConnection } from 'contexts/socketcontext/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getProfile } from 'redux/profile-reducer/selectors';
import { AGENCY_SELECTED_ID, TOKEN_CLAIMS } from 'utils/constants';

export default function AuthenticatedLayout() {
  const history = useHistory();
  const location = useLocation();
  const { logout, isAuthenticated } = useAuth0();
  const { t, i18n } = useTranslation();
  const { socket, updateSocket } = useSocket();

  const { first_name, last_name, accesses } = useSelector(getProfile);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [agencySelected, setAgencySelected] = useState(
    localStorage.getItem(AGENCY_SELECTED_ID) || ''
  );

  const selectAgency = (agencyId: string) => {
    localStorage.setItem(AGENCY_SELECTED_ID, agencyId);
    setAgencySelected(agencyId);
  };

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_CLAIMS);

    if (isAuthenticated && token && !socket) {
      const localsocket = initializeSocketConnection(token);
      updateSocket(localsocket);
    }

    return () => {
      if (socket) {
        socket.off();
        socket.disconnect();
        updateSocket(null);
      }
    };
  }, []);

  return {
    t,
    i18n,
    history,
    location,
    userIsTitleAdmin: accesses.length > 0,
    anchorEl,
    setAnchorEl,
    languageAnchorEl,
    setLanguageAnchorEl,
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
    logout,
    userLetters: `${first_name.charAt(0)}${last_name.charAt(0)}`,
    agencySelected,
    selectAgency,
  };
}
