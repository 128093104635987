import { Grid } from '@material-ui/core';
// import TableComponent from 'components/Table';
import React, { FC, useCallback, useMemo } from 'react';
import { AccountingType } from 'v2-types/order';

import { TableLedgerItemColumn } from '../../services';
import useAccountingContext from '../../services/useAccountingContext';
import { generateCollapseRow, ledgerColumns, parseLedgerColumns } from './services';
import useSettingTable from './table-ledger-hooks';
import VirtualizedTable from 'components/virtualized-table';

type ledgerType ={
  ledgerData:AccountingType[],

}

const Ledger: FC<ledgerType> = ({ ledgerData, }) => {
  const { itemsCollapsed, setItemCollapse, t } = useSettingTable();
  const {
    handleAddParty,
	 handleEditAmount,
	 handleEditLedgerAssociation,
	 handleDeleteLedgetEntry,
    handleDeleteLegerAssociation,
    handleCreateLink
  } = useAccountingContext()! || {};
  const columnscollapsed = generateCollapseRow(handleEditLedgerAssociation, handleDeleteLegerAssociation);
  const handleCollapseRow = useCallback((row:any) => {
    setItemCollapse(row.id);
  }, [setItemCollapse]);
  const columns = useMemo(() => ledgerColumns(t, handleEditAmount, handleCollapseRow, itemsCollapsed, handleAddParty, handleDeleteLedgetEntry, handleCreateLink),
    [handleAddParty, handleCollapseRow, handleDeleteLedgetEntry, handleEditAmount, itemsCollapsed, t, handleCreateLink]);

  const parsedcolumns = parseLedgerColumns(ledgerData);
  return (
    <Grid container direction="column">
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <VirtualizedTable
          columns={columns}
          dataSet={parsedcolumns as TableLedgerItemColumn[]}
          itemsCollapsed={itemsCollapsed}
          columnCollapsing={columnscollapsed}
        />
      </Grid>
    </Grid>
  );
};

export default Ledger;
