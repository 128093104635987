import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import {
  NewOrderPartyParams,
  NewOrderPartyType,
  NewOrderType,
} from 'types/order';

export type GQLGetOrder = {
  getOrder: NewOrderType;
};

const getOrder = async (_: string, id: string) => {
  const query = gql`
    query GetOrder($id: ID!) {
      getOrder(_id: $id) {
        _id
        status
        order_id
        tokens {
          party_id
          email
          kind
          message
          token
          url
        }
		configuration {
			acceptance_date
			closing_date
			contract_date
			disbursement_date
			funding_date
			transaction_type
			type
			workflow
			closing_place {
				_id
				address
				country
				geo {
					coordinates
					type
				}
				locality
				kind
				postal_code
				references
				settlement
				state
				street_address
			}
		  }
        parties {
          _id
          name
          associations {
            linked_as
            party_id
            party_kind
            party_name
          }
          kinds
          member_of {
            _id
            kind
            name
          }
          order_data {
            ... on OrderPartyBuyer {
              acknowledgement
              declines_ssn_ein
              exception_1099
              gross_proceeds
              include_marital
              include_vesting
              is_foreign
              jurat
              kind
              kind_owner
              listed_on_title
              name_control
              ownership
              part_of_tax
              vesting
              vesting_block {
                kind
                text
                tabs
              }
              vesting_type
            }
            ... on OrderPartyExchange1031 {
              exchange_1031
              kind
            }
            ... on OrderPartySeller {
              acknowledgement
              declines_ssn_ein
              exception_1099
              gross_proceeds
              include_marital
              include_vesting
              is_foreign
              jurat
              kind
              kind_owner
              listed_on_title
              name_control
              ownership
              part_of_tax
              vesting
              vesting_block {
                kind
                text
                tabs
              }
              vesting_type
            }
            ... on OrderPartyPowerOfAttorney {
              end_date
              kind
              start_date
            }
          }
        }
        title_commitment {
          book
          certificate_number
          chain_clause
          chain_clause_hyperlink
          commitment_type
          document_date
          document_name
          document_number
          exceptions
          grantee
          grantor
          instrument_number
          instrument_type
          page
          recorded_date
          requirements
          stakeholders
        }
        estates {
          _id
          address {
            _id
            address
            country
            geo {
              coordinates
              type
            }
            locality
            postal_code
            references
            settlement
            state
            street_address
          }
          parcel_ids
          lot
          legal_description
          jurisdiction
          block
          range
          subdivision
          section
          survey_date
          survey_information
          survey_number
          township
          type
          accounting {
            _id
            amount
            amount_calculated
            amount_override
            code
            description
            entry_date
            involved_parties {
              _id
              amount
              at_closing_amount
              at_closing_percent
              before_closing_amount
              before_closing_percent
              kind
              member_of {
                _id
                kind
                name
              }
              name
              order_kinds
              payment_id
              payment_kind
              payment_reference
              percent
            }
            kind
            letter
            number
          }
        }
        accounting {
          _id
          amount
          amount_calculated
          amount_override
          code
          description
          entry_date
          involved_parties {
            _id
            amount
            at_closing_amount
            at_closing_percent
            before_closing_amount
            before_closing_percent
            kind
            member_of {
              _id
              kind
              name
            }
            name
            order_kinds
            payment_id
            payment_kind
            payment_reference
            percent
          }
          kind
          letter
          number
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLGetOrder>(query, { id });

  return response;
};

export type GQLCreateAssociation = {
  createOrderAssociation: {
    parties: NewOrderPartyType[];
  };
};

const createAssociation = async (payload: NewOrderPartyParams) => {
  const mutation = gql`
    mutation CreateOrderAssociation(
      $id: ID!
      $partyId: ID!
      $kind: String!
      $linkedToId: String
      $linkedToKind: String
    ) {
      createOrderAssociation(
        _id: $id
        party_id: $partyId
        kind: $kind
        linked_to_id: $linkedToId
        linked_to_kind: $linkedToKind
      ) {
        parties {
          _id
          name
          associations {
            linked_as
            party_id
            party_kind
            party_name
          }
          kinds
          member_of {
            _id
            kind
            name
          }
          order_data {
            ... on OrderPartyBuyer {
              acknowledgement
              declines_ssn_ein
              exception_1099
              gross_proceeds
              include_marital
              include_vesting
              is_foreign
              jurat
              kind
              kind_owner
              listed_on_title
              name_control
              ownership
              part_of_tax
              vesting
              vesting_block {
                kind
                text
                tabs
              }
              vesting_type
            }
            ... on OrderPartyExchange1031 {
              exchange_1031
              kind
            }
            ... on OrderPartySeller {
              acknowledgement
              declines_ssn_ein
              exception_1099
              gross_proceeds
              include_marital
              include_vesting
              is_foreign
              jurat
              kind
              kind_owner
              listed_on_title
              name_control
              ownership
              part_of_tax
              vesting
              vesting_block {
                kind
                text
                tabs
              }
              vesting_type
            }
            ... on OrderPartyPowerOfAttorney {
              end_date
              kind
              start_date
            }
          }
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreateAssociation>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeleteAssociation = {
  deleteOrderAssociation: {
    parties: NewOrderPartyType[];
  };
};

const deleteAssociation = async (payload: {
  id: string;
  partyId: string;
  kind: string;
}) => {
  const mutation = gql`
    mutation DeleteOrderAssociation($id: ID!, $partyId: ID!, $kind: String) {
      deleteOrderAssociation(_id: $id, party_id: $partyId, kind: $kind) {
        parties {
          _id
          name
          associations {
            linked_as
            party_id
            party_kind
            party_name
          }
          kinds
          member_of {
            _id
            kind
            name
          }
          order_data {
            ... on OrderPartyBuyer {
              acknowledgement
              declines_ssn_ein
              exception_1099
              gross_proceeds
              include_marital
              include_vesting
              is_foreign
              jurat
              kind
              kind_owner
              listed_on_title
              name_control
              ownership
              part_of_tax
              vesting
              vesting_block {
                kind
                text
                tabs
              }
              vesting_type
            }
            ... on OrderPartyExchange1031 {
              exchange_1031
              kind
            }
            ... on OrderPartySeller {
              acknowledgement
              declines_ssn_ein
              exception_1099
              gross_proceeds
              include_marital
              include_vesting
              is_foreign
              jurat
              kind
              kind_owner
              listed_on_title
              name_control
              ownership
              part_of_tax
              vesting
              vesting_block {
                kind
                text
                tabs
              }
              vesting_type
            }
            ... on OrderPartyPowerOfAttorney {
              end_date
              kind
              start_date
            }
          }
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLDeleteAssociation>(
    mutation,
    payload
  );

  return response;
};

export default {
  getOrder,
  createAssociation,
  deleteAssociation,
};
