import Actionbuttons from 'components/common/actions-buttons';
import { TFunction } from 'i18next';
import React from 'react';
import { IntlCurrencyFormat } from 'utils/helpers';
import { ColumnsType } from 'views/orders/accounting/services';
import { CellText } from 'views/orders/charges-accounting/components/table-cells';
import * as yup from 'yup';

import AccountedForC from '../components/accounted-for-element';

const dicCodeentries = {
  cash: 'Cash Transaction',
  wire: 'Wire Transaction',
  check: 'Check Transaction'
};

export const disbursementColumns = (
  t: TFunction,
  onAddReceipt:any,
  onDeleteReceipt:any,
  onDeleteEntry:any
):ColumnsType => ({
  parties: {
	  header: {
      title: t('accounting:actions'),
      width: '130px',
      minWidth: '130px'
	  },
	  renderer: ({ row }) => (
  <Actionbuttons
    handleEdit={(e) => {
      e.stopPropagation();
	  onAddReceipt('edit-entry', row, null);
    }}
    handleDelete={(e) => {
      e.stopPropagation();
      onDeleteEntry(row._id);
    }}
    disableDelete={row?.accounted_for?.length !== 0}
  />
    )
  },
  kind: {
	  header: {
      title: t('accounting:kind'),
      align: 'left'
	  },
	  renderer: ({ row }) => (
  <CellText data={dicCodeentries[row.code]} />
	  ),
  },
  description: {
	  header: {
      title: t('accounting:description'),
      align: 'left'
	  },
	  renderer: ({ row }) => (
  <CellText data={row.description} />
	  ),
  },
  amount: {
	  header: {
      title: t('accounting:amount'),
      width: '100px',
      minWidth: '100px'
	  },
	  renderer: ({ row }) => (
      // eslint-disable-next-line radix
  <CellText data={IntlCurrencyFormat(row.amount)} />
	  ),
  },
  accountedFor: {
	  header: { title: t('accounting:accounted-for'), },
	  renderer: ({ row }) => (
  <AccountedForC
    accountedItems={row?.accounted_for || []}
    onDelete={onDeleteReceipt}
    onAddReceipt={() => {
	  onAddReceipt('link', row, null);
    }}
    accountingId={row._id}
  />
	  ),
  },
});

export const validationSchema = yup.object({
  code: yup.string().required('Required'),
  description: yup.string().required('Required'),
  entryDate: yup.string().required('Required')
});

export const getHideFields = {
  'edit-entry': {
    code: true,
    kind: true,
    amount: true,
    months_advanced: true,
    annuality: true,
    number: true,
    letter: true,
    months: true

  },
  'add-entry': {
    amount_override: true,
	  months_advanced: true,
    annuality: true,
    number: true,
    letter: true,
    months: true,
    kind: true
  },
  other: {}
};
