import { Input, makeStyles, Typography } from '@material-ui/core';
import Switch from 'components/switch';
import { TFunction } from 'i18next';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  addDescField: {
    marginLeft: theme.spacing(3),
    '&>*': {
      height: theme.spacing(4),
      width: theme.spacing(30),
    },
  },
  underline: {
    fontSize: theme.spacing(2),
    color: theme.palette.primary.light,
    fontWeight: 500,
    width: '100%',
    paddingLeft: theme.spacing(1),
    '&&&:before': { borderBottom: 'none' },
    '&&:after': { borderBottom: 'none' },
    '&:hover': { background: theme.palette.input.border },
  },
}));

type IncludesFieldsType = {
  other: boolean;
  description: string
  onSwitchChange: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  homeownersInsurance: boolean;
  propertiesTaxes: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  t: TFunction;
}

const IncludesFields = ({
  other,
  description,
  onSwitchChange,
  homeownersInsurance,
  propertiesTaxes,
  t,
  setFieldValue,
}: IncludesFieldsType) => {
  const classes = useStyles();

  return (
    <>
      <span className={classes.root}>
        <Switch
          label=""
          checked={propertiesTaxes}
          handleOnChange={(e) => onSwitchChange('estimated_taxes.includes_property_taxes', e.target.checked)}
        />

        <Typography>{t('projectedPayments:propertiesTaxes')}</Typography>
      </span>

      <span className={classes.root}>
        <Switch
          label=""
          checked={homeownersInsurance}
          handleOnChange={(e) => onSwitchChange('estimated_taxes.includes_homeowners_insurance', e.target.checked)}
        />

        <Typography>{t('projectedPayments:homeownersInsurance')}</Typography>
      </span>

      <span className={classes.root}>
        <Switch
          label=""
          checked={other}
          handleOnChange={(e) => onSwitchChange('estimated_taxes.includes_other', e.target.checked)}
        />

        <Typography>{t('projectedPayments:other')}</Typography>

        <span className={classes.addDescField}>
          <Input
            className={classes.underline}
            placeholder={t('projectedPayments:addADescription')}
            value={description}
            onChange={(e) => setFieldValue('estimated_taxes.description', e.target.value)}
          />
        </span>
      </span>
    </>
  );
};

export default IncludesFields;
