import graphQLClient from "config/graphql-instance";
import { gql } from "graphql-request";

type instructions = {
  accounting_id: string;
  amount: number;
  association_id: string;
};

export const createTransferRequest = async (payload: {
  orderId: string;
  authorization: string[];
  instructions: instructions[];
}) => {
  const mutation = gql`
    mutation CreateTransferRequest(
      $orderId: String!
      $authorization: [String!]!
      $instructions: [TransferInstructionContent!]!
    ) {
      createTransferRequest(
        order_id: $orderId
        authorization: $authorization
        instructions: $instructions
      )
    }
  `;

  const response = await graphQLClient().request(mutation, payload);

  return response;
};

export const authorizeTransferRequest = async (payload: {
  orderId: string;
  reference: string;
  action: string;
}) => {
  const mutation = gql`
    mutation CreateTransferRequest(
      $orderId: String!
      $reference: String!
      $action: TransferAuthorizeAction!
    ) {
      authorizeTransferRequest(
        order_id: $orderId
        reference: $reference
        action: $action
      ) {
        status
        filename
        confirmation
        authorization
        signatures
      }
    }
  `;

  const response = await graphQLClient().request(mutation, payload);

  return response;
};
