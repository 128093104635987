import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  input: {
    '& .MuiFilledInput-input': {
      fontSize: '0.75rem',
      paddingBottom: '5px',
      paddingLeft: '7px'
    }
  }
}));
