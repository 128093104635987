import { Box, makeStyles, Typography } from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import React, { FC } from 'react';
import { TFunction } from 'react-i18next';
import { NewEmailType, NewPhoneType, NewUserType } from 'types/new-user';
import { NewAddressType } from 'v2-types/user';

const useStyles = makeStyles((theme: any) => ({
  weigthfont: { fontWeight: 500 },
  contactName: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  container: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    '&:hover': { backgroundColor: theme.palette.background.default }
  }
}));

const InfoSection = ({ title, info }: { title: string, info: NewEmailType[] | NewPhoneType[] | NewAddressType[] }) => (
  <Box flex="1" display="flex" flexDirection="column">
    <Typography variant="body2" style={{ fontWeight: 500, color: '#4F5B62' }}>{title}</Typography>
    {info.map((element) => (
      <Typography key={element.id} variant="caption" style={{ fontWeight: 500 }}>{`${element.number || element.address}`}</Typography>
    ))}
  </Box>
);

type customOptionProps = {
  contact: NewUserType,
  t: TFunction
  handleContactCreation?: (kind: string) => void,
  compact?: boolean
}

const CustomPartyOption: FC<customOptionProps> = ({ contact, handleContactCreation, t, compact }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" flex="1" className={classes.container}>
      {contact._id !== 'add-new-contact'
        ? (
          <>
            <Typography variant="body2" className={classes.contactName}>{contact.name}</Typography>
            {!compact && (
              <Box display="flex" flexDirection="row">
                {contact.phones.length > 0 && <InfoSection title="Phones" info={contact.phones} />}
                {contact.addresses.length > 0 && <InfoSection title="Addresses" info={contact.addresses} />}
                {contact.emails.length > 0 && <InfoSection title="Emails" info={contact.emails} />}
              </Box>
            )}
          </>
        )
        : (
          <Box style={{ display: "flex", gap: "10px" }}>
            <ContainedButton
              size="large"
              width="100%"
              text="Create Person"
              onClick={(e) => {
                e.stopPropagation();
                if (handleContactCreation) {
                  handleContactCreation("Person");
                }
              }}
            />
            <ContainedButton
              size="large"
              width="100%"
              text="Create Company"
              onClick={(e) => {
                e.stopPropagation();
                if (handleContactCreation) {
                  handleContactCreation("Company");
                }
              }}
            />
          </Box>
        )}
    </Box>
  );
};

export default CustomPartyOption;
