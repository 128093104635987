import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

export type GQLGetCalculation = {
  getCalculation: {
    amount: number;
  };
};

const getCalculation = async (payload: { orderId: string; code: string }) => {
  const query = gql`
    query GetCalculation($orderId: ID!, $code: String!) {
      getCalculation(order_id: $orderId, code: $code) {
        amount
      }
    }
  `;

  const response = await graphQLClient()
    .request<GQLGetCalculation>(query, payload);

  return response;
};

export type GQLGetCalculations = {
  getCalculations: {
    code: string;
    amount: number;
  }[];
};

const getCalculations = async (payload: { orderId: string; codes: string[] }) => {
  const query = gql`
    query GetCalculations($orderId: ID!, $codes: [String!]!) {
      getCalculations(order_id: $orderId, codes: $codes) {
        code
        amount
      }
    }
  `;

  const response = await graphQLClient()
    .request<GQLGetCalculations>(query, payload);

  return response;
};

export default { getCalculation, getCalculations };
