import { Typography } from '@material-ui/core';
import NumberField from 'components/number-field';
import React, { FC } from 'react';

import { useStyles } from './styles';

export type ConditionFieldProps = {
  prefix: string,
  value: number | undefined,
  onChange: (value: number) => void,
  sufix?: string,
  format?: string
}

export const ConditionField: FC<ConditionFieldProps> = ({
  prefix,
  value,
  onChange,
  sufix,
  format
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="caption"
        style={{ whiteSpace: 'nowrap', alignContent: 'flex-end' }}
      >
        {prefix}
      </Typography>
      <NumberField
        value={value}
        format={format}
        style={{ width: '60px', paddingLeft: '0px', paddingRight: '0px' }}
        InputProps={{ className: classes.input }}
        onChange={(e) => {
          let newValue = Number(e.target.value);
          if (Number.isNaN(newValue)) {
            newValue = 0;
          }
          onChange(newValue);
        }}
      />
      {sufix && (
        <Typography
          variant="caption"
          style={{ whiteSpace: 'nowrap', alignContent: 'flex-end' }}
        >
          {sufix}
          &nbsp;
        </Typography>
      )}
    </>
  );
};
