import { Grid, makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NewUserType } from 'types/new-user';

import Information from '../components/information-tab';
import SkeletonParties from '../components/skeleton-loading';
import TitleAction from '../components/title-action';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: `${theme.spacing(6)}px ${theme.spacing(4)}px` },
  containerModal: { padding: `${theme.spacing(2)}px ${theme.spacing(2)}px` },
  containerCommon: {
    overflow: 'scroll',
    height: '68vh',
  },
  title: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
  infoContainer: { marginTop: theme.spacing(2.5) },
}));

type LenderSceneProps = {
  party: NewUserType | undefined;
  isPartyLoading: boolean;
  isModal: boolean;
};

export const SimpleParty: FC<LenderSceneProps> = ({
  party,
  isPartyLoading,
  isModal,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isPartyLoading) {
    return <SkeletonParties />;
  }
  return (
    <Grid container direction="column">
      <Paper
        elevation={0}
        className={clsx({
          [classes.container]: isModal === false,
          [classes.containerModal]: isModal === true,
          [classes.containerCommon]: true,
        })}
      >
        <Grid item xs={12}>
          <TitleAction
            type={party?.kind.split('_').join(' ') || ''}
            name={party?.name || ''}
            partyId={party?._id || ''}
          />
        </Grid>

        {party && (
          <Grid item xs={12} className={classes.infoContainer}>
            <Information t={t} party={party} mainParty={false} />
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
