import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.light,
  },
  chip: {
    fontSize: '0.6rem',
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
  },
  options: {
    display: 'flex',
    gap: 5
  },
  inviteButton: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  forgotPasswordButton: {
    textTransform: 'capitalize',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icon: { marginRight: theme.spacing(1) },
}));

type HeaderProps = {
  t: TFunction;
  name: string;
  user_id: string;
  isTitleCompany: boolean;
  handleOpenConfirmModal: (state: boolean, kind: 'delete' | 'unlink') => void;
  handleOpenInviteUser: () => void;
  handleOpenModalResetPassword: (state: boolean) => void;
  userHasAccessToResetPassword: boolean;
};

const Header: FC<HeaderProps> = ({
  t,
  name,
  user_id,
  isTitleCompany,
  handleOpenConfirmModal,
  handleOpenInviteUser,
  handleOpenModalResetPassword,
  userHasAccessToResetPassword
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={6}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              {name}
            </Typography>
          </Grid>

          {user_id && (
            <Grid item>
              <Chip
                size="small"
                color="secondary"
                label={t('users:user-system')}
                className={classes.chip}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {!isTitleCompany && (
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {!user_id && (
              <Grid item>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  className={classes.inviteButton}
                  onClick={handleOpenInviteUser}
                >
                  {t('users:invite-user')}
                </Button>
              </Grid>
            )}

            {user_id && (
              <Grid item className={classes.options}>
                {userHasAccessToResetPassword && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    className={classes.forgotPasswordButton}
                    onClick={() => handleOpenModalResetPassword(true)}
                  >
                    {t('contact:reset-password')}
                  </Button>
                )}
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  className={classes.inviteButton}
                  onClick={() => handleOpenConfirmModal(true, 'unlink')}
                >
                  {t('users:unlink')}
                </Button>
              </Grid>
            )}

            {!user_id && (
              <Grid item>
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  className={classes.inviteButton}
                  onClick={() => handleOpenConfirmModal(true, 'delete')}
                >
                  <FontAwesomeIcon className={classes.icon} icon="trash-alt" />
                  {t('users:delete')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
