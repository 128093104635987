import { useTheme } from '@material-ui/styles';
import { getParty } from 'graphql/parties/queries';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import querys from 'services/querys';
import { NewOrderPartyType, NewOrderType } from 'types/order';
import useAlert from 'utils/alert';
import { PARTY_KIND_BUYER, PARTY_KIND_ORDER_OPENER, PARTY_KIND_SELLER, PARTY_KIND_SETTLEMENT_AGENCY } from 'utils/constants';

import { tableRowComponent } from '../../services';
import { generateColumns, scheduleMettingTokens } from './services';

const defaultKinds = [PARTY_KIND_SELLER, PARTY_KIND_BUYER, PARTY_KIND_ORDER_OPENER, PARTY_KIND_SETTLEMENT_AGENCY];

const useRonScheduleMeeting = (
  tokensInfo:scheduleMettingTokens,
  onClose: ()=> void,
  setTokensRon: React.Dispatch<React.SetStateAction<tableRowComponent[]>>,
  graphOrder: NewOrderType | undefined
) => {
  const { t } = useTranslation();
  const [partySelected, setPartySelected] = useState<string | null>(null);
  const { id: orderId } = useParams<{ id: string }>();
  const methods = useForm<scheduleMettingTokens>();
  const { fields, remove, append } = useFieldArray({ name: 'parties', control: methods.control });
  const showAlert = useAlert();
  const theme = useTheme();

  const [updpateTokens, updateTokensResponse] = useMutation(querys.updateOrderTokens, {
    onSuccess: (data) => {
      const { tokens } = data.updateOrderTokens;
      setTokensRon(fields.map((fieldToken) => ({
        party_info: fieldToken,
        url: tokens.find((i) => i.party_id === fieldToken._id)?.url!,
        token: tokens.find((i) => i.party_id === fieldToken._id)?.token!
      })));
      onClose();
      showAlert('Meeting Updated', 'success');
    }
  });

  const getPartyQuery = useQuery(
    ['getBuyer', partySelected],
    () => getParty({ id: partySelected! }),
    {
      enabled: partySelected !== null && partySelected !== '',
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setPartySelected(null);
          getPartyQuery.clear();
          append(data.getParty);
        }
      }
    },
  );

  useEffect(() => {
    const {
      date,
      init_time,
      end_time,
      parties,
      id
    } = tokensInfo;
    const { setValue } = methods;

    const fillPrebuilt = async (partiesTofind: NewOrderPartyType[] | undefined) => {
      const Proms = partiesTofind?.map((item) => getParty({ id: item._id })) || [];
      const PromiesesResult = await Promise.all(Proms);
      setValue('parties', PromiesesResult.map((p) => (p.getParty)));
    };

    if (parties.length === 0) {
      const defaultParties = graphOrder?.parties.filter((party) => defaultKinds.some((kind) => party.kinds.includes(kind))) || [];
      fillPrebuilt(defaultParties || []);
    } else {
      setValue('parties', parties || []);
    }

    setValue('date', date || '');
    setValue('end_time', end_time || null);
    setValue('parties', parties || []);
    setValue('init_time', init_time || null);
    setValue('id', id || undefined);
  }, [graphOrder?.parties, methods, tokensInfo]);

  const columns = useMemo(() => generateColumns(
    t,
    theme,
    (index) => remove(index)
  ), [remove, t, theme]);

  return {
    t,
    columns,
    methods,
    getPartyQuery,
    setPartySelected,
    attendies: fields,
    orderId,
    updpateTokens,
    loading: updateTokensResponse.isLoading,
    involvedParties: graphOrder?.parties
  };
};

export default useRonScheduleMeeting;
