import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { capitalize } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';
import HeaderPayor from 'views/orders/charges-accounting/components/header-payor';
import FormBreakdown from 'views/orders/charges-accounting/components/payment-breakdown';

const useStyles = makeStyles((theme: any) => ({
  containerPayment: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.offBackground.default,
    marginBottom: theme.spacing(3),
  },
}));

type PaymentLineProps = {
  currentEntry: AccountingType;
  handleClose: () => void;
  handleSuccess: () => void;
};
const PaymentLineConfig: FC<PaymentLineProps> = ({
  currentEntry,
  handleClose,
  handleSuccess,
}) => {
  const description = currentEntry.code.includes('line_item')
    ? currentEntry.description
    : capitalize(currentEntry.code.split('_')[1] || '');

  const classes = useStyles();

  return (
    <Grid item className={classes.containerPayment}>
      <Box
        display="flex"
        flexDirection="row"
        flex={1}
        justifyContent="flex-end"
      >
        <IconButton style={{ padding: 0 }} onClick={handleClose}>
          <FontAwesomeIcon icon="times" size="xs" />
        </IconButton>
      </Box>
      <HeaderPayor
        amount={currentEntry?.amount || 0}
        description={description}
        accountingID={currentEntry?._id || ''}
        handleSetAccounting={handleSuccess}
      />
      <Grid container direction="column">
        <FormBreakdown
          accountingInfo={currentEntry}
          handleSuccess={handleSuccess}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentLineConfig;
