import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { OrderProperty } from 'types/order';

import { SurveyScene } from './survey-scene';

type TabSurveyProps = {
  control: Control<OrderProperty, object>,
}

export const TabSurvey: FC<TabSurveyProps> = ({ control }) => (
  <SurveyScene
    control={control}
  />
);
