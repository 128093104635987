import {
  Box, Button, Grid, Link, makeStyles, Typography
} from '@material-ui/core';
import championshipLogo from 'assets/images/championship-logo@3x.png';
import Cityimage from 'assets/images/city-img-bw@2x.png';
import clsx from 'clsx';
import OverviewCard from 'components/cards/overview-card';
import { TFunction } from 'i18next';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: theme.palette.primary.dark,
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    margin: 0,
    padding: 0
  },
  logo: {
    marginTop: theme.spacing(11),
    // width: theme.spacing(28),
    height: theme.spacing(8.5),
    alignSelf: 'center',
    marginBottom: theme.spacing(5)
  },
  content: {
    borderTopStyle: 'solid',
    borderTopColor: theme.accent.main,
    borderTopWidth: 3,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(5),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonFilled: {
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(2),
    '&:hover': { backgroundColor: theme.palette.button.hover },
  },
  disabledButton: { opacity: 0.5 },
  textbutton: { color: theme.palette.primary.main, fontWeight: 500 },
  icon: { height: 16, width: 16, color: theme.palette.primary.main },
  title: {
    color: theme.palette.secondary.light,
    fontWeight: 500,
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
  subtitlebottom: { marginBottom: theme.spacing(3), textAlign: 'center' },
  buttonmargin: { marginBottom: theme.spacing(5) },
  linkbutton: { textAlign: 'center' },
  whitcolor: { color: theme.palette.common.white, },
  thankscontainer: { width: theme.spacing(69) },
  unsuscriedTitle: {
    color: theme.accent.main,
    fontWeight: 500,
    marginBottom: theme.spacing(2)
  }
}));

type TrustingType ={
  t:TFunction,
  showFinalScreen: boolean,
  handleUnsuscribe: ()=>void
}

const ThanksTrusting = ({ t, showFinalScreen, handleUnsuscribe }:TrustingType) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <img src={championshipLogo} alt="logo" className={classes.logo} />
      <Box className={classes.thankscontainer} justifyContent="center">
        <OverviewCard noPadding noTitle>
          <img src={Cityimage} alt="city" />
          <Box className={classes.content}>
            {!showFinalScreen ? (
              <>
                <Typography variant="h6" className={classes.title}>
                  {t('documents:are-you-sure')}
                </Typography>
                <Typography variant="body2" className={clsx(classes.subtitle, classes.subtitlebottom)}>
                  {t('documents:if-you-unsubscribe')}
                </Typography>

                <Button
                  className={classes.buttonFilled}
                  onClick={handleUnsuscribe}
                  size="large"
                >
                  <Typography variant="body2" className={classes.textbutton}>
                    {t('documents:yes-unsubscribe')}
                  </Typography>
                </Button>
              </>
            )
              : (
                <>
                  <Typography variant="h6" className={classes.unsuscriedTitle}>
                    {t('documents:unsubscribed')}
                  </Typography>
                  <Typography variant="body2" className={clsx(classes.subtitle, classes.subtitlebottom)}>
                    {t('documents:we-miss-you')}
                  </Typography>
                  <Typography variant="caption" className={classes.subtitle}>
                    {t('documents:subscribe-again')}
                  </Typography>
                  <Typography variant="caption" className={clsx(classes.subtitle, classes.buttonmargin)}>
                    {t('documents:clsing-agent')}
                  </Typography>

                </>
              ) }
          </Box>
        </OverviewCard>
        <Typography variant="body2" className={clsx(classes.linkbutton, classes.whitcolor)}>
          {t('documents:you-can-check')}
          <Link href="https://www.google.com/" className={classes.whitcolor} underline="always">
            {` ${t('documents:privacy-notice')} `}
          </Link>
          {t('documents:for-more-informtion')}
        </Typography>
      </Box>
    </Grid>
  );
};

export default ThanksTrusting;
