/* eslint-disable react/no-array-index-key */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import React from 'react';
import { Column } from 'types/common';

import useHeader from '../services/useHeader';
import useStyles from '../style';

type HeadType = {
  columns: Column[],
  disabledBorderCell?: boolean,
  disableFullborderCell?:boolean
};

const HeadTable = ({
  columns,
  disabledBorderCell,
  disableFullborderCell
}: HeadType) => {
  const {
    titleColumn,
    cursor,
    cellHeader,
    borderBottomCell,
    borderCellStyle
  } = useStyles();
  const { header, subHeader, hasHeader } = useHeader(columns);
  return (
    <>
      {hasHeader && (
      <TableRow>
        {header?.map((head, index) => (
          <TableCell
            colSpan={head?.colspan}
            className={`
            ${!disabledBorderCell && borderBottomCell}
            ${!disableFullborderCell && borderCellStyle}
            `}
            style={{ minWidth: head?.width, padding: head?.padding, background: 'white' }}
            key={`header-${index}`}
          >
            <Box
              className={`${cursor} ${cellHeader}`}
              style={{ justifyContent: head?.align || 'center', width: 'auto', background: 'white' }}
            >
              <Typography
                variant="body1"
                className={titleColumn}
                variantMapping={{ body1: 'p' }}
                style={{ color: head?.textColor }}
              >
                {head?.title}
              </Typography>
            </Box>
          </TableCell>
        ))}
      </TableRow>
      )}
      <TableRow>
        {subHeader.map((column, index) => (
          !column.disabled
          && (
          <TableCell
            key={`sub-head-${index}`}
            className={`
            ${!disabledBorderCell && borderBottomCell}
            ${!disableFullborderCell && borderCellStyle}
            `}
            style={{ minWidth: column?.width, width: column?.minWidth, background: 'white' }}
          >
            <Box
              className={`${cursor} ${cellHeader}`}
              style={{ justifyContent: column?.align || 'center', width: 'auto' }}
            >
              <Typography
                variant="body1"
                className={titleColumn}
                variantMapping={{ body1: 'p' }}
                style={{ color: column?.textColor }}
              >
                {column?.title}
              </Typography>
            </Box>
          </TableCell>
          )
        ))}
      </TableRow>
    </>
  );
};

HeadTable.defaultProps = {
  disabledBorderCell: false,
  disableFullborderCell: false,
};

export default HeadTable;
