import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: { width: '100%', display: 'flex', alignItems: 'center', gap: '10px' },
  text: {
    fontFamily: 'Roboto',
    color: theme.palette.primary.darker,
    fontWeight: 400,
    background: theme.palette.background.default,
    opacity: 1
  },
  textShadow: { textShadow: `0.25px 0.5px ${theme.palette.text.light}` }
}));

type Props = {
  text: string
  position?: 'center' | 'left' | 'right',
  colorText?: string | undefined,
  shadow?: boolean,
  backgroundColor?: string | undefined,
  variant?: 'inherit' | Variant | undefined,
  action: () => void
};

export const CellWithAction: FC<Props> = ({
  text,
  position,
  colorText,
  shadow,
  backgroundColor,
  variant,
  action
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {text.length > 0 && (
        <FontAwesomeIcon
          icon="edit"
          size="lg"
          onClick={action}
        />
      )}
      <Typography
        variant={variant}
        style={{
          textAlign: position || 'center',
          color: colorText,
          background: backgroundColor,
          padding: '5px',
          flexGrow: 1
        }}
        className={`${classes.text} ${shadow && classes.textShadow}`}
      >
        {text}
      </Typography>
    </Box>
  );
};
