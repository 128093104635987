import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  paperContainer: {
    overflowY: 'scroll',
    maxHeight: '100%',
    height: '100%',
    padding: '30px 60px'
  },
  textField: {
    width: '100%',
    height: '100%',
  },
  inputTextArea: {
    height: '100%',
    maxHeight: '100%',
    alignItems: 'flex-start',
    whiteSpace: 'pre-wrap',
    lineHeight: '1.7',
    color: '#000000',
  }
}));
