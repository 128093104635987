import React from 'react';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import useDisbursementsReceipts from './disbursements-receipts-hooks';
import DisbursementsReceiptsScene from './disbursements-receipts-scene';

const DisbursementsReceipts = () => {
  const {
    data,
    state,
    dispatch,
    updateAccountingCache,
    handledeleteLink,
    deleteDisbursement
  } = useDisbursementsReceipts();

  const handleModal = (param:string | null, entryLedger?:AccountingType | null, entryAssociacion?:AccountingInvolvedParty | null) => {
    dispatch({
      type: param,
      associationSelected: entryAssociacion,
      entryLedger
	  });
  };

  return (
    <DisbursementsReceiptsScene
      data={data || []}
      handleModal={handleModal}
      state={state}
      handleUpdateAccounting={updateAccountingCache}
      handledeleteLink={handledeleteLink}
      handleDeleteEntry={deleteDisbursement}
    />
  );
};

export default DisbursementsReceipts;
