import NumberField from 'components/number-field';
import React, { FC } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { CreateInterestForm } from 'views/orders/loans/types';

import { formatValue, isNumberAllowed, onNumberChange } from './utils';

type Props = {
  control: Control<CreateInterestForm, object>,
  name: FieldPath<Pick<CreateInterestForm, 'rate' | 'margin' | 'change_min' | 'change_max' | 'rate_min' | 'rate_max'>>,
  label: string
}

export const PercentField: FC<Props> = ({
  control,
  name,
  label
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <NumberField
        color="secondary"
        label={label}
        name={label}
        handleBlur={field.onBlur}
        value={formatValue(field.value)}
        isAllowed={isNumberAllowed}
        onChange={(e) => onNumberChange(e, field.onChange)}
        suffix="%"
      />
    )}
  />
);
