import { Button, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { REFINANCE } from 'utils/constants';

import { FormPolicy } from '../services/types';
import usePolicy from '../services/use-policy-context';

const useStyles = makeStyles((theme) => ({
  image: {
    width: 125,
    marginBottom: theme.spacing(3),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    '&:hover': { backgroundColor: theme.palette.primary.main },
  },
  button: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'capitalize',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

type NavigationProps = {
  selectedPolicy: 'owners_policy' | 'lenders_policy';
  handleSelectPolicy: (param: 'owners_policy' | 'lenders_policy') => void;
  typeTransaction: string;
};

const Navigation: FC<NavigationProps> = ({
  selectedPolicy,
  handleSelectPolicy,
  typeTransaction,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setValue } = useFormContext<FormPolicy>();
  const context = usePolicy();
  const { accounting } = context || {};
  const isCashOnly = accounting?.find((acc) => acc.code.includes('loan_amount'))?.amount === 0;

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container direction="row" spacing={2}>
          {typeTransaction !== REFINANCE && (
            <Grid item>
              <Button
                disableElevation
                variant={
                  selectedPolicy === 'owners_policy' ? 'contained' : 'outlined'
                }
                className={clsx([
                  selectedPolicy === 'owners_policy'
                    ? classes.activeButton
                    : classes.button,
                ])}
                onClick={() => {
                  handleSelectPolicy('owners_policy');
                  setValue('selectedPolicy', 'owners_policy');
                }}
              >
                {t('policyInfoAndRates:owners-policy')}
              </Button>
            </Grid>
          )}

          <Grid item>
            {!isCashOnly && (
              <Button
                disableElevation
                variant={
                  selectedPolicy === 'lenders_policy' ? 'contained' : 'outlined'
                }
                className={clsx([
                  selectedPolicy === 'lenders_policy'
                    ? classes.activeButton
                    : classes.button,
                ])}
                onClick={() => {
                  handleSelectPolicy('lenders_policy');
                  setValue('selectedPolicy', 'lenders_policy');
                }}
              >
                {t('policyInfoAndRates:lenders-policy')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navigation;
