import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import { TFunction } from 'i18next';
import React from 'react';
import { formatStringInputToNumber } from 'utils/helpers';

import InputCell from './input-cell';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    '&>*': { marginRight: theme.spacing(4), },
  },
  textField: {
    height: theme.spacing(4),
    '&>.MuiInputBase-root': {
      height: theme.spacing(4),
      '&>.MuiSelect-root': {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
  },
}));

type PrincipalInterestAmountFieldsProps = {
  emptyValue: Boolean;
  data: Data[];
  dataKey: DataElement;
  dataValue: DataElement;
  dataText: DataElement;
  estimatedTaxesTable: any;
  onChangeInput: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  periodicity: string;
  errors: any;
  t: TFunction;
};

type DataElement = 'name' | 'text';

type Data = {
  name: String;
  text: String;
}

const AmountFields = ({
  emptyValue,
  data,
  dataKey,
  dataValue,
  dataText,
  estimatedTaxesTable,
  onChangeInput,
  periodicity,
  errors,
  t,
}: PrincipalInterestAmountFieldsProps) => {
  const classes = useStyles();
  const { amount } = estimatedTaxesTable;

  return (
    <span className={classes.root}>
      <InputCell
        helperText={errors.estimated_taxes?.amount}
        value={amount}
        onChangeInput={(e: any) => {
          const value = formatStringInputToNumber(e.target.value, true);
          onChangeInput('estimated_taxes.amount', value);
        }}
      />

      <TextField
        select
        fullWidth
        variant="filled"
        value={periodicity}
        className={classes.textField}
        InputProps={{ disableUnderline: true }}
        onChange={(e) => onChangeInput('estimated_taxes.periodicity', e.target.value)}
      >
        {emptyValue && (
          <MenuItem value="">
            {t('common:select-one')}
          </MenuItem>
        )}
        {data.map((element: any) => (
          <MenuItem
            key={element[dataKey]}
            value={element[dataValue]}
          >
            {element[dataText]}
          </MenuItem>
        ))}
      </TextField>
    </span>
  );
};

export default AmountFields;
