import React, { FC } from 'react';
import { NewOrderPartyType } from 'types/order';

import useExchangeTab from './tab-exchange-hooks';
import ExchangeTabScene from './tab-exchange-scene';

type ExchangeTabProps = {
  partyId: string;
  roleParty: string;
  exchange: NewOrderPartyType | undefined;
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>;
};
const ExchangeTab: FC<ExchangeTabProps> = ({
  partyId,
  roleParty,
  exchange,
  setLoaderActive,
}) => {
  const {
    t,
    parties,
    setSearchText,
    setExchangeID,
    orderId,
    deleteExchange,
    methods,
  } = useExchangeTab(partyId, roleParty, setLoaderActive);

  const handleSearch = (text: string): void => setSearchText(text);

  const handleDeletePoa = () => {
    if (orderId && exchange) {
      setLoaderActive(true);
      deleteExchange({
        id: orderId,
        partyId: exchange._id,
        kind: 'Exchange 1031',
      });
    }
  };

  return (
    <ExchangeTabScene
      t={t}
      handleSearch={handleSearch}
      parties={parties}
      setExchangeID={setExchangeID}
      exchange={exchange}
      handleDeletePoa={handleDeletePoa}
      methods={methods}
    />
  );
};

export default ExchangeTab;
