import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IntlCurrencyFormat } from 'utils/helpers';
import { AccountingInvolvedParty } from 'v2-types/order';

const useStyles = makeStyles((theme: any) => ({

  caption: {
    marginBottom: theme.spacing(0.2),
    color: theme.palette.primary.darker,
    fontSize: 10,
    textTransform: 'uppercase',
  },
  button: { textTransform: 'capitalize' },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '0.875rem',
    '&:hover': { color: theme.palette.common.black },
    alignSelf: 'center'
  },
}));

type DetaileAssociationProps = {
  association:AccountingInvolvedParty,
  handleDeleteAssociation: (kind: string, party_id: string) => void,
  partyDescription:string,
  completeInfo?:boolean
}

const DetailedInfo:FC<DetaileAssociationProps> = ({ association, handleDeleteAssociation, partyDescription, completeInfo }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="row" alignItems="center" style={{ marginBottom: 16 }}>
      <Grid item xs={3}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography className={classes.caption}>
              {partyDescription}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2" style={{ fontWeight: 500, color: 'black' }}>
              {`${association.name}${association.order_kinds.length > 0 ? `(${association.order_kinds.join(',')})` : ''}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={9}>
        <Box display="flex" flexDirection="row">
          <Box>
            <TextField
              value={`${IntlCurrencyFormat(association.amount)} (${association.percent * 100}%)`}
              label={t('accounting:full-amount')}
              disabled
            />
          </Box>
          {completeInfo
			&& (
<>

  <Box>
    <TextField
      value={`${IntlCurrencyFormat(association.at_closing_amount)} (${association.at_closing_percent * 100}%)`}
      label={t('accounting:at-closing-amount')}
      disabled
    />
  </Box>
  <Box>
    <TextField
      value={`${IntlCurrencyFormat(association.before_closing_amount)} (${association.before_closing_percent * 100}%)`}
      label={t('accounting:before-closing-amount')}
      disabled
    />
  </Box>
  <Box>
    <TextField
      value={association?.payment_reference || 'Not defined Yet'}
      label={t('accounting:payment-method')}
      disabled
    />
  </Box>
</>
			)}
          <IconButton
            className={classes.icon}
            onClick={() => {
              handleDeleteAssociation(association.kind, association._id);
            }}
          >
            <FontAwesomeIcon icon="times" />
          </IconButton>
        </Box>
      </Grid>

    </Grid>
  );
};

export default DetailedInfo;
