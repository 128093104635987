import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  icon: {
    fontSize: '16px',
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
}));

type SaveButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  customText?:string;
  type?: 'button' | 'reset' | 'submit' | undefined,
};

const SaveButton = ({
  disabled,
  loading,
  onClick,
  customText,
  type
}: SaveButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Button
      disableElevation
      color="secondary"
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {!loading && <FontAwesomeIcon icon="hdd" className={classes.icon} />}

      {loading && <FontAwesomeIcon spin icon="spinner" /> }

      {!loading && (
        <Typography variant="body2" className={classes.text}>
          {customText || t('common:save')}
        </Typography>
      )}
    </Button>
  );
};

SaveButton.defaultProps = {
  disabled: false,
  loading: false,
  customText: undefined,
  onClick: undefined,
  type: undefined
};

export default SaveButton;
