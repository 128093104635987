import { Box, Divider } from '@material-ui/core';
import { AddAndUpdatePartyLedgerEntry } from 'components/add-and-update-party-ledger-modal/add-and-update-party-ledger-entry';
import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import { DeleteConfirmationModal } from 'components/delete-confirmation';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AccountingType, Loan } from 'v2-types/order';

import { LoanFormType, ModalData } from '../../types';
import { Additional } from '../additional';
import { CreateInterestModal } from '../create-interest-modal';
import { Flags } from '../flags';
import { Interests } from '../interests';
import { LatePenaltyAs } from '../late-penalty-as';
import { LoanActions } from '../loan-actions';
import { LoanAmountAndAmountKind } from '../loan-amount-and-amount-kind';
import { Property } from '../property';
import { RelatedAmountsAccounting } from '../related-amounts-accounting';
import { TermsAndDatesIncreasesDisclosure } from '../terms-and-dates-increases-disclosure';
import { useStyles } from './styles';
import { kindAllowedAssociationList } from './utils';

type Props = {
  isLoansLoading: boolean,
  isUpdateLoanLoading: boolean,
  selectedLoan: Loan | undefined,
  loanForm: UseFormReturn<LoanFormType, object>,
  setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>,
  modalData: ModalData | undefined,
  onCloseModal: () => void,
  updateAccountingCache: (accounting: AccountingType[]) => void,
  onDeleteLoan: () => void,
  isDeleteLoanLoading: boolean,
  onDeleteLoanInterest: () => void,
  isDeleteInterestLoading: boolean,
  updateLoansCache: (loans: Loan[], loanIdToSelect?: string | undefined) => void,
  onSave: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
}

export const LoanContent: FC<Props> = ({
  isLoansLoading,
  isUpdateLoanLoading,
  selectedLoan,
  loanForm,
  setModalData,
  modalData,
  onCloseModal,
  updateAccountingCache,
  onDeleteLoan,
  onDeleteLoanInterest,
  isDeleteInterestLoading,
  isDeleteLoanLoading,
  updateLoansCache,
  onSave
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.content}>
      <LoanActions
        isDataLoading={isLoansLoading}
        isUpdateLoading={isUpdateLoanLoading}
        isFormDirty={loanForm.formState.isDirty}
        setModalData={setModalData}
        onSave={onSave}
      />
      <Box className={classes.container}>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '40px', flex: 1 }}>
          <Property
            isDataLoading={isLoansLoading}
            selectedLoan={selectedLoan}
          />

          <LoanAmountAndAmountKind
            isDataLoading={isLoansLoading}
            loanForm={loanForm}
          />

          <Flags loanForm={loanForm} />

          <TermsAndDatesIncreasesDisclosure
            loanForm={loanForm}
            isDataLoading={isLoansLoading}
          />
        </Box>

        <Divider orientation="vertical" className={classes.dividerVertical} />

        <Box style={{ display: 'flex', flexDirection: 'column', gap: '40px', flex: 1 }}>
          <RelatedAmountsAccounting
            isDataLoading={isLoansLoading}
            selectedLoan={selectedLoan}
            setModalData={setModalData}
          />

          <Additional
            setModalData={setModalData}
            isDataLoading={isLoansLoading}
            isUpdateLoading={isUpdateLoanLoading}
            loanAmountEntry={loanForm.getValues('loanAmountEntry')}
          />

          <LatePenaltyAs
            loanForm={loanForm}
            isDataLoading={isLoansLoading}
            isUpdateLoading={isUpdateLoanLoading}
          />

          <Interests
            isDataLoading={isLoansLoading}
            isUpdateLoading={isUpdateLoanLoading}
            setModalData={setModalData}
            selectedLoan={selectedLoan}
          />
        </Box>
      </Box>
      {modalData?.modal === 'editEntry' && (
        <AddLedgerEntry
          open
          handleClose={onCloseModal}
          prevData={modalData.entry as AccountingType}
          isEditingEntry
          onSuccesAddEntry={updateAccountingCache}
          accountingFilter="loan"
          disableFields={{ description: true }}
          hideFields={{
            code: true,
            letter: true,
            kind: true,
            number: true,
            amount: true,
            annuality: true,
            entryDate: true,
            months: true,
            months_advanced: true
          }}
        />
      )}
      {modalData?.modal === 'deleteInterest' && (
        <DeleteConfirmationModal
          isOpen
          title="Delete Interest"
          confirmationMessage="Are you sure you want to delete the interest?"
          onClose={onCloseModal}
          onConfirm={onDeleteLoanInterest}
          isRemoveLoading={isDeleteInterestLoading}
        />
      )}
      {modalData?.modal === 'deleteLoan' && (
        <DeleteConfirmationModal
          isOpen
          title="Delete Loan"
          confirmationMessage="Are you sure you want to delete the loan?"
          onClose={onCloseModal}
          onConfirm={onDeleteLoan}
          isRemoveLoading={isDeleteLoanLoading}
        />
      )}
      {modalData?.modal === 'createInterest' && (
        <CreateInterestModal
          onClose={onCloseModal}
          onSuccess={updateLoansCache}
        />
      )}
      {(modalData?.modal === 'editPartyEntry' || modalData?.modal === 'createAdditional') && (
        <AddAndUpdatePartyLedgerEntry
          handleClose={onCloseModal}
          handleSuccess={updateAccountingCache}
          kindAllowedAssociationList={kindAllowedAssociationList}
          partyKindFilter={['Buyer']}
          entryLedger={loanForm.getValues('loanAmountEntry') as AccountingType}
          partyLedgerEntry={modalData.entry}
          accountingFilter={`/.*${selectedLoan?._id}.*/`}
          hideFields={{
            at_closing_amount: true,
            at_closing_percent: true,
            before_closing_amount: true,
            before_closing_percent: true
          }}
        />
      )}
    </Box>
  );
};
