import {
  Dialog, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import DatePicker from 'components/date-picker/date-picker';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { Control, Controller, UseFormGetValues } from 'react-hook-form';

import { RequestDocumentItem } from './components/request-document-item';
import { useStyles } from './styles';
import { RequestPartyDocuments } from './types';

type RequestDocumentsModalSceneProps = {
  t: TFunction,
  isOpen: boolean,
  control: Control<RequestPartyDocuments, object>,
  getValues: UseFormGetValues<RequestPartyDocuments>,
  handleClose: () => void,
  isRequestDocumentsLoading: boolean,
  onSendRequest: () => void,
  isFormValid: boolean
}

export const RequestDocumentsModalScene: FC<RequestDocumentsModalSceneProps> = ({
  t,
  isOpen,
  control,
  getValues,
  handleClose,
  isRequestDocumentsLoading,
  onSendRequest,
  isFormValid
}) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} fullWidth onClose={handleClose}>
      <DialogContent style={{ padding: 0 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5" className={classes.title}>{t('documents:request-documents')}</Typography>
          </Grid>
          <Grid item container direction="column" spacing={3} className={classes.container}>
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body1">{t('documents:define-dates')}</Typography>
              </Grid>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs>
                  <Controller
                    control={control}
                    name="notBefore"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        disableToolbar={false}
                        label="Start"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs>
                  <Controller
                    control={control}
                    name="expiresIn"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        disableToolbar={false}
                        label="End"
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body1">{t('documents:select-files-party')}</Typography>
              </Grid>
              <Grid item>
                {getValues('requests').map((request, requestIndex) => (
                  <RequestDocumentItem
                    key={request.partyId}
                    partyName={request.partyName}
                    files={request.files}
                    control={control}
                    requestIndex={requestIndex}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <ContainedButton
          text="Close"
          onClick={handleClose}
        />
        <ContainedButton
          text="Send requests"
          isLoading={isRequestDocumentsLoading}
          onClick={onSendRequest}
          disabled={!isFormValid}
        />
      </DialogActions>
    </Dialog>
  );
};
