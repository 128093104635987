import { Paper, TextField } from '@material-ui/core';
import React, { FC } from 'react';

import { LoaderNotes } from '../LoaderNotes';
import { useStyles } from './styles';

type NoteEditorProps = {
  isLoadingNotes: boolean,
  inputRef: React.RefObject<HTMLInputElement>,
  saveNote: (text: string) => void
}

export const NoteEditor: FC<NoteEditorProps> = ({
  isLoadingNotes,
  inputRef,
  saveNote
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paperContainer}>
      {isLoadingNotes && <LoaderNotes />}
      <TextField
        autoFocus
        multiline
        inputRef={inputRef}
        onChange={(e) => {
          if (e.target !== null) {
            const text = e.target.value;
            saveNote(text);
          }
        }}
        className={classes.textField}
        InputProps={{
          disableUnderline: true,
          className: classes.inputTextArea,
          inputProps: { style: { paddingBottom: '50px', whiteSpace: 'pre-wrap' } }
        }}
      />
    </Paper>
  );
};
