import {
  Box,
  Drawer,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React, { memo } from 'react';
import { WorkspaceRoutes } from 'types/common';
import { DRAWER_ORDERS_WIDTH } from 'utils/constants';

import CloseSidemnu from './close';
import OpenSidemnu from './open';

type SidemenuProps = {
  t: TFunction;
  sidemenuOpen: boolean;
  setSidemenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleViewChange: (view: WorkspaceRoutes, param?: string) => void;
};

const useStyles = makeStyles((theme: any) => ({
  drawer: { width: DRAWER_ORDERS_WIDTH, },
  drawerOpen: {
    width: DRAWER_ORDERS_WIDTH,
    scrollbarColor: `${theme.palette.tab.offselected} ${theme.palette.primary.main}`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      // theme.palette.primary.main
      background: theme.palette.primary.main,
      width: theme.spacing(0.625),
      borderRadius: theme.spacing(0.625)
    }, /* Chrome */
    '&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.625) },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.tab.offselected,
      borderRadius: theme.spacing(0.625),
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: { width: theme.spacing(9) + 1 },
  },
  drawerPaperOpen: {
    width: DRAWER_ORDERS_WIDTH,
    backgroundColor: theme.palette.primary.main,
  },
  drawerPaperClose: {
    width: theme.spacing(7) + 1,
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: theme.mixins.toolbar,
}));

const Sidemenu = ({
  t,
  sidemenuOpen,
  setSidemenuOpen,
  handleViewChange
}: SidemenuProps) => {
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: sidemenuOpen,
        [classes.drawerClose]: !sidemenuOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerPaperOpen]: sidemenuOpen,
          [classes.drawerPaperClose]: !sidemenuOpen,
          [classes.drawerOpen]: sidemenuOpen,
          [classes.drawerClose]: !sidemenuOpen,
        })
      }}
    >
      <Box className={classes.toolbar} />

      {sidemenuOpen ? (
        <OpenSidemnu
          t={t}
          setSidemenuOpen={setSidemenuOpen}
          handleViewChange={handleViewChange}
        />
      ) : (
        <CloseSidemnu
          setSidemenuOpen={setSidemenuOpen}
          handleViewChange={handleViewChange}
        />
      )}
    </Drawer>
  );
};

export default memo(Sidemenu);
