/* eslint-disable import/prefer-default-export */
import { TFunction } from 'i18next';
import {
  JOINT_TENANTS,
  JOINT_TENANTS_WITH_RIGHT_OR_SURVIRVORSHIP,
  SOLE_OWNER,
  TENANTS_BY_THE_ENTIRETY,
  TENANTS_IN_COMMON
} from 'utils/constants';
import { capitalize } from 'utils/helpers';

export const options = (t:TFunction) => [
  { value: SOLE_OWNER, text: capitalize(t('orders:sole-owner')) },
  { value: JOINT_TENANTS, text: capitalize(t('orders:joint-tenants')) },
  { value: JOINT_TENANTS_WITH_RIGHT_OR_SURVIRVORSHIP, text: capitalize(t('orders:joint-tenants-rights')) },
  { value: TENANTS_IN_COMMON, text: capitalize(t('orders:tenants-common')) },
  { value: TENANTS_BY_THE_ENTIRETY, text: capitalize(t('orders:tenants-entirety')) },
];
