import React from 'react';
import { DisabledFields } from 'types/disabledFields';
import {
  AccountingFilter,
  AccountingInvolvedParty,
  AccountingType,
} from 'v2-types/order';

import { useAddAndUpdatePartyLedgerEntry } from './add-and-update-party-ledger-entry-hooks';
import { AddAndUpdatePartyLedgerScene } from './add-and-update-party-ledger-entry-scene';
import { AddAndUpdatePartyLedgerForm } from './types';

export type AddAndUpdatePartyLedgerEntryProps = {
  handleClose: () => void;
  handleSuccess: (accounting: AccountingType[]) => void;
  entryLedger: AccountingType;
  partyLedgerEntry?: AccountingInvolvedParty;
  kindAllowedAssociationList?: string | string[];
  filterParties?: string;
  accountingFilter?: AccountingFilter;
  partyKindFilter?: string[],
  hideFields?: DisabledFields<AddAndUpdatePartyLedgerForm>
};

export const AddAndUpdatePartyLedgerEntry = ({
  handleClose,
  handleSuccess,
  entryLedger,
  partyLedgerEntry,
  kindAllowedAssociationList,
  filterParties,
  accountingFilter,
  partyKindFilter,
  hideFields
}: AddAndUpdatePartyLedgerEntryProps) => {
  const {
    methods,
    allowedAssociations,
    handleAssociationCreateLedger,
    setCurrentPartySelected,
    currentPartySelected,
    createErrors,
    t,
    loadingAddAssociation,
    loadingEditAssociation,
    parties,
  } = useAddAndUpdatePartyLedgerEntry({
    handleClose,
    handleSuccess,
    accountingEntryId: entryLedger?._id!,
    prevAccountingPartyEntry: partyLedgerEntry,
    kindAllowedAssociationList,
    accountingFilter,
    partyKindFilter
  });

  return (
    <AddAndUpdatePartyLedgerScene
      onClose={handleClose}
      entryLedger={entryLedger}
      allowedAssociations={allowedAssociations}
      handleSubmit={handleAssociationCreateLedger}
      handleSelectParty={setCurrentPartySelected}
      methods={methods}
      currentPartySelected={currentPartySelected}
      errors={createErrors}
      partyEntryPrevInfo={partyLedgerEntry}
      t={t}
      orderParties={parties}
      loadingAddAssociation={loadingAddAssociation}
      loadingEditAssociation={loadingEditAssociation}
      filterParties={filterParties}
      hideFields={hideFields}
    />
  );
};
