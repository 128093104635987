import {
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DatePicker from 'components/date-picker/date-picker';
import NumberField from 'components/number-field';
import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatCurrencyValueToNumber } from 'utils/helpers';

import { calculateAdditionalInterest } from '../../../services';

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(2)
  },
  title: { color: theme.palette.primary.main },
  subtitle: {
	  color: theme.palette.tab.offselected,
	  marginBottom: theme.spacing(1),
  },
  labelContainer: {
	  display: 'flex',
	  alignItems: 'center',
	  justifyContent: 'flex-end',
	  paddingRight: theme.spacing(1),
  },
  label: { color: theme.palette.tab.offselected },
  itemContainer: { display: 'flex' },
  itemButtonGroupLeft: {
	  display: 'flex',
	  flex: 1,
  },
  itemButtonGroupRight: {
	  display: 'flex',
	  flex: 1,
	  justifyContent: 'flex-end',
  },
  itemButtonGroupRightLabel: {
	  display: 'flex',
	  alignItems: 'center',
	  paddingRight: theme.spacing(1),
  },
  buttonGroupContainer: { display: 'flex' },
  buttonSelected: {
	  textTransform: 'lowercase',
	  backgroundColor: theme.palette.primary.main,
	  color: theme.palette.common.white,
	  '&:hover': { backgroundColor: theme.palette.primary.main },
  },
  buttonNoSelected: {
	  textTransform: 'lowercase',
	  backgroundColor: 'transparent',
	  color: theme.palette.primary.main,
  },
  itemActionButtons: {
	  display: 'flex',
	  justifyContent: 'flex-end',
	  marginTop: theme.spacing(4),
  },
  saveButton: {
	  marginLeft: theme.spacing(1),
	  color: theme.palette.primary.main,
	  textTransform: 'none'
  },
}));

type interestModalForm = {
	interest_calculated: string,
	days_per_year:number,
	from_date:string,
	to_date:string,
	interest_rate:number,
	per_diem:number,
}
type AdditionalInterestModalProps = {
	handleCancel: () => void;
	handleSave:(data:interestModalForm)=> void,
	additionalAmount:number
  };

const AdditionalInterest = ({
  handleCancel,
  handleSave,
  additionalAmount
}: AdditionalInterestModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    control,
	 setValue,
	 getValues,
	 formState
  } = useForm<interestModalForm>({
    mode: 'onChange',
    defaultValues: {
      interest_calculated: 'to',
      days_per_year: 360,
      from_date: '',
      to_date: '',
      interest_rate: 0,
      per_diem: 0
    }
  });

  const fields = useWatch({ control, name: ['from_date', 'interest_rate', 'days_per_year'] });

  return (

    <form>
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.subtitle}>{`Principal Amount: \u0020${additionalAmount}`}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.itemContainer}>
          <div className={classes.itemButtonGroupLeft}>
            <Typography className={classes.label}>
              {t('payoffs:interest-calculated')}
            </Typography>

            <ButtonGroup
              disableElevation
              variant="outlined"
              color="primary"
            >
              <Button
                className={getValues('interest_calculated') === 'to' ? classes.buttonSelected : classes.buttonNoSelected}
                onClick={() => setValue('interest_calculated', 'to', { shouldValidate: true })}
              >
                {t('payoffs:to')}
              </Button>
              <Button
                className={getValues('interest_calculated') === 'through' ? classes.buttonSelected : classes.buttonNoSelected}
                onClick={() => setValue('interest_calculated', 'through', { shouldValidate: true })}
              >
                {t('payoffs:through')}
              </Button>
            </ButtonGroup>
          </div>

          <div className={classes.itemButtonGroupRight}>
            <div className={classes.itemButtonGroupRightLabel}>
              <Typography className={classes.label}>
                {t('payoffs:principal')}
              </Typography>
            </div>

            <ButtonGroup
              disableElevation
              variant="outlined"
              color="primary"
            >
              <Button
                className={getValues('days_per_year') === 360 ? classes.buttonSelected : classes.buttonNoSelected}
                onClick={() => {
                  setValue('days_per_year', 360, { shouldValidate: true });
                  const { perDiem } = calculateAdditionalInterest(additionalAmount, fields[1], 360);
				  setValue('per_diem', perDiem);
                }}
              >
                360
              </Button>
              <Button
                className={getValues('days_per_year') === 365 ? classes.buttonSelected : classes.buttonNoSelected}
                onClick={() => {
                  setValue('days_per_year', 365, { shouldValidate: true });
				  const { perDiem } = calculateAdditionalInterest(additionalAmount, fields[1], 365);
				  setValue('per_diem', perDiem);
                }}
              >
                365
              </Button>
              <Button
                className={getValues('days_per_year') === 366 ? classes.buttonSelected : classes.buttonNoSelected}
                onClick={() => {
                  setValue('days_per_year', 366, { shouldValidate: true });
				  const { perDiem } = calculateAdditionalInterest(additionalAmount, fields[1], 366);
				  setValue('per_diem', perDiem);
                }}
              >
                366
              </Button>
            </ButtonGroup>
          </div>
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name="from_date"
            render={({ field }) => (
              <DatePicker
                label={t('payoffs:from')}
                value={field.value}
                error={!!formState.errors.from_date}
                helperText={!!formState.errors.from_date}
                handleBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name="to_date"
            render={({ field }) => (
              <DatePicker
                disabled={fields[0] === ''}
                label={t('payoffs:to')}
                minDate={new Date(fields[0] as string)}
                error={!!formState.errors.to_date}
                helperText={!!formState.errors.to_date}
                handleBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />

        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name="interest_rate"
            render={({ field }) => (
              <NumberField
                label={t('payoffs:interest-rate')}
                suffix="%"
                decimalScale={0}
                isAllowed={({ floatValue = 0 }) => floatValue <= 100}
                value={field.value}
                error={!!formState.errors.interest_rate}
                helperText={!!formState.errors.interest_rate as any as string}
                handleBlur={field.onBlur}
                onChange={(event) => {
				  const interestRate = formatCurrencyValueToNumber(event.target.value, 0, null, '%') as number;
				  setValue('interest_rate', interestRate, { shouldValidate: true });
				  const { perDiem } = calculateAdditionalInterest(additionalAmount, interestRate, fields[2]);
				  setValue('per_diem', perDiem, { shouldValidate: true });
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name="per_diem"
            render={({ field }) => (
              <NumberField
                thousandSeparator
                decimalScale={2}
                label={t('payoffs:per-diem')}
                prefix="$"
                value={field.value}
                error={!!formState.errors.per_diem}
                helperText={!!formState.errors.per_diem as any as string}
                handleBlur={field.onBlur}
                onChange={(event) => {
				  const directPerDiem = formatCurrencyValueToNumber(event.target.value, 0) as number;
				  setValue('interest_rate', 0);
				  setValue('per_diem', directPerDiem);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} className={classes.itemActionButtons}>
          <Button
            disableElevation
            variant="outlined"
            color="primary"
            onClick={() => {
              handleCancel();
            }}
            className={classes.saveButton}
          >
            {t('common:close')}
          </Button>

          <Button
            disableElevation
            variant="contained"
            color="secondary"
            onClick={() => {
              const values = getValues();
              handleSave(values);
            }}
            className={classes.saveButton}
          >
            {t('common:apply')}
          </Button>
        </Grid>

      </Grid>
    </form>
  );
};

export default AdditionalInterest;
