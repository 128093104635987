import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getProfile } from 'redux/profile-reducer/selectors';

import BackdropLoading from '../../common/BackdropLoading';

type PageCheckerUnOnboardedProps = {
  render: () => React.ReactElement;
};

const PageCheckerUnOnboarded = ({ render }: PageCheckerUnOnboardedProps) => {
  const { onboarded, isLoading } = useSelector(getProfile);

  if (isLoading) {
    return <BackdropLoading open loadingText="loading" />;
  }

  if (!onboarded) {
    return <Redirect to="/onboarding/terms-and-conditions" />;
  }

  return render();
};

export default PageCheckerUnOnboarded;
