import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { WireTransfersType } from 'types/wire-transfers';
import { AccountingType } from 'v2-types/order';

export type GQLGetWireTransfers = {
  getTransfers: WireTransfersType[];
};

export type GQLGetOrderAccounting = {
  getOrder: {
    accounting: AccountingType[] | null;
  };
};

export type OrderMember = {
  kind: string;
  name: string;
  login_email: string;
}

export type GQLGetOrderMember = {
  getParty: {
    members: OrderMember[];
  };
};

export const getWireTrannsfersResponse = async (payload: {
  orderId: string;
}) => {
  const query = gql`
    query GetTransfers($orderId: String!) {
      getTransfers(order_id: $orderId) {
        authorization
        confirmation
        filename
        instructions {
          account_id
          account_kind
          amount
          description
          party_id
          party_name
        }
        records
        reference
        security
        signatures
        status
        submission
      }
    }
  `;

  const response = await graphQLClient().request<GQLGetWireTransfers>(
    query,
    payload
  );
  return response;
};

export const getAccountingData = async (payload: { id: string }) => {
  const query = gql`
    query GetOrder($id: ID!) {
      getOrder(_id: $id) {
        accounting {
          _id
          involved_parties {
            _id
            amount
            kind
            name
            payment_kind
            payment_reference
          }
          description
          kind
          code
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLGetOrderAccounting>(
    query,
    payload
  );
  return response;
};

export const getMembesrTitle = async (payload: { id: string }) => {
  const query = gql`
    query GetParty($id: ID!) {
      getParty(_id: $id) {
        members {
          kind
          name
          login_email
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLGetOrderMember>(
    query,
    payload
  );
  return response;
};
