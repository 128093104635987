/* eslint-disable react/no-array-index-key */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import React from 'react';
import { Column } from 'types/common';

import useHeader from './hooks/useHeader';
import useStyles from './style';

type ColumnSort = { [key:string]: 'asc' | 'desc' }

type HeadType = {
  columns: Column[],
  onSort?:(column:Column) => void,
  columnSort?: ColumnSort,
  disabledBorderCell?: boolean,
  disableFullborderCell?:boolean
};

const HeadTable = ({
  columns,
  onSort,
  columnSort,
  disabledBorderCell,
  disableFullborderCell
}: HeadType) => {
  const {
    titleColumn,
    cursor,
    cellHeader,
    iconSort,
    borderBottomCell,
    borderCellStyle
  } = useStyles();
  const onClickSort = (column: Column) => onSort && onSort(column);
  const { header, subHeader, hasHeader } = useHeader(columns);
  return (
    <TableHead>
      {hasHeader && (
      <TableRow>
        {header?.map((head, index) => (
          <TableCell
            colSpan={head?.colspan}
            style={{ minWidth: head?.width, padding: head?.padding }}
            className={`
            ${!disabledBorderCell && borderBottomCell}
            ${!disableFullborderCell && borderCellStyle}
            `}
            key={`header-${index}`}
          >
            <Box
              className={`${cursor} ${cellHeader}`}
              style={{ justifyContent: head?.align || 'center', width: 'auto' }}
              onClick={() => head?.isSort && onClickSort(head)}
            >
              <Typography
                variant="body1"
                className={titleColumn}
                variantMapping={{ body1: 'p' }}
                style={{ color: head?.textColor }}
              >
                {head?.title}
              </Typography>
            </Box>
          </TableCell>
        ))}
      </TableRow>
      )}
      <TableRow>
        {subHeader.map((column, index) => (
          !column.disabled
          && (
          <TableCell
            key={`sub-head-${index}`}
            className={`
            ${!disabledBorderCell && borderBottomCell}
            ${!disableFullborderCell && borderCellStyle}
            `}
            style={{ minWidth: column?.width, width: column?.minWidth }}
          >
            <Box
              className={`${cursor} ${cellHeader}`}
              style={{ justifyContent: column?.align || 'center', width: 'auto' }}
              onClick={() => column?.isSort && onClickSort(column)}
            >
              <Typography
                variant="body1"
                className={titleColumn}
                variantMapping={{ body1: 'p' }}
                style={{ color: column?.textColor }}
              >
                {column?.title}
              </Typography>
              { column?.isSort && columnSort && columnSort[column?.key] && (
              <FontAwesomeIcon
                className={iconSort}
                icon="angle-down"
              />
              )}
            </Box>
          </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
};

HeadTable.defaultProps = {
  onSort: undefined,
  disabledBorderCell: false,
  disableFullborderCell: false,
  columnSort: {}
};

export default HeadTable;
