/* eslint-disable import/prefer-default-export */
import { TFunction } from 'i18next';
import { IntlCurrencyFormat } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

export const mapEndorsements = (
  accounting: AccountingType[],
  role: 'owners' | 'lenders'
) => accounting.filter(
  ({ kind, code }) => kind === 'premium'
      && code.includes(role)
      && code.split('_')[1].match(/^[0-9]+$/)
);

export const sumEndorsements = (endorsements: AccountingType[]) => endorsements.reduce(
  (prev, { amount, amount_override }) => (amount_override > 0 ? prev + amount_override : prev + amount),
  0
);

export const validateAmountOverride = (accountingEntry: AccountingType) => (accountingEntry.amount_override > 0
  ? accountingEntry.amount_override
  : accountingEntry.amount);

export const getAmount = (accountingEntry: AccountingType | undefined) => (accountingEntry ? validateAmountOverride(accountingEntry) : 0);

export const LENDERS_ACTUAL = 'lenders_actual';
export const LENDERS_DISCLOSED = 'lenders_disclosed';
export const LENDERS_POLICY = 'lenders_policy';
export const LENDERS_TOTAL = 'lenders_total';
export const LENDERS_FEES = 'lenders_fees';
export const SUM_LENDERS_ENDORSEMENTS = 'lenders_endorsements';

export const OWNERS_ACTUAL = 'owners_actual';
export const OWNERS_DISCLOSED = 'owners_disclosed';
export const OWNERS_POLICY = 'owners_policy';
export const OWNERS_FEES = 'owners_fees';
export const SUM_OWNERS_ENDORSEMENTS = 'owners_endorsements';
export const RefinanceCredits = 'refinance_credits';
export const LendersPolicy = 'lenders_policy';
export const TX_GUARANTY_FEE = 'TX Guaranty Fee';

export const policyData = (
  t: TFunction,
  policy: number,
  sumEndorsement: number,
  fees: number,
  mode: string
) => [
  {
    text: t('policyInfoAndRates:policy'),
    value: IntlCurrencyFormat(policy),
    plus: false,
    result: false,
    minus: false,
    spaceLeft: true,
  },
  {
    text: t('policyInfoAndRates:endorsements'),
    value: IntlCurrencyFormat(sumEndorsement),
    plus: true,
    result: false,
    minus: false,
    spaceLeft: true,
  },
  {
    text: t('policyInfoAndRates:fees-taxes'),
    value: IntlCurrencyFormat(fees),
    plus: true,
    result: false,
    minus: false,
    spaceLeft: true,
  },
  {
    text:
      mode === 'disclosed'
        ? t('policyInfoAndRates:disclosed-total')
        : t('policyInfoAndRates:actual-total'),
    value: IntlCurrencyFormat(policy + sumEndorsement + fees),
    plus: false,
    result: true,
    minus: false,
    spaceLeft: true,
  },
];

export const policyDataRefinance = ({
  t,
  policy,
  credits,
  total
}: {
  t: TFunction,
  policy: number,
  credits: number,
  total: number
}) => [
  {
    text: t('policyInfoAndRates:policy'),
    value: IntlCurrencyFormat(policy),
    plus: false,
    result: false,
    minus: false,
    spaceLeft: true,
  },
  {
    text: t('policyInfoAndRates:credits'),
    value: IntlCurrencyFormat(credits),
    plus: false,
    minus: true,
    result: false,
    spaceLeft: true,
  },
  {
    text: t('policyInfoAndRates:total'),
    value: IntlCurrencyFormat(total),
    plus: false,
    result: true,
    minus: false,
    spaceLeft: true,
  },
];
