import {
  FormControlLabel,
  Radio as MUIRadio,
} from '@material-ui/core';
import React from 'react';

type RadioProps = {
  name?: string;
  label: string | React.ReactNode;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  size?: 'small' | 'medium';
  value: string | number | boolean;
  handleBlur?: any;
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  formControlClassName?: string;
  disabled?: boolean
};

const Radio = ({
  name,
  label,
  labelPlacement,
  size,
  value,
  handleBlur,
  handleOnChange,
  formControlClassName,
  disabled = false
}: RadioProps) => (
  <FormControlLabel
    name={name}
    label={label}
    labelPlacement={labelPlacement}
    value={value}
    className={formControlClassName}
    disabled={disabled}
    control={(
      <MUIRadio
        size={size}
        onBlur={handleBlur}
        onChange={handleOnChange}
      />
    )}
  />
);

Radio.defaultProps = {
  name: undefined,
  labelPlacement: 'end',
  size: 'medium',
  handleBlur: undefined,
  handleOnChange: () => {},
  formControlClassName: undefined,
};

export default Radio;
