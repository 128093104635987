import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { AccountingType } from 'v2-types/order';

import HeaderPayor from './header-payor';
import FormBreakdown from './payment-breakdown';

const useStyles = makeStyles((theme: any) => ({
  papercontainer: {
    background: theme.palette.background.paper,
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    width: '100%',
  },
}));

type PayorInfoProps = {
  chargeData: AccountingType | undefined;
  handleSuccess: (accountingData: AccountingType[]) => void;
};

const PayorInfo: FC<PayorInfoProps> = ({ handleSuccess, chargeData }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.papercontainer}>
      <HeaderPayor
        amount={chargeData?.amount || 0}
        description={chargeData?.description || ''}
        accountingID={chargeData?._id || ''}
        handleSetAccounting={handleSuccess}
      />
      <Grid container direction="column">
        <FormBreakdown
          accountingInfo={chargeData}
          handleSuccess={handleSuccess}
        />
      </Grid>
    </Paper>
  );
};

export default PayorInfo;
