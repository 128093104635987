import { Box, makeStyles, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  text: {
    fontFamily: 'Roboto',
    color: theme.palette.primary.darker,
    fontWeight: 400,
    background: theme.palette.background.default,
    opacity: 1
  },
  textShadow: { textShadow: `0.25px 0.5px ${theme.palette.text.light}` }
}));

type CellTextType = {
  data?: string,
  position?: 'center' | 'left' | 'right',
  colorText?: string | undefined,
  shadow?: boolean,
  backgroundColor?: string | undefined,
  variant?: 'inherit' | Variant | undefined
};

const CellText = ({
  data,
  position,
  colorText,
  shadow,
  backgroundColor,
  variant
}: CellTextType) => {
  const { text, textShadow } = useStyles();
  return (
    <Box style={{ width: '100%' }}>
      <Typography
        variant={variant}
        style={{
          textAlign: position || 'center',
          color: colorText,
          background: backgroundColor,
          padding: '5px'
        }}
        className={`${text} ${shadow && textShadow}`}
      >
        {data}
      </Typography>
    </Box>
  );
};

CellText.defaultProps = {
  shadow: false,
  data: '',
  position: 'center',
  colorText: undefined,
  backgroundColor: undefined,
  variant: undefined
};

export default CellText;
