import {
  Backdrop, CircularProgress, Grid, makeStyles, Paper
} from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Filters } from './components/filters';
import HeaderCard from './components/header-card';
import { ModalAuthTransfer } from './components/modal-auth-transfer/index';
import { ModalCreateWire } from './components/modal-create-wire';
import Nothing from './components/nothing';
import { TableWireTransfers } from './components/table-wire-transfers';
import useWireTransfers from './wire-transfers-hooks';

const useStyles = makeStyles((theme: any) => ({
  paperContainer: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(1),
    margin: `${theme.spacing(2.75)}px ${theme.spacing(2.5)}px`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  contentContainer: { padding: theme.spacing(4), gap: theme.spacing(2) },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

type Props = {
  idTitleCompany: string
}

export const WireTransfers: FC<Props> = ({ idTitleCompany }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    wireTransferPages,
    currentPage,
    totalPages,
    rowsPerPage,
    totalRows,
    setCurrentPage,
    changeRowsPerPage,
    email,
    accountingDataFilter,
    open,
    handleToggleModals,
    members,
    handleCreateWire,
    handleAuthTransfer,
    openBackdrop,
    onFilterChange,
    onSort,
    onUpdateAmountLimits,
    clearFilters
  } = useWireTransfers({ idTitleCompany });

  return (
    <>
      <Paper className={classes.paperContainer}>
        <HeaderCard t={t} handleToggleModals={handleToggleModals} />
        <Grid container className={classes.contentContainer} direction="column">
          <Grid item>
            <Filters
              t={t}
              onFilterChange={onFilterChange}
              onSort={onSort}
              onUpdateAmountLimits={onUpdateAmountLimits}
              clearFilters={clearFilters}
            />
          </Grid>
          {wireTransferPages.length > 0 && (
            <Grid item>
              <TableWireTransfers
                t={t}
                wireTransferPages={wireTransferPages}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                totalPages={totalPages}
                totalRows={totalRows}
                setCurrentPage={setCurrentPage}
                changeRowsPerPage={changeRowsPerPage}
                email={email}
                handleToggleModals={handleToggleModals}
              />
            </Grid>
          )}
        </Grid>
        <Backdrop open={openBackdrop} className={classes.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Paper>
      {wireTransferPages.length === 0 && <Nothing t={t} />}
      {open.location === 'createWire' && (
        <ModalCreateWire
          t={t}
          accountingDataFilter={accountingDataFilter}
          open={open.status}
          members={members}
          handleToggleModals={handleToggleModals}
          handleCreateWire={handleCreateWire}
          email={email}
        />
      )}
      {open.location === 'authTransfer' && open.data && (
        <ModalAuthTransfer
          transfer={open.data}
          currentUserEmail={email}
          open={open.status}
          t={t}
          handleToggleModals={handleToggleModals}
          handleAuthTransfer={handleAuthTransfer}
        />
      )}
    </>
  );
};
