/* eslint-disable react/no-array-index-key */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { truncateString } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import { deleteEndorsement } from '../graphql/mutations';
import { mapEndorsements } from '../services';
import usePolicy from '../services/use-policy-context';
import TableEndorsementsHeader from './endorsements-header';

const useStyles = makeStyles((theme: any) => ({
  tableContainer: { marginTop: theme.spacing(2) },
  table: { backgroundColor: theme.palette.background.default },
  cellHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.palette.grey[700],
  },
  cell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottomColor: theme.palette.grey[700],
  },
  text: {
    color: theme.palette.common.black,
    fontSize: '0.875rem',
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '0.875rem',
    '&:hover': { color: theme.palette.common.black },
  },
}));

type TableEndorsementsProps = {
  selectedPolicy: 'owners_policy' | 'lenders_policy'
  handleSelectEndorsement: (accountindInfo: AccountingType) => void
};

const TableEndorsements: FC<TableEndorsementsProps> = ({ selectedPolicy, handleSelectEndorsement }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const context = usePolicy();
  const { accounting, setAccounting, setLatestUpdate } = context || {};
  const { id } = useParams<any>();
  const showAlert = useAlert();
  const currentEndorsements = useMemo(() => {
    if (accounting) {
      return mapEndorsements(accounting, selectedPolicy === 'owners_policy' ? 'owners' : 'lenders');
    }
    return [];
  }, [accounting, selectedPolicy]);

  const [removeEndorsemet, removeEndorsementeResponse] = useMutation(deleteEndorsement, {
    onSuccess: (data) => {
      removeEndorsementeResponse.reset();
      showAlert('Endorsement deleted', 'success');
      if (setAccounting && setLatestUpdate) {
        setAccounting(data.deleteOrderPolicyEndorsement?.accounting || []);
        const now = format(new Date(), 'hh:mm');
        setLatestUpdate({ time: now, type: 'success', message: `updated at  ${now}` });
      }
    },
  });
  const handleAddEndorsement = useCallback((code:string) => {
    removeEndorsemet({
      id,
      kind: selectedPolicy === 'lenders_policy' ? 'lenders' : 'owners',
      code
    });
  }, [removeEndorsemet, selectedPolicy, id]);

  useEffect(() => {
    if (removeEndorsementeResponse.isLoading) {
      showAlert('Deleting', 'warning');
    }
  }, [removeEndorsementeResponse.isLoading, showAlert]);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <TableEndorsementsHeader
          selectedPolicy={selectedPolicy}
        />
      </Grid>
      <Grid item xs={12} className={classes.tableContainer}>
        <TableContainer>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellHeader} style={{ width: 100 }}>
                  <Typography className={classes.text}>
                    {t('policyInfoAndRates:endorsement')}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cellHeader}>
                  <Typography className={classes.text}>
                    {t('policyInfoAndRates:description')}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cellHeader} style={{ width: 90 }}>
                  <Typography className={classes.text}>
                    {t('policyInfoAndRates:price')}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cellHeader} style={{ width: 60 }} />
              </TableRow>
            </TableHead>

            <TableBody>
              {currentEndorsements.map((endorsement, index) => (
                <TableRow key={`${endorsement._id} - ${index}`}>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.text}>
                      {endorsement.code.split('_')[1]}
                    </Typography>
                  </TableCell>

                  <TableCell className={classes.cell}>
                    <Typography className={classes.text}>
                      {truncateString(endorsement.description, 60)}
                    </Typography>
                  </TableCell>

                  <TableCell className={classes.cell}>
                    <Typography className={classes.text}>
                      {`$${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(endorsement.amount_override > 0 ? endorsement.amount_override : endorsement.amount)}`}
                    </Typography>
                  </TableCell>

                  <TableCell align="center" className={classes.cell}>
                    <Box display="flex" flexDirection="row">
                      <IconButton
                        className={classes.icon}
                        onClick={() => {
                          handleSelectEndorsement(endorsement);
                        }}
                      >
                        <FontAwesomeIcon icon="edit" />
                      </IconButton>

                      <IconButton
                        className={classes.icon}
                        onClick={() => {
                          handleAddEndorsement(endorsement.code.split('_')[1]);
                        }}
                      >
                        <FontAwesomeIcon icon="times" />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TableEndorsements;
