import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import React, { FC } from 'react';

import { ResponseSource } from '../../types';
import { Row } from './components/Row';
import { useStyles } from './styles';

type SourceTableProps = {
  sources: ResponseSource[]
}

export const SourceTable: FC<SourceTableProps> = ({ sources }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className={classes.tableCell}>File Path</TableCell>
            <TableCell className={classes.tableCell}>File Name</TableCell>
            <TableCell className={classes.tableCell}>File Type</TableCell>
            <TableCell className={classes.tableCell}>File Size</TableCell>
            <TableCell className={classes.tableCell}>Creation Date</TableCell>
            <TableCell className={classes.tableCell}>Last Modified Date</TableCell>
            <TableCell className={classes.tableCell}>Last Accessed Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sources.map((source) => (
            <Row key={source.filePath} source={source} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
