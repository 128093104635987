import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  messageItem: {
    backgroundColor: 'rgba(239, 239, 240, 0.5)',
    padding: '15px',
    borderRadius: '5px',
    gap: '10px'
  },
}));
