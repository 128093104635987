import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  navigationButton: {
    minWidth: 0,
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: theme.palette.tab.offselected,
    '&:hover': { background: theme.palette.tab.offselected }
  },
  textStyle: {
    fontSize: theme.spacing(1.7),
    color: theme.palette.primary.light,
    fontWeight: 400
  },
  tableRow: {
    background: theme.palette.background.default,
    '& > *': {
      borderColor: theme.palette.input.border,
      borderWidth: 1,
      borderStyle: 'solid'
    }
  },
  containerItems: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: 4,
  },
  itemInCell: {
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.input.border,
    padding: theme.spacing(1),
    borderRadius: 2,
    '&:hover': { background: theme.palette.input.border }
  },
  iconPlus: { color: theme.palette.background.paper },
  row2: {
    width: 200,
    padding: theme.spacing(0, 1)

  },
  row4: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  underline: {
    fontSize: theme.spacing(2),
    color: theme.palette.primary.light,
    fontWeight: 500,
    paddingLeft: theme.spacing(1),
    '&&&:before': { borderBottom: 'none' },
    '&&:after': { borderBottom: 'none' },
    '&:hover': { background: theme.palette.input.border },
    '& .MuiFilledInput-input': { paddingTop: theme.spacing(2)!, }
  }
}));
