import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DOCUMENT_KIND_LIST } from 'utils/constants';

import { template_document } from '../types';

export type FileDataType = {
  kind: string;
  description: string;
  template: string;
  per: string[];
};

type CreateFileModalType = {
  open: boolean;
  handleClose: (id: string) => void;
  handleCreateFile: (payload: any) => void;
  getTemplates: template_document[] | undefined;
};

const CreateFileModal: FC<CreateFileModalType> = ({
  open,
  handleClose,
  handleCreateFile,
  getTemplates,
}) => {
  const handleCheckboxChange = (value: string[], per: string, onChange) => {
    if (value.includes(per)) {
      onChange(value.filter((val) => val !== per));
    } else {
      onChange([...value, per]);
    }
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
    reset: resetForm,
  } = useForm<FileDataType>({ mode: 'onChange' });

  return (
    <Dialog open={open} onClose={() => handleClose('')}>
      <DialogTitle>Create File</DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Controller
              defaultValue=""
              control={control}
              name="description"
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label="Description"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="kind"
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <SelectField
                  label="kind"
                  inputRef={ref}
                  {...field}
                  InputProps={{ ...field }}
                  data={DOCUMENT_KIND_LIST}
                  dataKey=""
                  dataValue=""
                  dataText=""
                />
              )}
            />
          </Grid>

          {getTemplates && watch('kind') === 'template' && (
            <>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="template"
                  rules={{ required: watch('kind') }}
                  render={({ field: { ref, ...field } }) => (
                    <SelectField
                      label="kind"
                      inputRef={ref}
                      {...field}
                      InputProps={{ ...field }}
                      data={getTemplates}
                      dataKey="_id"
                      dataValue="_id"
                      dataText="description"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Create file per</FormLabel>
                  <Controller
                    control={control}
                    name="per"
                    defaultValue={[]}
                    render={({ field: { ref, ...field } }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              inputRef={ref}
                              value="property"
                              onChange={(e) => handleCheckboxChange(
                                field.value,
                                e.target.value,
                                field.onChange
                              )}
                            />
                          )}
                          label="Property"
                        />
                        <FormControlLabel
                          disabled={field.value.includes('signer')}
                          control={(
                            <Checkbox
                              inputRef={ref}
                              value="party"
                              onChange={(e) => handleCheckboxChange(
                                field.value,
                                e.target.value,
                                field.onChange
                              )}
                            />
                          )}
                          label="Party"
                        />
                        <FormControlLabel
                          disabled={field.value.includes('party')}
                          control={(
                            <Checkbox
                              inputRef={ref}
                              value="signer"
                              onChange={(e) => handleCheckboxChange(
                                field.value,
                                e.target.value,
                                field.onChange
                              )}
                            />
                          )}
                          label="Signer"
                        />
                      </FormGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <ContainedButton
                onClick={handleSubmit((data) => {
                  handleCreateFile(data);
                  resetForm();
                  handleClose('');
                })}
                disabled={!isValid}
                text="Create Document"
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFileModal;
