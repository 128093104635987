import React from 'react';
import { TFunction } from 'react-i18next';
import { Column } from 'types/common';
import { ColumnsType } from 'views/orders/accounting/services';
import { CellText } from 'views/orders/charges-accounting/components/table-cells';

import Cellstatus from '../components/cell-status';
import TableSigners from '../components/table-signers';
import { SignatureType, SignersType } from './types';

type tableSignersrowType= {
	id: string
	order_id: string
	key_id: string
	archive_id: string
	source:string
	has_error:boolean
	is_complete:boolean
	is_declined: boolean
	provider_id:string
	signers: SignersType[]
	description: string
}

type RendererParams = {
	row: tableSignersrowType;
	column: Column;
	index: number;
	indexRow?:number;
	shouldCollapse?:boolean
}

export const signersColumns = (
  t: TFunction,
): ColumnsType => ({
  kind: {
    header: {
		  title: t('documents:archive'),
		  align: 'center',
		  width: '75px',
		  minWidth: '75px',
    },
    renderer: ({ row }: RendererParams) => (
      <CellText
        position="center"
        backgroundColor="#FAFAFA"
        colorText="#4F5B62"
        data={row.description}
      />
    ),
	  },
  parties: {
    header: {
      title: t('documents:completed'),
	  width: '50px',
      minWidth: '50px'
    },
    renderer: ({ row }:RendererParams) => (
      <Cellstatus status={row.is_complete} />
    )
  },
  code: {
    header: {
      title: t('common:error'),
      align: 'left',
	  width: '50px',
      minWidth: '50px'
    },
    renderer: ({ row }: RendererParams) => (
      <Cellstatus status={row.has_error} />
    ),
  },
  letter: {
    header: {
      title: t('documents:declined'),
      width: '50px',
      minWidth: '50px'
    },
    renderer: ({ row }: RendererParams) => (
      <Cellstatus status={row.is_declined} />
    ),
  },
  signers: {
    header: {
      title: t('documents:signers'),
      width: '50px',
      minWidth: '50px'
    },
	  renderer: ({ row }: RendererParams) => (
  <TableSigners signers={row.signers} />
	  )
  },

});

export const parseSignersColumns = (signersInfo:SignatureType[]):tableSignersrowType[] => signersInfo.map((sign) => ({
  id: sign._id,
  ...sign
}));
