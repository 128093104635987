import moment from 'moment';
import React, { FC } from 'react';
import { NewOrderType } from 'types/order';

import { tableRowComponent } from '../../services';
import useScheduleMeeting from './ron-schedule-meeting-hooks';
import RonScheduleMeetingScene from './ron-schedule-meeting-scene';
import { scheduleMettingTokens } from './services';

type RonScheduleMeetingtype = {
  open: boolean;
  onClose: ()=> void;
  onCancel: ()=>void;
  tokensInfo: scheduleMettingTokens;
  setTokensRon: React.Dispatch<React.SetStateAction<tableRowComponent[]>>;
  graphOrder: NewOrderType | undefined
}

const RonScheduleMeeting: FC<RonScheduleMeetingtype> = ({
  open,
  onCancel,
  onClose,
  tokensInfo,
  setTokensRon,
  graphOrder
}) => {
  const {
    t,
    columns,
    methods,
    setPartySelected,
    attendies,
    orderId,
    updpateTokens,
    loading,
    involvedParties
  } = useScheduleMeeting(
    tokensInfo,
    onClose,
    setTokensRon,
    graphOrder
  );

  const handleAddParty = (idParty:string) => {
    setPartySelected(idParty);
  };

  const handleUpdateTokens = (data:scheduleMettingTokens) => {
    const date = moment(data.date);
    const dateinit = moment(data.init_time).toISOString(true);
    const datend = moment(data.end_time).toISOString(true);
    updpateTokens({
      id: orderId!,
      parties: data.parties.map((party) => ({
        party_id: party._id,
        email_id: party.username,
      })),
      notBefore: `${date.format('YYYY-MM-DD')}T${dateinit.split('T')[1]}`,
      expiresIn: `${date.format('YYYY-MM-DD')}T${datend.split('T')[1]}`,
    });
  };

  return (
    <RonScheduleMeetingScene
      t={t}
      columns={columns}
      methods={methods}
      handleAddParty={handleAddParty}
      open={open}
      onClose={onClose}
      onCancel={onCancel}
      attendies={attendies}
      handleUpdateTokens={handleUpdateTokens}
      loading={loading}
      involvedParties={involvedParties || []}
    />
  );
};

export default RonScheduleMeeting;
