import { OrderType } from 'types/order';

import {
  ADD_CURRENT_ORDER_BUYER,
  ADD_CURRENT_ORDER_PROPERTY,
  ADD_CURRENT_ORDER_SELLER,
  EDIT_CURRENT_ORDER_BUYER,
  EDIT_CURRENT_ORDER_PROPERTY,
  EDIT_CURRENT_ORDER_SELLER,
  REMOVE_CURRENT_ORDER_BUYER,
  REMOVE_CURRENT_ORDER_PROPERTY,
  REMOVE_CURRENT_ORDER_SELLER,
  SET_CURRENT_ORDER,
  UPDATE_CURRENT_ORDER_CURRENT_VALUES,
} from './action-types';

export type OrderReduxStateType = {
  currentOrder: OrderType;
};

const initialState: OrderReduxStateType = {
  currentOrder: {
    buyers: [],
    created: '',
    dates_and_configuration: undefined,
    document_requests: [],
    documents: [],
    earnest_and_commissions: undefined,
    existing_order_type: undefined,
    id: '',
    loan: undefined,
    order_id: '',
    parties: [],
    payoffs: [],
    involved_parties: [],
    disclosures: undefined,
    policy_info_and_rates: undefined,
    properties: [],
    required_documents: [],
    roles_by_user: undefined,
    sellers: [],
    status: '',
    taxes_and_prorations: undefined
  },
};

const orderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          ...action.payload,
        },
      };

    case ADD_CURRENT_ORDER_SELLER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          sellers: [...(state?.currentOrder?.sellers || []), action.payload],
        },
      };

    case EDIT_CURRENT_ORDER_SELLER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          parties: [...(state?.currentOrder?.buyers || []), ...action.payload],
          sellers: [...action.payload],
        },
      };

    case REMOVE_CURRENT_ORDER_SELLER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          sellers: state?.currentOrder?.sellers?.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case ADD_CURRENT_ORDER_BUYER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          buyers: [...(state?.currentOrder?.buyers || []), action.payload],
        },
      };

    case EDIT_CURRENT_ORDER_BUYER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          parties: [...(state?.currentOrder?.sellers || []), ...action.payload],
          buyers: [...action.payload],
        },
      };

    case REMOVE_CURRENT_ORDER_BUYER:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          buyers: state?.currentOrder?.buyers?.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case ADD_CURRENT_ORDER_PROPERTY:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          properties: [
            ...(state?.currentOrder?.properties || []),
            action.payload,
          ],
        },
      };

    case EDIT_CURRENT_ORDER_PROPERTY:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          properties: [...action.payload],
        },
      };

    case REMOVE_CURRENT_ORDER_PROPERTY:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          properties: state?.currentOrder?.properties?.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case UPDATE_CURRENT_ORDER_CURRENT_VALUES:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          [action.payload.key]: action.payload.value,
        },
      };

    default:
      return state;
  }
};

export default orderReducer;
