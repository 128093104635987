import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { useStyles } from '../../styles';

type Props = {
  text: string
}

export const Description: FC<Props> = ({ text }) => {
  const classes = useStyles();
  return (<Typography className={classes.tableTitle} variant="subtitle2">{text}</Typography>);
};
