import { DocumentsType } from "v2-types/order";

import { AssociationType } from "../../../types";

export const getFilteredParty = ({
  parties,
  documents,
}: {
  parties: AssociationType[];
  documents: DocumentsType[];
}) => {
  const documentsUpload = documents.filter(
    (document) => document.kind === "upload"
  );
  return parties.filter((party) =>
    documentsUpload.some((file) =>
      file.data.some(
        (fileData) => fileData._id === party._id && fileData.kind === "party"
      )
    )
  );
};

export const getDocumentsByPartyId = ({
  partyId,
  documents,
}: {
  partyId: string;
  documents: DocumentsType[];
}) =>
  documents.filter(
    (document) =>
      document.data.some(
        (documentData) =>
          documentData._id === partyId && documentData.kind === "party"
      ) && document.kind === "upload"
  );
