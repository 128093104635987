import { GridDirection, GridJustification, GridSpacing } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Text } from 'components/text';
import React, { FC } from 'react';

import { TitleWithValue } from '../title-with-value';

type TitleWithValueProps = {
  title: string,
  value: string | number,
  titleVariant?: Variant,
  valueVariant?: Variant,
  direction?: GridDirection,
  justifyContent?: GridJustification,
  spacing?: GridSpacing,
  titleUppercase?: boolean,
  valueIsMoney?: boolean,
  valueLeftAdorment?: string | React.ReactNode,
  isLoading?: boolean,
  loadingRowsNumber?: number
}

export const TitleWithTextValue: FC<TitleWithValueProps> = ({
  title,
  value,
  titleVariant = 'caption',
  valueVariant = 'body2',
  direction = 'column',
  justifyContent = 'flex-start',
  spacing = 0,
  titleUppercase = true,
  valueIsMoney = false,
  valueLeftAdorment = null,
  isLoading = false,
  loadingRowsNumber = 1
}) => (
  (
    <TitleWithValue
      title={title}
      titleVariant={titleVariant}
      direction={direction}
      justifyContent={justifyContent}
      spacing={spacing}
      titleUppercase={titleUppercase}
      isLoading={isLoading}
      loadingRowsNumber={loadingRowsNumber}
    >
      <Text
        value={value}
        variant={valueVariant}
        isMoney={valueIsMoney}
        leftAdorment={valueLeftAdorment}
        fontWeight="bold"
      />
    </TitleWithValue>
  )
);
