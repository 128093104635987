import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  rootContainer: { padding: theme.spacing(3) },
  tableTitle: { textAlign: 'right', },
  didThisChangeColumnInputs: {
    display: 'flex',
    '&>:first-child': { flex: 1 },
    '&>:nth-child(2)': { flex: 5 },
  },
  desc_cell: { marginLeft: theme.spacing(3) },
  underline: {
    fontSize: theme.spacing(2),
    color: theme.palette.primary.light,
    fontWeight: 500,
    paddingLeft: theme.spacing(1),
    '&&&:before': { borderBottom: 'none' },
    '&&:after': { borderBottom: 'none' },
    '&:hover': { background: theme.palette.input.border },
    '& .MuiFilledInput-input': { paddingTop: theme.spacing(2)!, }
  },
  inputClassName: {
    color: theme.palette.primary.darker,
    fontWeight: 500,
    paddingLeft: theme.spacing(1),
    '&&&:before': { borderBottom: 'none' },
    '&&:after': { borderBottom: 'none' },
    '&:hover': { background: theme.palette.input.border },
    width: '100%',
    background: theme.palette.background.default,
  },
  disabledClass: {
    color: theme.palette.primary.darker,
    cursor: 'not-allowed'
  },
}));
