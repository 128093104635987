import { GeneralReduxStateType } from 'types/redux';

const initialState: GeneralReduxStateType = { usStates: [] };

const generalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_US_STATES':
      return { ...state, usStates: action.payload };

    default:
      return state;
  }
};

export default generalReducer;
