import { getCashToClose } from 'graphql/cash-to-close/queries';
import { QueryConfig, useQuery } from 'react-query';

import { CashToCloseData } from './types';
import { getCashToCloseData } from './utls';

type Params = {
  orderId: string,
  queryConfig?: QueryConfig<{
    cashToCloseData: CashToCloseData,
    configuration: {
      transaction_type: string
    }
  }, Error>
}

export const useGetCashToClose = ({ orderId, queryConfig }: Params) => useQuery(['accounting-balance-cash-to-close', orderId], {
  ...queryConfig,
  queryFn: async () => {
    const response = await getCashToClose({ orderId });

    return {
      cashToCloseData: getCashToCloseData({
        accounting: response.getOrder.accounting,
        cashToCloseEntries: response.getOrder.cash_to_close
      }),
      configuration: { transaction_type: response.getOrder.configuration.transaction_type }
    };
  }
});
