import { Grid } from '@material-ui/core';
import SelectField from 'components/select-field';
import { ListingEntry } from 'graphql/listings/queries';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatesAndConfigurationForm } from '../services';
import Title from './title';

type FormTypeProps = {
  transactionTypes: ListingEntry[];
};

const FormType = ({ transactionTypes, }: FormTypeProps) => {
  const { control, setValue } = useFormContext<DatesAndConfigurationForm>();
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title>{t('datesAndConfig:type')}</Title>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="transaction_type"
          control={control}
          render={({ field }) => (
            <SelectField
              value={field.value}
              label={t('datesAndConfig:transaction-types')}
              data={transactionTypes}
              dataKey="code"
              dataValue="code"
              dataText="description"
              handleBlur={field.onBlur}
              handleChange={(e) => {
                field.onChange(e);
                setValue('workflow', '');
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FormType;
