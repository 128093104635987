import { configureStore } from '@reduxjs/toolkit';

import { reducer as rootReducer } from './root-reducer';

const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  });

  return { store };
};

export default createStore;
