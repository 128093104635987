import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
    margin: 0,
    maxHeight: 'calc(100vh - 64px - 64px - 85px)',
    height: 'calc(100vh - 64px - 64px - 85px)',
    gap: '5px',
    scrollbarWidth: 'thin',
  },
  room: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    maxHeight: '100%',
    gap: '10px'
  },
  messageListContainer: {
    width: '100%',
    overflow: 'scroll',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    boxSizing: 'border-box',
    height: '100%',
    gap: '12px',
  },
  messageItem: {
    backgroundColor: 'rgba(239, 239, 240, 0.5)',
    padding: '15px',
    borderRadius: '5px',
    gap: '10px'
  },
  messageContainer: {
    maxHeight: '50%',
    flex: 1,
  },
  message: {
    border: '1px solid lightgray',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  questionText: {
    overflow: 'scroll',
    overflowX: 'hidden',
    maxHeight: '100px',
  }
}));
