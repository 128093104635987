import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: {},
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  row: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  rowTop: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },
  rowBottom: {
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  divider: {
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.grey[500],
    borderLeftStyle: 'solid',
  },
  textLeft: {
    textAlign: 'end',
    paddingRight: theme.spacing(1),
  },
  lastRow: { backgroundColor: theme.palette.input.border },
  text: {
    color: theme.palette.primary.darker,
    fontWeight: 500,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  ratesText: { paddingLeft: theme.spacing(1) },
}));

type dataPolicy = {
  text: string;
  value: string | number;
  plus: boolean;
  minus: boolean;
  result: boolean;
  spaceLeft: boolean;
};

type BalanceInfoProps = {
  title: string;
  data: dataPolicy[];
};

const BalanceInfo: FC<BalanceInfoProps> = ({ title, data }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          className={clsx(classes.text, classes.titleContainer)}
        >
          {title}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {data.map((row, index) => (
          <Grid
            key={`${title}-${row.text}`}
            container
            alignItems="center"
            className={clsx(classes.row, [
              {
                [classes.rowTop]: index === 0,
                [classes.rowBottom]: row.result,
                [classes.lastRow]: row.result,
              },
            ])}
          >
            <Grid item xs={6}>
              <Grid container alignItems="center">
                <Grid item xs={2} className={classes.iconContainer}>
                  {(index === 1 || index === 2) && <div />}
                </Grid>

                <Grid item xs={10} className={classes.textLeft}>
                  <Typography variant="body2" className={classes.text}>
                    {row.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} className={classes.divider}>
              <Grid container alignItems="center">
                {row.spaceLeft && (
                  <Grid item xs={4} className={classes.iconContainer}>
                    {row.plus && (
                      <FontAwesomeIcon icon="plus" className={classes.text} />
                    )}
                    {row.minus && (
                      <FontAwesomeIcon icon="minus" className={classes.text} />
                    )}
                  </Grid>
                )}
                <Grid item xs={row.spaceLeft ? 8 : 12}>
                  <Typography
                    variant="body2"
                    className={clsx(classes.text, { [classes.ratesText]: row.spaceLeft === false, })}
                  >
                    {row.value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default BalanceInfo;
