import {
  Box,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import TextField from 'components/text-field';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(2) },
  Twolines: { '& > *': { marginRight: theme.spacing(2), marginTop: theme.spacing(2) } },
  underlineDefault: {
	  '&.MuiFilledInput-underline:before': { borderColor: theme.palette.input.border },
	  '&.MuiInput-underline:before': { borderColor: theme.palette.input.border },
	  '&.MuiInput-underline:hover:not(.Mui-disabled):before': { borderColor: theme.palette.input.border },
	  padding: 0,
  },
  underlineFocus: {
	  '&.MuiFilledInput-underline.Mui-focused:after': { borderColor: theme.accent.main },
	  '&.MuiInput-underline.Mui-focused:after': { borderColor: theme.accent.main },
  },
  endAdornmentStyle: {
	  width: theme.spacing(9),
	  height: theme.spacing(7),
	  borderRadius: theme.spacing(0.5),
	  backgroundColor: '#0000001A',
	  display: 'flex',
	  justifyContent: 'center',
	  alignItems: 'center',
	  marginLeft: theme.spacing(1.2),
  },
  paperBottom: {
    width: '100%',
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
  },
}));

  type PrepaidType = {
	description:string,
  	months:number
  };

const PrepaidSettings = ({
  description,
  months
}: PrepaidType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Paper className={classes.paperBottom}>
      <Box className={classes.container}>
        <Box flexDirection="row" flex="1" display="flex" className={classes.Twolines}>
          <TextField
            fullWidth={false}
            disabled
            label={t('common:description')}
            variant="filled"
            value={description}
          />

          <TextField
            variant="filled"
            value={months}
            disabled
            style={{ width: '25%' }}
            InputProps={{
		    endAdornment: (
  <Box className={classes.endAdornmentStyle}>
    <Typography variant="body2">{t('loan:Mo')}</Typography>
  </Box>
		    ),
		    classes: {
			  focused: classes.underlineFocus,
			  root: classes.underlineDefault
		    },
		  }}
          />
        </Box>

      </Box>
    </Paper>
  );
};

export default PrepaidSettings;
