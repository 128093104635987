import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { NewMemberOfType } from 'v2-types/order';
import { NewEmailType } from 'v2-types/user';

export type GQLCreatePartyAssociation = {
  createPartyAssociation: {
    _id: string;
    members: NewMemberOfType[];
  };
};

export type GQLCreatePartyAssociationPayload = {
  id: string,
  partyId: string,
  kind: string,
}

export const createPartyAssociation = async (payload: GQLCreatePartyAssociationPayload) => {
  const mutation = gql`
    mutation CreatePartyAssociation($id: ID!, $partyId: ID!, $kind: String!) {
      createPartyAssociation(_id: $id, party_id: $partyId, kind: $kind) {
        _id
        members {
          kind
          _id
          name
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreatePartyAssociation>(
    mutation,
    payload
  );

  return response;
};

export type GQLCreatePartyEmail = {
  createEmail: {
    _id: string,
    username: string,
    emails: NewEmailType[],
  };
};

export const createPartyEmail = async (payload: {
  partyId: string,
  kind: string,
  data: NewEmailType
}) => {
  const mutation = gql`
    mutation CreateEmail($partyId: ID!, $kind: String!, $data: EmailContent!) {
      createEmail(party_id: $partyId, kind: $kind, data: $data) {
        _id
        username
        emails {
          _id
          address
          kind
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLCreatePartyEmail>(
    mutation,
    payload
  );

  return response;
};

export type GQLUpdatePartyEmail = {
  updateEmail: {
    _id: string,
    username: string,
    emails: NewEmailType[]
  };
};

export const updatePartyEmail = async (payload: {
  id: string,
  partyId: string,
  data: NewEmailType,
  kind: string
}) => {
  const mutation = gql`
    mutation UpdateEmail(
      $id: ID!
      $partyId: ID!
      $data: EmailContent!
      $kind: String
    ) {
      updateEmail(_id: $id, party_id: $partyId, data: $data, kind: $kind) {
        _id
        username
        emails {
          _id
          address
          kind
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLUpdatePartyEmail>(
    mutation,
    payload
  );

  return response;
};

export type GQLDeletePartyEmail = {
  deleteEmail: {
    _id: string,
    username: string,
    emails: NewEmailType[]
  };
};

export const deletePartyEmail = async (payload: { id: string; partyId: string }) => {
  const mutation = gql`
    mutation DeleteEmail($id: ID!, $partyId: ID!) {
      deleteEmail(_id: $id, party_id: $partyId) {
        _id
        username
        emails {
          _id
          address
          kind
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLDeletePartyEmail>(
    mutation,
    payload
  );

  return response;
};
