import { useTheme } from '@material-ui/core';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';
import useSettingTable from 'views/orders/accounting/components/table-ledger/table-ledger-hooks';
import { formatAccountingData, orderChargesByCodeAsc } from 'views/orders/charges-accounting/services';

import { GenerateClosingTotals, generateColumns } from './services';
import { ModalData } from './types';

const useTableCharges = (
  accountingData: AccountingType[]
) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [modalData, setModalData] = useState<ModalData>();

  const { itemsCollapsed, setItemCollapse } = useSettingTable();
  const columns = generateColumns(
    t,
    theme,
    undefined,
    () => {
      setModalData({ show: 'edit-ledger' });
	 },
	 (row) => { setItemCollapse(row.id); }
  );

  const mapedData = useMemo(() => {
    const closingCharge = GenerateClosingTotals(accountingData);
    const filledData = orderChargesByCodeAsc(formatAccountingData(accountingData.filter((d) => d.letter === 'J')) || []);

    return [
      closingCharge,
      ...filledData
    ];
  }, [accountingData]);

  return {
    mapedData,
    columns,
    modalData,
    setModalData,
    itemsCollapsed,
  };
};

export default useTableCharges;
