import { deleteOrderLedgerAssociation, DeleteOrderLedgerAssociationReturn } from 'graphql/ledger/mutations';
import { QueryConfig, useMutation } from 'react-query';

type Params = {
  queryConfig?: QueryConfig<DeleteOrderLedgerAssociationReturn, {
    response: {
      errors: {
        message: string
      }[]
    }
  }>
}

export const useDeleteOrderLedgerAssociation = ({ queryConfig }: Params) => useMutation(deleteOrderLedgerAssociation, { ...queryConfig, });
