import { useTranslation } from 'react-i18next';
import { PARTY_KIND_BUYER, PARTY_KIND_SELLER } from 'utils/constants';
import { NewOrderPartyType } from 'v2-types/order';

import { getContacts } from './services';
import { PartyWithPartyInvolved } from './types';

type UsePartiesInvolvedParams = {
  sellers: NewOrderPartyType[],
  buyers: NewOrderPartyType[],
  attorneys: NewOrderPartyType[],
}

export const usePartiesInvolved = ({
  sellers,
  buyers,
  attorneys,
}: UsePartiesInvolvedParams) => {
  const { t } = useTranslation();

  const sellerList: PartyWithPartyInvolved[] = sellers.map((seller) => {
    const sellerAttorneys = getContacts(attorneys, PARTY_KIND_SELLER, seller._id);
    return {
      name: seller.name,
      attorneys: sellerAttorneys.map((attorney) => attorney.name)
    };
  });

  const buyerList: PartyWithPartyInvolved[] = buyers.map((buyer) => {
    const buyersAttorneys = getContacts(attorneys, PARTY_KIND_BUYER, buyer._id);
    return {
      name: buyer.name,
      attorneys: buyersAttorneys.map((attorney) => attorney.name)
    };
  });

  return {
    t,
    sellerList,
    buyerList
  };
};
