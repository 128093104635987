import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import useGetChargesJ from './services/useGetChargesJ';

const useChargesJ = () => {
  const { t } = useTranslation();
  const [currentRowSelected, setCurrentRowSelected] = useState<columnsDataSet | null>(null);
  const { accounting, handleSetAccounting, isLoading } = useGetChargesJ('J');

  return {
    t,
    accounting,
    loadingAccounting: isLoading,
    currentRowSelected,
    setCurrentRowSelected,
    handleSetAccounting
  };
};
export default useChargesJ;
