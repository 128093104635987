import { ConditionsForm } from '../types';

const getNumberValue = (text): number | undefined => {
  const matches = text.match(/\d+/g);
  if (matches === null) return undefined;

  return Number(matches[0]);
};

export const getValues = (text: string | null | undefined): ConditionsForm => {
  const values: ConditionsForm = {
    firstConditionExist: false,
    firstFieldValues: {
      amount: undefined,
      years: undefined
    }
  };
  if (!text) return values;

  const conditions = text.split('. ');
  const firstCondition = conditions.find((condition) => condition.includes('As high as $'));
  if (firstCondition) {
    const [firstPart, secondPart] = firstCondition.split('if you pay off the loan within the first');
    const amount = getNumberValue(firstPart);
    const years = getNumberValue(secondPart);

    values.firstConditionExist = true;
    values.firstFieldValues = {
      amount,
      years
    };
  }

  return values;
};

export const formatConditionsForm = (data: ConditionsForm) => {
  const {
    firstConditionExist,
    firstFieldValues
  } = data;

  let conditions: string = '';
  if (firstConditionExist) {
    const { amount, years } = firstFieldValues;
    conditions += `As high as $${amount} if you pay off the loan within the first ${years} years. `;
  }

  return conditions;
};
