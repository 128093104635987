import { faMapMarkerAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  capitalize,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { ISO31661AssignedEntry, ISO31662Entry } from 'iso-3166';
import React, { FC } from 'react';
import { Control, useFieldArray, UseFormGetValues } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { Location } from 'types/geospatial';
import { OrderProperty } from 'types/order';

import { PropertyAutocomplete } from '../PropertyAutocomplete';
import { PropertySelectField } from '../PropertySelectField';
import { PropertyTextField } from '../PropertyTextField';
import { optionsJurisdiction, propertyTypeOptions } from './services';

const useStyles = makeStyles((theme: any) => ({
  container: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  primarycolorfonts: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  },
  addressIcon: { color: theme.palette.text.shadow, fontSize: '0.875rem' },
  paddingZero: { padding: 0 },
}));

type TabPropertySceneProps ={
  theme: Theme,
  t: TFunction,
  countries: ISO31661AssignedEntry[]
  states: ISO31662Entry[],
  control: Control<OrderProperty, object>,
  getValues: UseFormGetValues<OrderProperty>,
  selectCountry: (country: string) => void,
  selectState: (state: string) => void,
  selectCounty: (county: string) => void,
  selectZcode: (zcode: string) => void,
  counties: Location[],
  isCountyListLoading: boolean,
  zcodes: Location[],
  isZcodesListLoading: boolean,
  cities:Location[]
}

export const TabPropertyScene: FC<TabPropertySceneProps> = ({
  theme,
  t,
  countries,
  states,
  selectCountry,
  control,
  getValues,
  selectState,
  counties,
  selectCounty,
  isCountyListLoading,
  zcodes,
  isZcodesListLoading,
  selectZcode,
  cities
}) => {
  const classes = useStyles();
  const {
    fields: parcelIdFields,
    append: appendParcelIdField,
    remove: removeParcelIdField
  } = useFieldArray<OrderProperty>({
    control,
    name: 'parcel_ids'
  });
  const doesPropertyExist = getValues('_id').length > 0;
  const isUSselected = getValues('address.country') === 'US';

  return (
    <Box>
      <Grid container spacing={2} direction="column" className={classes.container}>
        <Grid item>
          <PropertyTextField
            control={control}
            name="address.street_address"
            label={t('common:street')}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className={classes.addressIcon} />
                </InputAdornment>)
            }}
            isDisabled={doesPropertyExist}
            required
          />
        </Grid>
        <Grid item container direction="row" wrap="nowrap" spacing={2}>
          <Grid item xs>
            <PropertySelectField
              control={control}
              name="address.country"
              label={t('common:country')}
              data={countries}
              dataKey="alpha2"
              dataValue="alpha2"
              dataText="name"
              isDisabled={doesPropertyExist}
              required
              onSelectItem={selectCountry}
            />
          </Grid>
          <Grid item xs>
            <PropertySelectField
              control={control}
              name="address.state"
              label={t('common:state')}
              data={states}
              dataKey="code"
              dataValue="code"
              dataText="name"
              isDisabled={doesPropertyExist}
              required
              onSelectItem={selectState}
            />

          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs>
            {isUSselected ? (
              <PropertyAutocomplete
                control={control}
                name="address.settlement"
                label={t('common:county')}
                data={counties}
                dataValue="name"
                dataText="name"
                isDisabled={doesPropertyExist}
                onSelectItem={selectCounty}
                isLoading={isCountyListLoading}
              />
            ) : (
              <PropertyTextField
                control={control}
                name="address.settlement"
                label={t('common:county')}
                isDisabled={doesPropertyExist}
              />
            )}
          </Grid>
          <Grid item xs>
            <PropertyAutocomplete
              control={control}
              data={zcodes}
              dataValue="name"
              dataText="name"
              label={t('common:zip-code')}
              name="address.postal_code"
              required
              inputType="number"
              isDisabled={doesPropertyExist}
              isLoading={isZcodesListLoading}
              onTextChange={selectZcode}
            />
          </Grid>
          <Grid item xs>
            <PropertyAutocomplete
              control={control}
              data={cities}
              dataValue="name"
              dataText="name"
              label={t('common:city')}
              name="address.locality"
              required
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs>
            <PropertySelectField
              control={control}
              name="type"
              label={capitalize(t('orders:property-type'))}
              data={propertyTypeOptions(t)}
              dataKey="value"
              dataValue="value"
              dataText="text"
              required
            />
          </Grid>
          <Grid item xs>
            <PropertySelectField
              control={control}
              name="jurisdiction"
              label={capitalize(t('orders:municipal-jurisdiction'))}
              data={optionsJurisdiction(t)}
              dataKey="value"
              dataValue="value"
              dataText="text"
              required
            />
          </Grid>
        </Grid>
        <Grid item>
          <PropertyTextField
            control={control}
            name="address.references"
            label={t('common:references')}
            isDisabled={doesPropertyExist}
          />
        </Grid>
        {/* Parcel Ids */}
        <Grid item container direction="column" spacing={2}>
          <Grid item container justifyContent="space-between">
            <Typography variant="h6" className={classes.primarycolorfonts}>{`${capitalize(t('orders:parcel'))} ID`}</Typography>
            <Button
              style={{
                textTransform: 'none',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              startIcon={<FontAwesomeIcon icon="plus" style={{ fontSize: '0.875rem', color: theme.palette.secondary.main }} />}
              onClick={() => appendParcelIdField({ value: '' })}
            >
              <Typography
                variant="body2"
                style={{ fontWeight: 500, color: theme.palette.secondary.main }}
              >
                {capitalize(t('orders:add-parcel'))}
              </Typography>
            </Button>
          </Grid>
          <Grid item container spacing={2}>
            {parcelIdFields.map((field, index) => (
              <Grid item key={field.id} xs={4}>
                <PropertyTextField
                  control={control}
                  name={`parcel_ids.${index}.value`}
                  label={`${capitalize(t('orders:parcel'))} ID #${index + 1}`}
                  inputProps={{
                    endAdornment: (
                      index !== 0
                        ? (
                          <IconButton
                            className={classes.paddingZero}
                            onClick={() => {
                              removeParcelIdField(index);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} size="xs" />
                          </IconButton>
                        ) : null
                    )
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
