import { ConfigurationHelperType, GeneralInformationValuesType } from 'components/users/types';
import React from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { NewUserType } from 'v2-types/user';

import {
  createAddress as fnCreateAddress,
  createEmail as fnCreateEmail,
  createLicense as fnCreateLicense,
  createPhone as fnCreatePhone,
  updateAddress as fnUpdateAddress,
  updateBasicInformation,
  updateEmail as fnUpdateEmail,
  updateLicense as fnUpdateLicense,
  updatePartyNotifications as fnUpdatePartyNotifications,
  updatePhone as fnUpdatePhone,
} from '../contacts/contact-edit/services';
import useProfile from './profile-hooks';
import ProfileScene from './profile-scene';

const Profile = () => {
  const {
    t,
    methods,
    errors,
    loading,
    currentTab,
    setCurrentTab,
    updateParty,
    updateAddress,
    updateEmail,
    updatePhone,
    updateLicense,
    updatePartyNotifications,
    createAddress,
    createEmail,
    createPhone,
    createLicense,
    deleteAddress,
    deleteEmail,
    deletePhone,
    deleteLicense,
  } = useProfile();

  const helpers = (getValues: UseFormGetValues<NewUserType>):
  GeneralInformationValuesType & { configuration: ConfigurationHelperType } => ({
    first_name: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    middle_name: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    last_name: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    ssn: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    birth: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    gender: {
      onBlur: (event) => {
        updateParty(updateBasicInformation(getValues()));
        event();
      },
    },
    addresses: {
      add: async (_data, onClose) => {
        await createAddress(fnCreateAddress(getValues('_id'), _data));
        onClose!();
      },
      remove: (id) => deleteAddress({ id, partyId: getValues('_id') }),
      addresses: (getValues('addresses') || []).map((address) => ({
        street_address: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        state: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        postal_code: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        references: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        locality: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        settlement: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
        kind: {
          onBlur: (event) => {
            updateAddress(fnUpdateAddress(getValues('_id'), address));
            event();
          },
        },
      })),
    },
    emails: {
      add: async (_data, onClose) => {
        await createEmail(fnCreateEmail(getValues('_id'), _data));
        onClose!();
      },
      remove: (id) => deleteEmail({ id, partyId: getValues('_id') }),
      emails: (getValues('emails') || []).map((email) => ({
        address: {
          disabled: (() => {
            if (email.kind === 'Login' && getValues('user_id')) {
              return true;
            }

            return false;
          })(),
          onBlur: (event) => {
            updateEmail(fnUpdateEmail(getValues('_id'), email));
            event();
          },
        },
        kind: {
          disabled: (() => {
            if (email.kind === 'Login' && getValues('user_id')) {
              return true;
            }

            return false;
          })(),
          onBlur: (event) => {
            updateEmail(fnUpdateEmail(getValues('_id'), email));
            event();
          },
        },
      })),
    },
    phones: {
      add: async (_data, onClose) => {
        await createPhone(fnCreatePhone(getValues('_id'), _data));
        onClose!();
      },
      remove: (id) => deletePhone({ id, partyId: getValues('_id') }),
      phones: (getValues('phones') || []).map((phone) => ({
        number: {
          onBlur: (event) => {
            updatePhone(fnUpdatePhone(getValues('_id'), phone));
            event();
          },
        },
        kind: {
          onBlur: (event) => {
            updatePhone(fnUpdatePhone(getValues('_id'), phone));
            event();
          },
        },
      })),
    },
    licenses: {
      add: async (_data, onClose) => {
        await createLicense(fnCreateLicense(getValues('_id'), _data));
        onClose!();
      },
      remove: (id) => deleteLicense({ id, partyId: getValues('_id') }),
      licenses: (getValues('licenses') || []).map((license) => ({
        number: {
          onBlur: (event) => {
            updateLicense(fnUpdateLicense(getValues('_id'), license));
            event();
          },
        },
        state: {
          onBlur: (event) => {
            updateLicense(fnUpdateLicense(getValues('_id'), license));
            event();
          },
        },
      })),
    },
    parties: {
      remove: () => {},
      parties: (getValues('parties') || []).map(() => ({
        name: { disabled: true },
        kind: { disabled: true },
      })),
    },
    payments: {
      add: () => { },
      remove: () => { },
      payments: (getValues('payments') || []),
    },
    configuration: {
      notifications: {
        all: {
          onChange: (event) => {
            event();
            updatePartyNotifications(fnUpdatePartyNotifications(getValues('_id'), getValues('notifications')));
          },
        },
      },
    },
  });

  const handleOnChange = (_: React.ChangeEvent<{}>, newValue: number) => setCurrentTab(newValue);

  return (
    <ProfileScene
      t={t}
      methods={methods}
      helpers={helpers}
      errors={errors}
      loading={loading}
      currentTab={currentTab}
      handleOnChange={handleOnChange}
    />
  );
};

export default Profile;
