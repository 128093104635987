import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import Actionbuttons from 'components/common/actions-buttons';
import React, { FC } from 'react';

import { CellIndicator, CellText } from '../../components/table-cells';
import { formattedCharge } from '../services';
import CellHeader from './header-cell';
import HudRow from './hud-row';

const useStyles = makeStyles((theme: any) => ({
  borderCellStyle: {
    borderColor: theme.palette.input.border,
    borderWidth: 1,
    borderStyle: 'solid',
    opacity: 1,
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
  },
  padding: { padding: theme.spacing(3) },
  table: { background: theme.palette.background.paper },
  containerTable: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
  },
  scrollDiv: {
    overflowX: 'auto',
    width: '100%',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.5) },
    '&&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark, },
  },
}));

type totalStateBrokerTable = {
  totalBrokerFees: formattedCharge[];
  handleEdit: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleDelete: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleSelectItem: (
    param: Pick<formattedCharge, 'id' | 'chargeData' | 'description' | 'number'>
  ) => void;
  handleClick: (event: React.MouseEvent, number: number) => void;
};

const TotalStateBrokerTable: FC<totalStateBrokerTable> = ({
  totalBrokerFees,
  handleDelete,
  handleEdit,
  handleSelectItem,
  handleClick,
}) => {
  const classes = useStyles();
  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow>
          <CellHeader style={{ width: '6rem' }}>
            <Actionbuttons handleEdit={() => {}} handleDelete={() => {}} />
          </CellHeader>
          <CellHeader
            style={{ width: '5rem' }}
            onClick={(e) => handleClick(e, totalBrokerFees[0].number)}
          >
            <CellIndicator
              isActive={false}
              position={totalBrokerFees[0].number}
            />
          </CellHeader>
          <CellHeader>
            <CellText
              position="left"
              backgroundColor="transparent"
              colorText="#4F5B62"
              data={`${totalBrokerFees[0].description}: ${totalBrokerFees[0].chargeData.amount}`}
              variant="body1"
            />
          </CellHeader>
          <CellHeader style={{ width: '7rem' }}>
            <CellText
              position="left"
              backgroundColor="transparent"
              colorText="#4F5B62"
              data="Amount"
              variant="body1"
            />
          </CellHeader>
          <CellHeader style={{ width: '7rem' }}>
            <CellText
              position="left"
              backgroundColor="transparent"
              colorText="#4F5B62"
              data="Months"
              variant="body1"
            />
          </CellHeader>
          <CellHeader rowSpan={4} style={{ width: '8rem', minWidth: '8rem' }}>
            <CellText
              variant="body1"
              position="center"
              backgroundColor="transparent"
              colorText="#4F5B62"
              data="Paid From Borrower’s Funds atSettlement"
            />
          </CellHeader>
          <CellHeader rowSpan={4} style={{ width: '8rem', minWidth: '8rem' }}>
            <CellText
              variant="body1"
              position="center"
              backgroundColor="transparent"
              colorText="#4F5B62"
              data=" Paid From
						Seller’s
						Funds at
						Settlement"
            />
          </CellHeader>
        </TableRow>
        <TableRow>
          <TableCell
            className={classes.borderCellStyle}
            style={{ width: '6rem' }}
          />
          <TableCell className={classes.borderCellStyle}>
            <CellText
              position="left"
              backgroundColor="transparent"
              colorText="#4F5B62"
              data=""
            />
          </TableCell>
          <TableCell className={classes.borderCellStyle}>
            <CellText
              position="left"
              backgroundColor="transparent"
              colorText="#4F5B62"
              data="Division of commission (line 700) as follows :"
            />
          </TableCell>
        </TableRow>
        {totalBrokerFees
          .filter((f) => f.number > 700 && f.number < 703)
          .map((row) => (
            <TableRow key={row.id}>
              <TableCell
                className={classes.borderCellStyle}
                style={{ width: '6rem' }}
              >
                <Actionbuttons handleEdit={() => {}} handleDelete={() => {}} />
              </TableCell>
              <TableCell
                className={classes.borderCellStyle}
                style={{ width: '5rem' }}
                onClick={(e) => handleClick(e, row.number)}
              >
                <CellIndicator isActive={false} position={row.number} />
              </TableCell>
              <TableCell className={classes.borderCellStyle}>
                <CellText
                  position="left"
                  backgroundColor="transparent"
                  colorText="#4F5B62"
                  data={row.description}
                />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          ))}
        {totalBrokerFees
          .filter((f) => f.number > 702)
          .map((row) => (
            <HudRow
              key={row.id}
              row={row}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              handleSelectItem={handleSelectItem}
              handleClick={handleClick}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default TotalStateBrokerTable;
