import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { TabOrder } from 'hooks/useOrderTabs';
import React from 'react';

type OrderOpenTabsProps = {
  openedOrders: TabOrder[];
  tabValue?: TabOrder;
  handleChange: (_: React.ChangeEvent<{}>, tabSelected: TabOrder) => void;
  handleCloseTab: (orderId: string) => void;
};

const useStyles = makeStyles((theme: any) => ({
  tabIndicator: { backgroundColor: theme.palette.secondary.dark },
  tab: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
  },
  tabSelected: { backgroundColor: theme.palette.background.paper },
  tabLabel: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
  },
  labelSelected: { color: theme.palette.common.black },
  iconContainer: {
    fontSize: 14,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: 0,
  },
  iconButton: {
    fontSize: 14,
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    zIndex: 100
  },
  icon: { color: theme.palette.dualBackground.default },
  iconSelected: { color: theme.palette.common.black },
}));

const OrderOpenTabs = ({
  openedOrders,
  tabValue,
  handleChange,
  handleCloseTab,
}: OrderOpenTabsProps) => {
  const classes = useStyles();

  return (
    tabValue ? (
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        value={tabValue}
        onChange={handleChange}
        TabIndicatorProps={{ className: classes.tabIndicator }}
      >
        {openedOrders.map((tab) => (
          <Tab
            key={tab.id}
            value={tab}
            className={clsx(classes.tab, { [classes.tabSelected]: tab.id === tabValue.id })}
            label={(
              <div className={classes.tabLabel}>
                <Typography
                  variant="body2"
                  className={clsx(classes.label, { [classes.labelSelected]: tab.id === tabValue.id })}
                >
                  {tab.orderId}
                </Typography>

                <div className={classes.iconContainer}>
                  <span>
                    <IconButton
                      component="div"
                      className={classes.iconButton}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleCloseTab(tab.id);
                      }}
                    >
                      <FontAwesomeIcon
                        icon="times"
                        size="xs"
                        className={clsx(classes.icon, { [classes.iconSelected]: tab.id === tabValue.id })}
                      />
                    </IconButton>
                  </span>
                </div>
              </div>
            )}
          />
        ))}
      </Tabs>
    ) : null);
};

export default OrderOpenTabs;
