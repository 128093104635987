import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: 40,
    height: 40,
    borderRadius: 4,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  notActive: {
    background: theme.palette.background.paper,
    color: theme.palette.text.shadow,
    borderColor: theme.palette.text.primary_light,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  active: {
    background: theme.palette.secondary.dark,
    color: theme.palette.primary.darker,
    border: 'none',
  },
  text: {
    fontWeight: 900,
    fontSize: 12,
    color: theme.palette.primary.darker,
    letterSpacing: 1,
  },
}));

type CellIndicatorType = {
  isActive?: boolean;
  position?: number;
  StringValue?: string;
};

const numberWithTwoDigit = (number: number) => `0${number}`.slice(-2);

const CellIndicator = ({
  isActive,
  position = 1,
  StringValue,
}: CellIndicatorType) => {
  const { active, notActive, container, text } = useStyles();
  return (
    <Box className={`${container} ${notActive} ${isActive && active}`}>
      <Typography variant="h1" variantMapping={{ h1: 'p' }} className={text}>
        {StringValue
          || (position < 10 ? numberWithTwoDigit(position) : position)}
      </Typography>
    </Box>
  );
};

CellIndicator.defaultProps = {
  isActive: false,
  position: 1,
  StringValue: undefined,
};

export default CellIndicator;
