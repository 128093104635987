import React, { FC } from 'react';

import useContacts from './contacts-hooks';
import ContactsScene from './contacts-scene';
import { ContactKind } from './types';

const Contacts: FC = () => {
  const {
    t,
    history,
    parties,
    searchText,
    setSearchText,
    displayMode,
    setDisplayMode,
    columns,
    createModalOpen,
    setCreateModalOpen,
    detailsDesktop,
    contactKind,
    setContactKind
  } = useContacts();

  const handleUserCreateModal = (state: boolean, kind: ContactKind) => {
    setCreateModalOpen(state);
    setContactKind(kind);
  };

  const handleDisplayMode = (mode: 'list' | 'grid'): void => setDisplayMode(mode);

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => setSearchText(event.target.value);

  const handleEditNav = (id): void => history.push(`/contacts/${id}`);

  return (
    <ContactsScene
      t={t}
      columns={columns}
      parties={parties}
      displayMode={displayMode}
      searchText={searchText}
      detailsDesktop={detailsDesktop}
      createModalOpen={createModalOpen}
      handleDisplayMode={handleDisplayMode}
      handleSearch={handleSearch}
      handleEditNav={handleEditNav}
      handleUserCreateModal={handleUserCreateModal}
      contactKind={contactKind}
    />
  );
};

export default Contacts;
