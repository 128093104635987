import { Box } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { LoanFormType } from 'views/orders/loans/types';

import { ConditionForm } from '../condition-form';
import { ConditionsForm } from './types';
import { formatConditionsForm, getValues } from './utils';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>,
}

export const IncreaseBalloonPaymentConditions: FC<Props> = ({ loanForm }) => {
  const { control } = loanForm;
  const { has_balloon_payment_text } = useWatch({ control });

  const conditionForm = useForm<ConditionsForm>({ mode: 'onChange' });

  const { firstConditionExist, firstFieldValues } = useWatch({ control: conditionForm.control });

  useEffect(() => {
    conditionForm.reset(getValues(has_balloon_payment_text));
  }, [conditionForm, has_balloon_payment_text]);

  const saveForm = () => {
    loanForm.setValue('has_balloon_payment_text', formatConditionsForm(conditionForm.getValues()), { shouldDirty: true });
  };

  return (
    <Box>
      {firstConditionExist && (
      <ConditionForm
        conditionsFields={[
          {
            prefix: 'You will have to pay $',
            value: firstFieldValues?.amount,
            onChange: (value) => {
              conditionForm.setValue('firstFieldValues.amount', value);
              saveForm();
            }
          },
          {
            prefix: ' at the end of year',
            value: firstFieldValues?.year,
            onChange: (value) => {
              conditionForm.setValue('firstFieldValues.year', value);
              saveForm();
            }
          }
        ]}
        onRemove={() => {
          conditionForm.setValue('firstConditionExist', false);
          conditionForm.setValue('firstFieldValues', {
            amount: undefined,
            year: undefined
          });
          saveForm();
        }}
      />
      )}
    </Box>
  );
};
