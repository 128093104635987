import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tableHeaderCell: { color: '#000000' },
  tableRow: {
    backgroundColor: '#e8edef',
    borderLeft: '5px solid #8c8c8c'
  },
  tableCell: { padding: '10px 16px' },
  total: { fontWeight: 'bold' }
}));
