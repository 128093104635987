/* eslint-disable react/no-array-index-key */
/* eslint-disable no-control-regex */
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faIndent,
  faOutdent,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import { UpdateOrderSpecific } from 'types/order';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  primarycolorfonts: { fontWeight: 500, color: theme.palette.primary.main },
  inputs: {
    '& .MuiInputBase-input': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: theme.palette.common.black,
    },
    '&:focus': { backgroundColor: theme.palette.background.default },
  },
  containerLine: {
    flex: 1,
    display: 'flex',
    backgroundColor: theme.palette.input.border,
    height: theme.spacing(5),
    marginBottom: theme.spacing(1)
  },
  linesItems: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    flex: 5,
    alignItems: 'center',
    flexDirection: 'row'
  },
  iconSize: { fontSize: '0.875rem' },
  buttonIcon: { width: theme.spacing(1), height: theme.spacing(2) },
  lineCharacter: { fontWeight: 500, color: theme.palette.primary.main },
  tabCharacter: { borderBottom: '1px solid black' }
}));

type signatureLine ={
  onDown: () => void,
  disableddown: boolean,
  disabledUp: boolean,
  onUp: () => void,
  onDelete: () => void,
  disableDelete: boolean,
  t: TFunction,
  organization: boolean,
  control:Control<UpdateOrderSpecific, any>,
  index:number,
  name:'data_buyer.vesting_block' | 'data_seller.vesting_block',
  setValue: UseFormSetValue<UpdateOrderSpecific>,
  idItem:string
}

const Signatureline:FC<signatureLine> = ({
  onDown,
  disableddown,
  disabledUp,
  onUp,
  onDelete,
  disableDelete,
  t,
  organization = false,
  control,
  index,
  name,
  setValue,
  idItem
}) => {
  const classes = useStyles();
  const value = useWatch({
    name,
    control,
  });
  const removeIndent = () => {
    const currentTabs = value[index].tabs;
    setValue(`${name}.${index}.tabs`, currentTabs - 1);
  };
  const addIndent = () => {
    const currentTabs = value[index].tabs;
    setValue(`${name}.${index}.tabs`, currentTabs + 1);
  };
  return (
    <Box className={classes.containerLine}>
      <Box className={classes.linesItems}>
        {value[index].kind === 'line'
          ? (
            <>
              {(value[index].tabs && value[index].tabs >= 1)
                ? [...Array(value[index]?.tabs)].map((_, indexlocal) => (
                  (<Box key={`${idItem}line-tab-${indexlocal}`}>&emsp;&emsp;&emsp;&emsp;</Box>)
                ))
                : null}
              {organization ? <Typography variant="body2" className={classes.lineCharacter}>{'By: '}</Typography> : null }
              <Box
                className={classes.tabCharacter}
                display="flex"
                flex="1"
              >
                <Box>&emsp;&emsp;&emsp;&emsp;</Box>
              </Box>
            </>
          )
          : (
            <>
              {(value[index].tabs && value[index].tabs > 0)
                ? [...Array(value[index]?.tabs)].map((_, indexlocal) => ((
                  <Box key={`${idItem}line-tab-${indexlocal}`}>&emsp;&emsp;&emsp;&emsp;</Box>
                )))
                : null}
              <Controller
                name={`${name}.${index}.text`}
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    value={value[index].text}
                    fullWidth
                    placeholder={t('orders:blank-line')}
                    InputProps={{ disableUnderline: true }}
                    className={classes.inputs}
                  />
                )}
              />
            </>
          )}
      </Box>
      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-around"
      >
        <Box>
          <IconButton
            className={classes.buttonIcon}
            onClick={onDown}
            disabled={disableddown}
          >
            <FontAwesomeIcon icon={faArrowAltCircleDown} className={classes.iconSize} />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            className={classes.buttonIcon}
            onClick={onUp}
            disabled={disabledUp}
          >
            <FontAwesomeIcon icon={faArrowAltCircleUp} className={classes.iconSize} />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            className={classes.buttonIcon}
            onClick={addIndent}
            disabled={value[index].tabs === 4}
          >
            <FontAwesomeIcon icon={faIndent} className={classes.iconSize} />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            className={classes.buttonIcon}
            onClick={removeIndent}
            disabled={value[index].tabs === 0}
          >
            <FontAwesomeIcon icon={faOutdent} className={classes.iconSize} />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            className={classes.buttonIcon}
            onClick={onDelete}
            disabled={disableDelete}
          >
            <FontAwesomeIcon icon={faTimesCircle} className={classes.iconSize} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Signatureline;
