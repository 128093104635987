/* eslint-disable no-tabs */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
/*
import {
  POLICY_TAXES_AND_FEES_DISCLOSED_PREMIUM_LINE,
  POLICY_TAXES_AND_FEES_PAID_TO_FIRST_NATIONAL,
} from 'utils/constants';
*/
import { AccountingType } from 'v2-types/order';

import { getAmount, TX_GUARANTY_FEE } from '../services';
import usePolicy from '../services/use-policy-context';

const useStyles = makeStyles((theme: any) => ({
  title: { color: theme.palette.tab.offselected },
  tableContainer: { marginTop: theme.spacing(1.5) },
  table: { backgroundColor: theme.palette.background.default },
  cellHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.palette.grey[700],
  },
  cell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  text: {
    color: theme.palette.common.black,
    fontSize: '0.875rem',
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    '&:hover': { color: theme.palette.common.black },
  },
}));

type TableOwnersPolicyTaxesAndFeesProps = {
 selectedPolicy: 'owners_policy' | 'lenders_policy',
 handleSelectEndorsement: (accountindInfo: AccountingType | null) => void
};

const TablePolicyTaxesAndFees: FC<TableOwnersPolicyTaxesAndFeesProps> = ({ selectedPolicy, handleSelectEndorsement }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { accounting } = usePolicy() || {};

  const mapTaxesAndFees = useMemo<Map<string, AccountingType | undefined >>(() => {
    const taxesMap = new Map();
    const ownersFee = accounting?.find((acc) => acc.code.includes('owners_tx_guaranty_fee'));
    const lendersFee = accounting?.find((acc) => acc.code.includes('lenders_tx_guaranty_fee'));
    taxesMap.set('owners_policy', ownersFee);
    taxesMap.set('lenders_policy', lendersFee);
    return taxesMap;
  }, [accounting]);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.title}>
          {selectedPolicy === 'owners_policy'
            ? t('policyInfoAndRates:owner-policy-taxes-and-fees')
            : t('policyInfoAndRates:lender-policy-taxes-and-fees')}
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.tableContainer}>
        <TableContainer>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.cellHeader}>
                  <Typography className={classes.text}>
                    {t('policyInfoAndRates:charge')}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cellHeader}>
                  <Typography className={classes.text}>
                    {t('policyInfoAndRates:fee-or-tax')}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cellHeader}>
                  <Typography className={classes.text}>
                    {t('policyInfoAndRates:description')}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cellHeader}>
                  <Typography className={classes.text}>
                    {t('policyInfoAndRates:price')}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cellHeader} />
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell align="center" className={classes.cell}>
                  <Checkbox
                    checked
                    disabled
                  />
                </TableCell>

                <TableCell className={classes.cell} style={{ minWidth: 160 }}>
                  <Typography className={classes.text}>
                    {TX_GUARANTY_FEE}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cell} style={{ minWidth: 180 }}>
                  <Typography className={classes.text}>
                    {mapTaxesAndFees.get(selectedPolicy)?.description || ''}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cell}>
                  <Typography className={classes.text}>
                    {`$${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
                      .format(getAmount(mapTaxesAndFees.get(selectedPolicy)) || 0)}`}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cell}>
                  <Tooltip arrow title={t('policyInfoAndRates:tooltip') as string}>
                    <IconButton
                      className={classes.icon}
                      onClick={() => {
                        handleSelectEndorsement(mapTaxesAndFees.get(selectedPolicy) || null);
                      }}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TablePolicyTaxesAndFees;
