import { makeStyles } from '@material-ui/core';

type StylesProps = {
  direction: 'vertical' | 'horizontal',
  padding: string,
  mapHeight: string,
  mapWidth: string
}

export const useStyles = makeStyles((theme:any) => ({
  root: { padding: (props) => theme.spacing(Number(props.padding)), width: '100%', margin: 0, },
  mapContainer: {
    width: (props: StylesProps) => (props.direction === 'vertical' ? '100%' : props.mapWidth),
    height: (props: StylesProps) => props.mapHeight,
    minHeight: (props: StylesProps) => props.mapHeight
  },
  controls: {
    backgroundColor: theme.palette.common.white,
    flex: 1,
    padding: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%'
  }
}));
