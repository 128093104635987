import { Box } from '@material-ui/core';
import NumberField from 'components/number-field';
import Switch from 'components/switch';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatCurrencyValueToNumber } from 'utils/helpers';

import { LoanFormType } from '../../types';
import { useStyles } from './styles';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>
}

export const Flags: FC<Props> = ({ loanForm }) => {
  const { t } = useTranslation();
  const { control } = loanForm;
  const {
    heloc: isHelocSelected,
    construction: isConstructionSelected,
    mers: isMersSelected,
  } = useWatch({ control });

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box style={{ flex: 1 }}>
        <Box>
          <Controller
            control={control}
            name="heloc"
            render={({ field }) => (
              <Switch
                name="heloc"
                label={t('loan:heloc')}
                checked={field.value}
                handleBlur={field.onBlur}
                handleOnChange={(event) => {
                  field.onChange(event);

                  const isContructionLoanSelected = loanForm.getValues('construction');
                  if (event.target.checked && isContructionLoanSelected) {
                    loanForm.setValue('construction', false);
                  }
                }}
              />
            )}
          />
          {isHelocSelected && (
          <Controller
            control={control}
            name="heloc_draw"
            render={({ field }) => (
              <TextField
                name="heloc_draw"
                label={t('loan:heloc_initial_draw')}
                value={field.value}
                handleBlur={field.onBlur}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
          )}
        </Box>

        <Box>
          <Controller
            control={control}
            name="construction"
            render={({ field }) => (
              <Switch
                name="construction"
                label={t('loan:construction_loan')}
                checked={field.value}
                handleBlur={field.onBlur}
                handleOnChange={(event) => {
                  field.onChange(event);

                  const isHelocSelectedValue = loanForm.getValues('heloc');
                  if (event.target.checked && isHelocSelectedValue) {
                    loanForm.setValue('heloc', false);
                  }
                }}
              />
            )}
          />
          {isConstructionSelected && (
          <Controller
            control={control}
            name="construction_initial"
            render={({ field }) => (
              <NumberField
                thousandSeparator
                decimalScale={2}
                name="construction_initial_draw"
                label={t('loan:construction_loan_initial_loan')}
                prefix="$"
                value={field.value}
                handleBlur={field.onBlur}
                onChange={(event) => {
                  field.onChange(formatCurrencyValueToNumber(event.target.value));
                }}
              />
            )}
          />
          )}
        </Box>
      </Box>

      <Box style={{ flex: 1 }}>
        <Controller
          control={control}
          name="mers"
          render={({ field }) => (
            <Switch
              name="mers"
              label={t('loan:mers')}
              checked={field.value}
              handleBlur={field.onBlur}
              handleOnChange={field.onChange}
            />
          )}
        />
        {isMersSelected && (
          <Controller
            control={control}
            name="mers_min"
            render={({ field }) => (
              <TextField
                name="mers_min"
                label={t('loan:mers_min')}
                value={field.value}
                handleBlur={field.onBlur}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
};
