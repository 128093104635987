import auth from "./auth";
import common from "./common";
import documents from "./documents";
import orders from "./orders";

export default {
  ...auth,
  ...common,
  ...documents,
  ...orders,
};
