import { InferenceResponse } from 'graphql/questions-and-answers/queries';
import { TOKEN_CLAIMS } from 'utils/constants';

export const getInferenceByAudio = async ({ audioBlob }: { audioBlob: Blob }) => {
  const data = new FormData();
  data.append('file', audioBlob, 'audio.ogg');
  const access_token = await localStorage.getItem(TOKEN_CLAIMS);

  const response = await fetch(`${process.env.REACT_APP_API_URL!}/inference/audio`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${access_token}` },
    body: data
  });

  if (!response.ok) {
    const errorResponse: { message: string } = await response.json();
    throw new Error(errorResponse.message);
  }

  const audioResponse: InferenceResponse = await response.json();
  return audioResponse;
};
