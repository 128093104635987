import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

import Header from './header';

const useStyles = makeStyles((theme: any) => ({
  padding: {
    padding: theme.spacing(3),
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  calculatorContainer: { paddingLeft: theme.spacing(2) },
}));

export const SkeletonPolicy = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={8}>
        <Paper>
          <Grid container direction="column">
            <Header />
          </Grid>
          <Box className={classes.padding}>
            <Skeleton variant="rect" width="100%" height={50} />
            <Skeleton variant="rect" width="40%" height={30} />
            <Skeleton variant="rect" width="70%" height={30} />
            <Skeleton variant="rect" width="70%" height={30} />
            <Skeleton variant="rect" width="70%" height={30} />
            <Skeleton variant="text" />
            <Skeleton variant="rect" width="100%" height={400} />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={4} className={classes.calculatorContainer}>
        <Paper className={classes.padding}>
          <Box className={classes.padding}>
            <Skeleton variant="rect" width="100%" height={50} />
            <Skeleton variant="text" />
            <Skeleton variant="rect" width="100%" height={90} />
            <Skeleton variant="rect" width="100%" height={50} />
            <Skeleton variant="text" />
            <Skeleton variant="rect" width="100%" height={90} />
          </Box>
        </Paper>
      </Grid>
    </>
  );
};
