import { capitalize } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { updateCurrentOrderCurrentValues } from 'redux/order-reducer/actions';
import services from 'services/endpoints';

import useAlert from './alert';

/**
 * @deprecated this hook will be replaced wen all the pending screens are migrated.
 */
const useSendOrderToServer = (
  updateRedux = false,
  key: string | string[] = '',
  dataKey: string | string[] = '',
  onSuccess = false,
  successAction = () => {},
) => {
  const { t } = useTranslation();
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const [updateOrder, response] = useMutation(services.updateOrder);

  useEffect(() => {
    if (response.isSuccess) {
      response.reset();

      if (updateRedux) {
        if (typeof key === 'string' && typeof dataKey === 'string') {
          dispatch(updateCurrentOrderCurrentValues({
            key,
            value: response.data[dataKey],
          }));
        } else {
          (key as string[]).forEach((_, index) => (
            dispatch(updateCurrentOrderCurrentValues({
              key: key[index],
              value: response.data[dataKey[index]],
            }))
          ));
        }
      }

      if (onSuccess) {
        successAction();
      }

      showAlert(capitalize(t('dialogs:order-updated')), 'success');
    }

    if (response.isError) {
      response.reset();
      showAlert(capitalize(t('dialogs:there-is-an')), 'error');
    }
  }, [response, showAlert, dispatch, t, key, dataKey, updateRedux, onSuccess, successAction]);

  return {
    data: response.data,
    updateOrder,
  };
};

export default useSendOrderToServer;
