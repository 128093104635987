import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
import AddentryModal from 'components/add-entry-modal/add-entry-modal';
import React, {
  FC,
  memo,
  useCallback,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import useDeleteEntry from 'utils/ledger/use-delete-ledger-entry';
import { useYupValidationResolver } from 'utils/ledger/use-yup-resolver';
import { AccountingType } from 'v2-types/order';
import * as yup from 'yup';

// import AddLedgerEntry from './add-entry-modal/add-entry-modal';
import Recording from './recording';

const useStyles = makeStyles((theme:any) => ({
  container: { padding: theme.spacing(2) },
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': { marginLeft: theme.spacing(2) },
  },
  add: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    alignSelf: 'flex-end',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
    marginBottom: theme.spacing(2)
  },
  iconSpace: { marginRight: theme.spacing(1) },
}));

type DocumentsType = {
  accountingData:AccountingType[],
  handleUpdateAccounting:(accountingData:AccountingType[]) => void,
}

export const validationSchema = yup.object({
  code: yup.string().required('Required'),
  description: yup.string().required('Required'),
  kind: yup.string().required('Required')
});

const DocumentsDetails: FC<DocumentsType> = ({ accountingData, handleUpdateAccounting }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openAddModal, setOpenAddModal] = useState(false);
  const { id } = useParams<{id:string}>();
  const showAlert = useAlert();
  const resolver = useYupValidationResolver(validationSchema);
  const { deleteEntry, deleteEntryResponse } = useDeleteEntry((data) => {
    deleteEntryResponse.reset();
    showAlert('Document Deleted', 'success');
    handleUpdateAccounting(data.deleteOrderLedgerEntry.accounting || []);
  });
  const memoizedDelete = useCallback((accountingId) => {
    deleteEntry({
      id,
      accountingId,
      filters: { accounting: 'E' }
    });
  }, [deleteEntry, id]);

  return (
    <>
      <Paper className={classes.container}>
        <Grid item xs={12}>
          {accountingData.map((acc) => (
            <Recording
              recording={acc}
              key={acc._id}
              handleUpdateAccounting={handleUpdateAccounting}
              handleDelete={() => { memoizedDelete(acc._id); }}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.row}>
            <Button
              className={classes.add}
              variant="outlined"
              onClick={() => setOpenAddModal(true)}
            >
              <FontAwesomeIcon
                icon="plus"
                size="1x"
                className={classes.iconSpace}
              />
              { t('common:add') }
            </Button>
          </Box>
        </Grid>
      </Paper>
      {openAddModal !== null
      && (
      <AddentryModal
        open={!!openAddModal}
        handleClose={() => { setOpenAddModal(false); }}
        onSuccesAddEntry={handleUpdateAccounting}
        prevData={{
          code: 'document',
          kind: 'manual',
          letter: 'E'
        } as AccountingType}
        hideFields={{
          entryDate: true,
          code: true,
          amount_override: true,
          months: true,
          number: true,
          letter: true,
          kind: true,
          months_advanced: true,
          annuality: true
        }}
        resolver={resolver}
      />
      )}
    </>
  );
};

export default memo(DocumentsDetails);
