import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
  emptyTitleContainer: { marginBottom: theme.spacing(2) },
  iconContainer: { textAlign: 'center' },
  icon: {
    fontSize: '1.2rem',
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: theme.spacing(1),
  },
  createButtonContainer: { marginTop: theme.spacing(2) },
  createButton: {
    height: 30,
    width: '100%',
    textTransform: 'capitalize',
  },
  createButtonIcon: { marginRight: theme.spacing(1) },
}));
