import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  container: { '&&& > * + *': { marginLeft: theme.spacing(1) } },
  add: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary
    },
    alignSelf: 'flex-end'

  },
  tag: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    alignSelf: 'flex-end'

  },
  clear: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  iconSpace: { marginRight: theme.spacing(1) },
}));

type CellOptionsType = {
  handleAddrow:() => void;
};

const CellOptions = ({ handleAddrow, }: CellOptionsType) => {
  const { t } = useTranslation();

  const { add, iconSpace, container } = useStyles();

  return (

    <Box
      className={container}
      display="flex"
      justifyContent="left"
      flexDirection="row"
    >
      <Button
        className={add}
        variant="outlined"
        onClick={handleAddrow}
      >
        <FontAwesomeIcon
          icon="plus"
          size="1x"
          className={iconSpace}
        />
        {t('charges:additional-row')}
      </Button>
    </Box>
  );
};

CellOptions.defaultProps = {};
export default CellOptions;
