import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  font: {
    fontFamily: 'Roboto',
    fontSize: 16,
    marginBottom: theme.spacing(3),
    color: theme.palette.common.white
  },
}));

type ParagraphTagProps = {
  text: string;
};

const ParagraphTag = ({ text }: ParagraphTagProps) => {
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      className={classes.font}
    >
      {text}
    </Typography>
  );
};

export default ParagraphTag;
