import { Table, TableBody } from '@material-ui/core';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoanFormType } from 'views/orders/loans/types';

import { Row } from './components/row';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>
}

export const Increases: FC<Props> = ({ loanForm }) => {
  const { control } = loanForm;
  const { t } = useTranslation();

  return (
    <Table>
      <TableBody>
        <Row
          control={control}
          description={t('disclosure:loan-amount')}
          optionName="increase_loan_amount"
          options={
            [
              {
                title: t('disclosure:can-increase'),
                value: true
              },
              {
                title: t('disclosure:cannot-increase'),
                value: false
              }
            ]
          }
          conditionName="increase_loan_amount_text"
          loanForm={loanForm}
        />

        <Row
          control={control}
          description={t('disclosure:interest-rate')}
          optionName="increase_interest_rate"
          options={
            [
              {
                title: t('disclosure:can-increase'),
                value: true
              },
              {
                title: t('disclosure:cannot-increase'),
                value: false
              }
            ]
          }
          conditionName="increase_interest_rate_text"
          loanForm={loanForm}
        />

        <Row
          control={control}
          description={t('disclosure:monthly-principal-interest')}
          optionName="increase_monthly_principal"
          options={
            [
              {
                title: t('disclosure:can-increase'),
                value: true
              },
              {
                title: t('disclosure:cannot-increase'),
                value: false
              }
            ]
          }
          conditionName="increase_monthly_principal_text"
          loanForm={loanForm}
        />

        <Row
          control={control}
          description={t('disclosure:prepayment-penalty')}
          optionName="has_prepayment_penalty"
          options={
            [
              {
                title: t('common:yes'),
                value: true
              },
              {
                title: 'No',
                value: false
              }
            ]
          }
          conditionName="has_prepayment_penalty_text"
          loanForm={loanForm}
        />

        <Row
          control={control}
          description={t('disclosure:balloon-payment')}
          optionName="has_balloon_payment"
          options={
            [
              {
                title: t('common:yes'),
                value: true
              },
              {
                title: 'No',
                value: false
              }
            ]
          }
          conditionName="has_balloon_payment_text"
          loanForm={loanForm}
        />
      </TableBody>
    </Table>
  );
};
