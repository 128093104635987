import React from 'react';
import { Redirect } from 'react-router-dom';

import BackdropLoading from '../../components/common/BackdropLoading';
import SignInScene from './sign-in-scene';
import useSignInHooks from './use-sign-in-hooks';

const SignIn = () => {
  const {
    t,
    theme,
    focusedIntput,
    setfocusedInput,
    loginResponse,
    setEmail,
    setPassword,
    loginWithRedirect,
    isLoading,
    isAuthenticated,
  } = useSignInHooks();

  if (loginResponse.isLoading || isLoading) { return (<BackdropLoading open loadingText="loading" />); }
  if (isAuthenticated) { return (<Redirect to="/orders" />); }

  return (
    <SignInScene
      setEmail={setEmail}
      setPassword={setPassword}
      loginWithRedirect={() => { loginWithRedirect(); }}
      t={t}
      theme={theme}
      focusedIntput={focusedIntput}
      setfocusedInput={setfocusedInput}
    />
  );
};

export default SignIn;
