import { useCreateLoan } from 'hooks/useCreateLoan';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { Loan } from 'v2-types/order';

import { CreateLoan } from '../../types';

const createLoanInitialValues: CreateLoan = {
  orderId: '',
  estateId: '',
  loanNumber: ''
};

type Params = {
  onClose: () => void,
  onSuccess: (data: Loan[], loanIdToSelect: string) => void,
}
export const useCreateLoanModal = ({ onClose, onSuccess }: Params) => {
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const history = useHistory();
  const createLoanForm = useForm<CreateLoan>({ defaultValues: createLoanInitialValues, mode: 'onChange' });

  const [createModal, { isLoading: isCreateLoanLoading }] = useCreateLoan({
    queryConfig: {
      onSuccess: (response) => {
        const { loans } = response.createOrderLoan;
        if (loans) {
          const createdLoan = response.createOrderLoan.loans.find((loan) => loan.loan_number === createLoanForm.getValues('loanNumber'));
          history.push(createdLoan?._id || '');
          onSuccess(loans, createdLoan?._id || '');
        }

        showAlert('Loan successfully created', 'success');
        onClose();
      },
      onError: () => {
        showAlert('Unexpected error occurred while trying to create a new loan', 'error');
      }
    }
  });

  const onConfirm = createLoanForm.handleSubmit((data) => {
    createModal({
      ...data,
      orderId
    });
  });

  return { createLoanForm, onConfirm, isCreateLoanLoading };
};
