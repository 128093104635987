import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { TableSkeleton } from "components/table-skeleton";
import { ListingEntry } from "graphql/listings/queries";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Loan } from "v2-types/order";
import { ModalData } from "views/orders/loans/types";
import { useStyles } from "../../../../styles";
import { InterestsTable } from "../interests-table";

type Props = {
  isLoading: boolean,
  selectedLoan?: Loan,
  setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>,
  kindListing: ListingEntry[]
}

export const Content: FC<Props> = ({
  isLoading,
  selectedLoan,
  setModalData,
  kindListing
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (isLoading) {
    return (
      <TableSkeleton
        numberOfRows={5}
        showBorder={false}
        columns={{
          actions: {
            header: { title: t('accounting:actions'), width: '50px' },
            renderer: () => <Skeleton animation="wave" variant="text" height={40} />
          },
          kind: {
            header: { title: 'Kind', align: 'left' },
            renderer: () => <Skeleton animation="wave" variant="text" height={40} />,
          },
          data: { renderer: () => <Skeleton animation="wave" variant="text" height={40} /> }
        }}
      />
    );
  }

  if (selectedLoan && selectedLoan.interests.length === 0) {
    return (
      <Typography className={classes.placeholderText}>
        There is not interests added yet
      </Typography>
    );
  }

  return (
    <Box style={{ overflow: 'scroll', maxWidth: '100%' }}>
      {selectedLoan && (
        <InterestsTable data={selectedLoan.interests} onShowModal={setModalData} kindListing={kindListing} />
      )}
    </Box>
  );
};