import { Grid } from '@material-ui/core';
import NumberField from 'components/number-field';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formInfoOrder } from '../services';
import SectionTitle from './section-title';

type amountsPDFProps = {
	control:Control<formInfoOrder, object>
}

const AmountsPDFInformation:FC<amountsPDFProps> = ({ control }) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitle title={t('orders:amount')} />
      <Grid item style={{ marginBottom: 16 }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="purchase_price"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      value={field.value}
                      customRef={ref}
                      label={t('orders:purchase-price')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onValueChange={({ floatValue }) => {
                        field.onChange(floatValue);
				  }}
                      handleBlur={field.onBlur}
                      onFocus={() => {}}
                    />
				  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="loan_amount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      value={field.value}
                      customRef={ref}
                      label={t('orders:loan-amount')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onValueChange={({ floatValue }) => {
                        field.onChange(floatValue);
				  }}
                      handleBlur={field.onBlur}
                      onFocus={() => {}}
                    />
				  )}
                />
              </Grid>
            </Grid>

          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="earnest_money"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      value={field.value}
                      customRef={ref}
                      label={t('properties:earnest-money')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onValueChange={({ floatValue }) => {
                        field.onChange(floatValue);
				  }}
                      handleBlur={field.onBlur}
                      onFocus={() => {}}
                    />
				  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="option_fee"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      value={field.value}
                      customRef={ref}
                      label={t('earnestAndCommissions:option-fee')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onValueChange={({ floatValue }) => {
                        field.onChange(floatValue);
				  }}
                      handleBlur={field.onBlur}
                      onFocus={() => {}}
                    />
				  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="selling_commission"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      value={field.value}
                      customRef={ref}
                      label={t('properties:selling-commission')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onValueChange={({ floatValue }) => {
                        field.onChange(floatValue);
				  }}
                      handleBlur={field.onBlur}
                      onFocus={() => {}}
                    />
				  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="listing_commission"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <NumberField
                      value={field.value}
                      customRef={ref}
                      label={t('properties:listing-commission')}
                      thousandSeparator=","
                      decimalScale={4}
                      prefix="$"
                      onValueChange={({ floatValue }) => {
                        field.onChange(floatValue);
				  }}
                      onFocus={() => {}}
                    />
				  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AmountsPDFInformation;
