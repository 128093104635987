import React, { FC } from 'react';
import { DocumentsType } from 'v2-types/order';

import AddNewSignModal from './components/add-new-sign-modal';
import useSignDocuments from './sign-documents-hooks';
import SignDocumentsScene from './sign-documents-scene';

type signDocumentsType={
	documents:DocumentsType[]
}

const SignDocuments:FC<signDocumentsType> = ({ documents }) => {
  const {
    signatures,
    openModal,
    setOpenModal
  } = useSignDocuments();

  const handleSetOpenModal = (param:boolean) => {
    setOpenModal(param);
  };
  return (
    <>
      <SignDocumentsScene
        signatures={signatures}
        handleOpenModal={handleSetOpenModal}
      />
      {
		openModal
		&& (
			<AddNewSignModal
  				documents={documents}
  				open={openModal}
  				onClose={() => { handleSetOpenModal(false); }}
			/>
		)
	}
    </>
  );
};

export default SignDocuments;
