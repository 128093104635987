import { PayloadAction } from '@reduxjs/toolkit';
import { DatesAndConfigurationType } from 'v2-types/order';

import { SET_ORDER_CONFIGURATION } from './actions-types';

export type OrderConfigurationStateType = {
	orderConfiguration: DatesAndConfigurationType | null
}

const initialState: OrderConfigurationStateType = { orderConfiguration: null };

const orderConfigurationReducer = (state = initialState, action: PayloadAction<DatesAndConfigurationType>) => {
  switch (action.type) {
    case SET_ORDER_CONFIGURATION:
      return { orderConfiguration: action.payload };
    default:
      return state;
  }
};

export default orderConfigurationReducer;
