import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { WireTransfersType } from 'types/wire-transfers';

import { TransferInfo } from './components/transfer-info';
import { useStyles } from './styles';

type ModalAuthTransferProps = {
  transfer: WireTransfersType,
  currentUserEmail: string,
  t: TFunction,
  open: boolean,
  handleToggleModals: () => void,
  handleAuthTransfer: (action: string) => void,
}

export const ModalAuthTransfer: FC<ModalAuthTransferProps> = ({
  transfer,
  currentUserEmail,
  t,
  open,
  handleToggleModals,
  handleAuthTransfer,
}) => {
  const classes = useStyles();

  const handleClickAuth = (action: number) => {
    const actions = ['authorize', 'reject'];
    handleAuthTransfer(actions[action]);
    handleToggleModals();
  };

  return (
    <Dialog open={open} onClose={() => handleToggleModals()} fullWidth>
      <DialogTitle className={classes.dialgoTitleContainer}>
        <Grid container direction="column">
          <Grid item className={classes.closeIconContainer}>
            <Button onClick={() => handleToggleModals()} style={{ minWidth: 'auto' }}>
              <FontAwesomeIcon
                icon="plus"
                size="lg"
                transform={{ rotate: 45 }}
                className={classes.closeIcon}
              />
            </Button>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              className={classes.dialogTitle}
            >
              {t('payments:authorize-transfer')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="body1" align="center">
              {t('payments:authorize-wire-transfer-option')}
            </Typography>
          </Grid>
          <Grid item container>
            <TransferInfo transfer={transfer} currentUserEmail={currentUserEmail} />
          </Grid>
          <Grid item>
            <Box
              marginBottom={2}
              marginTop={2}
              display="flex"
              justifyContent="center"
              className={classes.actionsContainer}
            >
              <ContainedButton
                text={t('payments:authorize')}
                icon="check"
                backgroundColor="#4caf50"
                hoverColor="#49914c"
                onClick={() => {
                  handleClickAuth(0);
                  handleToggleModals();
                }}
              />
              <ContainedButton
                text={t('payments:reject')}
                icon="ban"
                backgroundColor="#F44336"
                hoverColor="#c44d44"
                onClick={() => {
                  handleClickAuth(1);
                  handleToggleModals();
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAuthTransfer;
