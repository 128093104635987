import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, Button, Grid, IconButton, Typography
} from '@material-ui/core';
import React from 'react';

const DataSection = ({
  data,
  handleToggleModal,
  document_id,
  handleRemoveData,
  searchWordClass
}) => (
  <Box margin={1}>
    <Grid container direction="row">
      <Grid item xs={6}>
        <Typography style={{ color: '#000' }}>Data</Typography>
      </Grid>
      <Grid container xs={6} justifyContent="flex-end">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleToggleModal(document_id, 'addData')}
        >
          <Typography variant="subtitle2">Add Party</Typography>
        </Button>
      </Grid>
    </Grid>
    {data.map((association) => (
      <Box alignItems="center" display="flex" gridGap={8}>
        <Typography variant="subtitle1" style={{ color: '#000' }} className={searchWordClass}>
          {association.kind}
          {association.kind === 'deliver' ? ' to ' : ' '}
          {association.kind === 'property'
            ? association.address
            : association.name}
        </Typography>
        <IconButton
          style={{ color: '#F44336' }}
          size="small"
          onClick={() => {
            handleRemoveData({
              fileId: document_id,
              kind: association.kind,
              dataId: association._id,
            });
          }}
        >
          <FontAwesomeIcon icon="times-circle" />
        </IconButton>
      </Box>
    ))}
  </Box>
);

export default DataSection;
