import { createContext } from 'react';

type Context = {
	handleAddParty:(row:any)=>void,
	handleEditAmount:(row:any)=>void,
	handleEditLedgerAssociation:(row:any)=>void,
	handleDeleteLedgetEntry:(row:any)=>void,
	handleDeleteLegerAssociation:(row:any)=>void,
	handledeleteLink:(order_id: string, paymentRecordId: string, associationKind: string, associationId: string, associationLedgerRow: string) => void,
	handleCreateLink:(row:any)=>void,
};

const handlerAccountingContext = createContext<Context | null>(null);

export default handlerAccountingContext;
