import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { asyncForEach } from 'utils/helpers';
import { AccountingFilter, AccountingType, OrderLedgerKinds } from 'v2-types/order';

import gqlRequest from '../generic';

// CreateOrderLedgerEntry

export type CreateOrderLedgerEntryParams = {
  id: string;
  amount: number;
  code: string;
  description: string;
  kind: string;
  letter: string;
  filters?: object;
};

const mutationCreateOrderLedgerEntry = (response: string) => `
  mutation CreateOrderLedgerEntry($id: ID!, $amount: Float!, $code: OrderLedgerCodes!, $description: String!, $kind: OrderLedgerKinds!, $letter: OrderLedgerCharges!, $filters: OrderFilters) {
    createOrderLedgerEntry(_id: $id, amount: $amount, code: $code, description: $description, kind: $kind, letter: $letter, filters: $filters) {
      ${response}
    }
  }
`;

export const gqlCreateOrderLedgerEntry = <R>(
  response: string,
  params: CreateOrderLedgerEntryParams,
) => gqlRequest<R, CreateOrderLedgerEntryParams>(
  mutationCreateOrderLedgerEntry(response),
  params,
);

// UpdateOrderLedger

export type UpdateOrderLedgerParams = {
  id: string;
  accountingId: string;
  data: {
    letter?: string;
    disclosed_as?: string;
    amount_override?: number;
  };
  filters?: object;
};

const mutationUpdateOrderLedger = (response: string) => `
  mutation UpdateOrderLedger($id: ID!, $accountingId: ID!, $data: OrderLedgerEntryUpdateContent!, $filters: OrderFilters) {
    updateOrderLedger(_id: $id, accounting_id: $accountingId, data: $data, filters: $filters) {
      ${response}
    }
  }
`;

export const gqlUpdateOrderLedger = <R>(
  response: string,
  params: UpdateOrderLedgerParams,
) => gqlRequest<R, UpdateOrderLedgerParams>(
  mutationUpdateOrderLedger(response),
  params,
);

// DeleteOrderLedgerEntry

export type DeleteOrderLedgerEntryParams = {
  id: string;
  accountingId: string;
  filters?: object;
};

const mutationDeleteOrderLedgerEntry = (response: string) => `
  mutation DeleteOrderLedgerEntry($id: ID!, $accountingId: ID!, $filters: OrderFilters) {
    deleteOrderLedgerEntry(_id: $id, accounting_id: $accountingId, filters: $filters) {
      ${response}    
    }
  }
`;

export const gqlDeleteOrderLedgerEntry = <R>(
  response: string,
  params: DeleteOrderLedgerEntryParams,
) => gqlRequest<R, DeleteOrderLedgerEntryParams>(
  mutationDeleteOrderLedgerEntry(response),
  params,
);

// CreateOrderLedgerAssociation

export type CreateOrderLedgerAssociationParams = {
  id: string;
  accountingId: string;
  kind: string;
  partyId: string;
  data: {
    amount?: number;
    percent?: number;
    at_closing_percent?: number;
    before_closing_percent?: number;
    payment_id?: string;
  };
  filters?: object;
};

const mutationCreateOrderLedgerAssociation = (response: string) => `
  mutation CreateOrderLedgerAssociation($id: ID!, $accountingId: ID!, $kind: String!, $partyId: ID!, $data: OrderLedgerAssociationContent!, $filters: OrderFilters) {
    createOrderLedgerAssociation(_id: $id, accounting_id: $accountingId, kind: $kind, party_id: $partyId, data: $data, filters: $filters) {
      ${response}
    }
  }
`;

export const gqlCreateOrderLedgerAssociation = <R>(
  response: string,
  params: CreateOrderLedgerAssociationParams,
) => gqlRequest<R, CreateOrderLedgerAssociationParams>(
  mutationCreateOrderLedgerAssociation(response),
  params,
);

// UpdateOrderLedgerAssociation

export type UpdateOrderLedgerAssociationParams = {
  id: string;
  accountingId: string;
  partyId: string;
  kind: string;
  data: {
    amount?: number;
    percent?: number;
    at_closing_percent?: number;
    before_closing_percent?: number;
  };
  filters?: object;
};

const mutationUpdateOrderLedgerAssociation = (response: string) => `
  mutation UpdateOrderLedgerAssociation($id: ID!, $accountingId: ID!, $partyId: ID!, $kind: String!, $data: OrderLedgerAssociationUpdateContent!, $filters: OrderFilters) {
    updateOrderLedgerAssociation(_id: $id, accounting_id: $accountingId, party_id: $partyId, kind: $kind, data: $data, filters: $filters) {
      ${response}
    }
  }
`;

export const gqlUpdateOrderLedgerAssociation = <R>(
  response: string,
  params: UpdateOrderLedgerAssociationParams,
) => gqlRequest<R, UpdateOrderLedgerAssociationParams>(
  mutationUpdateOrderLedgerAssociation(response),
  params,
);

// DeleteOrderLedgerAssociation

type deleteLedgerEntryParams = {
	id: string,
	accountingId: string,
	filters?: object;
  }

export type deleteLedgerEntryReturn = {
	deleteOrderLedgerEntry: {
	  accounting: AccountingType[] | null;
	}
  }

const queryDeleteEntry = `
  mutation Mutation($id: ID!, $accountingId: ID!,$filters: OrderFilters) {
	deleteOrderLedgerEntry(_id: $id, accounting_id: $accountingId,filters: $filters) {
	  accounting {
		_id
		amount
		amount_calculated
		amount_override
		code
		description
		entry_date
		months
		per_month
		annuality
		months_advanced
		accounted_for {
          amount
          percent
          record {
			  _id
			  code
			  number
			  amount
			  letter
			  description
			  involved_parties{
			    _id
				name
				kind
			  }
			}
        }
		involved_parties {
		  _id
		  amount
		  at_closing_amount
		  at_closing_percent
		  before_closing_amount
		  before_closing_percent
		  accounted_amount
		  accounted_percent
		  accounted_by {
			amount
			percent
			record {
			  _id
			  code
			  description
			  entry_date
			}
		  }
		  kind
		  name
		  order_kinds
		  payment_id
		  payment_kind
		  payment_reference
		  percent
		}
		kind
		letter
		number
	  }
	}
  }
  `;

export const deleteLedgerEntry = (params:deleteLedgerEntryParams) => gqlRequest<deleteLedgerEntryReturn, deleteLedgerEntryParams >(queryDeleteEntry, params);

type EditManualChargeParams = {
  id: string,
  accountingId: string,
  data: {
    amount_override: number | null,
    entry_date?: null | string,
    letter?: null | string,
    number?: null | number,
    months?: null |number,
    months_advanced?: number | null,
    annuality?: number | null,
	  description?: string | null
  },
  filters?: { [key: string]: AccountingFilter}
 }

export type EditEntryReturn = {
  updateOrderLedgerEntry: {
      accounting: AccountingType[] | null;
    }
}

const mutationEditEntry = `
  mutation UpdateOrderLedgerEntry($id: ID!, $accountingId: ID!, $data: OrderLedgerEntryUpdateContent!, $filters: OrderFilters) {
    updateOrderLedgerEntry(_id: $id, accounting_id: $accountingId, data: $data, filters: $filters) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        annuality
        code
        description
        entry_date
        kind
        letter
        number
		months
		per_month
		months_advanced
        accounted_for {
          amount
          percent
          record {
			  _id
			  code
			  number
			  amount
			  letter
			  description
			  involved_parties{
			    _id
				name
				kind
			  }
			}
        }
        involved_parties {
          _id
          amount
          accounted_amount
          accounted_percent
          accounted_by {
            amount
            percent
            record {
              _id
              code
              description
            }
          }
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          name
          order_kinds
          payment_id
          percent
        }
      }
    }
  }
`;

export const editManualCharge = (params: EditManualChargeParams) => gqlRequest<EditEntryReturn, EditManualChargeParams>(mutationEditEntry, params);

type DeleteLdgerAssociationParams ={
  id: string,
  accountingId:string,
  kind: string,
  partyId: string,
  filters?: { [key: string]: string}
}

export type DeleteAssociationReturn = {
  deleteOrderLedgerAssociation: {
      accounting: AccountingType[] | null;
    }
}

const mutationDeleteLedgerAssociation = `
  mutation Mutation($id: ID!, $accountingId: ID!, $kind: String!, $partyId: ID!,$filters: OrderFilters) {
    deleteOrderLedgerAssociation(_id: $id, accounting_id: $accountingId, kind: $kind, party_id: $partyId, filters: $filters) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        code
        description
        entry_date
		months
		per_month
        involved_parties {
          _id
          amount
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          name
          order_kinds
          payment_id
          payment_kind
          payment_reference
          percent
        }
        kind
        letter
        number
      }
    }
  }
`;

export const deleteLedgerAssociation = (params: DeleteLdgerAssociationParams) => gqlRequest<DeleteAssociationReturn, DeleteLdgerAssociationParams>(mutationDeleteLedgerAssociation, params);

type Entry = {
	accountingId: string,
	data: {
	  amount_override: number | null,
	  entry_date: null | string,
	  letter: null | string,
	  number?: null | number,
	  months?: null |number
	}
}

type UpdateLedgerEntriesResult = {
	updateOrderLedgerEntry: {
	  _id: string
	  accounting: AccountingType[]
	},
	updateOrderLedgerAssociation: {
	  _id: string,
	  accounting: AccountingType[]
	}
  }

type UpdateLedgerEntriesParams = {
	id: string,
	entries: Entry[],
	filters?: { [key: string]: AccountingFilter},
}

export const updateLedgerEntries = async (params: UpdateLedgerEntriesParams) => {
  const query = `
		mutation UpdateOrderLedgerEntry(
			$id: ID!,
			$filters: OrderFilters,
      $accountingID: ID!,
	    $dataAccounting: OrderLedgerEntryUpdateContent!)
    {
      updateOrderLedgerEntry(
        _id: $id, 
        accounting_id: $accountingID, 
        data: $dataAccounting, 
        filters: $filters
      ) {
        _id
      }
		}
  `;

  const responseList = await asyncForEach(params.entries, (entry) => graphQLClient().request<UpdateLedgerEntriesResult>(
    query,
    {
      id: params.id,
      filters: { ...params.filters },
      accountingID: entry.accountingId,
      dataAccounting: entry.data
    }
  ));

  return responseList;
};

export type HelpOrderLedgerEntryLinkedParams = {
  orderId: string,
  amount: number,
  code: string,
  description: string,
  kind: OrderLedgerKinds,
  entryDate: string,
  data: {
    accounting_id: string,
    kind: string,
    party_id: string
  }
  filters?: { [key: string]: AccountingFilter },
}

export type HelpOrderLedgerEntryLinkedReturn = {
  helpOrderLedgerEntryLinked: {
    accounting: AccountingType[]
  }
}

export const helpOrderLedgerEntryLinked = async (payload: HelpOrderLedgerEntryLinkedParams) => {
  /* eslint-disable max-len */
  const query = gql`
    mutation HelpOrderLedgerEntryLinked($orderId: ID!, $amount: Float!, $code: OrderLedgerCodes!, $data: helpOrderLedgerEntryLinkedContent!, $description: String!, $kind: OrderLedgerKinds!, $entryDate: String, $filters: OrderFilters) {
        helpOrderLedgerEntryLinked(_id: $orderId, amount: $amount, code: $code, data: $data, description: $description, kind: $kind, entry_date: $entryDate, filters: $filters) {
        accounting {
          _id
          amount
          amount_calculated
          amount_override
          code
          description
          entry_date
          kind
          letter
          number
          months
		  annuality
          per_month
		  months_advanced
          accounted_for {
            amount
            percent
          }
          involved_parties {
            _id
            amount
            at_closing_amount
            at_closing_percent
            before_closing_amount
            before_closing_percent
            kind
            name
            order_kinds
            payment_id
            payment_kind
            payment_reference
            percent
            accounted_amount
            accounted_percent
            accounted_by {
              amount
	            percent
	            record {
		            _id
		            description
		            code
		            entry_date
	            }
            }
          }
        }
      }
    }
  `;

  return gqlRequest<HelpOrderLedgerEntryLinkedReturn, HelpOrderLedgerEntryLinkedParams>(query, payload);
};

export type DeleteOrderLedgerAssociationParams = {
  orderId: string,
  accountingId: string,
  kind: string,
  partyId: string,
  filters?: { [key: string]: AccountingFilter },
}

export type DeleteOrderLedgerAssociationReturn = {
  deleteOrderLedgerAssociation: {
    accounting: AccountingType[]
  }
}

export const deleteOrderLedgerAssociation = async (payload: DeleteOrderLedgerAssociationParams) => {
  /* eslint-disable max-len */
  const query = gql`
    mutation DeleteOrderLedgerAssociation($orderId: ID!, $accountingId: ID!, $kind: String!, $partyId: ID!, $filters: OrderFilters) {
     deleteOrderLedgerAssociation(_id: $orderId, accounting_id: $accountingId, kind: $kind, party_id: $partyId, filters: $filters) {
      accounting {
        _id
        amount
        amount_calculated
        amount_override
        code
        description
        entry_date
        kind
        letter
        number
        months
        per_month
        accounted_for {
          amount
          percent
        }
        involved_parties {
          _id
          amount
          at_closing_amount
          at_closing_percent
          before_closing_amount
          before_closing_percent
          kind
          name
          order_kinds
          payment_id
          payment_kind
          payment_reference
          percent
          accounted_amount
          accounted_percent
          accounted_by {
            amount
            percent
            record {
              _id
              description
              code
              entry_date
            }
          }
        }
      }
    }
  }
  `;

  return gqlRequest<DeleteOrderLedgerAssociationReturn, DeleteOrderLedgerAssociationParams>(query, payload);
};

type DeleteLinkParams = {
	orderId: string,
	accountingId: string,
	targetEntryId: string,
	targetAssociationId: string,
	targetAssociationKind: string,
  filters?: { [key: string]: AccountingFilter },
}

export type DeleteLinkReturn = {
  deleteOrderLedgerLink: {
    accounting: AccountingType[] | null;
  }
}

const deleteOrderLedgerLinkQuery = `
mutation DeleteOrderLedgerLink($orderId: ID!, $accountingId: ID!, $targetEntryId: ID!, $targetAssociationId: ID!, $targetAssociationKind: String!, $filters: OrderFilters) {
	deleteOrderLedgerLink(_id: $orderId, accounting_id: $accountingId, target_entry_id: $targetEntryId, target_association_id: $targetAssociationId, target_association_kind: $targetAssociationKind, filters: $filters) {
	  accounting {
			_id
			accounted_for {
				amount
				percent
				record {
				_id
				code
				number
				amount
				letter
				description
				involved_parties{
					_id
					name
					kind
				}
				}
			}
			amount
			amount_calculated
			amount_override
			annuality
			code
			description
			entry_date
			involved_parties {
				_id
				accounted_amount
				accounted_percent
				accounted_by {
					amount
					percent
					record {
						_id
						code
						description
						amount
						entry_date
					}
				}
				amount
				at_closing_amount
				at_closing_percent
				before_closing_amount
				before_closing_percent
				kind
				name
				order_kinds
				payment_id
				payment_kind
				payment_reference
				percent
			}
			kind
			letter
			number
			months
			months_advanced
			per_month
	  }
	}
}
`;

export const deleteLedgerLink = (params: DeleteLinkParams) => gqlRequest<DeleteLinkReturn, DeleteLinkParams>(deleteOrderLedgerLinkQuery, params);
