import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { NewUserType } from 'types/new-user';
import { NewOrderPartyType } from 'types/order';

import InformationTab from '../information-tab';
import PartySearch from '../party-search/party-search';

const useStyles = makeStyles((theme: any) => ({
  button: {
    textTransform: 'none',
    marginTop: theme.spacing(4),
    fontWeight: 500,
  },
  paddingContent: { paddingTop: theme.spacing(3) },
  suubtitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
  tabsContainer: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  divider: {
    padding: `0px ${theme.spacing(5)}px`,
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[400],
  },
  selectedagent: {
    backgroundColor: theme.palette.button.default,
    textTransform: 'none',
  },
  unseLectedagent: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'none',
  },
  iconSize: { fontSize: '0.875rem' },
  buttonLetter: {
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  titleagentlist: { marginTop: theme.spacing(3) },
  buttoncontainer: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  buttonDelete: { textTransform: 'none' },
}));

type agentSceneType = {
  t: TFunction;
  handleSearch: (text: string) => void;
  parties: NewUserType[];
  setagentID: React.Dispatch<any>;
  agentData: NewUserType | undefined;
  handleDeleteagent: () => void;
  agents: NewOrderPartyType[];
  agentSelected: NewOrderPartyType | null;
  handleSelectagent: (agent: NewOrderPartyType) => void;
  kind: string;
};

const AgentScene: FC<agentSceneType> = ({
  t,
  handleSearch,
  parties,
  setagentID,
  agentData,
  handleDeleteagent,
  agents,
  agentSelected,
  handleSelectagent,
  kind,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.paddingContent}>
      <PartySearch
        description={t(`parties:${kind.toLowerCase()}-search`)}
        labelField={t('parties:contact')}
        parties={parties}
        handleOnChange={handleSearch}
        handleItemSelect={setagentID}
      />
      <Divider orientation="horizontal" className={classes.divider} />

      {agents.length > 0 && (
        <>
          <Grid item xs={12} className={classes.titleagentlist}>
            <Typography variant="subtitle1" className={classes.suubtitle}>
              {t(`parties:${kind.toLowerCase()}-list`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.tabsContainer}>
              {agents
                && agents.map((item) => (
                  <Box key={item._id}>
                    <Button
                      variant="contained"
                      disableElevation
                      className={clsx({
                        [classes.selectedagent]:
                          agentSelected?.name === item.name,
                        [classes.unseLectedagent]:
                          agentSelected?.name !== item.name,
                      })}
                      onClick={() => handleSelectagent(item)}
                    >
                      <Typography
                        variant="body2"
                        className={classes.buttonLetter}
                      >
                        {item.name}
                      </Typography>
                    </Button>
                  </Box>
                ))}
            </Grid>
            <Divider orientation="horizontal" className={classes.divider} />
          </Grid>
        </>
      )}

      <Grid item>
        {agentData && (
          <Grid container direction="column">
            <Grid item>
              <Box
                display="flex"
                flexDirection="row"
                className={classes.buttoncontainer}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={(
                    <FontAwesomeIcon
                      icon="trash"
                      size="xs"
                      className={classes.iconSize}
                    />
                  )}
                  className={classes.buttonDelete}
                  onClick={() => {
                    handleDeleteagent();
                  }}
                >
                  {t(`parties:delete-${kind.toLowerCase()}`)}
                </Button>
              </Box>
            </Grid>
            <InformationTab party={agentData} t={t} mainParty={false} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AgentScene;
