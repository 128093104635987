import { Grid } from '@material-ui/core';
import React from 'react';

import { NoteEditor } from './components/NoteEditor';
import { NoteList } from './components/NoteList';
import { useNotesHooks } from './hooks';
import { useStyles } from './styles';

export const Notes = () => {
  const {
    isLoading,
    noteList,
    selectedNote,
    selectNote,
    saveNote,
    addNewNote,
    removeNote,
    copyNote,
    isSavingChanges,
    actionsEnabled,
    inputRef
  } = useNotesHooks();
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
    >
      <Grid item className={classes.sideBar}>
        <NoteList
          noteList={noteList}
          selectedNoteId={selectedNote?.id ?? ''}
          isLoadingNotes={isLoading}
          isSavingChanges={isSavingChanges}
          addNewNote={addNewNote}
          selectNote={selectNote}
          removeNote={removeNote}
          copyNote={copyNote}
          actionsEnabled={actionsEnabled}
        />
      </Grid>
      <Grid item xs={10}>
        <NoteEditor
          isLoadingNotes={isLoading}
          inputRef={inputRef}
          saveNote={saveNote}
        />
      </Grid>
    </Grid>
  );
};
