import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { NewTemplateType } from 'types/auto-templates';
import { listingItemtype } from 'types/ledger';

import { emptyTemplate } from '../utils/constants';
import { FormTemplate } from './form-template';

type ModalNewTemplateProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  partyRolls: listingItemtype[];
  createTemplate: (payload: NewTemplateType) => void;
  codeTemplates: string[];
  t: TFunction;
};

export const ModalNewTemplate: FC<ModalNewTemplateProps> = ({
  open,
  setOpen,
  partyRolls,
  createTemplate,
  codeTemplates,
  t,
}) => (
  <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
    <DialogTitle>{t('documents:new-template')}</DialogTitle>
    <DialogContent>
      <FormTemplate
        handleOnSubmit={createTemplate}
        partyRolls={partyRolls}
        template={emptyTemplate}
        codeTemplates={codeTemplates}
        closeModal={() => setOpen(false)}
      />
    </DialogContent>
  </Dialog>
);

export default ModalNewTemplate;
