import React from 'react';

import useChargesKMLN from '../services/useGetChargesKM';
import ChargesKMScene from './charges-km-scene';

const ChargesKM = () => {
  const {
    dataCharges,
    handleUpdateAccounting,
    handleDelete,
    handleEditModal,
    handlePayor,
    openAddModal,
    handleAddModal,
    state
	  } = useChargesKMLN(['K', 'M']);

  return (
    <ChargesKMScene
      accountingData={dataCharges || []}
      handleUpdateAccounting={handleUpdateAccounting}
      handleDelete={handleDelete}
      handleEditModal={handleEditModal}
      handlePayor={handlePayor}
      openAddModal={openAddModal}
      handleaddModal={handleAddModal}
      state={state as any}
    />
  );
};

export default ChargesKM;
