import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Redirect } from 'react-router';
import { OLD_ROUTE } from 'utils/constants';

import BackdropLoading from '../../common/BackdropLoading';

const PageCheckedHome = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <BackdropLoading open loadingText="loading" />;
  }

  const location = localStorage.getItem(OLD_ROUTE);

  if (!isAuthenticated) {
    return <Redirect to="/sign-in" />;
  }

  if (location) {
    localStorage.clear();
    return <Redirect to={location} />;
  }

  return <Redirect to="/orders" />;
};

export default PageCheckedHome;
