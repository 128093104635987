import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

type UpdateCashToCloseParams = {
  orderId: string,
  entryCode: string,
  data: {
    changed: boolean,
    changed_text: string,
    estimate_amount: number
  }
}

export type UpdateCashToCloseReturn = {
  updateOrderCash: {
    _id
  }
}

export const updateCashToClose = async (payload: UpdateCashToCloseParams) => {
  const query = gql`
    mutation UpdateOrderCash($orderId: ID!, $entryCode: String!, $data: OrderCashContent!) {
      updateOrderCash(_id: $orderId, entry_code: $entryCode, data: $data) {
        _id
      }
    }
  `;
  const response = await graphQLClient().request<UpdateCashToCloseReturn, UpdateCashToCloseParams>(query, payload);
  return response;
};
