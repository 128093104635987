import { Box } from '@material-ui/core';
import TableComponent from 'components/Table';
import React, { FC } from 'react';

import { useStyles } from './styles';
import { ColumnsType } from './types';

type Props = {
  columns: ColumnsType,
  numberOfRows?: number,
  showBorder?: boolean
}
export const TableSkeleton: FC<Props> = ({
  columns,
  numberOfRows = 8,
  showBorder = true
}) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.scrollDiv} ${classes.containerTable}`}>
      <TableComponent
        disabledBorderLeft
        disableFullborderCell={showBorder}
        columns={columns}
        dataSet={[...Array(numberOfRows)]}
      />
    </Box>
  );
};
