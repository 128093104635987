import React, { FC } from 'react';
import { NewOrderPartyType } from 'types/order';

import usePoaTab from './tab-poa-hooks';
import PoaTabScene from './tab-poa-scene';

type PoaTabProps = {
  partyId: string;
  roleParty: string;
  poa: NewOrderPartyType | undefined;
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>;
};
const PoaTab: FC<PoaTabProps> = ({
  partyId,
  roleParty,
  poa,
  setLoaderActive,
}) => {
  const {
    t, parties, setSearchText, setPoatID, orderId, deletePoa, methods
  } = usePoaTab(partyId, roleParty, setLoaderActive);

  const handleSearch = (text: string): void => setSearchText(text);

  const handleDeletePoa = () => {
    if (orderId && poa) {
      setLoaderActive(true);
      deletePoa({
        id: orderId,
        partyId: poa._id,
        kind: 'Power of Attorney',
      });
    }
  };

  return (
    <PoaTabScene
      t={t}
      handleSearch={handleSearch}
      parties={parties}
      setPoatID={setPoatID}
      poa={poa}
      handleDeletePoa={handleDeletePoa}
      methods={methods}
    />
  );
};

export default PoaTab;
