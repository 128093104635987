import { TFunction } from 'react-i18next';
import { WorkspaceRoutes } from 'types/common';

type chargesMenuItemType ={
	view:string;
	param:string;
	onClick:() => void;
	title:string
}

const HUDCharges = (t:TFunction, handleChange:(view: WorkspaceRoutes, param?: string) => void):chargesMenuItemType[] => [
  {
    view: 'charges',
    param: 'j-k',
    title: t('charges:summary-jk'),
    onClick: () => { handleChange('charges', 'j-k'); }
  },
  {
    view: 'charges',
    param: 'l',
    title: t('charges:settlement-charges'),
    onClick: () => { handleChange('charges', 'l'); }
  }
];

const closingDisclosuresCharge = (t:TFunction, handleChange:(view: WorkspaceRoutes, param?: string) => void):chargesMenuItemType[] => [
  {
    view: 'charges',
    param: 'a',
    title: t('charges:origination-charges'),
    onClick: () => { handleChange('charges', 'a'); }
  },
  {
    view: 'charges',
    param: 'b',
    title: t('charges:did-not-shop-for'),
    onClick: () => { handleChange('charges', 'b'); }
  },
  {
    view: 'charges',
    param: 'c',
    title: t('charges:did-shop-for'),
    onClick: () => { handleChange('charges', 'c'); }
  },
  {
    view: 'charges',
    param: 'e',
    title: t('charges:taxes-and-fees'),
    onClick: () => { handleChange('charges', 'e'); }
  },
  {
    view: 'charges',
    param: 'f',
    title: t('charges:prepaids'),
    onClick: () => { handleChange('charges', 'f'); }
  },
  {
    view: 'charges',
    param: 'g',
    title: t('charges:escrow'),
    onClick: () => { handleChange('charges', 'g'); }
  },
  {
    view: 'charges',
    param: 'h',
    title: t('charges:other-charges'),
    onClick: () => { handleChange('charges', 'h'); }
  },
  {
    view: 'charges',
    param: 'j',
    title: t('charges:lender-credits'),
    onClick: () => { handleChange('charges', 'j'); }
  },
  {
    view: 'charges',
    param: 'k-m',
    title: t('charges:debits-and-credits'),
    onClick: () => { handleChange('charges', 'k-m'); }
  },
  {
    view: 'charges',
    param: 'l-n',
    title: t('charges:debits-and-credits'),
    onClick: () => { handleChange('charges', 'l-n'); }
  }
];

export const getOrderCharges = (t:TFunction, OrderType:string, handlechange:(view: WorkspaceRoutes, param?: string) => void) => {
  switch (OrderType) {
    case 'HUD 1':
      return HUDCharges(t, handlechange);
    case 'Closing Disclosure':
      return closingDisclosuresCharge(t, handlechange);
    default:
      return closingDisclosuresCharge(t, handlechange);
  }
};
