import { useMutation } from 'react-query';
import { getInferenceByAudio } from 'services/inference/getInferenceByAudio';

export const useInferenceAudio = () => {
  const [getInferenceAudio, { isLoading }] = useMutation(getInferenceByAudio);

  return {
    getInferenceAudio,
    isLoading
  };
};
