import {
  Avatar,
  Box,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { infoUpdateType } from 'types/notifications';

const useStyles = makeStyles((theme: any) => ({
  popoverContainer: {
    minWidth: '200px',
    width: '100%',
    maxHeight: '350px',
    overflow: 'scroll',
  },
  emptyTitle: {
    padding: '15px',
    textAlign: 'center',
    fontSize: '16px',
  },
  notificationContainer: {
    cursor: 'pointer',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    padding: '15px',
    '&:nth-child(n + 2)': { borderTop: `1px solid ${theme.palette.secondary.main}`, },
  },
  textContainer: {
    display: 'flex',
    gap: '5px',
  },
  fontBold: {
    fontSize: '14px',
    fontWeight: 900,
    color: theme.palette.primary.darker,
  },
  fontThin: {
    fontSize: '14px',
    fontWeight: 100,
    color: theme.palette.primary.darker,
  },
  textHours: {
    marginTop: '5px',
    fontSize: '12px',
    fontWeight: 100,
    color: theme.palette.primary.darker,
  },
}));

const getInitials = (fullName: string) => {
  const words = fullName.trim().split(' ');

  const initials = words[0][0] + words[words.length - 1][0];

  return initials.toUpperCase();
};

const getElapsedTime = (timestamp: number, t: TFunction) => {
  const currentDate = new Date();
  const differenceInMS = currentDate.getTime() - timestamp;

  const minutesPassed = Math.floor(differenceInMS / (1000 * 60));

  if (minutesPassed < 1) {
    return t('notifications:just-now');
  }

  if (minutesPassed > 60) {
    const hoursPassed = Math.floor(differenceInMS / (1000 * 60 * 60));
    return `${hoursPassed} ${t('notifications:hours')}`;
  }

  return `${minutesPassed} ${t('notifications:minutes')}`;
};

type NotificationsPopupProps = {
  openNotifications: boolean;
  anchorNotifications: HTMLButtonElement | null;
  handleCloseNotifications: () => void;
  notifications: infoUpdateType[];
  t: TFunction;
};

const NotificationsPopup: FC<NotificationsPopupProps> = ({
  openNotifications,
  anchorNotifications,
  handleCloseNotifications,
  notifications,
  t,
}) => {
  const classes = useStyles();

  const sortNotifications = notifications
    .slice()
    .reverse()
    .map((notification) => ({
      ...notification,
      viewed: true,
    }));

  return (
    <Popover
      open={openNotifications}
      anchorEl={anchorNotifications}
      onClose={handleCloseNotifications}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box className={classes.popoverContainer}>
        {notifications.length > 0 ? (
          sortNotifications.map((notification) => (
            <Box
              key={notification.timestamp}
              className={classes.notificationContainer}
            >
              <Avatar>{getInitials(notification.name)}</Avatar>
              <Box>
                <Typography className={classes.textContainer}>
                  <span className={classes.fontBold}>{notification.name}</span>
                  <span className={classes.fontThin}>
                    {t('notifications:just')}
                  </span>
                  <span className={classes.fontBold}>
                    {t(`notifications:${notification.kind}`)}
                  </span>
                </Typography>
                <Typography className={classes.textHours}>
                  {getElapsedTime(notification.timestamp, t)}
                </Typography>
              </Box>
            </Box>
          ))
        ) : (
          <Typography className={classes.emptyTitle}>
            {t('notifications:empty-history')}
          </Typography>
        )}
      </Box>
    </Popover>
  );
};

export default NotificationsPopup;
