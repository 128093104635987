import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  textCenter: { textAlign: 'center' },
  titleStyle: {
    textTransform: 'none',
    color: theme.palette.tab.offselected,
  },
  centerIcon: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignContent: 'center',
  },
  completedStatus: { backgroundColor: '#FB8C00', },
  pendingStatus: { backgroundColor: '#FFBD45' },
  iconSize: {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    color: theme.palette.common.black,
  },
}));

type MilestoneItemProps = {
  title: string,
  onClick?: () => void,
  iconClassName?: string
  status?: boolean,
  icon?: string,
  selectedMilestone?: string,
  optionKey?: string,
} & ({
  iconContent: React.ReactNode,
} | {
  iconContent?: React.ReactNode,
  icon: string,
  selectedMilestone: string,
  status?: boolean,
  optionKey: string,
})

export const MilestoneItem: FC<MilestoneItemProps> = ({
  title,
  status = false,
  icon,
  onClick,
  selectedMilestone,
  optionKey,
  iconContent,
  iconClassName
}) => {
  const classes = useStyles();
  const iconButtonClassName = iconClassName ?? clsx(classes.centerIcon, {
    [classes.completedStatus]: selectedMilestone === optionKey,
    [classes.pendingStatus]: selectedMilestone !== optionKey,
  });

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
      <Grid item className={classes.textCenter}>
        <Typography variant="caption" className={classes.titleStyle}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          onClick={onClick}
          className={iconButtonClassName}
        >
          {iconContent ?? (!status ? (
            <FontAwesomeIcon icon={icon as IconProp} className={classes.iconSize} />
          ) : <FontAwesomeIcon icon="check" className={classes.iconSize} />)}
        </IconButton>
      </Grid>
    </Grid>
  );
};
