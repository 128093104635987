import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CreateCSSProperties, CSSProperties } from '@material-ui/core/styles/withStyles';

type StylesProps = {
  buttonBackgroundColor: CSSProperties['backgroundColor'],
  buttonHoverColor: CSSProperties['backgroundColor'],
  buttonBorderColor: CSSProperties['backgroundColor'],
  textColor?: CSSProperties['backgroundColor'],
  width?: CSSProperties['width']
}

export const useStyles = makeStyles((theme: Theme) => {
  const classes = createStyles({
    text: {
      textTransform: 'none',
      color: (props: StylesProps) => (props.textColor ?? theme.palette.primary.main),
      fontWeight: 500,
      textAlign: 'center'
    },
    icon: {
      fontSize: '16px',
      marginRight: theme.spacing(1),
    },
    button: (props: StylesProps) => {
      let styles: CreateCSSProperties = {};
      if (props.buttonBorderColor) {
        styles.borderColor = props.buttonBorderColor;
      }

      if (props.buttonBackgroundColor) {
        styles = {
          ...styles,
          backgroundColor: props.buttonBackgroundColor,
          '&:hover': {
            backgroundColor: props.buttonHoverColor ?? props.buttonBackgroundColor,
            color: props.textColor ?? theme.palette.primary.main,
          },
        };
      }

      if (props.width) {
        styles.width = props.width;
      }

      return styles;
    }
  });

  return classes;
});
