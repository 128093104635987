import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import NumberField from 'components/number-field';
import React, { FC } from 'react';

import { PartyList, Position } from './hooks';

type ListPartyProps = {
  list: PartyList[];
  startDrag: (e, item) => void;
  dragInOver?: (e) => void;
  onDrop?: (e, section) => void;
  kind: Position;
  removeParty?: (partyID: string) => void;
  changePorcentageParty?: (partyID: string, e) => void;
};

const useStyles = makeStyles((theme: any) => ({
  listContainer: {
    height: '150px',
    overflow: 'scroll',
    width: '100%',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'grab',
    margin: '0 10px 10px 10px',
    padding: '5px 0 10px 5px',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    justifyContent: 'space-between',
  },
  typographyContainer: {
    width: '40%',
    color: theme.palette.primary.dark,
  },
  inputContainer: { width: '40%', },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  titleEmpty: { color: theme.palette.primary.dark, },
}));

const ListParty: FC<ListPartyProps> = ({
  list,
  startDrag,
  dragInOver,
  onDrop,
  kind,
  removeParty,
  changePorcentageParty,
}) => {
  const classes = useStyles();
  const filterList = list.filter((item) => item.position === kind);

  return (
    <div
      className={classes.listContainer}
      data-droppable="true"
      onDragOver={(e) => (dragInOver ? dragInOver(e) : '')}
      onDrop={(e) => (onDrop ? onDrop(e, kind) : '')}
    >
      {filterList.length > 0 ? (
        filterList.map((item) => (
          <Box
            key={item._id}
            draggable
            className={classes.itemContainer}
            onDragStart={(e) => startDrag(e, item)}
          >
            {removeParty && (
              <IconButton size="small" onClick={() => removeParty(item._id)}>
                <FontAwesomeIcon icon="times" />
              </IconButton>
            )}
            <Typography
              variant="subtitle2"
              className={classes.typographyContainer}
            >
              {item.name}
            </Typography>
            {removeParty && changePorcentageParty && (
              <NumberField
                className={classes.inputContainer}
                decimalScale={2}
                isAllowed={({ floatValue = 0 }) => floatValue <= 100}
                onChange={(e) => changePorcentageParty(item._id, e)}
                value={item.porcentage}
                label="percentage"
              />
            )}
          </Box>
        ))
      ) : (
        <div className={classes.emptyContainer}>
          <Typography className={classes.titleEmpty} variant="body1">
            No parties
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ListParty;
