import useSearchParty from 'hooks/useSearchParty';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { generateColumns } from './services';
import { ContactKind } from './types';

const useContacts = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [detailsDesktop, setDetailsDesktop] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [contactKind, setContactKind] = useState<ContactKind>('Person');
  const [searchText, setSearchText] = useState<string | undefined>();
  const [displayMode, setDisplayMode] = useState<'list' | 'grid'>('grid');

  const { parties } = useSearchParty(searchText);

  return {
    t,
    history,
    parties,
    searchText,
    setSearchText,
    displayMode,
    setDisplayMode,
    columns: generateColumns(t),
    createModalOpen,
    setCreateModalOpen,
    detailsDesktop,
    setDetailsDesktop,
    contactKind,
    setContactKind
  };
};

export default useContacts;
