import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentOrder } from 'redux/order-reducer/selectors';
import { ProjectedPaymentsType } from 'types/disclosures';
import useAlert from 'utils/alert';
import { FORMIK_PERSIST_PROJECTED_PAYMENTS } from 'utils/constants';

type ShowFormAlertType = boolean | null;
type DidOrderLoadedType = boolean;

const FormContext = () => {
  const showAlert = useAlert();
  const currentOrder = useSelector(getCurrentOrder);
  const { t } = useTranslation();
  const { setValues, values } = useFormikContext<ProjectedPaymentsType>();
  const [showFormAlert, setShowFormAlert] = useState<ShowFormAlertType>(false);
  const [didOrderLoadead, setDidOrderLoaded] = useState<DidOrderLoadedType>(false);

  useEffect(() => {
    const localStorageValues = localStorage.getItem(FORMIK_PERSIST_PROJECTED_PAYMENTS);
    setShowFormAlert(null);
    setDidOrderLoaded(true);

    if (localStorageValues) {
      const parse = JSON.parse(localStorageValues);
      if (parse.values.projected_payments) {
        setValues(parse.values.projected_payments);
        return;
      }
    }

    if (currentOrder.disclosures?.projected_payments) {
      setValues(currentOrder.disclosures?.projected_payments);
    }
  }, [currentOrder, setValues]);

  // Takes inputs that need to be alerted when changed
  const { payments, estimated_taxes } = values;
  const {
    year_range_from,
    year_raneg_to,
    principal_interest_amount,
    mortgage_insurance,
    estimated_escrow,
    principal_interest_min,
    principal_interest_max,
  } = payments;
  const { amount } = estimated_taxes;

  useEffect(() => {
    if (showFormAlert) {
      setShowFormAlert(false);
      showAlert(t('projectedPayments:formChanged'), 'warning');
    } else if (showFormAlert === null) {
      setShowFormAlert(true);
    }
    // This useEffect must no run when neither showFormAlert or showAlert changes due to its logic. Next line disables eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    year_range_from,
    year_raneg_to,
    principal_interest_amount,
    mortgage_insurance,
    estimated_escrow,
    principal_interest_min,
    principal_interest_max,
    amount,
    setShowFormAlert,
    t,
    didOrderLoadead,
  ]);

  return null;
};

export default FormContext;
