import { Box, makeStyles } from '@material-ui/core';
import OnboardingBackground from 'assets/images/onboarding-background.png';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${OnboardingBackground})`,
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'relative',
    padding: 0,
    margin: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));

type OnboardingLayoutProps = {
  children: React.ReactNode;
};

const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {children}
    </Box>
  );
};

export default OnboardingLayout;
