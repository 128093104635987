import { Divider, Grid, makeStyles } from '@material-ui/core';
import { PaperCard } from 'components/cards/paper-card';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { BalancingItem } from './components/balancing-item';

const useStyles = makeStyles((theme: any) => ({ divider: { backgroundColor: theme.palette.tab.offselected, opacity: 0.5 } }));

type BalancingSceneProps = {
  t: TFunction,
  postedReceipts: number,
  expectedReceipts: number,
  postedDisbursements: number,
  lenderOwes: number,
  borrowerOwes: number,
  earnestOwed: number,
  sellerDue: number,
  othersDue: number,
  balanced: number
}

export const BalancingScene: FC<BalancingSceneProps> = ({
  t,
  postedReceipts,
  expectedReceipts,
  postedDisbursements,
  lenderOwes,
  borrowerOwes,
  earnestOwed,
  sellerDue,
  othersDue,
  balanced
}) => {
  const classes = useStyles();
  return (
    <PaperCard title={t('accounting:balancing')}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <BalancingItem
            title={t('accounting:posted-receipts')}
            value={postedReceipts}
          />
        </Grid>
        <Grid item>
          <BalancingItem
            title={t('accounting:expected-receipts')}
            value={expectedReceipts}
          />
        </Grid>
        <Grid item>
          <BalancingItem
            title={t('accounting:posted-disbursements')}
            value={postedDisbursements}
            isNegativeIncome
          />
        </Grid>
        <Grid item>
          <BalancingItem
            title={t('accounting:lender-owes')}
            value={lenderOwes}
            isPositiveIncome
          />
        </Grid>
        <Grid item>
          <BalancingItem
            title={t('accounting:borrower-owes')}
            value={borrowerOwes}
            isPositiveIncome
          />
        </Grid>
        <Grid item>
          <BalancingItem
            title={t('accounting:earnest-owed')}
            value={earnestOwed}
            isPositiveIncome
          />
        </Grid>
        <Grid item>
          <BalancingItem
            title={t('accounting:seller-due')}
            value={sellerDue}
            isNegativeIncome
          />
        </Grid>
        <Grid item>
          <BalancingItem
            title={t('accounting:others-due')}
            value={othersDue}
            isNegativeIncome
          />
        </Grid>
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item>
          <BalancingItem
            title={t('acounting:balanced')}
            value={balanced}
          />
        </Grid>
      </Grid>
    </PaperCard>
  );
};
