import Actionbuttons from 'components/common/actions-buttons';
import TableComponent from 'components/Table';
import { ListingEntry } from 'graphql/listings/queries';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Interest } from 'v2-types/order';

import { ModalData } from '../../../../types';
import { getInterestTextInfo } from '../../utils/getInterestTextInfo';

type Props = {
  data: Interest[],
  onShowModal: (data: ModalData) => void,
  kindListing: ListingEntry[]
}

export const InterestsTable: FC<Props> = ({
  data,
  onShowModal,
  kindListing
}) => {
  const { t } = useTranslation();

  return (
    <TableComponent
      disabledBorderLeft
      cellAlignment="left"
      columns={{
        actions: {
          header: { title: t('accounting:actions'), width: '50px' },
          renderer: ({ row }) => (
            <Actionbuttons
              handleDelete={() => onShowModal({ modal: 'deleteInterest', interestId: row._id })}
            />
          )
        },
        kind: {
          header: { title: 'Kind', align: 'left' },
          renderer: ({ row }) => <>{kindListing.find(kind => kind.code === row.kind)?.description ?? "" }</>,
        },
        data: { renderer: ({ row }) => getInterestTextInfo(row) }
      }}
      dataSet={data || []}
    />
  );
};
