import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoanFormType } from 'views/orders/loans/types';

import { RadioButtonForm } from '../radio-button-form';

type Props = {
  control: Control<LoanFormType, object>,
  setValue: UseFormSetValue<LoanFormType>,
  isDataLoading: boolean,
}

export const EscrowAccountForm: FC<Props> = ({ control, setValue, isDataLoading }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="escrow"
      render={({ field }) => (
        <Box display="flex" flexDirection="column" flex={1}>
          <RadioButtonForm
            title={t('loanDisclosures:escrow-account')}
            description={t('loanDisclosures:escrow-account-subtitle')}
            value={field.value}
            options={[
              {
                label: t('loanDisclosures:escrow-account-option-1'),
                disabled: isDataLoading,
                value: true
              },
              {
                label: t('loanDisclosures:escrow-account-option-2'),
                disabled: isDataLoading,
                value: false
              }
            ]}
            onChange={(value) => {
              const hasEscrow = value === 'true';
              if (hasEscrow) setValue("escrow_declined", null);
              field.onChange(hasEscrow);
            }}
          />
          {field.value === false && (
            <Controller
              control={control}
              name="escrow_declined"
              render={({ field: escrowDeclinedField }) => (
                <RadioButtonForm
                  description={t('loanDisclosures:escrow-account-subtitle-will-not')}
                  value={escrowDeclinedField.value}
                  options={[
                    {
                      label: t('loanDisclosures:escrow-account-option-1-will-not'),
                      disabled: isDataLoading,
                      value: true
                    },
                    {
                      label: t('loanDisclosures:escrow-account-option-2-will-not'),
                      disabled: isDataLoading,
                      value: false
                    }
                  ]}
                  onChange={(value) => escrowDeclinedField.onChange(value === 'true')}
                />
              )}
            />
          )}
        </Box>
      )}
    />
  );
};
