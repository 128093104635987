import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import SelectField from 'components/select-field';
import React, { FC, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { DocumentsType } from 'v2-types/order';

import useStyles from '../styles';
import { SelectedFilesType } from '../types';

type MergeDocumentsModalType = {
  handleClose: (id: string) => void;
  selectedFiles: SelectedFilesType[];
  documents: DocumentsType[];
  handleupdateFile: (
    description: undefined | string,
    fileId: string,
    parts: string[]
  ) => void;
};

const MergeDocumentsModal: FC<MergeDocumentsModalType> = ({
  handleClose,
  selectedFiles,
  documents,
  handleupdateFile,
}) => {
  const [selectedFilesSorted, setSelectedFilesSorted] = useState<SelectedFilesType[]>(selectedFiles);

  const [docId, setDocId] = useState('');

  const docsFilter = documents.filter(
    (doc) => doc.kind.includes('pdf') || doc.kind.includes('zip')
  );

  const handleRemoveSelectedFile = (id: string) => {
    setSelectedFilesSorted(
      selectedFilesSorted.filter((file) => file.id !== id)
    );
  };

  const { mergedDocumentsText } = useStyles();

  return (
    <Dialog open onClose={() => handleClose('')}>
      <DialogTitle>Merge Documents</DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <List dense component="div" role="list">
              <ReactSortable
                list={selectedFilesSorted}
                setList={setSelectedFilesSorted}
              >
                {selectedFilesSorted.map((file) => (
                  <ListItem key={file.id} button>
                    <ListItemAvatar>
                      <Avatar>
                        <FontAwesomeIcon icon="file" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className={mergedDocumentsText}
                      primary={file.name}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => handleRemoveSelectedFile(file.id)}
                      >
                        <FontAwesomeIcon icon="times" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </ReactSortable>
            </List>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              value={docId}
              handleChange={(e) => setDocId(e.target.value)}
              label="Document to merge"
              data={docsFilter}
              dataKey="_id"
              dataValue="_id"
              dataText="description"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                onClick={() => {
                  const IDDocs = selectedFilesSorted.map((doc) => doc.id);
                  handleupdateFile(undefined, docId, IDDocs);
                  handleClose('');
                }}
                disabled={docId === '' || selectedFilesSorted.length === 0}
                variant="contained"
                color="secondary"
              >
                Merge Documents
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MergeDocumentsModal;
