import React, { FC } from 'react';

import useModalCreateOrdersHook from './modal-create-orders-hooks';
import ModalCreateOrdersScene from './modal-create-orders-scene';

type ModalCreateOrdersProps = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onUploadDocument: (param:any)=>void
};

const ModalCreateOrders: FC<ModalCreateOrdersProps> = ({ open, onClose, onCancel, onUploadDocument }) => {
  const {
    t,
    methods,
    transactionTypes,
    types,
    workflows,
    selectTransactionType,
    handleOnSubmit
  } = useModalCreateOrdersHook(onCancel);

  return (
    <ModalCreateOrdersScene
      t={t}
      open={open}
      methods={methods}
      transactionTypes={transactionTypes}
      types={types}
      workflows={workflows}
      onClose={onClose}
      onCancel={onCancel}
      selectTransactionType={selectTransactionType}
      handleOnSubmit={handleOnSubmit}
      onUploadDocument={onUploadDocument}
    />
  );
};

export default ModalCreateOrders;
