import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, Menu, MenuItem, Typography
} from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { sliceString } from 'views/notes/utils';

import { NoteType } from '../../types';
import { useStyles } from './styles';

type NoteProps = {
  note: NoteType,
  isSelected: boolean,
  actionsDisabled: boolean,
  selectNote: () => void,
  removeNote: (id: string) => void,
  copyNote: (text: string) => void
}

export const Note: FC<NoteProps> = ({
  note,
  isSelected,
  actionsDisabled,
  selectNote,
  removeNote,
  copyNote
}) => {
  const classes = useStyles({ isSelected, actionsDisabled });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  return (
    <Grid
      container
      className={classes.noteContainer}
      onClick={() => !actionsDisabled && selectNote()}
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid item>
        <Typography variant="body2">{sliceString({ text: note.text, maxLength: 75 })}</Typography>
      </Grid>
      <Grid item>
        <IconButton
          aria-controls={`long-menu-${note.id}`}
          aria-haspopup="true"
          disabled={actionsDisabled}
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
          size="small"
        >
          <FontAwesomeIcon icon="ellipsis-v" />
        </IconButton>
        <Menu
          id={`long-menu-${note.id}`}
          anchorEl={anchorEl}
          keepMounted={false}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            key="delete-option"
            onClick={(e) => {
              e.stopPropagation();
              removeNote(note.id);
              setAnchorEl(null);
            }}
          >
            {t('common:delete')}
          </MenuItem>
          <MenuItem
            key="copy-option"
            onClick={(e) => {
              e.stopPropagation();
              copyNote(note.text);
              setAnchorEl(null);
            }}
          >
            {t('common:copy-text')}
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};
