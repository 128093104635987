import { useMemo, useState } from 'react';
import { listingItemtype } from 'types/ledger';

const useSearchEndorsement = (endorsements: listingItemtype[]) => {
  const [search, setSearch] = useState('');

  const formated = useMemo(() => endorsements.map((e) => (
    { ...e, description: `${e.code} - ${e.description}` }
  )), [endorsements]);

  const filtered = useMemo(() => (search.trim() === '' ? formated : (
    formated.filter((e) => e.description.toLowerCase().includes(search.toLowerCase()))
  )), [formated, search]);

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return {
    search,
    endorsements: filtered,
    handleOnChange,
  };
};

export default useSearchEndorsement;
