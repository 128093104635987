import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { AccountingType } from 'v2-types/order';

import { chargesSorted } from '.';
import { getOrder } from './querys';

const useChargesBC = (letter:string) => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const [disclosure, setDislosure] = useState<string | null>(null);
  const showAlert = useAlert();
  const { isLoading, isFetching } = useQuery(['accounting-order', orderId],
    () => getOrder(orderId),
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
	  onSuccess: (localdata) => {
        if (localdata) {
          setDislosure(localdata.getOrder.lenders_policy.disclosure_section || null);
          setAccounting(chargesSorted(localdata.getOrder.accounting?.filter((asc) => asc.letter === letter && asc.number !== null) || []));
        }
      }
    },);
  const handleSetAccounting = useCallback((accountingData: AccountingType[]) => {
    setAccounting(chargesSorted(accountingData.filter((asc) => asc.letter === letter && asc.number !== null)));
  }, [letter]);

  const handleSetDisclosure = useCallback((disclosureLetter:string) => {
    setDislosure(disclosureLetter);
  }, []);

  useEffect(() => {
    if (isFetching && !isLoading) {
      showAlert('Updating...', 'info');
    }
  }, [isFetching, showAlert, isLoading]);

  return {
    t,
    isLoading,
    accounting,
    disclosure,
    handleSetAccounting,
    handleSetDisclosure
  };
};
export default useChargesBC;
