/* eslint-disable radix */

import { generateRandomString } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

const generateGenericAccounting = (size:number, startNumber:number, letter:string) :AccountingType[] => Array.from(Array(size).keys()).map((_, index) => ({
  _id: generateRandomString(10),
  amount: 0,
  amount_calculated: 0,
  amount_override: 0,
  code: 'charge',
  kind: 'manual',
  letter,
  number: startNumber + index,
  description: '',
  involved_parties: [],
  entry_date: '',
  months: null,
  per_month: null,
}));

const fillCharges = (acc:AccountingType[], from:number, to:number, size:number, letter:string) => {
  const filteredAccounting = acc?.filter((a) => a.letter?.includes(letter));
  const numbersToInclude = Array.from({ length: (to - from) / 1 }, (value, index) => from + index * 1);
  const filteredCharges = filteredAccounting.filter((f) => numbersToInclude.some((s) => parseInt(f.number as unknown as string) === s));
  if (filteredCharges.length < size) {
    return [
      ...generateGenericAccounting(size, from, letter).map((genericChharge) => {
        const findedCharge = filteredCharges.find((charge) => parseInt(charge.number as unknown as string) === genericChharge.number);
        if (findedCharge) {
          return findedCharge;
        }
        return genericChharge;
	  })
    ];
  }
  return filteredCharges;
};
export const formatDataKM = (accountingData: AccountingType[]) => accountingData.map((f) => ({
  id: f._id,
  description: f.description,
  chargeData: f,
  number: f.number
}));

export const mockData = (accounting:AccountingType[]) => ({
  kdues: formatDataKM(fillCharges(accounting, 1, 5, 4, 'K')),
  kAdjustments: formatDataKM(fillCharges(accounting, 5, 8, 3, 'K')),
  kAdjustmentsItems: formatDataKM(fillCharges(accounting, 8, 16, 8, 'K')),
  ldues: formatDataKM(fillCharges(accounting, 1, 9, 8, 'M')),
  ladjustments: formatDataKM(fillCharges(accounting, 9, 17, 8, 'M')),
  laddendums: formatDataKM(accounting.filter((f) => f.letter?.includes('M') && f.number === null && f.kind === 'manual')),
  kaddendums: formatDataKM(accounting.filter((f) => f.letter?.includes('K') && f.number === null && f.kind === 'manual'))
});
