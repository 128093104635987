import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { AccountingType, CashToCloseEntry } from 'v2-types/order';

type GetCashToCloseParams = {
  orderId: string,
  filters?: {
    accounting: 'closing'
  }
}

export type GetCashToCloseReturn = {
  getOrder: {
    configuration: {
      transaction_type: string
    },
    cash_to_close: CashToCloseEntry[],
    accounting: AccountingType[]
  }
}

export const getCashToClose = async (payload: GetCashToCloseParams) => {
  const query = gql`
    query GetOrder($orderId: ID!, $filters: OrderFilters) {
      getOrder(_id: $orderId, filters: $filters) {
        configuration {
          transaction_type
        }
        cash_to_close {
          changed
          changed_text
          entry_code
          estimate_amount
        }
        accounting {
          _id
          amount
          code
          description
          kind
        }
      }
    }
  `;
  const response = await graphQLClient().request<GetCashToCloseReturn, GetCashToCloseParams>(query, {
    ...payload,
    filters: { accounting: 'closing' }
  });
  return response;
};
