import axios from 'axios';
import { Buffer } from 'buffer';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * @deprecated this function will be replaced wen all the pending screens are migrated.
 */
export const validateToken = async (_: any, token: string) => {
  const { data } = await axios.get(`${API_BASE_URL}v1/token_validation/${token}`);
  return data;
};

export default {
  validateToken,
};
