import {
  Box,
  Grid,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { BaseButton } from 'components/BaseButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LineItems from '../line-items/Line-Items';
import InformationRow from './components/information-row';
import PartiesMortgage from './components/parties-mortgage';
import PayOffInfo from './components/payoff-info-form';
import TitleSection from './components/title-section';
import useMortgageDetails from './mortgage-details-hooks';

const useStyles = makeStyles((theme:any) => ({
  title: {
    color: theme.palette.tab.offselected,
    marginTop: theme.spacing(1)
  },
  containerBasingInfo: { padding: theme.spacing(3) },
  containerBackground: { backgroundColor: theme.palette.offBackground.default },
}));
const MortgageDetail = () => {
  const {
    currentMortgage,
    deleteMortgageMutation,
    deleteMortgageResponse,
    id,
  } = useMortgageDetails();
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="column" className={classes.containerBasingInfo} spacing={3}>
      <Grid item>
        <BaseButton
          text="Delete Mortgage"
          icon="trash"
          onClick={() => { deleteMortgageMutation({ id, mortgageId: currentMortgage?._id! }); }}
          isLoading={deleteMortgageResponse.isLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <TitleSection title="Loan Information">
          <Box display="flex" flex="1" flexDirection="column" className={clsx(classes.containerBasingInfo, classes.containerBasingInfo)}>
            <InformationRow title="Loan Number" value={currentMortgage?.loan_number || ''} />
            <InformationRow title="Property Selected" value={currentMortgage?.estate.address.address || ''} />
          </Box>
        </TitleSection>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item xs={4}>
            <TitleSection title="Parties">
              <PartiesMortgage currentMortgage={currentMortgage} />
            </TitleSection>
          </Grid>
          <Grid item xs={8}>
            <TitleSection title={t('payoffs:recording_information')}>
              <PayOffInfo currentMortgage={currentMortgage} />
            </TitleSection>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TitleSection title="Line Items">
          <LineItems
            accounting={currentMortgage?.accounting || []}
            mortgateId={currentMortgage?._id!}
          />
        </TitleSection>
      </Grid>
    </Grid>
  );
};

export default MortgageDetail;
