import axios from 'axios';

const AUTH_DOMAIN_URL = process.env.REACT_APP_Auth_0_Domain;

const URL = process.env.REACT_APP_API_URL;

export const login = async (payload: any) => {
  const { data } = await axios.post(`https://${AUTH_DOMAIN_URL}/oauth/token`, payload);
  return data;
};

export const resetPassword = async (payload: any) => {
  const { data } = await axios.post(
    `${URL}/users/reset`,
    payload
  );
  return data;
};

export default { login, resetPassword };
