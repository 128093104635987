import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useStyles } from '../../styles';
import { LoanFormType } from '../../types';
import { AmountKindForm } from '../amount-kind-form';
import { MoneyField } from '../moneyField';

type Props = {
  isDataLoading: boolean,
  loanForm: UseFormReturn<LoanFormType, object>
}

export const LoanAmountAndAmountKind: FC<Props> = ({
  isDataLoading,
  loanForm
}) => {
  const classes = useStyles();
  const { control } = loanForm;

  return (
    <Box style={{ display: 'flex', gap: '20px', alignItems: 'flex-end' }}>
      <Box style={{ flex: 1 }}>
        <MoneyField
          control={control}
          name="loanAmountEntry.amount"
          label="Loan Amount"
          disabled={isDataLoading}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Typography variant="h6" className={classes.subTitle}>
          Amount Kind
        </Typography>
        <AmountKindForm
          loanForm={loanForm}
          isLoanLoading={isDataLoading}
        />
      </Box>
    </Box>
  );
};
