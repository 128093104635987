import IconWithTooltip from 'components/common/icon-with-tootip';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useAlert from 'utils/alert';

import { useLocalBlobImage } from '../hooks/useLocalBlobImage';

const { ClipboardItem } = window;

type ItemTagImageOptionProps = {
  tagId: string;
};

export const ItemTagImageOption: FC<ItemTagImageOptionProps> = ({ tagId }) => {
  const showAlert = useAlert();
  const { t } = useTranslation();
  const { isLoading, image } = useLocalBlobImage(tagId);

  const copyImageTag = useCallback(async () => {
    try {
      if (image) {
        await navigator.clipboard.write([
          new ClipboardItem({ [image.type]: image }),
        ]);
        showAlert(t('documents:image-copied'), 'success');
      }
    } catch (err: any) {
      showAlert(err.message, 'error');
    }
  }, [image]);

  return (
    <IconWithTooltip
      tooltipText={t('documents:copy-image')}
      icon={isLoading ? 'spinner' : 'image'}
      onClick={copyImageTag}
    />
  );
};
