import TableComponent from 'components/Table';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import { disbursementColumns } from '../services';

type disbursementTableProps = {
	data:AccountingType[],
	handleModal:(param: string | null, entryLedger?: AccountingType | null, entryAssociacion?: AccountingInvolvedParty | null) => void,
	handledeleteLink: (order_id: string, paymentRecordId: string, associationKind: string, associationId: string, associationLedgerRow: string) => void,
	handledeleteDisbursement:(accountingId: string) => void
}

const DisbursementTable:FC<disbursementTableProps> = ({
  data,
  handleModal,
  handledeleteLink,
  handledeleteDisbursement
}) => {
  const { t } = useTranslation();
  const columns = disbursementColumns(t, handleModal, handledeleteLink, handledeleteDisbursement);

  return (
    <TableComponent
      disabledBorderLeft
      disableFullborderCell
      columns={columns}
      dataSet={data}
    />
  );
};

export default DisbursementTable;
