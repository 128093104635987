import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import {
  OrderPerKinds,
  TemplateData,
  TemplateTriggers,
} from 'types/auto-templates';

export const createAutoTemplate = async (payload: {
  data: TemplateData;
  code: string;
  name: string;
  per: OrderPerKinds[];
  triggers: TemplateTriggers;
}) => {
  const mutation = gql`
    mutation CreateAutoTemplate(
      $code: String!
      $data: TemplateDataInput!
      $name: String!
      $triggers: TemplateTriggersInput!
      $per: [String!]
    ) {
      createAutoTemplate(
        code: $code
        data: $data
        name: $name
        triggers: $triggers
        per: $per
      ) {
        triggers {
          workflow
          type
          transaction_type
        }
        per
        name
        data {
          signer
          party
          property
          deliver
        }
        code
        _id
      }
    }
  `;

  const response = await graphQLClient().request(mutation, payload);

  return response;
};

export const updateAutoTemplate = async (payload: {
  id: string;
  data: TemplateData;
  name: string;
  per: OrderPerKinds[];
  triggers: TemplateTriggers;
}) => {
  const mutation = gql`
    mutation UpdateAutoTemplate(
      $id: ID!
      $data: TemplateDataInput
      $name: String
      $per: [String!]
      $triggers: TemplateTriggersInput
    ) {
      updateAutoTemplate(
        _id: $id
        data: $data
        name: $name
        per: $per
        triggers: $triggers
      ) {
        triggers {
          workflow
          type
          transaction_type
        }
        per
        name
        data {
          signer
          property
          party
          deliver
        }
        code
        _id
      }
    }
  `;

  const response = await graphQLClient().request(mutation, payload);

  return response;
};

export const deleteAutoTemplate = async (payload: { id: string }) => {
  const mutation = gql`
    mutation DeleteAutoTemplate($id: ID!) {
      deleteAutoTemplate(_id: $id) {
        triggers {
          workflow
          type
          transaction_type
        }
        per
        name
        data {
          signer
          property
          party
          deliver
        }
        code
        _id
      }
    }
  `;

  const response = await graphQLClient().request(mutation, payload);

  return response;
};
