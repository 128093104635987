import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import { TFunction } from 'i18next';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

type HeaderProps = {
  t: TFunction,
  submitForm: (() => Promise<void>) & (() => Promise<any>),
  isLoading: boolean
};

export const Header = ({ t, submitForm, isLoading }: HeaderProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.headerContainer}
    >
      <Grid item>
        <Typography variant="h5" className={classes.headerTitle}>
          {t('disclosure:cash-to-close')}
        </Typography>
      </Grid>

      <Grid item>
        <ContainedButton
          text="Save"
          icon="hdd"
          isLoading={isLoading}
          onClick={submitForm}
        />
      </Grid>
    </Grid>
  );
};
