import { getProrations, GetProrationsReturn } from 'graphql/prorations/queries';
import { QueryConfig, useQuery } from 'react-query';

type Params = {
  orderId: string,
  queryConfig?: QueryConfig<GetProrationsReturn, Error>
}

export const useGetProrations = ({ orderId, queryConfig }: Params) => useQuery([{ orderId }, 'prorations'], {
  ...queryConfig,
  queryFn: getProrations
});
