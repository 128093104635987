import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { useStyles } from './styles';

type Props = {
  label: string,
  value: string,
}

export const LabelWithValue: FC<Props> = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Typography variant="body2">
      <span className={classes.label}>{label}</span>
      {`: ${value}`}
    </Typography>
  );
};
