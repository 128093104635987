import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { GeneralInformationValuesType } from 'components/users/types';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';
import {
  Controller, useForm, useFormContext, UseFormGetValues, useWatch
} from 'react-hook-form';
import { NewEmailType } from 'types/new-user';
import { NewUserType } from 'v2-types/user';
import * as Yup from 'yup';

import TextField from '../text-field';

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
  emptyTitleContainer: { marginBottom: theme.spacing(2) },
  buttonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  buttonText: {
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  iconContainer: { textAlign: 'center' },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: theme.spacing(1),
  },
  createAlertContainer: { marginBottom: theme.spacing(2) },
  createButtonContainer: { marginTop: theme.spacing(2) },
  createButton: {
    height: 30,
    width: '100%',
    textTransform: 'capitalize',
  },
  createButtonIcon: { marginRight: theme.spacing(1) },
}));

type ModalCreateEmailProps = {
  t: TFunction;
  open: boolean;
  add: (data: NewEmailType, onClose?: () => void) => void;
  onClose: () => void;
};

const ModalCreateEmail: FC<ModalCreateEmailProps> = ({
  t,
  open,
  add,
  onClose,
}) => {
  const classes = useStyles();

  const schema = Yup.object({
    address: Yup.string()
      .email(t('validations:invalidEmail'))
      .required(t('validations:required')),
    kind: Yup.string().matches(/^(?!login$).*$/i, {
      message: t('validations:email-kind-invalid'),
      excludeEmptyString: true,
    }).required(t('validations:required')),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NewEmailType>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { address: '', kind: '' },
  });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>
        {t('users:create-email')}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit((data) => add(data, handleOnClose))}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="address"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:email')}
                    error={!!errors.address}
                    helperText={errors.address?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="kind"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    label={t('users:kind')}
                    error={!!errors.kind}
                    helperText={errors.kind?.message || t('validations:required')}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    disableElevation
                    disabled={!isValid}
                    variant="contained"
                    color="secondary"
                    className={classes.buttonText}
                  >
                    {t('users:create')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

type FormEmailsProps = {
  t: TFunction;
  helpers: (getValues: UseFormGetValues<NewUserType>) => GeneralInformationValuesType,
};

const FormEmails: FC<FormEmailsProps> = ({ t, helpers }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const { control, getValues, formState: { errors } } = useFormContext<NewUserType>();
  const user = useWatch({ control });
  const emails = user.emails || [];
  const helpersEmail = helpers(getValues).emails;

  return (
    <>
      {!emails.length && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} className={classes.emptyTitleContainer}>
            <Typography variant="body2" className={classes.title}>
              {t('users:no-emails')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => setOpen(true)}
            >
              {t('users:create')}
            </Button>
          </Grid>
        </Grid>
      )}

      {!!emails.length && (
        <>
          <Box>
            {emails.map((email, index) => (
              <Grid
                key={email._id}
                container
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Controller
                    name={`emails.${index}.address`}
                    control={control}
                    render={({ field: { ref, onBlur, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        disabled={helpersEmail.emails[index].address.disabled}
                        label={t('users:email')}
                        handleBlur={() => {
                          const error = !!errors.emails;

                          if (!error) {
                            helpersEmail.emails[index].address.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.emails && !!errors.emails[index] && !!errors?.emails?.[index]?.address}
                        helperText={errors.emails && errors.emails[index] && errors?.emails?.[index]?.address?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={helpersEmail.emails[index].address.disabled && helpersEmail.emails[index].kind.disabled ? 6 : 5}>
                  <Controller
                    name={`emails.${index}.kind`}
                    control={control}
                    render={({ field: { ref, onBlur, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        disabled={helpersEmail.emails[index].kind.disabled}
                        label={t('users:kind')}
                        handleBlur={() => {
                          const error = !!errors.emails;

                          if (!error) {
                            helpersEmail.emails[index].kind.onBlur!(onBlur);
                          }
                        }}
                        error={!!errors.emails && !!errors.emails[index] && !!errors?.emails?.[index]?.kind}
                        helperText={errors.emails && errors.emails[index] && errors?.emails?.[index]?.kind?.message}
                      />
                    )}
                  />
                </Grid>

                {!helpersEmail.emails[index].address.disabled && !helpersEmail.emails[index].kind.disabled && (
                  <Grid item xs={1} className={classes.iconContainer}>
                    <IconButton
                      className={classes.icon}
                      onClick={() => helpersEmail.remove(email._id || '')}
                    >
                      <FontAwesomeIcon icon="times" size="xs" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>

          <Grid container className={classes.createButtonContainer}>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.createButton}
                onClick={() => setOpen(true)}
              >
                <FontAwesomeIcon
                  icon="plus-square"
                  className={classes.createButtonIcon}
                />

                {t('users:add-new')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <ModalCreateEmail
        t={t}
        open={open}
        add={helpersEmail.add}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default FormEmails;
