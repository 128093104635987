import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import useAlert from 'utils/alert';

import { downloadFile } from '../../services/download';

const convertToBytes = (size: number) => {
  if (size !== null) {
    if (size < 1024) {
      return `${size} bytes`;
    }
    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    }
    if (size < 1024 * 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
    if (size < 1024 * 1024 * 1024 * 1024) {
      return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
    return `${(size / (1024 * 1024 * 1024 * 1024)).toFixed(2)} TB`;
  }
  return '';
};

const unixTimestampToDate = (unixTimestamp: string) => {
  const date = new Date(parseInt(unixTimestamp, 10));
  if (!Number.isNaN(date.getTime())) {
    return date.toLocaleString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  return '';
};

type FilesTableProps = {
  files: any;
  kind: string;
  handleToggleModal: any;
  handleCheckboxChange: any;
  selectedFiles: any;
  documentId: string;
  documentKind?: string;
  handleRemoveArchive?: ({
    documentId,
    archiveId,
    fileId,
  }: {
    documentId: string;
    archiveId: string;
    fileId: string;
  }) => void;
  searchWordClass: string;
};

const FilesTable = ({
  files,
  kind,
  handleToggleModal,
  handleCheckboxChange,
  selectedFiles,
  documentId,
  documentKind,
  handleRemoveArchive,
  searchWordClass,
}: FilesTableProps) => {
  const showAlert = useAlert();

  const handleDownload = async (link, fileName) => {
    const success = await downloadFile(link, fileName);
    if (success) {
      showAlert('Documento Descargado con exito.', 'success');
    } else {
      showAlert(
        'Un error ocurrio al descargar el archivo, intentelo de nuevo.',
        'error'
      );
    }
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {kind !== 'parts' && (
            <TableCell style={{ color: '#000' }}>Party</TableCell>
          )}
          <TableCell style={{ color: '#000' }}>File Name</TableCell>
          <TableCell style={{ color: '#000' }}>Size</TableCell>
          <TableCell style={{ color: '#000' }}>Date</TableCell>
          {kind !== 'parts' && (
            <TableCell style={{ color: '#000' }}>Actions</TableCell>
          )}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file) => (
          <TableRow key={file.source}>
            {kind !== 'parts' && (
              <TableCell style={{ color: '#000' }} className={searchWordClass}>
                {file.name}
              </TableCell>
            )}
            <TableCell style={{ color: '#000' }} className={searchWordClass}>
              {file.filename}
            </TableCell>
            <TableCell style={{ color: '#000' }}>
              {convertToBytes(file.size)}
            </TableCell>
            <TableCell style={{ color: '#000' }}>
              {unixTimestampToDate(file.mtime)}
            </TableCell>
            <TableCell style={{ color: '#000' }}>
              <Box>
                {file.filename
                  && !file.filename.includes('.zip')
                  && kind !== 'parts' && (
                    <IconButton
                      size="small"
                      onClick={() => handleToggleModal(file._id, 'view')}
                    >
                      <FontAwesomeIcon icon="eye" />
                    </IconButton>
                )}

                {kind !== 'parts' && (
                  <IconButton
                    size="small"
                    onClick={() => handleDownload(file.download, file.filename)}
                  >
                    <FontAwesomeIcon icon="download" />
                  </IconButton>
                )}

                {documentKind && documentKind === 'upload' && (
                  <>
                    <IconButton
                      size="small"
                      onClick={() => handleToggleModal(file._id, 'updateArchive', {
                        documentId,
                        archiveId: file.archiveId,
                        fileId: file._id,
                        filename: file.filename.split('.')[0],
                      })}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveArchive
                        && handleRemoveArchive({
                          documentId,
                          archiveId: file.archiveId,
                          fileId: file._id,
                        })}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </IconButton>
                  </>
                )}
                {kind !== 'parts' && (
                  <Checkbox
                    checked={selectedFiles.some(
                      (selectedFile) => selectedFile.id === file.source
                    )}
                    onChange={() => handleCheckboxChange({
                      id: file.source,
                      name: file.filename,
                    })}
                  />
                )}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FilesTable;
