import { capitalize } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import services from 'services/endpoints';
import useAlert from 'utils/alert';

const useUnsuscribe = () => {
  const { t } = useTranslation();
  const { token } = useParams<any>();
  const [unsuscribe, unsuscribeResponse] = useMutation(services.unsuscribe);
  const [showFinalScreen, setShowfinalScreen] = useState(false);
  const showAlert = useAlert();

  const tokenResponse = useQuery(
    ['tokenValidate', token],
    services.validateToken,
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (unsuscribeResponse.isSuccess) {
      unsuscribeResponse.reset();
      showAlert(capitalize(t('dialogs:order-updated')), 'success');
      setShowfinalScreen(true);
    }

    if (unsuscribeResponse.isError) {
      unsuscribeResponse.reset();
      showAlert(capitalize(t('dialogs:there-is-an')), 'error');
    }
  }, [unsuscribeResponse, showAlert, t]);

  return {
    t,
    tokenResponse,
    unsuscribeResponse,
    unsuscribe,
    showFinalScreen,
    setShowfinalScreen
  };
};

export default useUnsuscribe;
