import {
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UpdateOrderSpecific } from 'types/order';

const useTabSignature = (roleParty:string) => {
  const { t } = useTranslation();
  const formMethods = useFormContext<UpdateOrderSpecific>();

  const include_vesting_value = useWatch({
    control: formMethods.control,
    name: `${roleParty === 'Buyer' ? 'data_buyer' : 'data_seller'}.include_vesting`
  });
  const single_signature_methods = useFieldArray({
    control: formMethods.control,
    name: `${roleParty === 'Buyer' ? 'data_buyer' : 'data_seller'}.vesting_block`

  });

  return {
    t,
    include_vesting_value,
    single_signature_methods,
    formMethods
  };
};

export default useTabSignature;
