import {
  Dialog, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import React, { FC } from 'react';

import { useStyles } from './styles';

type DeleteConfirmationModalProps = {
  title: string,
  confirmationMessage: string,
  isOpen: boolean,
  onConfirm: () => void,
  onClose: () => void,
  isRemoveLoading: boolean,
  buttonConfirmText?: string
}

export const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  title,
  confirmationMessage,
  isOpen,
  onConfirm,
  onClose,
  isRemoveLoading,
  buttonConfirmText = 'Delete'
}) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogContent style={{ padding: 0 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item container className={classes.container}>
            <Grid item>
              <Typography variant="body1">
                {confirmationMessage}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <ContainedButton
          text="Close"
          onClick={onClose}
          disabled={isRemoveLoading}
        />
        <ContainedButton
          text={buttonConfirmText}
          isLoading={isRemoveLoading}
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};
