import React from 'react';
import { AccountingType } from 'v2-types/order';

import useAccounting from './accounting-hooks';
import AccountingScene from './accounting-scene';
import { sortedAccounting } from './services';

const Accounting = () => {
  const {
    accounting,
    setAccounting,
    handleDeleteLedgerItem,
    t,
    handleDeleteLedgerParty,
    resolver,
    handledeleteLink,
    state,
    dispatch
  } = useAccounting();

  const handleUpdateLedger = (accountingData:AccountingType[]) => {
    setAccounting(sortedAccounting(accountingData));
  };

  return (
    <AccountingScene
      accountingData={accounting || []}
      handleUpdateLedger={handleUpdateLedger}
      t={t}
      handleDeleteLedgerItem={handleDeleteLedgerItem}
      handleDeleteLedgerParty={handleDeleteLedgerParty}
      resolver={resolver}
      handledeleteLink={handledeleteLink}
      state={state}
      dispatch={dispatch}
    />

  );
};

export default Accounting;
