import { AllowedLedgerListingType } from '../types/ledger';
import gqlRequest from './generic';

export type GetListings = {
  getListings: {
    code: string;
    entries: {
      code: string;
      description: string;
    }[];
  }[];
};

const queryGetListings = `
  query GetListings {
    getListings {
      code
      entries {
        code
        description
      }
    }
  }
`;

export const getListings = () => gqlRequest<GetListings, undefined>(queryGetListings);

type SearchParty = {
  searchParty: {
    _id: string;
    name: string;
    kind: string;
    user_id: string;
  }[];
};

type SearchPartyParams = { query: string };

const querySearchParty = `
  query SearchParty($query: String!) {
    searchParty(query: $query) {
      _id
      name
      kind
      user_id
    }
  }
`;

export const searchPartyBasicInfo = (query: string) => gqlRequest<SearchParty, SearchPartyParams>(querySearchParty, { query });

type GetPartyPayments = {
  getParty: {
    payments: {
      _id: string;
      kind: string;
      reference: string;
    }[];
  };
};

type GetPartyParams = { id: string };

const queryGetPartyPayments = `
  query GetParty($id: ID!) {
    getParty(_id: $id) {
      payments {
        _id
        kind
        reference
      }
    }
  }
`;

export const getPartyPayments = (id: string) => gqlRequest<GetPartyPayments, GetPartyParams>(queryGetPartyPayments, { id });

type GetledgerAllowedListings = {
  getListing: AllowedLedgerListingType
};

type GetLedgerAllowedParams = {code: string };

const queryCodes = `
query GetListing($code: String!) {
  getListing(code: $code) {
    code
    entries {
      code
      description
    }
  }
}
`;
export const getLedgerListings = (code:string) => gqlRequest<GetledgerAllowedListings, GetLedgerAllowedParams>(queryCodes, { code });

export default {};
