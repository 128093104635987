import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import TextField from 'components/text-field';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NewEmailType } from 'types/new-user';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: any) => ({
  formContainer: { marginBottom: theme.spacing(1) },
  emailsContainer: { marginTop: theme.spacing(4) },
}));

type ModalInviteUserProps = {
  t: TFunction;
  open: boolean;
  emails: NewEmailType[];
  onClose: () => void;
  handleCreateLoginEmail: (email: string, onClose: () => void) => void;
};

const ModalInviteUser: FC<ModalInviteUserProps> = ({
  t,
  open,
  emails,
  onClose,
  handleCreateLoginEmail,
}) => {
  const classes = useStyles();

  const schema = Yup.object({ email: Yup.string().email(t('validations:invalidEmail')) });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { email: '' },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog fullWidth open={open} maxWidth="sm" onClose={onClose}>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} className={classes.formContainer}>
            <form onSubmit={handleSubmit((data) => handleCreateLoginEmail(data.email, handleClose))}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={10}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextField
                        {...field}
                        inputRef={ref}
                        label={t('users:email')}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                      <Button
                        type="submit"
                        disabled={watch('email').trim() === '' || !isValid}
                      >
                        {t('users:invite')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>

          {!!(emails || []).length && (
            <Grid item xs={12} className={classes.emailsContainer}>
              {(emails || []).map((email) => (
                <Grid key={email._id} container direction="row" alignItems="center">
                  <Grid item xs={10}>
                    <Typography>{email.address}</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Grid container direction="row" justifyContent="flex-end">
                      <Grid item>
                        <Button onClick={() => handleCreateLoginEmail(email.address, handleClose)}>
                          {t('users:invite')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalInviteUser;
