import React, { FC } from 'react';
import { AccountingType } from 'v2-types/order';

import { useAccountingTotals } from './accounting-totals-hooks';
import { AccountingTotalsScene } from './accounting-totals-scene';

type AccountingTotalsProps = {
  isOpen: boolean,
  onClose: () => void,
  accountingBalance: AccountingType[]
}

export const AccountingTotals: FC<AccountingTotalsProps> = ({
  isOpen,
  onClose,
  accountingBalance
}) => {
  const {
    t,
    totalReceipts,
    allDisbursements,
    funding
  } = useAccountingTotals({ accountingBalance });
  return (
    <AccountingTotalsScene
      isOpen={isOpen}
      onClose={onClose}
      t={t}
      totalReceipts={totalReceipts}
      allDisbursements={allDisbursements}
      funding={funding}
    />
  );
};
