import { getAccountingEntryByLedgerCode } from 'utils/accounting';
import {
  LEDGER_CODE_ADJUSTMENTS_CREDITS,
  LEDGER_CODE_CASH_CLOSE,
  LEDGER_CODE_CASH_DEPOSIT,
  LEDGER_CODE_CASH_LOAN,
  LEDGER_CODE_CASH_PAYOFFS, LEDGER_CODE_CASH_TOTAL, LEDGER_CODE_COSTS_FINANCED, LEDGER_CODE_DOWN_PAYMENT, LEDGER_CODE_FUNDS_BORROWER, LEDGER_CODE_PAID_BEFORE, LEDGER_CODE_SELLER_CREDITS
} from 'utils/constants';
import { AccountingType, CashToCloseEntry } from 'v2-types/order';

import { CashToCloseData, CashToCloseDataEntry } from '../types';

export function getCashToCloseData({
  accounting,
  cashToCloseEntries
}: {
  accounting: AccountingType[],
  cashToCloseEntries: CashToCloseEntry[] | null
}) {
  const getEntryByLedgerCode = (ledgerCode: string) => getAccountingEntryByLedgerCode({ accounting, ledgerCode });

  function getCashToCloseDataEntry(ledgerCode: string): CashToCloseDataEntry | undefined {
    const accountingEntry = getEntryByLedgerCode(ledgerCode);
    if (accountingEntry === undefined) return undefined;

    let cashToCloseEntry;
    if (cashToCloseEntries) {
      cashToCloseEntry = cashToCloseEntries.find((entry) => entry.entry_code === ledgerCode);
    }

    return {
      final: accountingEntry.amount,
      changed: cashToCloseEntry?.changed ?? false,
      changed_text: cashToCloseEntry?.changed_text ?? '',
      entry_code: ledgerCode,
      estimate_amount: cashToCloseEntry?.estimate_amount ?? 0
    };
  }

  const cashToCloseData: CashToCloseData = {
    total_closing_costs_j: getCashToCloseDataEntry(LEDGER_CODE_CASH_TOTAL)!,
    paid_before_closing: getCashToCloseDataEntry(LEDGER_CODE_PAID_BEFORE)!,
    cash_to_close: getCashToCloseDataEntry(LEDGER_CODE_CASH_CLOSE)!,
    adjustments_and_other_credits: getCashToCloseDataEntry(LEDGER_CODE_ADJUSTMENTS_CREDITS),
    closing_costs_financed: getCashToCloseDataEntry(LEDGER_CODE_COSTS_FINANCED),
    loan_amount: getCashToCloseDataEntry(LEDGER_CODE_CASH_LOAN),
    deposit: getCashToCloseDataEntry(LEDGER_CODE_CASH_DEPOSIT),
    down_payment_from_borrower: getCashToCloseDataEntry(LEDGER_CODE_DOWN_PAYMENT),
    funds_for_borrower: getCashToCloseDataEntry(LEDGER_CODE_FUNDS_BORROWER),
    seller_credits: getCashToCloseDataEntry(LEDGER_CODE_SELLER_CREDITS),
    total_payoffs_and_payments_k: getCashToCloseDataEntry(LEDGER_CODE_CASH_PAYOFFS),
  };

  return cashToCloseData;
}
