import formatISO from 'date-fns/formatISO';
import { editManualCharge } from 'graphql/ledger/mutations';
import { getLedgerListings } from 'graphql/ledger/queries';
import { useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { listingItemtype } from 'types/ledger';
import useAlert from 'utils/alert';
import { validateAllowedKindTypes } from 'utils/filterItemsbykind';
import { capitalize } from 'utils/helpers';
import { AccountingType } from 'v2-types/order';

import { addManualCharge } from './services/mutations';
import { EntryLedgerType } from './services/types';

const useAddRecipts = (
  handleClose:()=>void,
  handleSuccess:(accounting:AccountingType[])=> void,
  prevData:AccountingType | null,
  kindAllowedEntry?: string | string[],
  codeAllowedEntry?: string | string[],
  resolver?: Resolver<EntryLedgerType, object> | undefined,
  mortgageId? :string
) => {
  const { t } = useTranslation();
  const [createErrors, setcreateErrors] = useState<string[]>([]);
  const { id: orderId } = useParams<{ id: string}>();
  const showAlert = useAlert();
  const [allowedCodes, setAllowedCodes] = useState<listingItemtype[]>([]);
  const [allowedKinds, setAllowedKinds] = useState<listingItemtype[]>([]);
  const methods = useForm<EntryLedgerType>({
    defaultValues: {
      entryDate: prevData?.entry_date || null,
      description: prevData?.description || '',
      code: prevData?.code || '',
      amount: prevData?.amount || 0,
      kind: prevData?.kind || '',
      letter: prevData?.letter,
      number: prevData?.number,
      amount_override: prevData?.amount_override || prevData?.amount,
      amount_calculated: prevData?.amount_calculated || 0,
	  months: prevData?.months || 0,
    },
    resolver
  });

  const [AddManualcharge, responseAddManualcharge] = useMutation(addManualCharge, {
    onSuccess: (data) => {
      if (data) {
        showAlert(capitalize(t('dialogs:order-updated')), 'success');
        handleSuccess(data.createOrderLedgerEntry.accounting || []);
        responseAddManualcharge.reset();
        methods.reset();
        handleClose();
      }
    },
    onError: (e:any) => {
      const { errors = ['Error Adding Association'] } = e?.response || {};
      const errorsMapped = errors.map((error:any) => error.message);
      setcreateErrors(errorsMapped);
    }
  });

  const [EditManualcharge, responseEditManualcharge] = useMutation(editManualCharge, {
    onSuccess: (data) => {
      if (data) {
        showAlert(capitalize(t('dialogs:order-updated')), 'success');
        responseEditManualcharge.reset();
        methods.reset();
        handleClose();
        handleSuccess(data.updateOrderLedgerEntry?.accounting || []);
      }
    },
    onError: () => { showAlert(capitalize(t('dialogs:there-is-an')), 'error'); }
  });
  const getLedgerAllowedKinds = useQuery(['allowed-ledger-kinds', 'allowed_ledger_kinds'],
    () => getLedgerListings('allowed_ledger_kinds'),
    {
      enabled: prevData === null,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.getListing.entries) {
          const kinds = validateAllowedKindTypes(data?.getListing?.entries, kindAllowedEntry);
          setAllowedKinds(kinds);
          if (!prevData) {
            methods.setValue('kind', kinds[0].code);
          }
        }
      }
    });

  const getLedgerAllowedCodes = useQuery(['allowed-ledger-Codes', 'allowed_ledger_codes'],
    () => getLedgerListings('allowed_ledger_codes'),
    {
      enabled: prevData === null,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.getListing.entries) {
          const codes = validateAllowedKindTypes(data?.getListing?.entries, codeAllowedEntry);
          setAllowedCodes(codes);
          if (!prevData) {
            methods.setValue('code', codes[0].code);
          }
        }
      },
    });

  const handleEntry = methods.handleSubmit((data) => {
    if (prevData) {
      EditManualcharge({
        id: orderId!,
        accountingId: prevData?._id!,
        data: {
          amount_override: parseFloat(data.amount_override as unknown as string) || 0,
          entry_date: data?.entryDate || null,
          letter: data.letter === '' ? null : data.letter,
          number: data.number ? parseFloat(data.number as unknown as string) : null,
		     // eslint-disable-next-line radix
          months: data.months ? parseInt(data.months as unknown as string) : null,
        }
      });
      return;
    }
    AddManualcharge({
      id: orderId!,
	  mortgageId,
      ...data,
      number: data.number ? parseFloat(data.number as unknown as string) : null,
      amount: parseFloat(data.amount as unknown as string) || 0,
      // eslint-disable-next-line radix
      months: data.months ? parseInt(data.months as unknown as string) : null,
      entryDate: data?.entryDate ? formatISO(data?.entryDate as unknown as Date) : null,
      letter: data.letter === '' ? null : data.letter
    });
  });

  return {
    allowedCodes,
    allowedKinds,
    loadingKinds: getLedgerAllowedKinds.isLoading,
    loadingCodes: getLedgerAllowedCodes.isLoading,
    loadingEditEntry: responseEditManualcharge.isLoading,
    loadingAddNewEntry: responseAddManualcharge.isLoading,
    handleEntry,
    methods,
    createErrors,
    t,
  };
};

export default useAddRecipts;
