import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NumberField from 'components/number-field';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({

  container: { marginLeft: theme.spacing(3), paddingTop: theme.spacing(3), },
  containerText2: {
    background: theme.accent.main,
    fontSize: theme.spacing(1.7),
    color: theme.palette.primary.darker,
    borderRadius: 4,
    padding: theme.spacing(0.25, 0.5),
    textTransform: 'uppercase',
  },
  title: {
    color: theme.palette.tab.offselected,
    fontSize: 23,
    fontWeight: 500,
  },
  inlineText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': { marginRight: theme.spacing(1) },
  },
  input: {
    width: '50%',
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiSelect-iconFilled': { display: 'none' },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
  },
  underlineFocus: {
    '&.MuiFilledInput-underline.Mui-focused:after': { borderColor: theme.accent.main },
    '&.MuiInput-underline.Mui-focused:after': { borderColor: theme.accent.main },
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    // fontSize: '0.875rem',
    '&:hover': { color: theme.palette.common.black },
  },
}));

type TaxCriteriaProps= {
	taxAmount:number
}

const TaxCriteria: FC<TaxCriteriaProps> = ({ taxAmount }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.inlineText}>
        <Typography className={classes.title}>
          {t('taxesAndFees:tax-criteria')}
        </Typography>

        <Typography className={classes.containerText2}>
          TX
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="baseline">
        <NumberField
          suffix="%"
          isNumericString
          thousandSeparator
          allowNegative={false}
          name="earnest"
          isAllowed={({ floatValue = 0 }) => floatValue <= 100}
          className={classes.input}
          label={`${t('taxesAndFees:sales-tax-rate')} (%)`}
          variant="filled"
          value={taxAmount}
          InputProps={{ classes: { focused: classes.underlineFocus }, }}
          disabled
        />
        <IconButton
          className={classes.icon}
          onClick={() => {

          }}
        >
          <FontAwesomeIcon icon="edit" size="xs" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(TaxCriteria) as typeof TaxCriteria;
