import { DocumentsType } from "v2-types/order";
import { PartyType } from "views/orders/new-parties/services";

import { RequestPartyDocuments } from "../types";
import { AssociationType } from "../../../types";

export const getInitialData = ({
  orderId,
  parties,
  documents,
}: {
  orderId: string;
  parties: AssociationType[];
  documents: DocumentsType[];
}): RequestPartyDocuments => {
  const files = documents.filter((document) => document.kind === "upload");
  const partiesRequests = parties.filter((party) =>
    files.some((file) =>
      file.data.some(
        (fileData) => fileData._id === party._id && fileData.kind === "party"
      )
    )
  );

  const requests = partiesRequests.map((party) => ({
    partyId: party._id,
    partyName: party.name,
    files: files
      .filter((file) =>
        file.data.some(
          (fileParty) =>
            fileParty._id === party._id && fileParty.kind === "party"
        )
      )
      .map((file) => ({
        archiveId: file._id,
        filename: file.description,
        checked: false,
      })),
  }));

  return {
    id: orderId,
    requests,
    expiresIn: "",
    notBefore: "",
  };
};
