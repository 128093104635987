import { Grid, makeStyles, Typography } from '@material-ui/core';
import { TFunction } from 'i18next';
import React from 'react';
import { EstimatedTaxesType } from 'types/disclosures';

import { getEstimatedTaxesRows, getPaymentTableInputs } from '../services';
import ProjectedPaymentsTable from './projected-payments-table';

const useStyles = makeStyles((theme: any) => ({
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

type EstimatedTaxesSectionTableType = {
  t: TFunction;
  estimatedTaxesTable: EstimatedTaxesType;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: any;
};

const EstimatedTaxesSectionTable = ({
  t,
  estimatedTaxesTable,
  setFieldValue,
  errors,
}:EstimatedTaxesSectionTableType) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.subTitle}>
          {t('projectedPayments:estimated-taxes-title')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ProjectedPaymentsTable
          data={getPaymentTableInputs(estimatedTaxesTable)}
          rows={getEstimatedTaxesRows({
            ...estimatedTaxesTable, estimatedTaxesTable, t, errors, setFieldValue
          })}
        />
      </Grid>
    </Grid>
  );
};

export default EstimatedTaxesSectionTable;
