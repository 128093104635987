import { TFunction } from 'i18next';

export const SENTRY_URL = 'https://34b75e6fd19d4dcaa909516a4f1ac68a@o226201.ingest.sentry.io/5632074';

export const ORGANIZATION = 'organization';
export const INDIVIDUAL = 'individual';

export const MALE = 'male';
export const FEMALE = 'female';

export const TAB_FILES = 'tab_files';
export const TAB_REQUEST = 'tab_request';

export const ADMIN = 'admin';
export const BUYER = 'buyer';
export const SELLER = 'seller';
export const TITLE_AGENT = 'title_agent';
export const SPLIT = 'split';

export const CONTACT_TITLE_COMPANY_KIND = 'Title Company';
export const CONTACT_TITLE_COMPANY_NAME = 'Championship Title';
export const CONTACT_TITLE_ADMINISTRATOR = 'Title Administrator';
export const CONTACT_TITLE_AGENT = 'Title Agent';
export const CONTACT_TITLE_AGENCY = 'Title Agency';

export const CONTACT_BUYER = 'Buyer';
export const CONTACT_SELLER = 'Seller';
export const CONTACT_LENDER = 'Lender';

export const OLD_ROUTE = 'old_route';

export const SAFE_DELETE_WORD = 'DELETE';

export const PAID_BY_OTHERS = 'paid_by_others';

export const CURRENT_PATH = 'current-path';
export const ROUTE_PAYOFFS = 'payoffs';
export const ROUTE_DOCUMENTS = 'documents';
export const ROUTE_CLOSING = 'closing';
export const ROUTE_PARTIES = 'parties';
export const ROUTE_CHARGES = 'charges';
export const ROUTE_EARNEST_COMMISSIONS = 'earnest-and-commissions';
export const ROUTE_LOAN = 'loan';
export const ROUTE_DATES_AND_CONFIG = 'dates-and-config';
export const ROUTE_TEAM_AND_REPORTING = 'team-and-reporting';
export const ROUTE_PAYMENTS = 'payments';
export const ROUTE_PROPERTIES = 'properties';
export const ROUTE_SELLER = 'sellers';
export const ROUTE_BUYER = 'buyers';
export const OVERVIEW = 'overview';
export const QUESTIONS_AND_ANSWERS = 'questions-and-answers';
export const NARRATIVE = 'narrative';
export const ROUTE_TAXES_AND_PRORATIONS = 'taxes-and-prorations';
export const ROUTE_POLICY_INFO_AND_RATES = 'policy-info-and-rates';
export const DEFAULT_ROUTE = OVERVIEW;
export const ROUTE_DISBURSEMENTS_AND_RECEIPTS = `${ROUTE_PAYMENTS}/disbursements-and-receipts`;

export const LOADING = 'loading';
export const IDLE = 'idle';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const PENDING = 'pending';
export const DONE = 'done';
export const INVALID = 'invalid';
export const ALL = 'all';

export const PURCHASE = 'Purchase';
export const CLOSING_DISCLOSURE = 'Closing Disclosure';
export const PURCHASE_STANDARD = 'Purchase Standard';

export const EMPLOYEES = 'employees';
export const SUMMARY = 'summary';
export const PAYMENTS = 'payments';
export const DEFAULT_FEES = 'default_fees';
export const LENDER_FORM = 'lender-form';
export const PAYMENT_ACCOUNTS = 'payment-accounts';

export const PRIMARY = 'primary';
export const COMMITMENT = 'commitment';
export const PAYMENT = 'payment';
export const CPL = 'cpl';
export const POST_PROCESSING = 'post-processing';
export const LOSS_PAYEE = 'loss-payee';
export const MAILING = 'mailing';

export const ASSISTANT = 'Assistant';
export const LOAN_ORIGINATOR = 'Loan Originator';
export const LOAN_PROCESSOR = 'Loan Processor';
export const SETTLEMENT_STATEMENT = 'Settlement Statement';
export const HUD_1 = 'HUD 1';
export const REFINANCE = 'Refinance';
export const HELOC = 'heloc';
export const FORECLOSURE = 'Foreclosure';
export const BULKSALE = 'Bulksale';
export const SUBORDINATE_ORDER = 'subordinate_order';
export const CONSTRUCTION_LOAN = 'construction_loan';
export const COMMERCIAL_PURCHASE = 'commercial_purchase';
export const COMMERCIAL_REFINANCE = 'commercial_refinance';
export const TITLE_SEARCH_ONLY = 'title_search_only';
export const TITLE_SEARCH_ONLY_REFINANCE = 'title_search_only_refinance';
export const CONSTRUCTION_BINDER = 'construction_binder';

export const ACCESS_TOKEN = 'access_token';
export const TOKEN_CLAIMS = 'token_claims';
export const ORDER_ID = 'orderId';
export const AGENCY_SELECTED_ID = 'agency_selected_id';

export const FORMIK_PERSIST_DATES_AND_CONFIG = 'dates_and_config';
export const FORMIK_PERSIST_TEAM_AND_REPORTING = 'team_and_reporting';
export const FORMIK_PERSIST_PAYOFFS = 'payoffs';
export const FORMIK_PERSIST_COMMISSIONS = 'commissions';
export const FORMIK_PERSIST_LOAN = 'loan';
export const FORMIK_PERSIST_TAXES_AND_PRORATIONS = 'taxes_and_prorations';
export const FORMIK_PERSIST_POLICY_INFO_AND_RATES = 'policy_info_and_rates';
export const FORMIK_PERSIST_PROJECTED_PAYMENTS = 'projects_payments';

export const FORMIK_PERSIST_PROPERTY_TAB = 'property-tab';
export const FORMIK_PERSIST_SURVEY_TAB = 'survey-tab';
export const FORMIK_PERSIST_SUBDIVISIONS_TAB = 'subdivisions-tab';

export const REMOVE_LOCALSTORAGE = [
  FORMIK_PERSIST_DATES_AND_CONFIG,
  FORMIK_PERSIST_TEAM_AND_REPORTING,
  FORMIK_PERSIST_PAYOFFS,
  FORMIK_PERSIST_LOAN,
  FORMIK_PERSIST_TAXES_AND_PRORATIONS,
  FORMIK_PERSIST_POLICY_INFO_AND_RATES,
  FORMIK_PERSIST_PROJECTED_PAYMENTS,
  FORMIK_PERSIST_PROPERTY_TAB,
  FORMIK_PERSIST_SURVEY_TAB,
  FORMIK_PERSIST_SUBDIVISIONS_TAB,
];

export const CHECK = 'Check';
export const WIRE = 'wire';
export const NET_FUNDED = 'Net Funded';
export const HOLDBACK = 'Holdback';
export const AGGREGATE_PAYMENT = 'Aggregate Payment';
export const LENDER_NAME = 'Lender Name';

export const SECTION_A = 'section A';
export const SECTION_B = 'section B';
export const SECTION_C = 'section C';
export const SECTION_H = 'section H';

export const SECTION_800 = 'section 800';
export const SECTION_1100 = 'section 1100';
export const SECTION_1300 = 'section 1300';

export const BORROWER = 'Borrower';
export const BORROWER_BEFORE_CLOSING = 'Borrower (Before Closing)';
export const SELLER_BEFORE_CLOSING = 'Seller (Before Closing)';
export const OTHERS = 'Others';

export const APPLICATION = 'Application';
export const APPRAISAL = 'Appraisal';
export const ASSIGNMENT_CONSIDERATION = 'Assignment Consideration';
export const COURIER = 'Courier';
export const CREDIT_REPORT = 'Credit Report';
export const DOCUMENT_PREPARATION = 'Document Preparation';
export const E_RECORDING_FEE = 'E-Recording Fee';
export const ENDORSEMENT = 'Endorsement';
export const FLOOD_CERTIFICATION = 'Flood Certification';
export const HAZARD_INSURANCE = 'Hazard Insurance';
export const HOME_WARRANTY = 'Home Warranty';
export const INSURANCE = 'Insurance';
export const LENDERS_POLICY = "Lender's Policy";
export const NOTARY = 'Notary';
export const ORIGINATION = 'Origination';
export const OWNERS_POLICY = "Owner's Policy";
export const PAYOFF = 'Payoff';
export const PROPERTY_OWNERS_ASSOCIATION_RESALE_CERTIFICATE = "Property Owner's Association - Resale Certificate";
export const PROPERTY_OWNERS_ASSOCIATION_RESALE_TRANSFER_FEE = "Property Owne's Association - Transfer Fee";
export const PROPERTY_TAX = 'Property Tax';
export const REAL_STATE_AGENT_COMMISION = 'Real Estate Agent Commission';
export const REAL_STATE_BROKER_COMMISION = 'Real Estate Broker Commission';
export const RECORDING_FEE = 'Recording Fee';
export const REIMBURSEMENT = 'Reimbursement';
export const REMITTANCE_ENDORSEMENT = 'Remittance: Endorsement';
export const REMITTANCE_LTP = 'Remittance: LTP';
export const REMITTANCE_OTP = 'Remittance: OTP';
export const SALES_TAX = 'Sales Tax';
export const SELLER_FINANCE = 'Seller Finance';
export const SETTLEMENT_FEE = 'Settlement Fee';
export const TAX_SERVICE = 'Tax Service';
export const SURVEY = 'Survey';
export const TAX_CERTIFICATE = 'Tax Certificate';
export const TIGA = 'TIGA';
export const TRANSFER_TAX = 'Transfer Tax';
export const WIRE_FEE = 'Wire Fee';
export const EXCLUDED = 'Excluded';
export const UNCLASSIFIED = ' Unclassified';
export const PREPAID_INTEREST = 'Prepaid Interest';
export const SECTION = 'Section';
export const DESCRIPTION = 'Description';
export const PAYEE = 'Payee';
export const AMOUNT = 'Amount';
export const PAID_BY = 'Paid By';
export const PAYEE_LABEL = 'Payee Label';
export const TAXABLE = 'Taxable';
export const LISTING_AGENT_COMMISSION = 'Listing Agent Commission';
export const SELLING_AGENT_COMMISSION = 'Selling Agent Commission';
export const OWNERS_TITLE_POLICY = "Owner's Title Policy";
export const LENDER_TITLE_POLICY = "Lender's Title Policy";
export const STATE_OF_TEXAS_POLICY = 'State of Texas Policy';
export const TO_FIRST_NATIONAL_TITLE_INSURANCE_COMPANY = 'to First National Title Insurance Company';
export const POLICY = 'Policy';
export const TAXES = 'Taxes';
export const FEES_TAXES = 'fees-taxes';

export const SETTLEMENT_AGENCY = 'Settlement Agency';
export const NONE = 'None';

export const NET = 'Net';
export const GROSS = 'Gross';

export const VARIABLE = 'variable';
export const FIXED = 'fixed';

export const LIST_STATUS = [LOADING, IDLE, SUCCESS, ERROR];

export const COMPANY_TYPE = [
  'corporation',
  'limit-liability-company',
  'estate',
  'trust',
  'partnertship',
  'guardianship',
];

export const DESCRIPTIONS = (t: TFunction) => ({
  [APPLICATION]: t('lender:application'),
  [APPRAISAL]: t('lender:appraisal'),
  [ASSIGNMENT_CONSIDERATION]: t('lender:assignment-consideration'),
  [COURIER]: t('lender:courier'),
  [CREDIT_REPORT]: t('lender:credit-report'),
  [DOCUMENT_PREPARATION]: t('lender:document-preparation'),
  [E_RECORDING_FEE]: t('lender:e-recording-fee'),
  [ENDORSEMENT]: t('lender:endorsement'),
  [FLOOD_CERTIFICATION]: t('lender:flood-certification'),
  [HAZARD_INSURANCE]: t('lender:hazard-insurance'),
  [HOME_WARRANTY]: t('lender:home-warranty'),
  [INSURANCE]: t('lender:insurance'),
  [LENDERS_POLICY]: t('lender:lenders-policy'),
  [NOTARY]: t('lender:notary'),
  [ORIGINATION]: t('lender:origination'),
  [OWNERS_POLICY]: t('lender:owners_policy'),
  [PAYOFF]: t('lender:payoff'),
  [PROPERTY_OWNERS_ASSOCIATION_RESALE_CERTIFICATE]: t(
    'lender:property-owners-association-resale-certificate'
  ),
  [PROPERTY_OWNERS_ASSOCIATION_RESALE_TRANSFER_FEE]: t(
    'lender:property-owners-association-transfer-fee'
  ),
  [PROPERTY_TAX]: t('lender:property-tax'),
  [REAL_STATE_AGENT_COMMISION]: t('lender:real-estate-agent-commission'),
  [REAL_STATE_BROKER_COMMISION]: t('lender:real-estate-broker-commission'),
  [SETTLEMENT_FEE]: t('lender:settlement-fee'),
  [RECORDING_FEE]: t('lender:recording-fee'),
  [REIMBURSEMENT]: t('lender:reimbursement'),
  [REMITTANCE_ENDORSEMENT]: t('lender:remittance-endorsment'),
  [REMITTANCE_LTP]: t('lender:remittance-ltp'),
  [REMITTANCE_OTP]: t('lender:remittance-otp'),
  [SALES_TAX]: t('lender:sales-tax'),
  [SELLER_FINANCE]: t('lender:seller-finance'),
  [SURVEY]: t('lender:survey'),
  [TAX_CERTIFICATE]: t('lender:tax-certificate'),
  [WIRE_FEE]: t('lender:wire-fee'),
  [EXCLUDED]: t('lender:excluded'),
  [UNCLASSIFIED]: t('lender:unclassified'),
  [TRANSFER_TAX]: t('lender:transfer-tax'),
  [TAX_SERVICE]: t('lender:tax-service'),
  [TIGA]: t('lender:tiga'),
});

export const DRAWER_ORDERS_WIDTH = 241;

export const DEFAULT_TOTAL_CHARGES = 8;

export const CHARGES_ORIGINATIONS_CHARGES = 'origination_charges';
export const CHARGES_DID_NOT_SHOP_FOR = 'did_not_shop_for';
export const CHARGES_DID_SHOP_FOR = 'did_shop_for';
export const CHARGES_TAXES_AND_FEES = 'taxes_and_fees';
export const CHARGES_PREPAIDS = 'prepaids';
export const CHARGES_ESCROW = 'escrow';
export const CHARGES_OTHER_CHARGES = 'other_charges';
export const CHARGES_LENDER_CREDITS = 'lender_credits';
export const CHARGES_DEBITS_AND_CREDITS = 'debits_and_credits';

export const PAYMENT_CHECK = 'check';
export const PAYMENT_WIRE = 'wire';
export const PAYMENT_NET_FUNDED = 'net_funded';
export const PAYMENT_AGGREGATE = 'aggregate';
export const PAYMENT_TRANSFER = 'transfer';
export const PAYMENT_HOLD_BACK = 'hold_back';

export const APPRAISAL_COMPANY = 'Appraisal Company';
export const BUILDER = 'Builder';
export const CREDIT_CARD_CO = 'Credit Card Co.';
export const EXCHANGE_ACCOMMODATOR = 'Exchange Accommodator';
export const GENERAL_CONTRACTOR = 'General Contractor';
export const GOVERNMENT_ENTITY = 'Government Entity';
export const HOA = 'HOA';
export const HOA_MANAGEMENT_CO = 'HOA Management Co.';
export const HOME_WARRANTY_CO = 'Home Warranty Co.';
export const INSURANCE_COMPANY = 'Insurance Company';
export const LAW_FIRM = 'Law Firm';
export const MORTGAGE_BROKERAGE = 'Mortgage Brokerage';
export const NOTARY_COMPANY = 'Notary Company';
export const OTHER_COMPANY = 'Other Company';
export const PEST_INSPECTOR = 'Pest Inspector';
export const REO_MANAGEMENT_CO = 'REO Management Co.';
export const REAL_ESTATE_AGENCY = 'Real Estate Agency';
export const RECORDING_OFFICE = 'Recording Office';
export const RELEASE_TRACKER = 'Release Tracker';
export const SURVEYING_FIRMS = 'Surveying Firms';
export const TITLE_ABSTRACTOR = 'Title Abstractor';
export const TAX_ASSESSOR = 'Tax Assessor';
export const TAX_AUTHORITY = 'Tax Authority';
export const TITLE_COMPANY = 'Title Company';
export const UNDERWRITER = 'Underwriter';
export const UTILITY_CO = 'Utility Co.';

export const PAYMENTS_TYPE: { [key: string]: string } = {
  [PAYMENT_CHECK]: 'Check',
  [PAYMENT_WIRE]: 'Wire',
  [PAYMENT_NET_FUNDED]: 'Ned Funded',
  [PAYMENT_AGGREGATE]: 'Aggregate',
  [PAYMENT_TRANSFER]: 'transfer',
  [PAYMENT_HOLD_BACK]: 'Hold back',
};

export const POLICY_TYPE_POLICY_OF_TITLE = "OWNER'S POLICY OF TITLE INSURANCE (Form T-1) (Not applicable for improved one-to-four family residential real estate)";
export const POLICY_TYPE_TEXAS_RESIDENTIAL = "TEXAS RESIDENTIAL OWNER'S POLICY OF TITLE INSURANCE ONE-TO-FOUR FAMILY RESIDENCES (Form T1-R)";
export const POLICY_TYPE_LOAN_POLICY = 'LOAN POLICY OF TITLE INSURANCE (Form T-2)';
export const POLICY_TYPE_TEXAS_SHORT_FORM = 'TEXAS SHORT FORM RESIDENTIAL LOAN POLICY OF TITLE INSURANCE (Form T-2R)';
export const POLICY_TYPE_LOAN_TITLE_POLICY = 'LOAN TITLE POLICY BINDER ON INTERIM CONSTRUCTION LOAN (Form T-13)';
export const POLICY_TYPE_NO_LENDERS_POLICY = "No Lender's Policy";
export const POLICY_TYPE_NO_OWNERS_POLICY = "No Owner's Policy";

export const RATE_TYPE_SIMULTANEOUS = 'Simultaneous (R-5)';
export const RATE_TYPE_SIMULTANEOUS_REISSUE = 'Simultaneous (R-5) w/ Reissue';
export const RATE_TYPE_SIMULTANEOUS_PAY = 'Simultaneous Pay-As-You-Go (R-5)';

export const RATE_TYPE_STAT_CODE_SUBSEQUENT = 'Subsequent to Interim Construction Loan Binder';
export const RATE_TYPE_STAT_CODE_SINGLE_OWNER = "Single Owner's Policy for Separate Purchases Simultaneous with Loan Policy";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_LOAN_EXCEEDS_OWNER = "Simultaneous with Loan that Exceeds Owner's";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_PAY = 'Simultaneous with Pay-As-You-Go Loan';
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_PAY_OWNER = "Simultaneous with Pay-As-You-Go Loan - Owner's Exceeds Loan";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_LOAN_CREDIT = "Simultaneous with Loan with Credit for Previous Owner's Policy or Policies (Owner's Policy issued per P-8a)";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_LOAN_CREDIT_OWNER = "Owner's Policy Simultaneous with Loan with Credit for Previous Owner's Policy or Policies (Owner's Policy issued without P-8a)";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_GRANTOR = "Simultaneous with Grantor's";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_LOAN_CONSTRUCTION = 'Simultaneous with Loan Following Construction in excess of $5,000,000';
export const RATE_TYPE_STAT_CODE_FIRST_LIEN_POLICY = 'First Lien Policy - Simultaneous with Subordinate Lien Policy(ies)';
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_OWNER_POLICY = "Simultaneous with Owner's Policy";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_OWNER_PAY = "Simultaneous with Owner's Policy Pay-As-You-Go";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_LIEN_POLICY = 'Simultaneous with First Lien Policy';
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_EXCEEDS_LOAN = "Simultaneous with Owner's Policy when Loan Policy Exceeds Owner's";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_EXCEEDS_LOAN_PAY = "Simultaneous with Owner's that Exceeds Loan (Pay-As-You-Go)";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_CREDIT = "Simultaneous with Owner's with Credit for Previous Owner's Policy or Policies";
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_OWNER_CONSTRUCTION = "Simultaneous with Owner's Following Construction in excess of $5,000,000";
export const RATE_TYPE_STAT_CODE_LIMITED_FORECLOSURE = 'Limited Pre-Foreclosure Policy (T-98)';
export const RATE_TYPE_STAT_CODE_LIMITED_COVERAGE = 'Limited Coverage Junior Loan Policy (T-44)';
export const RATE_TYPE_STAT_CODE_SIMULTANEOUS_WITH_LOAN_POLICY = 'Simultaneous with Loan Policy';
export const SHOW_AS_PART_OF_POLICY_PREMIUM_LINE = 'Part Of Policy Premium Line';
export const SHOW_AS_ONE_AGGREGATE_LINE = 'One Aggregate Line';
export const SHOW_AS_INDIVIDUAL_LINES = 'Individual Lines';

export const POLICY_TAXES_AND_FEES_FEE_OR_TAX_ID = 'TX Guaranty Fee';
export const POLICY_TAXES_AND_FEES_DESCRIPTION = 'State of Texas Policy Guaranty Fee';
export const POLICY_TAXES_AND_FEES_DISCLOSED_PREMIUM_LINE = 'Include in policy premium line';
export const POLICY_TAXES_AND_FEES_DISCLOSED_SEPARATE_SECTION_POLICY = 'Separate line in same section as policy';
export const POLICY_TAXES_AND_FEES_DISCLOSED_SEPARATE_SECTION_E = 'Separate line in section E';
export const POLICY_TAXES_AND_FEES_DISCLOSED_AGGREGATE_SECTION_E = 'Aggregate line in section E';
export const POLICY_TAXES_AND_FEES_PAID_TO_FIRST_NATIONAL = 'First National Title Insurance Company';
export const POLICY_TAXES_AND_FEES_PAID_TO_AGENTS_NATIONAL = 'Agents National';
export const POLICY_TAXES_AND_FEES_PAID_TO_ALAMO_TITLE = 'Alamo Title';
export const POLICY_TAXES_AND_FEES_PAID_TO_ALLIANT = 'Alliant';
export const POLICY_TAXES_AND_FEES_PAID_TO_AMTRUST = 'AmTrust';
export const POLICY_TAXES_AND_FEES_PAID_TO_ARMTRUST_RATES_AND_FORMS_ONLY = 'AmTrust (Rates & Forms Only)';
export const POLICY_TAXES_AND_FEES_PAID_TO_ATG = 'ATG';
export const POLICY_TAXES_AND_FEES_PAID_TO_ATTORNEYS_TITLE = 'Attorneys Title';
export const POLICY_TAXES_AND_FEES_PAID_TO_CATIC = 'CATIC';
export const POLICY_TAXES_AND_FEES_PAID_TO_CHICAGO_TITLE = 'Chicago Title';
export const POLICY_TAXES_AND_FEES_PAID_TO_COMMONWEALTH = 'Commonwealth';
export const POLICY_TAXES_AND_FEES_PAID_TO_CONESTOGA = 'Conestoga';
export const POLICY_TAXES_AND_FEES_PAID_TO_DOMA = 'Doma';
export const POLICY_TAXES_AND_FEES_PAID_TO_FIDELITY = 'Fidelity';
export const POLICY_TAXES_AND_FEES_PAID_TO_FIRST_AMERICAN = 'First American';
export const POLICY_TAXES_AND_FEES_PAID_TO_FIRST_AMERICAN_OF_LOUISIANA = 'First American of Louisiana';
export const POLICY_TAXES_AND_FEES_PAID_TO_FIRST_AMERICAN_TITLE_GUARANTY_COMPANY = 'First American Title Guaranty Company';
export const POLICY_TAXES_AND_FEES_PAID_TO_FNTI = 'FNTI';
export const POLICY_TAXES_AND_FEES_PAID_TO_GA_FUND = 'GA Fund';
export const POLICY_TAXES_AND_FEES_PAID_TO_GENERAL_TITLE = 'General Title';
export const POLICY_TAXES_AND_FEES_PAID_TO_INVESTORS_NATIONAL_INVESTORS = 'Investors / National Investors';
export const POLICY_TAXES_AND_FEES_PAID_TO_LIGHTHOUSE = 'Lighthouse';
export const POLICY_TAXES_AND_FEES_PAID_TO_MAIN_STREET_TITLE_INSURANCE = 'Main Street Title Insurance';
export const POLICY_TAXES_AND_FEES_PAID_TO_NATIONAL_TITLE_INSURANCE_OF_NEW_YORK_INC = 'National Title Insurance of New York, Inc.';
export const POLICY_TAXES_AND_FEES_PAID_TO_OLD_REPUBLIC = 'Old Republic';
export const POLICY_TAXES_AND_FEES_PAID_TO_PREMIER_LAND_TITLE = 'Premier Land Title';
export const POLICY_TAXES_AND_FEES_PAID_TO_PULSAR = 'Pulsar';
export const POLICY_TAXES_AND_FEES_PAID_TO_RADIAN = 'Radian';
export const POLICY_TAXES_AND_FEES_PAID_TO_RATIC = 'RATIC';
export const POLICY_TAXES_AND_FEES_PAID_TO_SECURITY_TITLE = 'Security Title';
export const POLICY_TAXES_AND_FEES_PAID_TO_SIERRA_TITLE = 'Sierra Title';
export const POLICY_TAXES_AND_FEES_PAID_TO_SOUTHWEST_LAND_TITLE = 'Southwest Land Title';
export const POLICY_TAXES_AND_FEES_PAID_TO_SOUTHWEST_LAND_TITLE_RATES_FORMS_ONLY = 'Southwest Land Title (Rates & Forms Only)';
export const POLICY_TAXES_AND_FEES_PAID_TO_STEWART = 'Stewart';
export const POLICY_TAXES_AND_FEES_PAID_TO_TEXAN_TITLE = 'Texan Title';
export const POLICY_TAXES_AND_FEES_PAID_TO_THE_FUND = 'The Fund';
export const POLICY_TAXES_AND_FEES_PAID_TO_TITLE_RESOURCES = 'Title Resources';
export const POLICY_TAXES_AND_FEES_PAID_TO_WESTCOR = 'Westcor';
export const POLICY_TAXES_AND_FEES_PAID_TO_WFG = 'WFG';
export const POLICY_TAXES_AND_FEES_PAID_TO_AGENT = 'Agent & Underwriter w/ Default Split';

export const CHARGES_ADJUST_PAYMENTS = 'Adjust Payments';
export const CHARGES_ADJUST_TOTALS = 'Adjust Totals';
export const CHARGES_PREVIEW_STATEMENT = 'Preview Statement';
export const CUSTOM_ENDORSEMENT_ID = 'CUSTOM';
export const CUSTOM_ENDORSEMENT_DESCRIPTION = 'Custom Endorsement';

export const SALES_PRICE_OF_PROPERTY = 'Sales Price of Property';
export const SALES_PRICE_OF_ANY_PERSONAL_INCLUDED_IN_SALE = 'Sales Price of Any Personal Property Included in Sale';
export const CLOSING_COSTS_PAID_AT_CLOSING = 'Closing Costs Paid at Closing (J)';

export const CITY_TOWN_TAXES = 'City / Town Taxes';
export const COUNTY_TAXES = 'County Taxes';
export const ASSESSMENTS = 'Assessments';

export const DEPOSIT = 'Deposit';
export const LOAN_AMOUNT = 'Loan Amount';
export const EXISTING_LOAN_ASSUMMED = 'Existing Loan(s) Assumed or Taken Subject to';
export const SELLER_CREDIT = 'Seller Credit';
export const EXCESS_DEPOSIT = 'Excess Deposit';
export const PAYOFF_OF_FIRST_MORTGAGE = 'Payoff of First Mortgage';
export const PAYOFF_OF_SECOND_MORTGAGE = 'Payoff of Second Mortgage';

export const RATE_OVERRIDDEN_ALLOWED_SURCHARGE = 'Allowed Surcharge';
export const RATE_OVERRIDDEN_ALLOWED_DISCOUNT = 'Allowed Discount';
export const RATE_OVERRIDDEN_PRICED_WITH_BINDER = 'Priced with Binder';
export const RATE_OVERRIDDEN_RATE_SYSTEM_UNAVAILABLE = 'Rate System Unavailable';
export const RATE_OVERRIDDEN_INCREASED_LIABILITY_PREMIUM = 'Increased Liability Premium';
export const RATE_OVERRIDDEN_UNDERWRITING_APPROVED_OVERRIDE = 'Underwriting Approved Override';
export const RATE_OVERRIDDEN_NEW_RATE = 'New Rate';

export const WILL_ALLOW_ASSUMPTION = 'will allow assumption';
export const WILL_NOT_ALLOW_ASSUMPTION = 'will not allow assumption';
export const HAS_A_DEMAND_FEATURE = 'has a demand feature';
export const NO_DEMAND_FEATURE = 'no demand feature';
export const AMORTIZATION_AS_FEATURE = 'have a negative amortization as a feature';
export const NEGATIVE_AMORTIZATION = 'may have negative amortization';
export const NOT_A_FEATURE_OF_LOAN = 'not a feature of loan';
export const HAS_ESCROW = 'has escrow';
export const WILL_NOT_HAVE_ESCROW = 'will not have escrow';
export const ESCROW_HAS_DECLINED = 'escrow has declined';
export const NO_OFFER_ESCROW = 'lender does not offer escrow';

export const PROTECTS_FROM_UNPAID_BALANCE_LIABILITY = 'Protects from unpaid balance liability';
export const DOES_NOT_PROTECTS_FROM_UNPAID_BALANCE_LIABILITY = 'Does not protect from unpaid balance liability';

export const LEGAL_DESCRIPTION_TAB = 'legal-description';
export const SUBDIVISIONS_TAB = 'subdivisions';
export const PROPERTY_TAB = 'property';
export const SURVEY_TAB = 'survey';
export const AMOUNTS_TAB = 'amount';

export const PARTY_KIND_ORDER_OPENER = 'Order Opener';
export const PARTY_KIND_SELLER = 'Seller';
export const PARTY_KIND_BUYER = 'Buyer';
export const PARTY_KIND_ATTORNEY = 'Attorney';
export const PARTY_KIND_LENDER = 'Lender';
export const PARTY_KIND_SETTLEMENT_AGENCY = 'Settlement Agency';

export const CONTACT_KIND_COMPANY = 'Company';
export const CONTACT_KIND_ORGANIZATION = 'Organization';
export const CONTACT_KIND_PERSON = 'Person';

export const LEDGER_KIND_CLOSING = 'closing';
export const LEDGER_KIND_BALANCE = 'balance';

export const LEDGER_CODE_LOAN_AMOUNT = 'loan_amount';
export const LEDGER_CODE_PURCHASE_PRICE = 'purchase_price';
export const LEDGER_CODE_CASH_LOAN = 'cash_loan';
export const LEDGER_CODE_CASH_TOTAL = 'cash_total';
export const LEDGER_CODE_CASH_PAYOFFS = 'cash_payoffs';
export const LEDGER_CODE_PAID_BEFORE = 'paid_before';
export const LEDGER_CODE_COSTS_FINANCED = 'costs_financed';
export const LEDGER_CODE_DOWN_PAYMENT = 'down_payment';
export const LEDGER_CODE_CASH_DEPOSIT = 'cash_deposit';
export const LEDGER_CODE_FUNDS_BORROWER = 'funds_borrower';
export const LEDGER_CODE_SELLER_CREDITS = 'seller_credits';
export const LEDGER_CODE_ADJUSTMENTS_CREDITS = 'adjustments_credits';
export const LEDGER_CODE_CASH_CLOSE = 'cash_close';
export const LEDGER_CODE_TOTAL_RECEIPTS = 'total_receipts';
export const LEDGER_CODE_ALL_DISBURSEMENTS = 'all_disbursements';
export const LEDGER_CODE_FUNDING = 'funding';
export const LEDGER_CODE_POSTED_RECEIPTS = 'posted_receipts';
export const LEDGER_CODE_EXPECTED_RECEIPTS = 'expected_receipts';
export const LEDGER_CODE_POSTED_DISBURSEMENTS = 'posted_disbursements';
export const LEDGER_CODE_LENDER_OWES = 'lender_owes';
export const LEDGER_CODE_BORROWER_OWES = 'borrower_owes';
export const LEDGER_CODE_EARNEST_OWED = 'earnest_owed';
export const LEDGER_CODE_SELLER_DUE = 'seller_due';
export const LEDGER_CODE_OTHERS_DUE = 'others_due';
export const LEDGER_CODE_BALANCE = 'balance';
export const LEDGER_CODE_PRORATION_AMOUNT = "proration_amount";
export const LEDGER_CODE_PRORATION_PERDIEM = "proration_perdiem";
export const LEDGER_CODE_PRORATION_PRORATED = "proration_prorated";

export const PDF_CLOSING_DISCLOSURE = 'Closing Disclosure.pdf';

export const EARNEST_COMMISSIONS_NONE = 'none';
export const EARNEST_COMMISSIONS_LISTING_AGENT = 'listing-agent';
export const EARNEST_COMMISSIONS_RETURNED_BUYER = 'returned-to-buyer';
export const EARNEST_COMMISSIONS_SELLER = 'seller';
export const EARNEST_COMMISSIONS_BUYER_ATTORNEY = 'buyers-attorney';
export const EARNEST_COMMISSIONS_SELLER_ATTORNEY = 'sellers-attorney';
export const EARNEST_COMMISSIONS_SETTLEMENT_AGENCY = 'settlement-agency';
export const EARNEST_COMMISSIONS_SELLING_AGENT = 'selling-agent';

export const SOLE_OWNER = 'Sole Owner';
export const JOINT_TENANTS = 'Joint Tenants';
export const JOINT_TENANTS_WITH_RIGHT_OR_SURVIRVORSHIP = 'Joint Tenants with right or survirvorship';
export const TENANTS_IN_COMMON = 'Tenants in Common';
export const TENANTS_BY_THE_ENTIRETY = 'Tenants by the entirety';

export const INFORMATION = 'information';
export const NOTARY_BLOCKS = 'notary_blocks';
export const ATTORNEY = 'attorney';
export const SIGNATURE_AND_VESTING = 'signature_and_vesting';
export const BUYER_AGENT = 'Buyer Agent';
export const POA = 'POA';
export const EXCHANGE = 'Exchange';
export const SELLER_AGENT = 'Seller Agent';
export const AGENT = 'Agent';
export const SPOUSE = 'Spouse';
export const REAL_STATE_AGENT = 'Real Estate Agent';
export const TRANSACTION_COORDINATOR = 'Transaction Coordinator';

export const T7_TITLE_COMMITMENT = 'T7- Title Commitment';
export const DEED = 'Deed';

export const LISTING_ALLOWED_CD_AS = 'allowed_cd_as';
export const LISTING_ALLOWED_DISCLOSED_AS = 'allowed_disclosed_as';
export const LISTING_ENDORSEMENTS = 'allowed_policy_endorsements';
export const LISTING_LEDGER_ASSOCIATIONS = 'allowed_ledger_associations';
export const LISTING_OWNER_POLICY_TYPES = 'allowed_owner_policy_types';
export const LISTING_OWNER_POLICY_RATE_TYPES = 'allowed_owner_policy_rate_types';
export const LISTING_OWNER_POLICY_STATS = 'allowed_owner_policy_stats';
export const LISTING_LENDER_POLICY_TYPES = 'allowed_lender_policy_types';
export const LISTING_LENDER_POLICY_RATE_TYPES = 'allowed_lender_policy_rate_types';
export const LISTING_LENDER_POLICY_STATS = 'allowed_lender_policy_stats';

export const DOCUMENT_KIND_LIST = ['template', 'upload', 'pdf', 'zip'];

export const WIRETRANSFER_STATUS_STARTED = 'Started';
export const WIRETRANSFER_STATUS_ON_AUTHORIZATION = 'On authorization';
export const WIRETRANSFER_STATUS_AUTHORIZED = 'Authorized';
export const WIRETRANSFER_STATUS_REJECTED = 'Rejected';
export const WIRETRANSFER_STATUS_ON_SUBMISSION = 'On submission';
export const WIRETRANSFER_STATUS_SUBMITTED = 'Submitted';
export const WIRETRANSFER_STATUS_CONFIRMED = 'Confirmed';

export const WIRETRANSFER_STATUS_LIST = [
  WIRETRANSFER_STATUS_AUTHORIZED,
  WIRETRANSFER_STATUS_CONFIRMED,
  WIRETRANSFER_STATUS_ON_AUTHORIZATION,
  WIRETRANSFER_STATUS_ON_SUBMISSION,
  WIRETRANSFER_STATUS_REJECTED,
  WIRETRANSFER_STATUS_STARTED,
  WIRETRANSFER_STATUS_SUBMITTED,
];

export const ADDRESS_KIND_LOGIN = 'Login';

export const QUERY_KEY_NOTES = 'notes';

export const LISTING_CODE_ALLOWED_ORDER_ASSOCIATIONS = 'allowed_order_associations';
export const LISTING_CODE_ALLOWED_INTEREST_BLOCKS = 'allowed_interest_blocks';
export const LISTING_CODE_ALLOWED_TRANSACTIONS = "allowed_transactions";
export const LISTING_CODE_ALLOWED_WORKFLOWS = "allowed_workflows";
export const LISTING_CODE_ALLOWED_TYPES = "allowed_types";

export const CACHE_30_DAYS = 1000 * 60 * 60 * 24 * 30;

export const ALLOWED_LEDGER_ASSOCIATION_LATE_PENALTY = 'Late penalty';
export const ALLOWED_LEDGER_ASSOCIATION_ADDITIONAL_FEES = 'Additional fees';
export const ALLOWED_LEDGER_ASSOCIATION_DISCOUNTS = 'Discounts';
export const ALLOWED_LEDGER_ASSOCIATION_ORIGINATION_CHARGES = 'Origination charges';
export const ALLOWED_LEDGER_ASSOCIATION_POINTS = 'Points';
export const CONDITION_CAN_GO_AS_HIGH_AS_$ = 'Can go as high as $';
export const CONDITION_CAN_INCREASE_UNTIL_YEAR = 'Can increase until year ';

export const LOCAL_STORAGE_TABS_KEY = "tabs";