import { TableRow } from '@material-ui/core';
import React, { FC } from 'react';
import { Column } from 'types/common';

const ExpandableRow:FC = (props:any) => {
  const { columnCollapsing, itemsCollapsed } = props?.context;
  const { item } = props;
  return (
    <>
      <TableRow {...props} />
      {!!columnCollapsing?.length && (
      <TableRow>
        {columnCollapsing?.map(
          (columCollapse: Column, index: number) => columCollapse?.renderer
				  && itemsCollapsed
				  && itemsCollapsed[item?.id]
				  && columCollapse?.renderer({
				    row: item,
				    column: columCollapse,
				    index,
				    shouldCollapse: itemsCollapsed[item?.id],
				  })
			  )}

      </TableRow>
		  )}

    </>
  );
};

export default ExpandableRow;
