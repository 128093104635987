/* eslint-disable react-hooks/exhaustive-deps */
import { getParty } from 'graphql/parties/queries';
import useSearchParty from 'hooks/useSearchParty';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import graphql from 'services/querys';
import { NewUserType } from 'types/new-user';
import { NewOrderPartyType } from 'types/order';
import useAlert from 'utils/alert';

import PartiesContext from '../../../services/context';

const useSpouseTab = (
  partyId: string,
  roleParty: string,
  spouse: NewOrderPartyType | undefined,
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>,
  loaderActive: boolean
) => {
  const context = useContext(PartiesContext);
  const { filterParties } = context || {};
  const { t } = useTranslation();
  const [spouseID, setSpousetID] = useState<string | null>(null);
  const [spouseData, setSpouseData] = useState<NewUserType | null>(null);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { parties } = useSearchParty(searchText);
  const showAlert = useAlert();
  const { id: orderId } = useParams<{ id: string }>();
  const [addSpouse, addSpouseResponse] = useMutation(
    graphql.createAssociation,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          setLoaderActive(false);
          setSpousetID(null);
          showAlert(t('parties:spouse_add'), 'success');
          addSpouseResponse.reset();
          if (data?.createOrderAssociation.parties && filterParties) {
            filterParties(data?.createOrderAssociation?.parties!);
          }
        }, 1500);
      },
      onError: () => {
        setLoaderActive(false);
        showAlert(t('parties:mutation-error'), 'error');
      },
    }
  );
  const [removeSpouse, removeSpouseResponse] = useMutation(
    graphql.deleteAssociation,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          setLoaderActive(false);
          showAlert(t('parties:spouse_deleted'), 'success');
          setSpousetID(null);
          setSpouseData(null);
          removeSpouseResponse.reset();
          if (data?.deleteOrderAssociation.parties && filterParties) {
            filterParties(data?.deleteOrderAssociation?.parties!);
          }
        }, 1500);
      },
      onError: () => {
        setLoaderActive(false);
        showAlert(t('parties:mutation-error'), 'error');
      },
    }
  );

  useEffect(() => {
    if (orderId && partyId && spouseID) {
      setLoaderActive(true);
      addSpouse({
        id: orderId!,
        partyId: spouseID!,
        linkedToId: partyId,
        kind: 'Spouse',
        linkedToKind: roleParty,
      });
    }
  }, [orderId, partyId, roleParty, spouseID]);

  const getPartyResponse = useQuery(
    ['getParty', spouse?._id || spouseID],
    () => getParty({ id: spouse?._id || spouseID! }),
    {
      enabled:
        (!!spouse?._id || !!spouseID)
        && spouseData === null
        && !loaderActive
        && (addSpouseResponse.isSuccess || addSpouseResponse.isError),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setSpouseData(data.getParty);
      },
    }
  );

  return {
    t,
    parties,
    setSearchText,
    setSpousetID,
    orderId,
    deleteSpouse: removeSpouse,
    spouseData,
    isSpouseDataLoading:
      getPartyResponse.isLoading || getPartyResponse.isFetching,
  };
};

export default useSpouseTab;
