import React, { FC } from 'react';
import {
  NewBuyerSellerType,
  NewExchange1031Type,
  NewPOAType,
} from 'types/order';
import { NewUserType } from 'v2-types/user';

import useBuyerHooks from './buyer-hooks';
import BuyerScene from './buyer-scene';

type BuyerProps = {
  party: NewUserType | undefined;
  isPartyLoading: boolean;
  isModal: boolean;
};

export const Buyer: FC<BuyerProps> = ({ party, isPartyLoading, isModal }) => {
  const {
    currentTab,
    setcurrentTab,
    t,
    attorneys,
    methods,
    udpdateBuyerData,
    orderId,
    agents,
    poa,
    updatePOAData,
    exchange,
    updateExchangeData,
    spouse,
    realStateAgents,
    transactionCoordinators,
    loaderActive,
    setLoaderActive,
  } = useBuyerHooks(party?._id);

  const handleTabChange = (event: any, index: string) => {
    setcurrentTab(index);
  };

  const handleSaveInfo = (data_buyer: NewBuyerSellerType) => {
    if (orderId && party?._id) {
      udpdateBuyerData({
        id: orderId,
        partyId: party?._id,
        dataBuyer: data_buyer,
      });
    }
  };

  const handleSavePOA = (data_poa: NewPOAType) => {
    if (orderId && poa?._id) {
      updatePOAData({
        id: orderId,
        partyId: poa?._id,
        dataPoa: data_poa,
      });
    }
  };

  const handleSaveExchange = (data_exchange: NewExchange1031Type) => {
    if (orderId && exchange?._id) {
      updateExchangeData({
        id: orderId,
        partyId: exchange?._id,
        dataExchange: data_exchange,
      });
    }
  };

  return (
    <BuyerScene
      isModal={isModal}
      handleTabChange={handleTabChange}
      currentTab={currentTab}
      buyerInfo={party}
      t={t}
      attorneys={attorneys}
      methods={methods}
      handleSaveInfo={handleSaveInfo}
      agents={agents}
      poa={poa}
      handleSavePOA={handleSavePOA}
      exchange={exchange}
      handleSaveExchange={handleSaveExchange}
      spouse={spouse}
      isLoading={isPartyLoading}
      realStateAgents={realStateAgents}
      transactionCoordinators={transactionCoordinators}
      loaderActive={loaderActive}
      setLoaderActive={setLoaderActive}
    />
  );
};
