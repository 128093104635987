import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../text-field';

const useStyles = makeStyles((theme: any) => ({
  container: { padding: theme.spacing(4) },
  titleContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
  title: {
    color: theme.palette.primary.darker,
    textAlign: 'center',
  },
  avatar: { backgroundColor: theme.palette.tab.offselected },
  nameContainer: { paddingLeft: theme.spacing(3) },
  text: { color: theme.palette.tab.offselected },
  descriptionContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  confirmDescriptionContainer: { paddingBottom: theme.spacing(3) },
  confirmContainer: { paddingBottom: theme.spacing(3) },
  button: { textTransform: 'initial' },
  actionButton: { color: theme.palette.primary.darker },
  cancelButtonContainer: { marginRight: theme.spacing(3) },
}));

type Props = {
  open: boolean;
  title: string;
  name: string;
  initials: string;
  description: string;
  confirm?: boolean;
  confirmWord?: string;
  confirmButtonText?: string;
  handleConfirm: () => void;
  handleCancel: () => void;
};

const ConfirmDialog: FC<Props> = ({
  open,
  title,
  name,
  initials,
  description,
  confirm,
  confirmWord,
  confirmButtonText,
  handleConfirm,
  handleCancel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [value, setValue] = useState('');

  const validateConfirmWord = () => {
    if (!confirm) {
      return false;
    }

    const _value = value.trim().toLowerCase();

    if (_value === '') {
      return true;
    }

    if (_value !== confirmWord!.toLowerCase()) {
      return true;
    }

    return false;
  };

  const onClose = () => {
    setValue('');
    handleCancel();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogContent className={classes.container}>
        <Grid container direction="column">
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Avatar className={classes.avatar}>{initials}</Avatar>
                  </Grid>

                  <Grid item xs={10} className={classes.nameContainer}>
                    <Typography className={classes.text}>
                      {name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={clsx(classes.descriptionContainer, { [classes.confirmDescriptionContainer]: confirm })}
          >
            <Typography className={classes.text}>
              {description}
            </Typography>
          </Grid>

          {confirm && (
            <Grid item xs={12} className={classes.confirmContainer}>
              <TextField
                label={t('users:dialog-confirm-label').replace('${}', confirmWord!.toUpperCase())}
                value={value}
                onChange={(event) => setValue(event.target.value)}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container direction="row-reverse">
              <Grid item>
                <Button
                  disableElevation
                  color="secondary"
                  variant="contained"
                  disabled={validateConfirmWord()}
                  className={clsx(classes.button, classes.actionButton)}
                  onClick={() => {
                    onClose();
                    handleConfirm();
                  }}
                >
                  {confirmButtonText || t('common:confirm')}
                </Button>
              </Grid>

              <Grid item className={classes.cancelButtonContainer}>
                <Button className={classes.button} onClick={onClose}>
                  {t('common:cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  confirmWord: 'delete',
  confirm: true,
  confirmButtonText: undefined,
};

export default ConfirmDialog;
