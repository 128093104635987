import { getNarrative } from 'graphql/narrative/queries';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CACHE_30_DAYS } from 'utils/constants';

export const useNarrativeHooks = () => {
  const { id: orderId } = useParams<{ id: string }>();

  const { isLoading, data } = useQuery([{ orderId }, 'order-narrative'], getNarrative, {
    refetchOnWindowFocus: false,
    staleTime: CACHE_30_DAYS
  });

  return {
    isLoading,
    narrative: data?.getOrder.narrative ?? ''
  };
};
