import { AccountingInvolvedParty } from 'v2-types/order';

export const filterPartiesByKindList = (parties: AccountingInvolvedParty[], kindList?: string[]) => {
  let filteredParties = parties;
  if (kindList) {
    filteredParties = parties.filter((party) => kindList.some((kind) => party.kind === kind));
  }

  return filteredParties;
};
