import { useCreateProration } from "hooks/useCreateProration";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useAlert from "utils/alert";
import { Proration } from "v2-types/order";
import { CreateProrationForm } from "./types";

type Params = {
  updateProrationsCache: (prorations: Proration[]) => void,
  onClose: () => void
}

export const useCreateProrationModal = ({
  updateProrationsCache,
  onClose
}: Params) => {
  const { id: orderId } = useParams<{ id: string}>();
  const showAlert = useAlert();
  const prorationForm = useForm<CreateProrationForm>();

  const [createProrationMutation, { isLoading }] = useCreateProration({
    queryConfig: {
      onSuccess: (data) => {
        updateProrationsCache(data.createOrderProration.prorations);
        showAlert("Proration created successfully", "success");
        onClose();
      },
      onError: () => {
        showAlert("Unexpected error ocurred", "error");
      }
    }
  });

  const onConfirm = prorationForm.handleSubmit((data) => {
    createProrationMutation({
      orderId,
      ...data
    });
  });

  return {
    isLoading,
    prorationForm,
    onConfirm,
  };
};