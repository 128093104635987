import { BaseButton, BaseButtonProps } from 'components/BaseButton';
import React, { FC } from 'react';

type OutlinedButtonProps = BaseButtonProps

export const OutlinedButton: FC<OutlinedButtonProps> = (props) => (
  <BaseButton
    buttonVariant="outlined"
    buttonColor="secondary"
    {...props}
  />
);
