import { Box, makeStyles } from '@material-ui/core';
import TableComponent from 'components/Table';
import React, { FC } from 'react';
import { generateCollapseRow } from 'views/orders/charges-accounting/components/prototype-new-table-charges/services';

import { ColumnsType } from './services';

const useStyles = makeStyles((theme) => ({
  padding: { padding: theme.spacing(3) },
  containerTable: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
  },
  scrollDiv: {
    overflowX: 'auto',
    width: '100%',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.5) },
    '&&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark },
  },
}));

type TableChargesProps = {
	columns:ColumnsType,
	mapedData:any,
	handleClickItem?:(item:any)=>void,
	itemsCollapsed:{
		[key: string]: boolean;
	}
}

const TableChargesScene:FC<TableChargesProps> = ({
  columns,
  mapedData,
  handleClickItem,
  itemsCollapsed,
}) => {
  const classes = useStyles();
  const collapsedcolumns = generateCollapseRow({ });

  return (
    <Box className={`${classes.scrollDiv} ${classes.containerTable}`}>
      <TableComponent
        pointer
        disabledBorderLeft
        disableFullborderCell
        columns={columns}
        dataSet={mapedData}
        onClickItem={(row: any, __: any, index) => {
          if (handleClickItem) {
            handleClickItem({ ...row, indexRow: index });
          }
        }}
        columnCollapsing={collapsedcolumns}
        itemsCollapsed={itemsCollapsed}
      />
    </Box>
  );
};

export default TableChargesScene;
