import DateFnsUtils from '@date-io/date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  input: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: theme.palette.background.default,
    },
  },
  naked: {
    '&:focus': { backgroundColor: theme.palette.background.default },
    '& .MuiFilledInput-input': {
      paddingTop: 10,
      paddingLeft: 0,
    },
    '& .MuiFilledInput-root': {
      textTransform: 'none',
      fontSize: '0.875rem',
      color: 'black',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      backgroundColor: 'transparent',
    },
  },
  icon: { padding: 0 },
}));

type TimePickerProps = {
    value: ParsableDate;
    onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void
    label?: React.ReactNode
}

const TimePicker: FC<TimePickerProps> = ({ value, onChange, label }) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        fullWidth
        value={value}
        label={label}
        onChange={onChange}
        className={classes.input}
        inputVariant="filled"
        keyboardIcon={(
          <InputAdornment position="end">
            <FontAwesomeIcon icon="clock" size="xs" />
          </InputAdornment>
      )}
      />
    </MuiPickersUtilsProvider>
  );
};

TimePicker.defaultProps = { label: undefined };

export default TimePicker;
