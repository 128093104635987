import { getLoans, GetLoansReturn } from 'graphql/loans/queries';
import { QueryConfig, useQuery } from 'react-query';
import { orderAccountingLoanEntriesByDescription } from 'utils/accounting';

type Params = {
  orderId: string,
  queryConfig?: QueryConfig<GetLoansReturn, Error>
}

export const useGetLoans = ({ orderId, queryConfig }: Params) => useQuery([{ orderId }, 'loans'], {
  ...queryConfig,
  queryFn: async () => {
    const response = await getLoans({ orderId });
    response.getOrder.loans = response.getOrder.loans.map((loan) => ({
      ...loan,
      accounting: orderAccountingLoanEntriesByDescription(loan.accounting)
    }));
    return response;
  }
});
