import { TFunction } from 'i18next';

export const optionsJurisdiction = (t: TFunction) => [
  { value: 'Borough', text: t('common:borough') },
  { value: 'City', text: t('common:city') },
  { value: 'Town', text: t('common:town') },
  { value: 'Township', text: t('orders:township') },
  { value: 'Unincorporated Area', text: t('common:unincorporated-area') },
  { value: 'Village', text: t('common:village') }
];
export const propertyTypeOptions = (t: TFunction) => [
  { value: 'Residence (1-4 Families)', text: t('common:residence-1-4') },
  { value: 'Residence (5+ Families)', text: t('common:residence-5+') },
  { value: 'Commercial', text: t('common:commercial') },
  { value: 'Condominium', text: t('common:condominium') },
  { value: 'Planned Unit Development', text: t('common:planned-unit') },
  { value: 'Land and Buildings', text: t('common:land-and-buildings') },
  { value: 'Land Only', text: t('ordersProperties:land-only') },
  { value: 'Leasehold', text: t('ordersProperties:leasehold') },
  { value: 'Commercial Leasehold', text: t('ordersProperties:commercial-le') },
  { value: 'Manufactured Home', text: t('ordersProperties:manufactured') },
  { value: 'Mobile Home', text: t('ordersProperties:mobile-home') },
  { value: 'Wetlands', text: t('ordersProperties:wetlands') }
];
