import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  makeStyles,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { Alert } from '@material-ui/lab';
import { TFunction } from 'i18next';
import React, { FC, useEffect, useState } from 'react';
import { infoUpdateType } from 'types/notifications';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

type SnackBarTypes = {
  notification: infoUpdateType;
  t: TFunction;
};

const useStyles = makeStyles(() => ({
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  weightText: { fontWeight: 900, },
}));

const SnackBar: FC<SnackBarTypes> = ({ notification, t }) => {
  const [open, setOpen] = useState(true);

  const { name, kind } = notification;

  useEffect(() => {
    setOpen(true);
  }, [notification]);

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      TransitionComponent={TransitionDown}
      message="I love snacks"
      key={1}
    >
      <Alert
        className={classes.alertContainer}
        icon={false}
        severity="success"
        action={(
          <IconButton onClick={handleClose} color="inherit">
            <FontAwesomeIcon icon="times" />
          </IconButton>
        )}
      >
        <Typography className={classes.weightText}>
          {`${name} ${t(`notifications:${kind}`)}`}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
