import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import DatePicker from 'components/date-picker/date-picker';
import NumberField from 'components/number-field';
import React, { FC } from 'react';

const useStyles = makeStyles((theme:any) => ({
  labelContainer: {
	  display: 'flex',
	  alignItems: 'center',
	  justifyContent: 'space-between',
  },
  label: { color: theme.palette.tab.offselected },
  paddingByItem: { '& > * ': { marginRight: theme.spacing(1) } },
}));

type lineItemProps= {
  value:string | number,
  label:string,
  date:string,
  handlePayments:()=>void,
  handleEdit:()=>void,
  handleDelete?:()=>void
}

const LineItem:FC<lineItemProps> = ({
  value,
  label,
  date,
  handlePayments,
  handleEdit,
  handleDelete
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} style={{ padding: 8 }}>
      <Grid container spacing={2}>
        <Grid item xs={4} className={classes.labelContainer}>
          <Box display="flex" flexDirection="row" className={classes.paddingByItem}>
            <IconButton
              style={{ padding: 0 }}
              onClick={(e) => {
			  e.stopPropagation();
			  handleEdit();
              }}
            >
              <FontAwesomeIcon size="xs" icon="edit" />
            </IconButton>
            <IconButton
              style={{ padding: 0 }}
              onClick={(e) => {
				  e.stopPropagation();
				  handlePayments();
              }}
            >
              <FontAwesomeIcon size="xs" icon="dollar-sign" />
            </IconButton>
            {handleDelete
            && (
            <IconButton
              style={{ padding: 0 }}
              onClick={(e) => {
				  e.stopPropagation();
				  handleDelete();
              }}
            >
              <FontAwesomeIcon size="xs" icon="trash" />
            </IconButton>
            )}
          </Box>
          <Box>
            <Typography className={classes.label}>
              {label}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <NumberField
            thousandSeparator
            decimalScale={2}
            variant="outlined"
            disabled
            value={value as number}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            disabled
            value={date}
            label="Date"
            onChange={() => {}}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LineItem;
