import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import SelectField from 'components/select-field';
import { TFunction } from 'i18next';
import React from 'react';
import { OrderProperty } from 'types/order';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import { AddAndUpdatePartyLedgerEntry } from '../../../components/add-and-update-party-ledger-modal/add-and-update-party-ledger-entry';
import { TableEntries } from './components/table-entries';

const useStyles = makeStyles((theme) => ({
  rootContainer: { padding: theme.spacing(3) },
  mainContainer: { padding: theme.spacing(3) },
  title: { marginBottom: theme.spacing(2) },
  buttonAddEntry: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
  },
  tableContainer: { marginTop: theme.spacing(3) },
  emptyEntriesContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
  },
  emptyEntriesTitle: {
    color: theme.palette.primary.light,
    fontWeight: 100,
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1 },
}));

type EarnestAndCommissionsSceneProps = {
  t: TFunction;
  selectedProperty: OrderProperty;
  properties: OrderProperty[];
  selectProperty: (id: string) => void;
  purchasePriceEntries: AccountingInvolvedParty[];
  setPartyLedgerEntry: React.Dispatch<
    React.SetStateAction<AccountingInvolvedParty | undefined>
  >;
  setOpenPartyLedgerModal: React.Dispatch<React.SetStateAction<boolean>>;
  partyLedgerEntry: AccountingInvolvedParty | undefined;
  entryLedger: AccountingType | undefined;
  openPartyLedgerModal: boolean;
  onUpdatePurchasePriceEntries: (accounting: AccountingType[]) => void;
  onRemoveOrderPartyEntry: (entry: AccountingInvolvedParty) => void;
  isLoading: boolean;
};

const EarnestAndCommissionsScene = ({
  t,
  selectedProperty,
  properties,
  selectProperty,
  purchasePriceEntries,
  entryLedger,
  setOpenPartyLedgerModal,
  setPartyLedgerEntry,
  partyLedgerEntry,
  openPartyLedgerModal,
  onUpdatePurchasePriceEntries,
  onRemoveOrderPartyEntry,
  isLoading,
}: EarnestAndCommissionsSceneProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.rootContainer}>
      <Paper className={classes.mainContainer}>
        <Typography variant="h6" className={classes.title}>
          {t('earnestAndCommissions:earnest-and-commissions')}
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <SelectField
              label="Properties"
              value={selectedProperty._id}
              data={properties.map((property) => ({
                _id: property._id,
                address: property.address.address,
              }))}
              dataKey="_id"
              dataValue="_id"
              dataText="address"
              handleChange={(e) => selectProperty(e.target.value)}
            />
          </Grid>
          {selectedProperty._id !== '' && (
            <Grid item xs={6} className={classes.buttonAddEntry}>
              <Button
                color="secondary"
                style={{
                  textTransform: 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                startIcon={(
                  <FontAwesomeIcon
                    icon="plus"
                    color="secondary"
                    style={{ fontSize: '0.875rem' }}
                  />
                )}
                onClick={() => setOpenPartyLedgerModal(true)}
              >
                <Typography variant="body2" style={{ fontWeight: 500 }}>
                  {t('accounting:add-entry')}
                </Typography>
              </Button>
            </Grid>
          )}

          <Grid item xs={12} className={classes.tableContainer}>
            {purchasePriceEntries.length > 0 ? (
              <TableEntries
                setOpenPartyLedgerModal={setOpenPartyLedgerModal}
                setPartyLedgerEntry={setPartyLedgerEntry}
                purchasePriceEntries={purchasePriceEntries}
                t={t}
                onRemoveOrderPartyEntry={onRemoveOrderPartyEntry}
              />
            ) : (
              <Box className={classes.emptyEntriesContainer}>
                <Typography variant="h6" className={classes.emptyEntriesTitle}>
                  {t('earnestAndCommissions:empty-entries')}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        {entryLedger && openPartyLedgerModal && (
          <AddAndUpdatePartyLedgerEntry
            kindAllowedAssociationList={['earnest', 'commission', 'option']}
            entryLedger={entryLedger}
            partyLedgerEntry={partyLedgerEntry}
            handleClose={() => {
              setPartyLedgerEntry(undefined);
              setOpenPartyLedgerModal(false);
            }}
            handleSuccess={onUpdatePurchasePriceEntries}
          />
        )}
      </Paper>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </Box>
  );
};

export default EarnestAndCommissionsScene;
