import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles
} from '@material-ui/core';
import ContactCreation from 'components/users/form-contact-creation/contact-creation';
import React, { FC } from 'react';

import { ContactKind } from '../../types';
import { useModalCreateContact } from './modal-create-contact-hooks';

const useStyles = makeStyles((theme: any) => ({
  title: { color: theme.palette.tab.offselected },
  buttonText: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
}));

type ModalCreateContactProps = {
  open: boolean;
  onClose: () => void;
  searchText?: string;
  contactKind?: ContactKind
};

export const ModalCreateContact: FC<ModalCreateContactProps> = ({
  open,
  onClose,
  searchText,
  contactKind
}) => {
  const {
    t,
    history,
    created,
    createdId,
    setCreated,
    setCreatedId
  } = useModalCreateContact();
  const classes = useStyles();

  const handleNavigateEdit = () => createdId && history.push(`/contacts/${createdId}`);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={() => {
        setCreated(false);
        onClose();
      }}
    >
      <DialogTitle className={classes.title}>
        {t('users:create-user')}
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" spacing={2}>

          <Grid item xs={12}>
            <Grid container direction="column">
              <ContactCreation
                selectedKind={contactKind ?? 'Person'}
                onSuccess={({ _id }) => {
                  setCreatedId(_id);
				          setCreated(true);
                }}
                searchText={searchText}
              />
            </Grid>
          </Grid>
          {created && (
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Button
                  disableElevation
                  variant="contained"
                  color="secondary"
                  className={classes.buttonText}
                  onClick={handleNavigateEdit}
                  disabled={!created}
                >
                  {t('contact:go-to-created-contact')}
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  color="secondary"
                  className={classes.buttonText}
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('common:close')}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
