import { BaseButton, BaseButtonProps } from 'components/BaseButton';
import React, { CSSProperties, FC } from 'react';

type OptionButtonProps = BaseButtonProps & {
  isSelected: boolean,
  backgroundSelected?: CSSProperties['backgroundColor'],
  textColorSelected?: CSSProperties['color']
}

export const OptionButton: FC<OptionButtonProps> = ({
  isSelected,
  backgroundSelected,
  textColorSelected,
  ...props
}) => {
  let backgroundColor: CSSProperties['backgroundColor'] = '#ffffff';
  if (isSelected) {
    backgroundColor = backgroundSelected ?? '#000000';
  }

  let textColor: CSSProperties['color'];
  if (isSelected) {
    textColor = textColorSelected ?? '#ffffff';
  }

  return (
    <BaseButton
      buttonVariant="outlined"
      buttonColor="secondary"
      backgroundColor={backgroundColor}
      textColor={textColor}
      borderColor="#000000"
      {...props}
    />
  );
};
