import { CashToCloseData } from 'hooks/useGetCashToClose/types';
import {
  LEDGER_CODE_ADJUSTMENTS_CREDITS,
  LEDGER_CODE_CASH_CLOSE,
  LEDGER_CODE_CASH_DEPOSIT,
  LEDGER_CODE_CASH_LOAN,
  LEDGER_CODE_CASH_PAYOFFS, LEDGER_CODE_CASH_TOTAL, LEDGER_CODE_COSTS_FINANCED, LEDGER_CODE_DOWN_PAYMENT, LEDGER_CODE_FUNDS_BORROWER, LEDGER_CODE_PAID_BEFORE, LEDGER_CODE_SELLER_CREDITS
} from 'utils/constants';

export const initialData: CashToCloseData = {
  loan_amount: {
    changed: false,
    changed_text: '',
    entry_code: LEDGER_CODE_CASH_LOAN,
    estimate_amount: 0,
    final: 0
  },
  total_closing_costs_j: {
    entry_code: LEDGER_CODE_CASH_TOTAL,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
  paid_before_closing: {
    entry_code: LEDGER_CODE_PAID_BEFORE,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
  cash_to_close: {
    entry_code: LEDGER_CODE_CASH_CLOSE,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
  adjustments_and_other_credits: {
    entry_code: LEDGER_CODE_ADJUSTMENTS_CREDITS,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
  closing_costs_financed: {
    entry_code: LEDGER_CODE_COSTS_FINANCED,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
  deposit: {
    entry_code: LEDGER_CODE_CASH_DEPOSIT,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
  down_payment_from_borrower: {
    entry_code: LEDGER_CODE_DOWN_PAYMENT,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
  funds_for_borrower: {
    entry_code: LEDGER_CODE_FUNDS_BORROWER,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
  seller_credits: {
    entry_code: LEDGER_CODE_SELLER_CREDITS,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
  total_payoffs_and_payments_k: {
    entry_code: LEDGER_CODE_CASH_PAYOFFS,
    changed: false,
    changed_text: '',
    estimate_amount: 0,
    final: 0
  },
};
