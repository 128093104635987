import gqlRequest from "graphql/generic";
import { AccountingType } from "v2-types/order";

import { PolicyType } from "../services/types";

type CommonResponse = {
  getOrder: {
    accounting: AccountingType[] | null;
    configuration: { type: string; transaction_type: string };
    owners_policy: PolicyType;
    lenders_policy: PolicyType;
    parties: {
      _id: string;
      name: string;
    }[];
  };
};

type QueryParam = { id: string };

const query = `
query GetOrder($id: ID!) {
  getOrder(_id: $id) {
    accounting {
      _id
      amount
      amount_calculated
      amount_override
      code
      kind
      letter
      number
      entry_date
      description
      involved_parties {
        _id
        amount
        at_closing_amount
        at_closing_percent
        before_closing_amount
        before_closing_percent
        kind
        name
        order_kinds
        payment_id
        payment_kind
        payment_reference
        percent
      }  
    }
    configuration {
      type
      transaction_type
    }
    owners_policy {
      disclosure_section
      endorsements_shown_as
      policy_type
      proposed_insured
      rate_type
      remove_section_13
      stat_code
    }
    lenders_policy {
      disclosure_section
      endorsements_shown_as
      policy_type
      proposed_insured
      rate_type
      remove_section_13
      stat_code
    }
    parties {
      _id
      name
      kinds
    }
  }
}
`;

export const getOrder = <T>(id: string) =>
  gqlRequest<CommonResponse & T, QueryParam>(query, { id });

export default {};
