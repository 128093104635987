import {
  ALLOWED_LEDGER_ASSOCIATION_ADDITIONAL_FEES,
  ALLOWED_LEDGER_ASSOCIATION_DISCOUNTS,
  ALLOWED_LEDGER_ASSOCIATION_LATE_PENALTY,
  ALLOWED_LEDGER_ASSOCIATION_ORIGINATION_CHARGES,
  ALLOWED_LEDGER_ASSOCIATION_POINTS
} from 'utils/constants';

export const kindAllowedAssociationList = [
  ALLOWED_LEDGER_ASSOCIATION_LATE_PENALTY,
  ALLOWED_LEDGER_ASSOCIATION_ADDITIONAL_FEES,
  ALLOWED_LEDGER_ASSOCIATION_DISCOUNTS,
  ALLOWED_LEDGER_ASSOCIATION_ORIGINATION_CHARGES,
  ALLOWED_LEDGER_ASSOCIATION_POINTS
];
