import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React from 'react';

const useStyles = makeStyles((theme: any) => ({
  toast: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    color: theme.palette.primary.main,
  },
}));

const useAlert = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const create = (
    message: string,
    severity: 'error' | 'success' | 'warning' | 'info',
    timeOut: number = 3000
  ) => enqueueSnackbar(message, {
    content: (key, _message) => (
      <Alert
        key={key}
        elevation={6}
        variant="filled"
        severity={severity}
        className={classes.toast}
      >
        {_message}
      </Alert>
    ),
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    autoHideDuration: timeOut,
  });

  return create;
};

export default useAlert;
