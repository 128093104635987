import { useMemo } from 'react';
import {
  LISTING_ALLOWED_CD_AS,
  LISTING_ALLOWED_DISCLOSED_AS,
  LISTING_ENDORSEMENTS,
  LISTING_LEDGER_ASSOCIATIONS,
  LISTING_LENDER_POLICY_RATE_TYPES,
  LISTING_LENDER_POLICY_STATS,
  LISTING_LENDER_POLICY_TYPES,
  LISTING_OWNER_POLICY_RATE_TYPES,
  LISTING_OWNER_POLICY_STATS,
  LISTING_OWNER_POLICY_TYPES,
} from 'utils/constants';
import useGetListings from 'utils/getlistings';

import { ListingsType } from './types';

const useListings = (): ListingsType => {
  const listings = useGetListings();

  const options = useMemo(() => new Map(
    (listings.data?.getListings || []).map((e) => [e.code, e.entries])
  ), [listings]);

  return {
    show_cd_as: options.get(LISTING_ALLOWED_CD_AS) || [],
    endorsements: options.get(LISTING_ENDORSEMENTS) || [],
    disclosed_as: options.get(LISTING_ALLOWED_DISCLOSED_AS) || [],
    ledger_associations: options.get(LISTING_LEDGER_ASSOCIATIONS) || [],
    owners_policy: {
      policy_types: options.get(LISTING_OWNER_POLICY_TYPES) || [],
      policy_rate_types: options.get(LISTING_OWNER_POLICY_RATE_TYPES) || [],
      policy_stats: options.get(LISTING_OWNER_POLICY_STATS) || [],
    },
    lenders_policy: {
      policy_types: options.get(LISTING_LENDER_POLICY_TYPES) || [],
      policy_rate_types: options.get(LISTING_LENDER_POLICY_RATE_TYPES) || [],
      policy_stats: options.get(LISTING_LENDER_POLICY_STATS) || [],
    }
  };
};

export default useListings;
