import { LoanFormType } from '../types';

export const loanInitialValues: LoanFormType = {
  amount_kind: 'net',
  commitment_date: '',
  construction: false,
  construction_initial: 0,
  grace_days: 0,
  heloc: false,
  heloc_draw: '',
  insurance_case: '',
  mers_min: '',
  mers: false,
  payment_first_date: '',
  payment_last_date: '',
  term_months: 0,
  term_years: 0,
  loan_number: '',
  terms: '',
  product: '',
  purpose: '',
  buyer_issue_date: '',
  seller_issue_date: '',
  late_payment_days: 0,
  transfer_assumption: null,
  demand_feature: null,
  negative_amortization: null,
  partial_payment_accept: null,
  partial_payment_denied: null,
  partial_payment_hold: null,
  escrow: null,
  escrow_declined: null,
  unpaid_liability: null,
  increase_loan_amount: null,
  increase_loan_amount_text: '',
  increase_interest_rate: null,
  increase_interest_rate_text: '',
  increase_monthly_principal: null,
  increase_monthly_principal_text: '',
  has_prepayment_penalty: null,
  has_prepayment_penalty_text: '',
  has_balloon_payment: null,
  has_balloon_payment_text: '',
  penalty_as_percent: false
};
