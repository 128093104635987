import SelectField from 'components/select-field';
import TextField from 'components/text-field';
import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewPartyType, NewUserType } from 'types/new-user';
import { CONTACT_TITLE_ADMINISTRATOR, CONTACT_TITLE_AGENCY, CONTACT_TITLE_AGENT } from 'utils/constants';

const titleRoles = [
  CONTACT_TITLE_AGENT,
  CONTACT_TITLE_ADMINISTRATOR,
  CONTACT_TITLE_AGENCY,
];

type KindFieldProps = {
  isTitleCompany: boolean,
  isTitleAgency: boolean,
  control: Control<NewPartyType & { selected: NewUserType | null;}, object>,
  errors: FieldErrors<NewPartyType & {selected: NewUserType | null;}>
}

export const KindField: FC<KindFieldProps> = ({
  isTitleCompany,
  isTitleAgency,
  control,
  errors
}) => {
  const { t } = useTranslation();

  if (isTitleCompany || isTitleAgency) {
    let titleKinds = titleRoles;
    if (isTitleAgency) titleKinds = titleKinds.filter((kind) => kind !== CONTACT_TITLE_AGENCY);

    return (
      <Controller
        name="kind"
        control={control}
        render={({ field: { ref, onChange, ...field } }) => (
          <SelectField
            {...field}
            inputRef={ref}
            data={titleKinds}
            dataKey=""
            dataText=""
            dataValue=""
            label={t('users:kind')}
            handleChange={onChange}
            error={!!errors.kind}
            helperText={
              errors.kind?.message || t('validations:required')
            }
          />
        )}
      />
    );
  }

  return (
    <Controller
      name="kind"
      control={control}
      render={({ field: { ref, ...field } }) => (
        <TextField
          {...field}
          inputRef={ref}
          label={t('users:kind')}
          error={!!errors.kind}
          helperText={
            errors.kind?.message || t('validations:required')
          }
        />
      )}
    />
  );
};
