import {
  Box,
  Grid,
  makeStyles,
  Paper
} from '@material-ui/core';
import AddLedgerEntry from 'components/add-entry-modal/add-entry-modal';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

import Header from '../components/header';
import PayorInfo from '../components/payor-info';
import { columnsDataSet } from '../components/table-charges-kmln/services';
import TablePrototypeKdues from '../components/table-charges-kmln/table-prototype-kdues';
import { chargesSorted, getDisabledFieldsv2, getHideFieldsv2 } from '../services';
import { mockData } from './services';

const useStyles = makeStyles((theme) => ({
  padding: { padding: theme.spacing(3) },
  marginCard: { marginBottom: theme.spacing(2) }
}));

type ChargesJKProps = {
	accountingData:AccountingType[],
	handleUpdateAccounting:(acc:AccountingType[])=> void,
	handleEditModal:(item:columnsDataSet)=> void,
	handlePayor:(item:columnsDataSet)=> void,
	handleDelete:(item:columnsDataSet)=> void,
	openAddModal: boolean,
	handleaddModal: (modal:string | null)=>void,
	state:{type: string;data?: any;}
}

const ChargesJKScene:FC<ChargesJKProps> = ({
  accountingData,
  handleUpdateAccounting,
  handleEditModal,
  handlePayor,
  handleDelete,
  openAddModal,
  handleaddModal,
  state
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentChargeData = accountingData.find((acc) => acc._id === state.data?.chargeData?._id);

  const mapedData = useMemo(() => mockData(chargesSorted(accountingData, true)), [accountingData]);
  return (
    <>
      <Grid container direction="column" className={classes.padding}>
        <Grid item xs={12}>
          <Paper>
            <Header
              title={t('charges:summary-jk')}
              chargesIdentifier="J-K"
            />
            <Grid container direction="row">
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column">
                  <TablePrototypeKdues
                    data={mapedData.grossAmountBorrower}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:gross-amount-due')}
                    type="dues"
                  />
                  <TablePrototypeKdues
                    data={[...mapedData.JAdjustmentsItems, ...mapedData.JGrosstotals, ...mapedData.JAdjustmentsItemssecond]}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:adjustments-for-items')}
                    type="adjustments"
                  />
                  <TablePrototypeKdues
                    data={[...mapedData.JUnpaidItems, ...mapedData.JUnpaidTotals, ...mapedData.CashBorrower]}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:adjustments-for-items-updaid')}
                    type="adjustments"
                  />

                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column">
                  <TablePrototypeKdues
                    data={mapedData.grossAmountSeller}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:gross-due-seller')}
                    type="dues"
                  />
                  <TablePrototypeKdues
                    data={[...mapedData.KAdjustments, ...mapedData.KGrosstotals, ...mapedData.KAdjustmentssecond]}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:adjustments-for-items')}
                    type="adjustments"
                  />
                  <TablePrototypeKdues
                    data={[...mapedData.KUnpaidItems, ...mapedData.KUnpaidTotals, ...mapedData.CaskSeller]}
                    handleEditModal={handleEditModal}
                    handleDelete={handleDelete}
                    handlePayor={handlePayor}
                    title={t('charges:adjustments-for-items-updaid')}
                    type="adjustments"
                  />

                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {state.type === 'parties' && currentChargeData?._id
      && (
        <Grid item xs={12}>
          <PayorInfo
            handleSuccess={handleUpdateAccounting}
            chargeData={currentChargeData}
          />
        </Grid>
      )}
      </Grid>

      {openAddModal && (
      <AddLedgerEntry
        open={!!openAddModal}
        handleClose={() => { handleaddModal(null); }}
        onSuccesAddEntry={handleUpdateAccounting}
        prevData={state.type === 'edit' ? state.data?.chargeData! : null}
        kindAllowedEntry={['manual']}
        codeAllowedEntry={['charge']}
        disableFields={getDisabledFieldsv2(state)}
        hideFields={getHideFieldsv2(state)}
        isEditingEntry={state.type === 'edit' && state.data?.chargeData?._id.length > 10}
      />
      )}
    </>
  );
};

export default ChargesJKScene;
