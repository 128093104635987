import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import useAccessRequired from './access-required-hooks';

const useStyles = makeStyles((theme:any) => ({
  container: {
    height: '100%',
    backgroundColor: theme.palette.offBackground.default,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  logo: {
    marginTop: theme.spacing(11),
    alignSelf: 'center',
    marginBottom: theme.spacing(5)
  },
  fontbasic: {
    color: theme.palette.tab.offselected,
    alignSelf: 'center',
  },
  fontTitle: {
    fontWeight: 500,
    marginTop: theme.spacing(11),
    marginBottom: theme.spacing(2)
  },

}));

type AccesRequiredProps = {
  children?: React.ReactElement
}

const AccessRequired = ({ children }:AccesRequiredProps):React.ReactElement => {
  const { isAdmin, t } = useAccessRequired();
  const classes = useStyles();
  if (isAdmin) {
    return children!;
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      className={classes.container}
    >
      <Typography variant="h3" className={clsx(classes.fontTitle, classes.fontbasic)}>{`${t('users:sorry')}!`}</Typography>
      <Typography variant="h5" className={classes.fontbasic}>{`${t('users:you-dont-have-access')}`}</Typography>
      <Typography variant="h5" className={classes.fontbasic}>{`${t('users:contact-to-the-admin')}`}</Typography>
    </Box>
  );
};

AccessRequired.defaultProps = { children: undefined };
export default AccessRequired;
