import { useOrderTabs } from 'hooks/useOrderTabs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { setOrderConfiguration } from 'redux/order-configuration-reducer/actions';
import { setCurrentOrder } from 'redux/order-reducer/actions';
import { setIdOrdenSelected, setWorkspace } from 'redux/workspace-reducer/actions';
import services from 'services/endpoints';
import querys from 'services/querys';
import { OrderType } from 'types/order';
import { initialDisclosuresValues } from 'utils/initial-values';

export default function useOrdersLayout() {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [sidemenuOpen, setSidemenuOpen] = useState(true);

  const [currentOrderDetail, setCurrentOrderDetail] = useState<OrderType | undefined>(undefined);

  const { id: orderId } = useParams<{ id: string }>();

  const {
    addOrderTab,
    removeOrderTab,
    openedOrderIdList: openedOrders,
    selectedOrderIdTab,
    selectOrderTab
  } = useOrderTabs();

  const order = useQuery<OrderType>(
    ['orderById', orderId],
    services.getOrderById,
    {
      refetchOnWindowFocus: false,
      enabled: orderId !== undefined,
      onSuccess: (data) => {
        if (data) {
          setCurrentOrderDetail(data);
          dispatch(setWorkspace({ [orderId]: data }));
        }
      }
    },
  );
  const getGraphOrder = useQuery(
    ['party-order', orderId],
    querys.getOrder,
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          const { configuration } = data.getOrder;
          if (configuration) {
            dispatch(setOrderConfiguration(configuration));
          }
        }
        const orderTab = {
          id: data.getOrder._id,
          orderId: data.getOrder.order_id
        };
        addOrderTab(orderTab);
        dispatch(setIdOrdenSelected(orderId));
      }
    },
  );

  useEffect(() => {
    if (currentOrderDetail) {
      dispatch(setCurrentOrder({
        ...currentOrderDetail,
        disclosures: currentOrderDetail?.disclosures || initialDisclosuresValues,
      }));
    }
  }, [currentOrderDetail, dispatch]);

  const orderType = getGraphOrder.data?.getOrder.configuration?.type || "";
  const properties = getGraphOrder.data?.getOrder.estates || [];
  const parties = getGraphOrder.data?.getOrder.parties || [];
  const isOrderLoading = getGraphOrder.isLoading;
  const orderHasAccountingData = Boolean(getGraphOrder.data?.getOrder.accounting);
  const idTitleCompany = getGraphOrder.data?.getOrder.parties.find((party) => party.kinds.includes("Title Company"))?._id ?? "";

  return {
    t,
    url,
    history,
    sidemenuOpen,
    setSidemenuOpen,
    properties,
    parties,
    isOrderLoading,
    openedOrders,
    statusFetchOrderData: order.status,
    orderHasAccountingData,
    orderType,
    idTitleCompany,
    removeOrderTab,
    selectedOrderIdTab,
    selectOrderTab
  };
}
