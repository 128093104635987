import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import PartiesContext from 'views/orders/new-parties/services/context';

import { PartyType } from '../../../services';
import GetICon from './get-icon-party';

const useStyles = makeStyles((theme: any) => ({
  sideContainer: { padding: theme.spacing(3) },
  title: {
    fontWeight: 600,
    color: theme.palette.tab.offselected,
  },
  buttonContainer: { padding: theme.spacing(2) },
  buttonCreate: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
    margin: '0 auto 10px auto',
    display: 'block',
  },
  listTitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
  },
  icon: { marginRight: theme.spacing(1.5), },
  iconUsers: {
    marginRight: theme.spacing(1.5),
    color: '#4F5B62DE',
  },
  psrtytitle: {
    color: '#4F5B62DE',
    fontWeight: 500
  },
  accordionContainer: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    margin: 0,
  },
  accordionLabelExpandIcon: { fontSize: theme.spacing(1.5), },
  accordionLabel: { color: theme.accent.main },
  accordionLabelIcon: { marginRight: theme.spacing(1) },
  accordonDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(4.25),
    height: theme.spacing(4.25)
  },
  listAvatar: { minWidth: 0 },
  iconv: { width: theme.spacing(2), height: theme.spacing(2) },
  capitalizeInitials: { textTransform: 'uppercase' }
}));

const getInitials = (word:string) => (word.split(' ').length > 1
  ? `${word.split(' ')[0].charAt(0)}${word.split(' ')[1].charAt(0)}`
  : `${word.substring(0, 2)}`);

export type PartyProps = {
  title: string;
  parties: PartyType[];
  kind: 'Seller' | 'Buyer' | 'Lender' | string;
  sectionId: 'seller' | 'buyer' | 'lender' | string;
  handleViewChange: (partytype: string, id: string) => void;
  handleDeleteAssociation: (partyId: string, kind: string) => void;
};

const Party: React.FC<PartyProps> = ({
  title,
  parties,
  kind,
  sectionId,
  handleViewChange,
  handleDeleteAssociation,
}) => {
  const classes = useStyles();
  const context = useContext(PartiesContext);
  const deleteEnabled = context?.listingEntries.some((listingEntry) => listingEntry.code === kind);

  return (

    <Accordion className={classes.accordionContainer}>
      <AccordionSummary
        expandIcon={(
          <FontAwesomeIcon
            icon="chevron-up"
            className={classes.accordionLabelExpandIcon}
          />
              )}
      >
        <Box display="flex" flex="1" flexDirection="row" alignItems="center">
          <FontAwesomeIcon icon="users" className={classes.iconUsers} />
          <Typography variant="body1" className={classes.psrtytitle}>
            {title}
          </Typography>
        </Box>

      </AccordionSummary>

      <AccordionDetails className={classes.accordonDetailsContainer}>
        <List subheader={<li />}>
          {parties.map((party) => (
            <React.Fragment key={party._id}>
              <ListItem
                button
                key={`${party._id}-level1`}
                onClick={() => handleViewChange(sectionId, party._id)}
              >
                <ListItemAvatar className={classes.listAvatar}>

                  <Avatar className={classes.avatar}>
                    <Typography variant="body1" className={classes.capitalizeInitials}>{getInitials(party.name)}</Typography>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={party.name} className={classes.listTitle} />
                {deleteEnabled && (
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={(event) => {
                      handleDeleteAssociation(party._id, kind);
                      event.stopPropagation();
                    }}
                  >
                    <FontAwesomeIcon icon="times-circle" className={classes.iconv} />
                  </IconButton>
                )}
              </ListItem>
              {party?.associations?.filter((a) => a.party_kind !== 'Seller' && a.party_kind !== 'Buyer').map((association) => {
                const subpartyname = association.party_kind.trim();

                return (
                  <ListItem
                    button
                    key={`${association.party_id}-sublevel1`}
                    onClick={() => handleViewChange(subpartyname.split(' ').join('_'), association.party_id)}
                    style={{ paddingLeft: 32, }}
                  >
                    <ListItemAvatar className={classes.listAvatar}>

                      <Avatar className={classes.avatar}>
                        <GetICon type={association.party_kind} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={association.party_name} className={classes.listTitle} />
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={(event) => {
                        handleDeleteAssociation(association.party_id, association.party_kind);
                        event.stopPropagation();
                      }}
                    >
                      <FontAwesomeIcon icon="times-circle" className={classes.iconv} />
                    </IconButton>
                  </ListItem>
                );
              })}
            </React.Fragment>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>

  );
};

export default Party;
