import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoanFormType } from 'views/orders/loans/types';

import { RadioButtonForm } from '../radio-button-form';

type Props = {
  control: Control<LoanFormType, object>
  isDataLoading: boolean,
}

export const DemandFeatureForm: FC<Props> = ({ control, isDataLoading }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="demand_feature"
      render={({ field }) => (
        <RadioButtonForm
          title={t('loanDisclosures:demand-feature')}
          description={t('loanDisclosures:demand-feature-subtitle')}
          value={field.value}
          options={[
            {
              label: t('loanDisclosures:demand-feature-option-1'),
              disabled: isDataLoading,
              value: true
            },
            {
              label: t('loanDisclosures:demand-feature-option-2'),
              disabled: isDataLoading,
              value: false
            }
          ]}
          onChange={(value) => field.onChange(value === 'true')}
        />
      )}
    />
  );
};
