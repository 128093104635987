import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UpdateOrderSpecific } from 'types/order';

const useNotartBlocks = () => {
  const methods = useFormContext<UpdateOrderSpecific>();
  const { t } = useTranslation();

  return {
    methods,
    t
  };
};

export default useNotartBlocks;
