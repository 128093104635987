import { Input, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme: any) => ({
  inputClassName: {
    color: theme.palette.primary.darker,
    fontWeight: 500,
    paddingRight: theme.spacing(1),
    '&&&:before': { borderBottom: 'none' },
    '&&:after': { borderBottom: 'none' },
    '&:hover': { background: theme.palette.input.border },
    width: '100%',
  },
  disabledClass: {
    color: theme.palette.primary.darker,
    background: theme.palette.background.default,
    cursor: 'not-allowed'
  },
}));

type Props = {
  value: number | undefined,
  onChange: (value: number) => void,
  isDisabled?: boolean,
}

export const MoneyField = ({
  value,
  isDisabled = false,
  onChange
}: Props) => {
  const { disabledClass, inputClassName } = useStyles();

  const NumberFormatCustom = useCallback((props) => {
    const { inputRef, ...other } = props;

    const formatValue = (_value: string) => {
      let amount = Number(_value.replace('$', '').replace('-$', ''));
      if (Number.isNaN(amount)) {
        amount = 0;
      }
      return amount;
    };

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange(formatValue(values.value));
        }}
        decimalScale={2}
        thousandSeparator
        allowNegative
        prefix="$"
        isAllowed={({ floatValue = 0 }) => floatValue >= -9_999_999_999_999.99 && floatValue <= 9_999_999_999_999.99}
      />
    );
  }, [onChange]);

  return (
    <Input
      className={inputClassName}
      disabled={isDisabled}
      value={value}
      classes={{ disabled: disabledClass }}
      inputProps={{ style: { textAlign: 'right' } }}
      inputComponent={NumberFormatCustom}
    />
  );
};
