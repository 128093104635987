import React from 'react';

import useEarnestAndCommissions from './earnest-and-commissions-hook';
import EarnestAndCommissionsScene from './earnest-and-commissions-scene';

const EarnestAndCommissions = () => {
  const {
    t,
    properties,
    selectedProperty,
    selectProperty,
    purchasePriceEntries,
    entryLedger,
    partyLedgerEntry,
    setPartyLedgerEntry,
    openPartyLedgerModal,
    setOpenPartyLedgerModal,
    onUpdatePurchasePriceEntries,
    onRemoveOrderPartyEntry,
    isLoading,
  } = useEarnestAndCommissions();

  return (
    <EarnestAndCommissionsScene
      t={t}
      properties={properties}
      selectedProperty={selectedProperty}
      selectProperty={selectProperty}
      purchasePriceEntries={purchasePriceEntries}
      entryLedger={entryLedger}
      partyLedgerEntry={partyLedgerEntry}
      setPartyLedgerEntry={setPartyLedgerEntry}
      openPartyLedgerModal={openPartyLedgerModal}
      setOpenPartyLedgerModal={setOpenPartyLedgerModal}
      onUpdatePurchasePriceEntries={onUpdatePurchasePriceEntries}
      onRemoveOrderPartyEntry={onRemoveOrderPartyEntry}
      isLoading={isLoading}
    />
  );
};

export default EarnestAndCommissions;
