import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Grid,
  makeStyles
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';
import { NewUserType } from 'types/new-user';

import InformationTab from './information-tab';
import PartySearch from './party-search/party-search';
import SkeletonParties from './skeleton-loading';

type PartySearchWithPartyInformationProps = {
  t: TFunction,
  partySelectedData: NewUserType | null,
  parties: NewUserType[],
  isPartySelectedLoading: boolean,
  handleSearch: Function,
  handleItemSelect: Function,
  handleDeleteParty: Function,
  description: string,
  labelField: string,
  deleteLabel: string
}

const useStyles = makeStyles((theme: any) => ({
  cardContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
  },
  containerItem: { padding: theme.spacing(3) },
  button: {
    textTransform: 'none',
    marginTop: theme.spacing(4),
    fontWeight: 500
  },
  byttonText: { marginLeft: theme.spacing(1) },
  paddingContent: { paddingTop: theme.spacing(3) },
  partySelection: {
    fontWeight: 500,
    color: theme.palette.common.black
  },
  partyItem: { padding: theme.spacing(1) },
  suubtitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500
  },
  tabsContainer: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  divider: {
    padding: `0px ${theme.spacing(5)}px`,
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.grey[400],
  },
  selectedagent: {
    backgroundColor: theme.palette.button.default,
    textTransform: 'none'
  },
  unseLectedagent: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'none'
  },
  iconSize: { fontSize: '0.875rem' },
  searchTitle: {
    fontWeight: 500,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  buttonLetter: {
    fontWeight: 500,
    color: theme.palette.common.black
  },
  titleagentlist: { marginTop: theme.spacing(3) },
  buttoncontainer: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end'
  },
  buttonDelete: { textTransform: 'none' },
  autompleteuser: { marginBottom: theme.spacing(4) }
}));

const PartySearchWithPartyInformation: FC<PartySearchWithPartyInformationProps> = ({
  t,
  partySelectedData,
  parties,
  isPartySelectedLoading,
  handleSearch,
  handleItemSelect,
  handleDeleteParty,
  description,
  labelField,
  deleteLabel
}) => {
  const classes = useStyles();

  if (isPartySelectedLoading) {
    return (<SkeletonParties />);
  }

  return (
    <Grid container direction="column" className={classes.paddingContent}>

      {!partySelectedData ? (
        <PartySearch
          description={description}
          labelField={labelField}
          parties={parties}
          handleOnChange={handleSearch}
          handleItemSelect={handleItemSelect}
        />
      ) : (
        <Grid item>
          {partySelectedData && (
          <Grid container direction="column">
            <Grid item>
              <Box
                display="flex"
                flexDirection="row"
                className={classes.buttoncontainer}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon="trash" size="xs" className={classes.iconSize} />}
                  className={classes.buttonDelete}
                  onClick={() => {
                    handleDeleteParty();
                  }}
                >
                  {deleteLabel}
                </Button>
              </Box>
            </Grid>
            <InformationTab
              party={partySelectedData}
              t={t}
              mainParty={false}
            />
          </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default PartySearchWithPartyInformation;
