import { ListingEntry } from 'graphql/listings/queries';
import { createContext } from 'react';
import { NewOrderPartyType } from 'types/order';

import { partiesfiltered } from '.';

type ContextPartiesType ={
  filterParties: (parties: NewOrderPartyType[]) => void;
  unfilteredParties: NewOrderPartyType[];
  parties: partiesfiltered[],
  listingEntries: ListingEntry[]
}

const PartiesContext = createContext<ContextPartiesType | null>(null);

export default PartiesContext;
