import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

export type partiesforTokens = {
  party_id: string,
  email_id:string
}

export type tokensMutationType = {
  party_id: string;
  email: string;
  kind: string;
  message: string;
  token: string
  url: string
}

export type GQLUpdateOrderTokens = {
  updateOrderTokens:{
    tokens:tokensMutationType[]
  }
}

const updateOrderTokens = async (payload:{id:string, parties:partiesforTokens[], notBefore:string, expiresIn:string}) => {
  const mutation = gql`
  mutation Mutation($id: ID!, $parties: [OrderPartyToken!]!, $notBefore: String!, $expiresIn: String!) {
    updateOrderTokens(_id: $id, parties: $parties, not_before: $notBefore, expires_in: $expiresIn) {
      tokens {
        party_id
        email
        kind
        message
        token
        url
      }
    }
  }
  `;

  const response = await graphQLClient()
    .request<GQLUpdateOrderTokens>(mutation, payload);

  return response;
};

export default { updateOrderTokens };
