import {
  alpha,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import championshipLogo from 'assets/images/championship-logo.png';
import OnboardingBackground from 'assets/images/onboarding-background.png';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tab1 from './tab-1';
import Tab2 from './tab-2';
import Tab3 from './tab-3';
import Tab4 from './tab-4';

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: 'calc(100vh - 65px)',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${OnboardingBackground})`,
  },
  image: { width: theme.spacing(20) },
  titleContainer: { marginTop: theme.spacing(3) },
  subtitleContainer: { marginTop: theme.spacing(3) },
  tabsContainer: { marginTop: theme.spacing(4) },
  mainContainer: { paddingTop: theme.spacing(5) },
  hover: {
    '&$hover:hover': {
      background: alpha(theme.palette.common.black, 0.5),
      color: theme.palette.secondary.main,
    },
  },
  containerLogo: {
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing(2)
  },
  imgStyle: {
    width: theme.spacing(25),
    alignSelf: 'center'
  },
  titleLetters: {
    fontWeight: 500,
    color: theme.palette.common.white
  },
  tabLetters: {
    fontWeight: 500,
    color: theme.palette.common.white,
    textTransform: 'none'
  }
}));

const TermsOfService = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState('0');

  return (
    <Grid container direction="row" className={classes.container}>
      <TabContext value={currentTab}>
        <Grid item xs={3}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={2} />

                    <Grid item xs={10}>
                      <Grid container direction="column">
                        <Grid item xs={12} className={classes.containerLogo}>
                          <img
                            src={championshipLogo}
                            alt="logo"
                            className={classes.imgStyle}
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.titleContainer}>
                          <Typography variant="h4" className={classes.titleLetters}>
                            {t('onboarding:terms-of-service')}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.subtitleContainer}>
                          <Typography variant="subtitle2" className={classes.titleLetters}>
                            {t('onboarding:read-terms-of-service')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item className={classes.tabsContainer}>
                  <Tabs
                    variant="fullWidth"
                    value={currentTab}
                    orientation="vertical"
                    onChange={(_, value) => setCurrentTab(value)}
                  >
                    <Tab className={classes.hover} value="0" label={(<Typography variant="body1" className={classes.tabLetters}>Tab 1</Typography>)} />
                    <Tab className={classes.hover} value="1" label={(<Typography variant="body1" className={classes.tabLetters}>Tab 2</Typography>)} />
                    <Tab className={classes.hover} value="2" label={(<Typography variant="body1" className={classes.tabLetters}>Tab 3</Typography>)} />
                    <Tab className={classes.hover} value="3" label={(<Typography variant="body1" className={classes.tabLetters}>Tab 4</Typography>)} />
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={9} className={classes.mainContainer}>
          <TabPanel value="0">
            <Tab1 />
          </TabPanel>

          <TabPanel value="1">
            <Tab2 />
          </TabPanel>

          <TabPanel value="2">
            <Tab3 />
          </TabPanel>

          <TabPanel value="3">
            <Tab4 />
          </TabPanel>
        </Grid>
      </TabContext>
    </Grid>
  );
};

export default TermsOfService;
