import TableComponent from 'components/Table';
import React, { FC, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Column } from 'types/common';
import { ColumnsType } from 'views/orders/accounting/services';

import { SignersType } from '../services/types';

type RendererParams ={
	row: SignersType;
	column: Column;
	index: number;
	indexRow?:number;
	shouldCollapse?:boolean
}

export const signColumns = (
  t: TFunction,
): ColumnsType => ({
  email: {
    header: { title: t('users:email'), },
    renderer: ({ row }:RendererParams) => (
      <>{row.email}</>
    )
  },
  name: {
    header: { title: t('users:name'), },
    renderer: ({ row }:RendererParams) => (
      <>{row.name}</>
    )
  },
  status: {
    header: { title: t('documents:status'), },
    renderer: ({ row }:RendererParams) => (
      <>{row.status}</>
    )
  },
  error: {
    header: { title: 'Error', },
    renderer: ({ row }:RendererParams) => (
      <>{row.error}</>
    )
  },

});

const TableSigners:FC<{signers: SignersType[]}> = ({ signers }) => {
  const { t } = useTranslation();
  const columns = useMemo(() => signColumns(t), [t]);
  return (
    <TableComponent
      disabledBorderLeft
      columns={columns}
      dataSet={signers}
    />
  );
};

export default TableSigners;
