import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox, FormControlLabel, Grid, TextField, Typography
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import { EmptyConversationContent } from 'components/inference/components/empty-conversation-content';
import { MessageContent } from 'components/inference/components/message-content';
import { SourceInfoModal } from 'components/inference/components/source-info-modal';
import { useAutoscroll } from 'hooks/useAutoscroll';
import { useInference } from 'hooks/useInference';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

export const QuestionsAndAnswers = () => {
  const {
    messageList,
    sendQuestion,
    sources,
    setSources,
    isInferenceQueryLoading,
    currentPlaying,
    playSpeech,
    stopSpeech,
    isSpeechLoading,
    isRecording,
    startRecordAudio,
    stopRecordAudio,
    isInferenceAudioLoading,
    toogleAudioEnable
  } = useInference({});
  const classes = useStyles();
  const { t } = useTranslation();
  const { htmlContainerRef } = useAutoscroll();
  const questionInputRef = useRef<HTMLInputElement>(null);

  return (
    <Grid
      className={classes.root}
      container
    >
      <Grid
        item
        container
        className={classes.room}
        direction="column"
        wrap="nowrap"
      >
        <Grid
          item
          container
          className={classes.messageListContainer}
          direction="column"
          wrap="nowrap"
          ref={htmlContainerRef}
        >
          {messageList.length === 0 && !isInferenceAudioLoading && (
            <EmptyConversationContent />
          )}
          {messageList.map((message) => (
            <MessageContent
              message={message}
              onViewSourceClick={() => setSources(message.sources!)}
              onPlaySpeech={playSpeech}
              onStopSpeech={stopSpeech}
              isAudioPlaying={currentPlaying === message.id}
              isAudioLoading={isSpeechLoading}
            />
          ))}
          {isInferenceAudioLoading && (
          <Grid container justifyContent="center" style={{ padding: '10px' }}>
            <Grid item>
              <FontAwesomeIcon spin icon="spinner" size="3x" color="#FB8C00" />
            </Grid>
          </Grid>
          )}
        </Grid>

        <Grid
          item
          container
          className={classes.messageContainer}
          alignItems="flex-end"
        >
          <Grid
            item
            container
            direction="column"
            style={{ gap: '5px' }}
          >
            <Grid item>
              <Grid
                item
                container
                className={classes.message}
                direction="column"
                spacing={1}
              >
                <Grid item>
                  <TextField
                    fullWidth
                    multiline
                    placeholder="Assistant"
                    inputRef={questionInputRef}
                    InputProps={{
                      disableUnderline: true,
                      style: { color: '#000000' },
                      onKeyDown: (e) => {
                        if (e.key === 'Enter' && !e.shiftKey && !isInferenceQueryLoading) {
                          e.preventDefault();
                          sendQuestion({ text: questionInputRef.current!.value });
                          questionInputRef.current!.value = '';
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  wrap="nowrap"
                >
                  <Grid item>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          style={{ padding: 0 }}
                          onChange={toogleAudioEnable}
                        />
                      )}
                      label={<Typography variant="caption" color="primary">Do you want audio?</Typography>}
                      style={{ marginLeft: 0, whiteSpace: 'nowrap' }}
                    />
                  </Grid>
                  <Grid item container justifyContent="flex-end" spacing={1}>
                    <Grid item>
                      <ContainedButton
                        size="small"
                        icon="paper-plane"
                        text="Send"
                        onClick={() => {
                          sendQuestion({ text: questionInputRef.current!.value });
                          questionInputRef.current!.value = '';
                        }}
                        disabled={isInferenceQueryLoading || isInferenceAudioLoading}
                      />
                    </Grid>
                    <Grid item>
                      <ContainedButton
                        size="small"
                        icon={isRecording ? 'circle' : 'microphone'}
                        text={isRecording ? 'Recording...' : 'Record'}
                        onClick={isRecording ? stopRecordAudio : startRecordAudio}
                        disabled={isInferenceQueryLoading || isInferenceAudioLoading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="primary" display="block" align="right">
                Shift + Return to add a new line
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {sources.length > 0 && (
        <SourceInfoModal
          sources={sources}
          onClose={() => setSources([])}
          t={t}
        />
        )}
      </Grid>
    </Grid>
  );
};
