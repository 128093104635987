import {
  Box,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrderProperty } from 'types/order';
import { capitalize } from 'utils/helpers';

import { PropertyTextField } from '../PropertyTextField';

const useStyles = makeStyles((theme: any) => ({
  container: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
  primarycolorfonts: {
    fontWeight: 500,
    color: theme.palette.tab.offselected,
  }
}));

type TabSubdivisionSceneProps ={
  control: Control<OrderProperty, object>
}

export const TabSubdivisionScene: FC<TabSubdivisionSceneProps> = ({ control }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box>
      <Grid container direction="column" spacing={2} className={classes.container}>
        <Grid item container spacing={2}>
          <Grid item xs>
            <PropertyTextField
              control={control}
              name="subdivision"
              label={capitalize(t('orders:subdivision'))}
            />
          </Grid>
          <Grid item xs>
            <PropertyTextField
              control={control}
              name="section"
              label={capitalize(t('orders:section'))}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs>
            <PropertyTextField
              control={control}
              name="block"
              label={capitalize(t('orders:block'))}
            />
          </Grid>
          <Grid item xs>
            <PropertyTextField
              control={control}
              name="lot"
              label={capitalize(t('orders:lot'))}
            />
          </Grid>
        </Grid>
        <Grid item xs>
          <Typography
            variant="h6"
            className={classes.primarycolorfonts}
          >
            {capitalize(t('orders:township'))}
          </Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs>
            <PropertyTextField
              control={control}
              name="township"
              label={capitalize(t('orders:township'))}
            />
          </Grid>
          <Grid item xs>
            <PropertyTextField
              control={control}
              name="range"
              label={capitalize(t('orders:range'))}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
