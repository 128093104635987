import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { AccountingType } from 'v2-types/order';

import { updateOrderPolicy } from '../services/mutations';

const useStyles = makeStyles((theme:any) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.background.paper,
    // paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  tag: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    alignSelf: 'flex-end'
  },
  buttonContained: { color: theme.palette.primary.main, },
  buttonFilled: {
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    '&:hover': { backgroundColor: theme.palette.button.hover },
  },
  buttonOutlined: {
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  textbutton: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  paperBottom: {
    width: '100%',
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
  },
}));

type TableOptionsType = {
  currentSection: string | null;
  chargesIdendifier:string,
  handleUpdateAccounting: (accounting:AccountingType[])=>void,
  handleSetDisclosure: (disclosureLetter: string) => void
};

const SettingsBC = ({ currentSection, chargesIdendifier, handleUpdateAccounting, handleSetDisclosure }: TableOptionsType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{id:string}>();
  const showAlert = useAlert();
  const redirectToPolicyInfo = () => {
    history.push(`/orders/details/${id}/policy-info-and-rates`);
  };

  const [setChargesSection, response] = useMutation(updateOrderPolicy, {
    onSuccess: (data) => {
      showAlert('Charges Updated', 'success');
	  handleSetDisclosure(data.updateOrderPolicy.lenders_policy.disclosure_section || '');
      handleUpdateAccounting(data.updateOrderPolicy.accounting || []);
    },
  });

  const handleSection = useCallback((section) => {
    setChargesSection({
      id,
      kind: 'lenders',
      data: { disclosure_section: section === 'B' ? 'C' : 'B' }
    });
  }, [id, setChargesSection]);

  useEffect(() => {
    if (response.isLoading) {
	  showAlert('Updating...', 'info');
    }
  }, [response.isLoading, showAlert]);
  return (
    <Paper className={classes.paperBottom}>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonFilled}
              onClick={redirectToPolicyInfo}
            >
              <Typography variant="body2" className={classes.textbutton}>
                {t('charges:edit-premium-line')}
              </Typography>
            </Button>
          </Grid>

          {currentSection && chargesIdendifier === currentSection && (
          <Grid item xs={3}>
            <Button
              className={classes.buttonOutlined}
              variant="outlined"
              onClick={() => {
                handleSection(currentSection);
			  }}
            >
              <Typography variant="body2" className={classes.textbutton}>
                {`${t('charges:send-title-charges-to')} ${currentSection === 'B' ? 'C' : 'B'}`}
              </Typography>
            </Button>
          </Grid>
          )}
        </Grid>
      </div>

    </Paper>
  );
};

export default memo(SettingsBC) as unknown as typeof SettingsBC;
