import { capitalize } from '@material-ui/core';
import { helpOrderLedgerPayeePayer } from 'graphql/charges/mutations';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import querys from 'services/querys';
import useAlert from 'utils/alert';
import { CONTACT_BUYER, CONTACT_SELLER } from 'utils/constants';
import { AccountingFilter, AccountingType, NewOrderPartyType } from 'v2-types/order';

export type Position = 'buyer' | 'seller' | 'other' | 'payee' | 'payer';

export type PartyList = NewOrderPartyType & {
  position: Position;
  porcentage: number;
};

type expected = 'at' | 'before';

export const useModalSelectPayeePayer = (
  handleClose: () => void,
  handleUpdateAccounting: (accunting: AccountingType[]) => void,
  accountingFilter?: AccountingFilter
) => {
  const [partyList, setPartyList] = useState<PartyList[]>([]);
  const [dropFinish, setDropFinish] = useState(false);
  const [expectedValue, setExpectedValue] = useState<expected>('at');
  const showAlert = useAlert();

  const { id: orderId } = useParams<{ id: string }>();

  const getPosition = (party) => {
    let position: Position = 'other';
    if (party.kinds.includes(CONTACT_BUYER)) {
      position = 'buyer';
    }
    if (party.kinds.includes(CONTACT_SELLER)) {
      position = 'seller';
    }
    return position;
  };

  const filterParties = (parties: NewOrderPartyType[]) => {
    const filterList = parties.map<PartyList>((party) => ({
      ...party,
      position: getPosition(party),
      porcentage: 0,
    }));
    setPartyList(filterList);
  };

  const getOrder = useQuery(
    ['order-parties', orderId],
    querys.getOnlyOrderParties,
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          const { parties } = data.getOrder;
          filterParties(parties);
        }
      },
    }
  );

  const [addPayeePayer, responseAddPayeePayer] = useMutation(
    helpOrderLedgerPayeePayer,
    {
      onSuccess: (data) => {
        if (data) {
          handleUpdateAccounting(data.helpOrderLedgerPayeePayer.accounting);
          showAlert(capitalize('was added successfully'), 'success');
          handleClose();
        }
      },
      onError: () => {
        responseAddPayeePayer.reset();
        showAlert(capitalize('an error occurred'), 'error');
      },
    }
  );

  const removeParty = (partyID) => {
    const newList = partyList.map((party) => {
      if (party._id === partyID) {
        return {
          ...party,
          position: getPosition(party),
        };
      }
      return { ...party, };
    });
    setPartyList(newList);
  };

  const changePorcentageParty = (partyID, e) => {
    const newList = partyList.map((party) => {
      if (party._id === partyID) {
        return {
          ...party,
          porcentage: e.target.value,
        };
      }
      return { ...party, };
    });
    setPartyList(newList);
  };

  const startDrag = (e, party: NewOrderPartyType) => {
    e.dataTransfer.setData('PartyID', party._id);
  };

  const dragInOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const payers = partyList.filter(
      (party) => party.position === 'payer'
    ).length;
    const payees = partyList.filter(
      (party) => party.position === 'payee'
    ).length;
    if (dropFinish && (payees > 0 || payers > 0)) {
      const porcentagePayer = 100 / payers;
      const porcentagePayee = 100 / payees;

      const newList = partyList.map((party) => {
        if (party.position === 'payee') {
          return {
            ...party,
            porcentage: porcentagePayee,
          };
        }
        if (party.position === 'payer') {
          return {
            ...party,
            porcentage: porcentagePayer,
          };
        }
        return { ...party, };
      });
      setPartyList(newList);
      setDropFinish(false);
    }
  }, [dropFinish, partyList]);

  const onDrop = (e, section) => {
    const itemID = e.dataTransfer.getData('PartyID');
    const newList = partyList.map((party) => {
      if (party._id === itemID) {
        return {
          ...party,
          position: section,
        };
      }
      return { ...party, };
    });
    setPartyList(newList);
    setDropFinish(true);
  };

  const onChangeRadio = (e) => {
    setExpectedValue(e.target.value);
  };

  const handleSubmit = (accountingID: string) => {
    const data = {
      expected: expectedValue,
      payees: partyList
        .filter((party) => party.position === 'payee')
        .map((party) => ({
          party_id: party._id,
          percent: party.porcentage / 100,
        })),
      payers: partyList
        .filter((party) => party.position === 'payer')
        .map((party) => ({
          party_id: party._id,
          percent: party.porcentage / 100,
        })),
    };
    addPayeePayer({
      id: orderId,
      data,
      accountingId: accountingID,
      filters: accountingFilter ? { accounting: accountingFilter } : undefined
    });
  };

  return {
    isLoading: getOrder.isLoading || responseAddPayeePayer.isLoading,
    partyList,
    removeParty,
    changePorcentageParty,
    onDrop,
    startDrag,
    dragInOver,
    onChangeRadio,
    expectedValue,
    handleSubmit,
  };
};
