import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingType } from 'v2-types/order';

import Header from '../components/header';
import TableComponent from '../components/prototype-new-table-charges/new-table-charges';
import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import TableLoader from '../components/skeleton-table-charges';

const useStyles = makeStyles((theme) => ({ padding: { padding: theme.spacing(3) }, }));

type ChargesProtitypeProps = {
  accountingData: AccountingType[];
  handleSetAccounting: (accountingData: AccountingType[]) => void;
  currentRowSelected: columnsDataSet | null;
  handleSelectRow: (row: columnsDataSet) => void;
  loadingAccounting: boolean;
};

const ChargesHScene: FC<ChargesProtitypeProps> = ({
  accountingData,
  handleSetAccounting,
  currentRowSelected,
  handleSelectRow,
  loadingAccounting,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" className={classes.padding} spacing={2}>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Paper>
          <Header title={t('charges:other-charges')} chargesIdentifier="H" />
          {loadingAccounting ? (
            <TableLoader />
          ) : (
            <TableComponent
              accountingData={accountingData}
              handleClickItem={(item) => {
                handleSelectRow(item);
              }}
              itemSelected={currentRowSelected}
              updateAccounting={handleSetAccounting}
              chargesIdentifier="H"
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChargesHScene;
