import { Box, makeStyles, Typography } from '@material-ui/core';
import empty from 'assets/images/charges-empty-icon.png';
import React from 'react';
import { NothingType } from 'types/payments';

const useStyles = makeStyles((theme: any) => ({
  boxContainer: {
    margin: `${theme.spacing(25)}px ${theme.spacing(2.5)}px 0px ${theme.spacing(2.5)}px`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  titulo: { marginBottom: theme.spacing(5) },
  imgEmpty: { marginBottom: theme.spacing(2.5) }
}));

const Nothing = ({ t }: NothingType) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.boxContainer}>
        <img src={empty} alt="empty charges" className={classes.imgEmpty} />
        <Typography className={classes.titulo} variant="h4">{t('payments:no-charges')}</Typography>
        <Typography variant="subtitle1">{t('payments:no-charges-leyend')}</Typography>
      </Box>
    </>
  );
};

export default Nothing;
