import { Box, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { formatMoney } from 'utils/helpers';
import { AccountedBy } from 'v2-types/order';

type accocuntedforprops = {
	accountedInfo: AccountedBy[]
}

const useStyles = makeStyles((theme:any) => ({ divider: { marginBottom: theme.spacing(1) }, }));

const AccountedFor:FC<accocuntedforprops> = ({ accountedInfo }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      {accountedInfo?.map((accounted) => (
        <Box display="flex" flexDirection="column" className={classes.divider} key={`${accounted.amount}${accounted.record.description}`}>
          <Box>
            {`* ${accounted.record.description}`}
          </Box>
          <Box>
            {`${formatMoney(accounted.amount)}`}
          </Box>
        </Box>

      ))}
    </Box>
  );
};

export default AccountedFor;
