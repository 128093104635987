import {
  Dialog, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

import { ResponseSource } from '../../types';
import { SourceTable } from '../source-table';
import { useStyles } from './styles';

type SourceInfoModalProps = {
  sources: ResponseSource[],
  onClose: () => void,
  t: TFunction,
}

export const SourceInfoModal: FC<SourceInfoModalProps> = ({
  sources,
  onClose,
  t
}) => {
  const classes = useStyles();
  return (
    <Dialog open fullWidth maxWidth="lg" onClose={onClose}>
      <DialogContent style={{ padding: 0 }}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
        >
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              {t('questionsAndAnswers:sources')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
          >
            <SourceTable
              sources={sources}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <ContainedButton
          text="Close"
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};
