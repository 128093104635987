import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, Button, Grid, Paper, Typography
} from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NoteType } from 'views/notes/types';

import { LoaderListNotes } from '../LoaderListNotes';
import { Note } from '../Note';
import { useStyles } from './styles';

type NoteListProps = {
  noteList: NoteType[],
  isLoadingNotes: boolean,
  isSavingChanges: boolean,
  selectedNoteId: string
  addNewNote: () => void,
  selectNote: (note: NoteType) => void,
  removeNote: (noteId: string) => void,
  copyNote: (text: string) => void,
  actionsEnabled: boolean
}

export const NoteList: FC<NoteListProps> = ({
  noteList,
  isLoadingNotes,
  isSavingChanges,
  selectedNoteId,
  addNewNote,
  selectNote,
  removeNote,
  copyNote,
  actionsEnabled
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.paperContainer}>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography
            variant="h6"
            color="primary"
          >
            {t('notes:notes')}
          </Typography>
          <Button
            color="primary"
            disabled={isSavingChanges || !actionsEnabled}
            onClick={addNewNote}
          >
            <FontAwesomeIcon icon="plus" />
          </Button>
        </Box>
        <Grid
          className={classes.noteList}
          container
          direction="column"
          wrap="nowrap"
        >
          {isLoadingNotes && (<LoaderListNotes />)}
          {noteList.map((note) => (
            <Grid item key={note.id} className={classes.noteContainer}>
              <Note
                note={note}
                isSelected={selectedNoteId === note.id}
                actionsDisabled={isSavingChanges || (!actionsEnabled && note.text !== '')}
                selectNote={() => selectNote(note)}
                removeNote={(id) => removeNote(id)}
                copyNote={copyNote}
              />
            </Grid>
          ))}
        </Grid>
        {isSavingChanges && (
        <Grid
          className={classes.savingChanges}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography variant="body2" color="primary">
            <FontAwesomeIcon icon="spinner" size="sm" spin />
            {' '}
            {t('notes:saving-loading')}
          </Typography>
        </Grid>
        )}
      </Box>
    </Paper>
  );
};
