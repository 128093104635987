import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { DocumentsType } from 'v2-types/order';

import useStyles from '../../styles';
import { SelectedFilesType } from '../../types';
import DataSection from './dataSection';
import FilesTable from './files-table';

type FileRowProps = {
  document: DocumentsType;
  handleToggleModal: (
    archive_id: string,
    location: string,
    data?: DocumentsType
  ) => void;
  handleRemoveData: (payload: any) => void;
  handleRemoveFile: (fileId: string) => void;
  handleCheckboxChange: (file: SelectedFilesType) => void;
  selectedFiles: SelectedFilesType[];
  searchWordClass: string;
  handleRemoveArchive: ({
    documentId,
    archiveId,
    fileId
  }: {
    documentId: string,
    archiveId: string,
    fileId: string
  }) => void
};

const FileRow: FC<FileRowProps> = ({
  document,
  handleToggleModal,
  handleRemoveData,
  handleRemoveFile,
  handleCheckboxChange,
  selectedFiles,
  searchWordClass,
  handleRemoveArchive
}) => {
  const { rowContainer } = useStyles();

  const [open, setOpen] = React.useState(false);
  const archives = document.archives.flatMap((archive) => archive.files.map((file) => ({
    ...file,
    name: archive.name,
    archiveId: archive._id
  })));

  return (
    <>
      <TableRow className={rowContainer}>
        <TableCell onClick={() => setOpen(!open)}>
          <Box display="flex" alignItems="center" gridGap={8}>
            <Typography variant="subtitle1" style={{ color: '#000' }} className={searchWordClass}>
              {document.description}
            </Typography>
            {open ? (
              <FontAwesomeIcon icon="angle-up" />
            ) : (
              <FontAwesomeIcon icon="angle-down" />
            )}
          </Box>
        </TableCell>
        <TableCell style={{ color: '#000' }} className={searchWordClass}>{document.kind}</TableCell>
        <TableCell>
          <Box>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => handleToggleModal(document._id, 'updateFile', document)}
            >
              <FontAwesomeIcon icon="edit" />
            </IconButton>

            {document.kind !== 'signed' && document.kind !== 'accounting' && (
              <IconButton
                size="small"
                style={{ color: '#F44336' }}
                onClick={() => handleRemoveFile(document._id)}
              >
                <FontAwesomeIcon icon="trash" />
              </IconButton>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            <DataSection
              data={document.data}
              handleToggleModal={handleToggleModal}
              document_id={document._id}
              handleRemoveData={handleRemoveData}
              searchWordClass={searchWordClass}
            />
            <Box margin={1}>
              <Typography variant="subtitle1" style={{ color: '#000' }}>
                Files
              </Typography>
              {archives.length > 0 && (
                <FilesTable
                  files={archives}
                  kind="complete"
                  handleToggleModal={handleToggleModal}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedFiles={selectedFiles}
                  documentId={document._id}
                  documentKind={document.kind}
                  handleRemoveArchive={handleRemoveArchive}
                  searchWordClass={searchWordClass}
                />
              )}
            </Box>
            {document.parts && document.parts.length > 0 && (
              <Box margin={1}>
                <Typography variant="h6">Included parts</Typography>
                <FilesTable
                  files={document.parts}
                  kind="parts"
                  handleToggleModal={handleToggleModal}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedFiles={selectedFiles}
                  documentId={document._id}
                  searchWordClass={searchWordClass}
                />
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default FileRow;
