import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';

type DropDownProps<T> = {
  options: T[],
  value?: T | string,
  defaultValue?: string,
  optionValue: keyof T,
  optionText: keyof T,
  defaultText: string,
  onChange: (value: string) => void
}

export function DropDown<T>({
  options,
  value,
  defaultValue = '',
  optionValue,
  optionText,
  defaultText,
  onChange
}: DropDownProps<T>) {
  const classes = useStyles<T>({ value });
  return (
    <Select
      displayEmpty
      value={value}
      defaultValue={defaultValue}
      onChange={(e) => onChange(e.target.value as string)}
      className={classes.select}
      disableUnderline
      fullWidth
    >
      <MenuItem value="">{defaultText}</MenuItem>
      {options.map((option) => (
        <MenuItem
          selected={option[optionValue as string] as string === value}
          value={option[optionValue as string] as string}
        >
          {option[optionText]}
        </MenuItem>
      ))}
    </Select>
  );
}
