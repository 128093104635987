/* eslint-disable react-hooks/exhaustive-deps */
import useSearchParty from 'hooks/useSearchParty';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import graphql from 'services/querys';
import { UpdateOrderSpecific } from 'types/order';
import useAlert from 'utils/alert';

import PartiesContext from '../../../services/context';

const useExchangeTab = (
  partyId: string,
  roleParty: string,
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const context = useContext(PartiesContext);
  const { filterParties } = context || {};
  const { t } = useTranslation();
  const [exchangeID, setExchangeID] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { parties } = useSearchParty(searchText);
  const { id: orderId } = useParams<{ id: string }>();

  const showAlert = useAlert();
  const methods = useFormContext<UpdateOrderSpecific>();
  const [addExchange, addExchangeResponse] = useMutation(
    graphql.createAssociation,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          setLoaderActive(false);
          if (data) {
            showAlert(t('parties:exchange_add'), 'success');
            setExchangeID(null);
            addExchangeResponse.reset();
            if (data?.createOrderAssociation.parties && filterParties) {
              filterParties(data?.createOrderAssociation?.parties!);
            }
          }
        }, 1500);
      },
      onError: () => {
        setLoaderActive(false);
        showAlert(t('parties:mutation-error'), 'error');
      },
    }
  );

  const [deleteExchange, deleteExchangeResponse] = useMutation(
    graphql.deleteAssociation,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          setLoaderActive(false);
          showAlert(t('parties:exchange_deleted'), 'success');
          deleteExchangeResponse.reset();
          setExchangeID(null);
          if (data?.deleteOrderAssociation.parties && filterParties) {
            filterParties(data?.deleteOrderAssociation?.parties!);
          }
        }, 1500);
      },
      onError: () => {
        setLoaderActive(false);
        showAlert(t('parties:mutation-error'), 'error');
      },
    }
  );

  useEffect(() => {
    if (orderId && partyId && exchangeID) {
      setLoaderActive(true);
      addExchange({
        id: orderId,
        partyId: exchangeID,
        linkedToId: partyId,
        kind: 'Exchange 1031',
        linkedToKind: roleParty,
      });
    }
  }, [addExchange, orderId, partyId, exchangeID, roleParty]);

  return {
    t,
    parties,
    setSearchText,
    setExchangeID,
    orderId,
    deleteExchange,
    methods,
  };
};

export default useExchangeTab;
