import { getOrderParties, GetOrderPartiesType } from 'graphql/parties/queries';
import { QueryConfig, useQuery } from 'react-query';

type Params = {
  orderId: string,
  kindsFilter?: string[],
  queryConfig?: QueryConfig<GetOrderPartiesType, Error>
}

export const useGetOrderParties = ({ orderId, kindsFilter, queryConfig }: Params) => useQuery(['order-parties', orderId], {
  ...queryConfig,
  queryFn: async () => {
    const response = await getOrderParties(orderId);
    if (kindsFilter && kindsFilter.length) {
      response.getOrder.parties = response.getOrder.parties.filter((party) => kindsFilter.some((kind) => party.kinds.includes(kind)));
    }

    return response;
  }
});
