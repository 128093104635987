import React from 'react';
import { ProjectedPaymentsType } from 'types/disclosures';

import useProjectedPayments from './projected-payments-hook';
import ProjectedPaymentsScene from './projected-payments-scene';
import { generatePayload } from './services';

const ProjectedPayments = () => {
  const {
    t,
    initialValues,
    disclosures,
    orderId,
    updateOrder,
  } = useProjectedPayments();

  const handleSave = (values: ProjectedPaymentsType) => {
    const payload = generatePayload(values, disclosures!);
    updateOrder({ orderId, payload });
  };

  return (
    <ProjectedPaymentsScene
      t={t}
      initialValues={initialValues}
      handleSave={handleSave}
    />
  );
};

export default ProjectedPayments;
