import React, { FC } from 'react';
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { OrderProperty } from 'types/order';

import { TabPropertyScene } from './tab-property-scene';
import useTabProperty from './use-tab-property';

type TabPropertyProps = {
  control: Control<OrderProperty, object>,
  getValues: UseFormGetValues<OrderProperty>
  setValue: UseFormSetValue<OrderProperty>
}

export const TabProperty: FC<TabPropertyProps> = ({
  control,
  getValues,
  setValue
}) => {
  const {
    t,
    theme,
    countries,
    states,
    selectCountry,
    selectState,
    counties,
    selectCounty,
    isCountyListLoading,
    zcodes,
    isZcodesListLoading,
    selectZcode,
    cities
  } = useTabProperty({ getValues, setValue });

  return (
    <TabPropertyScene
      t={t}
      theme={theme}
      countries={countries}
      states={states}
      selectCountry={selectCountry}
      control={control}
      getValues={getValues}
      selectState={selectState}
      counties={counties}
      selectCounty={selectCounty}
      isCountyListLoading={isCountyListLoading}
      zcodes={zcodes}
      isZcodesListLoading={isZcodesListLoading}
      selectZcode={selectZcode}
      cities={cities}
    />
  );
};
