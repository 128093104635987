import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import TextField from 'components/text-field';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSortable } from 'react-sortablejs';

import useStyles from '../styles';
import { MergedFilesType } from '../types';

const UpdateFleModal = ({
  open,
  handleClose,
  data,
  handleupdateFile,
  fileId,
  allFilesinDocuments,
}) => {
  const [descriptionFile, setDescriptionFile] = useState(data.description);
  const [query, setQuery] = useState('');
  const { t } = useTranslation();
  const [allFiles, setAllFiles] = useState(allFilesinDocuments);

  const [selectedFilesSorted, setSelectedFilesSorted] = useState<
    MergedFilesType[] | []
  >(
    (data.parts ?? []).map((file) => ({
      name: file.filename,
      source: file.source,
    }))
  );

  const [filesQuery, setFilesQuery] = useState<MergedFilesType[] | []>(
    data.parts ?? []
  );

  const { mergedDocumentsText } = useStyles();

  const handleRemoveSelectedFile = (file) => {
    setSelectedFilesSorted(
      selectedFilesSorted.filter((item) => item.source !== file.source)
    );
    setFilesQuery([...filesQuery, file]);
  };

  useEffect(() => {
    const filteredSources = data.archives.flatMap((archives) => archives.files.map((file) => file.source));
    setAllFiles(
      allFiles.filter((allFile) => !filteredSources.includes(allFile.source))
    );
  }, []);

  useEffect(() => {
    const filesFiltred = allFiles.filter(
      (allFile) => !selectedFilesSorted.some((file) => file.source === allFile.source)
    );

    setFilesQuery(
      filesFiltred.filter((file) => file.name.toLowerCase().includes(query.toLowerCase()))
    );
  }, [query, allFiles]);

  const addToParts = (file) => {
    setSelectedFilesSorted([...selectedFilesSorted, file]);

    setFilesQuery(
      filesQuery.filter((fileQuery) => fileQuery.source !== file.source)
    );
  };

  return (
    <Dialog open={open} onClose={() => handleClose('')}>
      <DialogTitle>Edit Document</DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <TextField
              value={descriptionFile}
              label="Description"
              onChange={(e) => {
                setDescriptionFile(e.target.value);
              }}
            />
          </Grid>

          {data.parts && data.parts.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  style={{
                    marginBottom: '10px',
                    color: 'black',
                  }}
                >
                  Search the document
                </Typography>
                <TextField
                  label={t('common:search')}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                {filesQuery.length > 0 ? (
                  <List
                    dense
                    component="div"
                    role="list"
                    style={{
                      height: '510px',
                      overflow: 'scroll',
                      margin: '10px 0',
                    }}
                  >
                    {filesQuery.map((file) => (
                      <ListItem key={file.id}>
                        <ListItemAvatar>
                          <Avatar>
                            <FontAwesomeIcon icon="file" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={mergedDocumentsText}
                          primary={file.name}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            onClick={() => addToParts(file)}
                          >
                            <FontAwesomeIcon icon="plus" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography
                    variant="subtitle1"
                    style={{ textAlign: 'center' }}
                  >
                    No Files
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  style={{
                    marginBottom: '10px',
                    color: 'black',
                  }}
                >
                  {t('documents:files-included')}
                </Typography>
                {selectedFilesSorted.length > 0 ? (
                  <List
                    dense
                    component="div"
                    role="list"
                    style={{
                      height: '560px',
                      overflow: 'scroll',
                      margin: '10px 0',
                    }}
                  >
                    <ReactSortable
                      list={selectedFilesSorted}
                      setList={setSelectedFilesSorted}
                    >
                      {selectedFilesSorted.map((file) => (
                        <ListItem
                          key={file.source}
                          style={{ cursor: 'pointer' }}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <FontAwesomeIcon icon="file" />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            className={mergedDocumentsText}
                            primary={file.name}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              onClick={() => handleRemoveSelectedFile(file)}
                            >
                              <FontAwesomeIcon icon="times" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </ReactSortable>
                  </List>
                ) : (
                  <Typography
                    variant="subtitle1"
                    style={{ textAlign: 'center' }}
                  >
                    No Files
                  </Typography>
                )}
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                onClick={() => {
                  const IDDocs = selectedFilesSorted.map((doc) => doc.source);
                  handleupdateFile(descriptionFile, fileId, IDDocs);
                  handleClose('');
                }}
                variant="contained"
                color="secondary"
              >
                Update Document
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateFleModal;
