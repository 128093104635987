import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { uploadFile } from 'services/tus/uploadFile';
import useAlert from 'utils/alert';
import { TOKEN_CLAIMS } from 'utils/constants';
import { asyncForEach } from 'utils/helpers';
import { DocumentsType } from 'v2-types/order';

import { AssociationType } from '../../types';
import { DocumentPartyUpload } from './types';

type UseManualDocumentUploadModalParams = {
  parties: AssociationType[];
  documents: DocumentsType[];
  onClose: () => void;
};

export const useManualDocumentUploadModal = ({ onClose, }: UseManualDocumentUploadModalParams) => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const Alert = useAlert();
  const queryCache = useQueryCache();
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedParty, setSelectedParty] = useState<string>('');
  const {
    getInputProps,
    getRootProps,
    open: openFileDialog,
  } = useDropzone({
    maxSize: 31457280,
    // eslint-disable-next-line
    accept:'image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet',
    noClick: true,
    noKeyboard: true,
    onDropAccepted: (files: File[]) => setFilesToUpload([...filesToUpload, ...files]),
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
    reset: resetForm,
    setValue
  } = useForm<DocumentPartyUpload>({ mode: 'onChange' });

  const onRemoveFile = ({ file }: { file: File }) => {
    setFilesToUpload((prevFilesToUpload) => prevFilesToUpload.filter(
      (fileToUpload) => fileToUpload.name !== file.name
    ));
  };

  const onUploadDocuments = handleSubmit(async (data) => {
    setIsLoading(true);
    let filesUploadedCount = 0;
    await asyncForEach(filesToUpload, async (file) => {
      try {
        await uploadFile({
          file,
          archiveId: data.archiveId,
          token: localStorage.getItem(TOKEN_CLAIMS) ?? '',
          orderId,
          partyId: data.partyId,
          filename: file.name,
        });
        filesUploadedCount += 1;
      } catch (error) {
        Alert(t('documents:error-uplading'), 'error');
      }
    });
    setIsLoading(false);

    if (filesUploadedCount === filesToUpload.length) {
      Alert(t('documents:upload-success'), 'success');
      resetForm();
      setFilesToUpload([]);
      queryCache.invalidateQueries(['order-documents', orderId]);
      onClose();
    }
  });

  return {
    t,
    control,
    watch,
    isValid,
    onRemoveFile,
    onUploadDocuments,
    openFileDialog,
    getInputProps,
    getRootProps,
    filesToUpload,
    isLoading,
    selectedParty,
    setSelectedParty,
    setValue
  };
};
