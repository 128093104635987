import { gql } from 'graphql-request';
import gqlRequest from 'graphql/generic';

import { SignatureType } from '../services/types';

type GetSignatureReturn = {
  getSignatures:SignatureType[]
};

type GetSignatureParams = {orderId: string };

const query = gql`
query getSignatures($orderId: ID) {
	getSignatures(order_id: $orderId) {
	  _id
	  order_id
	  key_id
	  archive_id
	  source
	  has_error
	  is_complete
	  is_declined
	  description
	  provider_id
	  signers {
		_id
		email
		error
		name
		order
		status
		sign_url
	  }
	}
  }
`;

export const getSignatures = (id: string) => gqlRequest<GetSignatureReturn, GetSignatureParams>(query, { orderId: id });

export default {};
