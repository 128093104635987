import { TOKEN_CLAIMS } from 'utils/constants';

export const inferenceByImage = async ({ image, question }:{image:Blob, question:string}) => {
  const access_token = await localStorage.getItem(TOKEN_CLAIMS);
  const data = new FormData();
  data.append('file', image, 'fileName.jpg');
  data.append('question', question);
  const response = await fetch(`${process.env.REACT_APP_API_URL!}/inference/image`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      Accept: 'application/json'
    },
    body: data,
  });
  const res = await response.json();
  return res;
};
