import { getInference, GQLGetInference } from 'graphql/questions-and-answers/queries';
import { useInferenceAudio } from 'hooks/useInferenceAudio';
import { useMicrophone } from 'hooks/useMicrophone';
import { useSpeech } from 'hooks/useSpeech';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryCache } from 'react-query';
import useAlert from 'utils/alert';
import { v4 as uuidv4 } from 'uuid';

import {
  Message, NewMessage, Question, ResponseSource, UpdateMessage
} from './types';
import { getSources } from './utils/getSources';

type UseInferenceParams = {
  onConversationUpdated?: () => void
}

export const useInference = ({ onConversationUpdated }: UseInferenceParams) => {
  const queryCache = useQueryCache();
  const [question, setQuestion] = useState<Question>({ id: '', text: '' });
  const [messageList, setMessageList] = useState<Message[]>([]);
  const [sources, setSources] = useState<ResponseSource[]>([]);
  const [currentPlaying, setCurrentPlaying] = useState<string>();
  const [isInferenceAudioLoading, setIsInferenceAudioLoading] = useState(false);
  const playAudioEnabledRef = useRef(false);
  const showAlert = useAlert();
  const { t } = useTranslation();
  const { getInferenceAudio } = useInferenceAudio();
  const {
    getSpeech,
    stopSpeechAudio,
    isSpeechLoading,
    abortGetSpeech
  } = useSpeech({
    onSpeechAudioEnd() {
      setCurrentPlaying('');
    },
  });

  const playSpeech = ({ messageId, text }: { messageId: string, text: string }) => {
    setCurrentPlaying(messageId);
    stopSpeechAudio();
    if (isSpeechLoading) {
      abortGetSpeech();
    }
    getSpeech({ text });
  };

  const stopSpeech = () => {
    setCurrentPlaying('');
    stopSpeechAudio();
  };

  const setNewMessage = ({
    text,
    isMessageLoading = false,
    response = '',
    inferenceResponse,
    questionTime = new Date()
  }: NewMessage): string => {
    const id: string = uuidv4();
    setMessageList((prevMessageList) => [
      ...prevMessageList,
      {
        id,
        questionTime,
        isLoading: isMessageLoading,
        response,
        sources: inferenceResponse ? getSources({ data: inferenceResponse }) : undefined,
        text
      }
    ]);

    return id;
  };

  const updateMessage = useCallback(({
    id,
    response,
    sourceList,
    isLoading = false
  }: UpdateMessage) => {
    setMessageList((prevMessageList) => {
      const messageListCopy = [...prevMessageList];
      const messageIndex = messageListCopy.findIndex((questionText) => questionText.id === id);
      if (messageIndex >= 0) {
        const message = messageListCopy[messageIndex];
        messageListCopy[messageIndex] = {
          ...message,
          isLoading,
          response: response?.trim() ?? message.response,
          sources: sourceList ?? message.sources
        };
        queryCache.setQueryData(['questions'], messageListCopy, { cacheTime: 50 * (60 * 1000) });
        return messageListCopy;
      }
      return prevMessageList;
    });

    if (onConversationUpdated) {
      onConversationUpdated();
    }
  }, [onConversationUpdated, queryCache]);

  const {
    isRecording,
    startRecordAudio,
    stopRecordAudio
  } = useMicrophone({
    onRecordStart() {
      stopSpeech();
    },
    onRecordStop: async (audioBlob) => {
      setIsInferenceAudioLoading(true);
      getInferenceAudio({ audioBlob }, {
        onSuccess(audioResponse) {
          if (!audioResponse.response) return;

          if (playAudioEnabledRef.current) {
            getSpeech({ text: audioResponse.response.trim() }, {
              onSuccess() {
                setNewMessage({
                  isMessageLoading: false,
                  response: audioResponse.response.trim(),
                  inferenceResponse: audioResponse,
                  text: audioResponse.question
                });
                setIsInferenceAudioLoading(false);

                if (onConversationUpdated) {
                  onConversationUpdated();
                }
              }
            });
          } else {
            setNewMessage({
              isMessageLoading: false,
              response: audioResponse.response.trim(),
              inferenceResponse: audioResponse,
              text: audioResponse.question
            });
            setIsInferenceAudioLoading(false);

            if (onConversationUpdated) {
              onConversationUpdated();
            }
          }
        },
        onError() {
          showAlert(t('common:unexpected-error'), 'error');
          setIsInferenceAudioLoading(false);
        },
      });
    },
  });

  useEffect(() => {
    const data = queryCache.getQueryData(['questions']);
    if (data !== undefined && data !== null) {
      const messages = data as Message[];
      setMessageList(messages.map((message) => ({ ...message, showAnimation: false })));
    }
  }, []);

  const { isLoading: isInferenceQueryLoading } = useQuery(['getInference', question.id], {
    queryFn: () => getInference({ question: question.text }),
    onSuccess: (response: GQLGetInference) => {
      if (!response.getInference.response) return;

      if (playAudioEnabledRef.current) {
        getSpeech({ text: response.getInference.response.trim() }, {
          onSuccess() {
            updateMessage({
              id: question.id,
              response: response.getInference.response,
              sourceList: getSources({ data: response.getInference })
            });
          }
        });
      } else {
        updateMessage({
          id: question.id,
          response: response.getInference.response,
          sourceList: getSources({ data: response.getInference })
        });
      }
    },
    onError: () => {
      updateMessage({
        id: question.id,
        response: 'Unexpected error',
      });
    },
    enabled: question.text.length > 0,
    refetchOnWindowFocus: false
  });

  const sendQuestion = ({ text }: { text: string }) => {
    if (text.trim() !== '') {
      const id = setNewMessage({
        text,
        isMessageLoading: true
      });

      setQuestion({
        id,
        text
      });

      if (onConversationUpdated) {
        onConversationUpdated();
      }
    }
  };

  const toogleAudioEnable = () => {
    playAudioEnabledRef.current = !playAudioEnabledRef.current;
  };

  return {
    isInferenceQueryLoading,
    isInferenceAudioLoading,
    isRecording,
    isSpeechLoading,
    startRecordAudio,
    stopRecordAudio,
    messageList,
    sendQuestion,
    sources,
    setSources,
    toogleAudioEnable,
    playSpeech,
    stopSpeech,
    currentPlaying
  };
};
