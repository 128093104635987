import { gql } from 'graphql-request';
import { ListOrdersParams } from 'types/common';
import { OrderListingResultType } from 'types/order';

import gqlRequest from '../generic';

type GetOrderWorkspace = {
  getOrder: {
    _id: string;
    status: string;
    order_id: string;
  };
};

type GetOrderWorkspaceParams = { id: string };

const queryGetOrderWorkspace = `
  query GetOrder($id: ID!) {
    getOrder(_id: $id) {
      _id
      status
      order_id
    }
  }
`;

export const getOrderWorkspace = (id: string) => gqlRequest<GetOrderWorkspace, GetOrderWorkspaceParams>(
  queryGetOrderWorkspace,
  { id },
);

type GuardPolicyInfoAndRates = {
  getOrder: {
    configuration: {
      type: string;
    };
  };
};

type GuardPolicyInfoAndRatesParams = { id: string };

const queryguardPolicyInfoAndRates = `
  query GetOrder($id: ID!) {
    getOrder(_id: $id) {
      configuration {
        type
      }
    }
  }
`;

export const guardPolicyInfoAndRates = (id: string) => gqlRequest<GuardPolicyInfoAndRates, GuardPolicyInfoAndRatesParams>(
  queryguardPolicyInfoAndRates,
  { id },
);

export type GQLListOrders = {
  listOrders: OrderListingResultType;
};

export const listOrders = async (payload: ListOrdersParams) => {
  const query = gql`
    query ListOrders(
      $pagination: PaginationContent
      $status: OrderStatuses
      $searchText: String
      ${payload.agencyId && payload.agencyId.length ? '$agencyId: String' : ''}
    ) {
      listOrders(
        pagination: $pagination
        status: $status
        order_id: $searchText
        ${payload.agencyId && payload.agencyId.length ? 'agency_id: $agencyId' : ''}
      ) {
        orders {
          _id
          agency
          order_id
          address
          buyer
          closing_date
          loan_amount
          purchase_price
          transaction_type
          type
          seller
          status
        }
        pagination {
          items
          page
          pages
        }
      }
    }
  `;

  const response = await gqlRequest<GQLListOrders, ListOrdersParams>(query, payload);

  return response;
};
