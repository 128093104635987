import {
  CLEAR_WORKSPACE,
  SET_ID_ORDER_SELECTED,
  SET_WORKSPACE,
  UPDATE_WORKSPACE,
} from './action-types';

export const cleanWorkspace = () => ({ type: CLEAR_WORKSPACE });

export const setWorkspace = (payload: any) => ({
  type: SET_WORKSPACE,
  payload,
});

export const updateWorkspace = (payload: any) => ({
  type: UPDATE_WORKSPACE,
  payload,
});

export const setIdOrdenSelected = (payload: any) => ({
  type: SET_ID_ORDER_SELECTED,
  payload,
});
