import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import TableComponent from 'components/Table';
import moment from 'moment';
import React, { FC } from 'react';
import { TFunction } from 'react-i18next';
import { NewOrderType } from 'types/order';

import ItemwithIcon from './component/item-with-icon';
import ScheduleModal from './component/ron-schedule-meeting/ron-schedule-meeting';
import VoidMeetings from './component/void-meetings';
import { ColumnsType, tableRowComponent } from './services';

const useStyles = makeStyles((theme: any) => ({
  titleCard: {
    height: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3)
  },
  titleContainer: { marginBottom: theme.spacing(8) },
  container: { padding: theme.spacing(3) },
  dividerStyle: {
    height: 1,
    width: '100%',
    backgroundColor: theme.accent.main
  },
  paperFlex: { display: 'flex' },
  itemBottom: { marginBottom: theme.spacing(6) },
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  fontBlack: {
    color: theme.palette.common.black,
    fontWeight: 500
  },
  tableStyle: {
    background: theme.palette.background.default,
    borderRadius: 8,
    overflow: 'hidden'
  }
}));

type RonInviteSccreen = {
  t: TFunction;
  columns: ColumnsType;
  end: moment.Moment | null;
  start: moment.Moment | null;
  date: moment.Moment | null;
  tokens: tableRowComponent[];
  handleOpenScheduleModal: (openModal:boolean) => void;
  scheduleModal: boolean,
  loadingOrder: boolean,
  setTokensRon: React.Dispatch<React.SetStateAction<tableRowComponent[]>>;
  graphOrder: NewOrderType | undefined
}

const RonInviteScreenScene: FC<RonInviteSccreen> = ({
  t,
  columns,
  end,
  start,
  date,
  tokens,
  handleOpenScheduleModal,
  scheduleModal,
  loadingOrder,
  setTokensRon,
  graphOrder
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid item xs={12} className={classes.titleContainer}>
          <Paper className={classes.titleCard}>
            <Typography variant="h6" className={classes.fontBlack}>{t('ron:schedule-closing')}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid container direction="column">
              <Grid item xs={12} className={classes.titleCard}>
                <Typography variant="h6" className={classes.fontBlack}>{t('ron:details')}</Typography>
              </Grid>
              <Grid item>
                <Divider orientation="horizontal" className={classes.dividerStyle} />
              </Grid>
              <Grid item xs={12} className={classes.titleCard}>
                {loadingOrder && <FontAwesomeIcon spin icon="spinner" /> }
                {tokens.length > 0
                  && (
                    <Grid container direction="column">
                      <Grid item className={classes.itemBottom}>
                        <ItemwithIcon icon="calendar-alt" text="Schedule Date / Time" />
                        <Grid container spacing={3}>
                          <Grid item>
                            <Grid container direction="column">
                              <Typography variant="body1" className={classes.fontBlack}>{t('ron:date')}</Typography>
                              <Typography variant="body2" className={classes.fontBlack}>{date?.format('dddd MMMM Do YYYY') || ''}</Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container direction="column">
                              <Typography variant="body1" className={classes.fontBlack}>{t('ron:start')}</Typography>
                              <Typography variant="body2" className={classes.fontBlack}>{start?.format('h:mm a') || ''}</Typography>
                            </Grid>

                          </Grid>
                          <Grid item>
                            <Grid container direction="column">
                              <Typography variant="body1" className={classes.fontBlack}>{t('ron:end')}</Typography>
                              <Typography variant="body2" className={classes.fontBlack}>{end?.format('h:mm a') || ''}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item className={classes.itemBottom} xs={12}>
                        <ItemwithIcon icon="user" text={t('ron:attendees')} />
                        <Grid container direction="column" spacing={3}>
                          <Grid item xs={12}>
                            <TableComponent
                              disabledBorderLeft
                              disableFullborderCell
                              tableClassName={classes.tableStyle}
                              columns={columns}
                              dataSet={tokens || []}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                        >
                          <Button
                            disableElevation
                            color="secondary"
                            variant="contained"
                            onClick={() => handleOpenScheduleModal(true)}
                          >
                            <Typography variant="body2" className={classes.text}>
                              {t('ron:re-schedule-meeting')}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                {!loadingOrder && tokens.length === 0 && (
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <VoidMeetings
                      t={t}
                      onClickSchedule={() => handleOpenScheduleModal(true)}
                    />
                  </Grid>
                </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {scheduleModal
    && (
      <ScheduleModal
        open={scheduleModal}
        setTokensRon={setTokensRon}
        onClose={() => handleOpenScheduleModal(false)}
        onCancel={() => handleOpenScheduleModal(false)}
        graphOrder={graphOrder}
        tokensInfo={{
          parties: tokens.map((item) => item.party_info!),
          date: date?.format() || '',
          init_time: start?.format() || '',
          end_time: end?.format() || ''
        }}
      />
    )}
    </>
  );
};

export default RonInviteScreenScene;
