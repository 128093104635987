import { useTheme } from '@material-ui/styles';
import { getParty } from 'graphql/parties/queries';
import useGetOrder from 'hooks/useGetOrder';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OrderTokenRONType } from 'types/order';
import useAlert from 'utils/alert';
import { capitalize } from 'utils/helpers';

import { generateLenderCreditsColumns, tableRowComponent } from './services';

const useRonInviteScreen = () => {
  const { t } = useTranslation();
  const [tokensRon, setTokensRon] = useState<tableRowComponent[]>([]);
  const { id: orderId } = useParams<{ id: string }>();
  const [scheduleModal, setScheduleModal] = useState(false);
  const theme = useTheme();
  const showAlert = useAlert();
  const getOrder = useGetOrder(orderId!, 'get-order', {
    enabled: orderId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const fillPrebuilt = async (partiesTofind: OrderTokenRONType[]) => {
      const Proms = partiesTofind.map((item) => getParty({ id: item.party_id }));
      const promiesesResult = await Promise.all(Proms);

      setTokensRon(promiesesResult.map((item) => ({
        ...partiesTofind.find((token) => token.party_id === item.getParty._id)!,
        party_info: item.getParty
      })));
    };

    if (getOrder.isSuccess && getOrder.data) {
      const { tokens } = getOrder.data.getOrder;
      if (tokens) {
        fillPrebuilt(tokens || []);
      }
    }
  }, [getOrder]);

  const columns = useMemo(() => generateLenderCreditsColumns(
    t,
    theme,
    (url) => {
      if (url) {
        navigator.clipboard.writeText(url).then(() => {
          showAlert(capitalize(t('documents:link-copied')), 'success');
        }).catch(() => {
          showAlert(capitalize(t('dialogs:there-is-an')), 'error');
        });
      }
    }
  ), [showAlert, t, theme]);

  return {
    t,
    columns,
    tokensRon,
    scheduleModal,
    setScheduleModal,
    loadingOrder: getOrder.isLoading,
    setTokensRon,
    graphOrder: getOrder.data?.getOrder
  };
};

export default useRonInviteScreen;
