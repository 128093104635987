import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { uploadFile } from 'services/tus/uploadFile';
import useAlert from 'utils/alert';
import { asyncForEach } from 'utils/helpers';
import { Buffer } from 'buffer';

import { DocumentRequestInfo } from './types';

export const useDocumentRequestHooks = () => {
  const { t } = useTranslation();
  const Alert = useAlert();
  const { token } = useParams<{ token: string }>();
  const [documentRequestInfo, setDocumentRequestInfo] = useState<DocumentRequestInfo>();
  const [isLoading, setIsLoading] = useState(true);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [isSendButtonDisable, setIsSendButtonDisable] = useState(true);
  const [filesToUpload, setFilesToUpload] = useState<{ archiveId: string, file: File}[]>([]);
  const [isValidToken, setIsValidToken] = useState(false);
  const [showThanksTrustMessage, setShowThanksTrustMessage] = useState(false);

  useEffect(() => {
    let info: DocumentRequestInfo | null = null;
    try {
      info = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
      setDocumentRequestInfo(info!);
    } catch (error) {
      setIsValidToken(false);
    } finally {
      const nowInSeconds = Math.round(Date.now() / 1000);
      const isValidDate = (info && nowInSeconds >= info.nbf && nowInSeconds <= info.exp) || false;
      setIsValidToken(isValidDate);
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    setIsSendButtonDisable(filesToUpload.length <= 0);
  }, [filesToUpload]);

  const onCloseWindow = () => {
    window.open('about:blank', '_self');
    window.close();
  };

  const onAddFile = ({
    archiveId,
    file
  }: {archiveId: string, file: File}) => {
    setFilesToUpload((prevFilesToUpdate) => ([...prevFilesToUpdate, { archiveId, file }]));
  };

  const onRemoveFile = ({
    archiveId,
    fileName
  }: { archiveId: string, fileName: string}) => {
    setFilesToUpload((prevFilesToUpdate) => prevFilesToUpdate.filter((fileToUpdate) => !(fileToUpdate.archiveId === archiveId && fileToUpdate.file.name === fileName)));
  };

  const onSendFiles = async () => {
    setUploadIsLoading(true);

    let filesUploadedCount = 0;
    await asyncForEach(filesToUpload, async ({ archiveId, file }) => {
      try {
        await uploadFile({
          file,
          archiveId,
          token,
          orderId: documentRequestInfo?.order_id!,
          partyId: documentRequestInfo?.party!,
          filename: file.name
        });
        filesUploadedCount += 1;
      } catch (error) {
        Alert(t('documents:error-uplading'), 'error');
      }
    });

    if (filesUploadedCount === filesToUpload.length) {
      setShowThanksTrustMessage(true);
    }
    setUploadIsLoading(false);
  };

  return {
    t,
    documentRequestInfo,
    isValidToken,
    isLoading,
    isSendButtonDisable,
    showThanksTrustMessage,
    onCloseWindow,
    onAddFile,
    onRemoveFile,
    uploadIsLoading,
    onSendFiles
  };
};
