import React from 'react';

import useChargesKMLN from '../services/useGetChargesKM';
import ChargesLNScene from './charges-ln-scene';

const ChargesLN = () => {
  const {
    dataCharges,
    handleUpdateAccounting,
    handleDelete,
    handleEditModal,
    handlePayor,
    openAddModal,
    handleAddModal,
    state
  } = useChargesKMLN(['L', 'N']);
  return (

    <ChargesLNScene
      accountingData={dataCharges || []}
      handleUpdateAccounting={handleUpdateAccounting}
      handleDelete={handleDelete}
      handleEditModal={handleEditModal}
      handlePayor={handlePayor}
      openAddModal={openAddModal}
      handleaddModal={handleAddModal}
      state={state as any}
    />
  );
};

export default ChargesLN;
