import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  dividerVertical: {
    backgroundColor: theme.palette.text.hint,
    height: 'auto',
  },
}));
