import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import DateInput from 'components/date-picker/date-picker';
import TableComponent from 'components/Table';
import TimePicker from 'components/time-picker/time-picker';
import React, { FC } from 'react';
import { FieldArrayWithId, UseFormReturn, useWatch } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { NewOrderPartyType } from 'types/order';

import ItemwithIcon from '../item-with-icon';
import SearchContact from './components/search-local-parties';
import { ColumnsType, scheduleMettingTokens } from './services';

const useStyles = makeStyles((theme: any) => ({
  titleCard: {
    height: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3)
  },
  titleContainer: { marginBottom: theme.spacing(8) },
  container: { padding: theme.spacing(3) },
  dividerStyle: {
    height: 1,
    width: '100%',
    backgroundColor: theme.accent.main
  },
  itemtitle: {
    color: theme.palette.tab.offselected,
    fontWeight: 500,
    marginLeft: theme.spacing(1)
  },
  itemiconConainer: { marginBottom: theme.spacing(2) },
  itemBottom: { marginBottom: theme.spacing(6) },
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  cancelText: {
    textTransform: 'none',
    fontWeight: 500,
  },
  buttonswithMargin: { marginLeft: theme.spacing(2) },
  fontTitle: { color: theme.palette.common.black, fontWeight: 500 },
  tableStyle: {
    background: theme.palette.background.default,
    borderRadius: 8,
    overflow: 'hidden'
  },
  usersNeed: {
    display: 'flex',
    justifyContent: 'center'
  },
  usersNeedTitle: {
    color: theme.palette.error.main,
    fontWeight: 500,
  }
}));

type RonInviteSccreen = {
  t: TFunction;
  methods: UseFormReturn<scheduleMettingTokens, any>;
  columns: ColumnsType;
  handleAddParty: (idParty:string) => void,
  open: boolean;
  onCancel: () => void;
  onClose: () => void;
  attendies: FieldArrayWithId<scheduleMettingTokens, 'parties', 'id'>[];
  handleUpdateTokens: (data:scheduleMettingTokens)=>void;
  loading: boolean;
  involvedParties: NewOrderPartyType[];
}

const RonScheduleMeeting: FC<RonInviteSccreen> = ({
  t,
  columns,
  methods,
  handleAddParty,
  open,
  onCancel,
  onClose,
  attendies,
  handleUpdateTokens,
  loading,
  involvedParties
}) => {
  const classes = useStyles();

  const { control, setValue, handleSubmit } = methods;

  const startTime = useWatch({
    name: 'init_time',
    control
  });

  const endTime = useWatch({
    name: 'end_time',
    control
  });

  const date_meeting = useWatch({
    name: 'date',
    control
  });

  const handleClick = (partyId: string, action: ()=> void) => {
    if (!attendies.find((attendie) => attendie._id === partyId)) {
      handleAddParty(partyId);
    }
    action();
  };

  const today = new Date();

  const allPartiesHasEmail = attendies.every((attendie) => attendie.username != null && attendie.username !== '');

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <Grid container direction="column">
        <Grid item>
          <Paper>
            <Grid container direction="column">
              <Grid item xs={12} className={classes.titleCard}>
                <Typography variant="h6" className={classes.fontTitle}>{t('ron:details')}</Typography>
              </Grid>
              <Grid item>
                <Divider orientation="horizontal" className={classes.dividerStyle} />
              </Grid>
              <Grid item xs={12} className={classes.titleCard}>
                <Grid container direction="column">
                  <Grid item className={classes.itemBottom}>
                    <ItemwithIcon icon="calendar-alt" text={t('ron:schedule-date-time')} />
                    <Grid container spacing={3}>
                      <Grid item>
                        <DateInput
                          label={t('ron:date')}
                          name=""
                          minDate={today}
                          value={date_meeting}
                          onChange={(date) => {
                            setValue('date', date?.toString() || '');
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <TimePicker
                          value={startTime}
                          label={t('ron:start')}
                          onChange={(date) => {
                            setValue('init_time', date?.toString() || '');
                          }}
                        />

                      </Grid>
                      <Grid item>
                        <TimePicker
                          value={endTime}
                          label={t('ron:end')}
                          onChange={(date) => {
                            setValue('end_time', date?.toString() || '');
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item className={classes.itemBottom}>
                    <ItemwithIcon icon="user" text={t('ron:attendees')} />
                    <Grid container direction="column" spacing={3}>
                      <Grid item xs={12}>
                        <SearchContact
                          handleCreateAssociation={handleClick}
                          involvedParties={involvedParties}
                        />
                      </Grid>
                      {!allPartiesHasEmail && (
                        <Grid item xs={12} className={classes.usersNeed}>
                          <Box>
                            <Typography variant="body2" className={classes.usersNeedTitle}>
                              {t('ron:all-users-email')}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TableComponent
                          disabledBorderLeft
                          disableFullborderCell
                          tableClassName={classes.tableStyle}
                          columns={columns}
                          dataSet={attendies || []}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <Button
                        disableElevation
                        color="secondary"
                        variant="outlined"
                        onClick={onCancel}
                        className={classes.buttonswithMargin}
                      >
                        <Typography variant="body2" className={classes.cancelText}>
                          {t('ron:cancel')}
                        </Typography>
                      </Button>
                      <Button
                        disableElevation
                        color="secondary"
                        variant="contained"
                        disabled={
                          date_meeting === ''
                          || startTime === null
                          || endTime === null
                          || attendies.length === 0
                          || !allPartiesHasEmail
                        }
                        onClick={handleSubmit(handleUpdateTokens)}
                        className={classes.buttonswithMargin}
                      >
                        {loading ? <FontAwesomeIcon spin icon="spinner" />
                          : (
                            <Typography variant="body2" className={classes.text}>
                              {t('ron:schedule-meeting')}
                            </Typography>
                          )}
                      </Button>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RonScheduleMeeting;
