import { TFunction } from 'i18next';
import { NewOrderPartyType } from 'v2-types/order';

export const getPartyByKind = ({ parties, kind }: {parties: NewOrderPartyType[], kind: string}) => parties.find((party) => party.kinds.includes(kind));
export const filterPartiesByKind = ({ parties, kind }: {parties: NewOrderPartyType[], kind: string}) => parties.filter((party) => party.kinds.includes(kind));

export const transactionTypesTranslations = (t:TFunction) => ({
  Purchase: t('orders:purchase'),
  Refinance: t('orders:refinance'),
  HELOC: t('orders:heloc'),
  Foreclosure: t('orders:foreclosure'),
  'Bulk sale': t('orders:bulk-sale'),
  'Construction Binder': t('orders:construction-binder'),
  'Construction Loan': t('orders:construction-loan'),
  'Title Search Only': t('orders:title-search-only'),
  'Title Search Only (Refinance)': t('orders:title-search-refinance'),
  'Commercial Purchase': t('orders:commercial-purchase'),
  'Commercial Refinance': t('orders:commercial-refinance')
});
