import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import { formatMoney } from 'utils/helpers';
import { AccountingInvolvedParty } from 'v2-types/order';

const InvolvedPartyAccounted = ({ row, handleDeleteLink }:{row:AccountingInvolvedParty, handleDeleteLink:(paymentRecordId:string, associationKind:string, associationId:string)=>void}) => (
  <Box display="flex" flexDirection="column">
    { (row?.accounted_by && row?.accounted_by.length > 0)
		  ? row?.accounted_by.map((accouted) => (

  <Box display="flex" flexDirection="column" style={{ marginBottom: 8 }} key={`${accouted.amount}${accouted.record.description}`}>
    <Box display="flex" flexDirection="row">
      <IconButton
        style={{ padding: 0, fontSize: 16, marginRight: 8 }}
        onClick={() => {
	    handleDeleteLink(accouted.record._id, row.kind, row._id);
	  }}
      >
        <FontAwesomeIcon icon="trash" size="sm" />
      </IconButton>
      {accouted.record.description}
    </Box>
    <Box style={{ marginLeft: 16 }}>
      {`${formatMoney(accouted.amount)}`}
    </Box>
  </Box>

      		))
      : <>not defined yet</> }
  </Box>
);

export default InvolvedPartyAccounted;
