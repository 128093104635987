import { GQLGetParty } from 'graphql/parties/queries';
import React, { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { MutateFunction, MutationResult, QueryResult } from 'react-query';
import {
  GQLCreateAddress,
  GQLCreateLicense,
  GQLCreatePartyPayment,
  GQLCreatePhone,
  GQLCreateUser,
  GQLDeleteAddress,
  GQLDeleteLicense,
  GQLDeleteParty,
  GQLDeletePartyAssociation,
  GQLDeletePartyPayment,
  GQLDeletePhone,
  GQLResetPartyPassword,
  GQLUpdateAddress,
  GQLUpdateLicense,
  GQLUpdateParty,
  GQLUpdatePartyAssociation,
  GQLUpdatePhone,
} from 'services/querys/parties';
import { parseDate } from 'utils/helpers';
import { NewUserType } from 'v2-types/user';

import { addLoginEmailIfNotExist } from '.';

const useMutations = (
  id: string,
  history: any,
  methods: UseFormReturn<NewUserType, any>,
  setFetch: React.Dispatch<React.SetStateAction<boolean>>,
  setErrors: React.Dispatch<React.SetStateAction<string[]>>,
  sendInvitation: boolean,
  setSendInvitation: React.Dispatch<React.SetStateAction<boolean>>,

  createUser: MutateFunction<GQLCreateUser, unknown, { id: string }, unknown>,

  getPartyResponse: QueryResult<GQLGetParty, unknown>,

  responseUpdateParty: MutationResult<GQLUpdateParty, unknown>,
  responseUpdateAddress: MutationResult<GQLUpdateAddress, unknown>,
  responseUpdatePhone: MutationResult<GQLUpdatePhone, unknown>,
  responseUpdateLicense: MutationResult<GQLUpdateLicense, unknown>,
  responseUpdatePartyAssociation: MutationResult<
    GQLUpdatePartyAssociation,
    unknown
  >,

  responseCreateAddress: MutationResult<GQLCreateAddress, unknown>,
  responseCreatePhone: MutationResult<GQLCreatePhone, unknown>,
  responseCreateLicense: MutationResult<GQLCreateLicense, unknown>,
  responseCreateUser: MutationResult<GQLCreateUser, unknown>,

  responseDeleteAddress: MutationResult<GQLDeleteAddress, unknown>,
  responseDeletePhone: MutationResult<GQLDeletePhone, unknown>,
  responseDeleteLicense: MutationResult<GQLDeleteLicense, unknown>,
  responseDeleteParty: MutationResult<GQLDeleteParty, unknown>,
  responseDeletePartyAssociation: MutationResult<
    GQLDeletePartyAssociation,
    unknown
  >,
  responsePartyPayment: MutationResult<GQLCreatePartyPayment, unknown>,
  responseDeletePartyPayment: MutationResult<GQLDeletePartyPayment, unknown>,
  responseResetPartyPassword: MutationResult<GQLResetPartyPassword, unknown>,
  setUrlResetPassword: React.Dispatch<React.SetStateAction<string>>,
  setIsUrlResetPasswordRequestLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setUrlResetPasswordError: React.Dispatch<React.SetStateAction<string>>
) => {
  // Fetch
  useEffect(() => {
    if (getPartyResponse.isSuccess && getPartyResponse.data) {
      const data = getPartyResponse.data.getParty;
      methods.setValue('_id', data._id);
      methods.setValue('username', data.username);
      methods.setValue('user_id', data.user_id);
      methods.setValue('first_name', data.first_name);
      methods.setValue('middle_name', data.middle_name);
      methods.setValue('last_name', data.last_name);
      methods.setValue('kind', data.kind);
      methods.setValue('ssn', data.ssn || '');
      methods.setValue('birth', parseDate(data.birth as string));
      methods.setValue('gender', data.gender || '');
      methods.setValue('onboarded', data.onboarded);
      methods.setValue('name', data.name);
      methods.setValue('addresses', data.addresses);
      methods.setValue('phones', data.phones);
      methods.setValue('licenses', data.licenses);
      methods.setValue('orders', data.orders);
      methods.setValue('parties', data.parties);
      methods.setValue('members', data.members);
      methods.setValue('payments', data.payments);

      const emails = addLoginEmailIfNotExist(data.emails, data.username, data._id);
      methods.setValue('emails', emails);

      setFetch(false);
    }
    if (getPartyResponse.isError) {
      getPartyResponse.clear();
      history.push('/contacts');
    }
  }, [getPartyResponse, methods, history, setFetch]);

  // Update
  useEffect(() => {
    if (responseUpdateParty.isSuccess && responseUpdateParty.data) {
      responseUpdateParty.reset();
      methods.setValue('name', responseUpdateParty.data.updateParty.name);
    }

    if (responseUpdateParty.isError) {
      responseUpdateParty.reset();
      let { errors = ['Error update party'] } = (
        responseUpdateParty.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseUpdateParty, methods, setErrors]);

  useEffect(() => {
    if (responseUpdateAddress.isSuccess) {
      responseUpdateAddress.reset();
    }

    if (responseUpdateAddress.isError) {
      responseUpdateAddress.reset();
      let { errors = ['Error update address'] } = (
        responseUpdateAddress.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseUpdateAddress, setErrors]);

  useEffect(() => {
    if (responseUpdatePhone.isSuccess) {
      responseUpdatePhone.reset();
    }

    if (responseUpdatePhone.isError) {
      responseUpdatePhone.reset();
      let { errors = ['Error update phone'] } = (
        responseUpdatePhone.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseUpdatePhone, setErrors]);

  useEffect(() => {
    if (responseUpdateLicense.isSuccess) {
      responseUpdateLicense.reset();
    }

    if (responseUpdateLicense.isError) {
      responseUpdateLicense.reset();
      let { errors = ['Error update license'] } = (
        responseUpdateLicense.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseUpdateLicense, setErrors]);

  useEffect(() => {
    if (responseUpdatePartyAssociation.isSuccess) {
      responseUpdatePartyAssociation.reset();
    }

    if (responseUpdatePartyAssociation.isError) {
      responseUpdatePartyAssociation.reset();
      let { errors = ['Error update party'] } = (
        responseUpdatePartyAssociation.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseUpdatePartyAssociation, setErrors]);

  // Create
  useEffect(() => {
    if (responseCreateAddress.isSuccess && responseCreateAddress.data) {
      responseCreateAddress.reset();
      methods.setValue(
        'addresses',
        responseCreateAddress.data.createAddress.addresses
      );
    }

    if (responseCreateAddress.isError) {
      responseCreateAddress.reset();
      let { errors = ['Error create address'] } = (
        responseCreateAddress.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseCreateAddress, methods, setErrors]);

  useEffect(() => {
    if (responseCreatePhone.isSuccess && responseCreatePhone.data) {
      responseCreatePhone.reset();
      methods.setValue('phones', responseCreatePhone.data.createPhone.phones);
    }

    if (responseCreatePhone.isError) {
      responseCreatePhone.reset();
      let { errors = ['Error create phone'] } = (
        responseCreatePhone.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseCreatePhone, methods, setErrors]);

  useEffect(() => {
    if (responseCreateLicense.isSuccess && responseCreateLicense.data) {
      responseCreateLicense.reset();
      methods.setValue(
        'licenses',
        responseCreateLicense.data.createLicense.licenses
      );
    }

    if (responseCreateLicense.isError) {
      responseCreateLicense.reset();
      let { errors = ['Error create license'] } = (
        responseCreateLicense.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseCreateLicense, methods, setErrors]);

  useEffect(() => {
    if (responseCreateUser.isSuccess && responseCreateUser.data) {
      responseCreateUser.reset();
      methods.setValue('user_id', responseCreateUser.data.createUser.user_id);
    }

    if (responseCreateUser.isError) {
      responseCreateUser.reset();
      let { errors = ['Error inviting user'] } = (
        responseCreateUser.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseCreateUser, methods, setErrors]);

  // Delete
  useEffect(() => {
    if (responseDeleteAddress.isSuccess && responseDeleteAddress.data) {
      responseDeleteAddress.reset();
      methods.setValue(
        'addresses',
        responseDeleteAddress.data.deleteAddress.addresses
      );
    }

    if (responseDeleteAddress.isError) {
      responseDeleteAddress.reset();
      let { errors = ['Error delete address'] } = (
        responseDeleteAddress.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseDeleteAddress, methods, setErrors]);

  useEffect(() => {
    if (responseDeletePhone.isSuccess && responseDeletePhone.data) {
      responseDeletePhone.reset();
      methods.setValue('phones', responseDeletePhone.data.deletePhone.phones);
    }

    if (responseDeletePhone.isError) {
      responseDeletePhone.reset();
      let { errors = ['Error delete phone'] } = (
        responseDeletePhone.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseDeletePhone, methods, setErrors]);

  useEffect(() => {
    if (responseDeleteLicense.isSuccess && responseDeleteLicense.data) {
      responseDeleteLicense.reset();
      methods.setValue(
        'licenses',
        responseDeleteLicense.data.deleteLicense.licenses
      );
    }

    if (responseDeleteLicense.isError) {
      responseDeleteLicense.reset();
      let { errors = ['Error delete license'] } = (
        responseDeleteLicense.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseDeleteLicense, methods, setErrors]);

  useEffect(() => {
    if (responseDeleteParty.isSuccess && responseDeleteParty.data) {
      responseDeleteParty.reset();
      history.push('/contacts');
    }

    if (responseDeleteParty.isError) {
      responseDeleteParty.reset();
      let { errors = ['Error unliking'] } = (responseDeleteParty.error as any)
        .response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseDeleteParty, methods, history, setErrors]);

  useEffect(() => {
    if (
      responseDeletePartyAssociation.isSuccess
      && responseDeletePartyAssociation.data
    ) {
      responseDeletePartyAssociation.reset();
      methods.setValue(
        'parties',
        responseDeletePartyAssociation.data.deletePartyAssociation.parties
      );
    }

    if (responseDeletePartyAssociation.isError) {
      responseDeletePartyAssociation.reset();
      let { errors = ['Error deleting association'] } = (
        responseDeletePartyAssociation.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [responseDeletePartyAssociation, methods, setErrors]);

  useEffect(() => {
    if (responsePartyPayment.isSuccess && responsePartyPayment.data) {
      setTimeout(() => {
        getPartyResponse.refetch();
      }, 100);
      responsePartyPayment.reset();
      methods.setValue(
        'payments',
        responsePartyPayment.data.createPartyPayment.payments
      );
    }

    if (responsePartyPayment.isError) {
      responsePartyPayment.reset();
      let { errors = ['Error create payment'] } = (
        responsePartyPayment.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [methods, responsePartyPayment, setErrors]);

  useEffect(() => {
    if (
      responseDeletePartyPayment.isSuccess
      && responseDeletePartyPayment.data
    ) {
      setTimeout(() => {
        getPartyResponse.refetch();
      }, 100);
      responseDeletePartyPayment.reset();
      methods.setValue(
        'payments',
        responseDeletePartyPayment.data.deletePartyPayment.payments
      );
    }

    if (responseDeletePartyPayment.isError) {
      responseDeletePartyPayment.reset();
      let { errors = ['Error delete payment'] } = (
        responseDeletePartyPayment.error as any
      ).response;
      errors = errors.map((error: any) => error.message);
      setErrors((prev) => [...prev, errors]);
    }
  }, [methods, responseDeletePartyPayment, setErrors]);

  useEffect(() => {
    if (
      responseResetPartyPassword.isSuccess
      && responseResetPartyPassword.data
    ) {
      setUrlResetPassword(responseResetPartyPassword.data.resetPassword.url);
      responseResetPartyPassword.reset();
    } else if (responseResetPartyPassword.isError) {
      responseResetPartyPassword.reset();

      const { errors = [] } = (responseResetPartyPassword.error as any)
        .response;
      const error = errors.length > 0 ? errors[0]?.message : 'Unexpected Error';

      setUrlResetPasswordError(error);
    }

    setIsUrlResetPasswordRequestLoading(responseResetPartyPassword.isLoading);
  }, [
    responseResetPartyPassword,
    setUrlResetPassword,
    setUrlResetPasswordError,
    setIsUrlResetPasswordRequestLoading,
  ]);

  return null;
};

export default useMutations;
