import { UserReduxStateType } from 'types/redux';

import { SET_PROFILE } from './action-types';

export const setProfile = (payload: UserReduxStateType) => ({
  type: SET_PROFILE,
  payload,
});

export default { setProfile };
