import NumberField from 'components/number-field';
import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { LoanFormType } from 'views/orders/loans/types';

type Props = {
  control: Control<LoanFormType, object>,
  name: 'loanAmountEntry.amount',
  label: string,
  disabled?: boolean
}

export const MoneyField: FC<Props> = ({
  control,
  name,
  label,
  disabled = false
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <NumberField
        color="secondary"
        label={label}
        name={label}
        handleBlur={field.onBlur}
        value={field.value}
        onValueChange={({ floatValue }) => field.onChange(floatValue)}
        prefix="$"
        thousandSeparator
        disabled={disabled}
      />
    )}
  />
);
