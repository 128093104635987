import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { LoanFormType } from 'views/orders/loans/types';

import { AssumptionForm } from './components/assumption-form';
import { DemandFeatureForm } from './components/demand-feature-form';
import { EscrowAccountForm } from './components/escrow-account-form';
import { LiabilityForm } from './components/liability-form';
import { NegativeAmortizationForm } from './components/negative-amortization-form';
import { PartialPaymentsForm } from './components/partial-payments';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>,
  isDataLoading: boolean,
}

export const Disclosures: FC<Props> = ({
  loanForm,
  isDataLoading
}) => {
  const { control, setValue } = loanForm;

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Box style={{ display: 'flex', gap: '20px' }}>
        <LiabilityForm
          control={control}
          isDataLoading={isDataLoading}
        />

        <NegativeAmortizationForm
          control={control}
          isDataLoading={isDataLoading}
        />
      </Box>

      <Box style={{ display: 'flex', gap: '20px' }}>
        <AssumptionForm
          control={control}
          isDataLoading={isDataLoading}
        />

        <PartialPaymentsForm
          control={control}
          isDataLoading={isDataLoading}
        />
      </Box>

      <Box style={{ display: 'flex', gap: '20px' }}>
        <DemandFeatureForm
          control={control}
          isDataLoading={isDataLoading}
        />

        <EscrowAccountForm
          control={control}
          isDataLoading={isDataLoading}
          setValue={setValue}
        />
      </Box>
    </Box>
  );
};
