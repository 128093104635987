import { useAuth0 } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";
import {
  ACCESS_TOKEN,
  AGENCY_SELECTED_ID,
  ORDER_ID,
  TOKEN_CLAIMS,
} from "utils/constants";

const useValidSession = () => {
  const { logout } = useAuth0();

  return (error: AxiosError | null) => {
    if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(TOKEN_CLAIMS);
      localStorage.removeItem(ORDER_ID);
      localStorage.removeItem(AGENCY_SELECTED_ID);

      window.location.replace(window.location.origin);
      logout({ returnTo: window.location.origin });
    }
  };
};

export default useValidSession;
