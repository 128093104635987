import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  title: {
    fontWeight: 500,
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.accent.main,
    paddingBottom: theme.spacing(2)
  },
  container: { padding: theme.spacing(2) },
  actionButtons: { padding: theme.spacing(2) }
}));
