/* eslint-disable react/no-array-index-key */
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BaseButton } from 'components/BaseButton';
import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AGENCY_SELECTED_ID } from 'utils/constants';

import AmountsPDFInformation from './components/amounts-information-pdf';
import DatesPDFForm from './components/dates-form';
import OrderTypeForm from './components/ortder-type-form';
import PartiesForm from './components/parties-form';
import PropertyPDFInformation from './components/property-pdf-information';
import SkeletonForm from './components/skeleton-form';
import { formInfoOrder } from './services';

const useStyles = makeStyles((theme:any) => ({
  container: { overflow: 'hidden', padding: theme.spacing(3) },
  title: { fontWeight: 500, color: theme.palette.common.black },
  buttonsContainer: { marginBottom: theme.spacing(1) },
  createButtonContainer: { marginLeft: theme.spacing(2) },
  buttonText: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
  },
  pdfContainer: {
    overflowY: 'scroll',
    padding: theme.spacing(2),
    maxHeight: '80vh'
  },
  centerElements: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  spacingSection: { marginBottom: theme.spacing(2) },
}));

  type orderStepperSceneProps={
	  open:boolean,
	  pdfInformation: {
		  images: string[],
		  blobFiles: Blob[]
	  },
	  onClose: () => void;
	  methods: UseFormReturn<formInfoOrder, object>,
	  states: {
		code: string;
		parent: string;
		name: string;
	}[],
	handleCreateOrderAI: (data: formInfoOrder) => void,
	loadingCreatingOrder:boolean,
	handleRetry:()=>void
  }

const OrderStepperScene:FC<orderStepperSceneProps> = ({
  open,
  pdfInformation,
  onClose,
  states,
  methods,
  handleCreateOrderAI,
  loadingCreatingOrder,
  handleRetry
}) => {
  const classes = useStyles();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
  } = methods;
  const loadingform = watch('loadingForm');
  const loadingParties = watch('loadingParties');
  const errorform = watch('errorForm');
  const agencySelected = localStorage.getItem(AGENCY_SELECTED_ID);
  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
      <DialogContent className={classes.container}>
        <form onSubmit={handleSubmit((data) => handleCreateOrderAI(data))}>
          <Grid container direction="row">
            <Grid item xs={6} className={classes.pdfContainer} style={{ backgroundColor: '#263238' }}>
              <Box flex="1" display="flex" />
              {pdfInformation?.images?.map((image, index) => (
                <Box className={classes.spacingSection}>
                  <img
                    src={image}
                    alt={`${index}${image.slice(0, 5)}`}
                    style={{ maxWidth: '100%' }}
                    key={`${index}-${image.slice(0, 10)}`}
                  />
                </Box>
				 ))}
            </Grid>
            <Grid item xs={6} className={classes.pdfContainer}>

              <Grid container direction="column">
                <Grid item style={{ textAlign: 'center' }} className={classes.spacingSection}>
                  <Typography variant="h6" className={classes.title}>Information collected from the document</Typography>
                </Grid>
                <Grid item style={{ textAlign: 'center' }} className={classes.spacingSection}>
                  <Typography variant="body2" className={classes.title}>Plase check it before create the order, You can change this later in the order</Typography>
                </Grid>

                {!agencySelected && (
                <Grid item style={{ textAlign: 'center' }} className={classes.spacingSection}>
                  <Typography variant="body2" className={classes.title}>Plase Selece an Agency in your profile before create a new order</Typography>
                </Grid>
                )}

                {errorform !== null && (
                <Grid container direction="column" alignContent="center" spacing={2}>
                  <Grid item>
                    <Alert severity="error">
                      <Typography variant="body2">there was an error, please try again</Typography>
                    </Alert>
                  </Grid>
                  <Grid item className={classes.centerElements}>
                    <BaseButton
                      text="retry"
                      icon="arrow-alt-circle-right"
                      onClick={handleRetry}
                    />
                  </Grid>
                </Grid>
                )}
                {loadingform ? <SkeletonForm /> : (
                  <>
                    <PartiesForm
                      loadingParties={loadingParties}
                      control={control}
                      setValue={setValue}
                    />

                    <OrderTypeForm
                      control={control}
                    />

                    <PropertyPDFInformation
                      control={control}
                      states={states}
                    />

                    <DatesPDFForm
                      control={control}
                    />

                    <AmountsPDFInformation
                      control={control}
                    />

                    <Grid item className={classes.spacingSection}>
                      <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                        <Grid item>
                          <BaseButton
                            text="Cancel"
                            buttonVariant="outlined"
                            buttonColor="primary"
                            disabled={loadingCreatingOrder}
                            onClick={onClose}
                          />
                        </Grid>
                        <Grid item>
                          <BaseButton
                            text="continue"
                            type="submit"
                            isLoading={loadingCreatingOrder}
                            disabled={!agencySelected}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>

            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
	  };

export default OrderStepperScene;
