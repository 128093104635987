import { DeleteAssociationReturn, deleteLedgerAssociation } from 'graphql/ledger/mutations';
import { useMutation } from 'react-query';

const useDeleteLedgerAssociation = (onSuccess?:(data:DeleteAssociationReturn)=>void, onError?:()=> void) => {
  const [deleteAssociation, deleteAssociationResponse] = useMutation(deleteLedgerAssociation, { onSuccess, onError });

  return { deleteAssociation, deleteAssociationResponse };
};

export default useDeleteLedgerAssociation;
