import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, IconButton } from '@material-ui/core';
import React from 'react';
import { PartyInfo } from 'views/orders/new-parties/party/PartyInfo';

export const ModalParty = ({ openModal, closeModal, currentNode }) => (
  <Dialog open={openModal} fullScreen onClose={closeModal}>
    <Box
      padding={1}
      display="flex"
      justifyContent="end"
      style={{ borderBottom: '2px solid #FB8C00', }}
    >
      <IconButton
        onClick={() => closeModal()}
        style={{
          width: '50px',
          height: '50px',
          backgroundColor: '#d5dbdf',
        }}
      >
        <FontAwesomeIcon icon="times" />
      </IconButton>
    </Box>
    <PartyInfo
      kindProp={currentNode?.kind || ''}
      partyIdProp={currentNode?.partyId || ''}
    />
  </Dialog>
);
