import { format } from 'date-fns';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { REFINANCE } from 'utils/constants';
import { AccountingType } from 'v2-types/order';

import { updateOrderPolicy } from './graphql/mutations';
import { getOrder } from './graphql/querys';
import { updateType } from './services/context';
import useListings from './services/getlistings';
import { FormPolicy } from './services/types';

const usePolicyRefactor = () => {
  const { id } = useParams<{ id: string }>();
  const [accounting, setAccounting] = useState<AccountingType[]>([]);
  const [selectedPolicy, setSelectedPolicy] = useState<
    'owners_policy' | 'lenders_policy'
  >('owners_policy');
  const [selectedEndorsement, setSelectedEndorsmement] = useState<AccountingType | null>(null);
  const options = useListings();
  const [typeTransaction, setTypeTransaction] = useState('');

  const [info, setInfo] = useState<updateType>({
    time: '',
    type: 'info',
    message: 'listen updates',
  });
  const methods = useForm<FormPolicy>({
    mode: 'onChange',
    defaultValues: {
      parties: [],
      selectedPolicy: 'owners_policy',
      owners_policy: {
        disclosure_section: 'B',
        endorsements_shown_as: '',
        policy_type: '',
        proposed_insured: '',
        rate_type: '',
        remove_section_13: false,
        stat_code: '',
      },
      lenders_policy: {
        disclosure_section: 'B',
        endorsements_shown_as: '',
        policy_type: '',
        proposed_insured: '',
        rate_type: '',
        remove_section_13: false,
        stat_code: '',
      },
    },
  });

  const policyData = useQuery(['policy-order', id], () => getOrder(id), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setAccounting(data?.getOrder?.accounting || []);
      const { lenders_policy, owners_policy, parties, configuration } = data.getOrder;
      const transactionType = configuration.transaction_type;
      methods.reset({
        parties: parties || [],
        owners_policy,
        lenders_policy,
        selectedPolicy:
          transactionType === REFINANCE ? 'lenders_policy' : 'owners_policy',
      });
      setSelectedPolicy(
        transactionType === REFINANCE ? 'lenders_policy' : 'owners_policy'
      );
      setTypeTransaction(configuration.transaction_type);
    },
  });
  const [updateOrderInfo, updateOrderResponse] = useMutation(
    updateOrderPolicy,
    {
      onSuccess: (data) => {
        updateOrderResponse.reset();
        const now = format(new Date(), 'hh:mm');
        setInfo({ time: now, type: 'success', message: `updated at  ${now}` });
        setAccounting(data.updateOrderPolicy.accounting || []);
      },
    }
  );
  return {
    loadingData: policyData.isFetching,
    accounting,
    setSelectedPolicy,
    selectedPolicy,
    methods,
    options,
    setAccounting,
    setSelectedEndorsmement,
    selectedEndorsement,
    info,
    setInfo,
    updateOrderInfo,
    id,
    typeTransaction,
  };
};

export default usePolicyRefactor;
