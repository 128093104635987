import { Grid } from '@material-ui/core';
import DatePicker from 'components/date-picker/date-picker';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DatesAndConfigurationForm,
  validateAcceptanceDate,
  validateRecissionDate
} from '../services';
import Title from './title';

const FormClosingDate = () => {
  const { control, watch } = useFormContext<DatesAndConfigurationForm>();
  const [TransactionType] = watch(['transaction_type']);
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title>{t('datesAndConfig:closing-date')}</Title>
      </Grid>
      {validateAcceptanceDate.includes(TransactionType) && (
      <>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="acceptance_date"
            render={({ field }) => (
              <DatePicker
                value={field.value}
                disableToolbar={false}
                label={t('datesAndConfig:acceptance-date')}
                handleBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          />

        </Grid>

      </>
      )}
      <Grid item xs={6}>
        <Controller
          control={control}
          name="closing_date"
          render={({ field }) => (
            <DatePicker
              value={field.value}
              onChange={field.onChange}
              disableToolbar={false}
              label={t('datesAndConfig:estimated-closing-date')}
              handleBlur={field.onBlur}
            />
          )}
        />

      </Grid>
      {!validateRecissionDate.includes(TransactionType) && (
        <Grid item xs={6}>
          <Controller
            control={control}
            name="contract_date"
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onChange={field.onChange}
                disableToolbar={false}
                label={t('datesAndConfig:contract-date')}
                handleBlur={field.onBlur}
              />
            )}
          />
        </Grid>
	    ) }
      <Grid item xs={6}>
        <Controller
          control={control}
          name="funding_date"
          render={({ field }) => (
            <DatePicker
              value={field.value}
              inputRef={field.ref}
              disableToolbar={false}
              label={t('datesAndConfig:founding-date')}
              handleBlur={field.onBlur}
              onChange={field.onChange}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <Controller
          control={control}
          name="disbursement_date"
          render={({ field }) => (
            <DatePicker
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
              disableToolbar={false}
              label={t('datesAndConfig:disbursement-date')}
              handleBlur={field.onBlur}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FormClosingDate;
