import { Box, makeStyles, Typography } from '@material-ui/core';
import TableComponent from 'components/Table';
import React, { FC } from 'react';
import { AccountingInvolvedParty, AccountingType } from 'v2-types/order';

import { ColumnsType, generateCollapseRow } from './services';
import { ChargeEntry } from './types';

const useStyles = makeStyles((theme) => ({
  padding: { padding: theme.spacing(3) },
  containerTable: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
  },
  scrollDiv: {
    overflowX: 'auto',
    width: '100%',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.5) },
    '&&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark, },
  },
  voidCharges: {
    marginLeft: theme.spacing(3),
    fontWeight: 500,
    color: theme.palette.common.black,
  },
}));

type TableChargesProps = {
  columns: ColumnsType;
  mapedData: any;
  handleClickItem?: (item: any) => void;
  footerTableSet: any;
  footertable: any;
  itemsCollapsed: any;
  addDisbursement: (chargeData: ChargeEntry | undefined) => void;
  showRemoveAssociationModal: (chargeData: ChargeEntry | undefined) => void,
  showEditAssociationModal: (chargeData: AccountingType, accountingPartyEntry: AccountingInvolvedParty) => void,
  showDeleteReceiptModalConfirm: (chargeData: ChargeEntry | undefined) => void
};

const TableChargesScene: FC<TableChargesProps> = ({
  columns,
  mapedData,
  handleClickItem,
  footerTableSet,
  footertable,
  itemsCollapsed,
  addDisbursement,
  showRemoveAssociationModal,
  showEditAssociationModal,
  showDeleteReceiptModalConfirm
}) => {
  const classes = useStyles();
  const collapsedcolumns = generateCollapseRow({ addDisbursement, showRemoveAssociationModal, showEditAssociationModal, showDeleteReceiptModalConfirm });

  return (
    <Box className={`${classes.scrollDiv} ${classes.containerTable}`}>
      {mapedData.length === 0 && (
        <Typography className={classes.voidCharges} variant="body1">
          We dont have any charge information, to add a new entry click on the
          button
        </Typography>
      )}
      <TableComponent
        pointer
        disabledBorderLeft
        disableFullborderCell
        columns={columns}
        dataSet={mapedData}
        onClickItem={(row: any, __: any, index) => {
          if (handleClickItem) {
            handleClickItem({ ...row, indexRow: index });
          }
        }}
        footerDataSet={footerTableSet}
        footerComponents={footertable}
        itemsCollapsed={itemsCollapsed}
        columnCollapsing={collapsedcolumns}
      />
    </Box>
  );
};

export default TableChargesScene;
