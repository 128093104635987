import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => ({
  select: { '& .MuiFilledInput-root': { backgroundColor: 'transparent' } },
  underline: {
    width: '77px',
    fontSize: theme.spacing(2),
    color: theme.palette.primary.light,
    fontWeight: 500,
    paddingLeft: theme.spacing(1),
    '&&&:before': { borderBottom: 'none' },
    '&&:after': { borderBottom: 'none' },
    '&:hover': { background: theme.palette.input.border },
    '& .MuiFilledInput-input': { paddingTop: '10px', }
  }
}));
