import {
  Box,
  ClickAwayListener,
  makeStyles,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { FC, useState } from 'react';

const LightTooltip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme:any) => ({
  text: {
    fontFamily: 'Roboto',
    color: theme.palette.primary.darker,
    fontWeight: 400,
    background: theme.palette.background.default,
    opacity: 1
  },
  textShadow: { textShadow: `0.25px 0.5px ${theme.palette.text.light}` },
  colorFontTooltip: { color: theme.palette.common.white },
}));

type CellWithTooltipProps = {
  data?: string,
  position?: 'center' | 'left' | 'right',
  colorText?: string | undefined,
  shadow?: boolean,
  backgroundColor?: string | undefined,
  tooltipText: string,
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | undefined
}

const CellWithTooltip: FC<CellWithTooltipProps> = ({
  data,
  position,
  colorText,
  shadow,
  backgroundColor,
  tooltipText,
  tooltipPlacement
}) => {
  const formattedArray = tooltipText.split('-').reduce<any[]>((prev, curr) => {
    prev.push(curr);
    prev.push(<br key={curr} />);
    return prev;
  }, []);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <LightTooltip
          PopperProps={{ disablePortal: true, }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          placement={tooltipPlacement}
          title={(
            <Typography component="span" className={classes.colorFontTooltip} variant="caption">
              {formattedArray}
            </Typography>
          )}
        >
          <Box
            style={{ width: '100%' }}
            onMouseEnter={handleTooltipOpen}
            onMouseLeave={handleTooltipClose}
          >
            <Typography
              style={{
                textAlign: position || 'center',
                color: colorText,
                background: backgroundColor
              }}
              className={`${classes.text} ${shadow && classes.textShadow}`}
            >
              {data}

            </Typography>
          </Box>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
};

CellWithTooltip.defaultProps = {
  data: undefined,
  position: 'center',
  colorText: undefined,
  shadow: false,
  backgroundColor: undefined,
  tooltipPlacement: undefined,
};

export default CellWithTooltip;
