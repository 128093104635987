import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  makeStyles,
  Tab,
  Tabs as MUITabs,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { truncateString } from 'utils/helpers';

import { useFormContext, useWatch } from "react-hook-form";
import { ProrationsForm } from '../types';
import { defaultTabs } from '../utils';

type TabsProps = {
  selectedTabIndex: number,
  onSelectTab: (number) => void,
  onDelete: (prorationId: string) => void,
  disabledDelete: boolean
};

const useStyles = makeStyles((theme: any) => ({
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedLabel: { color: theme.palette.primary.darker },
  closeButtonContainer: { marginLeft: theme.spacing(3) },
  closeButton: {
    fontSize: 14,
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
}));

export const Tabs = ({
  selectedTabIndex,
  onSelectTab,
  onDelete,
  disabledDelete
}: TabsProps) => {
  const classes = useStyles();

  const { control } = useFormContext<ProrationsForm>();
  const prorationsForm = useWatch({ control });

  return (
    <MUITabs
      variant="scrollable"
      value={selectedTabIndex}
      onChange={(_, newIndex) => onSelectTab(newIndex)}
    >
      {prorationsForm.forms && prorationsForm.forms.map((value, index) => {
        return (
          <Tab
            key={value._id}
            className={clsx({ [classes.selectedLabel]: selectedTabIndex === index })}
            value={index}
            label={defaultTabs.includes(value.description!)
              ? truncateString(value.description!, 20)
              : (
                <Box className={classes.labelContainer}>
                  <Box>{truncateString(value.description!, 20)}</Box>

                  <Box className={classes.closeButtonContainer}>
                    <span>
                      <IconButton
                        component="div"
                        className={classes.closeButton}
                        onClick={() => onDelete(value._id!)}
                        disabled={disabledDelete}
                      >
                        <FontAwesomeIcon icon="times" />
                      </IconButton>
                    </span>
                  </Box>
                </Box>
              )}
          />
        );
      })}
    </MUITabs>
  );
};