import { Typography, TypographyVariant } from '@material-ui/core';
import React, { FC, useEffect, useRef } from 'react';

type TypewriterProps = {
  text: string,
  variant?: TypographyVariant,
  animationEnabled?: boolean
}

export const Typewriter: FC<TypewriterProps> = ({
  text,
  variant = 'body1',
  animationEnabled = true
}) => {
  let index = 0;
  const textLength = text.length;
  const textElementRef = useRef<HTMLSpanElement>();
  const speed = textLength > 200 ? (5000 / textLength) : 25;

  function typeCharacter() {
    if (index < textLength && textElementRef.current) {
      textElementRef.current!.innerHTML = textElementRef.current!.innerHTML + text[index];
      index += 1;
      setTimeout(typeCharacter, speed);
    }
  }

  useEffect(() => {
    if (textLength > 0 && textElementRef.current) {
      if (animationEnabled) {
        typeCharacter();
      } else {
        textElementRef.current!.innerHTML = text;
      }
    }
  }, [text, textElementRef]);

  return (
    <Typography
      innerRef={textElementRef}
      variant={variant}
      color="primary"
      style={{ whiteSpace: 'pre-wrap' }}
    />
  );
};
