/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, PopperProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from 'components/text-field';
import useSearchParty from 'hooks/useSearchParty';
import React, { ComponentType, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewUserType } from 'types/new-user';

import CustomPartyOption from './custom-party-option';

type SearchContactProps = {
  getValue: (user: NewUserType | null) => void,
  handleContactCreation?: (kind: string, searchText?: string) => void,
  popperComponent?: ComponentType<PopperProps>,
  disabled?: boolean,
  prevText?:string
};

const SearchContact: FC<SearchContactProps> = ({ getValue, handleContactCreation, popperComponent, disabled, prevText }) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const [localvalue, setLocalValue] = useState<NewUserType | null>(null);
  const { t } = useTranslation();

  const { parties } = useSearchParty(searchText);
  useEffect(() => {
    if (prevText) {
      setSearchText(prevText);
    }
  }, []);
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          disabled={disabled}
          value={localvalue}
          freeSolo
          fullWidth
          options={parties}
          PopperComponent={popperComponent}
          filterOptions={(options) => (options.length > 0
            ? [...options, {
              _id: 'add-new-contact',
              username: '',
              user_id: '',
              name: '',
              emails: [],
              addresses: [],
              phones: []
            } as unknown as NewUserType
            ]
            : []
          )}
          getOptionLabel={(party) => party.name}
          onInputChange={(_, value, reason) => {
            if (reason === 'input') {
              setSearchText(value);
            }
          }}
          renderOption={(props) => (
            <CustomPartyOption
              contact={props}
              handleContactCreation={(kind) => {
                setSearchText(undefined);
                if (handleContactCreation) {
                  handleContactCreation(kind, searchText);
                }
              }}
              t={t}
            />
          )}
          onChange={(_, value) => {
            _.stopPropagation();
            if (value && typeof value === 'object') {
              setLocalValue(value);
              getValue(value);
            } else {
              setLocalValue(null);
              getValue(null);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={params.InputProps.ref}
              InputProps={params.inputProps}
              label={t('parties:contact')}
              placeholder={t('parties:type-as-3-characters')}
            />
          )}
        />
      </Grid>
    </Grid>

  );
};

export default SearchContact;
