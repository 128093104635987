import React, { FC } from 'react';
import { ListOrdersParams } from 'types/common';
import { CURRENT_PATH, DEFAULT_ROUTE } from 'utils/constants';

import { v2_setWorkspace } from '../../v2-utils/workspace';
import useOrders from './orders-hooks';
import OrdersScene from './orders-scene';
import { FiltersType } from './services';

type OrdersProps = {
  agencySelected?: string;
};

const Orders: FC<OrdersProps> = (props) => {
  const { agencySelected } = props;
  const {
    t,
    history,
    filter,
    setFilter,
    currentData,
    userIsTitleEmployee,
    setParams,
    searchString,
    setSearchString,
    orders,
    loading,
    agencyName,
    state,
    dispatch
  } = useOrders(agencySelected);

  const handleModalOpen = (param: boolean) => {
    if (param) {
      dispatch({ type: 'new-order' });
    } else {
      dispatch({ type: null });
    }
  };

  const handleOpenOrderStepper = (type:string, data:any) => {
    dispatch({ type, data });
  };

  const handleSearchOrder = (orderId: string) => setSearchString(orderId);

  const handleChangeFilter = (value: FiltersType) => {
    setFilter(value);
    setParams((prev) => {
      const data: ListOrdersParams = {
        ...prev,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      };

      if (value === 'all') {
        delete data.status;
        return { ...data };
      }

      return {
        ...data,
        status: value,
      };
    });
  };

  const handleTablePageChange = (action: 'prev' | 'next') => {
    if (action === 'next' && currentData.page < currentData.pages) {
      return (cuantity = 1) => {
        setParams((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            page: prev.pagination.page + cuantity,
          },
        }));
      };
    }

    if (action === 'prev' && currentData.page > 1) {
      return (cuantity = 1) => {
        setParams((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            page: prev.pagination.page - cuantity,
          },
        }));
      };
    }

    return () => {};
  };

  const handleSelectRow = (id: string, code: string) => {
    localStorage.setItem(
      CURRENT_PATH,
      JSON.stringify({ view: DEFAULT_ROUTE, param: '' })
    );
    history.push(`/orders/details/${id}/${DEFAULT_ROUTE}`);

    // V2
    v2_setWorkspace({ id, code });
  };

  return (
    <OrdersScene
      agencyId={agencySelected || ''}
      t={t}
      filter={filter}
      searchString={searchString}
      orders={orders}
      loading={loading}
      currentData={currentData}
      userIsTitleEmployee={userIsTitleEmployee}
      handleModalOpen={handleModalOpen}
      handleChangeFilter={handleChangeFilter}
      handleSearchOrder={handleSearchOrder}
      handleSelectRow={handleSelectRow}
      handleTablePageChange={handleTablePageChange}
      agencyName={agencyName}
      state={state as any}
      handleOpenOrderStepper={handleOpenOrderStepper}
    />
  );
};

export default Orders;
