import React, { createContext, useState } from 'react';

export const MutateThemeContext = createContext<any>({ theme: 'light', setTheme: () => {} });

const MutateThemeProvider = ({ children }: any) => {
  const [theme, setTheme] = useState('light');
  const value = { theme, setTheme };

  return (
    <MutateThemeContext.Provider value={value}>
      {children}
    </MutateThemeContext.Provider>

  );
};

export default MutateThemeProvider;
