import { Grid, makeStyles, Typography } from '@material-ui/core';
import TextField from 'components/text-field';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  titleContainer: { paddingBottom: theme.spacing(3) },
  title: {
    textAlign: 'center',
    color: theme.palette.tab.offselected,
  },
}));

type SearchFieldProps = {
  t: TFunction;
  searchText: string | undefined;
  handleSearch: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

const SearchField: FC<SearchFieldProps> = ({
  t,
  searchText,
  handleSearch,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid item xs={12} className={classes.titleContainer}>
        <Typography variant="h6" className={classes.title}>
          {t('users:search-parties-orgs')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={3} />

          <Grid item xs={6}>
            <TextField
              label={t('users:placeholder-search-parties-orgs')}
              value={searchText}
              onChange={handleSearch}
              placeholder={t('parties:type-as-3-characters')}
            />
          </Grid>

          <Grid item xs={3} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchField;
