import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoanFormType } from 'views/orders/loans/types';

import { RadioButtonForm } from '../radio-button-form';

type Props = {
  control: Control<LoanFormType, object>
  isDataLoading: boolean,
}

export const LiabilityForm: FC<Props> = ({ control, isDataLoading }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="unpaid_liability"
      render={({ field }) => (
        <RadioButtonForm
          title={t('disclosure:liability-after-foreclosure')}
          description={t('disclosure:if-you-lender')}
          value={field.value}
          options={[
            {
              label: t('disclosure:protects-from-unpaid-balance-liability'),
              disabled: isDataLoading,
              value: true
            },
            {
              label: t('disclosure:dont-protects-from-unpaid-balance-liability'),
              disabled: isDataLoading,
              value: false
            }
          ]}
          onChange={(value) => field.onChange(value === 'true')}
        />
      )}
    />
  );
};
