import { useEffect, useRef } from 'react';

export const useAutoscroll = () => {
  const htmlContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let observer: MutationObserver;
    let resizeObserver: ResizeObserver;

    if (htmlContainerRef.current) {
      observer = new MutationObserver((mutationList) => {
        mutationList.forEach((mutation) => {
          if (mutation.type === 'childList') {
            const { addedNodes } = mutation;

            if (addedNodes.length > 0) {
              const lastNode = addedNodes.item(addedNodes.length - 1);
              if (lastNode) {
                if (resizeObserver) {
                  resizeObserver.disconnect();
                }
                resizeObserver = new ResizeObserver(() => {
                  htmlContainerRef.current!.scroll(0, htmlContainerRef.current!.scrollHeight);
                });
                if (lastNode.childNodes.length > 0 && lastNode.childNodes[0].parentElement) {
                  resizeObserver.observe(lastNode.childNodes[0].parentElement!);
                }
              }
            }
          }
        });
      });
      observer.observe(htmlContainerRef.current, { childList: true });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [htmlContainerRef.current]);

  return { htmlContainerRef };
};
