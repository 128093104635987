import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  instructionsContainer: {
    padding: '25px',
    borderBottom: '1px solid rgba(249, 246, 233, 1)',
    borderRight: '1px solid rgba(249, 246, 233, 1)',
    borderLeft: '1px solid rgba(249, 246, 233, 1)',
  },
  tableCell: { padding: '10px 0px' },
  tableCellColumn: {
    backgroundColor: 'white',
    color: '#000000',
    padding: '10px 0px'
  },
  tableCellCollapsable: {
    padding: 0,
    border: 'none'
  }
}));
