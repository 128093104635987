import debounce from 'debounce';
import { updateUserNotes } from 'graphql/notes/mutations';
import { getUserNotes } from 'graphql/notes/queries';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import useAlert from 'utils/alert';
import { QUERY_KEY_NOTES } from 'utils/constants';

import { NoteType } from './types';
import { getNotesArray, joinNotesArray } from './utils';

export const useNotesHooks = () => {
  const [noteList, setNoteList] = useState<NoteType[]>([]);
  const [selectedNote, setSelectedNote] = useState<NoteType>();
  const [actionsEnabled, setActionsEnabled] = useState(true);
  const noteListRef = useRef<NoteType[]>([]);
  const selectedNoteRef = useRef<NoteType>({} as NoteType);
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const showAlert = useAlert();

  const { isLoading } = useQuery([QUERY_KEY_NOTES], {
    queryFn: getUserNotes,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const noteArray = getNotesArray(data?.getUserNotes.notes ?? '');
      setNoteList(noteArray);
      noteListRef.current = noteArray;
    }
  });

  const [updateNotes, updateNotesResult] = useMutation(updateUserNotes, {
    onSuccess: (updateNotesData) => {
      const noteArray = getNotesArray(updateNotesData?.updateUserNotes.notes ?? '');
      setNoteList(noteArray);

      if (!actionsEnabled) setActionsEnabled(true);

      updateNotesResult.reset();
    },
    onError: () => {
      showAlert(t('notes:error-saving'), 'error');
    }
  });

  const selectNote = (note: NoteType) => {
    setSelectedNote(note);
    selectedNoteRef.current = note;
    if (inputRef.current) inputRef.current.value = note.text;
  };

  const updateNotesDebounce = useCallback(debounce(() => {
    const notesString = joinNotesArray(noteListRef.current);

    updateNotes({ notes: notesString });
  }, 1000), []);

  const saveNote = (newText: string) => {
    const noteIndex = noteListRef.current.findIndex((note) => note.id === selectedNoteRef.current.id);
    if (noteIndex < 0) return;

    setActionsEnabled(false);
    noteListRef.current[noteIndex].text = newText;

    updateNotesDebounce.clear();
    updateNotesDebounce();
  };

  const addNewNote = () => {
    const newNote = { id: '0', text: '' };
    const noteArray: NoteType[] = [newNote, ...noteListRef.current].map((note, index) => ({
      id: index.toString(),
      text: note.text
    }));

    updateNotesDebounce.clear();

    if (inputRef.current) inputRef.current.focus();
    noteListRef.current = noteArray;

    selectNote(newNote);
    setNoteList(noteArray);
    setActionsEnabled(false);
  };

  const removeNote = (noteId: string) => {
    const noteArray = noteListRef.current.filter((note) => note.id !== noteId).map((note, index) => ({
      id: index.toString(),
      text: note.text
    }));
    const notesString = joinNotesArray(noteArray);

    const newSelectedNote = noteArray.length > 0 ? noteArray[0] : { id: '0', text: '' };
    selectNote(newSelectedNote);

    setNoteList(noteArray);
    noteListRef.current = noteArray;

    updateNotes({ notes: notesString });
  };

  const copyNote = (text: string) => {
    navigator.clipboard.writeText(text);
    showAlert(t('notes:copied-clipboard'), 'success');
  };

  return {
    noteList,
    isLoading,
    selectedNote,
    selectNote,
    saveNote,
    addNewNote,
    removeNote,
    copyNote,
    isSavingChanges: updateNotesResult.isLoading,
    actionsEnabled,
    inputRef
  };
};
