import { combineReducers } from 'redux';

import { reducer as profileReducer } from '../profile-reducer';
import { reducer as orderReducer } from '../order-reducer';
import { reducer as workspaceReducer } from '../workspace-reducer';
import { reducer as generalReducer } from '../general-reducer';
import { reducer as orderConfigurationReducer } from '../order-configuration-reducer';

const initialState = {};

const appReducer = combineReducers({
  profileReducer,
  orderReducer,
  workspaceReducer,
  generalReducer,
  orderConfigurationReducer
});

const rootReducer = (state = initialState, action) => appReducer(state, action);

export default rootReducer;
