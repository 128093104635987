import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  containerTable: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
	  },
	  scrollDiv: {
    overflowX: 'auto',
    width: '100%',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.5) },
    '&&::-webkit-scrollbar-thumb': {
		  background: theme.palette.secondary.main,
		  borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark },
	  },
}));
