import { Typography } from '@material-ui/core';
import { CashToCloseData } from 'hooks/useGetCashToClose/types';
import { TFunction } from 'i18next';
import React, { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Description } from '../components/description';

import { MoneyField } from '../components/MoneyField';
import { CashToCloseEntryRow } from '../types';

type GenerateFooterTable = {
  colspan: number;
  renderer: (params: { item: CashToCloseEntryRow; }) => ReactElement;
}

export const getFooterColumns = (t: TFunction, control: Control<CashToCloseData, object>): GenerateFooterTable[] => ([
  {
    colspan: 1,
    renderer: (item) => {
      const row = item as unknown as CashToCloseEntryRow;
      return (<Description text={row.title} />);
    }
    ,
  },
  {
    colspan: 1,
    renderer: () => <></>
  },
  {
    colspan: 1,
    renderer: (item) => {
      const row = item as unknown as CashToCloseEntryRow;
      return (
        <Controller
          control={control}
          name={`${row.fieldName}.final`}
          render={({ field }) => (
            <MoneyField
              isDisabled
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      );
    },
  },
  {
    colspan: 4,
    renderer: () => (<></>),
  },
]);
