import { gql } from "graphql-request";
import gqlRequest from "graphql/generic";
import {
  AccountingFilter,
  AccountingType,
  DatesAndConfigurationType,
  NewOrderPartyType,
} from "v2-types/order";
import { NewUserType } from "v2-types/user";

export type GetOrderConfigurationsType = {
  getOrder: {
    configuration: DatesAndConfigurationType;
    order_id: string;
    accounting: Pick<AccountingType, "_id" | "amount" | "code">[];
    parties: Pick<NewOrderPartyType, "_id" | "accounts" | "kinds" | "name">[];
  };
};

type GetOrderConfigurationParams = {
  id: string;
  filters: { [key: string]: AccountingFilter };
};

const queryGetOrderParties = `
query GetOrder($id: ID!,$filters: OrderFilters) {
	getOrder(_id: $id,filters: $filters) {
	  order_id
	  parties {
        _id
        accounts {
          _id
          reference
          kind
        }
        kinds
        name
      }
	  accounting {
		_id
		amount
		code
	  }
	  configuration {
			acceptance_date
			closing_date
			contract_date
			disbursement_date
			funding_date
			transaction_type
			type
			workflow
			cash_only
			heloc
			construction_loan
			closing_place {
				_id
				address
				country
				geo {
					coordinates
					type
				}
				locality
				kind
				postal_code
				references
				settlement
				state
				street_address
			}
	  }
	}
  }
`;

export const getOrderConfiguration = ({
  id,
  filters,
}: GetOrderConfigurationParams) =>
  gqlRequest<GetOrderConfigurationsType, GetOrderConfigurationParams>(
    queryGetOrderParties,
    { id, filters }
  );

export type GQLGetParty = {
  getParty: Pick<NewUserType, "addresses">;
};
export type GQLGetPartyPayload = {
  id: string;
};

export const getParty = (payload: GQLGetPartyPayload) => {
  const query = gql`
    query GetParty($id: ID!) {
      getParty(_id: $id) {
        _id
        addresses {
          _id
          state
          address
          postal_code
          street_address
          country
          locality
          references
          settlement
          kind
        }
      }
    }
  `;

  return gqlRequest<GQLGetParty, GQLGetPartyPayload>(query, payload);
};
