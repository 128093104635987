import calculations from './calculations';
import orders from './orders';
import parties from './parties';
import ron from './ron';

export default {
  ...calculations,
  ...orders,
  ...parties,
  ...ron,
};
