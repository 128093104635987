import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const SkeletonProperties = () => (
  <Grid container direction="column">
    <Grid item>
      <Grid container spacing={1}>
        <Grid item>
          <Skeleton width={140} height={50} />
        </Grid>
        <Grid item>
          <Skeleton width={140} height={50} />
        </Grid>
        <Grid item>
          <Skeleton width={140} height={50} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={1}>
        <Grid item>
          <Skeleton width={500} height={84} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={1} style={{ padding: 0 }} justifyContent="space-between">
        <Grid item>
          <Skeleton width={240} height={84} />
        </Grid>
        <Grid item>
          <Skeleton width={240} height={84} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={1} style={{ padding: 0 }} justifyContent="space-between">
        <Grid item>
          <Skeleton width={150} height={84} />
        </Grid>
        <Grid item>
          <Skeleton width={150} height={84} />
        </Grid>
        <Grid item>
          <Skeleton width={150} height={84} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={1} style={{ padding: 0 }} justifyContent="space-between">
        <Grid item>
          <Skeleton width={240} height={84} />
        </Grid>
        <Grid item>
          <Skeleton width={240} height={84} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={1}>
        <Grid item>
          <Skeleton width={500} height={84} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={1}>
        <Grid item>
          <Skeleton width={500} height={84} />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default SkeletonProperties;
