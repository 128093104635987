import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { OLD_ROUTE } from 'utils/constants';

import BackdropLoading from '../../common/BackdropLoading';

type PageCheckerAuthenticatedProps = {
  render: () => React.ReactElement;
};

const PageCheckerAuthenticated = ({ render }: PageCheckerAuthenticatedProps) => {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <BackdropLoading open loadingText="loading" />;
  }

  if (!isAuthenticated) {
    if (!location?.pathname.includes('/sign-in')) {
      localStorage.setItem(OLD_ROUTE, location?.pathname);
    }

    return <Redirect to="/sign-in" />;
  }

  return render();
};

export default PageCheckerAuthenticated;
