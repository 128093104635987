/* eslint-disable react/no-array-index-key */
import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { Column } from 'types/common';

import useStyles from './style';

type BodyType = {
  pointer: boolean | undefined;
  columns: Column[];
  dataSet: any[];
  loading?: boolean;
  columnCollapsing?: Column[];
  itemsCollapsed?: { [key: string]: boolean };
  disabledBorderCell?: boolean;
  disabledBorderLeft?: boolean;
  disableFullborderCell?: boolean;
  onClickItem?: (row: any, column: Column, position?: number) => void;
  borderLeftClassName?: any;
  cellAlignment?:
    | 'left'
    | 'right'
    | 'inherit'
    | 'center'
    | 'justify'
    | undefined;
};

const BodyTable = ({
  pointer,
  columns,
  dataSet,
  loading,
  onClickItem,
  itemsCollapsed,
  columnCollapsing,
  disabledBorderCell,
  disabledBorderLeft,
  disableFullborderCell,
  borderLeftClassName,
  cellAlignment,
}: BodyType) => {
  const {
    paddingCell,
    borderBottomCell,
    borderTopCell,
    borderLeftCell,
    borderCellStyle,
  } = useStyles();
  return (
    <TableBody>
      {dataSet.map((row, indexRow) => (
        <Fragment key={`item-data-${indexRow}`}>
          <TableRow
            className={`${
              !disabledBorderLeft && (borderLeftClassName || borderLeftCell)
            }`}
            style={{ cursor: pointer ? 'pointer' : 'default' }}
          >
            {columns?.map((column: Column, index: number) => (
              <Fragment key={`item-column-${index}`}>
                {column?.columns
                  && column?.columns?.map((item, iterator) => (
                    <TableCell
                      key={`column-${iterator}`}
                      align={cellAlignment}
                      style={{
                        height: 0,
                        minWidth: item.header?.width,
                        width: item.header?.width,
                      }}
                      className={`
                      ${paddingCell}
                      ${!disabledBorderCell && borderBottomCell}
                      ${!disabledBorderCell && borderTopCell}
                      ${!disableFullborderCell && borderCellStyle}`}
                      onClick={() => onClickItem && onClickItem(row, column, indexRow)}
                    >
                      {item?.renderer({ row, column, index, indexRow })}
                    </TableCell>
                  ))}

                {column?.renderer && (
                  <TableCell
                    key={`cell-item-${index}`}
                    align={cellAlignment}
                    style={{
                      height: 0,
                      minWidth: column.header?.minWidth,
                      width: column.header?.width,
                    }}
                    className={`
                    ${paddingCell}
                    ${!disabledBorderCell && borderBottomCell}
                    ${!disabledBorderCell && borderTopCell}
                    ${!disableFullborderCell && borderCellStyle}
                    `}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onClickItem) {
                        onClickItem(row, column, indexRow);
                      }
                    }}
                  >
                    {column?.renderer({ row, column, index, indexRow })}
                  </TableCell>
                )}
              </Fragment>
            ))}
          </TableRow>

          {!!columnCollapsing?.length && (
            <TableRow className={`${disabledBorderCell && borderLeftCell}`}>
              {columnCollapsing?.map(
                (columCollapse: Column, index: number) => columCollapse?.renderer
                  && itemsCollapsed
                  && itemsCollapsed[row?.id]
                  && columCollapse?.renderer({
                    row,
                    column: columCollapse,
                    index,
                    shouldCollapse: itemsCollapsed[row?.id],
                  })
              )}
            </TableRow>
          )}
        </Fragment>
      ))}

      {loading && (
        <TableRow>
          <TableCell>
            <CircularProgress />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

BodyTable.defaultProps = {
  loading: false,
  onClickItem: undefined,
  disabledBorderCell: false,
  disabledBorderLeft: false,
  itemsCollapsed: {},
  columnCollapsing: [],
  disableFullborderCell: false,
  borderLeftClassName: undefined,
  cellAlignment: 'center',
};

export default BodyTable;
