import { useTranslation } from 'react-i18next';
import { getAccountingEntryByLedgerCode } from 'utils/accounting';
import {
  LEDGER_CODE_BALANCE,
  LEDGER_CODE_BORROWER_OWES,
  LEDGER_CODE_EARNEST_OWED,
  LEDGER_CODE_EXPECTED_RECEIPTS,
  LEDGER_CODE_LENDER_OWES,
  LEDGER_CODE_OTHERS_DUE,
  LEDGER_CODE_POSTED_DISBURSEMENTS,
  LEDGER_CODE_POSTED_RECEIPTS,
  LEDGER_CODE_SELLER_DUE
} from 'utils/constants';
import { AccountingType } from 'v2-types/order';

type UseBalancingParams = {
  accountingBalance: AccountingType[]
}

export const useBalancing = ({ accountingBalance }: UseBalancingParams) => {
  const { t } = useTranslation();

  const getEntryByLedgerCode = (ledgerCode: string) => getAccountingEntryByLedgerCode({ accounting: accountingBalance, ledgerCode });
  const postedReceipts = getEntryByLedgerCode(LEDGER_CODE_POSTED_RECEIPTS)?.amount || 0;
  const expectedReceipts = getEntryByLedgerCode(LEDGER_CODE_EXPECTED_RECEIPTS)?.amount || 0;
  const postedDisbursements = getEntryByLedgerCode(LEDGER_CODE_POSTED_DISBURSEMENTS)?.amount || 0;
  const lenderOwes = getEntryByLedgerCode(LEDGER_CODE_LENDER_OWES)?.amount || 0;
  const borrowerOwes = getEntryByLedgerCode(LEDGER_CODE_BORROWER_OWES)?.amount || 0;
  const earnestOwed = getEntryByLedgerCode(LEDGER_CODE_EARNEST_OWED)?.amount || 0;
  const sellerDue = getEntryByLedgerCode(LEDGER_CODE_SELLER_DUE)?.amount || 0;
  const othersDue = getEntryByLedgerCode(LEDGER_CODE_OTHERS_DUE)?.amount || 0;
  const balanced = getEntryByLedgerCode(LEDGER_CODE_BALANCE)?.amount || 0;

  return {
    t,
    postedReceipts,
    expectedReceipts,
    postedDisbursements,
    lenderOwes,
    borrowerOwes,
    earnestOwed,
    sellerDue,
    othersDue,
    balanced
  };
};
