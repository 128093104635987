import { Grid, makeStyles } from '@material-ui/core';
import NumberField from 'components/number-field';
import Select from 'components/select-field';
import Switch from 'components/switch';
import TextArea from 'components/text-field';
import React, { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getAmount } from '../services';
import { FormPolicy } from '../services/types';
import usePolicy from '../services/use-policy-context';

const useStyles = makeStyles((theme) => ({
  removeSwitchContainer: {
    marginTop: theme.spacing(1),
    marginBottom: -40,
  },
}));

type PolicyTypeFormProps = {
  selectedPolicy: 'owners_policy' | 'lenders_policy';
};

const purchasePriceConst = 'purchase_price';
const loanAmountConst = 'loan_amount';

const PolicyTypeForm: FC<PolicyTypeFormProps> = ({ selectedPolicy }) => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext<FormPolicy>();
  const classes = useStyles();
  const context = usePolicy();
  const { options, accounting } = context || {};
  const { policy_rate_types, policy_stats, policy_types } = options?.[selectedPolicy] || {};
  const memoizedAmounts = useMemo(() => {
    const purchasePrice = accounting?.find(
      (acc) => acc.code === purchasePriceConst
    );
    const insuranceAmount = accounting?.find(
      (acc) => acc.code === loanAmountConst
    );
    return {
      owners_policy: getAmount(purchasePrice),
      lenders_policy: getAmount(insuranceAmount),
    };
  }, [accounting]);

  return (
    <Grid container direction="column">
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={8}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`${selectedPolicy}.policy_type`}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      label={t('policyInfoAndRates:policy-type')}
                      inputRef={ref}
                      value={getValues(`${selectedPolicy}.policy_type`) || ''}
                      dataKey="code"
                      dataValue="code"
                      dataText="description"
                      handleChange={field.onChange}
                      data={policy_types || []}
                      handleBlur={field.onBlur}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={7}>
                <NumberField
                  disabled
                  thousandSeparator
                  decimalScale={2}
                  prefix="$"
                  value={memoizedAmounts[selectedPolicy]}
                  label={t('policyInfoAndRates:insurance-amount')}
                />
              </Grid>

              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={`${selectedPolicy}.rate_type`}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      label={t('policyInfoAndRates:rate-type')}
                      inputRef={ref}
                      value={getValues(`${selectedPolicy}.rate_type`) || ''}
                      dataKey="code"
                      dataValue="code"
                      dataText="description"
                      handleChange={(value) => {
                        const { onChange } = field;
                        onChange(value.target.value);
                        const otherpolicy = selectedPolicy === 'lenders_policy'
                          ? 'owners_policy'
                          : 'lenders_policy';
                        const policyValue = getValues(
                          `${otherpolicy}.rate_type`
                        );
                        if (policyValue === '') {
                          setValue(
                            `${otherpolicy}.rate_type`,
                            value.target.value
                          );
                        }
                      }}
                      data={policy_rate_types || []}
                      handleBlur={field.onBlur}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`${selectedPolicy}.stat_code`}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      {...field}
                      label={t('policyInfoAndRates:rate-type-stat-code')}
                      inputRef={ref}
                      value={getValues(`${selectedPolicy}.stat_code`) || ''}
                      dataKey="code"
                      dataValue="code"
                      dataText="description"
                      handleChange={field.onChange}
                      data={policy_stats || []}
                      handleBlur={field.onBlur}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`${selectedPolicy}.proposed_insured`}
                  render={({ field: { ref, ...field } }) => (
                    <TextArea
                      {...field}
                      multiline
                      inputRef={ref}
                      rows={10}
                      label={t('policyInfoAndRates:policy-proposed-insured')}
                      value={
                        getValues(`${selectedPolicy}.proposed_insured`) || ''
                      }
                      handleBlur={field.onBlur}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {selectedPolicy === 'lenders_policy' && (
        <Grid item xs={12} className={classes.removeSwitchContainer}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={8} />

            <Grid item xs={4}>
              <Controller
                control={control}
                name={`${selectedPolicy}.remove_section_13`}
                render={({ field: { ref, ...field } }) => (
                  <Switch
                    {...field}
                    inputRef={ref}
                    checked={
                      getValues(`${selectedPolicy}.remove_section_13`) || false
                    }
                    label={t('policyInfoAndRates:remove-section-13')}
                    handleBlur={field.onBlur}
                    handleOnChange={field.onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PolicyTypeForm;
