import { useTheme } from '@material-ui/core';
import { AddAndUpdatePartyLedgerEntryProps } from 'components/add-and-update-party-ledger-modal/add-and-update-party-ledger-entry';
import { useDeleteOrderLedgerAssociation } from 'hooks/useDeleteOrderLedgerAssociation';
import { useDeleteOrderLedgerLink } from 'hooks/useDeleteOrderLedgerLink';
import { useCallback, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import useDeleteLedger from 'utils/ledger/use-delete-ledger-entry';
import { useYupValidationResolver } from 'utils/ledger/use-yup-resolver';
import { AccountingFilter, AccountingInvolvedParty, AccountingType } from 'v2-types/order';
import useSettingTable from 'views/orders/accounting/components/table-ledger/table-ledger-hooks';

import { formatAccountingData, orderChargesByCodeAsc } from '../../services';
import { validationSchema } from '../../services/validate-entry';
import {
  generateColumns,
  generateFooterTable,
  initialState,
  reducerModals,
} from './services';
import { ChargeEntry } from './types';

const useTableCharges = (
  handleUpdateAccounting: (accouting: AccountingType[]) => void,
  accountingData: AccountingType[],
  itemSelected: AccountingType,
  chargesIdentifier: string,
  customColumnsFunction?: any,
  customFooterfunction?: any
) => {
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const [state, dispatch] = useReducer(reducerModals, initialState);
  const { t } = useTranslation();
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const resolver = useYupValidationResolver(validationSchema);
  const { itemsCollapsed, setItemCollapse } = useSettingTable();
  const [payerPayeePartyEntryModalProps, setPayerPayeePartyEntryModalProps] = useState<AddAndUpdatePartyLedgerEntryProps>({
    handleClose: () => dispatch({ type: null }),
    entryLedger: {} as AccountingType,
    handleSuccess: () => {},
  });

  const { deleteEntry, deleteEntryResponse } = useDeleteLedger((data) => {
    deleteEntryResponse.reset();
    showAlert('Charge Deleted', 'success');
    handleUpdateAccounting(data.deleteOrderLedgerEntry.accounting || []);
  });

  const [deleteAssociation, { isLoading: isRemoveAssociationLoading }] = useDeleteOrderLedgerAssociation({
    queryConfig: {
      onSuccess: (data) => {
        showAlert('The Payer was succesfully removed', 'success');
        handleUpdateAccounting(data.deleteOrderLedgerAssociation.accounting);
        dispatch({ type: null });
      },
      onError: (e) => {
        showAlert(e.response.errors[0].message, 'error');
        dispatch({ type: null });
      }
    }
  });

  const [deleteLink, { isLoading: isDeleteLinkLoding }] = useDeleteOrderLedgerLink({
    queryConfig: {
      onSuccess: (data) => {
        showAlert('The receipt was succesfully deleted', 'success');
        handleUpdateAccounting(data.deleteOrderLedgerLink.accounting || []);
        dispatch({ type: null });
      }
    }
  });

  const createcolumns = useCallback(() => {
    if (customColumnsFunction) {
      return customColumnsFunction(
        t,
        theme,
        itemSelected,
        (accountingId: string) => {
          deleteEntry({
            id,
            accountingId,
            filters: { accounting: chargesIdentifier },
          });
        },
        (row) => {
          dispatch({ type: 'edit', data: row });
        },
        (row) => {
          setItemCollapse(row.id);
        },
        (row) => {
          dispatch({ type: 'parties', data: row });
        },
        (row) => {
          dispatch({ type: 'tax', data: row });
        },
        (row) => {
          setPayerPayeePartyEntryModalProps((prevState) => ({
            handleClose: prevState.handleClose,
            handleSuccess: (data) => handleUpdateAccounting(data),
            entryLedger: row.chargeData,
            kindAllowedAssociationList: ['payer', 'payee'],
            partyLedgerEntry: undefined
          }));
          dispatch({ type: 'individual-payee-payer', data: { isEditing: false } });
        },
        (row, kind) => {
          dispatch({
            type: 'remove-association',
            data: {
              row,
              kind
            }
          });
        }
      );
    }

    return generateColumns(
      t,
      theme,
      itemSelected,
      (accountingId: string) => {
        deleteEntry({
          id,
          accountingId,
          filters: { accounting: chargesIdentifier },
        });
      },
      (row) => {
        dispatch({ type: 'edit', data: row });
      },
      (row) => {
        setItemCollapse(row.id);
      },
      (row) => {
        dispatch({ type: 'parties', data: row });
      },
      (row) => {
        dispatch({ type: 'tax', data: row });
      },
      (row) => {
        setPayerPayeePartyEntryModalProps((prevState) => ({
          handleClose: prevState.handleClose,
          handleSuccess: (data) => handleUpdateAccounting(data),
          entryLedger: row.chargeData,
          kindAllowedAssociationList: ['payer', 'payee'],
          partyLedgerEntry: undefined
        }));
        dispatch({ type: 'individual-payee-payer', data: { isEditing: false } });
      },
      (row, kind) => {
        dispatch({
          type: 'remove-association',
          data: {
            row,
            kind
          }
        });
      }
    );
  }, [
    chargesIdentifier,
    customColumnsFunction,
    deleteEntry,
    id,
    itemSelected,
    setItemCollapse,
    t,
    theme,
    handleUpdateAccounting
  ]);

  const footertable = useMemo(() => {
    if (customFooterfunction) {
      return customFooterfunction(() => {
        dispatch({ type: 'add' });
      }, t);
    }

    return generateFooterTable(() => {
      dispatch({ type: 'add' });
    }, t);
  }, [customFooterfunction, t]);

  const mapedData = useMemo(
    () => orderChargesByCodeAsc(formatAccountingData(accountingData) || []),
    [accountingData]
  );

  const footerTableSet = useMemo(
    () => [
      {
        borrower_total_at_closing: mapedData.reduce(
          (prev, curr) => prev + curr.buyer_at_closing,
          0
        ),
        borrower_total_before_closing: mapedData.reduce(
          (prev, curr) => prev + curr.buyer_before_closing,
          0
        ),
        seller_total_at_closing: mapedData.reduce(
          (prev, curr) => prev + curr.seller_at_closing,
          0
        ),
        seller_total_before_closing: mapedData.reduce(
          (prev, curr) => prev + curr.seller_before_closing,
          0
        ),
        total_by_others: mapedData.reduce(
          (prev, curr) => prev + curr.by_others,
          0
        ),
      },
    ],
    [mapedData]
  );

  const addDisbursement = (chargeData: ChargeEntry | undefined) => {
    dispatch({ type: 'disbursements', data: chargeData });
  };

  const showRemoveAssociationModal = (chargeData: ChargeEntry | undefined) => {
    dispatch({ type: 'remove-association-payer-confirmation', data: chargeData });
  };

  const showDeleteReceiptModalConfirm = (chargeData: ChargeEntry | undefined) => {
    dispatch({ type: 'delete-receipt-confirmation', data: chargeData });
  };

  const removeAssociation = () => {
    deleteAssociation({
      accountingId: state.data.accountingId,
      kind: state.data.kind,
      partyId: state.data.partyId,
      orderId,
      filters: { accounting: chargesIdentifier as AccountingFilter }
    });
  };

  const showEditAssociationModal = (chargeData: AccountingType, accountingPartyEntry: AccountingInvolvedParty) => {
    setPayerPayeePartyEntryModalProps((prevState) => ({
      handleClose: prevState.handleClose,
      handleSuccess: (data) => handleUpdateAccounting(data),
      entryLedger: chargeData,
      kindAllowedAssociationList: ['payer'],
      partyLedgerEntry: accountingPartyEntry
    }));
    dispatch({ type: 'individual-payee-payer', data: { isEditing: true } });
  };

  const deleteReceipt = () => {
    deleteLink({
      orderId,
      accountingId: state.data.targetEntryId,
      targetAssociationKind: state.data.kind,
      targetAssociationId: state.data.partyId,
      targetEntryId: state.data.accountingId,
      filters: { accounting: chargesIdentifier as AccountingFilter }
    });
  };

  return {
    deleteEntry,
    mapedData,
    footerTableSet,
    footertable,
    columns: createcolumns(),
    resolver,
    itemsCollapsed,
    addDisbursement,
    state,
    dispatch,
    showRemoveAssociationModal,
    isRemoveAssociationLoading,
    removeAssociation,
    payerPayeePartyEntryModalProps,
    showEditAssociationModal,
    deleteReceipt,
    isDeleteLinkLoding,
    showDeleteReceiptModalConfirm
  };
};

export default useTableCharges;
