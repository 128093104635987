import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, IconButton } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoTemplate, Tag } from 'types/auto-templates';
import { TOKEN_CLAIMS } from 'utils/constants';

import { AutocompleteVirtualize } from './autocomplete-virtualized';
import { ItemTag } from './item-tag';
import { ItemTagImageOption } from './item-tag-image-option';
import { ItemTagTextOption } from './item-tag-text';

type ModalEditTemplatesProps = {
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  openEditModal: boolean;
  template: AutoTemplate | undefined;
  tagsField: Tag[];
  tagsImages: Tag[];
  tagsSignatures: Tag[];
};

export const ModalEditTemplate: FC<ModalEditTemplatesProps> = ({
  setOpenEditModal,
  openEditModal,
  template,
  tagsField,
  tagsImages,
  tagsSignatures,
}) => {
  const access_token = localStorage.getItem(TOKEN_CLAIMS);
  const urlCollabora = process.env.REACT_APP_API_BASE_URL_COLLABORA || '';
  const linkCollabora = `${urlCollabora}templates_${template?.code}&access_token=${access_token}`;
  const { t } = useTranslation();

  return (
    <Dialog
      open={openEditModal}
      onClose={() => setOpenEditModal(false)}
      fullScreen
    >
      <Box
        padding={2}
        display="flex"
        style={{ gap: '12px' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <AutocompleteVirtualize
          options={tagsField}
          label={t('documents:field-info')}
          renderOption={(tag) => (
            <ItemTag
              key={tag._id}
              tag={tag}
              options={() => <ItemTagTextOption tagId={tag._id} />}
            />
          )}
        />
        <AutocompleteVirtualize
          options={tagsImages}
          label={t('documents:images')}
          renderOption={(tag) => (
            <ItemTag
              key={tag._id}
              tag={tag}
              options={() => (
                <>
                  <ItemTagImageOption tagId={tag._id} />
                  <ItemTagTextOption tagId={tag._id} />
                </>
              )}
            />
          )}
        />
        <AutocompleteVirtualize
          options={tagsSignatures}
          label={t('documents:signatures')}
          renderOption={(tag) => (
            <ItemTag
              key={tag._id}
              tag={tag}
              isSignature
              options={() => (
                <>
                  <ItemTagTextOption isSignature tagId={tag._id} />
                </>
              )}
            />
          )}
        />
        <IconButton onClick={() => setOpenEditModal(false)}>
          <FontAwesomeIcon icon="times" />
        </IconButton>
      </Box>
      <Box height="100%">
        <iframe
          title="prev"
          src={linkCollabora}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Dialog>
  );
};
