import { Box, Grid, Paper, useTheme } from '@material-ui/core';
import TableComponent from 'components/Table';
import { TableSkeleton } from 'components/table-skeleton';
import React, { useMemo } from 'react';

import { Header } from './components/header';
import { useCashToClose } from './hooks';
import { useStyles } from './styles';
import { getCashToCloseList } from './utils';
import { generateCashToCloseColumns } from './utils/getCashToCloseColumns';
import { getFooterColumns } from './utils/getFooterColumns';
import { getLoadingColumns } from './utils/getLoadingColumns';

export const CashToClose = () => {
  const {
    t,
    isCashToCloseLoading,
    isUpdateLoading,
    orderTransactionType,
    cashToCloseForm,
    onSave
  } = useCashToClose();

  const { control, setValue } = cashToCloseForm;
  const data = getCashToCloseList({ orderTransactionType });

  const theme = useTheme();
  const classes = useStyles();

  const columns = useMemo(() => generateCashToCloseColumns({
    t,
    theme,
    props: {
      classNames: {
        didThisChangeColumnInputs: classes.didThisChangeColumnInputs,
        rootClassName: classes.desc_cell,
        inputClassName: classes.inputClassName,
        disabledClass: classes.disabledClass
      }
    },
    control,
    setValue,
    isUpdateLoading
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [isUpdateLoading]);

  const footerRows = useMemo(() => getFooterColumns(t,
  // eslint-disable-next-line react-hooks/exhaustive-deps
    control), []);

  return (
    <Box className={classes.rootContainer}>
      <Paper>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Header
              t={t}
              submitForm={onSave}
              isLoading={isUpdateLoading}
            />
          </Grid>

          <Grid item xs={12}>
            {isCashToCloseLoading && (
              <TableSkeleton
                numberOfRows={5}
                showBorder={false}
                columns={getLoadingColumns({ t })}
              />
            )}

            {!isCashToCloseLoading && (
              <TableComponent
                disabledBorderLeft
                dataSet={data.slice(0, -1)}
                footerDataSet={data.slice(-1)}
                columns={columns}
                footerComponents={footerRows}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
