import { TFunction } from 'i18next';
import { WIRETRANSFER_STATUS_LIST } from 'utils/constants';

export const wireTransferStatusList = WIRETRANSFER_STATUS_LIST.map((value) => ({ status: value }));

export const sortByKindList = (t: TFunction) => (
  [
    { label: t('common:orderBy-amount-low-to-high'), property: 'amount', operator: 'asscending' },
    { label: t('common:orderBy-amount-high-to-low'), property: 'amount', operator: 'descending' }
  ]
);
