import NumberField from 'components/number-field';
import React, { FC } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import { OrderProperty } from 'types/order';
import { formatCurrencyValueToNumber } from 'utils/helpers';

type PropertyNumberFieldProps = {
  control: Control<OrderProperty, object>,
  name: FieldPath<OrderProperty>,
  label: string | React.ReactNode,
  decimalScale?: number,
  isAllowed?: (values: NumberFormatValues) => boolean,
  isDisabled?: boolean,
  required?: boolean,
  thousandSeparator?: string | boolean,
  prefix?: string,
  isMoney?: boolean
}

export const PropertyNumberField: FC<PropertyNumberFieldProps> = ({
  control,
  name,
  label,
  decimalScale,
  isAllowed,
  isDisabled = false,
  required = false,
  thousandSeparator,
  prefix,
  isMoney = false
}) => (
  <Controller
    control={control}
    name={name}
    rules={{ required }}
    render={({ field, fieldState: { error } }) => (
      <NumberField
        {...field}
        onChange={(e) => {
          const { value } = e.target;
          field.onChange(isMoney ? formatCurrencyValueToNumber(value) : value);
        }}
        label={label}
        value={Number(field.value)}
        decimalScale={decimalScale}
        isAllowed={isAllowed}
        variant="filled"
        error={!!error}
        disabled={isDisabled}
        thousandSeparator={thousandSeparator}
        prefix={prefix}
        ref={null}
      />
    )}
  />
);
