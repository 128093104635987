import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import { Proration } from 'v2-types/order';

export const prorationsQuery = gql`
  prorations {
    _id
    accounting {
      _id
      amount
      code
      description
    }
    days
    days_year
    debited_party
    decimals
    end_date
    description
    start_date
    type
    schedule
    proration_date
    paid_thru
    next_due
    kind
    include_1099
    impound_disclosure
    estate {
      _id
      address {
        address
      }
    }
  }
  accounting {
    _id
  }
  estates {
    _id
  }
`;

type GetProrationsParams = {
  orderId: string,
}

export type GetProrationsReturn = {
  getOrder: {
    prorations: Proration[]
  }
}

export const getProrations = async (payload: GetProrationsParams) => {
  const query = gql`
  query GetOrder($orderId: ID!) {
    getOrder(_id: $orderId) {
      ${prorationsQuery}
    }
  }
  `;
  const response = await graphQLClient().request<GetProrationsReturn, GetProrationsParams>(query, payload);
  return response;
};
