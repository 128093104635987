import { deleteLedgerEntry, deleteLedgerEntryReturn } from 'graphql/ledger/mutations';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import useAlert from 'utils/alert';

const useDeleteLedger = (onSuccess?:(data:deleteLedgerEntryReturn)=>void, onError?:()=> void) => {
  const [deleteEntry, deleteEntryResponse] = useMutation(deleteLedgerEntry, { onSuccess, onError });
  const showAlert = useAlert();
  useEffect(() => {
    if (deleteEntryResponse.isLoading) {
      showAlert('Deleting...', 'info');
    }
  }, [deleteEntryResponse.isLoading, showAlert]);

  return { deleteEntry, deleteEntryResponse };
};

export default useDeleteLedger;
