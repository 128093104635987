import { yupResolver } from '@hookform/resolvers/yup';
import { createPartyAssociation, GQLCreatePartyAssociation } from 'graphql/parties/mutations';
import useSearchParty from 'hooks/useSearchParty';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { NewPartyType, NewUserType } from 'types/new-user';
import * as Yup from 'yup';

type Params = {
  partyId: string,
  onSuccess: (data: GQLCreatePartyAssociation) => void,
  onError: () => void,
  onClose: () => void,
}

export const useModalCreateAssociationHooks = ({ partyId, onSuccess, onClose, onError }: Params) => {
  const { t } = useTranslation();
  const schema = Yup.object({
    _id: Yup.string().required(t('validations:required')),
    name: Yup.string().required(t('validations:required')),
    kind: Yup.string().required(t('validations:required')),
  });

  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const { parties } = useSearchParty(searchText);
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NewPartyType & { selected: NewUserType | null }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { _id: '', name: '', kind: '', selected: null },
  });

  const [createAssociationMutation] = useMutation(createPartyAssociation, {
    onSuccess: (data) => {
      onSuccess(data);
      reset();
      onClose();
    },
    onError: () => {
      reset();
      onError();
    }
  });

  const createAssociation = handleSubmit((data) => {
    createAssociationMutation({
      id: data._id,
      partyId,
      kind: data.kind
    });
  });

  return {
    schema,
    reset,
    watch,
    control,
    setValue,
    searchText,
    setSearchText,
    parties,
    errors,
    isValid,
    createAssociation,
    t
  };
};
