import { Box, Typography } from "@material-ui/core";
import NumberField from "components/number-field";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useStyles } from "../styles";
import { ProrationsForm } from "../types";

type Props = {
  selectedTabIndex: number,
  isLoading: boolean
}

export const ProratedAmount: FC<Props> = ({ selectedTabIndex, isLoading }) => {
  const classes = useStyles();
  const { control } = useFormContext<ProrationsForm>();

  return (
    <Box>
      <Typography variant="h6" className={classes.subTitle}>
        Prorated Amount
      </Typography>

      <Controller
        control={control}
        key={`forms.${selectedTabIndex}.proratedAmount.amount`}
        name={`forms.${selectedTabIndex}.proratedAmount.amount`}
        render={({ field }) => (
          <NumberField
            color="secondary"
            label="Prorated Amount"
            name="proratedAmount"
            handleBlur={field.onBlur}
            value={field.value}
            thousandSeparator
            onValueChange={({ floatValue }) => field.onChange(floatValue)}
            prefix="$"
            disabled={isLoading}
          />
        )}
      />
    </Box>
  );
};