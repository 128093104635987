/* eslint-disable react/no-array-index-key */
import {
  Box,
  Dialog,
  DialogContent,
  makeStyles,
  Grid
} from '@material-ui/core';
import React, { FC } from 'react';
import OrderCreateDocument from 'views/index/components/modal-create-orders/components/order-create-document';
import FormCharges from './components/form-charges';
import { BaseButton } from 'components/BaseButton';
import SkeletonForm from 'views/index/components/order-stepper-creation/components/skeleton-form';
import { useTranslation } from 'react-i18next';
import ErrorRefetch from './components/error-refetch';

const useStyles = makeStyles((theme:any) => ({
  spacingSection: { marginBottom: theme.spacing(2) },
  pdfContainer: {
    overflowY: 'scroll',
    padding: theme.spacing(2),
    maxHeight: '100vh',
    scrollbarColor: `${theme.palette.tab.offselected} ${theme.palette.button.default}`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      background: theme.palette.button.default,
      width: theme.spacing(0.625),
      borderRadius: theme.spacing(0.625)
    },
    '&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.625) },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.tab.offselected,
      borderRadius: theme.spacing(0.625),
    },
  },
  titleError: {
    fontWeight: 500,
    color: theme.palette.common.black,
    marginTop: theme.spacing(3)
  }
}));

type ModalchargesSceneProps ={
	open:boolean,
	onClose:()=>void,
    handleUploadPDF: (param: any) => void
	pdfInformation:{
		images?:string[]
	},
	arrayCharges:[],
	state:any;
	handleRefetch:()=>void
}

const ModalchargesScene:FC<ModalchargesSceneProps> = ({
  open,
  onClose,
  handleUploadPDF,
  pdfInformation,
  arrayCharges,
  state,
  handleRefetch
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      fullScreen={!!pdfInformation?.images?.length}
      open={open}
      onClose={onClose}
    >
      <DialogContent style={{ padding: 0 }}>
        {pdfInformation?.images?.length ?
          <Grid container direction="row">
            <Grid item xs={5} className={classes.pdfContainer} style={{ backgroundColor: '#263238' }}>
              {pdfInformation?.images?.map((image, index) => (
                <Box className={classes.spacingSection}>
                  <img
                    src={image}
                    alt={`${index}${image.slice(0, 5)}`}
                    style={{ maxWidth: '100%' }}
                    key={`${index}-${image.slice(0, 10)}`}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item xs={7} className={classes.pdfContainer}>
              {state.mode === 'error' &&
              <ErrorRefetch
                onClose={onClose}
                handleRefetch={handleRefetch}
              />}
              { state.mode === 'fetched' &&
              <FormCharges
                charges={arrayCharges}
                onClose={onClose}
              />}

              { state.mode === 'loading-data' &&
              <Grid item>
                <Grid container direction="column" justifyContent="flex-end" spacing={3}>
                  <Grid item>
                    <SkeletonForm />
                  </Grid>
                  <Grid item justifyContent="flex-end" style={{ display: 'flex' }}>
                    <BaseButton
                      buttonColor="primary"
                      buttonVariant="outlined"
                      text="Cancel"
                      onClick={onClose}
                    />
                  </Grid>
                </Grid>
              </Grid>}
            </Grid>
          </Grid>
          :
          <Grid container direction="column" style={{ padding: 24 }}>
            <Grid item xs={12}>
              <OrderCreateDocument
                onUploadDocument={(param) => {
		  		handleUploadPDF(param);
		 		}}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item>
                  <BaseButton text="Cancel" onClick={onClose} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>}
      </DialogContent>
    </Dialog>
  );
};

export default ModalchargesScene;