import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { CURRENT_PATH } from 'utils/constants';

const useStyles = makeStyles((theme: any) => ({
  link: {
    cursor: 'pointer',
    fontFamily: 'Roboto',
    color: theme.palette.common.white,
    opacity: 0.45,
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:hover': {
      color: theme.palette.common.white,
      opacity: 0.8,
    },
  },
  active: {
    color: theme.palette.common.white,
    opacity: 1,
  },
}));

type LinkProps = {
  view: string;
  param?: string;
  onClick: () => void;
  children: React.ReactNode;
};

const Link = ({ view, param, onClick, children }: LinkProps) => {
  const classes = useStyles();

  const isActiveLink = (): boolean => {
    const data = JSON.parse(localStorage.getItem(CURRENT_PATH) || '{}');

    if (param) {
      return data?.view === view && data?.param === param;
    }

    return data?.view === view;
  };

  return (
    <Box
      className={clsx(classes.link, { [classes.active]: isActiveLink() })}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

Link.defaultProps = { param: undefined };

export default Link;
