import { LEDGER_CODE_PRORATION_AMOUNT, LEDGER_CODE_PRORATION_PERDIEM, LEDGER_CODE_PRORATION_PRORATED } from "utils/constants";
import { Proration } from "v2-types/order";
import { PerDiemDecimals, ProrationForm } from "../types";

export const perDiemDecimals: PerDiemDecimals[] = [
  { value: 2, name: '2' },
  { value: 3, name: '3' },
  { value: 4, name: '4' },
  { value: 5, name: '5' },
  { value: 6, name: '6' },
  { value: 7, name: '7' },
  { value: 8, name: '8' },
  { value: 9, name: '9' },
];

export const defaultTabs = ["Assessments", "City/Town Taxes", "County Taxes"];

export const getPropertyProrationForms = (propertyId: string, prorations: Proration[]) => {
  if (!prorations) return [];

  const propertyProrations: ProrationForm[] = prorations
    .filter((proration) => proration.estate._id === propertyId)
    .map((proration) => {
      const anualAmount = proration.accounting.find((entry) => entry.code.includes(LEDGER_CODE_PRORATION_AMOUNT));
      const perDiemAmount = proration.accounting.find((entry) => entry.code.includes(LEDGER_CODE_PRORATION_PERDIEM));
      const proratedAmount = proration.accounting.find((entry) => entry.code.includes(LEDGER_CODE_PRORATION_PRORATED));
      return ({
        ...proration,
        anualAmount,
        perDiemAmount,
        proratedAmount
      });
    })
    ?? [];

  return propertyProrations;
};