import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  conainter: { marginBottom: theme.spacing(1), },
  buttonConfirm: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    fontWeight: 600,
    '&:hover': { backgroundColor: theme.palette.success.light, },
  },
  buttonDeny: {
    backgroundColor: theme.palette.error.main,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    fontWeight: 600,
    '&:hover': { backgroundColor: theme.palette.error.light, },
  },
  description: {
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(3),
  },
  title: { color: theme.palette.primary.dark },
}));

type ConfirmDeletesProps = {
  openModal: boolean;
  handleDeleteTemplate: () => void;
  closeModal: () => void;
};

export const ConfirmDelete: FC<ConfirmDeletesProps> = ({
  openModal,
  handleDeleteTemplate,
  closeModal,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog open={openModal}>
      <DialogTitle style={{ textAlign: 'center' }} className={classes.title}>
        {t('documents:title-delete-template')}
      </DialogTitle>
      <DialogContent className={classes.conainter}>
        <Typography className={classes.description}>
          {t('documents:description-delete-template')}
        </Typography>
        <Box display="flex" justifyContent="space-evenly">
          <Button
            className={classes.buttonConfirm}
            onClick={() => {
              closeModal();
              handleDeleteTemplate();
            }}
          >
            {t('documents:confirm-delete')}
          </Button>
          <Button className={classes.buttonDeny} onClick={() => closeModal()}>
            {t('documents:deny-delete')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
