/* eslint-disable react/no-array-index-key */
import {
  CircularProgress,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { Column } from 'types/common';
import useStyles from '../style';

  type BodyType = {
	columns: Column[];
	data: any;
	disabledBorderCell?: boolean;
	disableFullborderCell?: boolean;
	onClickItem?: (row: any, column: Column, position?: number) => void;
	indexRow:number
  };

const RowTable = ({
  columns,
  data,
  indexRow,
  onClickItem,
  disabledBorderCell,
  disableFullborderCell,
}: BodyType) => {
  const {
	  paddingCell,
	  borderBottomCell,
	  borderTopCell,
	  borderCellStyle,
  } = useStyles();

  return (
    <>
      {columns?.map((column: Column, index: number) => column?.renderer && (
      <TableCell
        key={`cell-item-${index}`}
        align="left"
        style={{
          height: 0,
          minWidth: column.header?.minWidth,
          width: column.header?.width,
	  }}
        className={`
		${paddingCell}
		${!disabledBorderCell && borderBottomCell}
		${!disabledBorderCell && borderTopCell}
		${!disableFullborderCell && borderCellStyle}
	  `}
        onClick={(e) => {
          e.stopPropagation();
          if (onClickItem) {
            onClickItem(data, column, indexRow);
          }
	  }}
      >
        {column?.renderer({ row: data, column, index, indexRow })}
      </TableCell>
				  ))}
    </>
  );
};

RowTable.defaultProps = {
  onClickItem: undefined,
  disabledBorderCell: false,
  disableFullborderCell: false,
};

export default RowTable;
