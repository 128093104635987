import { makeStyles, Typography } from '@material-ui/core';
import Switch from 'components/switch';
import { TFunction } from 'i18next';
import React from 'react';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
});

type InterestOnlyFieldsProps = {
  interest_only: boolean;
  onChangeInput: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  t: TFunction;
};

const InterestOnlyFields = ({ interest_only, onChangeInput, t }: InterestOnlyFieldsProps) => {
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <Switch label="" checked={interest_only} handleOnChange={(e) => onChangeInput('payments.interest_only', e.target.checked)} />
      <Typography>{t(`projectedPayments:${interest_only ? 'interestOnly' : 'no'}`)}</Typography>
    </span>
  );
};

export default InterestOnlyFields;
