import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Color } from '@material-ui/lab/Alert';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type AlertAdviceProps = {
  show: boolean,
  icon?: IconProp,
  message?: string
  severity?: Color,
  onClose?: () => void
}

export const AlertAdvice: FC<AlertAdviceProps> = ({
  show,
  message,
  onClose,
  icon = 'exclamation-circle',
  severity = 'error'
}) => {
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <Alert icon={<FontAwesomeIcon icon={icon} />} severity={severity} onClose={onClose}>
      {capitalize(message ?? t('common:unexpected-error'))}
    </Alert>
  );
};
