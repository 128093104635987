import axiosInstance from '../../config/axios-instance';

/**
 * @deprecated this function will be replaced wen all the pending screens are migrated.
 */
export const getOrderById = async (key: any, orderId: string) => {
  try {
    const { data } = await axiosInstance.get(`/v1/orders/${orderId}/`);
    return data;
  } catch (error) {
    return undefined;
  }
};

/**
 * @deprecated this function will be replaced wen all the pending screens are migrated.
 */
export const updateOrder = async (payload: any) => {
  try {
    const { data } = await axiosInstance.patch(`/v1/orders/${payload.orderId}/`, payload.payload);
    return data;
  } catch (error) {
    return undefined;
  }
};

export default {
  getOrderById,
  updateOrder,
};
