import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import InputCell from './input-cell';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    height: 'auto',
    '&>*': {
      marginRight: theme.spacing(4),
      height: 'auto !important',
      flex: 1,
    },
  },
  textField: {
    maxWidth: '50%',
    height: theme.spacing(4),
    '&>.MuiInputBase-root': {
      height: theme.spacing(4),
      '&>.MuiSelect-root': {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
  },
  rangeAmounts: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  }
}));

type PrincipalInterestAmountFieldsProps = {
  emptyValue: Boolean;
  data: Data[];
  dataKey: DataElement;
  dataValue: DataElement;
  dataText: DataElement;
  principal_interest_amount: Number
  onChangeField: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  principal_interest_type: 'amount' | 'range';
  principal_interest_min: Number;
  principal_interest_max: Number;
  errors: any;
};

type DataElement = 'name' | 'text';

type Data = {
  name: String;
  text: String;
}

const PrincipalInterestAmountFields = ({
  emptyValue,
  data,
  dataKey,
  dataValue,
  dataText,
  principal_interest_amount,
  onChangeField,
  principal_interest_type,
  principal_interest_min,
  principal_interest_max,
  errors,
}: PrincipalInterestAmountFieldsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <TextField
        select
        fullWidth
        variant="filled"
        value={principal_interest_type}
        className={classes.textField}
        InputProps={{ disableUnderline: true }}
        onChange={(e) => onChangeField('payments.principal_interest_type', e.target.value)}
      >
        {emptyValue && (
          <MenuItem value="">
            {t('common:select-one')}
          </MenuItem>
        )}
        {data.map((element: any) => (
          <MenuItem
            key={element[dataKey]}
            value={element[dataValue]}
          >
            {element[dataText]}
          </MenuItem>
        ))}
      </TextField>
      {
        principal_interest_type === 'amount'
          ? (
            <InputCell
              helperText={errors.payments?.principal_interest_amount}
              placeholder={t('projectedPayments:amount')}
              value={principal_interest_amount}
              onChangeInput={(e) => onChangeField('payments.principal_interest_amount', e.target.value)}
            />
          )
          : principal_interest_type === 'range' && (
          <span className={classes.rangeAmounts}>
            <InputCell
              helperText={errors.payments?.principal_interest_min}
              placeholder={t('projectedPayments:min')}
              value={principal_interest_min}
              onChangeInput={(e) => onChangeField('payments.principal_interest_min', e.target.value)}
            />
            <InputCell
              helperText={errors.payments?.principal_interest_max}
              placeholder={t('projectedPayments:max')}
              value={principal_interest_max}
              onChangeInput={(e) => onChangeField('payments.principal_interest_max', e.target.value)}
            />
          </span>
          )
      }

    </span>
  );
};

export default PrincipalInterestAmountFields;
