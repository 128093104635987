import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';
import {
  LISTING_CODE_ALLOWED_INTEREST_BLOCKS,
  LISTING_CODE_ALLOWED_ORDER_ASSOCIATIONS,
  LISTING_CODE_ALLOWED_TRANSACTIONS,
  LISTING_CODE_ALLOWED_TYPES,
  LISTING_CODE_ALLOWED_WORKFLOWS
} from 'utils/constants';

export type ListingEntry = {
  code: string,
  description: string
}

export type GQLGetListingResponse = {
  getListing: {
    code: string,
    entries: ListingEntry[]
  }
}

export type ListingCode = typeof LISTING_CODE_ALLOWED_ORDER_ASSOCIATIONS
| typeof LISTING_CODE_ALLOWED_INTEREST_BLOCKS
| typeof LISTING_CODE_ALLOWED_TRANSACTIONS
| typeof LISTING_CODE_ALLOWED_WORKFLOWS
| typeof LISTING_CODE_ALLOWED_TYPES;

type GetListingParams = {
  code: ListingCode
}

export const getListing = async (payload: GetListingParams) => {
  const query = gql`
    query GetListing($code: String!) {
      getListing(code: $code) {
        code
        entries {
          code
          description
        }
      }
    }
  `;

  const response = await graphQLClient().request<GQLGetListingResponse, GetListingParams>(query, payload);
  return response;
};
