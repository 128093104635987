import formatISO from 'date-fns/formatISO';
import { useHelpOrderLedgerEntryLinked } from 'hooks/useHelpOrderLedgerEntryLinked';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAlert from 'utils/alert';
import { capitalize } from 'utils/helpers';
import { useYupValidationResolver } from 'utils/ledger/use-yup-resolver';
import { AccountingFilter, AccountingType, OrderLedgerKinds } from 'v2-types/order';

import { validationSchema } from './utils/form-schema';
import { EntryLedgerType } from './utils/types';

type Params = {
  accountingId: string,
  chargeDescription?: string,
  kind: string,
  partyId: string,
  onAddEntrySuccess: (accounting: AccountingType[]) => void,
  onCloseModal: () => void,
  accountingFilter: AccountingFilter
}

export const useAddEntryLinkedModal = ({
  accountingId,
  chargeDescription,
  kind,
  partyId,
  onAddEntrySuccess,
  onCloseModal,
  accountingFilter
}: Params) => {
  const { id: orderId } = useParams<{ id: string }>();
  const showAlert = useAlert();
  const { t } = useTranslation();

  const [errors, setErrors] = useState<string[]>([]);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    control,
    handleSubmit,
    reset,
    formState
  } = useForm<EntryLedgerType>({
    defaultValues: { amount: 0, entryDate: null, code: '', description: chargeDescription },
    resolver
  });

  const [addEntryLinked, { isLoading }] = useHelpOrderLedgerEntryLinked({
    queryConfig: {
      onSuccess: (data) => {
        if (data) {
          showAlert(capitalize(t('dialogs:order-updated')), 'success');
          onAddEntrySuccess(data.helpOrderLedgerEntryLinked.accounting || []);
          reset();
          onCloseModal();
        }
      },
      onError: (e) => {
        if (e.response.errors.length > 0) {
          setErrors(e.response.errors.map((error) => error.message));
        } else {
          setErrors(['Error Adding Entry']);
        }
      }
    }
  });

  const onAddEntry = handleSubmit((data) => {
    addEntryLinked({
      amount: data.amount,
      description: data.description,
      orderId,
      code: data.code,
      entryDate: data.entryDate ? formatISO(new Date(data.entryDate)) : '',
      kind: OrderLedgerKinds.MANUAL,
      data: {
        accounting_id: accountingId,
        party_id: partyId,
        kind
      },
      filters: { accounting: accountingFilter }
    });
  });

  return {
    control,
    formState,
    onAddEntry,
    isAddEntryLoading: isLoading,
    t,
    errors
  };
};
