import { Grid } from '@material-ui/core';
import { ContainedButton } from 'components/ContainedButton';
import { DropDown } from 'components/dropdown';
import NumberField from 'components/number-field';
import { Text } from 'components/text';
import { TextButton } from 'components/TextButton';
import { TFunction } from 'i18next';
import React, { FC, useState } from 'react';

import { SearchParam, SortBy, SortKindOption, SortOperator } from '../../types';
import { useStyles } from './styles';
import { sortByKindList, wireTransferStatusList } from './utils/index';

type FiltersProps = {
  t: TFunction,
  onFilterChange: ({ value, paramKey }: { value: string, paramKey: keyof Omit<SearchParam, 'sortBy'> }) => void,
  onSort: ({ property, operator }: SortBy) => void,
  onUpdateAmountLimits: ({ min, max }: { min?: number, max?: number }) => void,
  clearFilters: () => void
}

export const Filters: FC<FiltersProps> = ({
  t, onFilterChange, onSort, onUpdateAmountLimits, clearFilters
}) => {
  const [status, setStatus] = useState('');
  const [sortKind, setSortKind] = useState('');
  const [amountLimits, setAmountLimits] = useState<{ min?: number, max?: number}>({});

  const sortKindList = sortByKindList(t);

  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item>
        <DropDown
          options={wireTransferStatusList}
          optionValue="status"
          optionText="status"
          onChange={(value) => {
            setStatus(value);
            onFilterChange({ value, paramKey: 'status' });
          }}
          defaultText={t('common:status')}
          value={status}
        />
      </Grid>
      <Grid item>
        <DropDown
          options={sortKindList}
          optionValue="label"
          optionText="label"
          onChange={(value) => {
            const sortKindValue = sortKindList.find((kind) => kind.label === value);
            if (sortKindValue) {
              onSort({ property: sortKindValue.property as SortKindOption, operator: sortKindValue.operator as SortOperator });
            }
            setSortKind(value);
          }}
          defaultText={t('common:sortBy')}
          value={sortKind}
        />
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Text value={t('common:amount')} color="primary" />
          </Grid>
          <Grid item>
            <NumberField
              value={amountLimits.min ?? null}
              className={classes.numberInput}
              onChange={(e) => {
                const amount = e.target.value.replace('$', '').replaceAll(',', '');
                const min = Number(amount) !== 0 ? Number(amount) : undefined;
                setAmountLimits((prevAmounts) => ({
                  ...prevAmounts,
                  min
                }));
              }}
              placeholder={t('common:min')}
              decimalScale={2}
              variant="filled"
              thousandSeparator
              prefix="$"
            />
          </Grid>
          <Grid item>
            <NumberField
              value={amountLimits.max ?? null}
              className={classes.numberInput}
              onChange={(e) => {
                const amount = e.target.value.replace('$', '').replaceAll(',', '');
                const max = Number(amount) !== 0 ? Number(amount) : undefined;
                setAmountLimits((prevAmounts) => ({
                  ...prevAmounts,
                  max
                }));
              }}
              placeholder={t('common:max')}
              decimalScale={2}
              variant="filled"
              thousandSeparator
              prefix="$"
            />
          </Grid>
          <Grid item>
            <ContainedButton
              icon="paper-plane"
              disabled={!amountLimits.min && !amountLimits.max}
              onClick={() => onUpdateAmountLimits({ min: amountLimits.min, max: amountLimits.max })}
            />
          </Grid>
          <Grid item>
            <TextButton
              text={t('common:clear-all')}
              onClick={() => {
                setStatus('');
                setSortKind('');
                setAmountLimits({ min: undefined, max: undefined });
                clearFilters();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
