import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { FC } from 'react';
import { AccountingFilter, AccountingType } from 'v2-types/order';

import { useModalSelectPayeePayer } from './hooks';
import ListParty from './list-party';

const useStyles = makeStyles((theme: any) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, },
  dropContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  dropItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

type modalSelectPayerProps = {
	accountingID:string,
	open:boolean,
	handleClose:() =>void,
	handleUpdateAccounting:(accunting: AccountingType[]) => void,
  accountingFilter?: AccountingFilter
}

const ModalSelectPayeePayer:FC<modalSelectPayerProps> = ({
  accountingID,
  open,
  handleClose,
  handleUpdateAccounting,
  accountingFilter
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('buyer');
  const {
    partyList,
    removeParty,
    changePorcentageParty,
    onDrop,
    startDrag,
    dragInOver,
    expectedValue,
    onChangeRadio,
    handleSubmit,
    isLoading,
  } = useModalSelectPayeePayer(handleClose, handleUpdateAccounting, accountingFilter);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <DialogTitle>Select Payee(s) and Payer(s)</DialogTitle>
      <DialogContent>
        <TabContext value={value}>
          <Paper square>
            <Tabs
              value={value}
              indicatorColor="secondary"
              onChange={handleChange}
            >
              <Tab value="buyer" label="Buyer" />
              <Tab value="seller" label="Seller" />
              <Tab value="other" label="Other" />
            </Tabs>
          </Paper>
          <TabPanel value="buyer">
            <ListParty list={partyList} kind="buyer" startDrag={startDrag} />
          </TabPanel>
          <TabPanel value="seller">
            <ListParty list={partyList} kind="seller" startDrag={startDrag} />
          </TabPanel>
          <TabPanel value="other">
            <ListParty list={partyList} kind="other" startDrag={startDrag} />
          </TabPanel>
        </TabContext>
        <Box className={classes.dropContainer}>
          <Box className={classes.dropItem}>
            <Typography>Payee</Typography>
            <ListParty
              list={partyList}
              startDrag={startDrag}
              dragInOver={dragInOver}
              onDrop={onDrop}
              kind="payee"
              removeParty={removeParty}
              changePorcentageParty={changePorcentageParty}
            />
          </Box>
          <Box className={classes.dropItem}>
            <Typography>Payer</Typography>
            <ListParty
              list={partyList}
              startDrag={startDrag}
              dragInOver={dragInOver}
              onDrop={onDrop}
              kind="payer"
              removeParty={removeParty}
              changePorcentageParty={changePorcentageParty}
            />
          </Box>
        </Box>
        <FormControl component="fieldset">
          <FormLabel component="legend">Expected Payment</FormLabel>
          <RadioGroup
            name="exptected"
            value={expectedValue}
            onChange={onChangeRadio}
            className={classes.radioContainer}
          >
            <FormControlLabel
              value="before"
              control={<Radio />}
              label="Before Closing"
            />
            <FormControlLabel
              value="at"
              control={<Radio />}
              label="At Closing"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="primary">
          Close
        </Button>
        <Button
          variant="outlined"
          autoFocus
          onClick={() => handleSubmit(accountingID)}
          color="secondary"
        >
          Save
        </Button>
      </DialogActions>

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </Dialog>
  );
};
export default ModalSelectPayeePayer;
