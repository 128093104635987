import NoActiveLink from 'components/non-active-link/not-active-link';
import { TFunction } from 'i18next';
import React from 'react';
import { QueryResult } from 'react-query';

import BackdropLoading from '../../components/common/BackdropLoading';
import ThanksforUnsubscribe from './components/unsubscribe-component';

type unsubscribeScence = {
  t: TFunction,
  tokenResponse: QueryResult<any, unknown>,
  handleUnsuscribe: (email:string) => void,
  showFinalScreen: boolean
}
const UnsuscribeScene = ({
  t,
  tokenResponse,
  handleUnsuscribe,
  showFinalScreen
}:unsubscribeScence) => {
  const { isLoading, data } = tokenResponse || {};
  const { valid_token, request } = data || {};

  if (isLoading) {
    return <BackdropLoading open loadingText={t('common:loading')} />;
  }
  if (!valid_token) {
    return (<NoActiveLink t={t} />);
  }
  return (
    <ThanksforUnsubscribe
      t={t}
      showFinalScreen={showFinalScreen}
      handleUnsuscribe={() => request?.email && handleUnsuscribe(request?.email)}
    />

  );
};

export default UnsuscribeScene;
