import {
  Box, Button, Grid, makeStyles, Typography
} from '@material-ui/core';
import TableComponent from 'components/Table';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { parseSignersColumns, signersColumns } from './services/index';
import { SignatureType } from './services/types';

const useStyles = makeStyles((theme:any) => ({
  containerTable: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    background: theme.palette.background.paper,
	  },
	  scrollDiv: {
    overflowX: 'auto',
    width: '100%',
    '&&::-webkit-scrollbar': { height: theme.spacing(1) },
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`,
    scrollbarWidth: 'thin',
    '&&::-webkit-scrollbar-track': { borderRadius: theme.spacing(0.5) },
    '&&::-webkit-scrollbar-thumb': {
		  background: theme.palette.secondary.main,
		  borderRadius: 4,
    },
    '&&::-webkit-scrollbar-thumb:hover': { background: theme.palette.secondary.dark },
	  },
}));

type signdocumentsType = {
	signatures: SignatureType[],
	handleOpenModal:(param:boolean)=>void
}

const SignDocumentsScene: FC<signdocumentsType> = ({ signatures, handleOpenModal }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const generateColumnsSigners = useMemo(() => signersColumns(t), [t]);
  return (
    <Grid container style={{ marginTop: 24, flex: 1 }}>
      <Grid item xs={12} alignItems="flex-end">
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            style={{ textTransform: 'none' }}
            onClick={() => { handleOpenModal(true); }}
          >
            <Typography variant="body2" style={{ fontWeight: 500, color: 'black' }}>{t('documents:new-sign')}</Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={`${classes.scrollDiv} ${classes.containerTable}`}>
          <TableComponent
            disabledBorderLeft
            columns={generateColumnsSigners}
            dataSet={parseSignersColumns(signatures)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignDocumentsScene;
