import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
// import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: any) => ({
  headerContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.accent.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  headerTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  text: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  icon: {
    fontSize: '16px',
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
}));

type HeaderProps = {
  title:string,
  handleAddEntry:()=>void
};

const Header = ({ title, handleAddEntry }: HeaderProps) => {
  const classes = useStyles();
  // const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.headerContainer}
    >
      <Grid item>
        <Typography variant="h5" className={classes.headerTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          disableElevation
          onClick={handleAddEntry}
        >
          <FontAwesomeIcon icon="plus" className={classes.icon} />
          <Typography variant="body2" className={classes.text}>
            Add Receipt/Disbursement
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;
