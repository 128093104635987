import graphQLClient from 'config/graphql-instance';
import { gql } from 'graphql-request';

export type GQLUpdateUserNotes = {
  updateUserNotes: {
    notes: string
  }
}

type UpdateUserNotesPayload = {
  notes: string
}

export const updateUserNotes = async (payload: UpdateUserNotesPayload) => {
  const mutation = gql`
    mutation UpdateUserNotes($notes: String!) {
      updateUserNotes(notes: $notes) {
        notes
      }
    }
  `;

  const response = await graphQLClient()
    .request<GQLUpdateUserNotes, UpdateUserNotesPayload>(mutation, payload);
  return response;
};
