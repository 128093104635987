import { Box, Divider } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { LoanFormType } from 'views/orders/loans/types';

import { ConditionForm } from '../condition-form';
import { ConditionsForm } from './types';
import { formatConditionsForm, getValues } from './utils';

type Props = {
  loanForm: UseFormReturn<LoanFormType, object>,
}

export const IncreaseMonthlyPrincipalConditions: FC<Props> = ({ loanForm }) => {
  const { control } = loanForm;
  const { increase_monthly_principal_text } = useWatch({ control });

  const conditionForm = useForm<ConditionsForm>({ mode: 'onChange' });

  const { control: conditionFormControl } = conditionForm;
  const {
    firstConditionExist,
    secondConditionExist,
    thirdConditonExist,
    firstFieldValues,
    secondFieldValues,
    thirdFieldValues
  } = useWatch({ control: conditionFormControl });

  useEffect(() => {
    conditionForm.reset(getValues(increase_monthly_principal_text));
  }, [conditionForm, increase_monthly_principal_text]);

  const saveForm = () => {
    loanForm.setValue('increase_monthly_principal_text', formatConditionsForm(conditionForm.getValues()), { shouldDirty: true });
  };

  return (
    <Box>
      {firstConditionExist && (
        <>
          <ConditionForm
            conditionsFields={[
              {
                prefix: 'Adjust every ',
                value: firstFieldValues?.years,
                sufix: 'years',
                onChange: (value) => {
                  conditionForm.setValue('firstFieldValues.years', value);
                  saveForm();
                }
              },
              {
                prefix: 'starting in year ',
                value: firstFieldValues?.year,
                format: '####',
                onChange: (value) => {
                  conditionForm.setValue('firstFieldValues.year', value);
                  saveForm();
                }
              }
            ]}
            onRemove={() => {
              conditionForm.setValue('firstConditionExist', false);
              conditionForm.setValue('firstFieldValues', {
                year: undefined,
                years: undefined
              });
              saveForm();
            }}
          />
        </>
      )}
      {secondConditionExist && (
      <>
        <Divider />
        <ConditionForm
          conditionsFields={[
            {
              prefix: 'Can go as high as ',
              value: secondFieldValues?.percent,
              sufix: '% ',
              onChange: (value) => {
                conditionForm.setValue('secondFieldValues.percent', value);
                saveForm();
              }
            },
            {
              prefix: ' in year ',
              value: secondFieldValues?.year,
              format: '####',
              onChange: (value) => {
                conditionForm.setValue('secondFieldValues.year', value);
                saveForm();
              }
            }
          ]}
          onRemove={() => {
            conditionForm.setValue('secondConditionExist', false);
            conditionForm.setValue('secondFieldValues', {
              percent: undefined,
              year: undefined
            });
            saveForm();
          }}
        />
      </>
      )}
      {thirdConditonExist && (
      <>
        <Divider />
        <ConditionForm
          conditionsFields={[
            {
              prefix: 'Includes only interest and no principal until year ',
              value: thirdFieldValues?.year,
              onChange: (value) => {
                conditionForm.setValue('thirdFieldValues.year', value);
                saveForm();
              }
            },
          ]}
          onRemove={() => {
            conditionForm.setValue('thirdConditonExist', false);
            conditionForm.setValue('thirdFieldValues', { year: undefined });
            saveForm();
          }}
        />
      </>
      )}
    </Box>
  );
};
