import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import championship2Logo from 'assets/images/championship-logo@2x.png';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import React from 'react';

const useStyles = makeStyles((theme: any) => createStyles({
  color: { color: theme.palette.common.white },
  colorTitle: { color: theme.accent.main },
  smallLogo: { width: theme.spacing(20) },
  button: {
    textTransform: 'none',
    color: theme.palette.common.black,
    marginLeft: theme.spacing(2),
  },
}));

type OnboardingWelcomeSceneProps = {
  t: TFunction;
  theme: any;
  handle: () => void;
};

const OnboardingWelcomeScene = ({
  t,
  theme,
  handle,
}: OnboardingWelcomeSceneProps) => {
  const classes = useStyles();
  const SM = useMediaQuery(`(max-width:${theme.spacing(75)}px)`);
  const MD = useMediaQuery(`(max-width:${theme.spacing(120)}px)`);

  return (
    <Grid container style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
      <Grid container item xs={12}>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: theme.spacing(9), marginBottom: theme.spacing(10) }}
        >
          <img src={championship2Logo} alt="logo" className={clsx({ [classes.smallLogo]: MD, })} />
        </Grid>

        <Grid
          container
          justifyContent="center"
          className={classes.color}
          style={{ textAlign: 'center' }}
        >
          <Typography
            data-testid="tite-home-screen"
            variant={SM ? 'h5' : 'h4'}
            className={classes.colorTitle}
          >
            {t('onboarding:welcome')}
          </Typography>
        </Grid>

        <Grid
          container
          justifyContent="center"
          className={classes.color}
          style={{ marginTop: theme.spacing(2) }}
        >
          <div style={{ textAlign: 'center' }}>
            <Typography
              data-testid="subtitle-excited-welcome-screen"
              variant={SM ? 'subtitle2' : 'subtitle1'}
            >
              {t('onboarding:excited')}
            </Typography>

            <Typography
              data-testid="subtitle-thanks-welcome-screen"
              variant={SM ? 'subtitle2' : 'subtitle1'}
            >
              {t('onboarding:thanks')}
            </Typography>
          </div>
        </Grid>

        <Grid container spacing={2} justifyContent="center" style={{ marginTop: theme.spacing(4) }}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handle}
          >
            {t('onboarding:go-to-profile')}
            <FontAwesomeIcon
              size="1x"
              icon="arrow-right"
              style={{ marginLeft: theme.spacing(2) }}
            />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OnboardingWelcomeScene;
