import { getParty } from 'graphql/parties/queries';
import useSearchParty from 'hooks/useSearchParty';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import graphql from 'services/querys';
import { NewUserType } from 'types/new-user';
import { NewOrderPartyType } from 'types/order';
import useAlert from 'utils/alert';

import PartiesContext from '../../../services/context';

type Params = {
  associationKind: string,
  partyId: string,
  roleParty: string,
  setLoaderActive: React.Dispatch<React.SetStateAction<boolean>>,
  loaderActive: boolean,
  removeSuccessMessage: string,
  createSuccessMessage: string
}
export const useTabCreateAssocation = ({
  associationKind,
  partyId,
  roleParty,
  setLoaderActive,
  loaderActive,
  removeSuccessMessage,
  createSuccessMessage
}: Params) => {
  const context = useContext(PartiesContext);
  const { filterParties } = context || {};
  const [selectedPartyToLinkId, setSelectedPartyToLinkId] = useState<string | null>(null);
  const [selectedPartyToLinkData, setSelectedPartyToLinkData] = useState<NewUserType | null>(
    null
  );
  const [selectedAssociationParty, setSelectedAssociationParty] = useState<NewOrderPartyType | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { parties } = useSearchParty(searchText);
  const { id: orderId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const showAlert = useAlert();

  const [
    removePartyAssociation,
    { isLoading: isLoadingRemove }
  ] = useMutation(
    graphql.deleteAssociation,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          setLoaderActive(false);
          showAlert(removeSuccessMessage, 'success');
          setSelectedPartyToLinkId(null);
          setSelectedPartyToLinkData(null);
          if (data?.deleteOrderAssociation.parties && filterParties) {
            filterParties(data?.deleteOrderAssociation?.parties!);
          }
        }, 1500);
      },
      onError: () => {
        setLoaderActive(false);
        showAlert(t('parties:mutation-error'), 'error');
      },
    }
  );

  const [createPartyAssociation] = useMutation(
    graphql.createAssociation,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          setLoaderActive(false);
          showAlert(createSuccessMessage, 'success');
          setSelectedPartyToLinkId(null);
          if (data?.createOrderAssociation.parties && filterParties) {
            filterParties(data?.createOrderAssociation?.parties!);
          }
        }, 1500);
      },
      onError: () => {
        setLoaderActive(false);
        showAlert(t('parties:mutation-error'), 'error');
      },
    }
  );

  useEffect(() => {
    if (orderId && partyId && selectedPartyToLinkId) {
      setLoaderActive(true);
      createPartyAssociation({
        id: orderId,
        partyId: selectedPartyToLinkId,
        kind: associationKind,
        linkedToId: partyId,
        linkedToKind: roleParty,
      });
    }
  }, [createPartyAssociation, orderId, partyId, selectedPartyToLinkId, roleParty]);

  const getPartyResponse = useQuery(
    ['getParty', selectedAssociationParty?._id],
    () => getParty({ id: selectedAssociationParty?._id! }),
    {
      enabled:
        selectedAssociationParty?._id !== undefined
        && !loaderActive,
      refetchOnWindowFocus: false
    }
  );

  const selectedPartyAssociatonData = getPartyResponse.data?.getParty || undefined;

  return {
    t,
    parties,
    setSearchText,
    selectedPartyToLinkId,
    setSelectedPartyToLinkId,
    orderId,
    removePartyAssociation,
    selectedPartyToLinkData,
    setSelectedAssociationParty,
    selectedAssociationParty,
    selectedPartyAssociatonData,
    isLoading: getPartyResponse.isLoading,
    isLoadingRemove
  };
};
