/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';

import useDatesAndConfig from './dates-and-config-hooks';
import DatesAndConfigScene from './dates-and-config-scene';
import { DatesAndConfigurationForm } from './services';

type DatesAndConfigProps = {
  orderHasAccountingData: boolean
}

const DatesAndConfig: FC<DatesAndConfigProps> = () => {
  const {
    updateOrder,
    transactionTypes,
    workflows,
    totalLoanAmount,
    totalPurchasePrice,
    methods,
    parties,
    orderId,
    info
  } = useDatesAndConfig();

  const handleSave = (data: DatesAndConfigurationForm) => {
    const { order_id, closing_place, ...rest } = data;
    updateOrder({
      id: orderId,
      data: {
        ...rest,
        closing_place: closing_place === '' ? null : closing_place
      }
	 });
  };

  return (
    <DatesAndConfigScene
      transactionTypes={transactionTypes}
      workflows={workflows}
      handleSave={handleSave}
      totalLoanAmount={totalLoanAmount}
      totalPurchasePrice={totalPurchasePrice}
      methods={methods}
      parties={parties}
      saveStatus={info}
    />
  );
};

export default DatesAndConfig;
