import axios from 'axios';
import { TOKEN_CLAIMS } from 'utils/constants';

export const downloadFile = async (link, fileName) => {
  const access_token = localStorage.getItem(TOKEN_CLAIMS);
  let success = true;

  try {
    const { data } = await axios({
      method: 'GET',
      responseType: 'blob',
      url: link,
      headers: { Authorization: `Bearer ${access_token}`, },
    });

    const href = URL.createObjectURL(data);
    const linkElement = document.createElement('a');

    linkElement.href = href;
    linkElement.setAttribute('download', fileName);

    document.body.appendChild(linkElement);
    linkElement.click();

    document.body.removeChild(linkElement);
    URL.revokeObjectURL(href);
  } catch (error) {
    success = false;
  }

  return success;
};
