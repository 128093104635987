import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MilestonesStatus } from 'v2-types/order';

import { getMilestones, Milestone } from './services';

export type MilestoneForm = Omit<MilestonesStatus, 'ready_to_close'>

export const useProgressOrder = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const milestoneList = getMilestones({ t });
  const [selectedMilestone, setSelectedMilestone] = useState<Milestone>(milestoneList.find((milestone) => milestone.key === 'general_info')!);

  return {
    t,
    milestoneList,
    selectedMilestone,
    setSelectedMilestone,
    history
  };
};
