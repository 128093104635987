import { useRef, useState } from 'react';
import useAlert from 'utils/alert';

type UseMicrophoneParams = {
  onRecordStart: () => void,
  onRecordStop: (audioBlob: Blob) => void,
  onRecordError?: () => void,
  maxSeconds?: number,
}

export const useMicrophone = ({ onRecordStart, onRecordStop, onRecordError, maxSeconds = 15 }: UseMicrophoneParams) => {
  const audioRecorderRef = useRef<MediaRecorder>();
  const audioChunksRef = useRef<Blob[]>([]);
  const timeoutIdRef = useRef<number>();
  const showAlert = useAlert();
  const [isRecording, setIsRecording] = useState(false);

  const stopRecordAudio = () => {
    if (audioRecorderRef.current) {
      audioRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const startRecordAudio = async () => {
    try {
      onRecordStart();
      const mediaStreamAudio = await navigator.mediaDevices.getUserMedia({ audio: true });

      audioRecorderRef.current = new MediaRecorder(mediaStreamAudio);
      audioRecorderRef.current.ondataavailable = (e) => {
        audioChunksRef.current.push(e.data);
      };
      audioRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/ogg; codecs=opus' });
        audioChunksRef.current = [];
        onRecordStop(audioBlob);

        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }
      };
      audioRecorderRef.current.start();
      setIsRecording(true);

      timeoutIdRef.current = window.setTimeout(() => {
        if (audioRecorderRef.current?.state === 'recording') {
          stopRecordAudio();
        }
      }, maxSeconds * 1000);
    } catch (error) {
      showAlert('Unexpected error occurred while trying to use the microphone', 'error');
      setIsRecording(false);
      if (onRecordError) {
        onRecordError();
      }
    }
  };

  return {
    isRecording,
    startRecordAudio,
    stopRecordAudio
  };
};
