import React from 'react';

import { columnsDataSet } from '../components/prototype-new-table-charges/services';
import useGetCharges from '../services/useGetCharges';
import ChargesCPrototpeScene from './charges-e-scene';

const ChargesE = () => {
  const {
    accounting,
    currentRowSelected,
    setCurrentRowSelected,
    handleSetAccounting,
    isLoading: loadingAccounting
  } = useGetCharges({ shouldFilterCharges: false });

  const handleSelectItem = (currentrow:columnsDataSet) => {
    setCurrentRowSelected(currentrow);
  };

  return (
    <ChargesCPrototpeScene
      accountingData={accounting}
      handleSetAccounting={handleSetAccounting}
      currentRowSelected={currentRowSelected}
      handleSelectRow={handleSelectItem}
      loadingAccounting={loadingAccounting}
    />
  );
};

export default ChargesE;
