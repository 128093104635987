import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: any) => ({
  title: { color: theme.palette.primary.main },
  createButton: {
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.button.hover },
  },
  createButtonText: { fontWeight: 500 },
  createIcon: { marginLeft: theme.spacing(1) },
  tooltipButton: {
    width: theme.spacing(3.7),
    height: theme.spacing(3.7),
    color: theme.palette.grey[700],
  },
}));

type HeaderProps = {
  t: TFunction;
  userIsTitleEmployee: boolean;
  handleModalOpen: (state: boolean) => void;
  agencyName: string
};

const Header: FC<HeaderProps> = ({
  t,
  userIsTitleEmployee,
  handleModalOpen,
  agencyName
}) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h4" className={classes.title}>
          {agencyName.length ? `${t('orders:orders-from')}${agencyName}` : t('orders:all-orders')}
        </Typography>
      </Grid>

      {userIsTitleEmployee && (
        <Grid item>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            className={classes.createButton}
            onClick={() => handleModalOpen(true)}
          >
            <Typography variant="body2" className={classes.createButtonText}>
              {t('orders:create-order')}
            </Typography>

            <FontAwesomeIcon
              icon="plus"
              size="sm"
              className={classes.createIcon}
            />
          </Button>
        </Grid>
      )}

      {!userIsTitleEmployee && (
        <Grid item>
          <Tooltip
            arrow
            title={t('users:create-order-only-title-employee') || ''}
          >
            <IconButton className={classes.tooltipButton}>
              <FontAwesomeIcon icon="question-circle" size="xs" />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
